import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import MindsetsJsSDK, {rolesConstants, metaUtils} from 'mindsets-js-sdk';

import PersonaSelection from './PersonaSelection'
import LoginForm from './LoginForm'


export default function Login() {
    const [searchParams] = useSearchParams();
    const [persona, setPersona] = useState([rolesConstants.ROLE_TEACHER, rolesConstants.ROLE_STUDENT].indexOf(searchParams.get('persona')) >= 0 ? searchParams.get('persona') : undefined);
    const [mode, setMode] = useState(['login', 'signup'].indexOf(searchParams.get('mode')) >= 0 ? searchParams.get('mode') : 'login');

    function getActionText() {
        switch (mode) {
            case 'signup':
                return 'Sign Up'
            case 'login':
            default:
                return 'Sign In'
        }
    }

    function onPersonaSelect(persona) {
        setPersona(persona);
    }

    function clearPersona() {
        setPersona(undefined);
    }

    function switchMode() {
        if (mode === 'signup') {
            setMode('login');
        } else {
            setMode('signup');
        }
    }

    useEffect(() => {
        metaUtils.setMetaTags(null, null, process.env.PUBLIC_S3_ASSETS_URL + '/link_preview/login.png');
        if (MindsetsJsSDK().Auth.isAuthenticated()) {
            window.location.href = '/';
        }
    });

    if (!persona) {
        return (
            <div className="content content-login">
                <div id="login-page">
                    <PersonaSelection onPersonaSelect={onPersonaSelect}/>
                </div>
            </div>
        );
    }

    return (
        <div className="content content-login">
            <div id="login-page">
                <LoginForm
                    persona={persona}
                    mode={mode}
                    action_text={getActionText()}
                    clearPersona={clearPersona}
                    switchMode={switchMode}
                />
            </div>
        </div>
    );
}
