import React from 'react';
import {useSelector} from "react-redux";
import {map} from 'lodash';

import {getStateBasedTitle} from '../utils';
import OneRow from './OneRow';
import STableHeader from './STableHeader';
import STablePage from './STablePage';

export default function ({selectPage, printable}) {

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);

    const data_table = dashboard_data.data_skill_table;

    if (!data_table) {
        return null;
    }

    const renderRows = () => {
        return map(data_table.items, (one_student, k) => {
            return (
                <OneRow
                    key={k}
                    one_student={one_student}
                    printable={printable}
                />
            );
        });
    }

    return (
        <div className="pam-component pam-component-table">
            <div className="pam-component-table-title">
                {getStateBasedTitle(dashboard).s_table}
            </div>

            <div>
                <table className="pam-component-table-main">
                    <STableHeader
                        data_table={data_table}
                        printable={printable}
                    />

                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </div>

            <div>
                <STablePage
                    data_table={data_table}
                    selectPage={selectPage}
                />
            </div>
        </div>
    );
}
