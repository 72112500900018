import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {graphingContentConfig} from 'utils/ggb'


class Graphing extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'challenge-component-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100)
    }

    componentDidMount() {
        this.ggbInit()
    }

    ggbInit() {
        const {config={}} = this.props.component_data
        const ggb_config = {
            ...graphingContentConfig(this.uniq_id),
            appletOnLoad: (graphing_instance) => {
                this.graphing_instance = graphing_instance
            },
            ...config.ggb
        }

        this.ggbApp = new GGBApplet(ggb_config, true)
        this.ggbApp.inject(this.uniq_id)
    }

    render() {
        return (
            <div className="challenge-component-graphing" id={`component-${this.uniq_id}`}>
                <div
                    className="component-graphing-content-container"
                >
                    <div
                        className="component-graphing-content-graph"
                    >
                        <div id={this.uniq_id}></div>
                    </div>
                </div>
            </div>
        )
    }
}

Graphing.propTypes = {
    component_data: PropTypes.object
}

export default Graphing
