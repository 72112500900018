import {Cookie} from "mindsets-js-sdk";
import {
    SET_PAM_SELECTED_CHALLENGE_ID,
    SET_PAM_FROM,
    SET_PAM_TILL,
    SET_PAM_SELECTED_PRACTICE_ID,
    SET_PAM_SELECTED_SCHOOL_ID,
    LOADED_PAM_PRACTICES,
    LOADED_PAM_CHALLENGE_SKILL_STRUCTURE,
    LOADED_PAM_STUDENT_SKILL_DATA,
    SET_PAM_MODE, SET_PAM_ZOOM, SET_PAM_SHOW_SCORE
} from "../actions/pam_actions";
import {FILTER_COOKIE_NAME} from "../pages/SAMDashboard";

const pam_selected_challenge_id = (payload = null, action) => {
    switch (action.type) {
        case SET_PAM_SELECTED_CHALLENGE_ID:
            return action.payload;
        default:
            return payload;
    }
};
const pam_from = (payload = null, action) => {
    switch (action.type) {
        case SET_PAM_FROM:
            return action.payload;
        default:
            return payload;
    }
};
const pam_till = (payload = null, action) => {
    switch (action.type) {
        case SET_PAM_TILL:
            return action.payload;
        default:
            return payload;
    }
};
const pam_selected_practice_id = (payload = null, action) => {
    switch (action.type) {
        case SET_PAM_SELECTED_PRACTICE_ID:
            return action.payload;
        default:
            return payload;
    }
};
const pam_selected_school_id = (payload = Cookie.hasCookie(FILTER_COOKIE_NAME + '_school_id') && !isNaN(parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + '_school_id'))) ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + '_school_id')) : null, action) => {
    switch (action.type) {
        case SET_PAM_SELECTED_SCHOOL_ID:
            return action.payload;
        default:
            return payload;
    }
};
const pam_mode = (payload = 0, action) => {
    switch (action.type) {
        case SET_PAM_MODE:
            return action.payload;
        default:
            return payload;
    }
};
const pam_zoom = (payload = 100, action) => {
    switch (action.type) {
        case SET_PAM_ZOOM:
            return action.payload;
        default:
            return payload;
    }
};
const pam_show_score = (payload = false, action) => {
    switch (action.type) {
        case SET_PAM_SHOW_SCORE:
            return action.payload;
        default:
            return payload;
    }
};
const pam_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_PAM_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};
const pam_challenge_skill_structure = (payload = [], action) => {
    switch (action.type) {
        case LOADED_PAM_CHALLENGE_SKILL_STRUCTURE:
            return action.payload;
        default:
            return payload;
    }
};
const pam_student_skill_data = (payload = [], action) => {
    switch (action.type) {
        case LOADED_PAM_STUDENT_SKILL_DATA:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    pam_selected_challenge_id,
    pam_from,
    pam_till,
    pam_selected_practice_id,
    pam_selected_school_id,
    pam_mode,
    pam_zoom,
    pam_show_score,
    pam_practices,
    pam_challenge_skill_structure,
    pam_student_skill_data
}
