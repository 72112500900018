import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SetStudentStoryPageIndexAction} from "../../actions/student_story_actions";

export default function () {
    const dispatch = useDispatch();
    const index = useSelector(state => state.student_story_page_index);

    if (index > 0) {
        return (
            <div className='student-story-nav student-story-prev'>
                <button
                    className="btn btn-large btn-challenge"
                    onClick={event => {
                        event.preventDefault();
                        dispatch(SetStudentStoryPageIndexAction(index - 1));
                    }}
                >
                    <i className="fas fa-chevron-left"/>
                </button>
            </div>
        );
    }

    return null;
}