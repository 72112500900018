import React from 'react';
import PropTypes from 'prop-types';


function HTMLContent({className, html_str}) {
    function refAndMore() {
        return (div) => {
            if (div) {
                div.innerHTML = html_str;
            }
        }
    }

    return (
        <div
            className={className}
            ref={refAndMore()}
        />
    );
}

HTMLContent.propTypes = {
    className: PropTypes.string,
    html_str: PropTypes.string.isRequired
}

export default HTMLContent
