import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {actions, ErrorMessageAction} from "mindsets-js-sdk";

import GeneralError from "../pages/GeneralError";
import {LogInProviderAction} from "../actions/login_actions";

export default function () {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Authentication code not provided.');

    useEffect(() => {
        if (searchParams.has('code')) {
            dispatch(LogInProviderAction('clever', {
                code: searchParams.get('code'),
                state: location.origin + '/auth/clever/callback'
            }))
                .then(() => {
                    let redirect_url = '/';
                    if (searchParams.has('state')) {
                        redirect_url = searchParams.get('state');
                    }
                    if (searchParams.has('redirect_url')) {
                        redirect_url = searchParams.get('redirect_url');
                    }

                    return window.location.href = redirect_url;
                })
                .catch(error => {
                    console.error('error: ', error);
                    dispatch(ErrorMessageAction('Sorry. There was a problem with your request. Please reload the page and try again or come back later.'));
                    setMessage(error);
                    setFailed(true);
                })
                .then(() => dispatch(actions.LoadingAction(false)));
        } else {
            setFailed(true);
        }
    }, []);

    if (failed === true) {
        return <GeneralError title='Authentication failed.' content={message}/>;
    }

    return (
        <div className="preloader-holder">
            <div className="preloader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='preloader-content'>Signing in...</div>
        </div>
    );
}
