import {get, set, isEmpty, forEach, sortBy, sortedUniqBy, filter, sumBy} from 'lodash'


export const computeStudentSkillData = (student_skill_data, skill_structure_data, mode) => {
    const computed_s_s_data = {}
    forEach(student_skill_data, ((item) => {
        if (isEmpty(get(computed_s_s_data, item.user_id))) {
            set(computed_s_s_data, item.user_id, {
                student_id: item.user_id,
                fname: item.fname,
                lname: item.lname,
                stage: item.completion,
                skills: {}
            })
        }

        set(
            computed_s_s_data,
            [item.user_id, 'skills', item.practice_skill_code, 'name'],
            item.practice_skill_name
        )

        // Aggregate points
        const points_path = [item.user_id, 'skills', item.practice_skill_code, 'points']
        const max_points_path = [item.user_id, 'skills', item.practice_skill_code, 'max_points']
        const points = get(computed_s_s_data, points_path, 0)
        const max_points = get(computed_s_s_data, max_points_path, 0)
        set(computed_s_s_data, points_path, points + item.points)
        set(computed_s_s_data, max_points_path, max_points + item.max_points)
    }))

    forEach(computed_s_s_data, ((student) => {
        forEach(student.skills, ((skill, skill_code) => {
            let max_points
            if (mode === 1) {
                // Potential mode
                max_points = skill.max_points
            } else {
                // Achievement mode
                max_points = sumBy(
                    filter(skill_structure_data, {practice_skill_code: skill_code}),
                    'max_points'
                )
            }
            // Calculate points percentage
            skill.points_percent = (skill.points / max_points * 100).toFixed(2)
        }))
    }))

    return computed_s_s_data
}

export const getUniqSkills = (student_skill_data) => {
    const data_sorted_1 = sortBy(student_skill_data, (d) => d.practice_skill_code)
    const data_sorted_2 = sortBy(data_sorted_1, (d) => d.practice_skill_category)
    const data_uniq = sortedUniqBy(data_sorted_2, (d) => d.practice_skill_code)
    return data_uniq.map((d) => {
        return {
            code: d.practice_skill_code,
            name: d.practice_skill_name,
            category: d.practice_skill_category
        }
    })
}

export const getStageName = (stage) => {
    switch (stage) {
        case 'stuck':
            return 'Started'
        case 'underway':
            return 'Underway'
        case 'nearly':
            return 'Nearly Completed'
        case 'completed':
            return 'Completed'
        case 'completed_bonus':
            return 'Bonus Completed'
        default:
            return ''
    }
}