import React from 'react';
import {useSelector} from "react-redux";
import {get, map} from 'lodash';

import {setTDWidth} from '../utils';

export default function ({data_table, printable}) {

    const dashboard_filters = useSelector(state => state.dashboard_filters);

    const getSkillNames = () => {
        const skills = get(data_table.items, [0, 'skills']);
        return map(skills, (skill, skill_code) => {
            if (dashboard_filters.subject === '') {
                return `${skill.subject} - ${skill_code} - ${skill.name}`;
            } else {
                return `${skill_code} - ${skill.name}`;
            }
        });
    }

    const renderSkillHeader = () => {
        const skill_names = getSkillNames();

        return skill_names.map((skill_name, k) => {
            if (printable) {
                return (
                    <th key={k} className="">
                        <strong>{skill_name}</strong>
                    </th>
                );
            }

            return (
                <th key={k} className="" style={setTDWidth(220)}>
                    <strong>{skill_name}</strong>
                </th>
            );
        });
    }

    if (printable) {
        return (
            <thead>
                <tr className="">
                    <th className="">
                        <strong>{`Student Name`}</strong>
                    </th>

                    <th className="">
                        <strong>{`Mindsets Challenges`}</strong>
                    </th>

                    <th className="">
                        <strong>{`Grade Level`}</strong>
                    </th>

                    <th className="">
                        <strong>{`Class Date`}</strong>
                    </th>

                    <th className="">
                        <strong>{`School`}</strong>
                    </th>

                    {renderSkillHeader()}
                </tr>
            </thead>
        );
    }

    return (
        <thead>
            <tr className="">
                <th className="" style={setTDWidth(150)}>
                    <strong>{`Student Name`}</strong>
                </th>

                <th className="" style={setTDWidth(150)}>
                    <strong>{`Mindsets Challenges`}</strong>
                </th>

                <th className="" style={setTDWidth(100)}>
                    <strong>{`Grade Level`}</strong>
                </th>

                <th className="" style={setTDWidth(100)}>
                    <strong>{`Class Date`}</strong>
                </th>

                <th className="" style={setTDWidth(250)}>
                    <strong>{`School`}</strong>
                </th>

                {renderSkillHeader()}
            </tr>
        </thead>
    );
}
