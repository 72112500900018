import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import classNames from 'classnames';

import {SetStudentStreamCurrentIndexAction} from "../../actions/stream_actions";

export default function () {
    const dispatch = useDispatch();

    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    // Small screen toggle, this varible only works in small screen
    const [toggle, setToggle] = useState(false);

    console.log('stream', stream);
    console.log('challenge_stream_entities', challenge_stream_entities);

    const renderProgress = () => {
        const total = challenge_stream_entities.length - 1;
        const done = (stream.index / total * 100).toFixed();

        return (
            <div className="ltb-progress flex-column flex-h-center">
                <div className="ltb-progress-text">
                    {`${done}% complete`}
                </div>
                <div className="ltb-progress-bar flex-row">
                    <div className="ltb-progress-done" style={{width: `${done}%`}}></div>
                </div>
            </div>
        );
    }

    const renderNav = () => {
        const render_page_labels = challenge_stream_entities.map((se, i) => {
            const class_name = classNames('ltb-nav-item flex-row flex-v-center', {
                'ltb-nav-item-active': i == current_index,
                'ltb-nav-item-done': i < stream.index,
                'ltb-nav-item-todo': i > stream.index
            });
            const onClick = () => {
                if (i <= stream.index) {
                    dispatch(SetStudentStreamCurrentIndexAction(i));
                    window.scrollTo(0, 0);
                    // Collapse left tool bar in small screen after clicked
                    setToggle(false);
                } else {
                    return false;
                }
            }
            return (
                <div className={class_name} key={se.id} onClick={onClick}>
                    <div className="ltb-nav-item-label">
                        {se.data.title}
                    </div>
                    <i className="ltb-nav-item-tick fas fa-check">
                    </i>
                </div>
            );
        });

        return (
            <div className="ltb-nav">
                {render_page_labels}
            </div>
        );
    }

    const renderToggleOpen = () => {
        const onClick = () => {
            setToggle(true);
        }

        return (
            <i className="left-tool-bar-toggle-open fas fa-bars" onClick={onClick}>
            </i>
        );
    }

    const renderToggleClose = () => {
        const onClick = () => {
            setToggle(false);
        }

        return (
            <i className="left-tool-bar-toggle-close fas fa-times" onClick={onClick}>
            </i>
        );
    }

    const ltb_classnames = classNames('left-tool-bar',
        {
            'left-tool-bar-expand': toggle
        }
    );

    return (
        <div className={ltb_classnames}>
            <div className="left-tool-bar-container">
                {renderProgress()}

                {renderNav()}

                {renderToggleClose()}
            </div>

            {renderToggleOpen()}
        </div>
    );
}