import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {map} from 'lodash'

import SpreadsheetCell from './SpreadsheetCell'
import {cellToObject} from './utils'


class SpreadsheetRow extends Component {
    renderCells() {
        return map(this.props.data, (cell, col_key) => {
            return (
                <SpreadsheetCell
                    key={col_key}
                    data={cellToObject(cell, this.props.getPlaceholder(col_key))}
                    is_preview={this.props.is_preview}
                    refEditableField={this.props.refEditableField(col_key)}
                />
            )
        })
    }

    render() {
        return (
            <tr className="spreadsheet-row">
                {this.renderCells()}
            </tr>
        )
    }
}

SpreadsheetRow.propTypes = {
    data: PropTypes.object.isRequired,
    is_preview: PropTypes.bool.isRequired,
    getPlaceholder: PropTypes.func.isRequired,
    refEditableField: PropTypes.func.isRequired
}

export default SpreadsheetRow
