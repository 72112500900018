import React from 'react';
import PropTypes from 'prop-types';
import {Droppable} from "react-beautiful-dnd";
import {get, find, isEmpty} from 'lodash';

import AnswerOption from './AnswerOption';
import {processStringContent} from 'utils/content';


function AnswerLayout({is_preview, answer_layout, answer_groups, onRemove}) {
    function getDroppableStyle(answer_group, part) {
        const color_light = get(answer_group, 'color_light')
        const color_dark = get(answer_group, 'color_dark')
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "10px",
            background: color_light,
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: color_dark,
            borderRadius: "4px",
            position: "relative",
            ...get(part, 'droppable_style')
        }
    }

    function renderPlaceholder(part) {
        if (isEmpty(part.content)) {
            return (
                <div className="dnd-answer-placeholder flex-row flex-vh-center">
                    {processStringContent(part.placeholder)}
                </div>
            );
        } else {
            return null;
        }
    }

    function renderSelected(part, answer_group, snapshot) {
        if (isEmpty(part.content)) {
            return null;
        }

        const extra_style = {}
        if (snapshot.isDraggingOver) {
            if (get(part, 'content.dnd_id') !== snapshot.draggingOverWith) {
                extra_style.opacity = 0.1;
            }
        }

        return (
            <AnswerOption
                key={part.content.dnd_id}
                is_answer={true}
                is_preview={is_preview}
                answer_group={answer_group}
                option={part.content}
                option_index={0}
                extra_style={extra_style}
                onRemove={onRemove(part.dnd_id)}
            />
        );
    }

    function renderDnD(part) {
        const answer_group = find(answer_groups, {dnd_type: part.dnd_type});

        return (
            <Droppable
                droppableId={part.dnd_id}
                type={part.dnd_type}
                direction="horizontal"
                isDropDisabled={false}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getDroppableStyle(answer_group, part)}
                    >
                        {renderPlaceholder(part)}

                        {renderSelected(part, answer_group, snapshot)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }

    function renderLayout() {
        const render_layout = answer_layout.map((part, i) => {
            switch (part.type) {
                case 'hidden':
                    return null;
                case 'static':
                    return (
                        <div key={i} className="flex-row flex-vh-center">
                            {processStringContent(part.content.html)}
                        </div>
                    );
                case 'dnd':
                    return (
                        <div key={i} className="">
                            {renderDnD(part)}
                        </div>
                    );
                default:
                    throw 'Unknown type';
            }
        });

        return (
            <div className="flex-row flex-box-10-10 flex-wrap">
                {render_layout}
            </div>
        )
    }

    return (
        <div className="component-dnd-answer">
            {renderLayout()}
        </div>
    );
}

AnswerLayout.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    answer_layout: PropTypes.array.isRequired,
    answer_groups: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default AnswerLayout
