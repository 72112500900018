import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {isBonus} from 'utils/challenge/streamEntity';


function CardContainer({challenge_version, stream_entity, use_challenge_color, hero_image, min_height, children}) {
    function renderBg() {
        if (isBonus(stream_entity)) {
            return (
                <div className="card-container-bg card-container-bg-bonus"/>
            )
        } else if (use_challenge_color) {
            return (
                <div
                    className="card-container-bg"
                    style={{backgroundColor: challenge_version.challenge_code.color}}/>
            )
        }
    }

    function renderHero() {
        if (hero_image) {
            return (
                <div className="card-container-hero" style={{backgroundImage: `url(${hero_image})`}}>
                    <div
                        className="card-container-slope"
                        style={{backgroundColor: challenge_version.challenge_code.color}}/>
                </div>
            );
        }
    }

    const class_name = classNames(
        'challenge-card-container',
        {
            'use-challenge-color': use_challenge_color && !isBonus(stream_entity)
        }
    )

    return (
        <div className={class_name}>
            {renderBg()}

            {renderHero()}

            <div className="container">
                <div className="challenge-card-min-height flex-row flex-v-center"
                     style={{minHeight: min_height}}>
                    <div className="flex-1">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

CardContainer.propsTypes = {
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            color: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    stream_entity: PropTypes.object.isRequired,
    use_challenge_color: PropTypes.bool,
    hero_image: PropTypes.string,
    min_height: PropTypes.string,
}

export default CardContainer
