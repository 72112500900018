import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {SetUserAction, WarningMessageAction} from "mindsets-js-sdk";

export default function ({user_new, validation_error_messages, setValidationErrorMessages}) {
    const dispatch = useDispatch();

    if (!user_new.password_reset) {
        return null;
    }

    const onChange = (field) => (event) => {
        const user_new_password = {
            ...user_new,
            [field]: event.target.value
        }
        dispatch(SetUserAction(user_new_password));

        if (validation_error_messages[field]) {
            const messages_new = {
                ...validation_error_messages
            }
            delete messages_new[field];
            setValidationErrorMessages(messages_new);
        }
    }

    return (
        <div className="profile-info-passwords">
            <div className="flex-row flex-wrap flex-gap-20">
                <div className="flex-1">
                    <div className="inputfield">
                        <label htmlFor="new_password" className="flex-row">
                            <div>{`Password *`}</div>
                            {
                                validation_error_messages.hasOwnProperty('new_password') ?
                                    <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                            }
                        </label>
                        <input
                            id="new_password"
                            type="password"
                            maxLength="50"
                            required={true}
                            placeholder={`At least 6 characters`}
                            value={user_new.new_password || ''}
                            onChange={onChange('new_password')}
                        />
                    </div>
                </div>

                <div className="flex-1">
                    <div className="inputfield">
                        <label htmlFor="confirm_password" className="flex-row">
                            <div>{`Confirm password *`}</div>
                            {
                                validation_error_messages.hasOwnProperty('confirm_password') ?
                                    <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                            }
                        </label>
                        <input
                            id="confirm_password"
                            type="password"
                            maxLength="50"
                            required={true}
                            placeholder={`Re-type password`}
                            value={user_new.confirm_password || ''}
                            onChange={onChange('confirm_password')}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}