import React, { useState } from 'react';
import { get } from 'lodash';

import WowMomentPhoto from './WowMomentPhoto';


export default (props) => {

    const showCollection = !!get(props.content, 'show_collection');
    const toggleCollection = (isShow) => () => {
        props.updateContent({show_collection: isShow});
    }

    if (!showCollection) {
        return (
            <div className="wow-moment-collection-open">
                <div className="flex-row flex-v-center flex-box-5-5">
                    <button className="btn btn-grey" onClick={toggleCollection(true)}>
                        <i className="fas fa-plus">
                        </i>
                    </button>
                    <div>
                        {`WOW Moments! Collection`}
                    </div>
                </div>
            </div>
        )
    }

    const renderPhoto1 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe1">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_1"
                    img={get(props.content, 'c_img_1')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="left"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto2 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe2">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_2"
                    img={get(props.content, 'c_img_2')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto3 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe3">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_3"
                    img={get(props.content, 'c_img_3')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto4 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe4">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_4"
                    img={get(props.content, 'c_img_4')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="right"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto5 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe1">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_5"
                    img={get(props.content, 'c_img_5')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="left"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto6 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe2">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_6"
                    img={get(props.content, 'c_img_6')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto7 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe3">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_7"
                    img={get(props.content, 'c_img_7')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto8 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-pe4">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_8"
                    img={get(props.content, 'c_img_8')}
                    wowMomentCode={props.wowMomentCode}
                    updateContent={props.updateContent}
                    positionX="right"
                    positionY="center"
                />
            </div>
        )
    }

    return (
        <div className="wow-moment-collection wow-moment-template">
            <div className="divider"></div>

            <div className="section">
                <div className="flex-row flex-v-center">
                    <div>
                        <button className="btn btn-grey" onClick={toggleCollection(false)}>
                            <i className="fas fa-minus">
                            </i>
                        </button>
                    </div>
                    <div className="flex-auto">
                        <h5 className="header-new center-align">
                            {`WOW Moments! Collection`}
                        </h5>
                    </div>
                </div>
            </div>

            <div className="flex-row">
                <div className="wm-template-mr-20">
                    {renderPhoto1()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto2()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto3()}
                </div>

                <div>
                    {renderPhoto4()}
                </div>
            </div>

            <div className="wm-template-mb-20"></div>

            <div className="flex-row">
                <div className="wm-template-mr-20">
                    {renderPhoto5()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto6()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto7()}
                </div>

                <div>
                    {renderPhoto8()}
                </div>
            </div>
        </div>
    )
}
