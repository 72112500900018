const analyticWowMoments = (Analytic) => {
    Analytic.prototype.wowMomentsSave = function (code) {
        this.track('WOW Moments Save', {
            code
        })
    }

    Analytic.prototype.wowMomentsUploadPhoto = function (code, photo_attr_name) {
        this.track('WOW Moments Upload Photo', {
            code,
            photo_attr_name
        })
    }

    Analytic.prototype.wowMomentsPublicView = function (code) {
        this.track('WOW Moments Public View', {
            code
        })
    }
}

export default analyticWowMoments
