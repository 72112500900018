import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {get, truncate} from 'lodash';

import {processStringContent, capitalizeFirstLetter} from 'utils/content';
import {getProjectExtensionHeroImage} from 'utils/cloud';
import ProjectExtensionLayer from './ProjectExtensionLayer';

const PROJECT_EXTENSION_COLOR = "#1F6641";

function OneProjectExtension({projectExtension, noPreview = false}) {
    const navigate = useNavigate();
    let me = useSelector(state => state.me);

    const [isActive, setIsActive] = useState(false);

    if (!me) {
        me = {
            curriculum_id: 1,
            curriculum: {
                is_code_shown: true,
                grade_term: 'grade'
            }
        }
    }

    const thumb_url = getProjectExtensionHeroImage(projectExtension.url);
    const standards = projectExtension.challenge_version.challenge_code.standards.filter(standard => me.curriculum_id ? standard.curriculum_id === me.curriculum_id : standard.curriculum_id === 1);

    const onClick = (e) => {
        e.preventDefault();
        setIsActive(!isActive);
    }

    const onMouseEnter = (e) => {
        e.preventDefault();
        setIsActive(true);
    }

    const onMouseLeave = (e) => {
        e.preventDefault();
        setIsActive(false);
    }

    function renderName() {
        const name_array = projectExtension.name.replace(': ', ':: ').split(': ');
        return (
            <div className="challenge-title">
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        );
    }

    function renderTopics() {
        let topics = [];
        standards.map(standard => {
            if (!topics.includes(standard.topic)) {
                topics.push(standard.topic);
            }
        });

        return (
            <div className="challenge-category">
                {truncate(topics.join(', '), {length: 140, omission: '...'})}
            </div>
        );
    }

    const renderPairInfo = () => {
        return (
            <div className="pair-info">
                {`Pairs with ${projectExtension.challenge_version.name}`}
            </div>
        );
    }

    const renderProjectExtensionLayer = () => {
        if (isActive) {
            return (
                <ProjectExtensionLayer
                    challenge_code={projectExtension.challenge_code}
                    projectExtension={projectExtension}
                />
            );
        }

        return null;
    }

    return (
        <div className="active-challenge" onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="challenge-triangle"
                 style={{borderBottomColor: PROJECT_EXTENSION_COLOR}}>&nbsp;</div>
            {/* renderNewLabel() */}
            <div className="challenge-container"
                 style={{backgroundImage: `url(${thumb_url})`}}>
                <div className="challenge-content-container"
                     style={{backgroundColor: PROJECT_EXTENSION_COLOR}}>
                    {renderName()}

                    <div className="challenge-description">
                        {
                            truncate(
                                processStringContent(projectExtension.description, {
                                    convert_html: false,
                                    convert_math: false
                                }),
                                {length: 140, omission: '...'}
                            )
                        }
                    </div>

                    {renderTopics()}

                    {renderPairInfo()}
                </div>
            </div>

            {renderProjectExtensionLayer()}
        </div>
    );
}

OneProjectExtension.propTypes = {
    ProjectExtension: PropTypes.object,
    noPreview: PropTypes.bool
};

export default OneProjectExtension;
