import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

export const LoadSamOverviewAction = options => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Data')))
        .then(() => MindsetsJsSDK().API.get('sam/overview', options))
        .then(response => {
            dispatch(LoadingAction(false));

            return response;
        });
};
