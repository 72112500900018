import MindsetsJsSDK from "mindsets-js-sdk";

export const isWindowsApp = () => {
    return typeof Windows !== 'undefined' && typeof Windows !== undefined
}

export const windowsConfig = () => {
    if (isWindowsApp()) {
        // Create windows push notifcation channel for logged in user
        if (MindsetsJsSDK().Auth.getAccessToken() && global_user.id) {
            var pushNotifications = Windows.Networking.PushNotifications
            var channelOperation = pushNotifications.PushNotificationChannelManager.createPushNotificationChannelForApplicationAsync()
            channelOperation.then(
                function (channel) {
                    if (channel && channel.uri) {
                        // Send the channel uri info to backend
                        MindsetsJsSDK().API.post('user_channel', {
                            type: 'windows_app_notification',
                            channel: channel.uri
                        }).then(response => {}).catch(error => {})

                        // Cancel the display of the notification if it is a toast and the app is in foreground
                        const pushNotificationReceivedHandler = (e) => {
                            switch (e.notificationType) {
                                case pushNotifications.PushNotificationType.toast:
                                    if (document.hasFocus()) {
                                        e.cancel = true;
                                    }
                                    break;
                                case pushNotifications.PushNotificationType.tile:
                                    break;
                                case pushNotifications.PushNotificationType.badge:
                                    break;
                            }
                        }
                        // Listen to the notification received event
                        channel.addEventListener("pushnotificationreceived", pushNotificationReceivedHandler);
                    }
                },
                function (error) {
                    // Could not create a channel. Retrieve the error through error.number.
                    console.error('error', error)
                }
            )
        }


        // Listen to windows app activated event
        Windows.UI.WebUI.WebUIApplication.addEventListener("activated", function (args) {
            if (args.argument) {
                const params = JSON.parse(args.argument)
                switch (params.type) {
                    case 'toast':
                        if (params.goto) {
                            window.location.href = params.goto
                        }
                        break
                }
            }
        })
    }
}

export const windowsNotification = (data) => {
    if (isWindowsApp()) {
        var Notifications = Windows.UI.Notifications;
        var idNumber = Math.floor(Math.random() * 100000000);  // Generates a unique ID number for the notification.

        // Setup the notification text.
        var toastXml = Notifications.ToastNotificationManager.getTemplateContent(Notifications.ToastTemplateType.toastText02);
        var strings = toastXml.getElementsByTagName("text");
        const {text = []} = data
        text.map((t, i) => {
            strings[i].appendChild(toastXml.createTextNode(t || ''));
        })

        // Set launch detail params
        const launch_params = {
            type: 'toast',
            ...data.launch_params
        }
        toastXml.selectSingleNode("/toast").setAttribute("launch", JSON.stringify(launch_params));

        // Create the toast notification object.
        var toast = new Notifications.ToastNotification(toastXml);
        toast.id = "Toast" + idNumber;


        Notifications.ToastNotificationManager.createToastNotifier().show(toast);

        // Add to the schedule.
        // Notifications.ToastNotificationManager.createToastNotifier().addToSchedule(toast);
    } else {
        console.info(data)
    }
}

export const windowsTile = (data) => {
    if (isWindowsApp()) {
        var tileContent = new Windows.Data.Xml.Dom.XmlDocument();
        var tile = tileContent.createElement("tile");
        tileContent.appendChild(tile);
        var visual = tileContent.createElement("visual");
        visual.setAttribute("branding", "nameAndLogo");
        tile.appendChild(visual);

        var bindingMedium = tileContent.createElement("binding");
        bindingMedium.setAttribute("template", "TileMedium");
        visual.appendChild(bindingMedium);

        const {image = []} = data
        image.map((im, i) => {
            if (im.src) {
                var peekImage = tileContent.createElement("image");
                peekImage.setAttribute("placement", "peek");
                peekImage.setAttribute("src", im.src);
                peekImage.setAttribute("alt", im.alt || '');
                bindingMedium.appendChild(peekImage);
            }
        })

        const {text = []} = data
        text.map((t, i) => {
            var text_element = tileContent.createElement("text");
            text_element.setAttribute("hint-wrap", "true");
            if (i == 0) {
                text_element.setAttribute("hint-style", "base");
            }
            text_element.innerText = t;
            bindingMedium.appendChild(text_element);
        })

        // TODO: Add other tile size bindings, like Wide and Large
        var notifications = Windows.UI.Notifications;
        var tileNotification = new notifications.TileNotification(tileContent);
        notifications.TileUpdateManager.createTileUpdaterForApplication().update(tileNotification);
    } else {
        console.info(data)
    }
}
