import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {sortBy} from 'lodash';
import {ErrorMessageAction, LoadedMySchoolChallengesAction, LoadMySchoolChallengesAction} from "mindsets-js-sdk";

import {SetPAMSelectedChallengeIdAction} from "../../actions/pam_actions";

function ChallengeFilter({school_required = true}) {
    const dispatch = useDispatch();
    const challenges = useSelector(state => state.me_school_challenges);
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);

    function loadChallenges() {
        if (school_required && !selected_school_id) {
            dispatch(LoadedMySchoolChallengesAction([]));
            return;
        }

        dispatch(LoadMySchoolChallengesAction({
            selected_school_id: selected_school_id ?? '',
            selected_from: from,
            selected_till: till
        })).catch((error) => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        });
    }

    function onChange(e) {
        e.preventDefault();
        if (!e.target.value) {
            dispatch(SetPAMSelectedChallengeIdAction(null));
        } else {
            dispatch(SetPAMSelectedChallengeIdAction(parseInt(e.target.value)));
        }
    }

    function renderOptions() {
        return sortBy(challenges, 'name').map((challenge) => {
            return (
                <option key={challenge.id} value={challenge.id}>
                    {`${challenge.name}`}
                </option>
            );
        });
    }

    useEffect(() => {
        loadChallenges();
    }, []);

    useEffect(() => {
        loadChallenges();
    }, [selected_school_id, from, till]);

    return (
        <div>
            <select
                value={selected_challenge_id ?? ''}
                disabled={challenges.length <= 0}
                onChange={onChange}
            >
                <option value="">
                    {`Select a Challenge`}
                </option>
                {renderOptions()}
            </select>
        </div>
    );
}

ChallengeFilter.propTypes = {
    school_required: PropTypes.bool
}

export default ChallengeFilter;