import {map, sortBy, sortedUniq, filter, forEach, get, set, sumBy} from 'lodash'

import {
    getIntervalCode,
    getIntervalName,
    getIntervalColor,
    getPracticeIntervalCodes
} from '../utils'


export const getCategoryNames = (student_skill_data) => {
    return sortedUniq(sortBy(map(student_skill_data, (d) => d.practice_skill_category)))
}

export const computeCategoryData = (student_skill_data, skill_structure_data, mode, practice_code) => {
    const student_category_data = {}
    forEach(student_skill_data, ((item) => {
        // Aggregate points
        const base_path = `${item.user_id}_${item.practice_skill_category}`
        const points_path = [base_path, 'points']
        const max_points_path = [base_path, 'max_points']
        const points = get(student_category_data, points_path, 0)
        const max_points = get(student_category_data, max_points_path, 0)
        set(student_category_data, points_path, points + item.points)
        set(student_category_data, max_points_path, max_points + item.max_points)
        set(student_category_data, [base_path, 'category'], item.practice_skill_category)
    }))

    forEach(student_category_data, ((student_category) => {
        let max_points
        if (mode === 1) {
            // Potential mode
            max_points = student_category.max_points
        } else {
            // Achievement mode
            max_points = sumBy(
                filter(skill_structure_data, {practice_skill_category: student_category.category}),
                'max_points'
            )
        }
        student_category.points_percent = (student_category.points / max_points * 100).toFixed(2)
        student_category.interval_code = getIntervalCode(practice_code, student_category.points_percent)
    }))

    const category_names = getCategoryNames(student_skill_data)
    const graph_series = map(getPracticeIntervalCodes(practice_code), (interval_code) => {
        const data_array = map(category_names, (category) => {
            return filter(student_category_data, {interval_code, category}).length
        })
        return {
            name: getIntervalName(practice_code, interval_code),
            data: data_array,
            color: getIntervalColor(practice_code, interval_code)
        }
    })

    return graph_series
}
