import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

export const LogInEmailAction = data => dispatch => {
    dispatch(LoadingAction(true, 'Signing in'));

    return MindsetsJsSDK().API
        .post(`auth/password/validate`, data)
        .then(() => MindsetsJsSDK().API.post('auth/login', data));
};
export const SignUpEmailAction = data => dispatch => {
    dispatch(LoadingAction(true, 'Signing up'));

    return MindsetsJsSDK().API.post(`auth/register`, data);
};
export const LogInCodeAction = data => dispatch => {
    dispatch(LoadingAction(true, 'Signing in'));

    return MindsetsJsSDK().API.post(`auth/code`, data);
};
export const LogInProviderAction = (provider, data) => dispatch => {
    dispatch(LoadingAction(true, 'Signing in'));

    return MindsetsJsSDK().API.post(`auth/${provider}/login`, data);
};
export const PasswordForgotAction = data => () => MindsetsJsSDK().API.post(`auth/password/forgot`, data);
export const PasswordResetAction = data => () => MindsetsJsSDK().API.post(`auth/login/reset`, data);
export const PasswordUpdateAction = data => () => MindsetsJsSDK().API.put(`password/0`, data);
export const LogoutAction = () => () => MindsetsJsSDK().API.post(`auth/logout`);
