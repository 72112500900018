import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page1'>
            <div className='flex-row flex-v-center flex-wrap content-row'>
                <div className='themes'>
                    <div className='line1'>You've have explored <span className='bold'>{data.themes_count}</span> real-world themes!</div>
                    <div className='line2'>Fantastic work!<br/>Keep exploring new horizons!</div>
                </div>
                <img
                    className='great-job-badge'
                    src={process.env.PUBLIC_S3_ASSETS_URL + '/student_story/badges/badge_greatjob.png'}
                    alt='Great Job Badge'
                />
            </div>
        </div>
    );
}