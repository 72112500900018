import React from 'react';
import {get} from 'lodash';

import SliderSingle from './SliderSingle'


export default class SliderMultiple extends SliderSingle {
    defaultAnswer() {
        return {
            min: this.props.component_data.data.valueMin,
            max: this.props.component_data.data.valueMax
        }
    }

    getStartValue() {
        return [this.state.answer.min, this.state.answer.max]
    }

    onUpdate() {
        return (values, handle) => {
            const answer = {
                min: this.numberFormat().from(get(values, 0)),
                max: this.numberFormat().from(get(values, 1))
            }
            this.setState({answer})
        }
    }
}
