const analyticSAMByTeacher = (Analytic) => {
    Analytic.prototype.samTeacherExpanded = function (
        teacher_id, teacher_name
    ) {
        this.track('SAM Teacher Expanded', {
            teacher_id,
            teacher_name
        })
    }

    Analytic.prototype.samTeacherClassReportDownloaded = function (
        class_code, challenge_name
    ) {
        this.track('SAM Teacher Class Report Downloaded', {
            class_code,
            challenge_name
        })
    }
}

export default analyticSAMByTeacher
