import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {LoadingAction, LoadChallengeCodeAction, LoadChallengeGuideAction} from "mindsets-js-sdk";

import PDFViewer from '../common/PDFViewer';
import GeneralError from "../pages/GeneralError";
import useAuth from "../hooks/useAuth";
import analytic from 'utils/Analytic';

export default function () {
    useAuth(true);

    const params = useParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const challenge_code = useSelector(state => state.challenge_code);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Challenge not found.');
    const [data, setData] = useState(null);

    function renderError() {
        if (failed === true) {
            return <GeneralError content={message}/>;
        }

        return null;
    }

    function renderContent() {
        if (data) {
            return (
                <PDFViewer
                    url={data}
                    fileName={challenge_code.latest_challenge_version_name + ' teacher guide.pdf'}/>
            );
        }

        return null;
    }

    function subscribe(event) {
        const link = event.target;
        if (link.hasAttribute('href')) {
            const data = {
                user_name: me.fname + ' ' + me.lname,
                email: me.email,
                challenge_code: challenge_code.code,
                challenge_name: challenge_code.latest_challenge_version_name
            };
            if (link.href.includes('challenge-resources/project-extensions')) {
                analytic.log('openProjectExtension', data);
            } else if (link.href.includes('challenge-resources/ELA-ELD')) {
                analytic.log('openELAExtension', data);
            }
        }
    }

    useEffect(() => {
        dispatch(LoadChallengeCodeAction(params.challenge_code, {
            'selectors[0]': 'latest_challenge_version_name'
        }))
            .then(() => dispatch(LoadChallengeGuideAction(params.challenge_code)))
            .then(buffer => setData(buffer))
            .then(() => {
                const element = document.getElementById("pdf-body");
                if (element) {
                    element.addEventListener("click", subscribe);
                }
            })
            .catch(error => {
                setMessage(error);
                setFailed(true);
            })
            .then(() => dispatch(LoadingAction(false)));
    }, []);

    if (!challenge_code || !me) {
        return null;
    }

    return (
        <>
            {renderError()}
            {renderContent()}
        </>
    );
}