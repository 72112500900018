import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {flatten} from 'lodash';
import ReactTooltip from 'react-tooltip';

import analytic from 'utils/Analytic';


function ProgressBar(
    {
        challenge_version,
        current_card_index,
        current_stream_index,
        show_avatar,
        current_taller,
        show_tooltip
    }
) {
    const me = useSelector(state => state.me);

    function renderAvatar() {
        if (show_avatar && me) {
            const onClick = () => {
                analytic.log('progressToolBarAvatar')
            };
            return (
                <div className="progress-cell-avatar hide-on-med-and-down">
                    <div className={`cashtivity-icon ${me.avatar}`} onClick={onClick}/>
                </div>
            );
        }
    }

    function renderCell(key, is_bonus, is_done, is_current) {
        const cell_props = {
            key,
            className: '',
            'data-for': 'challenge-progress-bar-tooltip',
            'data-tip': ''
        }

        cell_props.className += ' challenge-progress-cell ';
        cell_props.className += is_bonus ? ' progress-cell-bonus ' : ' progress-cell-page ';
        cell_props.className += is_done ? ' progress-cell-done ' : '';
        cell_props.className += is_current ? ' progress-cell-current ' : '';
        cell_props.className += is_current ? ' flex-6 ' : ' flex-4 ';
        cell_props.className += is_current ? ' flex-row flex-vh-center ' : '';
        cell_props.className += is_current && current_taller ? ' progress-cell-taller z-depth-1 ' : '';

        cell_props['data-tip'] += key === current_stream_index ? 'student progress<br>' : ''
        cell_props['data-tip'] += is_current ? 'you are here<br>' : ''

        const onClick = () => {
            analytic.log('progressToolBarBlock')
        };

        return [
            <div {...cell_props} onClick={onClick}>
                {is_current ? renderAvatar() : null}
            </div>
            ,
            <div className="flex-1" key={`${key}_divider`}/>
        ]
    }

    function renderCells() {
        const cells = [];
        const {first_bonus_index, final_index} = challenge_version;
        for (let i = 0; i <= final_index; i++) {
            cells.push(
                renderCell(
                    i,
                    i >= first_bonus_index - 1,
                    i <= current_stream_index,
                    i === current_card_index
                )
            );
        }

        const flatten_cells = flatten(cells);
        flatten_cells.pop();
        return flatten_cells;
    }

    function renderTooltip() {
        if (show_tooltip) {
            return (
                <ReactTooltip
                    id="challenge-progress-bar-tooltip"
                    effect="solid"
                    multiline={true}
                />
            );
        }
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <div className="challenge-progress-bar flex-row">
            {renderCells()}

            {renderTooltip()}
        </div>
    );
}

ProgressBar.propTypes = {
    challenge_version: PropTypes.shape({
        first_bonus_index: PropTypes.number.isRequired,
        final_index: PropTypes.number.isRequired
    }).isRequired,
    current_card_index: PropTypes.number.isRequired,
    current_stream_index: PropTypes.number.isRequired,
    show_avatar: PropTypes.bool.isRequired,
    current_taller: PropTypes.bool.isRequired,
    show_tooltip: PropTypes.bool.isRequired
};

export default ProgressBar;
