import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {throttle} from 'lodash';
import MindsetsJsSDK, {LoadingAction, Toggle, UpdateLessonAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';


function onChangeThrottle(callback) {
    return throttle(callback, 2000, {
        leading: true,
        trailing: false
    });
}

export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);

    function onChange() {
        const is_raise_hand_enable = !lesson.is_raise_hand_enable;

        dispatch(LoadingAction(true, is_raise_hand_enable ? 'Turning Raise Hands on' : 'Turning Raise hands off'));
        dispatch(UpdateLessonAction(lesson.id, {is_raise_hand_enable})).then(() => {
            analytic.log(
                'facilitationToggleRaiseHand',
                lesson.challenge_version.name,
                lesson.code,
                is_raise_hand_enable
            )
        }).catch((error) => {
            console.error(error)
        }).then(() => {
            setTimeout(() => {
                dispatch(LoadingAction(false));
            }, 1500)
        });
    }

    function renderLowerAllHands() {
        const onClick = (e) => {
            e.preventDefault()

            this.props.dispatch(actions.LoadingAction(true, 'Lowering All Hands'));

            MindsetsJsSDK().Api.lesson.lowerAllHands(
                this.props.lesson.id
            ).then(() => {
                analytic.log(
                    'facilitationLowerAllHands',
                    this.props.lesson.challenge_version.name,
                    this.props.lesson.code
                )
            }).catch((error) => {
                console.error(error)
            }).then(() => {
                setTimeout(() => {
                    this.props.dispatch(actions.LoadingAction(false));
                }, 1500)
            })
        }

        return (
            <div>
                <a href="#" onClick={onClick}>
                    {`Lower all hands`}
                </a>
            </div>
        )
    }

    return (
        <div className="mx-4 my-2">
            <div className='flex-row flex-v-center'>
                <i className={"mr-2 action-icon fas fa-hand-sparkles"}></i>
                <div className='switch-text'>
                    {`Raise Hands`}
                </div>
                <div className="flex-auto"></div>
                <Toggle onChange={onChangeThrottle(onChange)} is_on={lesson.is_raise_hand_enable}/>
                <div className="switch-value">
                    {lesson.is_raise_hand_enable ? 'Yes' : 'No'}
                </div>
            </div>

            {renderLowerAllHands()}
        </div>
    );
}
