import React, {useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import ProgressBar from 'common/challenge/ProgressBar';
import ButtonLeft from './ButtonLeft';
import ButtonRight from './ButtonRight';
import Bouncing from 'common/ui/Bouncing';


function ProgressToolBar({getRefComponents}) {
    const me = useSelector(state => state.me);
    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    function renderBouncing() {
        if (current_index === stream.challenge_version.complete_index) {
            return (
                <div className="progress-tool-bar-bouncing">
                    <Bouncing>
                        <i className="far fa-hand-point-down"/>
                    </Bouncing>
                </div>
            );
        }
    }

    //TODO is it necessary?
    useEffect(() => {
        if (me) {
            let user_channel = pusher.channel('user_' + me.id);
            if (!user_channel || !user_channel.subscribed) {
                user_channel = pusher.subscribe('user_' + me.id);
            }
            user_channel.bind('user.updated', () => {
                forceUpdate();
            });
        }

        return () => {
            let user_channel = pusher.channel('user_' + me.id);
            if (user_channel && user_channel.subscribed) {
                user_channel.unbind('user.updated', () => {
                });
            }
        }
    }, [me]);

    return (
        <div className="progress-tool-bar flex-row">
            <ButtonLeft/>

            <div className="flex-auto flex-column flex-h-center">
                <div className="progress-tool-bar-progress">
                    <ProgressBar
                        challenge_version={stream.challenge_version}
                        current_card_index={current_index}
                        current_stream_index={stream.index}
                        show_avatar={true}
                        current_taller={true}
                        show_tooltip={false}
                    />
                </div>
            </div>

            <ButtonRight
                getRefComponents={getRefComponents}
            />

            {renderBouncing()}
        </div>
    );
}

ProgressToolBar.propTypes = {
    getRefComponents: PropTypes.func.isRequired
}

export default ProgressToolBar
