import {get, set} from 'lodash'

import {processStringContent} from 'utils/content'


export const GRAPH_DEFAULT = {
    annotationsOptions: {
        enabledButtons: false
    },
    tooltip: {
        headerFormat: '<b>{series.name}</b><br />',
        pointFormat: 'x = {point.x}, y = {point.y}'
    }
}

export const POLL_GRAPH_DEFAULT = {
    chart: {
        type: "bar",
        backgroundColor: 'transparent',
        plotBackgroundColor: '#FFFFFF'
    },
    title: {
        text: "Results",
        align: "center"
    },
    credits: false,
    xAxis: {
        title: {
            text: "OPTIONS"
        },
        gridLineWidth: 1,
        tickInterval: 1,
        labels: {
            useHTML: true,
            style: {
                textAlign: "right"
            }
        }
    },
    yAxis: {
        title: {
            text: "STUDENTS"
        },
        gridLineWidth: 1,
        tickInterval: 2
    },
    legend: false,
    series: [
        {
            name: "Students chose...",
            color: "#AA4399",
            data: []
        }
    ]
}

export const processGraphLabels = (graph) => {
    const categories = get(graph, 'xAxis.categories')
    if (categories) {
        const categories_new = categories.map((c) => {
            return processStringContent(c, {convert_html: false})
        })
        set(graph, 'xAxis.categories', categories_new)
    }
}