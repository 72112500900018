import React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import MindsetsJsSDK, { toastActions } from "mindsets-js-sdk";
import { throttle } from 'lodash';

import analytic from 'utils/Analytic';


export default function (props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = () => {
        analytic.log(
            'wowMomentsSave',
            props.wowMomentCode
        );

        const data = {
            code: props.wowMomentCode ?? '',
            challenge_code_id: props.challengeCodeId ?? '',
            lesson_id: props.lessonId ?? '',
            content: props.content ?? ''
        }
        if (props.wowMomentId) {
            return MindsetsJsSDK().Api.wowMoment.put(
                props.wowMomentId, data
            ).then((response) => {
                dispatch(toastActions.SuccessMessageAction('WOW Moments! saved.'));
            }).catch((error) => {
                console.error(error);
                dispatch(toastActions.ErrorMessageAction(error));
            })
        } else {
            return MindsetsJsSDK().Api.wowMoment.post(
                data
            ).then((response) => {
                dispatch(toastActions.SuccessMessageAction('WOW Moments! saved.'));
                const wowMomentId = response.wow_moment_id;
                navigate(`/teacher/wow-moments/save/${wowMomentId}`, {replace: true});
            }).catch((error) => {
                console.error(error);
                dispatch(toastActions.ErrorMessageAction(error));
            })
        }
    }
    const onSubmitThrottle = throttle(onSubmit, 2000, {
        leading: true,
        trailing: false
    });

    return (
        <div className="wow-moment-submit">
            <div className="flex-row flex-h-center">
                <button className="btn btn-challenge" onClick={onSubmitThrottle}>
                    {`Save WOW Moments!`}
                </button>
            </div>
        </div>
    );
}