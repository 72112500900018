import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import Student from './Student';
import Answer from './Answer';
import {getCardName} from 'utils/challenge/streamEntity';


function Stream({stream}) {
    const lesson = useSelector(state => state.lesson);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);

    function renderStudent() {
        return (
            <Student
                stream={stream}
            />
        );
    }

    function isBehind() {
        return stream.index < selected_card_index;
    }

    function renderAnswer() {
        if (!isBehind()) {
            return (
                <Answer stream={stream}/>
            );
        } else {
            return (
                <div className="byquestion-stream-behind-progress">
                    {`on `}
                    {getCardName(lesson.challenge_version, stream.index)}
                </div>
            );
        }
    }

    const extra_classname = isBehind() ? ' byquestion-stream-behind ' : '';

    return (
        <tr className={"byquestion-stream " + extra_classname}>
            <td>
                {renderStudent()}
            </td>

            <td>
                {renderAnswer()}
            </td>
        </tr>
    );
}

Stream.propTypes = {
    stream: PropTypes.shape({
        index: PropTypes.number.isRequired
    }).isRequired
}

export default Stream
