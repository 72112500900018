import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useAuth from "../../hooks/useAuth";
import HomeMenu from "common/HomeMenu";
import LessonList from "common/ui/LessonList";
import {LoadCoTeachingLessonsAction} from "../../actions/lesson_actions";

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const co_teaching_lessons = useSelector(state => state.co_teaching_lessons);

    function getLessons() {
        dispatch(LoadCoTeachingLessonsAction({
            limit: 0,
            order_by: 'created_at',
            order_direction: 'desc',
            'selectors[0]': 'challengeVersion_challengeCode',
            'selectors[1]': 'students_count'
        }));
    }

    useEffect(() => {
        if (!co_teaching_lessons.length) {
            getLessons();
        }
    }, []);

    return (
        <div id="teacher-dashboard">
            <div className="container">
                <div className="section">
                    <HomeMenu/>
                </div>
                <div className="section">
                    <LessonList
                        lessons={co_teaching_lessons}
                        list_name='my_classes'
                    />
                </div>
            </div>
        </div>
    );
}