import React from 'react'

import QuestionCard from './QuestionCard'


export default class InstructionCard extends QuestionCard {
    getCardType() {
        return 'instruction'
    }
}
