import React, {useEffect, useState} from 'react';
import MindsetsJsSDK from "mindsets-js-sdk";

import Menu from './Menu';
import Content from './Content';
import useAuth from "../../hooks/useAuth";

export default function () {
    useAuth(['teacher']);

    const [selected_item, setSelectedItem] = useState(0);
    const [teacher_pd_code, setTeacherPdCode] = useState(null);

    useEffect(() => {
        MindsetsJsSDK().Api.setting.show('TEACHER_PD_CODE').then((response) => {
            setTeacherPdCode(response.setting.value);
        });
    }, []);

    return (
        <div id="teacher-pd">
            <div className="container">
                <div className="section">
                    <h2 className="header-new">
                        {`Certified Educator Course - Level 1`}
                    </h2>
                </div>

                <div className="section">
                    <div className="flex-row-m flex-box-15-15">
                        <div className="flex-2">
                            <Menu
                                selected_item={selected_item}
                                onSelectItem={selected_item => setSelectedItem(selected_item)}
                            />
                        </div>

                        <div className="teacher-pd-divider">
                        </div>

                        <div className="flex-5">
                            <Content
                                selected_item={selected_item}
                                teacher_pd_code={teacher_pd_code}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
