import React from 'react';

import UserInfo from './UserInfo'
import UserMembership from './UserMembership'
import Footer from './Footer'
import EmailPreferences from './EmailPreferences'
import useAuth from "../../hooks/useAuth";

export default function () {
    useAuth(true);

    return (
        <div id='profile-page'>
            <div className="container">
                <div className="section">
                    <h2 className="header-new">My Account</h2>
                </div>

                <div className="flex-row flex-box-5-10 flex-wrap">
                    <UserInfo/>

                    <div className="flex-auto"/>

                    <div className="flex-1">
                        <UserMembership/>
                    </div>
                </div>

                <hr className="separator bigger"/>

                <EmailPreferences/>

                <div className="section"/>

                <Footer/>
            </div>
        </div>
    );
}
