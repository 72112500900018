import React, {createRef, useEffect, useState} from 'react';
import {map, throttle, isEmpty, filter, reverse} from 'lodash';
import MindsetsJsSDK from "mindsets-js-sdk";

import AChallenge from './AChallenge';

const fetchChallengesThrottle = function (callback) {
    return throttle(callback, 2000, {
        leading: false,
        trailing: true
    })
}
let channel_names = [];

export default function () {
    const header = createRef();
    const [challenge_versions, setChallengeVersions] = useState(null);
    const [challenge_selected, setChallengeSelected] = useState(null);

    function fetchChallenges() {
        MindsetsJsSDK().Api.challengeVersion.student.index().then((response) => {
            const not_empty_challenges = filter(response.challenge_versions, (c) => !isEmpty(c.lessons));
            setChallengeVersions(reverse(not_empty_challenges));
        }).catch((error) => {
            console.error(error);
        });
    }

    function subscribe() {
        challenge_versions.map(challenge_version => {
            challenge_version.lessons.map((l) => {
                const channel_name = `challenge_${challenge_version.challenge_code.code}_user_${l.user_id}_lessons`;
                const existing_channels = pusher.channels.all().map(channel => channel.name);
                if (existing_channels.indexOf(channel_name) < 0 || !pusher.channel(channel_name).subscribed) {
                    channel_names.push(channel_name);
                    pusher.subscribe(channel_name).bind('lessons.updated', () => {
                        fetchChallengesThrottle(fetchChallenges)();
                    });
                }
            });
        });
    }

    function onSelectChallenge(challenge_selected) {
        setChallengeSelected(challenge_selected);
        window.scrollTo({
            top: header.current.offsetTop,
            behavior: 'smooth'
        });
    }

    function renderEmptyState() {
        return (
            <div>
                {`The Challenges shared or assigned to you by your teachers will appear here.`}
            </div>
        )
    }

    function renderSelectedChallenge() {
        if (!challenge_selected) {
            return null
        }

        const challenge_version = challenge_versions.find(challenge_version => challenge_version.challenge_code.code === challenge_selected);
        if (isEmpty(challenge_version)) {
            return null;
        }

        return (
            <div className="section">
                <AChallenge
                    challenge_version={challenge_version}
                    is_selected={true}
                    onSelectChallenge={() => {
                        setChallengeSelected(null);
                    }}
                />

                <div className="student-dashboard-my-challenges-divider">
                </div>
            </div>
        )
    }

    function renderFilling() {
        const n = Math.ceil(challenge_versions.length / 12) * 12
        const array_to_fill = Array(n - challenge_versions.length)
        return map(array_to_fill, (v, k) => {
            return (
                <div className="student-dashboard-a-challenge-filling" key={`filling-${k}`}></div>
            )
        })
    }

    function renderChallenges() {
        if (!challenge_versions) {
            return null;
        }

        if (Array.isArray(challenge_versions) && challenge_versions.length <= 0) {
            return renderEmptyState();
        }

        const challenge_versions_x = challenge_versions.map((challenge_version, key) => {
            return (
                <div key={key}>
                    <AChallenge
                        challenge_version={challenge_version}
                        is_selected={false}
                        onSelectChallenge={onSelectChallenge}
                    />
                </div>
            )
        })

        return (
            <div className="flex-row-m flex-wrap flex-between flex-box-10-10">
                {challenge_versions_x}

                {renderFilling()}
            </div>
        )
    }

    useEffect(() => {
        fetchChallengesThrottle(fetchChallenges)();
    }, []);
    useEffect(() => {
        if (Array.isArray(challenge_versions)) {
            subscribe();
        }
    }, [challenge_versions]);
    useEffect(() => {
        return () => {
            channel_names.map(channel_name => {
                const channel = pusher.channel(channel_name);
                if (channel && channel.subscribed) {
                    channel.unsubscribe();
                    channel.unbind_all();
                }
            });
            channel_names = [];
        }
    }, []);

    return (
        <div className="student-dashboard-my-challenges">
            <h3 className="header-new" ref={header}>
                {`My Challenges`}
            </h3>

            {renderSelectedChallenge()}

            <div className="section">
                {renderChallenges()}
            </div>
        </div>
    );
}