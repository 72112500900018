import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {actions, rolesConstants, ErrorMessageAction} from 'mindsets-js-sdk';

import {LogInProviderAction} from "../../../actions/login_actions";

let interval;

const Google = function ({persona, callback}) {
    const dispatch = useDispatch();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [counter, setCounter] = useState(0);
    const [googleIsLoading, setGoogleIsLoading] = useState(true);

    const handleCredentialResponse = function (response) {
        dispatch(LogInProviderAction('google', {
            id_token: response.credential,
            persona
        }))
            .then(callback)
            .catch(error => {
                dispatch(actions.LoadingAction(false));
                console.error('error: ', error);
                dispatch(ErrorMessageAction('Sorry. There was a problem with your request. Please reload the page and try again or come back later.'));
            });
    }

    useEffect(() => {
        interval = setInterval(() => {
            setCounter(counter + 1);
            if (typeof window !== "undefined" && window.google) {
                setGoogleIsLoading(false);
            }
        }, 100);
    }, []);

    useEffect(() => {
        if (counter >= 100 && interval) {
            clearInterval(interval);
            throw 'Unable to load Google SSO library. Your network is too slow or your Firewall is blocking it.';
        }
    }, [counter]);

    useEffect(() => {
        if (interval && !googleIsLoading) {
            clearInterval(interval);
        }
    }, [googleIsLoading]);

    useEffect(() => {
        if (scriptLoaded) return undefined;

        const initializeGoogle = () => {
            if (!window.google || scriptLoaded) return;

            setScriptLoaded(true);
            window.google.accounts.id.initialize({
                client_id: process.env.PUBLIC_GOOGLE_OAUTH_ID,
                callback: handleCredentialResponse,
            });
            window.google.accounts.id.renderButton(
                document.getElementById("google-sso-button"),
                {size: "large", width: "300px", logo_alignment: "left"}  // customization attributes
            );
        };

        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.onload = initializeGoogle;
        script.async = true;
        script.id = "google-client-script";
        document.querySelector("body")?.appendChild(script);

        return () => {
            window.google?.accounts.id.cancel();
            document.getElementById("google-client-script")?.remove();
        };
    }, [scriptLoaded]);

    return (
        <button className="sso-button-inner flex-row flex-v-center" id='google-sso-button'>
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_google.svg`} alt='with google'/>
            <div className="flex-auto center-align sso-button-label">Google</div>
        </button>
    )
}

Google.propTypes = {
    persona: PropTypes.oneOf([rolesConstants.ROLE_TEACHER, rolesConstants.ROLE_STUDENT]).isRequired,
    callback: PropTypes.func.isRequired
};

export default Google;
