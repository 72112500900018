import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {countriesConstants, statesConstants, SetUserAction} from "mindsets-js-sdk";

import {isEducator} from "utils/role";

const CountryField = function ({validation_error_messages, setValidationErrorMessages}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    let user_new = JSON.parse(JSON.stringify(user));
    let validation_error_messages_new = JSON.parse(JSON.stringify(validation_error_messages));

    if (isEducator(user_new)) {
        const countries = [];
        countries.push(<option key={'empty'} value='' disabled>Select country</option>);

        for (const code in countriesConstants.COUNTRIES) {
            if (countriesConstants.COUNTRIES.hasOwnProperty(code)) {
                countries.push(<option key={code} value={code}>{countriesConstants.COUNTRIES[code]}</option>);
            }
        }

        return (
            <div className="col s12 m6">
                <div className="inputfield">
                    <label htmlFor="country" className="flex-row">
                        <div>Country *</div>
                        {
                            validation_error_messages.hasOwnProperty('country') ?
                                <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                        }
                    </label>
                    <select
                        value={user_new.country ? user_new.country : ''}
                        onChange={event => {
                            user_new.country = event.target.value;
                            if (user_new.state && user_new.country && (!statesConstants.STATES.hasOwnProperty(user_new.country) || !statesConstants.STATES[user_new.country].hasOwnProperty(user_new.state))) {
                                delete user_new.state;
                            }
                            dispatch(SetUserAction(user_new));
                            delete validation_error_messages_new.country;
                            setValidationErrorMessages(validation_error_messages_new);
                        }}
                        id="country" required
                        className={validation_error_messages.hasOwnProperty('country') ? 'invalid' : ''}
                    >
                        {countries}
                    </select>
                </div>
            </div>
        );
    }

    return null;
}

CountryField.propTypes = {
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired
}

export default CountryField;
