import React from 'react';
import {throttle} from "lodash";
import MindsetsJsSDK, {filterConstants} from "mindsets-js-sdk";

import BaseAnswer from "../BaseAnswer";
import PollOption from "./PollOption";
import analytic from "utils/Analytic";

export default class InlinePoll extends BaseAnswer {
    constructor(props) {
        super(props);
        this.lesson_poll_responses_channel = null;
        this.state.responses = [];


        this.fetchLessonVotesThrottle = throttle(this.fetchPollResponses.bind(this), 5000, {
            leading: false,
            trailing: true
        })
    }

    componentDidMount() {
        if (this.props.lesson_id) {
            this.fetchPollResponses();
            this.subscribe();
        }
    }

    componentWillUnmount() {
        if (this.lesson_poll_responses_channel) {
            //TODO Which one of these two should be used?
            this.lesson_poll_responses_channel.unsubscribe();
            pusher.unsubscribe(`lesson_${this.props.lesson_id}_question_${this.props.question_id}`);
        }
    }

    fetchPollResponses() {
        if (this.props.lesson_id) {
            MindsetsJsSDK().API.get('responses', {
                'filters[lesson_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + this.props.lesson_id,
                'filters[question_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + this.props.question_id
            }).then(response => this.setState({responses: response.items})).catch(error => console.error(error));
        }
    }

    subscribe() {
        if (!this.lesson_poll_responses_channel && this.props.lesson_id) {
            const channel = pusher.subscribe(`lesson_${this.props.lesson_id}_question_${this.props.question_id}`);
            const that = this;
            channel.bind('responses.created', function (event_data) {
                let responses = JSON.parse(JSON.stringify(that.state.responses));
                event_data.responses.map(event_response => {
                    const index = responses.findIndex(response => response.stream_id === event_response.stream_id && response.question_id === event_response.question_id);
                    if (index >= 0) {
                        responses.splice(index, 1);
                    }
                });
                responses = responses.concat(event_data.responses);
                that.setState({responses});
                that.fetchLessonVotesThrottle();
            });
        }
    }

    saveVote(poll_option_id) {
        if (!this.props.is_preview && this.props.lesson_id && this.props.stream_id) {
            // Do not submit vote again if it is a same vote
            if (this.props.existing_answer && this.props.existing_answer.data && this.props.existing_answer.data.answer && this.props.existing_answer.data.answer === poll_option_id) {
                return;
            }
            let responses = JSON.parse(JSON.stringify(this.state.responses));
            //This is for instant update
            const index = responses.findIndex(response => response.stream_id === this.props.stream_id && response.question_id === this.props.question_id);
            if (index >= 0) {
                responses.splice(index, 1);
            }
            responses.push({
                data: {answer: poll_option_id},
                is_active: true,
                lesson_id: this.props.lesson_id,
                question_id: this.props.question_id,
                stream_entity_id: this.props.stream_entity_id,
                stream_id: this.props.stream_id,
                type: "poll.inline"
            });
            this.setState({
                responses,
                answer: poll_option_id
            });

            MindsetsJsSDK().API.post('responses', {
                responses: [
                    {
                        question_id: this.props.question_id,
                        response: poll_option_id
                    }
                ],
                stream_id: this.props.stream_id,
                lesson_id: this.props.lesson_id,
                stream_entity_id: this.props.stream_entity_id
            }).then(() => {
                analytic.log('studentSubmittedVote', {
                    lesson_id: this.props.lesson_id,
                    stream_id: this.props.stream_id,
                    stream_entity_id: this.props.stream_entity_id,
                    poll_id: this.props.support_data.poll.id,
                    poll_option_id: poll_option_id,
                    question_id: this.props.question_id
                });
            }).catch(error => console.error(error));
        }
    }

    render() {
        return (
            <div className="challenge-component-poll">
                <div className='poll-new'>
                    <div className="section">
                        <div className="poll-options">
                            <div className="poll-inline-options">
                                <div className="flex-row flex-h-start">
                                    <div className="flex-column flex-box-5-10">
                                        {this.props.support_data.poll.poll_options.map(poll_option => (
                                            <PollOption
                                                key={poll_option.id}
                                                poll_option={poll_option}
                                                show_graph={!!this.state.answer || (this.props.is_preview && !!this.props.lesson_id)}
                                                is_selected={!!this.state.answer && this.state.answer === poll_option.id}
                                                option_votes={this.state.responses.filter(response => response.data.answer === poll_option.id).length}
                                                total_votes={this.state.responses.length}
                                                saveVote={() => this.saveVote(poll_option.id)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
