import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import classNames from 'classnames';
import MindsetsJsSDK, {Toggle} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import FeatureLock from 'common/membership/FeatureLock';
import {userCan} from 'utils/membership';

import './toggle_is_hidden_action.scss';


function ToggleIsHiddenAction(
    {
        lesson,
        is_paid_only = false
    }
) {
    const me = useSelector(state => state.me);
    const [is_on, setIsOn] = useState(lesson.is_hidden);

    function toggleOnOff() {
        if (is_on === lesson.is_hidden) {
            setIsOn(!is_on);

            MindsetsJsSDK().API.put(`lessons/${lesson.id}`, {is_hidden: !lesson.is_hidden})
                .then(() => {
                    analytic.log(
                        'lessonToggleHiddenForStudents',
                        lesson.code,
                        !lesson.is_hidden
                    )
                })
                .catch((error) => {
                    setIsOn(lesson.is_hidden);
                    console.error(error);
                });
        }
    }

    function isDisabled() {
        return is_paid_only && (!me || !userCan(me.subscription_status, 'makeLessonHidden'))
    }

    function renderLabel() {
        const label = (
            <div className="toggle-label flex-row flex-v-center">
                <i className="toggle-icon fas fa-ban mr-2"/>
                <div>
                    {`Hide Class from students`}
                </div>
            </div>
        )

        if (is_paid_only && me) {
            return (
                <FeatureLock
                    enable={!!me && userCan(me.subscription_status, 'makeLessonHidden')}
                >
                    {label}
                </FeatureLock>
            );
        } else {
            return label;
        }
    }

    function renderSwitch() {
        return (
            <Toggle onChange={toggleOnOff} is_on={is_on} is_disabled={isDisabled()}/>
        );
    }

    function renderValue() {
        return (
            <div className="toggle-value">
                {is_on ? 'Yes' : 'No'}
            </div>
        );
    }

    function renderDescriptionOn() {
        if (!is_on) {
            return null;
        }

        return (
            <div>
                <span>
                    {`Yes. Students won’t be able to see this class. It is now hidden. `}
                </span>
            </div>
        );
    }

    useEffect(() => {
        setIsOn(lesson.is_hidden);
    }, [lesson.is_hidden]);

    const class_name = classNames(
        'toggle-is-hidden-action is-hidden-toggle',
        {
            'toggle-is-hidden-action-disabled': isDisabled()
        }
    );

    return (
        <div className={class_name}>
            <div className='flex-row flex-v-center'>
                {renderLabel()}

                <div className="flex-auto"/>

                {renderSwitch()}

                {renderValue()}
            </div>

            <div className="toggle-description">
                {renderDescriptionOn()}
            </div>
        </div>
    );
}

ToggleIsHiddenAction.propTypes = {
    lesson: PropTypes.object.isRequired,
    is_paid_only: PropTypes.bool
}

export default ToggleIsHiddenAction
