import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction, LoadingAction} from "mindsets-js-sdk";

import Teacher from "./Teacher";
import Email from "./Email";
import {getTeacherStreams} from "../../TeacherFacilitation/utils";
import analytic from "utils/Analytic";

export default function ({close}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const teachers = useSelector(state => state.users);
    const streams_x = useSelector(state => state.streams);
    const [ids, setIds] = useState([]);
    const [sending, setSending] = useState(false);
    const [input, setInput] = useState('');

    const teacher_streams = getTeacherStreams(streams_x).filter(stream => stream.user_id !== me.id);
    const teacher_stream_emails = teacher_streams.map(stream => stream.user.email);

    function filterTeachers(teachers) {
        return teachers.filter(teacher => {
            return teacher.id !== me.id && (input ? (teacher.email.toLowerCase().includes(input.toLowerCase()) || teacher.fname.toLowerCase().includes(input.toLowerCase()) || teacher.lname.toLowerCase().includes(input.toLowerCase())) : true);
        });
    }

    function getEmail(id) {
        return teachers.find(teacher => teacher.id === id).email;
    }

    function addUser(id) {
        let ids_x = JSON.parse(JSON.stringify(ids));
        if (!ids_x.includes(id)) {
            ids_x.push(id);
            setIds(ids_x);
            setInput('');
        }
    }

    function removeUser(id) {
        let ids_x = JSON.parse(JSON.stringify(ids));
        if (ids_x.includes(id)) {
            ids_x.splice(ids_x.indexOf(id), 1);
            setIds(ids_x);
        }
    }

    function assignTeachers() {
        setSending(true);
        dispatch(LoadingAction(true, 'Assigning Teachers'));
        MindsetsJsSDK().Api.lesson
            .assignUsers(lesson.id, {ids})
            .then(() => {
                analytic.log('facilitationMembersTeachersAssigned', ids);
                setSending(false);
                setIds([]);
                dispatch(LoadingAction(false));
                close();
            })
            .catch((error) => {
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
                console.error(error);
                setSending(false);
            });
    }

    function getMyTeachers(teachers) {
        return teachers.filter(teacher => teacher.school_id === me.school_id)
    }

    function getRemoteTeachers(teachers) {
        return teachers.filter(teacher => teacher.school_id !== me.school_id)
    }

    function renderMyTeachers(teachers) {
        return teachers.map(teacher => (
            <Teacher
                key={teacher.id}
                teacher={teacher}
                joined={teacher_stream_emails.includes(teacher.email) || ids.includes(teacher.id)}
                addUser={addUser}
            />
        ));
    }

    function renderRemoteTeachers(teachers) {
        return teachers.map(teacher => (
            <div key={teacher.id}>
                <div><strong>{teacher.school.name.toUpperCase()}</strong></div>
                <Teacher
                    teacher={teacher}
                    joined={teacher_stream_emails.includes(teacher.email) || ids.includes(teacher.id)}
                    addUser={addUser}
                />
            </div>
        ));
    }

    function renderSearchResults() {
        const teachers_x = filterTeachers(teachers);
        const my_teachers = getMyTeachers(teachers_x);
        const remote_teachers = getRemoteTeachers(teachers_x);
        if (my_teachers.length <= 0 && remote_teachers.length <= 0) {
            return (
                <Teacher
                    teacher={{
                        id: 0,
                        email: '',
                        fname: 'No results',
                        lname: ''
                    }}
                    joined={false}
                    addUser={() => setInput('')}
                />
            );
        }

        return (
            <div>
                {renderMyTeachers(my_teachers)}
                {renderRemoteTeachers(remote_teachers)}
            </div>
        );
    }

    useEffect(() => {
        return () => {
            setIds([]);
            setSending(false);
        }
    }, []);

    return (
        <form className='assign-teachers-form' onSubmit={(event) => event.preventDefault()}>
            <div className='flex-row flex-wrap email-list flex-box-2-5 mb-2'>
                {ids.map(id => (
                    <Email
                        key={id}
                        email={getEmail(id)}
                        removeUser={() => removeUser(id)}
                    />
                ))}
                <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder='Type a name or email'
                    value={input}
                    onChange={event => setInput(event.target.value)}
                />
            </div>
            <div className='results'>SEARCH RESULTS</div>
            <div className='teacher-list mb-2'>
                {renderSearchResults()}
            </div>
            <div className='flex-row flex-between flex-v-center'>
                <div className='action-hint'>Only teachers in your school will be shown here.</div>
                <button className="btn btn-on-white no-wrap" onClick={assignTeachers}
                        disabled={ids.length <= 0 || sending}>
                    <span>Add Teachers</span>
                </button>
            </div>
        </form>
    );
}
