import {
    CHALLENGE_IMAGE_PATH,
    CHALLENGE_IMAGE_MAIN_SOURCE,
    CHALLENGE_IMAGE_MAIN_THUMB
} from 'constants/index.js'


export const getChallengeHeroImage = (challenge_code) => {
    return `${process.env.PUBLIC_S3_ASSETS_URL}/${CHALLENGE_IMAGE_PATH}/${challenge_code}/${CHALLENGE_IMAGE_MAIN_SOURCE}`
}

export const getChallengeHeroThumb = (challenge_code) => {
    return `${process.env.PUBLIC_S3_ASSETS_URL}/${CHALLENGE_IMAGE_PATH}/${challenge_code}/${CHALLENGE_IMAGE_MAIN_THUMB}`
}

export const getChallengeKidImage = (challenge_code) => {
    return `${process.env.PUBLIC_S3_ASSETS_URL}/challenge-characters/char_${challenge_code}.png`
}

export const getChallengeBadgeUrl = (challenge_code) => {
    return `${process.env.PUBLIC_S3_ASSETS_URL}/badges/${challenge_code}.png`
}

export const getProjectExtensionHeroImage = (projectExtensionFileName) => {
    const fileName = projectExtensionFileName.split('/').pop().split('.').shift();
    return `${process.env.PUBLIC_S3_ASSETS_URL}/challenge-resources/project-extension-img/${fileName}.jpg`
}