const MATH_INPUT_BUTTONS_BASIC = [
    {
        key: 'plus',
        label: '+',
        action: '+'
    },
    {
        key: 'minus',
        label: '-',
        action: '-'
    },
    {
        key: 'times',
        label: '\\times',
        action: '\\times'
    },
    {
        key: 'divide',
        label: '\\div',
        action: '\\div'
    }
]

export const MATH_INPUT_BUTTON_SETS = {
    'square': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'square',
            label: 'a^2',
            action: (input) => {
                const contents = input.latex()
                if (contents) {
                    input.write('^2')
                }
            }
        }
    ],

    'algebra': [
        {
            key: 'power',
            label: 'a^b',
            action: (input) => {
                const contents = input.latex()
                if (contents) {
                    input.typedText('^')
                }
            }
        },
        {
            key: 'square_root',
            label: '\\sqrt{a}',
            action: '\\sqrt'
        },
        {
            key: 'fraction',
            label: '\\frac{a}{b}',
            action: (input) => {
                const contents = input.latex()
                input.typedText("/")
                if (input.latex() === contents) {
                    input.cmd("\\frac")
                }
            }
        },
        {
            key: 'subscript',
            label: 'a_b',
            action: (input) => {
                const contents = input.latex()
                if (contents) {
                    input.typedText("_")
                }
            }
        },
        {
            key: 'pi',
            label: '\\pi',
            action: '\\pi'
        }
    ],

    'binary': [
        {
            key: 'lt',
            label: '\\lt',
            action: '\\lt'
        },
        {
            key: 'gt',
            label: '\\gt',
            action: '\\gt'
        },
        {
            key: 'leq',
            label: '\\leq',
            action: '\\leq'
        },
        {
            key: 'geq',
            label: '\\geq',
            action: '\\geq'
        },
        {
            key: 'eq',
            label: '=',
            action: '='
        }
    ],

    'fraction': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'fraction',
            label: '\\frac{a}{b}',
            action: (input) => {
                const contents = input.latex()
                input.typedText("/")
                if (input.latex() === contents) {
                    input.cmd("\\frac")
                }
            }
        }
    ],

    'root': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'square_root',
            label: '\\sqrt{a}',
            action: '\\sqrt'
        }
    ],

    'exponent': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'power',
            label: 'a^b',
            action: (input) => {
                const contents = input.latex()
                if (contents) {
                    input.typedText('^')
                }
            }
        }
    ],

    'equal': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'eq',
            label: '=',
            action: '='
        }
    ],

    'subscript': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'subscript',
            label: 'a_b',
            action: (input) => {
                const contents = input.latex()
                if (contents) {
                    input.typedText("_")
                }
            }
        }
    ],

    'rational': [
        {
            key: 'plus',
            label: '+',
            action: '+'
        },
        {
            key: 'minus',
            label: '-',
            action: '-'
        },
        {
            key: 'times',
            label: '\\times',
            action: '\\times'
        },
        {
            key: 'fraction',
            label: '\\frac{a}{b}',
            action: (input) => {
                const contents = input.latex()
                input.typedText("/")
                if (input.latex() === contents) {
                    input.cmd("\\frac")
                }
            }
        },
        {
            key: 'power',
            label: 'a^b',
            action: (input) => {
                const contents = input.latex()
                if (contents) {
                    input.typedText('^')
                }
            }
        }
    ],

    'not_equal': [
        ...MATH_INPUT_BUTTONS_BASIC,
        {
            key: 'neq',
            label: '\\neq',
            action: '\\neq'
        }
    ]
}

export const MATH_INPUT_BUTTON_SETS_DEFAULT = [
    'square', 'algebra', 'binary'
]