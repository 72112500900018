import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import {getCardName} from 'utils/challenge/streamEntity';


function StudentInfo({stream}) {
    const lesson = useSelector(state => state.lesson);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);

    function renderName() {
        if (show_student) {
            return (
                <div className="overview-student-info-name">
                    {stream.user.full_name}
                </div>
            );
        }
    }

    function renderFlag() {
        if (stream.red_flag) {
            return (
                <div className="overview-student-info-flag flex-row flex-vh-center">
                    <i className="fas fa-flag"/>
                </div>
            );
        }
    }

    function renderProgressText() {
        return (
            <div>
                {getCardName(lesson.challenge_version, stream.index)}
            </div>
        );
    }

    return (
        <div className="overview-student-info flex-row flex-v-center">
            {renderName()}

            {renderFlag()}

            <div className="flex-auto"/>

            {renderProgressText()}
        </div>
    );
}

StudentInfo.propTypes = {
    stream: PropTypes.shape({
        index: PropTypes.number.isRequired,
        user: PropTypes.shape({
            full_name: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
}

export default StudentInfo
