import React from 'react';

export default function () {
    return (
        <div className='student-story-page page6'>
            <div className='contents'>
                <div className='title'>What are Math Practices?</div>
                <div className='practices'>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/ability_persist.png`}
                        alt='SMP1'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/ability_reason.png`}
                        alt='SMP2'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/ability_construct_critique.png`}
                        alt='SMP3'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/abliity_model.png`}
                        alt='SMP4'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/ability_use_tools.png`}
                        alt='SMP5'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/ability_work_precisely.png`}
                        alt='SMP6'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/ability_recognise_structure.png`}
                        alt='SMP7'/>
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/abliity_analyse.png`}
                        alt='SMP8'/>
                </div>
            </div>
        </div>
    );
}