import React from 'react';
import {map, isEqual} from 'lodash';
import classNames from 'classnames';
import {Checkbox} from "mindsets-js-sdk";

import BaseAnswer from './BaseAnswer';
import {processStringContent} from 'utils/content';


export default class ChoiceSingle extends BaseAnswer {
    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.existing_answer, prevProps.existing_answer)) {
            if (this.props.is_preview) {
                const answer = this.initAnswer()
                this.setState({answer})
            }
        }
    }

    isSelected(a) {
        return this.state.answer === a
    }

    isOptionCorrect(o) {
        return o === this.correctAnswer()
    }

    onChange(selected) {
        return () => {

            if (this.props.is_preview) {
                return
            }

            this.setState({
                answer: selected,
                auto_grading: false
            })
        }
    }

    renderOptions() {
        return map(this.props.component_data.data.options, (v, l) => {
            const is_selected = this.isSelected(l)
            const is_option_correct = this.isOptionCorrect(l)
            const is_answer_correct = this.isAnswerCorrect()
            const class_name = classNames('component-choice-option', {
                'component-choice-option-correct': this.state.auto_grading
                    && is_selected && is_option_correct,
                'component-choice-option-wrong': this.state.auto_grading
                    && is_selected && !is_option_correct,
                'component-choice-option-half-correct': this.state.auto_grading
                    && is_selected && is_option_correct && !is_answer_correct
            })
            const option_content = processStringContent(v, {
                className: "flex-row flex-v-center"
            })
            return (
                <div key={l} className={class_name}>
                    <Checkbox
                        toggle={this.onChange(l)}
                        checked={this.isSelected(l)}
                        label={option_content}
                    />
                </div>
            );
        })
    }

    render() {
        return (
            <div className="challenge-component-choice">
                <div className="flex-row flex-h-start">
                    <div className="flex-column flex-box-5-10">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        )
    }
}
