import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MindsetsJsSDK, {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);

    function fetchData() {
        Promise.resolve()
            .then(() => dispatch(LoadingAction(true)))
            .then(() => MindsetsJsSDK().API
                .get('sam/overall_engagement', {
                    school_id: selected_school_id ?? '',
                    challenge_code_id: selected_challenge_id ?? '',
                    from,
                    till
                })
                .then(response => {
                    setItems(response.items);
                    setCount(Object.values(response.items).reduce((a, b) => a + b, 0));
                })
            )
            .then(() => dispatch(LoadingAction(false)))
            .catch(error => {
                console.error(error);
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
            });
    }

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        fetchData();
    }, [from, till, selected_school_id, selected_challenge_id]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'bar'
                },
                title: {
                    text: 'Student Engagement'
                },
                xAxis: {
                    title: null,
                    categories: ['Bonus Completed', 'Completed', 'Nearly Completed', 'Underway', 'Started']
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Student %'
                    },
                    labels: {
                        formatter: function () {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                    crosshairs: true
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y + '%</b>';
                            }
                        }
                    }
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                series: [
                    {
                        showInLegend: false,
                        data: [
                            items['completed_bonus'] ? Math.round(items['completed_bonus'] * 100 / count) : 0,
                            items['completed'] ? Math.round(items['completed'] * 100 / count) : 0,
                            items['nearly'] ? Math.round(items['nearly'] * 100 / count) : 0,
                            items['underway'] ? Math.round(items['underway'] * 100 / count) : 0,
                            items['stuck'] ? Math.round(items['stuck'] * 100 / count) : 0
                        ],
                        colorByPoint: true,
                        colors: ['#4286F4', '#02FF01', '#D99BD9', '#F5BE1E', '#FF6D02']
                    }
                ],
                credits: {
                    enabled: false
                }
            }}
        />
    );
}
