import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {map, filter, get} from 'lodash'

import PollBlockOption from './PollBlockOption'
import ScrollReminder from './ScrollReminder'


class PollBlockOptions extends Component {
    renderOptions() {
        return this.props.poll.poll_options.map((o, i) => {
            const onClick = () => {
                if (!this.props.is_preview && this.props.lesson_id) {
                    this.props.submitVote(o)
                }
            }

            return (
                <div className="" key={i} onClick={onClick}>
                    <PollBlockOption
                        poll_option={o}
                        is_selected={this.props.my_vote === o.id}
                    />
                </div>
            )
        })
    }

    renderFilling() {
        const n = Math.ceil(this.props.poll.poll_options.length / 12) * 12
        const array_to_fill = Array(n - this.props.poll.poll_options.length)
        return map(array_to_fill, (v, k) => {
            return (
                <div className="poll-block-options-filling" key={`filling-${k}`}>
                    <div className="poll-option">
                        <div className="poll-option-image-container">
                            <img src={get(this.props.poll.poll_options, '0.option')} />
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className="poll-block-options">
                <div className="flex-row flex-between flex-wrap flex-box-15-15">
                    {this.renderOptions()}
                    {this.renderFilling()}
                </div>

                <ScrollReminder
                    show_graph={this.props.show_graph}
                />
            </div>
        )
    }
}

PollBlockOptions.propTypes = {
    poll: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    is_preview: PropTypes.bool,
    my_vote: PropTypes.number,
    submitVote: PropTypes.func.isRequired,
    show_graph: PropTypes.bool.isRequired
}

export default PollBlockOptions
