import React, {useEffect, useCallback, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import MindsetsJsSDK from "mindsets-js-sdk";
import {debounce} from 'lodash';
import classNames from 'classnames';

import analytic from 'utils/Analytic';
import {SetDashboardDataAction} from '../../../../actions/performance_actions';
import {FILTER_1} from "../../../../reducers/performance_reducers";
import PrintableHeader from './PrintableHeader';
import SkillGraph from './SkillGraph';
import CategoryGraph from './CategoryGraph';
import DomainGraph from './DomainGraph';
import SkillTable from './SkillTable';
import DomainTable from './DomainTable';
import Placeholder from './Placeholder';
import Filters from "../../Filters";

import "./pam_template.scss";
import "./pam_template_print.scss";

export default function ({dashboard_id, printable}) {
    const dispatch = useDispatch();
    const [search_params, setSearchParams] = useSearchParams();

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);
    const dashboard_filters = useSelector(state => state.dashboard_filters);
    const string = JSON.stringify(dashboard_filters[FILTER_1]);
    const mounted = useRef(false);
    let state = '';
    if (dashboard_id.includes('demo')) {
        if (dashboard_id === 'mindsets_demo') {
            state = 'MINDSETS';
        } else if (search_params.has('state')) {
            state = search_params.get('state');
        }
        dashboard_id = 0;
    }

    function loadAllData(dashboard_id, dashboard_filters) {
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/skills`, {...dashboard_filters, state}).then((response) => {
            dispatch(SetDashboardDataAction({skill_data: response}));
        });
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/categories`, {...dashboard_filters, state}).then((response) => {
            dispatch(SetDashboardDataAction({category_data: response}));
        });
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/domains`, {...dashboard_filters, state}).then((response) => {
            dispatch(SetDashboardDataAction({domain_data: response}));
        });
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data`, {current: 1, ...dashboard_filters, state}).then((response) => {
            dispatch(SetDashboardDataAction({data_skill_table: response}));
        });
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data-domain`, {current: 1, ...dashboard_filters, state}).then((response) => {
            dispatch(SetDashboardDataAction({data_domain_table: response}));
        });

        analytic.log('insightsLoadData', {
            dashboard_id,
            dashboard_title: dashboard.title,
            project_id: dashboard.project.id,
            project_name: dashboard.project.name,
            dashboard_filters: dashboard_filters
        });
    }

    const debounceLoadAllData = useCallback(debounce(loadAllData, 1500), []);

    useEffect(() => {
        if (!printable) {
            loadAllData(dashboard_id, dashboard_filters[FILTER_1]);
        }
    }, []);

    useEffect(() => {
        if (mounted.current) {
            if (!printable) {
                debounceLoadAllData(dashboard_id, dashboard_filters[FILTER_1]);
            }
        } else {
            mounted.current = true;
        }
    }, [string]);

    //-----------------------------------------------------
    // Skill table
    const debounceLoadSkillTableData = useCallback(
        debounce((dashboard_id, dashboard_filters, page) => {
            const current = page ? page : 1;
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data`, {current, ...dashboard_filters, state}).then((response) => {
                dispatch(SetDashboardDataAction({data_skill_table: response}));
            });
        }, 1000),
        []
    );

    const selectSkillTablePage = (page) => {
        if (dashboard_data.data_skill_table.current == page) {
            return false;
        }
        debounceLoadSkillTableData(dashboard_id, dashboard_filters[FILTER_1], page);
    }
    //-----------------------------------------------------

    //-----------------------------------------------------
    // Domain table
    const debounceLoadDomainTableData = useCallback(
        debounce((dashboard_id, dashboard_filters, page) => {
            const current = page ? page : 1;
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data-domain`, {current, ...dashboard_filters, state}).then((response) => {
                dispatch(SetDashboardDataAction({data_domain_table: response}));
            });
        }, 1000),
        []
    );

    const selectDomainTablePage = (page) => {
        if (dashboard_data.data_domain_table.current == page) {
            return false;
        }
        debounceLoadDomainTableData(dashboard_id, dashboard_filters[FILTER_1], page);
    }
    //-----------------------------------------------------

    if (printable && !dashboard_data.skill_data) {
        return (
            <div>
                {`Missing data. Go back to the Insights Dashboard and start again.`}
            </div>
        );
    }

    const class_name = classNames('pam-template', {
        'pam-template-printable': printable
    });

    const renderFilter = () => {
        if (printable) {
            return null;
        }

        return (
            <Filters id={FILTER_1}/>
        );
    }

    return (
        <div>
            {renderFilter()}
            <div id="pam-template-type-a" className={class_name}>
                <PrintableHeader
                    printable={printable}
                />

                <div className="flex-row flex-gap-10 flex-wrap">
                    <SkillGraph
                        printable={printable}
                    />

                    <CategoryGraph
                        printable={printable}
                    />
                </div>

                <div className="flex-row flex-gap-10 flex-wrap">
                    <DomainGraph
                        printable={printable}
                    />

                    <Placeholder
                    />
                </div>

                <div>
                    <SkillTable
                        selectPage={selectSkillTablePage}
                        printable={printable}
                    />
                </div>

                <div>
                    <DomainTable
                        selectPage={selectDomainTablePage}
                        printable={printable}
                    />
                </div>
            </div>
        </div>
    );
}
