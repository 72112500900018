import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {filter} from 'lodash'

import PollInlineOption from './PollInlineOption'


class PollInlineOptions extends Component {
    renderOptions() {
        return this.props.poll.poll_options.map((o, i) => {
            const onClick = () => {
                this.props.submitVote(o)
            }

            return (
                <div className="" key={o.id} onClick={onClick}>
                    <PollInlineOption
                        poll_option={o}
                        is_selected={this.props.my_vote === o.id}
                        show_graph={this.props.show_graph}
                        option_votes={filter(this.props.lesson_votes, {poll_option_id: o.id}).length}
                        total_votes={this.props.lesson_votes.length}
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <div className="poll-inline-options">
                <div className="flex-row flex-h-start">
                    <div className="flex-column flex-box-5-10">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        )
    }
}

PollInlineOptions.propTypes = {
    poll: PropTypes.object.isRequired,
    is_preview: PropTypes.bool,
    my_vote: PropTypes.number,
    submitVote: PropTypes.func.isRequired,
    lesson_votes: PropTypes.array.isRequired,
    show_graph: PropTypes.bool.isRequired
}

export default PollInlineOptions
