import React from 'react';
import PropTypes from 'prop-types';

import Google from "./Google";
import Microsoft from "./Microsoft";
import Clever from "./Clever";


function SSO({persona, callback}) {
    return (
        <div className="login-sso flex-column flex-box-5-10 flex-vh-center">
            <div>
                <div className="sso-button">
                    <Google
                        persona={persona}
                        callback={callback}
                    />
                </div>
            </div>

            <div>
                <div className="sso-button">
                    <Microsoft
                        persona={persona}
                        callback={callback}
                    />
                </div>
            </div>

            <div>
                <div className="sso-button">
                    <Clever/>
                </div>
            </div>
        </div>
    )
}

SSO.propTypes = {
    persona: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
};

export default SSO
