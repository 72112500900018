import React, {useState} from 'react';
import $ from 'jquery';

import useAuth from "../../hooks/useAuth";

export default function () {
    useAuth(['teacher']);
    const GOOGLE_FORM_URL = `https://docs.google.com/forms/d/1Z03ZkBTLeUEETkFwqi7K-gAxG2sYrMrD2OpsoP4Gwy8/viewform?embedded=true`;
    const [counter, setCounter] = useState(0);

    function count() {
        setCounter(counter + 1);
        if (counter === 2) {
            $('iframe').css('min-height', '250px');
        }
    }

    return (
        <div id="teacher-contact-us">
            <div className="container">
                <div className="section">
                    <h1 className="header-new">{`Contact Us`}</h1>
                </div>

                <div className="section">
                    <div className="flex-row flex-v-center flex-box-1-2">
                        <div>
                            {`Just starting? View our `}
                        </div>
                        <a
                            className="link-new"
                            href={`${process.env.PUBLIC_S3_ASSETS_URL}/resources/class_setup.pdf`}
                            target="_blank">
                            {`Easy Setup PDF Guide.`}
                        </a>
                    </div>

                    <div className="section">
                        <div className="flex-row flex-v-center flex-box-1-2">
                            <div>
                                {`How to use `}
                            </div>
                            <a
                                className="link-new"
                                href={`${process.env.PUBLIC_S3_ASSETS_URL}/resources/open-reader-guide-mindsets!.pdf`}
                                target="_blank">
                                {`Open Reader PDF Guide.`}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="flex-row flex-v-center flex-box-1-2">
                        <div>
                            {`If you would like to get a quote for your school/district, please `}
                        </div>
                        <a
                            className="link-new"
                            href="https://docs.google.com/forms/d/1Z03ZkBTLeUEETkFwqi7K-gAxG2sYrMrD2OpsoP4Gwy8/viewform"
                            target='_blank'>
                            {`click here.`}
                        </a>
                    </div>
                </div>

                <div className="divider"/>

                <div className="section">
                    <div>
                        {`For any other assistance or feedback, fill in the below form:`}
                    </div>

                    <div className="flex-row flex-v-center flex-box-1-2">
                        <div>
                            {`Can't see the form? `}
                        </div>
                        <a className="link-new" href={GOOGLE_FORM_URL} target="_blank">
                            {`Click here.`}
                        </a>
                    </div>

                    <div className="teacher-contact-us-iframe">
                        <iframe
                            src={GOOGLE_FORM_URL}
                            frameBorder="0"
                            marginHeight="0"
                            marginWidth="0"
                            onLoad={count}
                        >
                            Loading...
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}