import React from 'react';


export default function () {
    return (
        <div>
            <h3 className="header-new">
                {`Module 5: How to extend the Mindsets Challenge experience`}
            </h3>

            <br/>

            <div
                dangerouslySetInnerHTML={{__html: `<div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/btvcrjb2xz?videoFoam=true" title="Extras 1 Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div><script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`}}>
            </div>

            <br/>

            <h4 className="header-new">
                {`Gold standard practices to extend the experience`}
            </h4>
            <div>
                {`Here is a list of gold standard practices to extend the experience. `}
                {`Note that as with any new lesson or tool, your creativity will improve over time with practice. `}
                {`Students will also become familiar with the structure, expectations, and possibilities of Challenges.`}
            </div>
            <div>
                <br/>
                <img
                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/gold_practice_3.png`}
                    alt="A graphic showing 2 tips that can be implemented post Challenge."
                    title="A graphic showing 2 tips that can be implemented post Challenge."
                />
            </div>

            <div className="section">
                <div className="divider"></div>
            </div>

            <h4 className="header-new">
                {`Reflection:`}
            </h4>
            <div>
                <strong>
                    {`Using the Challenge you previously selected, decide on a potential project extension.`}
                </strong>
            </div>
        </div>
    );
}
