import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class MathInputButton extends Component {
    renderLabel() {
        return processStringContent(`\\( ${this.props.label} \\)`)
    }

    renderLayer() {
        return (
            <div className="math-input-button-layer" onClick={this.props.onClick}>
            </div>
        )
    }

    render() {
        return (
            <div className="math-input-button flex-row flex-vh-center">
                {this.renderLabel()}

                {this.renderLayer()}
            </div>
        )
    }
}

MathInputButton.propTypes = {
    label: PropTypes.string.isRequired,
    action: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]).isRequired,
    onClick: PropTypes.func.isRequired
}

export default MathInputButton
