import React from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {PublicClientApplication} from "@azure/msal-browser";
import {LoadingAction, rolesConstants, ErrorMessageAction} from 'mindsets-js-sdk';

import {LogInProviderAction} from "../../../actions/login_actions";

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: process.env.PUBLIC_OFFICE_OAUTH_ID
    }
});
await msalInstance.initialize();

function Microsoft({persona, callback}) {
    const dispatch = useDispatch();

    function loginCallback(loginResponse) {
        dispatch(LogInProviderAction('office', {
            id_token: loginResponse.idToken,
            persona
        })).then(callback);
    }

    function onClick(event) {
        event.preventDefault();

        msalInstance
            .loginPopup({scopes: ["user.read"]})
            .then(loginCallback)
            .catch(function (error) {
                dispatch(LoadingAction(false));
                console.error(error);
                dispatch(ErrorMessageAction('Sorry. There was a problem with your request. Please reload the page and try again or come back later.'));
            });
    }

    return (
        <button className="sso-button-inner flex-row flex-v-center" id='office-sso-button' onClick={onClick}>
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_microsoft.svg`} alt='with microsoft'/>
            <div className="flex-auto center-align sso-button-label">
                {`Sign in with Microsoft`}
            </div>
        </button>
    );
}

Microsoft.propTypes = {
    persona: PropTypes.oneOf([rolesConstants.ROLE_TEACHER, rolesConstants.ROLE_STUDENT]).isRequired,
    callback: PropTypes.func.isRequired
};

export default Microsoft;
