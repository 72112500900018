import React from 'react'

import StudentWelcome from './StudentWelcome'
import StudentJoin from './StudentJoin'
import OpenLessons from './OpenLessons'
import MyChallenges from './MyChallenges'
import useAuth from "../../hooks/useAuth";


export default function () {
    useAuth(['student']);

    return (
        <div id="student-dashboard">
            <div className="container">
                <div className="section">
                    <div className="flex-row flex-vh-center">
                        <StudentWelcome/>
                    </div>
                </div>

                <div className="section">
                    <StudentJoin/>
                </div>

                <hr className="separator bigger"/>

                <OpenLessons/>

                <MyChallenges/>
            </div>
        </div>
    );
}
