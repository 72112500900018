import {
    SET_STUDENT_STREAM_CURRENT_INDEX,
    SET_STUDENT_STREAM_STREAM_ENTITIES_RESPONSES,
    SET_STREAM
} from "../actions/stream_actions";

const student_stream_current_index = (payload = 0, action) => {
    switch (action.type) {
        case SET_STUDENT_STREAM_CURRENT_INDEX:
            return action.payload;
        default:
            return payload;
    }
};
const student_stream_stream_entities_responses = (payload = [], action) => {
    switch (action.type) {
        case SET_STUDENT_STREAM_STREAM_ENTITIES_RESPONSES:
            return action.payload;
        default:
            return payload;
    }
};
const stream = (payload = null, action) => {
    switch (action.type) {
        case SET_STREAM:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    student_stream_current_index,
    student_stream_stream_entities_responses,
    stream
}
