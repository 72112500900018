import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import AWS from 'aws-sdk';

import analytic from 'utils/Analytic';

const S3_BUCKET = process.env.PUBLIC_AWS_S3_BUCKET_NAME_WOW_MOMENTS;
const REGION = process.env.PUBLIC_AWS_GUEST_REGION;

export default (props) => {

    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const imgSrc = get(props.img, 'src', null);

    // Auto upload file after selected from local system
    useEffect(() => {
        if (selectedFile) {
            uploadFile(selectedFile);
        }
    }, [selectedFile])

    // Load image a while after upload, make sure the image is ready from S3
    useEffect(() => {
        if (selectedFile && progress >= 100) {
            setTimeout(() => {
                props.updateContent({
                    [props.attrName]: {
                        src: getFileName(true)
                    }
                });
            }, 3000);
        }
    }, [selectedFile, progress])

    const getFileName = (fullUrl = false) => {
        const fileExtension = selectedFile.name.split('.').pop();
        const fileName = `${props.wowMomentCode}-${props.attrName}.${fileExtension}`;
        if (fullUrl) {
            return `https://s3.amazonaws.com/${S3_BUCKET}/${fileName}`;
        } else {
            return fileName;
        }
    }

    const handleFileInput = (e) => {
        if (e.target.files[0].size > 5000000) {
            alert('Reduce the image size before uploading. Maximum: 5MB.');
        } else {
            setSelectedFile(e.target.files[0]);

            analytic.log(
                'wowMomentsUploadPhoto',
                props.wowMomentCode,
                props.attrName
            );
        }
    }

    const uploadFile = (file) => {
        if (!file) {
            alert(`Please select an image file to upload.`);
            return false;
        }

        const myBucket = new AWS.S3({
            params: {Bucket: S3_BUCKET},
            region: REGION,
        })

        const params = {
            // ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: getFileName()
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .promise()
            .then((data) => {
            })
            .catch((err) => {
                console.error(err);
            })
            // .send((err) => {
            //     if (err) {
            //         console.error(err)
            //     }
            // })
    }

    const onRemove = () => {
        setProgress(0);
        setSelectedFile(null);
        props.updateContent({
            [props.attrName]: undefined
        });
    }

    const getContainerStyle = () => {
        return {
            backgroundImage: imgSrc ? `url(${imgSrc})` : null,
            backgroundPositionX: props.positionX,
            backgroundPositionY: props.positionY,
            ...props.containerStyle
        }
    }

    return (
        <div className="wow-moment-photo" style={getContainerStyle()}>
            <DisplayUpload
                showUpload={!imgSrc}
                title={props.title}
                progress={progress}
                handleFileInput={handleFileInput}
            />

            <DisplayRemove
                showRemove={!!imgSrc}
                onRemove={onRemove}
            />
        </div>
    )
}


const UploadProgress = (props) => {
    if (!props.showProgress) {
        return null;
    }

    return (
        <div className="wm-photo-progress">
            <div className="flex-row">
                <div className="wm-photo-progress-done" style={{flex: props.progress}}></div>
                <div className="wm-photo-progress-rest" style={{flex: 100 - props.progress}}></div>
            </div>
            <div className="center-align">
                {`${props.progress}%`}
            </div>
        </div>
    )
}


const DisplayUpload = (props) => {

    const uploadButton = useRef();

    if (!props.showUpload) {
        return null;
    }

    return (
        <div className="wm-photo-upload flex-column flex-v-center">
            <div className="wm-photo-upload-hidden">
                <input type="file" accept="image/jpeg, image/png" ref={uploadButton} onChange={props.handleFileInput} />
            </div>

            <div>
                {props.title}
            </div>

            <div className="flex-auto flex-row flex-v-center">
                <div>
                    <div>
                        <button className="btn btn-on-white" onClick={() => uploadButton.current.click()}>
                            <i className="far fa-image"></i>
                            <span>{`Upload`}</span>
                        </button>
                    </div>

                    <UploadProgress
                        showProgress={props.showUpload && props.progress > 0}
                        progress={props.progress}
                    />
                </div>
            </div>
        </div>
    )
}


const DisplayRemove = (props) => {
    if (!props.showRemove) {
        return null;
    }

    return (
        <i
            className="wm-photo-remove fas fa-trash-alt"
            onClick={props.onRemove}
        >
        </i>
    )
}