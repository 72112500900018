import React, {useState} from 'react';
import classNames from 'classnames';
import {get} from 'lodash';

import {CLASS_SHARE_SELECTIONS} from './selections';


export default function () {
    const [selected, setSelected] = useState(0);

    function onSelect(selected) {
        setSelected(selected);
    }

    function renderSelections() {
        return CLASS_SHARE_SELECTIONS.map((s, k) => {
            const class_name = classNames(
                'class-share-container-selection',
                'flex-1',
                {
                    'class-share-container-selection-selected': selected === k
                }
            );
            return (
                <div
                    className={class_name}
                    key={k}
                    onClick={() => onSelect(k)}
                >
                    <div className="class-share-container-selection-icon">
                        {s.icon}
                    </div>
                    <div className="class-share-container-selection-title">
                        {s.title}
                    </div>
                </div>
            );
        });
    }

    function renderContent() {
        if (selected === undefined) {
            return null
        }

        return CLASS_SHARE_SELECTIONS.map((s, k) => {
            const ContentComponent = get(s, 'ContentComponent')
            const class_name = classNames('class-share-content-option', {
                'class-share-content-option-active': selected === k
            })
            return (
                <div className={class_name} key={s.key}>
                    <ContentComponent/>
                </div>
            );
        });
    }

    return (
        <div className="setup-class-share">
            <h4 className="header-new">
                {`For my students I will..`}
            </h4>

            <div className="section">
                <div className="class-share-container">
                    <div className="class-share-selections-container flex-row">
                        {renderSelections()}
                    </div>

                    <div className="class-share-content-container">
                        {renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
}
