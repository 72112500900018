import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import './sticky.scss';


function Sticky({style = {top: '0', left: '0', right: '0'}, children}) {
    const ref = useRef();

    function onScroll() {
        if (window.pageYOffset > ref.current.offsetTop) {
            ref.current.classList.add("sticky-div")
            ref.current.classList.remove("not-sticky-div")
        } else {
            ref.current.classList.remove("sticky-div")
            ref.current.classList.add("not-sticky-div")
        }
    }

    useEffect(() => {
        window.onscroll = onScroll;
    }, []);

    return (
        <div className="common-ui-sticky not-sticky-div" ref={ref} style={style}>
            {children}
        </div>
    )
}

Sticky.propTypes = {
    style: PropTypes.object
}

export default Sticky
