import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {map} from 'lodash';
import {Toggle} from "mindsets-js-sdk";

import {computeStudentSkillData, getUniqSkills} from './utils';
import ZoomTool from 'common/ZoomTool';
import TableHeader from './TableHeader';
import StudentRow from './StudentRow';
import {SetPAMShowScoreAction} from "../../../../actions/pam_actions";

export default function () {
    const dispatch = useDispatch();
    const skill_structure_data = useSelector(state => state.pam_challenge_skill_structure);
    const student_skill_data = useSelector(state => state.pam_student_skill_data);
    const mode = useSelector(state => state.pam_mode);
    const zoom = useSelector(state => state.pam_zoom);
    const show_score = useSelector(state => state.pam_show_score);

    function renderScoreToggle() {
        const onChange = () => {
            dispatch(SetPAMShowScoreAction(!show_score));
        }

        return (
            <div className="flex-row flex-v-center">
                <div>
                    {`Show Scores`}
                </div>

                <Toggle onChange={onChange} is_on={show_score}/>
            </div>
        )
    }

    function renderTable() {
        const uniq_skills = getUniqSkills(student_skill_data);
        const student_skill_data_processed = computeStudentSkillData(student_skill_data, skill_structure_data, mode);

        const render_students = map(student_skill_data_processed, ((student_data, student_id) => {
            return (
                <StudentRow
                    key={student_id}
                    student_data={student_data}
                    uniq_skills={uniq_skills}
                />
            );
        }));

        return (
            <div className="p-c-st-container">
                <table style={{zoom: zoom / 100}}>
                    <TableHeader
                        skills_data={uniq_skills}
                    />
                    <tbody>
                    {render_students}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div>
            <h5 className="header-new center-align">
                {`What are students' Strengths and Gaps?`}
            </h5>

            <div className="pam-challenge-studenttable">
                <div className="p-c-st-tool-bar">
                    <div className="flex-row flex-v-center flex-h-end flex-box-10-20">
                        <div>
                            <ZoomTool step={10}/>
                        </div>
                        <div>
                            {renderScoreToggle()}
                        </div>
                    </div>
                </div>

                {renderTable()}
            </div>
        </div>
    );
}
