import React from 'react';
import {CustomToast, Loader} from "mindsets-js-sdk";
import {Outlet} from "react-router-dom";

import Footer from './Footer';

export default function () {
    return (
        <>
            <CustomToast/>
            <main>
                <Outlet/>
            </main>
            <footer className="page-footer">
                <Footer/>
            </footer>
            <Loader/>
        </>
    );
}
