import React from 'react'
import {isEmpty, remove, cloneDeep, orderBy, find, get} from 'lodash'

import BaseAnswer from '../BaseAnswer'
import MatchingInstruction from './MatchingInstruction'
import MatchingItem from './MatchingItem'
import MatchingLine from './MatchingLine'

const MATCH_COLORS = [
    undefined, '#731E67', '#FF5907', '#2051A3', '#008C12', '#FD4596', '#FCB729', '#02A299', '#4F2E1B'
]


export default class Matching extends BaseAnswer {
    defaultAnswer() {
        return {
            text: '',
            answer_selections: []
        }
    }

    isValid() {
        return !isEmpty(this.state.answer.answer_selections)
    }

    getAnswerText = (answer_selections) => {
        if (isEmpty(answer_selections)) {
            return ''
        }

        const {items_left, items_right} = this.props.component_data.data

        const answer_text_array = answer_selections.map((a_s) => {
            const [id_left, id_right] = a_s
            const item_left = find(items_left.items, {id: id_left})
            const item_right = find(items_right.items, {id: id_right})
            return `${item_left.text} --- ${item_right.text}`
        })

        return answer_text_array.join('\n')
    }

    getMatchColor = (group) => (id) => {
        const match_item = find(this.state.answer.answer_selections, (a_s) => {
            const [id_left, id_right] = a_s
            if (group === 'left') {
                return id_left === id
            } else if (group === 'right') {
                return id_right === id
            } else {
                return false
            }
        })
        const key = get(match_item, 0)
        if (!key) {
            return undefined
        } else {
            return get(MATCH_COLORS, key)
        }
    }

    onClickItem = (group) => (id) => () => {
        if (this.props.is_preview) {
            return false
        }

        const state_name = `selection_${group}`
        if (this.state[state_name] === id) {
            this.setState({
                [state_name]: undefined
            })
        } else {
            this.setState({
                [state_name]: id
            }, () => {
                setTimeout(this.linkItems, 300)
            })
        }
    }

    linkItems = () => {
        if (this.props.is_preview) {
            return false
        }

        const {selection_left, selection_right} = this.state
        if (!selection_left || !selection_right) {
            return false
        }

        // Clone existing answer
        const answer_clone = cloneDeep(this.state.answer)
        // Remove exisitng if overlap
        remove(answer_clone.answer_selections, ((a_s) => {
            const [id_left, id_right] = a_s
            return (id_left === selection_left) || (id_right === selection_right)
        }))
        // Add new selection pair
        answer_clone.answer_selections.push([selection_left, selection_right])
        // Sort
        answer_clone.answer_selections = orderBy(answer_clone.answer_selections, (a_s) => a_s[0])
        // Compute answer text
        answer_clone.text = this.getAnswerText(answer_clone.answer_selections)

        this.setState({
            answer: answer_clone
        }, () => {
            // Clear tmp selection pair
            this.setState({
                selection_left: undefined,
                selection_right: undefined
            })
        })
    }

    // This function is a solution (temporary) to the problem
    // where the lines are drawn before the images are fully loaded
    // and the lines become misplaced.
    // This function re-renders the component when the count of ready items
    // === to the total number of items. This happens only one time.
    itemReady = () => {
        if (this.ready_items === undefined) {
            this.ready_items = 0
        }
        this.ready_items++

        const {items_left, items_right} = this.props.component_data.data
        if (this.ready_items === items_left.items.length + items_right.items.length) {
            this.setState({})
        }
    }

    renderLeft() {
        const items_left = this.props.component_data.data.items_left
        const header_name = items_left.header_name
        const all_matched_left = this.state.answer.answer_selections.map((a_s) => a_s[0])
        const render_items_left = items_left.items.map((item_data) => {
            return (
                <MatchingItem
                    key={item_data.id}
                    item_data={item_data}
                    item_group="left"
                    is_active={this.state.selection_left === item_data.id}
                    is_matched={all_matched_left.includes(item_data.id)}
                    match_color={this.getMatchColor('left')(item_data.id)}
                    onClick={this.onClickItem('left')(item_data.id)}
                    itemReady={this.itemReady}
                />
            )
        })
        return (
            <div className="cc-matching-group cc-matching-group-left flex-column flex-v-center">
                <div className="cc-matching-group-header">
                    {header_name}
                </div>
                <div className="flex-auto flex-column flex-evenly">
                    {render_items_left}
                </div>
            </div>
        )
    }

    renderRight() {
        const items_right = this.props.component_data.data.items_right
        const header_name = items_right.header_name
        const all_matched_right = this.state.answer.answer_selections.map((a_s) => a_s[1])
        const render_items_right = items_right.items.map((item_data) => {
            return (
                <MatchingItem
                    key={item_data.id}
                    item_data={item_data}
                    item_group="right"
                    is_active={this.state.selection_right === item_data.id}
                    is_matched={all_matched_right.includes(item_data.id)}
                    match_color={this.getMatchColor('right')(item_data.id)}
                    onClick={this.onClickItem('right')(item_data.id)}
                    itemReady={this.itemReady}
                />
            )
        })
        return (
            <div className="cc-matching-group cc-matching-group-right flex-column flex-v-center">
                <div className="cc-matching-group-header">
                    {header_name}
                </div>
                <div className="flex-auto flex-column flex-evenly">
                    {render_items_right}
                </div>
            </div>
        )
    }

    renderLines() {
        const render_lines = this.state.answer.answer_selections.map((pair) => {
            const [id_left, id_right] = pair
            return (
                <MatchingLine
                    key={id_left}
                    start_id={`matching-left-${id_left}`}
                    end_id={`matching-right-${id_right}`}
                    match_color={this.getMatchColor('left')(id_left)}
                />
            )
        })

        return (
            <div className="cc-matching-lines">
                {render_lines}
            </div>
        )
    }

    renderClear() {
        const onClick = () => {
            this.setState({
                answer: this.defaultAnswer(),
                selection_left: undefined,
                selection_right: undefined
            })
        }

        return (
            <div className="cc-matching-clear flex-row flex-h-center">
                <button className="btn btn-on-white" onClick={onClick}>
                    {`Clear selections`}
                </button>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-matching">
                <MatchingInstruction
                    component_data={this.props.component_data}
                />

                {this.renderClear()}

                <div className="flex-row flex-between">
                    {this.renderLeft()}

                    {this.renderRight()}
                </div>

                {this.renderLines()}
            </div>
        )
    }
}
