import {ErrorMessageAction, LoadingAction} from "mindsets-js-sdk";
import moment from "moment/moment";

import {store} from './index'

import {getBrowserDetails} from "utils";

function report(error) {
    let report = true;
    if (error === 'Unauthenticated') {
        report = false;
    }
    if (error.includes('Could not find a class with code:')) {
        report = false;
    }
    if (error.includes('The email must be a valid email address')) {
        report = false;
    }
    if (error.includes('Invalid role used')) {
        report = false;
    }
    if (error.includes('These credentials do not match our records')) {
        report = false;
    }
    if (error.includes('Incorrect email or password')) {
        report = false;
    }
    if (error.includes('Security code is invalid')) {
        report = false;
    }
    if (error.includes('Account with such an email address already exists')) {
        report = false;
    }
    if (error.includes('The email field is required')) {
        report = false;
    }
    if (error.includes('The password field is required')) {
        report = false;
    }
    if (error.includes('There is no account with that email address')) {
        report = false;
    }

    if (process.env.PUBLIC_LOG_ERROR_TO_SLACK && report) {
        const me = store.getState().me;
        try {
            const details = getBrowserDetails();
            let text = `GO ERROR(${process.env.PUBLIC_APP_ENV})\nOn: ${moment().format('DD MMM YYYY HH:mm:ss')}\nUID: ${me ? me.id : 0}\nURI: ${window.location.pathname}${window.location.search}`;
            text += `\nName: ${details.name}\nApp Name: ${details.app_name}\nVersion: ${details.version}\nUser Agent: ${details.user_agent}`
            if (typeof error === 'object' && Array.isArray(Object.keys(error)) && Object.keys(error).length > 0) {
                Object.keys(error).map(key => {
                    text += "\nError: " + key + ': ' + error[key];
                });
            } else {
                text += "\n" + error.toString();
            }
            text += `\nLast Events:\n`;
            let last_events = JSON.parse(JSON.stringify(store.getState().last_events));
            last_events.reverse().map((event, index) => {
                text += `    ${index}. `;
                Object.keys(event).map(key => {
                    text += `${key}: ${event[key]}. `;
                });
                text += `\n`;
            });
            fetch(process.env.PUBLIC_SLACK_ERROR_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: JSON.stringify({text})
            }).catch(error => console.error('Failed sending error to Slack(fetch).', error));
        } catch (e) {
            console.error('Failed sending error to Slack.', e);
        }
    }
}

export default store => next => action => {
    try {
        return Promise.resolve(next(action)).catch(error => {
            console.error('Caught a Promise exception!', error);
            store.dispatch(LoadingAction(false));
            store.dispatch(ErrorMessageAction(error.toString(), true));

            report(error);

            throw error;
        });
    } catch (error) {
        store.dispatch(LoadingAction(false));
        console.error('Caught an exception!', error);
        throw error;
    }
}