import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SetTeacherFacilitationSelectedCardIndexAction} from "mindsets-js-sdk";

import ToolBar from './ToolBar';
import QuestionContainer from './QuestionContainer';
import Streams from './Streams';


export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const question_fullscreen = useSelector(state => state.teacher_facilitation_question_fullscreen);
    const mobile_show_streams = useSelector(state => state.teacher_facilitation_mobile_show_streams);

    function renderLeft() {
        const extra_class_name = (question_fullscreen ? ' byquestion-left-fullscreen' : '')
        return (
            <div className={"byquestion-left flex-1 " + extra_class_name}>
                <ToolBar/>

                <div className={(mobile_show_streams ? 'hide-on-small-only' : '')}>
                    <QuestionContainer/>
                </div>
            </div>
        );
    }

    function renderRight() {
        const extra_class_name =
            (question_fullscreen ? ' hide-on-large-only' : '') +
            (mobile_show_streams ? '' : ' hide-on-small-only');
        return (
            <div className={"byquestion-right flex-1 " + extra_class_name}>
                <Streams/>
            </div>
        );
    }

    useEffect(() => {
        if (lesson.mode === 'teacher') {
            dispatch(SetTeacherFacilitationSelectedCardIndexAction(lesson.index));
        }
    }, []);

    return (
        <div className="teacher-facilitation-byquestion">
            <div className="container">
                <div className="section">
                    <div className="flex-row-l">
                        {renderLeft()}

                        {renderRight()}
                    </div>
                </div>
            </div>
        </div>
    );
}
