import React, {useEffect} from 'react';
import {ErrorMessageAction} from "mindsets-js-sdk";
import {useDispatch, useSelector} from "react-redux";
import {
    LoadedPamPracticesAction,
    LoadPamPracticesAction,
    SetPAMSelectedPracticeIdAction
} from "../../actions/pam_actions";

export default function () {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.pam_practices);
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);

    function loadPractices() {
        if (!selected_school_id || !selected_challenge_id) {
            dispatch(LoadedPamPracticesAction([]));
            return;
        }
        dispatch(LoadPamPracticesAction({
            selected_school_id: selected_school_id ?? '',
            selected_from: from ?? '',
            selected_till: till ?? '',
            selected_challenge_id: selected_challenge_id ?? ''
        })).then(practices => {
            if (practices.length === 1) {
                dispatch(SetPAMSelectedPracticeIdAction(practices[0].id));
            }
        }).catch((error) => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        });
    }

    function onChange(e) {
        e.preventDefault();
        if (!e.target.value) {
            dispatch(SetPAMSelectedPracticeIdAction(null));
        } else {
            dispatch(SetPAMSelectedPracticeIdAction(parseInt(e.target.value)));
        }
    }

    function renderEmptyOption() {
        if (practices.length !== 1) {
            return (
                <option value="">
                    {`Select a Practice`}
                </option>
            );
        }
    }

    function renderOptions() {
        return practices.map((practice) => {
            return (
                <option key={practice.id} value={practice.id}>
                    {`${practice.name}`}
                </option>
            );
        });
    }

    useEffect(() => {
        loadPractices();
    }, [selected_school_id, from, till, selected_challenge_id]);

    useEffect(() => {
        loadPractices();
    }, []);

    return (
        <div>
            <select
                value={selected_practice_id ?? ''}
                disabled={practices.length <= 0}
                onChange={onChange}
            >
                {renderEmptyOption()}
                {renderOptions()}
            </select>
        </div>
    );
}
