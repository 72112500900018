import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {isEmpty, get, find} from 'lodash';
import {ErrorMessageAction} from "mindsets-js-sdk";

import {getPracticeAttr} from './utils'
// import EvaluationProgress from './EvaluationProgress'
import ExportAction from './ExportAction'
import TopTitle from './TopTitle'
import StudentTable from './StudentTable'
import CategoryGraph from './CategoryGraph'
import SkillGraph from './SkillGraph'
import {
    LoadedPamStudentSkillDataAction,
    LoadPamChallengeSkillStructureAction,
    LoadPamStudentSkillDataAction
} from "../../../actions/pam_actions";

export default function () {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const challenges = useSelector(state => state.me_school_challenges);
    const practices = useSelector(state => state.pam_practices);
    const skill_structure_data = useSelector(state => state.pam_challenge_skill_structure);
    const student_skill_data = useSelector(state => state.pam_student_skill_data);

    const selected_challenge = find(challenges, {id: selected_challenge_id});
    const selected_practice = find(practices, {id: selected_practice_id});

    function loadSkillStructureData() {
        const challenge_version_id = get(selected_challenge, 'challenge_version_id')
        if (!challenge_version_id) {
            return null;
        }

        dispatch(LoadPamChallengeSkillStructureAction({
            challenge_version_id,
            selected_practice_id: selected_practice_id ?? ''
        })).catch((error) => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        })
    }

    function loadStudentSkillData() {
        Promise.resolve()
            .then(() => dispatch(LoadedPamStudentSkillDataAction([])))
            .then(() => dispatch(LoadPamStudentSkillDataAction({
                selected_school_id: selected_school_id ?? '',
                selected_from: from,
                selected_till: till,
                selected_challenge_id: selected_challenge_id ?? '',
                selected_practice_id: selected_practice_id ?? '',
                // These two are in url params only and for internal used, for now
                class_codes: searchParams.get('class_codes') ?? '',
                school_ids: searchParams.get('school_ids') ?? ''
            })))
            .catch((error) => {
                console.error(error);
                dispatch(ErrorMessageAction(error));
            });
    }

    // function loadEvaluationProgress() {
    //     // Do not need evaluation pregress for now
    //     return null
    //
    //     this.setState({evaluation_progress: undefined})
    //
    //     const searchParams = new URLSearchParams(location.search);
    //
    //     const {selected_school_id, selected_challenge_id} = this.props
    //
    //     return MindsetsJsSDK().Api.pam.evaluation_progress.get({
    //         selected_school_id: selected_school_id ? selected_school_id : '',
    //         selected_from: this.props.from,
    //         selected_till: this.props.till,
    //         selected_challenge_id: selected_challenge_id ? selected_challenge_id : '',
    //         // These two are in url params only and for internal used, for now
    //         class_codes: searchParams.has('class_codes') ? searchParams.get('class_codes') : '',
    //         school_ids: searchParams.has('school_ids') ? searchParams.get('school_ids') : ''
    //     }).then((response) => {
    //         this.setState({evaluation_progress: response.data.evaluation_progress})
    //     }).catch((error) => {
    //         console.error(error);
    //         this.props.dispatch(toastActions.ErrorMessageAction(error));
    //     })
    // }

    function renderTopTitle() {
        return (
            <div>
                <TopTitle/>
            </div>
        );
    }

    function renderStudentTable() {
        return (
            <div className="section">
                <StudentTable/>
            </div>
        );
    }

    function renderCategoryGraph() {
        const has_category = getPracticeAttr(selected_practice.code, 'has_category');
        if (!has_category) {
            return null;
        }

        return (
            <div className="section">
                <CategoryGraph/>
            </div>
        );
    }

    function renderSkillGraph() {
        return (
            <div className="section">
                <SkillGraph/>
            </div>
        );
    }

    useEffect(() => {
        loadSkillStructureData();
        loadStudentSkillData();
        // loadEvaluationProgress();
    }, [selected_school_id, from, till, selected_challenge_id, selected_practice_id]);

    if (isEmpty(skill_structure_data)) {
        return null
    }

    if (isEmpty(student_skill_data)) {
        return null
    }

    return (
        <div className="pam-challenge-student-achievement">
            {renderTopTitle()}

            <div className="flex-row flex-h-end flex-wrap">
                <ExportAction/>
            </div>

            {renderStudentTable()}
            <div className="section"/>
            {renderCategoryGraph()}
            <div className="section"/>
            {renderSkillGraph()}
        </div>
    );
}
