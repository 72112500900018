import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import {LoadCurriculumByCodeAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';

export default function () {
    const dispatch = useDispatch();
    const state_curriculum = useSelector(state => state.curriculum);
    const me = useSelector(state => state.me);

    useEffect(() => {
        if (!!me && !me.curriculum) {
            dispatch(LoadCurriculumByCodeAction('ccss'));
        }
    }, [me]);

    if (!me) {
        return null;
    }

    const curriculum = me.curriculum ? me.curriculum : state_curriculum;

    if (!curriculum) {
        return null;
    }

    return (
        <a
            href={`/curriculum/${curriculum.code}/map.pdf`}
            onClick={() => analytic.log('downloadCurriculumGuide', curriculum.code)}
            className='guide-link'
            target='_blank'
            data-tip={curriculum.name}
        >
            <img
                src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/curriculum_map.svg`}
                alt={curriculum.name}
            />
            <ReactTooltip/>
        </a>
    );
};
