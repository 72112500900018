import {LOADED_CHALLENGE_CODE} from "mindsets-js-sdk/src/react/actions/challenge_actions";

const challenge_code = (payload = null, action) => {
    switch (action.type) {
        case LOADED_CHALLENGE_CODE:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    challenge_code
}
