import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {sortBy, reverse} from 'lodash';

import analytic from 'utils/Analytic';
import Lesson from './Lesson';


function Teacher({teacher}) {
    const [open_lessons, setOpenLessons] = useState(false);

    function renderCompletionStats() {
        const calculatePercentage = (n) => {
            if (teacher.no_of_student_streams <= 0) {
                return 0
            } else {
                return Math.round(
                    n / teacher.no_of_student_streams * 100
                )
            }
        }

        const renderPart = (type, p) => {
            if (p > 0) {
                const class_names = classNames('sam-t-completion-bar', `sam-t-completion-${type}`)
                return [
                    (
                        <div key={`${type}-${p}`} className={class_names} style={{flex: p}} title={type}>
                        </div>
                    ),
                    (
                        <div key={`${type}-t`} className="sam-t-completion-text" title={type}>
                            {`${p}%`}
                        </div>
                    )
                ]
            } else {
                return null
            }
        }

        const completion_stats = teacher.completion_stats
        const p_started = calculatePercentage(completion_stats.stuck)
        const p_underway = calculatePercentage(completion_stats.underway)
        const p_nearly = calculatePercentage(completion_stats.nearly)
        const p_completed = calculatePercentage(completion_stats.completed)
        const p_completedbonus = calculatePercentage(completion_stats.completed_bonus)

        return (
            <div className="sam-t-completion flex-auto flex-row flex-v-center flex-h-end">
                {renderPart('started', p_started)}
                {renderPart('underway', p_underway)}
                {renderPart('nearly', p_nearly)}
                {renderPart('completed', p_completed)}
                {renderPart('completedbonus', p_completedbonus)}
            </div>
        )
    }

    function renderTeacher() {
        const class_names = classNames('flex-row sam-t-teacher', {
            'sam-t-teacher-0': !teacher.no_of_classes
        })

        const renderName = () => {
            const onClick = (e) => {
                e.preventDefault();
                setOpenLessons(!open_lessons);
                analytic.log(
                    'samTeacherExpanded',
                    teacher.id,
                    `${teacher.fname} ${teacher.lname}`
                )
            }

            if (teacher.no_of_classes > 0) {
                return (
                    <a href="#" onClick={onClick}>
                        {`${teacher.fname} ${teacher.lname}`}
                    </a>
                )
            } else {
                return `${teacher.fname} ${teacher.lname}`
            }
        }

        return (
            <div className={class_names}>
                <div className="sam-t-teacher-name">
                    <div>
                        {renderName()}
                    </div>
                </div>
                <div className="sam-t-teacher-classes">
                    {`${teacher.no_of_classes}`}
                </div>
                <div className="sam-t-teacher-students">
                    {`${teacher.no_of_student_streams}`}
                </div>
                {renderCompletionStats()}
            </div>
        )
    }

    function renderLessons() {
        if (!open_lessons) {
            return null
        }

        const sorted_lessons = reverse(sortBy(teacher.lessons, 'created_at'))
        const render_lessons = sorted_lessons.map((lesson) => {
            return (
                <Lesson
                    key={`l-${lesson.id}`}
                    lesson={lesson}
                />
            )
        })

        return (
            <div className="sam-t-lessons">
                <div className="sam-t-lesson-header">
                    <div className="flex-row">
                        <div className="sam-t-lesson-code">
                            {`Class Code`}
                        </div>
                        <div className="sam-t-lesson-report">
                            {`Report`}
                        </div>
                        <div className="sam-t-lesson-challenge">
                            {`Challenge`}
                        </div>
                        <div className="sam-t-lesson-date">
                            {`Created at`}
                        </div>
                        <div className="sam-t-lesson-mode">
                            {`Mode`}
                        </div>
                        <div className="sam-t-lesson-students">
                            {`Students`}
                        </div>
                        <div className="sam-t-lesson-completion flex-auto">
                            {`Student Engagement`}
                        </div>
                    </div>
                </div>

                {render_lessons}
            </div>
        )
    }

    return (
        <div className="sam-t-one-teacher">
            {renderTeacher()}
            {renderLessons()}
        </div>
    );
}

Teacher.propTypes = {
    teacher: PropTypes.object.isRequired
}

export default Teacher
