import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export default function () {
    const challenge_version = useSelector(state => state.challenge_version);

    return (
        <div className="challenge-preview-layer flex-column flex-vh-center">
            <div className="challenge-preview-layer-text flex-column flex-v-center center-align">
                <div>
                    {`Have your students join the Challenge!`}
                </div>
                <div>
                    {`Click the button to create & share the Challenge with them.`}
                </div>
            </div>

            <div className="section">
            </div>

            <div className="flex-row flex-h-center">
                <Link
                    className="btn btn-large btn-challenge"
                    to={`${window.location.origin}/explore/${challenge_version.challenge_code.code}`}
                    target="_blank"
                >
                    {`Explore More`}
                </Link>
            </div>

            <div className="section">
            </div>
        </div>
    );
}
