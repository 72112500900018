import React from 'react';
import {useSelector} from "react-redux";
import {find, get, sortBy} from 'lodash';

import Sticky from 'common/ui/Sticky';
import ProgressBar from 'common/challenge/ProgressBar';
import ToolBar from './ToolBar';
import {getStudentStreams} from "../../utils";

export default function () {
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_orig = useSelector(state => state.streams);
    const selected_stream_id = useSelector(state => state.teacher_facilitation_selected_stream_id);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);
    const streams = sortBy(getStudentStreams(streams_orig, me.id), (s) => {
        switch (sort_by) {
            case 'progress':
                return -(s.index);
            case 'name':
            default:
                return s.user.full_name;
        }
    });

    function getSelectedStream() {
        return find(streams, {id: selected_stream_id});
    }

    function renderProgress() {
        return (
            <div className="bystudent-progress">
                <ProgressBar
                    challenge_version={lesson.challenge_version}
                    current_card_index={selected_card_index}
                    current_stream_index={get(getSelectedStream(), 'index', 0)}
                    show_avatar={false}
                    current_taller={true}
                    show_tooltip={true}
                />
            </div>
        );
    }

    return (
        <div className="bystudent-top">
            <Sticky>
                <div className="bystudent-top-sticky">
                    <div className="container z-depth-1">
                        <div className="bystudent-top-container-1">
                            <div className="container">
                                <ToolBar/>
                            </div>
                        </div>

                        <div className="bystudent-top-container-2">
                            <div className="container">
                                {renderProgress()}
                            </div>
                        </div>
                    </div>
                </div>
            </Sticky>
        </div>
    );
}
