import React, {useState} from 'react';
import classNames from 'classnames';
import {get, set, clone} from 'lodash';

import {processStringContent} from 'utils/content';

import './component-accordion.scss';


export default function ({component_data}) {

    const items = get(component_data, 'data.items', []);
    const [expanded, setExpanded] = useState(Array(items.length));

    const renderItems = () => {
        const render_items = items.map((item, k) => {
            const icon_class = get(item, 'icon.class', 'fas fa-compass');
            const icon_style = get(item, 'icon.style', {});
            const title_text = get(item, 'title.text');
            const title_style = get(item, 'title.style', {});
            const content_html = get(item, 'content.html');
            const content_style = get(item, 'content.style');
            const header_color = get(item, 'header_color');
            const toggle_style = {};

            if (header_color) {
                icon_style.color = header_color;
                title_style.color = header_color;
                toggle_style.color = header_color;
            }

            const content_style_plus = {
                display: 'none',
                ...content_style
            }
            if (!!expanded[k]) {
                content_style_plus.display = 'block';
            }

            const toggle_class_names = classNames('cc-accordion-item-head-toggle', {
                'fas fa-chevron-down': !expanded[k],
                'fas fa-chevron-up': !!expanded[k]
            });

            const onClick = () => {
                const expanded_clone = clone(expanded);
                expanded_clone[k] = !expanded_clone[k];
                setExpanded(expanded_clone);
            }

            return (
                <div key={k} className="cc-accordion-item">
                    <div className="cc-accordion-item-head flex-row flex-v-center" onClick={onClick}>
                        <i className={`cc-accordion-item-head-icon ` + icon_class} style={icon_style}></i>
                        <div className="cc-accordion-item-head-title" style={title_style}>
                            {title_text}
                        </div>
                        <i className={toggle_class_names} style={toggle_style}></i>
                    </div>

                    <div className="cc-accordion-item-content" style={content_style_plus}>
                        {processStringContent(content_html)}
                    </div>
                </div>
            );
        });
        return (
            <div>
                {render_items}
            </div>
        );
    }

    return (
        <div className="challenge-component-accordion">
            <div>
                {renderItems()}
            </div>
        </div>
    );
}