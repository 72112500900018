import React, {Component} from 'react'
import PropTypes from 'prop-types'

import TextArea from './TextArea'
import NumberLine from './NumberLine'
import ChoiceSingle from './ChoiceSingle'
import ChoiceMultiple from './ChoiceMultiple'
import SliderSingle from './SliderSingle'
import SliderMultiple from './SliderMultiple'
import Graphing from './Graphing'
import Expression from './Expression'
import ExpressionMulti from './ExpressionMulti'
import DragAndDrop from './DragAndDrop'
import DragAndDropPuzzle from './DragAndDropPuzzle'
import Selector from './Selector'
import Matching from './Matching'
import NumberOnly from './NumberOnly'
import Fraction from './Fraction'
import InlinePoll from './InlinePoll'
import Spreadsheet from './Spreadsheet'


class AnswerComponent extends Component {
    getComponentClass() {
        const type = this.props.component_data.type.toLowerCase()
        switch (type) {
            case 'answer.textarea':
                return TextArea
            case 'answer.number_line':
                return NumberLine
            case 'answer.choice_single':
                return ChoiceSingle
            case 'answer.choice_multiple':
                return ChoiceMultiple
            case 'answer.slider':
                return SliderSingle
            case 'answer.range':
                return SliderMultiple
            case 'answer.graphing':
                return Graphing
            case 'answer.expression':
                return Expression
            case 'answer.expression_multi':
                return ExpressionMulti
            case 'answer.dnd':
                return DragAndDrop
            case 'answer.dnd_puzzle':
                return DragAndDropPuzzle
            case 'answer.selector':
                return Selector
            case 'answer.matching':
                return Matching
            case 'answer.number_only':
                return NumberOnly
            case 'answer.fraction':
                return Fraction
            case 'poll.inline':
                return InlinePoll
            case 'spreadsheet':
                return Spreadsheet
            default:
                throw 'Unknown component type'
        }
    }

    renderComponentContent() {
        const ComponentClass = this.getComponentClass()

        return (
            <ComponentClass
                component_data={this.props.component_data}
                existing_answer={this.props.existing_answer}
                challenge_version={this.props.challenge_version}
                lesson_id={this.props.lesson_id}
                stream_entity_id={this.props.stream_entity_id}
                stream_id={this.props.stream_id}
                is_preview={this.props.is_preview}
                is_latest={this.props.is_latest}
                question_id={this.props.question_id}
                ref={(c) => this.props.refComponent(c)}
                support_data={this.props.support_data}
            />
        )
    }

    render() {
        return (
            <div className="challenge-answer-component">
                {this.renderComponentContent()}
            </div>
        )
    }
}

AnswerComponent.propTypes = {
    component_data: PropTypes.object.isRequired,
    support_data: PropTypes.object,
    existing_answer: PropTypes.object,
    challenge_version: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    stream_entity_id: PropTypes.number,
    is_preview: PropTypes.bool.isRequired,
    is_latest: PropTypes.bool.isRequired,
    refComponent: PropTypes.func.isRequired,
    question_id: PropTypes.number.isRequired
}

export default AnswerComponent
