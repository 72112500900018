import React from 'react'

import BaseCard from './BaseCard'
import {processStringContent} from 'utils/content'


export default class QuestionCard extends BaseCard {
    getCardType() {
        return 'question'
    }

    renderTitle() {
        const {data} = this.props.stream_entity
        if (data && data.title) {
            return (
                <div className="challenge-card-title">
                    <h3 className="header-new">
                        {processStringContent(data.title)}
                    </h3>
                </div>
            )
        }
    }
}
