import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {get, isEmpty, take} from 'lodash';

import RaiseHand from './RaiseHand'
import ImmersiveReaderSwitch from 'common/challenge/ImmersiveReaderSwitch'
import CardSelector from 'common/challenge/CardSelector'
import {getCardName} from "utils/challenge/streamEntity";
import {SetStudentStreamCurrentIndexAction} from "../../../actions/stream_actions";


export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const stream_entities_responses = useSelector(state => state.student_stream_stream_entities_responses);
    const stream_entities = take(
        stream_entities_responses.map((se, i) => {
            const challenge_se = get(challenge_stream_entities, i);

            return {
                ...challenge_se,
                ...se
            }
        }),
        get(stream, 'index', 0) + 1
    );

    function onSelectCard(current_index) {
        dispatch(SetStudentStreamCurrentIndexAction(current_index));
        window.scrollTo(0, 0);
    }

    function renderLogoIcon() {
        const onClick = () => {
            navigate('/');
        }

        return (
            <div className="student-stream-header-logo-icon" onClick={onClick}>
                <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_icon_white.svg`}
                     alt='Mindsets logo'/>
            </div>
        );
    }

    function renderChallengeName() {
        return (
            <div className="student-stream-header-name hide-on-small-only">
                {stream.challenge_version.name}
            </div>
        );
    }

    function renderRaiseHand() {
        if (isEmpty(stream)) {
            return null;
        }

        return (
            <RaiseHand/>
        );
    }

    function renderImmersiveReaderSwitch() {
        const current_stream_entity = get(stream_entities, current_index);
        return (
            <ImmersiveReaderSwitch
                challenge_version={stream.challenge_version}
                stream_entity={current_stream_entity}
                from={`Student Stream`}
                stream={stream}
            />
        );
    }

    function renderCardSelector() {
        if (stream_entities.length) {
            if (stream.lesson.mode === 'teacher') {
                return (
                    <div className="challenge-card-selector flex-row">
                        <div className="challenge-card-selector-current flex-row flex-h-center">
                            <button className="btn btn-grey">
                            <span>
                                {getCardName(stream.challenge_version, current_index)}
                            </span>
                            </button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <CardSelector
                        challenge_version={stream.challenge_version}
                        stream_entities={stream_entities}
                        selected_card_index={current_index}
                        onSelect={onSelectCard}
                    />
                );
            }
        }
    }

    return (
        <div className="student-stream-header z-depth-1 flex-row flex-v-center">
            <div className="flex-1"/>
            <div className="flex-20 flex-row flex-wrap flex-v-center flex-box-5-10">
                <div className="flex-auto">
                    <div className="flex-row flex-v-center flex-box-5-10">
                        {renderLogoIcon()}

                        {renderChallengeName()}
                    </div>
                </div>

                <div className="flex-auto">
                    <div className="flex-row flex-v-center flex-h-end flex-box-5-10 flex-wrap">
                        {renderRaiseHand()}

                        {renderImmersiveReaderSwitch()}

                        {renderCardSelector()}
                    </div>
                </div>
            </div>
            <div className="flex-1"/>
        </div>
    );
}
