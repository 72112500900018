import React from 'react';
import PropTypes from 'prop-types';
import {sortBy, reverse, filter} from 'lodash';

import Teacher from './Teacher';


function School({school}) {
    function renderSchool() {
        return (
            <div className="sam-t-school">
                {`School: ${school.name}`}
            </div>
        )
    }

    function renderEmptyState() {
        if (school.teachers.length > 0) {
            return null
        }

        return (
            <div className="sam-t-teacher-empty">
                {`Currently no teacher.`}
            </div>
        )
    }

    function renderTeacherHeader() {
        if (school.teachers.length <= 0) {
            return null
        }

        return (
            <div className="flex-row sam-t-teacher-header">
                <div className="sam-t-teacher-name">
                    {`Teacher`}
                </div>
                <div className="sam-t-teacher-classes">
                    {`# of Classes`}
                </div>
                <div className="sam-t-teacher-students">
                    {`# of Students`}
                </div>
                <div className="sam-t-teacher-completion flex-auto">
                    {`Student Engagement`}
                </div>
            </div>
        )
    }

    function renderTeachers() {
        const sorted_teachers = reverse(
            sortBy(
                filter(school.teachers, ((t) => t.no_of_classes > 0)),
                ['no_of_classes', 'no_of_student_streams']
            )
        )

        return sorted_teachers.map((teacher) => {
            return (
                <Teacher
                    key={`t-${teacher.id}`}
                    teacher={teacher}
                />
            )
        });
    }

    return (
        <div className="sam-t-one-school">
            {renderSchool()}

            {renderEmptyState()}

            {renderTeacherHeader()}

            {renderTeachers()}
        </div>
    );
}

School.propTypes = {
    school: PropTypes.object.isRequired
}

export default School
