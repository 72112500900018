import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, find, sumBy} from 'lodash'
import classNames from 'classnames'

import Selection from './Selection'


class Selections extends Component {
    exceededMaxValue() {
        const value_maximum = get(this.props.component_data, 'data.value_maximum')
        if (value_maximum !== undefined) {
            if (this.props.answer.total_value > value_maximum) {
                return true
            }
        }
        return false
    }

    exceededMaxAmount() {
        const total_amount = sumBy(this.props.answer.answer_selections, 'amount')
        const amount_maximum = get(this.props.component_data, 'data.amount_maximum')
        if (amount_maximum !== undefined) {
            if (total_amount > amount_maximum) {
                return true
            }
        }
        return false
    }

    renderTop() {
        return (
            <div className="cc-selector-selections-top">
                <div>
                    <strong>
                        {get(this.props.component_data, 'data.title_2', '')}
                    </strong>
                </div>
                <div className="cc-selector-selections-top-description">
                    {get(this.props.component_data, 'data.description_2', '')}
                </div>
            </div>
        )
    }

    renderSelections() {
        let total = 0
        const items = get(this.props.component_data, 'data.items', [])
        const render_selections = this.props.answer.answer_selections.map((selection_data) => {
            const item = find(items, {id: selection_data.id})
            total += item.value * selection_data.amount
            return (
                <div key={selection_data.id}>
                    <Selection
                        component_data={this.props.component_data}
                        item={item}
                        selection_data={selection_data}
                        onClickItem={this.props.onClickItem}
                        answer={this.props.answer}
                    />
                </div>
            )
        })

        return (
            <div>
                {render_selections}
            </div>
        )
    }

    renderBar() {
        const total = this.props.answer.total_value
        const goal = get(this.props.component_data, 'data.value_goal', 0)
        let remaining = goal - total
        if (remaining < 0) {
            remaining = 0
        }
        const class_name = classNames('cc-selector-selections-bar-selected', {
            'cc-selector-selections-bar-error': this.exceededMaxValue() || this.exceededMaxAmount()
        })

        return (
            <div className="cc-selector-selections-bar flex-row">
                <div className={class_name} style={{flex: total}}>
                </div>
                <div className="cc-selector-selections-bar-remaining" style={{flex: remaining}}>
                </div>
            </div>
        )
    }

    renderTotal() {
        const total = this.props.answer.total_value
        const unit_prefix = get(this.props.component_data, 'data.unit_prefix', '')
        const unit_suffix = get(this.props.component_data, 'data.unit_suffix', '')
        const total_text = `${unit_prefix}${total}${unit_suffix}`

        return (
            <div className="cc-selector-selections-total">
                <div className="flex-row flex-v-center flex-h-end flex-box-0-10">
                    <div className="cc-selector-selections-total-title">
                        {`Total:`}
                    </div>
                    <div className="cc-selector-selections-total-subtotal">
                        {total_text}
                    </div>
                </div>
            </div>
        )
    }

    renderError() {
        let error_message = ''
        if (this.exceededMaxValue()) {
            error_message = get(this.props.component_data, 'data.error_value_too_big')
        } else if (this.exceededMaxAmount()) {
            error_message = get(this.props.component_data, 'data.error_amount_too_big')
        }

        if (error_message) {
            return (
                <div className="cc-selector-selections-error">
                    {error_message}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-selector-selections">
                {this.renderTop()}
                {this.renderSelections()}
                {this.renderBar()}
                {this.renderTotal()}
                {this.renderError()}
            </div>
        )
    }
}

Selections.propTypes = {
    component_data: PropTypes.object.isRequired,
    answer: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired
}

export default Selections