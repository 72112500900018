import {map, sortBy, sortedUniq, filter, forEach, get, set, sumBy} from 'lodash'

import {
    getIntervalCode,
    getIntervalName,
    getIntervalColor,
    getPracticeIntervalCodes
} from '../utils'


export const getSkillNames = (student_skill_data) => {
    return sortedUniq(sortBy(map(student_skill_data, (d) => `${d.practice_skill_code}: ${d.practice_skill_name}`)))
}

export const computeSkillData = (student_skill_data, skill_structure_data, mode, practice_code) => {
    const computed_s_s_data = {}
    forEach(student_skill_data, ((item) => {
        // Aggregate points
        const base_path = `${item.user_id}_${item.practice_skill_name}`
        const points_path = [base_path, 'points']
        const max_points_path = [base_path, 'max_points']
        const points = get(computed_s_s_data, points_path, 0)
        const max_points = get(computed_s_s_data, max_points_path, 0)
        set(computed_s_s_data, points_path, points + item.points)
        set(computed_s_s_data, max_points_path, max_points + item.max_points)
        set(computed_s_s_data, [base_path, 'skill_code'], item.practice_skill_code)
        set(computed_s_s_data, [base_path, 'skill'], `${item.practice_skill_code}: ${item.practice_skill_name}`)
    }))

    forEach(computed_s_s_data, ((student_skill) => {
        let max_points
        if (mode === 1) {
            // Potential mode
            max_points = student_skill.max_points
        } else {
            // Achievement mode
            max_points = sumBy(
                filter(skill_structure_data, {practice_skill_code: student_skill.skill_code}),
                'max_points'
            )
        }
        student_skill.points_percent = (student_skill.points / max_points * 100).toFixed(2)
        student_skill.interval_code = getIntervalCode(practice_code, student_skill.points_percent)
    }))

    const skill_names = getSkillNames(student_skill_data)
    const graph_series = map(getPracticeIntervalCodes(practice_code), (interval_code) => {
        const data_array = map(skill_names, (skill) => {
            return filter(computed_s_s_data, {interval_code, skill}).length
        })
        return {
            name: getIntervalName(practice_code, interval_code),
            data: data_array,
            color: getIntervalColor(practice_code, interval_code)
        }
    })

    return graph_series
}
