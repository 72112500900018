import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {LoadMeSubscriptionsAction} from "mindsets-js-sdk";

import {isEducator} from "utils/role";
import Free from './Free';
import SchoolPlan from './SchoolPlan';
import DistrictPlan from './DistrictPlan';
import TeacherPlan from './TeacherPlan';

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const district_subscription = useSelector(state => state.me_district_subscription);
    const school_subscription = useSelector(state => state.me_school_subscription);
    useEffect(() => {
        dispatch(LoadMeSubscriptionsAction());
    }, []);
    if (me && isEducator(me)) {
        if (me.subscription_status.subscribed) {
            if (district_subscription) {
                return (
                    <DistrictPlan/>
                );
            } else if (school_subscription) {
                return (
                    <SchoolPlan/>
                );
            } else if (me.subscription_status.only_individual_subscription) {
                return (
                    <TeacherPlan />
                );
            }
        } else {
            return <Free/>;
        }
    }

    return null;
}
