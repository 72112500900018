import React, {Component} from 'react'
import PropTypes from 'prop-types'


class Divider extends Component {
    render() {
        return (
            <div className="challenge-component-divider">
                <div className="divider-container">
                    <div className="divider-border">
                    </div>
                </div>
            </div>
        )
    }
}

Divider.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Divider
