import {ADDED_LOCATION, ADDED_LAST_EVENT} from "../actions/history_actions";
import {takeRight} from 'lodash';

const history = (payload = [], action) => {
    switch (action.type) {
        case ADDED_LOCATION:
            payload = JSON.parse(JSON.stringify(payload));
            payload.unshift(action.payload);
    }

    return payload;
};
const last_events = (payload = [], action) => {
    switch (action.type) {
        case ADDED_LAST_EVENT:
            // payload = JSON.parse(JSON.stringify(payload));
            // if (payload.length >= 5) {
            //     payload.shift();
            // }
            // payload.push(action.payload);

            return takeRight([...payload, action.payload], 5);
    }

    return payload;
};

export default {
    history,
    last_events
}
