import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {get, truncate} from 'lodash';
import moment from 'moment';

import {processStringContent, capitalizeFirstLetter} from 'utils/content';
import analytic from 'utils/Analytic';
import {getChallengeHeroThumb} from 'utils/cloud';

function ActiveChallenge({challenge_version, noPreview = false}) {
    const navigate = useNavigate();
    let me = useSelector(state => state.me);

    if (!me) {
        me = {
            curriculum_id: 1,
            curriculum: {
                is_code_shown: true,
                grade_term: 'grade'
            }
        }
    }

    const thumb_url = getChallengeHeroThumb(challenge_version.challenge_code.code);
    const standards = challenge_version.challenge_code.standards.filter(standard => me.curriculum_id ? standard.curriculum_id === me.curriculum_id : standard.curriculum_id === 1);

    function toPreview(e) {
        e.preventDefault();

        if (noPreview) {
            return;
        }

        analytic.log('previewChallenge', {
            name: challenge_version.name,
            code: challenge_version.challenge_code.code,
        });

        navigate(`/explore/${challenge_version.challenge_code.code}`);
    }

    function renderChallengeName() {
        const name_array = challenge_version.name.replace(': ', ':: ').split(': ');
        return (
            <div className="challenge-title">
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        );
    }

    function renderNewLabel() {
        const is_new = moment.unix(challenge_version.challenge_code.created_at).isAfter(moment().add(-90, 'day'));
        if (is_new) {
            return (
                <div className="challenge-new-label z-depth-2" style={{color: challenge_version.challenge_code.color}}>
                    {`* NEW *`}
                </div>
            );
        }
    }

    function renderTopics() {
        let topics = [];
        standards.map(standard => {
            if (!topics.includes(standard.topic)) {
                topics.push(standard.topic);
            }
        });

        return (
            <div className="challenge-category">
                {truncate(topics.join(', '), {length: 140, omission: '...'})}
            </div>
        );
    }

    function renderStandardCodes() {
        let codes = [];
        standards.map(standard => {
            if (me.curriculum && !me.curriculum.is_code_shown) {
                if (!codes.includes(standard.grade)) {
                    codes.push(standard.grade);
                }
            } else {
                if (!codes.includes(standard.code)) {
                    codes.push(standard.code);
                }
            }
        });
        codes = codes.join(', ');
        if (me.curriculum && !me.curriculum.is_code_shown) {
            codes = capitalizeFirstLetter(me.curriculum.grade_term) + ': ' + codes;
        }

        return (
            <div className="challenge-ccc">
                {truncate(codes, {length: 140, omission: '...'})}
            </div>
        );
    }

    function renderHover() {
        if (noPreview) {
            return null;
        }

        return (
            <div className="challenge-hover">
                <button className="btn btn-large btn-on-dark" onClick={toPreview}>
                    Preview
                </button>
            </div>
        );
    }

    return (
        <div className="active-challenge" onClick={toPreview}>
            <div className="challenge-triangle"
                 style={{borderBottomColor: challenge_version.challenge_code.color}}>&nbsp;</div>
            {renderNewLabel()}
            <div className="challenge-container"
                 style={{backgroundImage: `url(${thumb_url})`}}>
                <div className="challenge-content-container"
                     style={{backgroundColor: challenge_version.challenge_code.color}}>
                    {renderChallengeName()}

                    <div className="challenge-description">
                        {
                            truncate(
                                processStringContent(challenge_version.description, {
                                    convert_html: false,
                                    convert_math: false
                                }),
                                {length: 140, omission: '...'}
                            )
                        }
                    </div>

                    {renderTopics()}
                    {renderStandardCodes()}
                </div>
            </div>

            {renderHover()}
        </div>
    );
}

ActiveChallenge.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            standards: PropTypes.arrayOf(PropTypes.shape({
                code: PropTypes.string.isRequired,
                grade: PropTypes.string.isRequired,
                topic: PropTypes.string.isRequired,
                curriculum_id: PropTypes.number.isRequired
            })).isRequired
        }).isRequired
    }).isRequired,
    noPreview: PropTypes.bool
};

export default ActiveChallenge;
