import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {find, get, isEmpty} from 'lodash';

import {getIntervalCode, getIntervalName, getIntervalColor} from '../utils';

function SkillCell({skill_data}) {
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const practices = useSelector(state => state.pam_practices);
    const show_score = useSelector(state => state.pam_show_score);

    const selected_practice = find(practices, {id: selected_practice_id});

    function getContent() {
        if (isEmpty(skill_data)) {
            return '-'
        }

        const points_percent = get(skill_data, 'points_percent')
        const interval_code = getIntervalCode(selected_practice.code, points_percent)
        const interval_name = getIntervalName(selected_practice.code, interval_code)

        if (show_score) {
            return `${points_percent}%`
        } else {
            return interval_name
        }
    }

    function getColor() {
        const points_percent = get(skill_data, 'points_percent');
        const interval_code = getIntervalCode(selected_practice.code, points_percent);
        return getIntervalColor(selected_practice.code, interval_code);
    }

    return (
        <td className="p-c-st-skill center-align" style={{background: getColor()}}>
            {getContent()}
        </td>
    );
}

SkillCell.propTypes = {
    skill_data: PropTypes.object
}

export default SkillCell
