import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {EventBus, PDFViewer} from 'pdfjs-dist/web/pdf_viewer.mjs';

class Viewer extends Component {
    constructor(props) {
        super(props);
        this.initEventBus();
        this.state = {
            doc: null
        };
    }

    initEventBus() {
        let eventBus = new EventBus();
        eventBus.on('pagesinit', () => {
            if (this.props.onInit) {
                this.props.onInit({});
            }
            this.props.onScaleChanged(this._pdfViewer.currentScale);
        });
        eventBus.on('scalechange', (e) => this.props.onScaleChanged(e.scale));
        this._eventBus = eventBus;
    }

    componentDidMount() {
        const viewerContainer = document.getElementById("pdf-viewer");
        this._pdfViewer = new PDFViewer({
            container: viewerContainer,
            eventBus: this._eventBus,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.doc !== prevState.doc) {
            this._pdfViewer.setDocument(this.state.doc);
        }
        if (this.props.scale !== prevProps.scale) {
            this._pdfViewer.currentScale = this.props.scale;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.doc !== nextState.doc || this.props.scale !== nextProps.scale;
    }

    render() {
        return (
            <div className="pdf-viewer" id='pdf-viewer'>
                <div className="pdfViewer"/>
            </div>
        );
    }
}

Viewer.propTypes = {
    scale: PropTypes.number.isRequired,
    onInit: PropTypes.func,
    onScaleChanged: PropTypes.func,
};

export default Viewer;
