import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import analytic from "utils/Analytic";
import ReactTooltip from "react-tooltip";
import MindsetsJsSDK, {
    CustomDropdown,
    ErrorMessageAction,
    LoadingAction,
    MultipleSelector,
    UpdateLessonAction
} from "mindsets-js-sdk";

import moment from "moment/moment";
import {DATE_FORMAT} from "constants/index";
import {useSearchParams} from "react-router-dom";

function ShareTo(
    {
        fetchClasses,
        fetchStudents,
        isNotReady,
        event_prefix,
        provider,
        sendAssignment,
        renderExtraContent,
        renderErrorModal,
    }
) {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const lesson = useSelector(state => state.lesson);
    const [isOpened, setIsOpened] = useState(false);
    const [classes, setClasses] = useState([]);
    const [class_id, setClassId] = useState('');
    const [students, setStudents] = useState([]);
    const [selected_students, setSelectedStudents] = useState([]);

    function onClickAnalytic() {
        analytic.log(event_prefix + 'AssignStudentsClicked', lesson.code);
    }

    function onClick() {
        onClickAnalytic();
        if (classes.length) {
            setIsOpened(!isOpened);
        } else {
            fetch();
        }
    }

    function fetch() {
        return fetchClasses().then(classes => {
            setClasses(classes);
            setIsOpened(!isOpened);
        }).catch(error => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        }).then(() => dispatch(LoadingAction(false)));
    }

    function validate() {
        let messages = [];
        if (!students.length) {
            messages.push('There are no students in the selected class.');
        }

        if (messages.length > 0) {
            messages.map(message => {
                dispatch(ErrorMessageAction(message));
            });

            return false;
        }

        return true;
    }

    function onAssign() {
        if (validate()) {
            dispatch(LoadingAction(true, 'Assigning students'));
            return MindsetsJsSDK().Api.lesson
                .assignStudents(lesson.id, {
                    provider,
                    class_id,
                    students: students.filter(student => {
                        return selected_students.indexOf(student.id) >= 0;
                    })
                })
                .then(() => {
                    dispatch(LoadingAction(true, 'Updating Class name'));
                    const selected_class = classes.find(course => course.id === class_id);
                    const name = moment().format(DATE_FORMAT) + ' ' + selected_class.name;
                    const grade = selected_class.hasOwnProperty('grade') ? selected_class.grade : null;

                    return dispatch(UpdateLessonAction(lesson.id, {name, grade}))
                        .then(() => sendAssignment(selected_class).then(() => {
                            analytic.log('classroomAssignmentSent', lesson.id, provider, selected_class.id);
                            setStudents([]);
                            setSelectedStudents([]);
                            setClassId('');
                            setIsOpened(false);
                        }));
                })
                .catch(error => {
                    dispatch(ErrorMessageAction('Could not assign students'));
                    console.error(error);
                    dispatch(LoadingAction(false));
                });
        }
    }

    function isNotReadyToAssign() {
        return isNotReady || !class_id || selected_students.length <= 0;
    }

    function renderCoursesDropdownTrigger() {
        const course = classes.find(course => {
            return course.id === class_id;
        });

        return (
            <div className="class-selector flex-row flex-v-center">{course ? course.name : 'Select class'}</div>
        );
    }

    function renderCoursesDropdownContent() {
        let items = {};
        classes.map((course) => {
            items[course.id] = (
                <a href={"#!"} onClick={event => {
                    event.preventDefault();
                    setClassId(course ? course.id : null);
                    setStudents([]);
                    setSelectedStudents([]);
                }}>
                    {course.name}
                </a>
            );
        })

        return items;
    }

    function renderStudentsDropdownTrigger() {
        return (
            <div className="student-selector">
                {students.length === selected_students.length ? 'All students' : (selected_students.length + ' student(s)')}
            </div>
        );
    }

    function renderHeader() {
        return (
            <div className='assignment-header'>
                <div className="assignment-icon-box">
                    <div className="assignment-icon-box-internal">
                        <svg viewBox="0 0 24 24" focusable="false" width="24" height="24" className=" NMm5M hhikbc">
                            <path d="M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7z"/>
                            <path
                                d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 0 0-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"/>
                        </svg>
                    </div>
                </div>
                <div className='assignment-title'>Assignment</div>
            </div>
        );
    }

    function renderClasses() {
        return (
            <div className='assignment-row'>
                <div className='courses-label'>For</div>
                <CustomDropdown
                    trigger={renderCoursesDropdownTrigger()}
                    no_wrap={true}
                    items={renderCoursesDropdownContent()}
                />

                <MultipleSelector
                    trigger={renderStudentsDropdownTrigger()}
                    item_name='student'
                    items={students.map(student => {
                        return {
                            id: student.id,
                            label: student.first_name + ' ' + student.last_name
                        }
                    })}
                    selected={selected_students}
                    callback={selected_students => setSelectedStudents(selected_students)}
                    disabled={!class_id}
                    show_all_option={true}
                />
            </div>
        );
    }

    function renderFooter() {
        return (
            <div className='assignment-footer'>
                <div className='assignment-footer-submit'>
                    <div className='assignment-footer-submit-wrapper'
                         data-for={"assign-lesson-tooltip-" + lesson.code + '_' + provider}
                         data-tip='Choose a Google Class to assign students'>
                        <button className='btn btn-primary' onClick={onAssign} disabled={isNotReadyToAssign()}>Assign
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    function renderModalContent() {
        return (
            <div className="assignment">
                {renderHeader()}
                <div className='assignment-body'>
                    {renderClasses()}

                    {typeof renderExtraContent === 'function' ? renderExtraContent() : null}
                </div>
                {renderFooter()}
            </div>
        );
    }

    function renderAssignButtonTooltip() {
        if (isNotReadyToAssign()) {
            return (
                <ReactTooltip
                    id={"assign-lesson-tooltip-" + lesson.code + '_' + provider}
                    effect="solid"
                    multiline={true}
                    className='assign-tooltip'
                />
            );
        }

        return null;
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [isOpened]);
    useEffect(() => {
        if (class_id) {
            fetchStudents(class_id).then(students => {
                setStudents(students);
                setSelectedStudents(students.map(student => student.id));
            }).catch(error => {
                console.error(error);
                dispatch(ErrorMessageAction(error));
            }).then(() => dispatch(LoadingAction(false)));
        }
    }, [class_id]);
    useEffect(() => {
        if (searchParams.has('open') && provider === searchParams.get('open')) {
            fetch();
        }
    }, []);

    return (
        <div className='assign-button-wrapper'>
            <Modal
                open={isOpened}
                onClose={() => {
                    analytic.log('shareModalClosed', lesson.id);
                    setIsOpened(false);
                }}
                showCloseIcon={true}
                classNames={{
                    modal: 'assignment-modal',
                    closeButton: 'assignment-close-button'
                }}
                center
            >
                {renderModalContent()}
            </Modal>
            {typeof renderErrorModal === 'function' ? renderErrorModal() : null}
            <button className='btn btn-challenge btn-block' onClick={onClick}>Assign Students</button>
            {renderAssignButtonTooltip()}
        </div>
    );
}

ShareTo.propTypes = {
    event_prefix: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    fetchClasses: PropTypes.func.isRequired,
    fetchStudents: PropTypes.func.isRequired,
    sendAssignment: PropTypes.func.isRequired,
    isNotReady: PropTypes.bool.isRequired,
    renderExtraContent: PropTypes.func,
    renderErrorModal: PropTypes.func
};

export default ShareTo;
