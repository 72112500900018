import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SpreadsheetEditableField from './SpreadsheetEditableField'
import ReadOnly from './ReadOnly'
import {processStringContent} from 'utils/content'


class SpreadsheetCell extends Component {
    renderImage() {
        if (this.props.data.image) {
            return (
                <div>
                    <img className="spreadsheet-image" src={this.props.data.image} />
                </div>
            )
        }
    }

    renderText() {
        if (!this.props.data.editable) {
            return processStringContent(this.props.data.title)
        }
    }

    renderTextarea() {
        if (!this.props.is_preview && this.props.data.editable) {
            return (
                <SpreadsheetEditableField
                    data={this.props.data}
                    is_preview={this.props.is_preview}
                    ref={(field) => this.props.refEditableField(field)}
                />
            )
        }
    }

    renderReadOnly() {
        if (this.props.is_preview && this.props.data.editable) {
            return (
                <ReadOnly
                    data={this.props.data}
                    is_preview={this.props.is_preview}
                />
            )
        }
    }

    render() {
        const class_name = classNames(
            "spreadsheet-cell",
            this.props.data.class_name,
            {
                "spreadsheet-cell-editable": this.props.data.editable,
            }
        )
        return (
            <td className={class_name} style={this.props.data.style}>
                <div className="">
                    {this.renderImage()}
                    {this.renderText()}
                    {this.renderTextarea()}
                    {this.renderReadOnly()}
                </div>
            </td>
        )
    }
}

SpreadsheetCell.propTypes = {
    data: PropTypes.object.isRequired,
    is_preview: PropTypes.bool.isRequired,
    refEditableField: PropTypes.func.isRequired
}

export default SpreadsheetCell
