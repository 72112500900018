import React from 'react';
import {useSelector} from "react-redux";

import Page0 from "./Page0";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
import Page16 from "./Page16";
import ProgressBar from "../ProgressBar";

export default function () {
    const index = useSelector(state => state.student_story_page_index);
    const data = useSelector(state => state.student_story_data);

    function getPageClassName() {
        switch (index) {
            case 0:
                return Page0;
            case 1:
                return Page1;
            case 2:
                return Page2;
            case 3:
                return Page3;
            case 4:
                return Page4;
            case 5:
                return Page5;
            case 6:
                return Page6;
            case 7:
                return Page7;
            case 8:
                return Page8;
            case 9:
                return Page9;
            case 10:
                return Page10;
            case 11:
                return Page11;
            case 12:
                return Page12;
            case 13:
                return Page13;
            case 14:
                return Page14;
            case 15:
                return Page15;
            case 16:
                return Page16;
            default:
                throw 'Unknown page';
        }
    }

    const PageClass = getPageClassName();

    if (!data) {
        return null;
    }

    return (
        <div className='student-story-pages'>
            <ProgressBar/>
            <PageClass/>
        </div>
    );
}