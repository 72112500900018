export const ADDED_LOCATION = '[history] Added item to history';
export const ADDED_LAST_EVENT = '[history] Added last event';

export const AddLocationAction = payload => ({
    type: ADDED_LOCATION,
    payload
});
export const AddLastEventAction = payload => ({
    type: ADDED_LAST_EVENT,
    payload
});
