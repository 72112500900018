import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from "react-router-dom";
import {rolesConstants} from 'mindsets-js-sdk';

import LoginFormContainer from './LoginFormContainer';
import SSO from './SSO';
import ByEmail from './ByEmail';
import ByCode from './ByCode';

function LoginForm({persona, mode, action_text, clearPersona, switchMode}) {
    const searchParams = new URLSearchParams(useLocation().search);

    function callback() {
        return window.location.href = searchParams.has('redirect_url') ? searchParams.get('redirect_url') : '/';
    }

    function renderTop() {
        return (
            <SSO
                persona={persona}
                callback={callback}
            />
        );
    }

    function renderBottom() {
        let terms = null
        if (mode === 'signup') {
            terms = (
                <div className="flex-row flex-wrap flex-vh-center mb-3">
                    <div>{`By signing up, you agree to our`}&nbsp;</div>
                    <a className="link-new" href="https://www.mindsets.com/terms/" target="_blank">
                        {`Terms & Conditions`}
                    </a>
                    <div>{`.`}</div>
                </div>
            )
        }

        let join_by_code = null
        if (rolesConstants.ROLE_STUDENT === persona && mode === 'login' && process.env.FEATURE_SWITCH_QUICK_JOIN) {
            join_by_code = (
                <ByCode/>
            );
        }

        return (
            <div>
                <ByEmail
                    persona={persona}
                    mode={mode}
                    action_text={action_text}
                    terms={terms}
                    callback={callback}
                />
                {join_by_code}
            </div>
        );
    }

    return (
        <div className="container">
            <LoginFormContainer
                persona={persona}
                mode={mode}
                action_text={action_text}
                content_top={renderTop()}
                content_bottom={renderBottom()}
                clearPersona={clearPersona}
                switchMode={switchMode}
            />
        </div>
    );
}

LoginForm.propTypes = {
    persona: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    action_text: PropTypes.string.isRequired,
    clearPersona: PropTypes.func.isRequired,
    switchMode: PropTypes.func.isRequired
};

export default LoginForm;
