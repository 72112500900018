import React, {useRef} from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import {rolesConstants} from "mindsets-js-sdk";

import Popup from 'common/Popup'
import SortAction from './SortAction'
import ToggleStudentAction from './ToggleStudentAction'
import PauseAction from './PauseAction'
import MarkDoneAction from './MarkDoneAction'
import TeacherReportAction from 'common/TeacherReportAction'
import ToggleAnswerKeysAction from 'common/ToggleAnswerKeysAction'
import analytic from 'utils/Analytic'
import {userCan} from 'utils/membership'
import FeatureLock from 'common/membership/FeatureLock'
import ToggleIsHiddenAction from 'common/ToggleIsHiddenAction';
import ToggleRaiseHandAction from './ToggleRaiseHandAction'
import {getStudentStreams} from "../../utils";


export default function () {
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const streams_orig = useSelector(state => state.streams);
    let streams = getStudentStreams(streams_orig, me.id);
    const popup = useRef();

    function actionCallBack() {
        popup.current.toggle(false)();
    }

    function renderTeacherReport() {
        return (
            <div className='hovers'>
                <div className='px-4 py-2'>
                    <div className="facilitation-menu-actions-teacher-report">
                        <TeacherReportAction
                            lesson={lesson}
                            challenge_stream_entities={challenge_stream_entities}
                            streams={streams}
                            callBack={actionCallBack}
                        >
                            <a className="d-block py-2 mr-3" href={'#!'} onClick={e => e.preventDefault()}>
                                <i className="fas fa-download mr-2"/>
                                <span>Download Class Report</span>
                            </a>
                        </TeacherReportAction>
                    </div>
                </div>
            </div>
        );
    }

    function renderResourcesLink() {
        return (
            <div className="hovers">
                <div className="px-4 py-2">
                    <div className="facilitation-menu-actions-teacher-guide">
                        <Link to={`/explore/${lesson.challenge_version.challenge_code.code}/resources`}>
                            <i className="fas fa-list-alt mr-2"/>
                            <span>Resources</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    function renderToggleAnswerKeysAction() {
        if (lesson.challenge_version.answer_key_url && lesson.challenge_version.bonus_answer_key_url) {
            return (
                <div className='px-4 py-2'>
                    <ToggleAnswerKeysAction
                        lesson={lesson}
                        bonus_answer_key_url={lesson.challenge_version.bonus_answer_key_url}
                        answer_key_url={lesson.challenge_version.answer_key_url}
                        asToggle={true}
                        showLinks={true}
                    />
                </div>
            );
        }

        return null;
    }

    function renderIsHiddenToggle() {
        return (
            <div>
                <hr/>
                <div className='px-4 py-2'>
                    <ToggleIsHiddenAction
                        lesson={lesson}
                        is_paid_only={true}
                    />
                </div>
            </div>
        );
    }

    function renderDownloadGuide() {
        return (
            <div className="hovers">
                <div className="px-4 py-2">
                    <div className="facilitation-menu-actions-teacher-guide">
                        <a
                            href={`/challenge/${lesson.challenge_version.challenge_code.code}/guide.pdf`}
                            className="d-block py-2"
                            onClick={() => analytic.log('challengeGuideDownloaded', {
                                name: lesson.challenge_version.name,
                                code: lesson.challenge_version.challenge_code.code
                            })}
                            target='_blank'
                        >
                            <i className="fas fa-download mr-2"/>
                            <span>{`Download Teacher Guide`}</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    function renderMembers() {
        if (!!me && rolesConstants.ROLE_TEACHER === me.persona) {
            return (
                <div>
                    <hr/>
                    <div className="hovers">
                        <div className="px-4 py-2 flex-row facilitation-menu-actions-members">
                            <FeatureLock
                                enable={!!me && userCan(me.subscription_status, 'assign_teachers')}
                                title='Co-teaching available only on School or District Plans.'
                            >
                                <Link
                                    className='d-block py-2 mr-3'
                                    to={`/teacher/class/${lesson.code}/teachers`}
                                    onClick={() => analytic.log('facilitationGotoMembers', {
                                        name: lesson.challenge_version.name,
                                        code: lesson.challenge_version.challenge_code.code,
                                    }, lesson.code)}
                                >
                                    <i className="fas fa-user-friends mr-2"/>
                                    <span>Teachers</span>
                                </Link>
                            </FeatureLock>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    function renderPopup() {
        return (
            <div className="teacher-facilitation-menu-actions-options">
                <div className='mx-4'>
                    <h4 className="header-new">
                        {`Options`}
                    </h4>
                </div>

                <div className="flex-column">
                    <PauseAction asToggle={true}/>

                    <ToggleStudentAction/>

                    {renderToggleAnswerKeysAction()}

                    <ToggleRaiseHandAction/>

                    <SortAction callBack={actionCallBack}/>

                    {renderTeacherReport()}

                    {renderResourcesLink()}

                    {renderDownloadGuide()}

                    {renderMembers()}

                    {renderIsHiddenToggle()}

                    <MarkDoneAction/>
                </div>
            </div>
        );
    }

    function renderOptions() {
        return (
            <Popup ref={popup} popup_content={renderPopup()} right="0px">
                <button className="btn btn-flat">
                    <span className="hide-on-small-only">{`Options`}</span>
                    <i className="hide-on-med-and-up fas fa-cog"/>
                    <i className="fas fa-caret-down"/>
                </button>
            </Popup>
        );
    }

    // renderTeachingMode() {
    //     return (
    //         <div className='teaching-mode flex-row flex-v-center'>
    //             <img
    //                 className='mr-1'
    //                 src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/lesson_mode/` + (this.props.lesson.mode === 'student' ? 'student_teal' : 'teacher_purple') + '.svg'}
    //                 alt='Student Paced mode'
    //             />
    //             <p className='no-wrap'>{this.props.lesson.mode === 'student' ? 'Student Paced' : 'Teacher Paced'}</p>
    //         </div>
    //     );
    // }

    if (isEmpty(lesson)) {
        return null
    }

    return (
        <div className="teacher-facilitation-menu-actions flex-row flex-wrap flex-v-center flex-h-end flex-box-2-5">
            <div className="hide-on-med-and-down">
                <PauseAction asToggle={false}/>
            </div>

            {/*{renderTeachingMode()}*/}

            <div>
                {renderOptions()}
            </div>
        </div>
    );
}
