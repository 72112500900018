import React, { useEffect, useRef } from 'react'
import { get } from 'lodash'


export default (props) => {

    const textArea = useRef();

    const text = get(props.writing, 'text');

    useEffect(() => {
        if (text === undefined) {
            props.updateContent({
                [props.attrName]: {
                    text: props.default
                }
            });
        }
    }, []);

    const onChange = (e) => {
        e.preventDefault();
        props.updateContent({
            [props.attrName]: {
                text: e.target.value
            }
        });
    }

    const displayEdit = () => {
        const onClick = () => {
            textArea.current.focus();
        }

        return (
            <i
                className="wm-writing-edit fas fa-edit"
                onClick={onClick}
            >
            </i>
        )
    }

    return (
        <div className="wow-moment-writing">
            <textarea
                ref={textArea}
                value={text}
                placeholder={props.placeholder}
                onChange={onChange}
            >
            </textarea>

            {displayEdit()}
        </div>
    )
}
