import React from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function () {
    const navigate = useNavigate();
    const history = useSelector(state => state.history);
    const challenge_version = useSelector(state => state.challenge_version);
    let url = `/explore/${challenge_version.challenge_code.code}`;
    let title = 'To Preview';
    if (history.length >= 2) {
        const location = history[1];
        url = location.pathname;
        if (location.pathname.includes('/explore/')) {
            title = 'Back to Preview';
        } else if (location.pathname.includes('/teacher/class/')) {
            title = 'Back to Class';
        }
    }

    return (
        <div className={`challenge-header challenge-header-teacher z-depth-1`}>
            <div className="challenge-header-container container flex-column flex-h-center">
                <div className="flex-row flex-wrap flex-v-center flex-h-start flex-box-5-10">
                    <div className="challenge-header-name flex-row flex-v-center">
                        <i className="fas fa-chevron-left" onClick={event => {
                            event.preventDefault();
                            navigate(url);
                        }}/>
                        <span>{title}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
