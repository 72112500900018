import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    LoadChallengeVersionAction,
    LoadedChallengeVersionAction,
    LoadChallengeResourcesAction,
    LoadedChallengeResourcesAction
} from "mindsets-js-sdk";

import useAuth from "../../hooks/useAuth";
import Resource from "./Resource";
import Banner from "./Banner";
import Top from "./Top";

import "./challenge_resources.scss";

export default function () {
    useAuth(['teacher']);

    const params = useParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const challenge_version = useSelector(state => state.challenge_version);
    const challenge_resources = useSelector(state => state.challenge_resources);

    function renderKeyResources() {
        return (
            <div className='section'>
                <h5>Key Resources</h5>
                <div className='flex-row flex-wrap flex-gap-10'>
                    {resources.filter(res => res.category === 'key').map((res, index) => {
                        return (
                            <Resource key={index} resource={res}/>
                        );
                    })}
                </div>
            </div>
        );
    }

    function renderOtherResources() {
        return (
            <div className='section'>
                <h5>Other Resources</h5>
                <div className='flex-row flex-wrap flex-gap-10'>
                    {resources.filter(res => res.category === 'other').map((res, index) => {
                        return (
                            <Resource key={index} resource={res}/>
                        );
                    })}
                </div>
            </div>
        );
    }

    useEffect(() => {
        dispatch(LoadChallengeVersionAction(params.challenge_code, {
            'selectors[0]': 'is_locked'
        }));
        dispatch(LoadChallengeResourcesAction(params.challenge_code));

        return () => {
            dispatch(LoadedChallengeResourcesAction([]))
            dispatch(LoadedChallengeVersionAction(null));
        };
    }, []);

    if (!me || !challenge_version || !challenge_resources) {
        return null;
    }

    const default_resources = [
        {
            title: "Teacher Guide",
            thumbnail: "dove",
            description: "Includes facilitation tips, answer key, estimated time to complete, Challenge overview.",
            url: window.location.origin + `/challenge/${challenge_version.challenge_code.code}/guide.pdf`,
            background_color: "purple",
            category: "key",
            show_for_free: true
        },
        {
            title: "Pre-Teaching Slides",
            thumbnail: "chalkboard-teacher",
            description: "Pre-teaching slides provide guidance on the real-world context, useful practice on the math skills and rigor to prepare students for the Challenge.",
            background_color: "purple",
            category: "key",
            show_for_free: false
        },
        {
            title: "Fast Facts",
            thumbnail: "rocket",
            description: "For students to get background knowledge on context, vocabulary, links to other resources.",
            url: challenge_version.challenge_code.fact,
            background_color: "green",
            category: "other",
            show_for_free: true
        },
        {
            title: "Answer key - main",
            thumbnail: "check",
            description: "For students to view answer key after completing the main part of Challenge; teacher can toggle “Show Answer Keys” on or off.",
            url: challenge_version.answer_key_url,
            background_color: "green",
            category: "other",
            show_for_free: true
        },
        {
            title: "Answer key - bonus",
            thumbnail: "check-double",
            description: "For students to view answer keys after completing the bonus section of Challenge; teacher can toggle “Show Answer Keys” on or off.",
            url: challenge_version.bonus_answer_key_url,
            background_color: "green",
            category: "other",
            show_for_free: true
        },
        {
            title: "ELA/ELD Extension",
            thumbnail: "puzzle-piece",
            description: "Teacher guide to support students with strategies to collaborate, effectively communicate and develop language skills: grammar, phonics, writing, speaking & listening supports.",
            background_color: "purple",
            category: "key",
            show_for_free: false
        },
        {
            title: "Project Extension",
            thumbnail: "box-open",
            description: "Hands-on project-based extensions; includes teacher and student instructions.",
            background_color: "purple",
            category: "key",
            show_for_free: false
        },
        {
            title: "Family Flyer",
            thumbnail: "list-alt",
            description: "1-page description of Challenge for students and families.",
            background_color: "green",
            category: "other",
            show_for_free: true
        }
    ];
    let resources = [];
    default_resources.filter(res => !!res.url).map(default_resource => {
        const resource = challenge_resources.find(res => res.title === default_resource.title);

        resources.push({
            category: default_resource.category,
            title: resource && resource.title ? resource.title : default_resource.title,
            url: resource && resource.url ? resource.url : default_resource.url,
            background_color: resource && resource.background_color ? resource.background_color : default_resource.background_color,
            thumbnail: resource && resource.thumbnail ? resource.thumbnail : default_resource.thumbnail,
            description: resource && resource.description ? resource.description : default_resource.description,
            links: resource && resource.links ? resource.links : default_resource.links,
            show_for_free: resource && resource.show_for_free ? resource.show_for_free : default_resource.show_for_free,
        });
    });
    challenge_resources.filter(res => {
        if (me.curriculum && Array.isArray(res.curricula) && !res.curricula.includes(me.curriculum.code)) {
            return false;
        }
        if (!me.curriculum && Array.isArray(res.curricula) && !res.curricula.includes('ccss')) {
            return false;
        }

        return !resources.map(resource => resource.title).includes(res.title);
    }).map(res => {
        const default_resource = default_resources.find(default_resource => default_resource.title === res.title);

        resources.push({
            category: default_resource ? default_resource.category : res.category,
            title: res.title,
            name: res.name ? res.name : undefined,
            url: res && res.url ? res.url : default_resource.url,
            background_color: res && res.background_color ? res.background_color : default_resource.background_color,
            thumbnail: res && res.thumbnail ? res.thumbnail : default_resource.thumbnail,
            description: res && res.description ? res.description : default_resource.description,
            links: res && res.links ? res.links : undefined,
            show_for_free: res && res.show_for_free ? res.show_for_free : default_resource.show_for_free,
        });
    });

    return (
        <div id="challenge-preview">
            <div id="challenge-preview-page" className='challenge-resources'>
                <Top/>
                <div className="container">
                    <Banner/>
                    <div className="challenge-preview-info">
                        {renderKeyResources()}
                        {renderOtherResources()}
                    </div>
                </div>
            </div>
        </div>
    );
}
