import React from 'react';
import {get} from 'lodash';

const FRACTION_DEFAULT_PLACEHOLDER = 'Write a number here...';
const FRACTION_DEFAULT_MAX_LENGTH = 10;


export default (props) => {
    if (props.is_preview) {
        return renderReadOnlyField(props);
    } else {
        return renderInputField(props);
    }
}


const renderInputField = (props) => {
    const fieldData = props.fieldData;
    if (!fieldData) {
        return null;
    }

    const maxLength = get(props.component_data, 'config.max_length', FRACTION_DEFAULT_MAX_LENGTH);
    const value = get(props.answer, ['answer_selections', props.fractionKey, props.fieldKey]);

    return (
        <input
            className="cc-fraction-input"
            type="text"
            value={value ?? ''}
            placeholder={fieldData.placeholder ?? FRACTION_DEFAULT_PLACEHOLDER}
            onChange={props.onChange}
            maxLength={maxLength}
            style={fieldData.style}
        />
    );
}

const renderReadOnlyField = (props) => {
    const fieldData = props.fieldData;
    if (!fieldData) {
        return null;
    }

    const getContent = () => {
        if (props.existing_answer) {
            return get(props.existing_answer, ['data', 'answer', 'answer_selections', props.fractionKey, props.fieldKey]);
        } else {
            return (
                <span className="cc-fraction-readonly-placeholder">
                    {fieldData.placeholder ?? FRACTION_DEFAULT_PLACEHOLDER}
                </span>
            );
        }
    }

    return (
        <div className="cc-fraction-readonly" style={fieldData.style}>
            {getContent()}
        </div>
    );
}