import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import UserMenu from "./UserMenu";
import ToBuyButton from "common/membership/ToBuyButton";

import './nav_bar.scss';

export default function () {
    const me = useSelector(state => state.me);

    return (
        <nav className={'nav-bar nav-' + (me ? me.persona : 'guest')}>

            <div className="nav-wrapper container">
                <div className="flex-row flex-h-between flex-v-center h100">
                    <Link to="/" className="logo flex-row flex-v-center h100">
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_white.svg`}
                             alt='Logo'/>
                    </Link>

                    <ToBuyButton button_class_name={'btn-on-dark'}/>

                    <UserMenu/>
                </div>
            </div>

        </nav>
    );
}
