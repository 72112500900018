import React from 'react';
import {useSelector} from 'react-redux';

import STable from './STable';

export default function ({selectPage, printable}) {
    const dashboard_data = useSelector(state => state.dashboard_data);

    const data_table = dashboard_data.data_skill_table;
    if (!data_table || !data_table.total) {
        return null;
    }

    return (
        <div>
            <STable
                selectPage={selectPage}
                printable={printable}
            />
        </div>
    );
}
