import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {filter, sortBy} from 'lodash';
import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import Stream from './Stream';
import {getStudentStreams} from "../utils";


export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_orig = useSelector(state => state.streams);
    const streams = filter(getStudentStreams(streams_orig, me.id), (s) => !!s.raise_hand_at);

    function renderHeader() {
        return (
            <div>
                <h6 className="header-new">
                    {`Raised Hands`}
                </h6>
            </div>
        );
    }

    function renderLowerAllHands() {
        const onClick = (e) => {
            e.preventDefault();

            dispatch(LoadingAction(true, 'Lowering All Hands'));

            MindsetsJsSDK().Api.lesson.lowerAllHands(
                lesson.id
            ).then(() => {
                analytic.log(
                    'facilitationLowerAllHands',
                    lesson.challenge_version.name,
                    lesson.code
                );
            }).catch((error) => {
                console.error(error);
            }).then(() => {
                setTimeout(() => {
                    dispatch(LoadingAction(false));
                }, 1500);
            });
        }

        return (
            <div>
                <a href="#" onClick={onClick}>
                    {`Lower all hands`}
                </a>
            </div>
        );
    }

    function renderStreams() {
        const streams_x = sortBy(streams, 'raise_hand_at')
        if (streams_x.length <= 0) {
            return;
        }

        const render_streams = streams_x.map((stream) => {
            return (
                <div className="col s12 m6" key={stream.id}>
                    <Stream stream={stream}/>
                </div>
            );
        });

        return (
            <div className="row">
                {render_streams}
            </div>
        );
    }

    if (streams.length <= 0) {
        return null;
    }

    return (
        <div className="section">
            <div className="flex-row flex-v-center flex-between">
                {renderHeader()}

                <div>
                    {renderLowerAllHands()}
                </div>
            </div>

            {renderStreams()}
        </div>
    );
}
