import React from 'react';
import PropTypes from 'prop-types';
import {DateRangeSelector, Checkbox, CustomDropdown} from "mindsets-js-sdk";

export const FILTER_COOKIE_NAME = 'mindsets_lesson_filter_';

function LessonFilter({order, limit, hide_done, onOrderChange, updateState, list_name, from, till}) {
    function renderHideDone() {
        return (
            <div className='no-wrap'>
                <Checkbox
                    toggle={() => updateState('hide_done', !hide_done)}
                    checked={true === hide_done}
                    label='Hide marked as done'
                />
            </div>
        );
    }

    return (
        <div className="flex-row flex-gap-10 flex-wrap flex-v-center flex-grow-1">
            <DateRangeSelector
                from={from}
                till={till}
                onUpdate={data => Object.keys(data).map(key => updateState(key, data[key]))}
                as={'selector'}
                with_label={true}
                label='Date created'
                key={list_name + '-date-selector'}
            />
            {/*<button className='btn btn-on-white no-wrap' onClick={event => {*/}
            {/*    event.preventDefault();*/}
            {/*    if (limit) {*/}
            {/*        updateState('limit', null);*/}
            {/*    } else {*/}
            {/*        onOrderChange('created_at:desc');*/}
            {/*        updateState('range', null);*/}
            {/*        updateState('from', null);*/}
            {/*        updateState('till', null);*/}
            {/*        updateState('limit', 5);*/}
            {/*    }*/}
            {/*}}>{limit ? 'Show all' : 'Show last 5'}</button>*/}
            {/*<div className='no-wrap'>*/}
            {/*    <Checkbox*/}
            {/*        toggle={() => {*/}
            {/*            if (limit) {*/}
            {/*                updateState('limit', null);*/}
            {/*            } else {*/}
            {/*                onOrderChange('created_at:desc');*/}
            {/*                updateState('range', null);*/}
            {/*                updateState('from', null);*/}
            {/*                updateState('till', null);*/}
            {/*                updateState('limit', 5);*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        checked={!!limit}*/}
            {/*    >Show last 5</Checkbox>*/}
            {/*</div>*/}
            {/*<div className='no-wrap'>*/}
            {/*    <Checkbox*/}
            {/*        toggle={onShowHiddenToggle}*/}
            {/*        checked={true === show_hidden}*/}
            {/*    >Include Classes hidden from students</Checkbox>*/}
            {/*</div>*/}
            <div>
                <label>Order by</label>
                <CustomDropdown
                    id={list_name + '-order_by'}
                    items={{
                        newest: <div className='order-by-item'
                                     onClick={() => onOrderChange('created_at:desc')}>Newest first</div>,
                        oldest: <div className='order-by-item'
                                     onClick={() => onOrderChange('created_at:asc')}>Oldest first</div>,
                        class_name: <div className='order-by-item'
                                         onClick={() => onOrderChange('name:asc')}>Class Name A-Z</div>,
                        challenge_name: <div className='order-by-item'
                                             onClick={() => onOrderChange('challenge:asc')}>Challenge Name
                            A-Z</div>,
                    }}
                    trigger={(
                        <div>{order === 'created_at:desc' ? 'Newest first' : (order === 'created_at:asc' ? 'Oldest first' : (order === 'name:asc' ? 'Class Name A-Z' : 'Challenge Name A-Z'))}</div>)}
                />
            </div>
            {/*{renderHideDone()}*/}
        </div>
    );
}

LessonFilter.propTypes = {
    order: PropTypes.string.isRequired,
    limit: PropTypes.number,
    hide_done: PropTypes.bool.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    updateState: PropTypes.func.isRequired,
    list_name: PropTypes.string.isRequired,
    from: PropTypes.number,
    till: PropTypes.number
}

export default LessonFilter;
