import React from 'react';
import PropTypes from 'prop-types';
import {get, truncate, capitalize} from 'lodash';
import moment from 'moment';
// import * as microsoftTeams from "@microsoft/teams-js"

import {processStringContent} from 'utils/content';
import {getChallengeHeroThumb} from 'utils/cloud';


function GuestChallengeCard({challenge_version, curriculum, onPreview}) {
    function toPreview() {
        onPreview();

        // microsoftTeams.settings.setSettings({
        //     websiteUrl: window.location.origin,
        //     contentUrl: window.location.origin + `/teams/challenge_preview/${challenge_version.code}`,
        // })
    }

    function renderChallengeName(name) {
        const name_array = name.replace(': ', ':: ').split(': ')
        return (
            <div className="challenge-title">
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        )
    }

    function renderNewLabel() {
        const is_new = moment.unix(challenge_version.challenge_code.created_at).isAfter(moment().add(-90, 'day'))
        if (is_new) {
            return (
                <div className="challenge-new-label z-depth-2" style={{color: challenge_version.challenge_code.color}}>
                    {`* NEW *`}
                </div>
            );
        }
    }

    function renderTopics(standards) {
        let topics = [];
        standards.map(standard => {
            if (!topics.includes(standard.topic)) {
                topics.push(standard.topic);
            }
        });

        return (
            <div className="challenge-category">
                {truncate(topics.join(', '), {length: 140, omission: '...'})}
            </div>
        );
    }

    function renderStandardCodes(standards) {
        let codes = [];
        standards.map(standard => {
            if (curriculum && !curriculum.is_code_shown) {
                if (!codes.includes(standard.grade)) {
                    codes.push(standard.grade);
                }
            } else {
                if (!codes.includes(standard.code)) {
                    codes.push(standard.code);
                }
            }
        });
        codes = codes.join(', ');
        if (curriculum && !curriculum.is_code_shown) {
            codes = capitalize(curriculum.grade_term) + ': ' + codes;
        }

        return (
            <div className="challenge-ccc">
                {truncate(codes, {length: 140, omission: '...'})}
            </div>
        );
    }

    const thumb_url = getChallengeHeroThumb(challenge_version.challenge_code.code)
    const standards = challenge_version.challenge_code.standards.filter(standard => curriculum.id ? standard.curriculum_id === curriculum.id : standard.curriculum_id === 1);

    return (
        <div className="guest-challenge-card">
            <div className="challenge-triangle"
                 style={{borderBottomColor: challenge_version.challenge_code.color}}>&nbsp;</div>
            {renderNewLabel()}
            <div className="challenge-container"
                 style={{backgroundImage: `url(${thumb_url})`}}>
                <div className="challenge-content-container"
                     style={{backgroundColor: challenge_version.challenge_code.color}}>
                    {renderChallengeName(challenge_version.name)}

                    <div className="challenge-description">
                        {
                            truncate(
                                processStringContent(challenge_version.description, {
                                    convert_html: false,
                                    convert_math: false
                                }),
                                {length: 140, omission: '...'}
                            )
                        }
                    </div>

                    {renderTopics(standards)}
                    {renderStandardCodes(standards)}
                </div>
            </div>

            <div className="challenge-hover">
                <button
                    className="btn btn-large btn-on-dark"
                    onClick={toPreview}
                >
                    {`Preview`}
                </button>
            </div>
        </div>
    );
}

GuestChallengeCard.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            created_at: PropTypes.number.isRequired,
            standards: PropTypes.arrayOf(PropTypes.shape({
                code: PropTypes.string.isRequired,
                grade: PropTypes.string.isRequired,
                topic: PropTypes.string.isRequired,
                curriculum_id: PropTypes.number.isRequired
            })).isRequired,
        }).isRequired,
    }).isRequired,
    curriculum: PropTypes.object,
    onPreview: PropTypes.func.isRequired
};

export default GuestChallengeCard;
