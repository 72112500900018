import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {debounce} from 'lodash';

import analytic from 'utils/Analytic';
import {SetStudentStreamCurrentIndexAction} from "../../../../actions/stream_actions";


export default function () {
    const dispatch = useDispatch();
    const current_index = useSelector(state => state.student_stream_current_index);

    const onClick = debounce(() => {
        if (current_index > 0) {
            analytic.log('progressToolBarBack');

            return onSelectCard(current_index - 1);
        }
    }, 200, {
        leading: false,
        trailing: true
    });

    function onSelectCard(current_index) {
        dispatch(SetStudentStreamCurrentIndexAction(current_index));
        window.scrollTo(0, 0);
    }

    function renderEmptyButton() {
        return (
            <div className="progress-tool-bar-nav progress-tool-bar-nav-empty"/>
        );
    }

    function renderBackButton() {
        return (
            <div className="progress-tool-bar-nav progress-tool-bar-nav-left flex-row flex-vh-center" onClick={onClick}>
                <i className="fas fa-angle-left"/>
                <span>{`BACK`}</span>
            </div>
        );
    }

    if (current_index === 0) {
        return renderEmptyButton();
    } else {
        return renderBackButton();
    }
}
