import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import {processStringContent} from 'utils/content'


class ClickToShow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    renderButton() {
        const onClick = () => {
            this.setState({show: true})
        }
        return (
            <button className="btn btn-challenge" onClick={onClick}>
                {get(this.props.component_data, 'data.button_label')}
            </button>
        )
    }

    renderContent() {
        if (this.state.show) {
            const content = get(this.props.component_data, 'data.html')
            return (
                <div className="center-align">
                    {processStringContent(content)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-clicktoshow">
                <div className="flex-column flex-vh-center flex-box-10-20">
                    <div>
                        {this.renderButton()}
                    </div>

                    <div>
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

ClickToShow.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default ClickToShow
