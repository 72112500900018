import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class Table extends Component {
    getHTML() {
        return this.props.component_data.data.html
    }

    getContent() {
        return processStringContent(this.getHTML(), {
            className: "flex-row flex-h-start"
        })
    }

    render() {
        return (
            <div className="challenge-component-table">
                <div className="horizontal-scroll-container">
                    {this.getContent()}
                </div>
            </div>
        )
    }
}

Table.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Table
