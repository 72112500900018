import React, { useState } from 'react';
import { get } from 'lodash';

import WowMomentPhoto from './WowMomentPhoto';


export default (props) => {

    const wowMoment = props.wowMoment;

    const showCollection = get(wowMoment, 'content.show_collection');
    if (!showCollection) {
        return null;
    }

    const renderPhoto1 = () => {
        const img = get(wowMoment, 'content.c_img_1');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe1">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_1"
                    img={img}
                    positionX="left"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto2 = () => {
        const img = get(wowMoment, 'content.c_img_2');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe2">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_2"
                    img={img}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto3 = () => {
        const img = get(wowMoment, 'content.c_img_3');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe3">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_3"
                    img={img}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto4 = () => {
        const img = get(wowMoment, 'content.c_img_4');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe4">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_4"
                    img={img}
                    positionX="right"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto5 = () => {
        const img = get(wowMoment, 'content.c_img_5');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe1">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_5"
                    img={img}
                    positionX="left"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto6 = () => {
        const img = get(wowMoment, 'content.c_img_6');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe2">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_6"
                    img={img}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto7 = () => {
        const img = get(wowMoment, 'content.c_img_7');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe3">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_7"
                    img={img}
                    positionX="center"
                    positionY="center"
                />
            </div>
        )
    }

    const renderPhoto8 = () => {
        const img = get(wowMoment, 'content.c_img_8');
        if (!img) {
            return null;
        }
        return (
            <div className="wm-template-photo wm-template-photo-pe4">
                <WowMomentPhoto
                    title="Photo"
                    attrName="c_img_8"
                    img={img}
                    positionX="right"
                    positionY="center"
                />
            </div>
        )
    }

    return (
        <div className="wow-moment-collection wow-moment-template">
            <div className="divider"></div>

            <div className="section">
                <h5 className="header-new center-align">
                    {`WOW Moments! Collection`}
                </h5>
            </div>

            <div className="flex-row">
                <div className="wm-template-mr-20">
                    {renderPhoto1()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto2()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto3()}
                </div>

                <div>
                    {renderPhoto4()}
                </div>
            </div>

            <div className="wm-template-mb-20"></div>

            <div className="flex-row">
                <div className="wm-template-mr-20">
                    {renderPhoto5()}
                </div>

                <div className="wm-template-mr-20">
                    {renderPhoto6()}
                </div>

                <div className="wm-template-mr-20">
                   {renderPhoto7()}
                </div>

                <div>
                    {renderPhoto8()}
                </div>
            </div>
        </div>
    )
}
