import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

export const SET_STUDENT_STREAM_CURRENT_INDEX = '[student_stream] Set current index';
export const SET_STUDENT_STREAM_STREAM_ENTITIES_RESPONSES = '[student_stream] Set stream entities responses';
export const SET_STREAM = '[stream] Set stream';

export const SetStudentStreamCurrentIndexAction = payload => ({
    type: SET_STUDENT_STREAM_CURRENT_INDEX,
    payload
});
export const SetStreamEntitiesResponsesAction = payload => ({
    type: SET_STUDENT_STREAM_STREAM_ENTITIES_RESPONSES,
    payload
});

export const LoadStreamAction = (stream_id, options) => dispatch => {
    return MindsetsJsSDK().API.get('streams/' + stream_id, options)
        .then(response => {
            dispatch(SetStreamAction(response.stream));

            return response.stream;
        });
};
export const SetStreamAction = payload => ({
    type: SET_STREAM,
    payload
});
