import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

function ViewModeSwitcher({mode, onViewModeChange}) {
    return (
        <div className='mode-switcher'>
            <label>View</label>
            <a
                href='#' onClick={event => {
                event.preventDefault();
                onViewModeChange(mode === 'grid' ? 'list' : 'grid');
            }}
                className='mode-switcher-button flex-row flex-v-center'
                data-tip={mode === 'grid' ? 'List view' : 'Grid view'}
            >
                <i className={'fas fa-lg ' + (mode === 'grid' ? 'fa-th-list' : 'fa-th-large')}/>
            </a>
            <ReactTooltip/>
        </div>
    );
}

ViewModeSwitcher.propTypes = {
    mode: PropTypes.string.isRequired,
    onViewModeChange: PropTypes.func.isRequired
}

export default ViewModeSwitcher;
