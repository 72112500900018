import React from 'react';
import PropTypes from "prop-types";

function GeneralError({title, content}) {
    function renderTitle() {
        if (title) {
            let text = '';
            if (typeof title === 'object' && Array.isArray(Object.keys(title)) && Object.keys(title).length > 0) {
                text += Object.values(title).join('. ');
            } else {
                text += title;
            }
            return <div className="not-found-title">{text}</div>;
        }

        return null;
    }

    function renderContent() {
        if (content) {
            let text = '';
            if (typeof content === 'object' && Array.isArray(Object.keys(content)) && Object.keys(content).length > 0) {
                text += Object.values(content).join('. ');
            } else {
                text += content;
            }
            return <h5>{text}</h5>;
        }

        return null;
    }

    return (
        <div className="container">
            <div className="section center not-found">
                <div className="not-found-title">UH-OH</div>
                {renderTitle()}
                {renderContent()}
                <div className="not-found-back">
                    <span>Return to the</span>&nbsp;
                    <a href="/">home page</a><span>.</span>
                </div>
                <div className="not-found-contact-us">
                    <div className="flex-row flex-vh-center flex-box-2-5">
                        <i className="far fa-envelope"/>
                        <a href={`/teacher/contact-us`}>Contact us</a>
                    </div>
                </div>
                <img className="not-found-image" src={process.env.PUBLIC_S3_ASSETS_URL + '/application/404.png'}
                     alt="Error"/>
            </div>
        </div>
    );
}

GeneralError.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default GeneralError;
