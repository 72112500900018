import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {LoadingAction, ErrorMessageAction, SuccessMessageAction, Toggle} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';


function HideAction({asToggle = true}) {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);

    function pauseClass(e) {
        e.preventDefault();
        e.stopPropagation();

        dispatch(LoadingAction(true, 'Pausing Class'));

        MindsetsJsSDK().Api.lesson.pause(
            lesson.id
        ).then(() => {
            dispatch(SuccessMessageAction('All students in your class have been paused'));
        }).catch((error) => {
            dispatch(ErrorMessageAction('An unexpected error occurred when pausing the class'));
            console.error(error)
        }).then(() => {
            dispatch(LoadingAction(false));
        });

        analytic.log('facilitationPauseClass', {
            name: lesson.challenge_version.name,
            code: lesson.challenge_version.challenge_code.code,
        }, lesson.code);
    }

    function startClass(e) {
        e.preventDefault();
        e.stopPropagation();

        dispatch(LoadingAction(true, 'Resuming Class'));

        MindsetsJsSDK().Api.lesson.unpause(
            lesson.id
        ).then(() => {
            dispatch(SuccessMessageAction('All students in your class have been resumed'));
        }).catch((error) => {
            dispatch(ErrorMessageAction('An unexpected error occurred when resuming the class'));
            console.error(error);
        }).then(() => {
            dispatch(LoadingAction(false));
        });

        analytic.log('facilitationResumeClass', {
            name: lesson.challenge_version.name,
            code: lesson.challenge_version.challenge_code.code,
        }, lesson.code);
    }

    function renderButton() {
        if (lesson.status === 'paused') {
            return (
                <button className="btn btn-on-white" onClick={startClass}>
                    <i className="fas fa-play"/>
                    <span>{`Resume Class`}</span>
                </button>
            );
        } else {
            return (
                <button className="btn btn-on-white" onClick={pauseClass}>
                    <i className="fas fa-pause"/>
                    <span>{`Pause Class`}</span>
                </button>
            );
        }
    }

    function renderToggle() {
        return (
            <div className='flex-row flex-v-center mx-4 my-2'>
                <i className={"mr-2 action-icon fas " + (lesson.status === 'started' ? 'fa-pause-circle' : 'fa-play-circle')}></i>
                <div className='switch-text'>
                    {lesson.status === 'started' ? 'Pause Class' : 'Resume Class'}
                </div>
                <div className="flex-auto"></div>
                <Toggle onChange={lesson.status === 'started' ? pauseClass : startClass}
                        is_on={lesson.status === 'started'}/>
                <div className="switch-value">
                    {lesson.status === 'started' ? 'Yes' : 'No'}
                </div>
            </div>
        );
    }

    if (asToggle) {
        return renderToggle();
    } else {
        return renderButton();
    }
}

HideAction.propTypes = {
    asToggle: PropTypes.bool
}

export default HideAction
