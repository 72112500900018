import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page2'>
            <div className='themes'>Your Top Mindsets Themes are</div>
            <div className='flex-row flex-wrap badges'>
                {data.top_themes.map(theme => {
                    return (
                        <img
                            key={theme}
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/theme_${theme}.png`}
                            alt={theme}
                        />
                    );
                })}
            </div>
        </div>
    );
}
