import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CardContainer from './CardContainer'
import CardContent from 'common/challengeNew/CardContent'


class BaseCard extends Component {
    getCardType() {
        return 'base'
    }

    useChallengeColor() {
        return false
    }

    getHeroImage() {
        return undefined
    }

    getMinHeight() {
        return undefined
    }

    renderTitle() {
        return undefined
    }

    renderOtherContent() {
        return undefined
    }

    renderCardContent() {
        const {data} = this.props.stream_entity
        if (data && data.card_content) {
            return (
                <CardContent
                    card_content={data.card_content}
                    challenge_version={this.props.challenge_version}
                    stream_entity={this.props.stream_entity}
                    lesson_id={this.props.lesson.id}
                    stream_id={this.props.stream_id}
                    is_preview={this.props.is_preview}
                    is_latest={this.props.is_latest}
                    refComponent={this.props.refComponent}
                />
            )
        }
    }

    renderAfterContent() {
        return null
    }

    render() {
        return (
            <CardContainer
                challenge_version={this.props.challenge_version}
                stream_entity={this.props.stream_entity}
                use_challenge_color={this.useChallengeColor()}
                hero_image={this.getHeroImage()}
                min_height={this.getMinHeight()}
            >
                <div className={`challenge-${this.getCardType()}-card`}>
                    {this.renderTitle()}

                    <div className="challenge-card-other-content">
                        {this.renderOtherContent()}
                    </div>

                    {this.renderCardContent()}

                    <div className="challenge-card-after-content">
                        {this.renderAfterContent()}
                    </div>
                </div>
            </CardContainer>
        )
    }
}

BaseCard.defaultProps = {
    refComponent: () => () => () => {}
}

BaseCard.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        overview: PropTypes.string,
        tips: PropTypes.string,
        answer_key_url: PropTypes.string,
        bonus_answer_key_url: PropTypes.string,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            is_pd: PropTypes.bool.isRequired,
            fact: PropTypes.string,
        }).isRequired
    }).isRequired,
    stream_entity: PropTypes.shape({
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            content1: PropTypes.string,
            content2: PropTypes.string,
            iframe: PropTypes.string
        })
    }).isRequired,
    lesson: PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        is_answer_keys_shown: PropTypes.bool
    }),
    stream_id: PropTypes.number,
    is_preview: PropTypes.bool.isRequired,
    is_latest: PropTypes.bool.isRequired,
    is_active: PropTypes.bool,
    refComponent: PropTypes.func
}

export default BaseCard
