import React from 'react'

import {isNumber} from 'lodash'

import HTMLContent from 'common/HTMLContent'
import {convertViaDOM} from 'utils/dom'
import {convertMath} from 'utils/math'
import {convertSpelling} from 'utils/spelling'


export const processStringContent = (str, options={}) => {
    if (!str) {
        return str
    }

    const {
        className="",
        keep_html=true,
        convert_html=true,
        convert_math=true,
        convert_spelling=true
    } = options

    let str_new = str

    if (!keep_html) {
        str_new = str_new.replace(/(<([^>]+)>)/ig, '')
    }

    if (convert_math) {
        str_new = convertMath(str_new)
    }

    str_new = convertViaDOM(str_new, (elem) => {
        let text_new = elem.textContent

        if (convert_spelling) {
            text_new = convertSpelling(text_new)
        }

        elem.replaceWith(text_new)
    })

    if (convert_html) {
        return (
            <HTMLContent
                className={className}
                html_str={str_new}
            />
        )
    } else {
        return str_new
    }
}

export const replaceSpecialChars = (string, options = {}) => {
    const {
        convert_katex = true,
    } = options;

    let searches = [
        {
            search: /’/g,
            replace: "'"
        },
        {
            search: /&nbsp;/g,
            replace: ' '
        },
        {
            search: /`/g,
            replace: '\''
        },
        {
            search: /“/g,
            replace: ''
        },
        {
            search: /"/g,
            replace: ''
        },
        {
            search: /\\medspace/g,
            replace: ''
        }
    ];

    if (convert_katex) {
        searches.push({
            search: /(\\[\(\[])(.*?)(\\[\)\]])/ig,
            replace: ' '
        });
        searches.push({
            search: /\(\s*\)/ig,
            replace: ''
        });
    }

    searches.map(i => {
        if (isNumber(string)) {
            string = '' + string;
        }
        if (null !== string) {
            string = string.replace(i.search, i.replace);
        }
    });

    return string;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
