import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page3'>
            <div className='contents'>
                <div className='title'>Let’s look into your Challenge progress!</div>
                <div className='subtitle'>You have joined {data.number_challenges} Mindsets Challenges!</div>
                <div className='challenges'>
                    {data.challenges.map(challenge => {
                        let backgroundColor = '#D8D8D8';
                        if (challenge.percentage <= 20) {
                            backgroundColor = '#FF6D02';
                        } else if (challenge.percentage <= 50) {
                            backgroundColor = '#F5BE1E';
                        } else if (challenge.percentage <= 80) {
                            backgroundColor = '#1DD318';
                        } else {
                            backgroundColor = '#4286F4';
                        }
                        return (
                            <div className='challenge' key={challenge.code}>
                                <div className='name'>{challenge.name}</div>
                                <div className='percentage'>
                                    <div className='fill' style={{
                                        width: challenge.percentage + '%',
                                        backgroundColor
                                    }}/>
                                </div>
                                <div className='completion'>{challenge.completion}</div>
                                <img
                                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/challenge_${challenge.code}.png`}
                                    alt={challenge.name}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}