import React, {Component} from 'react'
import PropTypes from 'prop-types'

import ReadOnly from 'common/challengeNew/CardComponent/AnswerComponent/Expression/ReadOnly'
import MathInput from 'common/challengeNew/CardComponent/AnswerComponent/Expression/MathInput'


class ExpressionSingle extends Component {
    renderReadOnly() {
        if (!this.props.is_readonly) {
            return null
        }

        return (
            <ReadOnly
                is_readonly={this.props.is_readonly}
                default_answer={this.props.answer}
            />
        )
    }

    renderMathInput() {
        if (this.props.is_readonly) {
            return null
        }

        return (
            <MathInput
                value={this.props.answer}
                onChange={this.props.onChange}
                button_sets={this.props.button_sets}
            />
        )
    }

    render() {
        return (
            <div className="challenge-component-expression">
                <div className="challenge-component-expression-width">
                    {this.renderReadOnly()}

                    {this.renderMathInput()}
                </div>
            </div>
        )
    }
}

ExpressionSingle.propTypes = {
    answer: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    button_sets: PropTypes.array.isRequired,
    is_readonly: PropTypes.bool.isRequired
}

export default ExpressionSingle
