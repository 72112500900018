import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction, LoadingAction, SuccessMessageAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';


function StudentIcon({stream}) {
    const dispatch = useDispatch();
    const show_student = useSelector(state => state.teacher_facilitation_show_student);

    function changeStreamStatus(status) {
        dispatch(LoadingAction(true));

        MindsetsJsSDK().Api.stream.status.update(
            stream.id, {status}
        ).then(() => {
            dispatch(SuccessMessageAction(`${stream.user.full_name} has been ${status}`));
        }).catch(() => {
            dispatch(ErrorMessageAction('An unexpected error occurred'));
        }).then(() => {
            dispatch(LoadingAction(false));
        });

        switch (status) {
            case 'paused':
                analytic.log('facilitationPauseStudentStream', stream.code, stream.user);
                break;
            case 'started':
                analytic.log('facilitationResumeStudentStream', stream.code, stream.user);
                break;
        }
    }

    function renderPaused() {
        return (
            <i
                className="overview-student-avatar-paused far fa-play-circle"
                onClick={() => changeStreamStatus(`started`)}
            />
        );
    }

    function renderHand() {
        const onClick = () => {
            dispatch(LoadingAction(true, 'Lowering hand'));

            MindsetsJsSDK().Api.stream.update(
                stream.id, {raise_hand_at: null}
            ).then(() => {
                dispatch(LoadingAction(true, 'Hand lowered'));

                analytic.log(
                    'facilitationLowerHand',
                    stream.lesson.challenge_version.name,
                    stream.lesson.code,
                    stream.id
                )
            }).catch((error) => {
                console.error(error);
            }).then(() => {
                setTimeout(() => {
                    dispatch(LoadingAction(false));
                }, 1000);
            });
        }

        return (
            <i
                className="overview-student-avatar-hand fas fa-hand-sparkles"
                onClick={onClick}
                title="lower hand"
            />
        );
    }

    function renderHide() {
        return (
            <i className="overview-student-avatar-hide fas fa-user-graduate"/>
        );
    }

    function renderAvatar() {
        return (
            <div className={`cashtivity-icon ${stream.user.avatar}`}/>
        );
    }

    if (stream.status === 'paused') {
        return renderPaused();
    } else if (stream.raise_hand_at) {
        return renderHand();
    } else if (!show_student) {
        return renderHide();
    } else {
        return renderAvatar();
    }
}

StudentIcon.propTypes = {
    stream: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        raise_hand_at: PropTypes.number,
        user: PropTypes.shape({
            avatar: PropTypes.string.isRequired
        }).isRequired,
        lesson: PropTypes.shape({
            code: PropTypes.string.isRequired,
            challenge_version: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    }).isRequired
}

export default StudentIcon
