import {get} from 'lodash'

export default class Graphing {
    render(cells, answer) {
        const answer_option = get(answer, 'data.answer.answer_option', '');
        if (answer_option === 'paper') {
            cells.push('This student has not selected the online graph tool for this question.');
        } else if (answer_option === 'tool') {
            cells.push(`${window.location.origin}/view/answer/graphing/${answer.id}`);
        } else {
            throw 'Invalid graphing answer option';
        }
    }
}
