import React from 'react';

import GuestLayout from "./layouts/GuestLayout";
import MainLayout from "./layouts/MainLayout";
import NoHeaderLayout from "./layouts/NoHeaderLayout";
import OnlyFooterLayout from "./layouts/OnlyFooterLayout";
import App from "./App";

import AnswerGraphing from "./pages/AnswerGraphing";
import ChallengeGuidePDF from "./pages/ChallengeGuidePDF";
import ChallengePreview from "./pages/ChallengePreview";
import ChallengeResources from "./pages/ChallengeResources";
import ClassLinkCallback from "./pages/ClassLinkCallback";
import CleverCallback from "./pages/CleverCallback";
import CurriculumMapPDF from "./pages/CurriculumMapPDF";
import DistrictDashboard from "./pages/DistrictDashboard";
import Forbidden from "./pages/Forbidden";
import GeneralError from "./pages/GeneralError";
import Home from "./pages/Home";
import JoinLesson from "./pages/JoinLesson";
import JoinLessonForce from "./pages/JoinLessonForce";
import InsightsDashboards from "./pages/InsightsDashboards";
import InsightsDashboard from "./pages/InsightsDashboard";
import InvalidPersona from "./pages/InvalidPersona";
import LessonMembers from "./pages/LessonMembers";
import Lessons from "./pages/Lessons";
import LessonsCoTeaching from "./pages/LessonsCoTeaching";
import Library from "./pages/Library";
import Login from "./pages/Login";
import NoMatch from "./pages/nomatch";
import PAMChallenge from "./pages/PAMChallenge";
import PasswordForgot from "./pages/PasswordForgot";
import PasswordReset from "./pages/PasswordReset";
import PDStream from "./pages/PDStream";
import ProfessionalDevelopmentLibrary from './pages/ProfessionalDevelopmentLibrary';
import Profile from "./pages/Profile";
import ProjectExtensionLibrary from "./pages/ProjectExtensionLibrary";
import RubricPDF from "./pages/RubricPDF";
import SAMDashboard from "./pages/SAMDashboard";
import SAMEngagement from "./pages/SAMEngagement";
import SAMTeacher from "./pages/SAMTeacher";
import SchoolDashboard from "./pages/SchoolDashboard";
import StripeCallback from "./pages/StripeCallback";
import StripeSuccess from "./pages/StripeSuccess";
import StudentDashboard from "./pages/StudentDashboard";
import StudentStory from "./pages/StudentStory";
import StudentStream from "./pages/StudentStream";
import TeacherClassSetup from "./pages/TeacherClassSetup";
import TeacherContactUs from "./pages/TeacherContactUs";
import TeacherFacilitation from "./pages/TeacherFacilitation";
import TeacherPDBeginner from "./pages/TeacherPDBeginner";
import TeacherPDEntry from "./pages/TeacherPDEntry";
import TeacherPDCertificatePDF from "./pages/TeacherPDCertificatePDF";
import TeacherQuote from "./pages/TeacherQuote";
import TeamsChallengeLibrary from "./pages/TeamsChallengeLibrary";
import TeamsChallengePreview from "./pages/TeamsChallengePreview";
import TeamsConfig from "./pages/TeamsConfig";
import WowMoment from "./pages/WowMoment";
import WowMomentSave from "./pages/WowMomentSave";

export default [
    {
        path: '/',
        element: <App/>,
        errorElement: <GeneralError/>,
        children: [
            {
                path: '',
                element: <MainLayout/>,
                children: [
                    {
                        path: '',
                        element: <Home/>
                    },
                    {
                        path: 'challenge',
                        children: [
                            {
                                path: ':challenge_code/guide.pdf',
                                element: <ChallengeGuidePDF/>
                            },
                            {
                                path: ':challenge_version_id/practice/:practice_identifier/rubric.pdf',
                                element: <RubricPDF/>
                            }
                        ]
                    },
                    {
                        path: 'curriculum/:curriculum_code/map.pdf',
                        element: <CurriculumMapPDF/>
                    },
                    {
                        path: 'district',
                        element: <DistrictDashboard/>
                    },
                    {
                        path: 'engagement',
                        children: [
                            {
                                path: 'overview',
                                element: <SAMDashboard/>
                            },
                            {
                                path: 'school',
                                element: <SAMEngagement/>
                            },
                            {
                                path: 'teacher',
                                element: <SAMTeacher/>
                            }
                        ]
                    },
                    {
                        path: 'explore',
                        children: [
                            {
                                path: ':challenge_code',
                                children: [
                                    {
                                        path: '',
                                        element: <ChallengePreview/>
                                    },
                                    {
                                        path: 'resources',
                                        element: <ChallengeResources/>
                                    }
                                ]
                            },
                            {
                                path: 'school',
                                element: <SAMEngagement/>
                            },
                            {
                                path: 'teacher',
                                element: <SAMTeacher/>
                            }
                        ]
                    },
                    {
                        path: 'join',
                        children: [
                            {
                                path: ':lesson_code',
                                children: [
                                    {
                                        path: '',
                                        element: <JoinLesson/>
                                    },
                                    {
                                        path: 'force',
                                        element: <JoinLessonForce/>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'invalid-persona',
                        element: <InvalidPersona/>
                    },
                    {
                        path: 'pam_blu',
                        element: <PAMChallenge/>
                    },
                    {
                        path: 'insights',
                        element: <InsightsDashboards/>
                    },
                    {
                        path: 'insights/:dashboard_id',
                        element: <InsightsDashboard/>
                    },
                    {
                        path: 'profile',
                        element: <Profile/>
                    },
                    {
                        path: 'quote',
                        element: <TeacherQuote/>
                    },
                    {
                        path: 'school',
                        children: [
                            {
                                path: '',
                                element: <SchoolDashboard/>
                            },
                            {
                                path: ':school_id',
                                element: <SchoolDashboard/>
                            }
                        ]
                    },
                    {
                        path: 'teacher',
                        children: [
                            {
                                path: 'contact-us',
                                element: <TeacherContactUs/>
                            },
                            {
                                path: 'class',
                                children: [
                                    {
                                        path: ':code',
                                        children: [
                                            {
                                                path: 'setup',
                                                element: <TeacherClassSetup/>
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                path: 'home',
                                element: <Library/>
                            },
                            {
                                path: 'project-extension',
                                element: <ProjectExtensionLibrary/>
                            },
                            {
                                path: 'classes',
                                element: <Lessons/>
                            },
                            {
                                path: 'co-classes',
                                element: <LessonsCoTeaching/>
                            },
                            {
                                path: 'professional-development',
                                element: <ProfessionalDevelopmentLibrary/>
                            },
                            {
                                path: 'pd',
                                children: [
                                    {
                                        path: 'entry',
                                        element: <TeacherPDEntry/>
                                    },
                                    {
                                        path: 'beginner',
                                        element: <TeacherPDBeginner/>
                                    },
                                    {
                                        path: 'certificate/:stream_id',
                                        element: <TeacherPDCertificatePDF/>
                                    }
                                ]
                            },
                            {
                                path: 'wow-moments',
                                children: [
                                    {
                                        path: 'save',
                                        children: [
                                            {
                                                path: '',
                                                element: <WowMomentSave/>
                                            },
                                            {
                                                path: ':wowMomentId',
                                                element: <WowMomentSave/>
                                            }
                                        ]
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        path: 'student',
                        children: [
                            {
                                path: 'home',
                                element: <StudentDashboard/>
                            }
                        ]
                    },
                    {
                        path: 'welcome',
                        element: <StripeSuccess/>
                    }
                ]
            },
            {
                path: 'auth',
                element: <GuestLayout/>,
                children: [
                    {
                        path: 'clever/callback',
                        element: <CleverCallback/>
                    },
                    {
                        path: 'classlink/callback',
                        element: <ClassLinkCallback/>
                    },
                    {
                        path: 'stripe/callback',
                        element: <StripeCallback/>
                    }
                ]
            },
            {
                path: 'login',
                element: <GuestLayout/>,
                children: [
                    {
                        path: '',
                        element: <Login/>
                    }
                ]
            },
            {
                path: 'password',
                element: <GuestLayout/>,
                children: [
                    {
                        path: 'p_forgot',
                        element: <PasswordForgot/>
                    },
                    {
                        path: 'p_reset',
                        element: <PasswordReset/>
                    }
                ]
            },
            {
                path: 'hello/:code',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: '',
                        element: <StudentStream/>
                    },
                ]
            },
            {
                path: 'teacher',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: 'class',
                        children: [
                            {
                                path: ':code',
                                children: [
                                    {
                                        path: '',
                                        element: <TeacherFacilitation/>
                                    },
                                    {
                                        path: 'teachers',
                                        element: <LessonMembers/>
                                    }
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                path: 'teams',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: 'challenge_library',
                        element: <TeamsChallengeLibrary/>
                    },
                    {
                        path: 'challenge_preview/:challenge_code',
                        element: <TeamsChallengePreview/>
                    },
                    {
                        path: 'config',
                        element: <TeamsConfig/>
                    }
                ]
            },
            {
                path: 'view/answer/graphing/:answer_code',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: '',
                        element: <AnswerGraphing/>
                    }
                ],
            },
            {
                path: 'wow-moments/:wowMomentCode',
                element: <OnlyFooterLayout/>,
                children: [
                    {
                        path: '',
                        element: <WowMoment/>
                    }
                ],
            },
            {
                path: 'insights/:dashboard_id/printable',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: '',
                        element: <InsightsDashboard printable={true}/>
                    }
                ]
            },
            {
                path: 'pd/s/:code',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: '',
                        element: <PDStream/>
                    },
                ]
            },
            {
                path: 'student/story/:student_id',
                element: <NoHeaderLayout/>,
                children: [
                    {
                        path: '',
                        element: <StudentStory/>
                    }
                ]
            },
        ]
    },
    {
        path: '/404',
        element: <GuestLayout/>,
        children: [
            {
                path: '',
                element: <NoMatch/>
            }
        ]
    },
    {
        path: '/403',
        element: <GuestLayout/>,
        children: [
            {
                path: '',
                element: <Forbidden/>
            }
        ]
    },
    {
        path: '*',
        element: <GuestLayout/>,
        children: [
            {
                path: '*',
                element: <NoMatch/>
            }
        ]
    }
];
