import React from 'react'
import {useSelector} from "react-redux";

import ButtonCopyText from 'common/ButtonCopyText'
import analytic from 'utils/Analytic'

export default function () {
    const lesson = useSelector(state => state.lesson);

    function getInstructionText() {
        return `1. Go to ${window.location.origin} \n`
            + `2. Sign up or Sign in \n`
            + `3. Use this code to join: \n`
            + lesson.code.toUpperCase()
    }

    function whenCopyCode() {
        analytic.log(
            'classSetupCopyCode',
            lesson.code
        );
    }

    return (
        <div className="class-share-content-code">
            <h5 className="header-new">
                {`Student Instructions`}
            </h5>

            <div>
                <div>
                    {`1. Go to `}
                    {window.location.origin}
                </div>
                <div>
                    {`2. Sign up or Sign in`}
                </div>
                <div>
                    {`3. Use this code to join:`}
                </div>
            </div>

            <div className="flex-row flex-v-center flex-wrap flex-box-5-10">
                <div>
                    <div className="content-code-code">
                        {lesson.code}
                    </div>
                </div>

                <div>
                    <ButtonCopyText
                        text_to_copy={getInstructionText()}
                        whenClick={whenCopyCode}
                    />
                </div>
            </div>
        </div>
    );
}
