import {get} from 'lodash';

import {replaceSpecialChars} from 'utils/content';

export default class AnswerChoice {
    render(cells, options, answer) {
        const answer_content = get(answer, 'data.answer');
        if (answer_content !== undefined) {
            if (Array.isArray(answer_content)) {
                answer_content.map(answer => cells.push(replaceSpecialChars(options[answer])))
            } else {
                cells.push(replaceSpecialChars(options[answer_content]));
            }
        }
    }
}
