import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';

import './zoom_tool.scss';
import {SetPAMZoomAction} from "../../actions/pam_actions";

function ZoomTool({step}) {
    const dispatch = useDispatch();
    const zoom = useSelector(state => state.pam_zoom);

    function renderZoomOut() {
        const onClick = () => {
            const new_zoom_level = zoom - step
            if (new_zoom_level > 0) {
                dispatch(SetPAMZoomAction(new_zoom_level));
            }
        }

        return (
            <i className="zoom-tool-icon fas fa-search-minus" onClick={onClick}/>
        );
    }

    function renderZoomLevel() {
        return (
            <div className="zoom-tool-text">
                {`${zoom}%`}
            </div>
        );
    }

    function renderZoomIn() {
        const onClick = () => {
            dispatch(SetPAMZoomAction(zoom + step));
        }

        return (
            <i className="zoom-tool-icon fas fa-search-plus" onClick={onClick}/>
        );
    }

    return (
        <div className="zoom-tool">
            <div className="flex-row flex-v-center">
                <div>
                    {renderZoomOut()}
                </div>
                <div>
                    {renderZoomLevel()}
                </div>
                <div>
                    {renderZoomIn()}
                </div>
            </div>
        </div>
    );
}

ZoomTool.propTypes = {
    step: PropTypes.number.isRequired,
}

export default ZoomTool
