import React from 'react';
import {get, isEqual} from 'lodash';

import BaseAnswer from './BaseAnswer'
import NumberLineOld from 'common/challenge/NumberLineOld'


export default class NumberLine extends BaseAnswer {
    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.existing_answer, prevProps.existing_answer)) {
            if (this.props.is_preview) {
                const answer = this.initAnswer()
                this.setState({answer})
            }
        }
    }

    defaultAnswer() {
        return get(this.props.component_data, 'data.initial_pos', 0)
    }

    isValid() {
        return true
    }

    onChange() {
        return answer => {
            this.setState({answer})
        }
    }

    render() {
        return (
            <div className="challenge-component-numberline">
                <NumberLineOld
                    value={this.state.answer}
                    data={this.props.component_data.data}
                    updateAnswer={this.onChange()}
                    readonly={this.props.is_preview}
                />
            </div>
        )
    }
}
