import IntroCard from './IntroCard'
import WelcomeCard from './WelcomeCard'
import PollCard from './PollCard'
import WinnerCard from './WinnerCard'
import InstructionCard from './InstructionCard'
import QuestionCard from './QuestionCard'
import CompleteCard from './CompleteCard'
import UnlockCard from './UnlockCard'
import FinalCard from './FinalCard'


export const getCardClass = (stream_entity) => {
    switch (stream_entity.type) {
        case 'intro':
            return IntroCard
        case 'welcome':
            return WelcomeCard
        case 'poll':
            return PollCard
        case 'winner':
            return WinnerCard
        case 'instruction':
            return InstructionCard
        case 'question':
            return QuestionCard
        case 'complete':
            return CompleteCard
        case 'unlock':
            return UnlockCard
        case 'final':
            return FinalCard
    }

    throw 'Invalid stream entity'
}