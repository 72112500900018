import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

function NoMatch({title, content}) {
    function renderTitle() {
        if (title) {
            return <div className="not-found-title">{title}</div>;
        }

        return null;
    }

    function renderContent() {
        if (content) {
            return <h5>{content}</h5>;
        }

        return null;
    }

    return (
        <div className="container">
            <div className="section center not-found">
                <div className="not-found-title">UH-OH</div>
                {renderTitle()}
                {renderContent()}
                <div className="not-found-back">
                    <span>Return to the</span>&nbsp;
                    <Link to="/">home page</Link><span>.</span>
                </div>
                <div className="not-found-contact-us">
                    <div className="flex-row flex-vh-center flex-box-2-5">
                        <i className="far fa-envelope"/>
                        <a href={`/teacher/contact-us`}>Contact us</a>
                    </div>
                </div>
                <img className="not-found-image" src={process.env.PUBLIC_S3_ASSETS_URL + '/application/404.png'}
                     alt="404 Page not found"/>
            </div>
        </div>
    );
}

NoMatch.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string
};

export default NoMatch;
