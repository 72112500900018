import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Textarea from 'react-textarea-autosize'
import {isEmpty, trim} from 'lodash'

import {processStringContent} from 'utils/content'


class SpreadsheetEditableField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.data.title === props.data.placeholder ? '' : props.data.title
        }
    }

    isValid() {
        return !isEmpty(trim(this.state.value))
    }

    isEdited() {
        return this.state.value != this.props.data.title
    }

    getValue() {
        return this.state.value
    }

    getPlaceholder() {
        return processStringContent(this.props.data.placeholder, {
            convert_html: false,
            convert_math: false
        })
    }

    onChange() {
        return e => {
            e.preventDefault()
            if (!this.props.is_preview) {
                this.setState({value: e.target.value})
            }
        }
    }

    render() {
        return (
            <Textarea
                className="spreadsheet-textarea"
                value={this.state.value}
                placeholder={this.getPlaceholder()}
                onChange={this.onChange()}
            >
            </Textarea>
        )
    }
}

SpreadsheetEditableField.propTypes = {
    data: PropTypes.object.isRequired,
    is_preview: PropTypes.bool.isRequired
}

export default SpreadsheetEditableField
