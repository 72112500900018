import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {LoadingAction, JoinLessonAction} from "mindsets-js-sdk";

import ChallengeTile from 'common/ui/ChallengeTile';
import analytic from 'utils/Analytic';

function ALesson({lesson}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function joinNow() {
        dispatch(JoinLessonAction(lesson.code)).then((response) => {
            analytic.log('joinOpenChallengeLesson', lesson.code);
            navigate('/hello/' + response.stream_code);
        }).catch(error => console.error(error)).then(() => {
            dispatch(LoadingAction(false));
        });
    }

    return (
        <div>
            <ChallengeTile
                challenge_version={lesson.challenge_version}
                show_kid={true}
                description={lesson.code.toUpperCase()}
                button_label={`Join Now`}
                buttonOnClick={joinNow}
            />
        </div>
    );
}

ALesson.propTypes = {
    lesson: PropTypes.shape({
        code: PropTypes.string.isRequired,
        challenge_version: PropTypes.object.isRequired
    }).isRequired
}

export default ALesson;
