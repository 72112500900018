import {get, find, isString, isPlainObject, sum, filter, head} from 'lodash';

import {processStringContent, replaceSpecialChars} from 'utils/content';
import {isBonus} from 'utils/challenge/streamEntity';
import Answer from './Answer';
import Expression from './Expression';
import Graphing from './Graphing';
import Poll from './Poll';
import Spreadsheet from './Spreadsheet';
import AnswerChoice from "./AnswerChoice";
import {getQuestionID} from 'utils/challenge/challengeComponent'
import InlinePollResponse from "./InlinePollResponse";


export default class DataCompiler {
    compile(rows, streams, stream_entities, lesson) {
        const is_new = this.isNewDataModel(lesson);
        if (is_new) {
            rows.push(['Student', 'Question', 'Answer(s)']);
        } else {
            rows.push(['Student', 'Question type', 'Question', 'Answer(s)']);
        }

        streams
            .map((stream) => {
                stream_entities
                    .filter(stream_entity => {
                        if (is_new) {
                            const rows = get(stream_entity, 'data.card_content.rows', []);
                            return sum(
                                rows.map((row) => {
                                    const components = get(row, 'components', []);
                                    return filter(components, (c) => {
                                        return c.type === 'question' || c.type === 'poll.block';
                                    }).length;
                                })
                            ) > 0;
                        } else {
                            let no_questions = 0;
                            let no_polls = 0;
                            let no_spreadsheets = 0;
                            if (Array.isArray(stream_entity.questions)) {
                                no_questions = stream_entity.questions.filter(question => this.questionRequiresAnswer(question)).length
                            }
                            if (Array.isArray(stream_entity.polls)) {
                                no_polls = stream_entity.polls.length
                            }
                            if (Array.isArray(stream_entity.spreadsheets)) {
                                no_spreadsheets = stream_entity.spreadsheets.filter(spreadsheet => this.spreadsheetRequiresAnswer(spreadsheet)).length
                            }

                            return no_questions + no_polls + no_spreadsheets > 0;
                        }
                    })
                    .map((stream_entity) => {
                        stream.stream_entities
                            .filter((user_data) => user_data.id === stream_entity.id)
                            .map((user_data) => {
                                if (is_new) {
                                    this.compileNew(rows, stream.user, stream_entity, user_data)
                                } else {
                                    this.compileOld(rows, stream.user, stream_entity, user_data);
                                }
                            });
                    });
            });

        return rows;
    }

    compileOld(rows, user, stream_entity, user_data) {
        if (Array.isArray(user_data.answers) && user_data.answers.length) {
            user_data.answers.map((answer, index) => {
                let cells = [];
                cells.push(user.fname + ' ' + head(user.lname));
                cells.push(this.getQuestionType(stream_entity, index));
                cells.push(this.getOldQuestion(stream_entity, index));
                const answerClass = new Answer;
                answerClass.render(cells, answer);
                rows.push(cells);
            });
        }
        if (Array.isArray(user_data.votes) && user_data.votes.length) {
            user_data.votes.map((vote, index) => {
                let cells = [];
                cells.push(user.fname + ' ' + head(user.lname));
                cells.push(this.getPollType(stream_entity, index));
                cells.push(this.getOldQuestion(stream_entity, index));
                const poll = new Poll();
                poll.render(cells, vote);
                rows.push(cells);
            });
        }
        if (Array.isArray(user_data.user_spreadsheets) && user_data.user_spreadsheets.length) {
            user_data.user_spreadsheets.map((user_spreadsheet, index) => {
                let cells = [];
                cells.push(user.fname + ' ' + head(user.lname));
                cells.push('Spreadsheet');
                cells.push(this.getOldQuestion(stream_entity, index));
                const spreadsheet = new Spreadsheet();
                spreadsheet.render(cells, user_spreadsheet);
                rows.push(cells);
            });
        }
    }

    compileNew(rows, user, stream_entity, user_data) {
        const polls = stream_entity.polls;
        const user_votes = user_data.votes;
        const responses = user_data.responses;

        stream_entity.data.card_content.rows.map((row) => {
            row.components.map((component) => {
                let cells = [];
                cells.push(user.fname + ' ' + head(user.lname));
                cells.push(this.getQuestion(stream_entity, component));

                const question_id = getQuestionID(component, stream_entity.questions)
                const poll = find(polls, (p) => p.question_id == question_id)
                const response = find(responses, {question_id})

                switch (true) {
                    case component.type.startsWith('answer.'):
                        if (!response) {
                            break
                        }
                        switch (true) {
                            case component.type === 'answer.graphing':
                                new Graphing().render(cells, response);
                                break;
                            case component.type.startsWith('answer.expression'):
                                new Expression().render(cells, response);
                                break;
                            case component.type.startsWith('answer.choice_'):
                                new AnswerChoice().render(cells, component.data.options, response);
                                break;
                            default:
                                new Answer().render(cells, response);
                        }
                        break;
                    case component.type === 'poll.inline':
                        if (!poll) {
                            break
                        }
                        if (response) {
                            InlinePollResponse(cells, poll, response);
                        }
                        break;
                    case component.type === 'poll.block':
                        // In case of Poll block, question_id is undefined
                        if (!poll) {
                            break
                        }
                        const vote = find(user_votes, (v) => v.poll_id === poll.id);
                        if (vote) {
                            new Poll().render(cells, vote);
                        }
                        break;
                    case component.type === 'spreadsheet':
                        if (response) {
                            new Spreadsheet().render(cells, response);
                        }
                        break;
                }

                if (cells.length > 2) {
                    rows.push(cells);
                }
            })
        });
    }

    getOldQuestion(stream_entity, index) {
        let title = '';
        if (stream_entity.data.card_no) {
            title += (isBonus(stream_entity) ? 'B' : 'Q') + stream_entity.data.card_no + ': ';
        }
        if (get(stream_entity, ['questions', index, 'title'])) {
            title += get(stream_entity, ['questions', index, 'title']);
        } else if (stream_entity.data.title) {
            title += stream_entity.data.title;
        } else if (get(stream_entity, ['polls', index, 'description'])) {
            title += get(stream_entity, ['polls', index, 'description'])
        }

        return processStringContent(
            replaceSpecialChars(title),
            {
                keep_html: false,
                convert_html: false,
                convert_math: false
            }
        ).substr(0, 500);
    }

    getQuestionType(stream_entity, index) {
        switch (get(stream_entity, ['questions', index, 'type'])) {
            case 'single':
            case 'multiple':
                return 'Multiple choice';
            case 'slider':
            case 'range':
            case 'numberline':
                return 'Slider';
            default:
                return 'Open answer';
        }
    }

    getPollType(stream_entity, index) {
        switch (get(stream_entity, ['polls', index, 'type'])) {
            case 'inline':
                return 'Multiple choice';
            default:
                return 'Poll';
        }
    }

    getQuestion(stream_entity, component) {
        const q_number = component.data && component.data.question_number ? component.data.question_number : null;
        if (q_number) {
            let question_component;
            find(stream_entity.data.card_content.rows, (row) => {
                return question_component = find(row.components, (component) => {
                    return get(component, ['data', 'question_number']) === q_number && 'question' === get(component, ['type']);
                });
            });
            if (question_component) {
                const question_text = processStringContent(
                    replaceSpecialChars(get(question_component, ['data', 'question']), {convert_katex: false}),
                    {
                        keep_html: false,
                        convert_html: false,
                        convert_math: false
                    }
                ).substr(0, 500);
                return 'Q' + q_number + ': ' + question_text;
            } else {
                return '';
            }
        } else if (component.data && component.type.startsWith('poll.')) {
            if (component.data.title) {
                return component.data.title;
            } else {
                return 'Poll';
            }
        } else {
            return '';
        }
    }

    isNewDataModel(lesson) {
        return lesson.challenge_version.data_version > 0;
    }

    spreadsheetRequiresAnswer(spreadsheet) {
        let isRequired = false;
        spreadsheet.data.map(row => {
            Object.keys(row).map(cell_key => {
                const cell = row[cell_key];
                if ((isString(cell) && cell.length === 0) || (isPlainObject(cell) && cell.hasOwnProperty('editable') && true === cell.editable)) {
                    isRequired = true;
                }
            });
        });

        return isRequired;
    }

    questionRequiresAnswer(question) {
        return question.type !== 'nullable';
    }
}
