import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {isString} from 'lodash';
import {actions, authUtils, rolesConstants} from 'mindsets-js-sdk';

import analytic from 'utils/Analytic';
import {LogInEmailAction, SignUpEmailAction} from "../../../actions/login_actions";


function ByEmail({persona, mode, action_text, terms, callback}) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [email_error, setEmailError] = useState(false);
    const [password_error, setPasswordError] = useState(false);
    const [confirm_password_error, setConfirmPasswordError] = useState(false);
    const [show_password, setShowPassword] = useState(false);

    useEffect(() => {
        setError(null);
        setEmailError(false);
        setPasswordError(false);
        setConfirmPasswordError(false);
    }, [mode]);

    function onEmailChange(e) {
        e.preventDefault();
        const value = e.target.value;
        setEmail(value);
        if (mode === 'signup' && !authUtils.isValidEmail(value)) {
            setEmailError(true);
            setError(null);
        } else {
            setEmailError(false);
            setError(null);
        }
    }

    function onPasswordChange(e) {
        e.preventDefault();
        const value = e.target.value;
        setPassword(value);
        if (mode === 'signup' && !authUtils.isValidPassword(e.target.value)) {
            setPasswordError(true);
            setError(null);
        } else {
            setPasswordError(false);
            setError(null);
        }
    }

    function onConfirmPasswordChange(e) {
        e.preventDefault();
        const value = e.target.value;
        setConfirmPassword(value);
        if (mode === 'signup' && e.target.value !== password) {
            setConfirmPasswordError(true);
            setError(null);
        } else {
            setConfirmPasswordError(false);
            setError(null);
        }
    }

    function onLogin(e) {
        e.preventDefault();
        setError(null);
        dispatch(LogInEmailAction({email, password, persona}))
            .then(callback)
            .catch(error => {
                setError(error);
                analytic.log('loginError');
            })
            .then(() => dispatch(actions.LoadingAction(false)));
    }

    function onSignUp(e) {
        e.preventDefault();
        if (!authUtils.isValidEmail(email)) {
            setError('Invalid email address');
            return;
        }
        if (!authUtils.isValidPassword(password)) {
            setError('Invalid password');
            return;
        }
        if (password !== confirm_password) {
            setError('Passwords do not match');
            return;
        }
        setError(null);
        dispatch(SignUpEmailAction({email, password, password_confirmation: password, persona}))
            .then(callback)
            .catch(error => {
                if (error.email && error.email[0]) {
                    setError(error.email[0]);
                } else if (isString(error)) {
                    setError(error);
                } else {
                    setError('Invalid details provided, try again.');
                }
                analytic.log('signUpError')
            })
            .then(() => dispatch(actions.LoadingAction(false)));
    }

    function onShowPassword(e) {
        e.preventDefault();
        setShowPassword(!show_password);
    }

    function renderForgotPassword() {
        if (mode === 'login') {
            return (
                <Link
                    className="forgot"
                    to='/password/p_forgot'
                    tabIndex="-1"
                    onClick={() => {
                        analytic.log('forgotPassword')
                    }}
                >
                    Forgot password?
                </Link>
            )
        }
    }

    function renderConfirmPassword() {
        if (mode !== 'signup') {
            return null;
        }

        return (
            <div className="flex-column mt-3">
                <label htmlFor='password'>
                    {`Confirm Password`}
                </label>
                <input
                    className={"input-new browser-default" + (confirm_password_error ? " error" : "")}
                    type="password"
                    name="confirm_password"
                    placeholder="Re-type password"
                    maxLength="30"
                    value={confirm_password}
                    onChange={onConfirmPasswordChange}
                />
                <div
                    className={'login-by-email-inline-error text-red' + (confirm_password_error ? "" : " invisible")}>
                    {`This must match the password above.`}
                </div>
            </div>
        );
    }

    return (
        <div className="login-by-email">
            <div className="section">
                <h4 className="header-new center-align">
                    {`Or use your Mindsets account:`}
                </h4>
            </div>

            <form
                onSubmit={mode === 'signup' ? onSignUp : onLogin}
                className="flex-column flex-vh-center"
            >
                <div className="flex-column">
                    <label htmlFor='email'>
                        School Email
                    </label>
                    <input
                        className={"input-new browser-default" + (email_error ? " error" : "")}
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        maxLength="50"
                        value={email}
                        onChange={onEmailChange}
                    />
                    <div
                        className={'login-by-email-inline-error text-red' + (email_error ? "" : " invisible")}>
                        {`Enter a valid email address.`}
                    </div>
                </div>

                <div className="flex-column mt-2">
                    <div className="flex-row flex-between">
                        <label htmlFor='password'>
                            {mode === 'signup' ? 'Create Password' : 'Password'}
                        </label>

                        <a
                            className="login-by-email-show-password"
                            href="#"
                            onClick={onShowPassword}
                            tabIndex="-1"
                        >
                            {show_password ? 'hide' : 'show'}
                        </a>
                    </div>
                    <input
                        className={"input-new browser-default" + (password_error ? " error" : "")}
                        type={show_password ? "text" : "password"}
                        name="password"
                        placeholder="At least 6 characters"
                        maxLength="30"
                        value={password}
                        onChange={onPasswordChange}
                    />
                    {renderForgotPassword()}
                    <div
                        className={'login-by-email-inline-error text-red' + (password_error ? "" : " invisible")}>
                        {`Choose a password with at least 6 characters.`}
                    </div>
                </div>

                {renderConfirmPassword()}

                <div className="login-by-email-error mt-2">
                    {error}
                </div>

                {terms}
                <div className=".login-by-email-mode-switcher">
                    <button
                        className="btn btn-challenge btn-large"
                        disabled={mode === 'signup' && (!email || !password || !confirm_password || email_error || password_error || confirm_password_error)}
                        onClick={mode === 'signup' ? onSignUp : onLogin}
                    >
                        {action_text}
                    </button>
                </div>
            </form>
        </div>
    )
}

ByEmail.propTypes = {
    persona: PropTypes.oneOf([rolesConstants.ROLE_TEACHER, rolesConstants.ROLE_STUDENT]).isRequired,
    mode: PropTypes.string.isRequired,
    action_text: PropTypes.string.isRequired,
    terms: PropTypes.object,
    callback: PropTypes.func.isRequired
};

export default ByEmail;
