import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

import GeneralError from "../pages/GeneralError";
import {LogInProviderAction} from "../actions/login_actions";

export default function () {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Authentication code not provided.');

    useEffect(() => {
        if (searchParams.has('session_id')) {
            dispatch(LogInProviderAction('stripe', {
                session_id: searchParams.get('session_id')
            }))
                .then(() => {
                    return window.location.href = '/welcome';
                })
                .catch(error => {
                    console.error('error: ', error);
                    dispatch(ErrorMessageAction('Sorry. There was a problem with your request. Please reload the page and try again or come back later.'));
                    setMessage(error);
                    setFailed(true);
                })
                .then(() => dispatch(LoadingAction(false)));
        } else {
            setFailed(true);
        }
    }, []);

    if (failed === true) {
        return <GeneralError title='Authentication failed.' content={message}/>;
    }

    return (
        <div>
            <div>
            </div>

            <div className="preloader-holder">
                <div className="preloader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className='preloader-content'>
                    {`Creating your Mindsets! account...`}
                </div>
            </div>
        </div>
    );
}
