import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {map, groupBy, find} from 'lodash';

import {getPracticeAttr} from '../utils';

function TableHeader({skills_data}) {
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const practices = useSelector(state => state.pam_practices);

    const selected_practice = find(practices, {id: selected_practice_id});

    function renderSkillCategories() {
        const has_category = getPracticeAttr(selected_practice.code, 'has_category')
        if (!has_category) {
            return null;
        }

        const skill_categories = groupBy(skills_data, 'category');
        return map(skill_categories, (skills, category_name) => {
            return (
                <th key={category_name} className="center-align" colSpan={skills.length}>
                    {category_name}
                </th>
            );
        });
    }

    function renderSkillHeaders() {
        return map(skills_data, (skill) => {
            return (
                <th key={skill.code} className="p-c-st-skill center-align">
                    {skill.code}
                    {`: `}
                    {skill.name}
                </th>
            );
        });
    }

    return (
        <thead>
        <tr>
            <th className="p-c-st-student" style={{border: "none"}}>
            </th>
            <th className="p-c-st-stage" style={{border: "none"}}>
            </th>
            {renderSkillCategories()}
        </tr>
        <tr>
            <th className="p-c-st-student center-align">
                {`Student`}
            </th>
            <th className="p-c-st-stage center-align">
                {`Engagement Stage`}
            </th>
            {renderSkillHeaders()}
        </tr>
        </thead>
    );
}

TableHeader.propTypes = {
    skills_data: PropTypes.array.isRequired
}

export default TableHeader