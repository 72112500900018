import React from 'react';
import {useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import {rolesConstants} from 'mindsets-js-sdk';

import Case from "./components/Case";
import useAuth from "../../hooks/useAuth";

export default function () {
    useAuth(true);

    const me = useSelector(state => state.me);
    let [searchParams] = useSearchParams();
    const query = JSON.parse(atob(searchParams.get('q')));

    function getCase() {
        if (query.hasOwnProperty('route_name') && 'student.join' === query.route_name) {
            return 'join';
        } else {
            return 'unknown';
        }
    }

    function getCaseText() {
        if (rolesConstants.ROLE_STUDENT !== me.persona && 'join' === getCase()) {
            return `Do you wish to join this class as a student?`;
        } else if (query.persona !== me.persona) {
            return `Are you a ${query.persona} or are you a ${me.persona}?`;
        } else {
            return `Do you wish to continue as a ${query.persona}?`;
        }
    }

    function getReturnText() {
        if (rolesConstants.ROLE_STUDENT === me.persona) {
            return 'Home page'
        } else {
            return 'Mindsets Library'
        }
    }

    function renderStaySignedIn() {
        if (query.persona !== me.persona) {
            return (
                <div className="section">
                    <div className="center-align text">If you prefer to stay signed in as a {me.persona},
                    </div>
                    <div className='center text'>
                        <Link to='/'>return to the {getReturnText()}</Link>
                    </div>
                </div>
            );
        }

        return null;
    }

    if (!me) {
        return null;
    }

    return (
        <div id='invalid-persona-page'>
            <div className="container">
                <div className="section center">
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/403.svg`} alt='OOPS'
                         className='happy-people'/>
                    <h3 className="header-new header center-align">We noticed you are signed in as
                        a {me.persona}</h3>
                    <div className="center-align text">{getCaseText()}</div>
                </div>
                <div className="invalid-persona-section">
                    <Case/>
                </div>
                {renderStaySignedIn()}
            </div>
        </div>
    );
}
