import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {get} from 'lodash';

import AnswerOld from './AnswerOld.js';
import {getFormattedAnswer} from './utils';


function Answer({stream}) {
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const selected_challenge_stream_entity = get(challenge_stream_entities, selected_card_index);
    const selected_stream_entity = get(stream.stream_entities, selected_card_index);

    function renderOldDataModelAnswers() {
        return (
            <AnswerOld
                selected_stream_entity={selected_stream_entity}
                selected_challenge_stream_entity={selected_challenge_stream_entity}
            />
        );
    }

    function renderAnswerRow(key, component_data, formatted_answer) {
        const renderQuestionNumber = () => {
            const question_number = get(component_data, 'data.question_number');
            if (question_number) {
                return (
                    <div className="stream-answer-row-question-number">
                        {`Q${question_number}:`}
                    </div>
                );
            }
        }

        return (
            <div key={key} className="stream-answer-row">
                <div className="flex-row flex-v-start flex-box-2-5">
                    {renderQuestionNumber()}

                    <div className="flex-auto stream-answer-row-content">
                        {formatted_answer}
                    </div>
                </div>
            </div>
        );
    }

    function renderAnswers() {
        const card_content = get(selected_challenge_stream_entity, 'data.card_content');

        if (!card_content) {
            // This is for old data model
            return renderOldDataModelAnswers();
        }

        const questions = selected_challenge_stream_entity.questions
        const polls = selected_challenge_stream_entity.polls
        const responses = selected_stream_entity.responses
        const user_votes = selected_stream_entity.votes

        return card_content.rows.map((row, r_key) => {
            return row.components.map((component_data, c_key) => {
                if (
                    component_data.type.startsWith('answer.')
                    ||
                    component_data.type.startsWith('poll.')
                    ||
                    component_data.type === 'spreadsheet'
                ) {
                    const formatted_answer = getFormattedAnswer(
                        component_data, questions, responses, polls, user_votes
                    );
                    if (formatted_answer) {
                        return renderAnswerRow(`${r_key}_${c_key}`, component_data, formatted_answer);
                    }
                }

                return null;
            });
        });
    }

    return (
        <div className="byquestion-stream-answer">
            {renderAnswers()}
        </div>
    );
}

Answer.propTypes = {
    stream: PropTypes.shape({
        index: PropTypes.number.isRequired
    }).isRequired
}

export default Answer
