import React from 'react';
import {useSelector} from "react-redux";
import {countriesConstants} from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);
    if (me && me.country) {
        return (
            <div className="flex-row user-info-field">
                <div className="user-info-field-label">Country</div>
                <div>{countriesConstants.COUNTRIES[me.country]}</div>
            </div>
        );
    }

    return null;
}
