import React from 'react';
import PropTypes from 'prop-types';
import {get, head} from 'lodash';

import {getStageName} from './utils';
import SkillCell from './SkillCell';

function StudentRow({student_data, uniq_skills}) {
    function renderName() {
        return (
            <td className="p-c-st-student center-align">
                {student_data.fname}
                {` `}
                {head(student_data.lname)}
            </td>
        );
    }

    function renderStage() {
        return (
            <td className="p-c-st-stage center-align">
                {getStageName(student_data.stage)}
            </td>
        );
    }

    function renderSkills() {
        return uniq_skills.map((skill) => {
            return (
                <SkillCell
                    key={skill.code}
                    skill_data={get(student_data, ['skills', skill.code])}
                />
            );
        });
    }

    return (
        <tr>
            {renderName()}
            {renderStage()}
            {renderSkills()}
        </tr>
    );
}

StudentRow.propTypes = {
    student_data: PropTypes.shape({
        stage: PropTypes.string.isRequired,
        fname: PropTypes.string.isRequired,
        lname: PropTypes.string,
        skills: PropTypes.object
    }).isRequired,
    uniq_skills: PropTypes.array.isRequired
}

export default StudentRow
