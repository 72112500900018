import {SET_DISTRICT} from "mindsets-js-sdk/src/react/actions/district_actions";

const district = (payload = null, action) => {
    switch (action.type) {
        case SET_DISTRICT:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    district
}
