import React from 'react';
import {useSelector} from "react-redux";
import {get} from "lodash";

import Card from 'common/challengeNew/Card';


export default function () {
    const lesson = useSelector(state => state.lesson);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const current_stream_entity = getSelectedChallengeStreamEntity();

    function getSelectedChallengeStreamEntity() {
        return get(challenge_stream_entities, selected_card_index);
    }

    function renderPreview() {
        if (current_stream_entity) {
            return (
                <div className="challenge-preview-adjustment">
                    <Card
                        challenge_version={lesson.challenge_version}
                        stream_entity={current_stream_entity}
                        lesson={lesson}
                        is_preview={true}
                    />
                </div>
            );
        }
    }

    return (
        <div className="byquestion-question-container">
            {renderPreview()}
        </div>
    );
}
