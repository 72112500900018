import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MindsetsJsSDK, {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const [items, setItems] = useState([]);

    function fetchData() {
        Promise.resolve()
            .then(() => dispatch(LoadingAction(true)))
            .then(() => MindsetsJsSDK().API
                .get('sam/completion/challenge', {
                    school_id: selected_school_id ?? '',
                    challenge_code_id: selected_challenge_id ?? '',
                    from,
                    till
                })
                .then(response => {
                    setItems(response.items.sort((a, b) => a.avg_completion - b.avg_completion));
                })
            )
            .then(() => dispatch(LoadingAction(false)))
            .catch(error => {
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
            });
    }

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        fetchData();
    }, [from, till, selected_school_id, selected_challenge_id]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Student Progress by Challenge'
                },
                plotOptions: {
                    column: {
                        maxPointWidth: 100
                    },
                    series: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y + '%</b>';
                            }
                        }
                    }
                },
                xAxis: {
                    title: null,
                    categories: items.map(item => item.challenge_name),
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: null,
                    labels: {
                        formatter: function () {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                    crosshairs: true
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                series: [
                    {
                        showInLegend: false,
                        data: items.map(item => Math.round(item.avg_completion)),
                        colorByPoint: true,
                        colors: items.map(item => item.challenge_color)
                    }
                ],
                credits: {
                    enabled: false
                }
            }}
        />
    );
}
