const analyticChallengePreview = (Analytic) => {
    Analytic.prototype.previewChallenge = function (challenge) {
        this.track('Preview Challenge', {
            challenge_code: challenge.code,
            challenge_name: challenge.name
        })
        this.increment("No. of Previews")
    }

    Analytic.prototype.previewPrevious = function (challenge, to_index) {
        this.track('Preview Previous', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            page_number: to_index + 1
        })
    }

    Analytic.prototype.previewNext = function (challenge, to_index) {
        this.track('Preview Next', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            page_number: to_index + 1
        })
    }

    Analytic.prototype.previewGoto = function (challenge, from_index, to_index) {
        this.track('Preview Goto', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            from_page_number: from_index + 1,
            to_page_number: to_index + 1
        })
    }

    Analytic.prototype.challengeGuideDownloaded = function (challenge) {
        this.track('Teacher Guide downloaded', {
            challenge_code: challenge.code,
            challenge_name: challenge.name
        })
    }

    Analytic.prototype.openProjectExtension = function (data) {
        this.track('Project Extension opened', data)
    }

    Analytic.prototype.openELAExtension = function (data) {
        this.track('ELA Extension opened', data)
    }

    Analytic.prototype.openChallengeLock = function (data) {
        this.track('Challenge Lock opened', data)
    }
}

export default analyticChallengePreview
