import React from 'react';


export default function Content0() {
    return (
        <div>
            <h3 className="header-new">
                {`Teaching with Mindsets - Getting Started`}
            </h3>

            <div className="section">
                <div className="flex-box-5-10">
                    <div>
                        <i className="far fa-clock"></i>
                        <span> </span>
                        <strong>{`Duration:`}</strong> <span>{`1 hour`}</span>
                    </div>

                    <div>
                        {`Mindsets is an instructional platform for authentic, real-world multi-step problem solving that your math students will love. `}
                        {`Mindsets helps you to create classroom experiences that guide students to connect classroom learning to their everyday lives.`}
                    </div>

                    <div>
                        {`In this course you will learn about the key features of Mindsets Challenges and best practices around sharing and assigning Mindsets Challenges to your students. `}
                        {`You will learn how Mindsets Challenges can be integrated into your current curriculum.`}
                    </div>

                    <div>
                        {`Mindsets is endorsed for remote teaching and learning!`}
                    </div>
                </div>
            </div>

            <h4 className="header-new">
                {`Learning Objectives`}
            </h4>

            <ul>
                <li>
                    {`Learn how to use key components of a Mindsets Challenge`}
                </li>
                <li>
                    {`Identify sequencing and timing options for Mindsets Challenges`}
                </li>
                <li>
                    {`Plan for using a Mindsets Challenge`}
                </li>
                <li>
                    {`Learn best practices around facilitating a Mindsets Challenge`}
                </li>
                <li>
                    {`Plan to implement a project-based extension of a Mindsets Challenge`}
                </li>
            </ul>
        </div>
    )
}
