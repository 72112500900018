import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import {processStringContent} from "utils/content";

const PollOption = function ({poll_option, show_graph, is_selected, option_votes, total_votes, saveVote}) {
    const class_name_poll_option = classNames('poll-option', {
        "poll-option-selected": is_selected,
        "z-depth-2": is_selected
    });
    const class_name_icon = classNames('poll-option-radio far', {
        "fa-dot-circle": is_selected,
        "fa-circle": !is_selected
    });
    const option_content = processStringContent(poll_option.option, {
        className: "flex-row flex-v-center"
    });

    function renderResult() {
        if (show_graph) {
            return (
                <div className="poll-option-result-container flex-row">
                    <div className="poll-bar flex-auto flex-row">
                        <div className="poll-bar-color" style={{flex: option_votes}}/>
                        <div style={{flex: total_votes - option_votes}}/>
                    </div>

                    <div className="poll-stat flex-row flex-vh-center">
                        <div>{option_votes}</div>
                        <i className="fas fa-user"/>
                    </div>
                </div>
            )
        }
    }

    return (
        <div onClick={saveVote}>
            <div className={class_name_poll_option}>
                <div className="poll-option-content-container">
                    <div className="flex-row flex-v-center">
                        <i className={class_name_icon}/>

                        <div className="flex-auto">
                            {option_content}
                        </div>
                    </div>
                </div>

                {renderResult()}
            </div>
        </div>
    );
}

PollOption.propTypes = {
    poll_option: PropTypes.shape({
        id: PropTypes.number.isRequired,
        option: PropTypes.string.isRequired
    }).isRequired,
    show_graph: PropTypes.bool.isRequired,
    is_selected: PropTypes.bool.isRequired,
    option_votes: PropTypes.number.isRequired,
    total_votes: PropTypes.number.isRequired,
    saveVote: PropTypes.func.isRequired
}

export default PollOption;
