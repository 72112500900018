import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import Popup from 'common/Popup';
import CardMenu from 'common/challenge/CardMenu';
import {getCardName} from 'utils/challenge/streamEntity';
import analytic from 'utils/Analytic';


function CardSelector(
    {
        challenge_version,
        stream_entities,
        selected_card_index,
        is_preview = false,
        onSelect,
        popup_setting = {
            right: '0px'
        }
    }
) {
    const popupRef = useRef();

    function onSelectCallback(i) {
        return (e) => {
            e.preventDefault()
            if (i !== selected_card_index) {
                onSelect(i)
            }
            popupRef.current.toggle(false)(e)

            analytic.log('cardSelectorOnSelect', selected_card_index)
        }
    }

    function renderPopup() {
        return (
            <div className="challenge-card-selector-popup">
                <CardMenu
                    challenge_version={challenge_version}
                    stream_entities={stream_entities}
                    selected_index={selected_card_index}
                    onClick={onSelectCallback}
                    is_preview={is_preview}
                />
            </div>
        );
    }

    function renderCurrent() {
        return (
            <div className="challenge-card-selector-current flex-row flex-h-center">
                <Popup ref={popupRef} popup_content={renderPopup()} {...popup_setting}>
                    <button className="btn btn-grey">
                        <span>
                            {getCardName(challenge_version, selected_card_index)}
                        </span>
                        <i className="fas fa-caret-down"/>
                    </button>
                </Popup>
            </div>
        );
    }

    return (
        <div className="challenge-card-selector flex-row">
            {renderCurrent()}
        </div>
    );
}

CardSelector.propTypes = {
    challenge_version: PropTypes.object.isRequired,
    stream_entities: PropTypes.array.isRequired,
    selected_card_index: PropTypes.number.isRequired,
    is_preview: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    popup_setting: PropTypes.object
}

export default CardSelector
