import React from 'react'
import PropTypes from 'prop-types'

import Content0 from './Content0'
import Content1 from './Content1'
import Content2 from './Content2'
import Content3 from './Content3'
import Content4 from './Content4'
import Content5 from './Content5'
import Content6 from './Content6'

const CONTENT_CLASSES = [Content0, Content1, Content2, Content3, Content4, Content5, Content6]

function Content({selected_item, teacher_pd_code}) {
    function renderContent() {
        const ContentClass = CONTENT_CLASSES[selected_item]
        return (
            <ContentClass
                teacher_pd_code={teacher_pd_code}
            />
        )
    }

    return (
        <div className="teacher-pd-content">
            {renderContent()}
        </div>
    );
}

Content.propTypes = {
    selected_item: PropTypes.number.isRequired,
    teacher_pd_code: PropTypes.string,
}

export default Content
