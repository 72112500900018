import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class ReadOnly extends Component {
    getPlaceholder() {
        const placeholder = processStringContent(this.props.data.placeholder, {
            convert_html: false,
            convert_math: false
        })
        return (
            <div className="spreadsheet-cell-readonly-placeholder">
                {placeholder}
            </div>
        )
    }

    getContent() {
        if (!this.props.data.title) {
            return this.getPlaceholder()
        } else {
            return this.props.data.title
        }
    }

    render() {
        if (!this.props.is_preview) {
            return null
        }

        return (
            <div className="spreadsheet-cell-readonly">
                {this.getContent()}
            </div>
        )
    }
}

ReadOnly.propTypes = {
    data: PropTypes.object.isRequired,
    is_preview: PropTypes.bool.isRequired
}

export default ReadOnly
