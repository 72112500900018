export const SET_STUDENT_STORY_PAGE_INDEX = '[student_story] Set page index';
export const LOAD_STUDENT_STORY_DATA = '[student_story] Load data';

export const SetStudentStoryPageIndexAction = payload => ({
    type: SET_STUDENT_STORY_PAGE_INDEX,
    payload
});

export const LoadStudentStoryDataAction = id => dispatch => {
    return Promise.resolve()
        .then(() => {
            return {
                data: {
                    fname: 'Augustin',
                    year: '2024',
                    themes_count: 15,
                    top_themes: ['math_exploration', 'community_causes', 'college_careers'],
                    number_challenges: 9,
                    challenges: [
                        {
                            name: 'Delicious Dishes',
                            code: 'delicious_dishes',
                            percentage: 50,
                            completion: 'Underway'
                        },
                        {
                            name: 'Animals Big & Small',
                            code: 'animals_big_small',
                            percentage: 20,
                            completion: 'Started'
                        },
                        {
                            name: 'Stop Food Waste',
                            code: 'stop_food_waste',
                            percentage: 45,
                            completion: 'Underway'
                        },
                        {
                            name: 'Hike Mt. Everest',
                            code: 'hike_mteverest',
                            percentage: 40,
                            completion: 'Underway'
                        },
                        {
                            name: 'Delivery Dash',
                            code: 'delivery_dash',
                            percentage: 99,
                            completion: 'Completed Bonus'
                        },
                        {
                            name: 'Aquarium Vet',
                            code: 'aquarium_vet',
                            percentage: 31,
                            completion: 'Underway'
                        },
                        {
                            name: 'Theme Park App',
                            code: 'theme_park_app',
                            percentage: 14,
                            completion: 'Started'
                        },
                        {
                            name: 'Best Road Trip',
                            code: 'best_road_trip',
                            percentage: 79,
                            completion: 'Completed'
                        },
                        {
                            name: 'Catchy Clothing',
                            code: 'catchy_clothing',
                            percentage: 71,
                            completion: 'Completed'
                        }
                    ],
                    number_challenges_completed: 3,
                    top_challenges: ['catchy_clothing', 'delivery_dash', 'best_road_trip'],
                    top_strengths: ['ability_persist', 'ability_reason', 'ability_use_tools'],
                    top_opportunities: ['ability_construct_critique', 'abliity_model', 'abliity_analyse'],
                    best_skill: {
                        name: 'Ability to Reason',
                        code: 'SMP2',
                        slug: 'ability_reason'
                    },
                    top_math_skills: ['ability_algebra', 'ability_communitating_reasoning'],
                    top_literacy_skills: ['ability_compose_full_texts', 'ability_research_inquiry'],
                    suggested_challenges: [
                        {
                            name: 'Party Planner',
                            code: 'party',
                            color: '#C6246C',
                            description: 'Which celebrity guest would you invite?',
                            topic: 'work with decimals'
                        },
                        {
                            name: 'Piles & Holes 1 - Digging',
                            code: 'ph1',
                            color: '#F37436',
                            description: 'How many sand piles can we create at the beach?',
                            topic: 'add & subtract'
                        },
                        {
                            name: 'Ice Caps',
                            code: 'ice',
                            color: '#A1D0EC',
                            description: 'What do polar bears like to do?',
                            topic: 'compare & contrast decimals'
                        }
                    ]
                },
                result: 'success'
            };
        })
        .then(response => {
            dispatch(LoadedStudentStoryDataAction(response.data));

            return response.data;
        });
};
export const LoadedStudentStoryDataAction = payload => ({
    type: LOAD_STUDENT_STORY_DATA,
    payload
});
