import React, {useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {WarningMessageAction} from "mindsets-js-sdk";

import ProgressBar from 'common/challenge/ProgressBar';
import ButtonRight from './ButtonRight';
import analytic from 'utils/Analytic';


function TeacherDrivenProgressToolBar({getRefComponents}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    function renderProgress() {
        return (
            <div className="progress-tool-bar-progress">
                <ProgressBar
                    challenge_version={stream.challenge_version}
                    current_card_index={current_index}
                    current_stream_index={stream.index}
                    show_avatar={true}
                    current_taller={true}
                    show_tooltip={false}
                />
            </div>
        );
    }

    //TODO is it necessary?
    useEffect(() => {
        if (me) {
            let user_channel = pusher.channel('user_' + me.id);
            if (!user_channel || !user_channel.subscribed) {
                user_channel = pusher.subscribe('user_' + me.id);
            }
            user_channel.bind('user.updated', forceUpdate);
        }

        return () => {
            let user_channel = pusher.channel('user_' + me.id);
            if (user_channel && user_channel.subscribed) {
                user_channel.unbind('user.updated', forceUpdate);
            }
        }
    }, [me]);

    return (
        <div className="progress-tool-bar flex-row">
            <div className="progress-tool-bar-nav flex-row flex-vh-center" onClick={() => {
                dispatch(WarningMessageAction('Your class is currently in Teacher Paced mode. Your teacher controls the page you see.'));
                analytic.log(
                    'clickTeachingModeIndicator',
                    stream.lesson.code
                );
            }}>
                <img
                    className='teacher-mode'
                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/lesson_mode/teacher_purple.svg`}
                    alt='Student Paced mode'
                />
            </div>

            <div className="flex-auto flex-column flex-h-center">
                {renderProgress()}
            </div>

            <ButtonRight
                getRefComponents={getRefComponents}
            />
        </div>
    );
}

TeacherDrivenProgressToolBar.propTypes = {
    getRefComponents: PropTypes.func.isRequired
}

export default TeacherDrivenProgressToolBar
