import React from 'react';

import EmptyState from './EmptyState';
import RaiseHandStreams from './RaiseHandStreams';
import StudentStreams from './StudentStreams';


export default function () {
    return (
        <div className="teacher-facilitation-overview">
            <div className="container">
                <EmptyState/>
                <RaiseHandStreams/>
                <StudentStreams/>
            </div>
        </div>
    );
}
