import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page0'>
            <div className='welcome'>
                <div className='l1'>Hi {data.fname}!</div>
                <div className='l3'>Ready to delve into your Mindsets Skills in {data.year}?</div>
                <div className='l2'>Let's jump right in!</div>
            </div>
        </div>
    );
}