export const getCardName = (challenge_version, card_index, page_text = 'Page ', bonus_text = 'Bonus ') => {
    const {complete_index, first_bonus_index, final_index} = challenge_version

    if (!complete_index || undefined === first_bonus_index || null === first_bonus_index || !final_index) {
        console.error('Missing some Challenge version data.')
        return null
    }

    if (card_index === final_index) {
        return 'Finished!'
    } else if (card_index === complete_index + 1) {
        return 'Bonus Unlocked'
    } else if (card_index <= complete_index) {
        const card_number = card_index + 1
        return `${page_text}${card_number}`
    } else if (card_index >= first_bonus_index) {
        const card_number = card_index - first_bonus_index + 1
        return `${bonus_text}${card_number}`
    } else {
        return null
    }
}


export const isPoll = (se) => {
    const {data={}. polls} = se

    // New data model
    if (data.type == 'poll') {
        return true
    }

    // Old data model
    const poll = polls[0]
    if (poll && poll.type != 'inline') {
        return true
    }

    return false
}

// export const isQuestion = (se) => {
//     const {data={}} = se
//     const {is_bonus=false, card_no} = data
//
//     // New data model
//     if (card_no && !is_bonus) {
//         return true
//     }
//
//     // Old data model
//     if (card_no && data.type != 'bonus') {
//         return true
//     }
//
//     return false
// }

export const isBonus = (se) => {
    const {data={}} = se
    const {is_bonus=false} = data

    // New data model
    if (se.type === 'unlock' || is_bonus || se.type === 'final') {
        return true
    }

    // Old data model
    if (data.type === 'bonus' || data.type === 'final') {
        return true
    }

    return false
}