import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {LoadingAction, JoinLessonForceAction} from "mindsets-js-sdk";

import GeneralError from "../pages/GeneralError";

export default function () {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Could not join the Class.');

    function renderError() {
        if (failed === true) {
            return <GeneralError content={message}/>;
        }

        return null;
    }

    useEffect(() => {
        if (params.lesson_code) {
            dispatch(JoinLessonForceAction(params.lesson_code))
                .then(response => navigate(`/hello/${response.stream_code}`))
                .catch(error => {
                    setMessage(error);
                    setFailed(true);
                })
                .then(() => dispatch(LoadingAction(false)));
        } else {
            setFailed(true);
        }
    }, []);

    return (
        <>
            {renderError()}
        </>
    );
}
