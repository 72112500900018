import React from 'react'
import classNames from 'classnames'
import {useDispatch, useSelector} from "react-redux";
import {SetPAMModeAction} from "../../../../actions/pam_actions";

export default function () {
    const dispatch = useDispatch();
    const mode = useSelector(state => state.pam_mode);

    function renderModeZero() {
        const class_names = classNames('btn', {
            'btn-challenge': mode === 0,
            'btn-flat': mode !== 0
        })

        const onClick = () => {
            dispatch(SetPAMModeAction(0));
        }

        return (
            <div>
                <button className={class_names} onClick={onClick}>
                    {`Achievement View`}
                </button>
            </div>
        )
    }

    function renderModeOne() {
        const class_names = classNames('btn', {
            'btn-challenge': mode === 1,
            'btn-flat': mode !== 1
        })

        const onClick = () => {
            dispatch(SetPAMModeAction(1));
        }

        return (
            <div>
                <button className={class_names} onClick={onClick}>
                    {`Potential View`}
                </button>
            </div>
        )
    }

    return (
        <div className="pam-challenge-mode-selector">
            <div className="flex-row flex-h-center flex-box-10-10">
                <div>
                    {renderModeZero()}
                </div>

                <div>
                    {renderModeOne()}
                </div>
            </div>
        </div>
    );
}