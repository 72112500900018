import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {SetUserAction} from "mindsets-js-sdk";

import {isEducator} from "utils/role";
import CurriculumSelector from "common/CurriculumSelector";

export default function () {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const curricula = useSelector(state => state.curricula);
    let user_new = JSON.parse(JSON.stringify(user));

    if (isEducator(user)) {
        return (
            <div className="col s12 m6">
                <CurriculumSelector
                    curriculum_id={user.curriculum_id}
                    onCurriculumIdChange={curriculum_id => {
                        user_new.curriculum_id = curriculum_id;
                        dispatch(SetUserAction(user_new));
                    }}
                    validation_error_messages={{}}
                    curriculums={curricula}
                />
            </div>
        );
    }

    return null;
}
