import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TEACHER_PD_MENU = [
    'Overview',
    '1. What are the key components of a Mindsets Challenge?',
    '2. What are sequencing and timing options for Mindsets Challenges?',
    '3. How to prepare for a Mindsets Challenge',
    '4. How to facilitate a Mindsets Challenge',
    '5. How to extend the Mindsets Challenge experience',
    'Quiz'
]


function Menu({selected_item, onSelectItem}) {
    function renderItems() {
        const items = TEACHER_PD_MENU.map((item, k) => {
            const class_name = classNames('pd-menu-item', {
                'pd-menu-item-active': k === selected_item
            });
            const onClick = (e) => {
                e.preventDefault();
                return onSelectItem(k);
            }

            return (
                <div key={k}>
                    <a className={class_name} href="#" onClick={onClick}>
                        {item}
                    </a>
                </div>
            );
        })

        return (
            <div className="flex-column flex-box-5-10">
                {items}
            </div>
        );
    }

    return (
        <div className="teacher-pd-menu">
            {renderItems()}
        </div>
    )
}

Menu.propTypes = {
    selected_item: PropTypes.number.isRequired,
    onSelectItem: PropTypes.func.isRequired
}

export default Menu
