import React from 'react'
import {DragDropContext} from "react-beautiful-dnd"
import {get, find, cloneDeep, isEmpty} from 'lodash'

import BaseAnswer from '../BaseAnswer'
import AnswerGroup from './AnswerGroup'
import AnswerLayout from './AnswerLayout'


export default class DragAndDrop extends BaseAnswer {
    defaultAnswer() {
        const answer_layout = get(this.props.component_data, 'data.answer_layout')
        return {
            text: this.getAnswerText(answer_layout),
            answer_layout
        }
    }

    isValid() {
        return !!find(this.state.answer.answer_layout, (al) => {
            return al.type == 'dnd' && !isEmpty(al.content);
        });
    }

    getAnswerText(answer_layout) {
        const is_not_empty = !!find(answer_layout, (al) => {
            return al.type === 'dnd' && !isEmpty(get(al, 'content.text'))
        })
        if (is_not_empty) {
            const text_array = answer_layout.map((al) => get(al, 'content.text', ' [unfilled] '))
            return text_array.join('')
        } else {
            return ""
        }
    }

    onDragEnd() {
        return (result) => {
            const draggable_id = get(result, 'draggableId')
            const dest_droppable_id = get(result, 'destination.droppableId')
            const source_droppable_id = get(result, 'source.droppableId')
            const dnd_type = get(result, 'type')

            if (!draggable_id || !dest_droppable_id) {
                return null
            }

            if (dest_droppable_id === source_droppable_id) {
                return null
            }

            const answer_clone = cloneDeep(this.state.answer)

            const source_droppable = find(answer_clone.answer_layout, {type: 'dnd', dnd_type, dnd_id: source_droppable_id})
            if (source_droppable) {
                // This is when user dragging an item out from an answer layout
                source_droppable.content = undefined
            }

            const dest_droppable = find(answer_clone.answer_layout, {type: 'dnd', dnd_type, dnd_id: dest_droppable_id})
            if (dest_droppable) {
                // This is when user dragging an item into an answer layout
                const answer_groups = get(this.props.component_data, 'data.answer_groups')
                const answer_group = find(answer_groups, {dnd_type})
                const draggable_item = find(answer_group.options, {dnd_id: draggable_id})
                const clone_draggable_item = cloneDeep(draggable_item)
                clone_draggable_item.dnd_id = undefined
                dest_droppable.content = clone_draggable_item
            }

            answer_clone.text = this.getAnswerText(answer_clone.answer_layout)

            this.setState({
                answer: answer_clone
            })
        }
    }

    onRemove = (dnd_id) => () => {
        const answer_clone = cloneDeep(this.state.answer)
        const answer_group = find(answer_clone.answer_layout, {dnd_id})
        answer_group.content = undefined
        answer_clone.text = this.getAnswerText(answer_clone.answer_layout)
        this.setState({
            answer: answer_clone
        })
    }

    renderAnswerGroups() {
        const answer_groups = get(this.props.component_data, 'data.answer_groups')
        const render_groups = answer_groups.map((answer_group) => {
            return (
                <div key={answer_group.dnd_type} className="">
                    <AnswerGroup
                        is_preview={this.props.is_preview}
                        answer_group={answer_group}
                        answer_layout={get(this.state.answer, 'answer_layout')}
                    />
                </div>
            )
        })

        return (
            <div className="flex-row flex-box-10-10 flex-wrap">
                {render_groups}
            </div>
        )
    }

    renderAnswerLayout() {
        const answer_layout = get(this.state.answer, 'answer_layout')
        const answer_groups = get(this.props.component_data, 'data.answer_groups')

        return (
            <AnswerLayout
                is_preview={this.props.is_preview}
                answer_layout={answer_layout}
                answer_groups={answer_groups}
                onRemove={this.onRemove}
            />
        )
    }

    render() {
        return (
            <div className="challenge-component-dnd">
                <DragDropContext onDragEnd={this.onDragEnd()}>
                    {this.renderAnswerGroups()}

                    <div className="section">
                    </div>

                    <div>
                        {this.renderAnswerLayout()}
                    </div>

                </DragDropContext>
            </div>
        )
    }
}
