import {toastActions} from "mindsets-js-sdk";

export const isValidEmail = (email) => {
    if (!email) return false
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
}

export const isValidPassword = (password) => {
    if (!password) return false
    return password.length >= 6
}

export const isValidClassCode = (code) => {
    if (!code) return false
    return code.length >= 6
}

export const downloadFile = (dispatch, title, string, format = 'csv', type = 'data:application/octet-stream') => {
    try {
        let a = document.createElement('a');
        let rows = [];
        // let blob = new Blob(["\ufeff", string], {'type': type});
        const blob = new Blob([string], {'type': type});
        a.href = window.URL.createObjectURL(blob);
        a.download = title + '.' + format;
        a.click();
    } catch (e) {
        dispatch(toastActions.ErrorMessageAction('We are sorry, there was a problem with downloading the file. Please try again later.'));
        console.error(e);
    }
}

export function getBrowserDetails() {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 4);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    } else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
        // In MS Edge, the true version is after "Edg" in userAgent
        browserName = "Microsoft Edge";
        fullVersion = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
        // In MSIE, the true version is after "MSIE" in userAgent
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        // In Chrome, the true version is after "Chrome"
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        // In Safari, the true version is after "Safari" or after "Version"
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        // In Firefox, the true version is after "Firefox"
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        // In most other browsers, "name/version" is at the end of userAgent
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
        fullVersion = fullVersion.substring(0, ix);

    return {
        name: browserName,
        version: fullVersion,
        app_name: navigator.appName,
        user_agent: navigator.userAgent
    }
}
