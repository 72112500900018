import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import MindsetsJsSDK, {rolesConstants, ErrorMessageAction} from "mindsets-js-sdk";

import {LogoutAction} from "../../../actions/login_actions";

export default function () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    let [searchParams] = useSearchParams();
    const query = JSON.parse(atob(searchParams.get('q')));

    if (!me) {
        return null;
    }
    if (rolesConstants.ROLE_STUDENT !== me.persona && query.hasOwnProperty('route_name') && 'student.join' === query.route_name && query.hasOwnProperty('lesson_code')) {
        return (
            <div className='flex-row flex-h-center'>
                <button
                    onClick={() => {
                        MindsetsJsSDK().Api.lesson.joinForce(query.lesson_code)
                            .then(response => navigate('/hello/' + response.stream_code))
                            .catch(error => dispatch(ErrorMessageAction(error)));
                    }}
                    className='btn btn-challenge'
                >JOIN AS A STUDENT
                </button>
            </div>
        );
    } else if (query.persona !== me.persona) {
        return (
            <div>
                <div className='flex-row flex-h-center'>
                    <button
                        onClick={() => dispatch(LogoutAction()).then(() => navigate(`/login?persona=${query.persona}`))}
                        className='btn btn-on-white mr-2'>I'M A {query.persona.toUpperCase()}
                    </button>
                    <Link to={`/`} className='btn btn-challenge ml-2'>I'M A {me.persona.toUpperCase()}</Link>
                </div>
            </div>
        );
    }

    return (
        <div className='flex-row flex-h-center'>
            <button
                onClick={() => dispatch(LogoutAction()).then(() => navigate(`/login?persona=${query.persona}`))}
                className='btn btn-challenge'>SIGN IN AS A {query.persona}</button>
        </div>
    );
}
