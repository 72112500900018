import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import Hint from './Hint'
import {processStringContent} from 'utils/content'


class Question extends Component {
    getQuestionNumber() {
        const question_number = get(this.props.component_data, 'data.question_number')
        if (question_number) {
            return `<strong>Q${question_number}. </strong>`
        } else {
            return `<strong>Question: </strong>`
        }
    }

    getOldAdjustmentClass() {
        const question_number = get(this.props.component_data, 'data.question_number')
        if (!question_number) {
            return 'question-old-adjustment'
        } else {
            return ''
        }
    }

    renderQuestion() {
        const question = get(this.props.component_data, 'data.question')
        if (question) {
            return (
                <div>
                    {processStringContent(this.getQuestionNumber() + question)}
                </div>
            )
        }
    }

    renderHint() {
        const hint = get(this.props.component_data, 'data.hint')
        if (hint) {
            return (
                <Hint
                    hint={hint}
                />
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-question max-width-850">
                <div className={this.getOldAdjustmentClass()}>
                    <div>
                        {this.renderQuestion()}
                    </div>

                    <div>
                        {this.renderHint()}
                    </div>
                </div>
            </div>
        )
    }
}

Question.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Question
