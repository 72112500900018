import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Cookie, Toggle, SetTeacherFacilitationShowStudentsAction} from "mindsets-js-sdk";

import {SHOW_STUDENTS_NAMES_COOKIE_NAME} from "../../index";
import analytic from "utils/Analytic";


export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);

    function toggleStudent(show_student) {
        Cookie.setCookie(SHOW_STUDENTS_NAMES_COOKIE_NAME + lesson.code, show_student === true ? 'true' : 'false');
        dispatch(SetTeacherFacilitationShowStudentsAction(show_student));
        analytic.log('facilitationToggleStudentNames', {
            name: lesson.challenge_version.name,
            code: lesson.challenge_version.challenge_code.code,
        }, lesson.code, show_student);
    }

    return (
        <div className='flex-row flex-v-center mx-4 my-2'>
            <i className="mr-2 action-icon fas fa-eye"></i>
            <div className='switch-text'>
                {`Show Names`}
            </div>
            <div className="flex-auto"></div>
            <Toggle onChange={() => toggleStudent(!show_student)} is_on={show_student}/>
            <div className="switch-value">
                {show_student ? 'Yes' : 'No'}
            </div>
        </div>
    );
}