import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SetTeacherFacilitationSelectedCardIndexAction} from "mindsets-js-sdk";

import Streams from './Streams';
import Top from './Top';
import StreamContainer from './StreamContainer';

export default function () {
    const dispatch = useDispatch();
    const selected_stream_id = useSelector(state => state.teacher_facilitation_selected_stream_id);

    function renderStreams() {
        if (!selected_stream_id) {
            return (
                <div className="section">
                    <Streams/>
                </div>
            );
        }
    }

    function renderTop() {
        if (selected_stream_id) {
            return (
                <Top/>
            );
        }
    }

    function renderStreamContainer() {
        if (selected_stream_id) {
            return (
                <StreamContainer
                    key={selected_stream_id ?? 0}
                />
            );
        }
    }

    useEffect(() => {
        return () => {
            dispatch(SetTeacherFacilitationSelectedCardIndexAction(0));
        }
    }, []);
    useEffect(() => {
        dispatch(SetTeacherFacilitationSelectedCardIndexAction(0));
    }, [selected_stream_id]);

    return (
        <div className="teacher-facilitation-bystudent">
            <div className="container">
                {renderStreams()}
            </div>

            {renderTop()}

            <div className="container">
                {renderStreamContainer()}
            </div>
        </div>
    );
}
