import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page10'>
            <div className='level-up'>You’ve Levelled Up!</div>
            <div className='second-row'>
                <div className='contents'>
                    <div className='title'>Did you know you have levelled up the most
                        in {data.best_skill.code} - {data.best_skill.name}?
                    </div>
                    <div className='subtitle'>Well done!</div>
                </div>
                <img
                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${data.best_skill.slug}.png`}
                    alt={data.best_skill.code}/>
            </div>
        </div>
    );
}