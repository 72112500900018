import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";

import analytic from 'utils/Analytic';


export default () => {
    const lesson = useSelector(state => state.lesson);

    if (!process.env.FEATURE_SWITCH_WOW_MOMENTS) {
        return null
    }

    const onClick = () => {
        analytic.log(
            'facilitationNavWowMoments',
            lesson.code
        );
    }

    return (
        <div>
            <hr className="teacher-facilitation-nav-popup-divider"/>
            <div className="teacher-facilitation-nav-setup">
                <Link to={`/teacher/wow-moments/save?li=${lesson.id}`} onClick={onClick}>
                    <i className="fas fa-camera"></i>
                    <span>{`Share WOW Moments!`}</span>
                </Link>
            </div>
        </div>
    );
}
