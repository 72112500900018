import React, {Component} from 'react'
import PropTypes from 'prop-types'

import TextBubble from 'common/ui/TextBubble'
import {processStringContent} from 'utils/content'


class Speech extends Component {
    renderLeft() {
        const {left} = this.props.component_data.data
        if (left) {
            return (
                <TextBubble
                    bubble_background={left.bubble_background}
                    bubble_direction='right'
                >
                    {processStringContent(left.text)}
                </TextBubble>
            )
        }
    }

    renderImage() {
        const {image} = this.props.component_data.data
        if (image) {
            return (
                <div className="component-speech-image">
                    <img src={image.src} />
                </div>
            )
        }
    }

    renderRight() {
        const {right} = this.props.component_data.data
        if (right) {
            return (
                <TextBubble
                    bubble_background={right.bubble_background}
                    bubble_direction='left'
                >
                    {processStringContent(right.text)}
                </TextBubble>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-speech max-width-850">
                <div className="flex-row flex-h-start flex-v-center flex-box-5-10">
                    {this.renderLeft()}
                    {this.renderImage()}
                    {this.renderRight()}
                </div>
            </div>
        )
    }
}

Speech.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Speech
