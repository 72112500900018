import {LOAD_STUDENT_STORY_DATA, SET_STUDENT_STORY_PAGE_INDEX} from "../actions/student_story_actions";

const student_story_page_index = (payload = 0, action) => {
    switch (action.type) {
        case SET_STUDENT_STORY_PAGE_INDEX:
            return action.payload;
        default:
            return payload;
    }
};
const student_story_data = (payload = null, action) => {
    switch (action.type) {
        case LOAD_STUDENT_STORY_DATA:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    student_story_page_index,
    student_story_data
}
