import React, {useState} from 'react';
import Modal from 'react-responsive-modal';

import CategoryLevel from './CategoryLevel';

export default function () {
    const [show, setShow] = useState(false);

    function renderModal() {
        return (
            <Modal
                open={show}
                onClose={() => {
                    setShow(false);
                }}
                showCloseIcon={true}
                center
            >
                <div>
                    <CategoryLevel/>
                </div>
            </Modal>
        )
    }

    function renderTrigger() {
        const onClick = (e) => {
            e.preventDefault()
            setShow(true);
        }

        return (
            <div className="center-align">
                <a className="link-new" href="#" onClick={onClick}>
                    <i className="far fa-question-circle"></i>
                    <span>{`Questions and skills weightage`}</span>
                </a>
            </div>
        );
    }

    return (
        <div className="question-skill-weightage">
            {renderModal()}
            {renderTrigger()}
        </div>
    );
}