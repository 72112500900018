import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import ReactTooltip from "react-tooltip";

import {getChallengeHeroThumb, getChallengeKidImage} from 'utils/cloud';

import './challenge_tile.scss';

function ChallengeTile(
    {
        challenge_version,
        show_kid = false,
        description,
        button_label,
        buttonOnClick,
        is_done = false
    }
) {
    function getChallengeImageUrl() {
        return getChallengeHeroThumb(challenge_version.challenge_code.code)
    }

    function renderKid() {
        if (show_kid) {
            const kid_url = getChallengeKidImage(challenge_version.challenge_code.code)
            return (
                <div className="challenge-tile-kid">
                    <img src={kid_url} alt='challenge-tile-kid-icon '/>
                </div>
            );
        }

        return null;
    }

    function renderChallengeName() {
        const name_array = challenge_version.name.replace(': ', ':: ').split(': ')
        return (
            <div>
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        );
    }

    function renderDescription() {
        return (
            <div className="challenge-tile-description">
                {description}
            </div>
        );
    }

    function renderButton() {
        if (button_label && buttonOnClick) {
            return (
                <button className="btn btn-challenge" onClick={buttonOnClick}>
                    {button_label}
                </button>
            );
        }

        return null;
    }

    function renderMark() {
        if (is_done) {
            return (
                <div className='mark-done-tick center'>
                    <i
                        className={'fas fa-check-circle fa-4x'}
                        data-tip='Class completed'
                    />
                    <ReactTooltip/>
                </div>
            );
        }

        return null;
    }

    return (
        <div className="challenge-tile">
            <div className="challenge-tile-triangle"
                 style={{borderBottomColor: challenge_version.challenge_code.color}}>
                &nbsp;
            </div>

            {renderKid()}

            <div className="challenge-tile-container"
                 style={{backgroundImage: `url(${getChallengeImageUrl()})`}}>
                <div className="challenge-tile-content-container"
                     style={{backgroundColor: challenge_version.challenge_code.color}}>
                    <h5 className="header-new">
                        {renderChallengeName()}
                    </h5>

                    <div className="flex-row flex-v-center flex-between">
                        {renderDescription()}

                        {renderButton()}
                    </div>
                </div>
            </div>
            {renderMark()}
        </div>
    );
}

ChallengeTile.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    show_kid: PropTypes.bool,
    description: PropTypes.string,
    button_label: PropTypes.string,
    buttonOnClick: PropTypes.func,
    is_done: PropTypes.bool,
}

export default ChallengeTile
