import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class InfoTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: 0
        }
    }

    onSelect(selected) {
        return (e) => {
            e.preventDefault()
            this.setState({selected})
        }
    }

    getSelected() {
        return this.props.component_data.data.items[this.state.selected]
    }

    renderIcon() {
        return (
            <div className="infotabs-chat-icon">
                <i className="fas fa-comment"></i>
            </div>
        )
    }

    renderTabs() {
        const tabs = this.props.component_data.data.items.map((tab, i) => {
            return (
                <div className="infotabs-tabs-item" key={i} onClick={this.onSelect(i)}>
                    <div className="center-align">
                        <img src={tab.image} alt={tab.name} />
                    </div>
                    <div className="center-align">
                        {tab.name}
                    </div>
                    {
                        i == this.state.selected &&
                        this.renderIcon()
                    }
                </div>
            )
        })
        return (
            <div className="infotabs-tabs flex-row flex-wrap flex-h-start">
                {tabs}
            </div>
        )
    }

    renderContent() {
        const tab = this.getSelected()
        return (
            <div className="infotabs-content">
                <h5 className="header-new">
                    {`${tab.name} says...`}
                </h5>

                {processStringContent(tab.information)}
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-infotabs max-width-850">
                {this.renderTabs()}

                {this.renderContent()}
            </div>
        )
    }
}

InfoTabs.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default InfoTabs
