import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './popup.scss';


class Popup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show || false
        }
    }

    toggle(show) {
        return (e) => {
            if (e) {
                e.preventDefault()
            }

            if (show === undefined) {
                show = !this.state.show
            }
            this.setState({show})
        }
    }

    renderTrigger() {
        return (
            <div className="popup-trigger" onClick={this.toggle()}>
                {this.props.children}
            </div>
        )
    }

    renderTriangle() {
        if (this.state.show) {
            return (
                <div className="popup-triangle hide-on-small-only">
                    <div className="popup-triangle-inner">
                    </div>
                </div>
            )
        }
    }

    renderContent() {
        if (this.state.show) {
            const {left, right} = this.props
            const style = {left, right}
            return (
                <div className='popup-content z-depth-2' style={style}>
                    {this.props.popup_content}
                </div>
            )
        }
    }

    renderOverlay() {
        if (this.state.show) {
            return (
                <div className='popup-overlay' onClick={this.toggle(false)}>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="popup-container">
                {this.renderTrigger()}

                {this.renderTriangle()}

                {this.renderContent()}

                {this.renderOverlay()}
            </div>
        )
    }
}

Popup.defaultProps = {
    show: false
}

Popup.propTypes = {
    popup_content: PropTypes.node.isRequired,
    show: PropTypes.bool,
    left: PropTypes.string,
    right: PropTypes.string
}

export default Popup
