import React from 'react';
import PropTypes from 'prop-types';

import './avatar_name.scss';


function AvatarName({user,private_mode,content_right,content_bottom}){
    function renderAvatar() {
        return (
            <div className="avatar-name-avatar flex-row flex-vh-center">
                <div className="avatar-name-avatar-icon">
                    <div className={`cashtivity-icon ${user.avatar}`}/>
                </div>

                <div className="avatar-name-private-icon">
                    <i className="avatar-name-private-icon fas fa-user-graduate"/>
                </div>
            </div>
        );
    }

    function renderName() {
        return (
            <div className="avatar-name-name">
                {user.full_name}
            </div>
        );
    }

    return (
        <div className="user-avatar-name">
            <div className={private_mode ? 'private-mode' : 'not-private-mode'}>
                <div className="flex-row flex-v-center">
                    {renderAvatar()}

                    <div className="flex-column">
                        <div className="flex-auto flex-row flex-v-center">
                            {renderName()}

                            <div className="user-avatar-name-content-right">
                                {content_right}
                            </div>
                        </div>

                        <div className="user-avatar-name-content-bottom">
                            {content_bottom}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AvatarName.propTypes = {
    user: PropTypes.object.isRequired,
    private_mode: PropTypes.bool.isRequired,
    content_right: PropTypes.node,
    content_bottom: PropTypes.node
}

export default AvatarName
