import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {get, isEmpty} from 'lodash';
import MindsetsJsSDK, {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

import Graphing from './Graphing';
import analytic from 'utils/Analytic';

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(undefined);
    const [user, setUser] = useState(undefined);

    function fetchAnswer() {
        dispatch(LoadingAction(true, 'Loading...'));
        MindsetsJsSDK().API.get(`responses/${params.answer_code}`).then((response) => {
            setResponse(response.response);
            setUser(response.response.user);
        }).catch((error) => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        }).then(() => {
            setLoading(false);
            dispatch(LoadingAction(false));
        });
    }

    function renderStudent() {
        if (loading) {
            return null;
        }

        const persona = get(me, 'persona');
        if (!isEmpty(me) && persona !== 'student') {
            const student_name = get(user, 'fname');
            return (
                <div>
                    <span>{`Student: `}</span>
                    <strong>{`${student_name}`}</strong>
                </div>
            );
        } else {
            const student_id = get(user, 'id');
            return (
                <div>
                    <span>{`Student: `}</span>
                    <strong>{`${student_id}`}</strong>
                </div>
            );
        }
    }

    function renderClose() {
        if (isEmpty(me)) {
            return null;
        }

        const onClick = () => {
            window.close();
        };

        return (
            <button className="btn btn-on-white" onClick={onClick}>
                {`Close`}
            </button>
        );
    }

    function renderGraphing() {
        if (loading) {
            return null;
        }

        const answer_option = get(response, 'data.answer.answer_option');
        if (response && answer_option === 'tool') {
            return (
                <Graphing response={response}/>
            );
        } else {
            return (
                <div>
                    {`There is an error loading the student graphing answer, please try to refresh this page.`}
                    <br/>
                    {`If error still persists, please contact support.`}
                </div>
            );
        }
    }

    useEffect(() => {
        fetchAnswer();
    }, []);

    useEffect(() => {
        analytic.log('viewAnswerPage', params.answer_code);
    }, []);

    return (
        <div id="answer-graphing">
            <div className="container">
                <div className="flex-row flex-v-center flex-between">
                    <img
                        className="mindsets-logo-guest"
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                        alt="Mindsets Learning Inc."
                    />

                    <div>
                        {renderClose()}
                    </div>
                </div>

                <div className="section">
                    <div>
                        {renderStudent()}
                    </div>
                    <div>
                        {`Note: Any changes you make to this student's graph will not be saved.`}
                    </div>
                </div>

                <div className="section">
                    {renderGraphing()}
                </div>
            </div>
        </div>
    );
}
