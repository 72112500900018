const analyticTeacherFacilitation = (Analytic) => {
    Analytic.prototype.facilitationGotoOverview = function (challenge, class_code) {
        this.track('Facilitation Goto Overview', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationGotoByQuestion = function (challenge, class_code) {
        this.track('Facilitation Goto By Question', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationGotoPresentation = function (challenge, class_code) {
        this.track('Facilitation Goto Presentation', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationGotoByStudent = function (challenge, class_code) {
        this.track('Facilitation Goto By Student', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationGotoMembers = function (challenge, class_code) {
        this.track('Facilitation Goto Members', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationPauseClass = function (challenge, class_code) {
        this.track('Facilitation Pause Class', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationResumeClass = function (challenge, class_code) {
        this.track('Facilitation Resume Class', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationSortStudents = function (challenge, class_code, sort_by) {
        this.track('Facilitation Sort Students', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code,
            sort_by
        })
    }

    Analytic.prototype.facilitationViewAsStudent = function (challenge, class_code) {
        this.track('Facilitation View as Student', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.facilitationDeleteStudentStream = function (stream_code, student) {
        this.track('Facilitation Delete Student Stream', {
            stream_code,
            student_id: student.id,
            student_name: student.fname
        })
    }

    Analytic.prototype.facilitationPauseStudentStream = function (stream_code, student) {
        this.track('Facilitation Pause Student Stream', {
            stream_code,
            student_id: student.id,
            student_name: student.fname
        })
    }

    Analytic.prototype.facilitationResumeStudentStream = function (stream_code, student) {
        this.track('Facilitation Resume Student Stream', {
            stream_code,
            student_id: student.id,
            student_name: student.fname
        })
    }

    Analytic.prototype.facilitationOpenStudentStream = function (stream_code, student) {
        this.track('Facilitation Open Student Stream', {
            stream_code,
            student_id: student.id,
            student_name: student.fname
        })
    }

    Analytic.prototype.facilitationWatchTour = function () {
        this.track('Facilitation Watch Tour', {})
    }

    Analytic.prototype.facilitationToggleStudentNames = function (challenge, class_code, show) {
        this.track('Facilitation Toggle Student Names', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code,
            show
        })
    }

    Analytic.prototype.downloadReport = function (class_code) {
        this.track('Report Downloaded', {
            class_code: class_code
        })
    }

    Analytic.prototype.downloadReportError = function (class_code, error_message, error_stack) {
        this.track('Report Download Error', {
            class_code: class_code,
            error_message: error_message,
            error_stack: error_stack
        })
    }

    Analytic.prototype.exportGoogleReport = function (class_code) {
        this.track('Report Exported to Google', {
            class_code: class_code
        })
    }

    Analytic.prototype.facilitationByStudentSelectStudent = function (student) {
        this.track('Facilitation By Student Select Student', {
            student_id: student.id,
            student_name: student.fname
        })
    }

    Analytic.prototype.facilitationByStudentViewAnotherStudent = function () {
        this.track('Facilitation By Student View Another Student', {})
    }

    Analytic.prototype.facilitationMembersTeachersAssigned = function (data) {
        this.track('Facilitation Members Teachers Assigned', data)
    }

    Analytic.prototype.facilitationLowerHand = function (challenge_name, class_code, stream_id) {
        this.track('Facilitation Lower Hand', {
            challenge_name,
            class_code,
            stream_id
        })
    }

    Analytic.prototype.facilitationLowerAllHands = function (challenge_name, class_code) {
        this.track('Facilitation Lower All Hands', {
            challenge_name,
            class_code
        })
    }

    Analytic.prototype.facilitationToggleRaiseHand = function (challenge_name, class_code, enable) {
        this.track('Facilitation Toggle Raise Hand', {
            challenge_name,
            class_code,
            enable
        })
    }

    Analytic.prototype.facilitationCopyCode = function (class_code) {
        this.track('Facilitation Copy Code', {
            class_code
        })
    }

    Analytic.prototype.facilitationNavClassSetup = function (class_code) {
        this.track('Facilitation Nav Class Setup', {
            class_code
        })
    }

    Analytic.prototype.facilitationNavWowMoments = function (class_code) {
        this.track('Facilitation Nav WOW Moments', {
            class_code
        })
    }
}

export default analyticTeacherFacilitation
