import {get} from 'lodash'

import {seOldToNew} from 'utils/challenge/dataModel'
import {processStringContent} from 'utils/content'


export const getContentStreamEntity = (challenge, stream_entity) => {
    const se = seOldToNew(challenge, stream_entity)

    const content = {
        title: challenge.name,
        chunks: []
    }

    const addToContent = (c) => {
        if (c) {
            newContent(content, c)
            newContent(content, "\n")
        }
    }

    switch (se.type) {
        case 'intro':
            addToContent(challenge.name)
            addToContent(challenge.overview)
            addToContent(challenge.tips)
            break
        case 'welcome':
            addToContent(se.data.title)
            addToContent(se.data.subtitle)
            addToContent(se.data.content1)
            addToContent(se.data.content2)
            break
        case 'winner':
            addToContent(se.data.title)
            addToContent(se.data.content1)
            addToContent(se.data.content2)
            break
        case 'complete':
            addToContent(se.data.title)
            addToContent(se.data.content)
            break
        case 'unlock':
            addToContent(se.data.title)
            addToContent(se.data.content1)
            break
        case 'final':
            addToContent(se.data.title)
            addToContent(se.data.content)
            break
        case 'instruction':
        case 'question':
        case 'poll':
            addToContent(se.data.title)
            break
    }

    if (se.data.card_content) {
        se.data.card_content.rows.map(r => r.components.map(c => {
            switch (c.type) {
                case 'text':
                    addToContent(c.data.html)
                    break
                case 'question':
                    addToContent(c.data.question)
                    break
                case 'poll.block':
                    addToContent(c.data.description)
                    break
                case 'step':
                    addToContent(get(c.data, 'title'))
                    addToContent(get(c.data, 'description'))
                    break
                case 'table':
                    addToContent(get(c.data, 'html'))
                    break
                case 'comparison':
                    addToContent(get(c.data, 'text'))
                    break
                case 'speech':
                    addToContent(get(c.data, 'left.text'))
                    addToContent(get(c.data, 'right.text'))
                    break
                case 'click_to_show':
                    addToContent(get(c.data, 'text'))
                    break
                case 'info_tabs':
                    get(c.data, 'items').map((item) => {
                        addToContent(get(item, 'name') + ' says... ' + get(item, 'information'))
                    })
                    break
            }
        }))
    }

    return content
}

const newContent = (content, str) => {
    const str_new = processStringContent(str, {convert_html: false})
    if (str_new.search('katex-display') > -1) {
        newMathContent(content, str_new)
    } else {
        content.chunks.push({
            content: str_new.replace(/\&nbsp;/g, ' ').replace(/\&amp;/g, '&').replace(/<sup>2<\/sup>/g, '²').replace(/<sup>3<\/sup>/g, '³').replace(/<(?:.|\n)*?>/gm, ' '),
            lang: 'en'
        })
    }
}

const newMathContent = (content, str) => {
    const dom = document.createElement('div')
    dom.innerHTML = str
    // Find all katex-display nodes
    const katex_nodes = dom.querySelectorAll('span.katex-display')
    const math_ml_list = []
    katex_nodes.forEach((kn) => {
        // Find the katex-mathml node
        const mathml_node = kn.querySelectorAll('span.katex-mathml')[0]
        if (mathml_node) {
            // Set the xmlns and display for the katex-mathml node
            mathml_node.firstChild.setAttribute('xmlns', 'http://www.w3.org/1998/Math/MathML')
            mathml_node.firstChild.setAttribute('display', 'block')
            // Save the math XML into an array for later use
            math_ml_list.push(mathml_node.innerHTML)
        }
        // Empty the katex-display node
        kn.innerHTML = ''
    })

    const str_array = dom.innerHTML.split('<span class="katex-display"></span>')
    str_array.forEach((s, i) => {
        // Prepare none math content
        newContent(content, s)
        if (math_ml_list[i]) {
            // Prepare math content
            content.chunks.push({
                content: math_ml_list[i],
                lang: 'en',
                mimeType: 'application/mathml+xml'
            })
        }
    })
}

export const plainTextToImmersiveReader = (challenge, str) => {
    const content = {
        title: challenge.name,
        chunks: []
    }
    newContent(content, str)
    return content
}

export const objectsDiff = (obj1, obj2) => {
    let result = {};
    Object.keys(obj1).map(key => {
        const value = obj1[key];
        if (obj2[key] !== value) {
            result[key] = obj2[key];
        }
    });

    return result;
}
