import React from 'react'
import {isEqual, get, set, clone, slice, filter, isEmpty, trim} from 'lodash'

import {convertLatexToKatex, convertKatexToString} from 'utils/math'
import BaseAnswer from 'common/challengeNew/CardComponent/AnswerComponent/BaseAnswer'
import ExpressionSingle from './ExpressionSingle'


export default class ExpressionMulti extends BaseAnswer {
    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.existing_answer, prevProps.existing_answer)) {
            if (this.props.is_preview) {
                const answer = this.initAnswer()
                this.setState({answer})
            }
        }
    }

    defaultAnswer() {
        return get(this.props.component_data, 'data.prefill', [null])
    }

    isValid() {
        const validate_answers = this.state.answer.map((ans) => {
            try {
                const test_str = convertLatexToKatex(ans)
                convertKatexToString(test_str)
            } catch (error) {
                throw('Invalid symbol or syntax in expression.')
            }

            return !isEmpty(trim(ans, '\\ '))
        })
        const invalid_ones = filter(validate_answers, (is_valid) => !is_valid)
        return invalid_ones.length === 0
    }

    getMaxLine() {
        return get(this.props.component_data, 'config.max_line', 10)
    }

    onChange() {
        return (key) => (answer) => {
            const clone_ans = clone(this.state.answer)
            const new_ans = set(clone_ans, key, answer)
            this.setState({
                answer: new_ans
            })
        }
    }

    renderExpressions() {
        return this.state.answer.map((ans, key) => {
            // if (ans === 'null') {
            //     ans = '';
            // }
            const button_sets = get(this.props.component_data, 'data.button_sets', [])
            return (
                <div key={`expression_single_${key}`}>
                    <div className="expression-multi-each">
                        <ExpressionSingle
                            answer={isEmpty(ans) ? '' : ans}
                            onChange={this.onChange()(key)}
                            button_sets={button_sets}
                            is_readonly={this.props.is_preview}
                        />
                    </div>
                </div>
            )
        })
    }

    renderRemoveButton() {
        const disabled = this.props.is_preview || this.state.answer.length <= 1
        const onRemove = () => {
            if (!disabled) {
                this.setState({
                    answer: slice(this.state.answer, 0, -1)
                })
            }
        }
        return (
            <div>
                <button className="btn btn-challenge" disabled={disabled} onClick={onRemove}>
                    <i className="fas fa-minus">
                    </i>
                </button>
            </div>
        )
    }

    renderAddButton() {
        const disabled = this.props.is_preview || this.state.answer.length >= this.getMaxLine()
        const onAdd = () => {
            if (!disabled) {
                this.setState({
                    answer: [...this.state.answer, null]
                })
            }
        }
        return (
            <div>
                <button className="btn btn-challenge" disabled={disabled} onClick={onAdd}>
                    <i className="fas fa-plus">
                    </i>
                </button>
            </div>
        )
    }

    renderButtons() {
        return (
            <div className="expression-multi-buttons">
                <div className="flex-row flex-h-end flex-box-0-10">
                    {this.renderRemoveButton()}

                    {this.renderAddButton()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-expression-multi">
                <div>
                    {this.renderExpressions()}
                </div>

                {this.renderButtons()}
            </div>
        )
    }
}
