import {map, head, get} from 'lodash'

import {getIntervalCode, getIntervalName, getIntervalColor} from '../utils'
import {computeStudentSkillData, getUniqSkills, getStageName} from '../StudentTable/utils'


export const addStudentTableTitleRequests = (sheetId) => {
    return [
        {
            appendCells: {
                sheetId: sheetId,
                fields: '*',
                rows: [
                    {
                        values: [
                            {
                                userEnteredValue: {
                                    stringValue: `What are students' Strengths and Gaps?`
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
}

export const addStudentTableHeaderRequests = (sheetId, raw_data) => {
    const computed_uniq_skills = getUniqSkills(raw_data.student_skill_data)
    const skills_headers = computed_uniq_skills.map((skill) => {
        return {
            userEnteredValue: {
                stringValue: `${skill.code}: ${skill.name}`
            }
        }
    })

    return [
        {
            appendCells: {
                sheetId: sheetId,
                fields: '*',
                rows: [
                    {
                        values: [
                            {
                                userEnteredValue: {
                                    stringValue: 'Student'
                                }
                            },
                            {
                                userEnteredValue: {
                                    stringValue: 'Engagement Stage'
                                }
                            },
                            ...skills_headers
                        ]
                    }
                ]
            }
        }
    ]
}

export const addStudentTableRequests = (sheetId, raw_data, mode) => {
    const computed_student_data = computeStudentSkillData(raw_data.student_skill_data, raw_data.skill_structure_data, mode)
    const computed_uniq_skills = getUniqSkills(raw_data.student_skill_data)

    const rows = map(computed_student_data, (student) => {
        const values = []
        values.push({
            userEnteredValue: {
                stringValue: student.fname + ' ' + head(student.lname)
            }
        })
        values.push({
            userEnteredValue: {
                stringValue: getStageName(student.stage)
            }
        })

        computed_uniq_skills.map((skill) => {
            const points_percent = get(student, ['skills', skill.code, 'points_percent'])
            if (points_percent === undefined) {
                values.push({
                    userEnteredValue: {
                        stringValue: ''
                    }
                })
            } else {
                const interval_code = getIntervalCode(raw_data.practice_code, points_percent)
                const interval_name = getIntervalName(raw_data.practice_code, interval_code)
                const interval_color = getIntervalColor(raw_data.practice_code, interval_code, true)
                values.push({
                    userEnteredValue: {
                        stringValue: `${points_percent}% (${interval_name})`
                    },
                    userEnteredFormat: {
                        backgroundColor: interval_color
                    }
                })
            }
        })

        return {
            values
        }
    })

    return [
        {
            appendCells: {
                sheetId: sheetId,
                fields: '*',
                rows
            }
        }
    ]
}
