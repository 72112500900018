import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {processStringContent} from 'utils/content'


class PollInlineOption extends Component {
    renderBar() {
        const {option_votes, total_votes} = this.props
        return (
            <div className="poll-bar flex-auto flex-row">
                <div className="poll-bar-color" style={{flex: option_votes}}>
                </div>
                <div style={{flex: total_votes - option_votes}}>
                </div>
            </div>
        )
    }

    renderStat() {
        const {option_votes} = this.props
        return (
            <div className="poll-stat flex-row flex-vh-center">
                <div>
                    {option_votes}
                </div>
                <i className="fas fa-user"></i>
            </div>
        )
    }

    renderContent() {
        const class_name_icon = classNames('poll-option-radio far', {
            "fa-dot-circle": this.props.is_selected,
            "fa-circle": !this.props.is_selected
        })

        const option_content = processStringContent(this.props.poll_option.option, {
            className: "flex-row flex-v-center"
        })

        return (
            <div className="poll-option-content-container">
                <div className="flex-row flex-v-center">
                    <i className={class_name_icon}></i>

                    <div className="flex-auto">
                        {option_content}
                    </div>
                </div>
            </div>
        )
    }

    renderResult() {
        if (this.props.show_graph) {
            return (
                <div className="poll-option-result-container flex-row">
                    {this.renderBar()}

                    {this.renderStat()}
                </div>
            )
        }
    }

    render() {
        const class_name_poll_option = classNames('poll-option', {
            "poll-option-selected": this.props.is_selected,
            "z-depth-2": this.props.is_selected
        })

        return (
            <div className={class_name_poll_option}>
                {this.renderContent()}

                {this.renderResult()}
            </div>
        )
    }
}

PollInlineOption.propTypes = {
    poll_option: PropTypes.object.isRequired,
    is_selected: PropTypes.bool.isRequired,
    show_graph: PropTypes.bool.isRequired,
    option_votes: PropTypes.number.isRequired,
    total_votes: PropTypes.number.isRequired
}

export default PollInlineOption
