import React from 'react'
import {useSelector} from "react-redux";

import ButtonCopyText from 'common/ButtonCopyText';
import analytic from 'utils/Analytic';

export default function () {
    const lesson = useSelector(state => state.lesson);

    function getLink() {
        return `${window.location.origin}/join/${lesson.code}`
    }

    function whenCopyLink() {
        analytic.log(
            'classSetupCopyLink',
            lesson.code
        );
    }

    return (
        <div className="class-share-content-link">
            <h5 className="header-new">
                {`Copy and paste this link`}
            </h5>

            <div>
                {`Your students will be able to join the Challenge by clicking on the link.`}
            </div>

            <div className="flex-row flex-v-center flex-wrap flex-box-5-10">
                <div>
                    <div className="content-link-link">
                        {getLink()}
                    </div>
                </div>

                <div>
                    <ButtonCopyText
                        text_to_copy={getLink()}
                        whenClick={whenCopyLink}
                    />
                </div>
            </div>
        </div>
    );
}
