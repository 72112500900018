import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {SuccessMessageAction, ErrorMessageAction, Toggle} from "mindsets-js-sdk";


export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const [is_on, setIsOn] = useState(lesson.is_done);

    function toggleMarkAsDone() {
        setIsOn(!is_on);
        Promise.resolve()
            .then(() => MindsetsJsSDK().Api.lesson.toggleMarkAsDone(lesson.id))
            .then(() => dispatch(SuccessMessageAction(!lesson.is_done ? 'Congratulations! This Class is complete.' : 'This Class has been reactivated')))
            .catch(error => {
                console.error(error);
                dispatch(ErrorMessageAction('Couldn\'t update the Class'));
            });
    }

    useEffect(() => {
        setIsOn(lesson.is_done);
    }, [lesson.is_done]);

    return (
        <div className='flex-row flex-v-center px-4 py-2'>
            <i className={"mr-2 action-icon fas fa-check-circle"}/>
            <div className='switch-text'>Mark Class as completed</div>
            <div className="flex-auto"/>
            <Toggle onChange={toggleMarkAsDone} is_on={is_on}/>
            <div className="switch-value">
                {is_on ? 'Yes' : 'No'}
            </div>
        </div>
    );
}
