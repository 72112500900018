import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {find, sortBy} from "lodash";
import {
    SetTeacherFacilitationSelectedCardIndexAction,
    SetTeacherFacilitationSelectedStreamIdAction
} from "mindsets-js-sdk";

import {getCardName} from 'utils/challenge/streamEntity'
import AvatarName from 'common/user/AvatarName'
import CardNavigator from 'common/challenge/CardNavigator'
import analytic from 'utils/Analytic'
import TeacherReportAction from "common/TeacherReportAction"
import {getStudentStreams} from "../../utils";


export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_orig = useSelector(state => state.streams);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const selected_stream_id = useSelector(state => state.teacher_facilitation_selected_stream_id);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);
    const streams = sortBy(getStudentStreams(streams_orig, me.id), (s) => {
        switch (sort_by) {
            case 'progress':
                return -(s.index);
            case 'name':
            default:
                return s.user.full_name;
        }
    });

    function onSelectingStream() {
        if (null !== selected_stream_id) {
            dispatch(SetTeacherFacilitationSelectedStreamIdAction(null));
        }
    }

    function onSelectCard(selected_card_index_new) {
        if (selected_card_index_new >= 0 && selected_card_index_new < stream_entities.length) {
            if (selected_card_index_new !== selected_card_index) {
                dispatch(SetTeacherFacilitationSelectedCardIndexAction(selected_card_index_new));
            }
        }
    }

    function getSelectedStream() {
        return find(streams, {id: selected_stream_id});
    }

    function renderProgress() {
        return (
            <div>
                {`is on`} {getCardName(lesson.challenge_version, getSelectedStream().index)}
            </div>
        );
    }

    function renderSelectStream() {
        const onClick = (e) => {
            e.preventDefault();
            onSelectingStream();
            analytic.log('facilitationByStudentViewAnotherStudent');
        }
        return (
            <div className="flex-row flex-wrap">
                <TeacherReportAction
                    lesson={lesson}
                    challenge_stream_entities={stream_entities}
                    streams={streams}
                    selected_stream_id={selected_stream_id}
                >
                    <a className="link-new" href={'#!'} onClick={e => e.preventDefault()}>
                        <span>Download Student Report</span>
                        <i className="fas fa-download mr-2"/>
                    </a>
                </TeacherReportAction>
                <span className='hide-on-small-only'>|</span>
                <a className="link-new ml-lg-2 ml-xl-2 ml-md-2 ml-sm-2" href="#" onClick={onClick}>
                    <span>{`View Another Student`}</span>
                    <i className="fas fa-random"/>
                </a>
            </div>
        );
    }

    function renderStream() {
        return (
            <AvatarName
                user={getSelectedStream().user}
                private_mode={!show_student}
                content_right={renderProgress()}
                content_bottom={renderSelectStream()}
            />
        );
    }

    return (
        <div className="bystudent-toolbar flex-row flex-v-center flex-between flex-wrap">
            <div>
                {renderStream()}
            </div>

            <div>
                <CardNavigator
                    challenge_version={lesson.challenge_version}
                    stream_entities={stream_entities}
                    selected_card_index={selected_card_index}
                    onSelect={onSelectCard}
                    popup_setting={{right: '-60px'}}
                />
            </div>
        </div>
    );
}
