import {actions, toastActions} from "mindsets-js-sdk";

import {googleAuthenticate, grantGoogleScopes} from 'utils/google';
import {batchUpdateRequests} from './utils';


export default class ExportToGoogleDrive {
    execute(raw_data, dispatch) {
        this.raw_data = raw_data

        const scopes = ['https://www.googleapis.com/auth/spreadsheets'];

        gapi.load('client', function () {
            gapi.client.init({}).then(function () {
                gapi.client.load('sheets', 'v4');
            });
        });
        googleAuthenticate(scopes, this.dispatch, tokenResponse => {
            if (google.accounts.oauth2.hasGrantedAllScopes(tokenResponse, ...scopes)) {
                this.allSteps(dispatch);
            } else {
                grantGoogleScopes(scopes, () => this.allSteps(dispatch), dispatch);
            }
        });
    }

    allSteps(dispatch) {
        return this.createSheetRequest().then((response) => {
            return batchUpdateRequests(this.spreadsheet, this.raw_data)
        }).then((response) => {
            const success_message = `The Report has been exported to your Drive. `
                + `<a href="${this.spreadsheet.spreadsheetUrl}" target="_blank">Click here.</a>`;
            dispatch(toastActions.SuccessMessageAction(success_message, true));
        }).catch((error) => {
            console.error(error)
        }).then(() => {
            dispatch(actions.LoadingAction(false));
        })
    }

    createSheetRequest() {
        return gapi.client.sheets.spreadsheets.create({
            properties: {
                title: 'Student Performance at Mindsets'
            }
        }).then((response) => {
            this.spreadsheet = response.result;
            return response.result;
        })
    }
}
