import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {find} from 'lodash'

import CardRow from './CardRow'


class CardContent extends Component {
    getSupportData = (component_type, question_id) => {
        switch (component_type) {
            case 'poll.block':
            case 'poll.inline':
                // For poll.block, question_id should be undefined
                // Agreed by Katherine, maximum one poll.block per page
                const poll = find(
                    this.props.stream_entity.polls,
                    (p) => p.question_id == question_id
                )
                if (!poll) {
                    throw 'Error, can not find poll by question id'
                    return undefined
                }
                const vote = find(
                    this.props.stream_entity.votes,
                    (v) => v.poll_id === poll.id
                );
                //Inline poll does not require and does not use vote prop.
                return {
                    poll,
                    vote
                };
            case 'spreadsheet':
                //Spreadsheet does not need any support data(original spreadsheet) ad it uses component_data for it.
                return {};
        }
    }

    getExistingAnswer = (question_id) => {
        return find(this.props.stream_entity.responses, {question_id});
    }

    renderRows() {
        if (this.props.card_content && this.props.card_content.rows) {
            return this.props.card_content.rows.map((row_data, i) => {
                return (
                    <CardRow
                        key={i}
                        row_data={row_data}
                        challenge_version={this.props.challenge_version}
                        stream_entity={this.props.stream_entity}
                        lesson_id={this.props.lesson_id}
                        stream_id={this.props.stream_id}
                        is_preview={this.props.is_preview}
                        is_latest={this.props.is_latest}
                        getSupportData={this.getSupportData}
                        getExistingAnswer={this.getExistingAnswer}
                        refComponent={this.props.refComponent(i)}
                    />
                )
            })
        }
    }

    render() {
        return (
            <div className="challenge-card-content">
                {this.renderRows()}
            </div>
        )
    }
}

CardContent.propTypes = {
    card_content: PropTypes.object.isRequired,
    challenge_version: PropTypes.object.isRequired,
    stream_entity: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    is_preview: PropTypes.bool,
    is_latest: PropTypes.bool,
    refComponent: PropTypes.func.isRequired
}

export default CardContent
