import React, {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {throttle, isEmpty} from 'lodash';
import {
    Tabs,
    LoadLessonAction,
    Cookie,
    LoadStreamsAction,
    LoadChallengeVersionStreamEntitiesAction,
    SetTeacherFacilitationSelectedCardIndexAction,
    SetTeacherFacilitationShowStudentsAction,
    SetTeacherFacilitationSortByAction
} from "mindsets-js-sdk";

import Nav from './Nav';
import analytic from 'utils/Analytic';
import useAuth from "../../hooks/useAuth";
import Overview from "./Overview";
import ByQuestion from "./ByQuestion";
import ByStudent from "./ByStudent";
import Actions from "./Menu/Actions";

export const SHOW_STUDENTS_NAMES_COOKIE_NAME = 'mindsets_show_students_names_';
export const SORT_STUDENTS_BY_COOKIE_NAME = 'mindsets_sort_students_by_';

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);

    const fetchLessonThrottle = useCallback(throttle(fetchLesson, 2000, {
        leading: false,
        trailing: true
    }), []);
    const fetchLessonStreamsThrottle = useCallback(throttle(fetchLessonStreams, 2000, {
        leading: false,
        trailing: true
    }), []);

    function fetchData() {
        return fetchLesson().then(lesson => {
            fetchChallengeStreamEntities(lesson);
            fetchLessonStreams(lesson);
        });
    }

    function fetchLesson() {
        return dispatch(LoadLessonAction(params.code, {'selectors[0]': 'challenge_code_standards'}));
    }

    function fetchChallengeStreamEntities(lesson) {
        return dispatch(LoadChallengeVersionStreamEntitiesAction(lesson.challenge_version_id));
    }

    function fetchLessonStreams(lesson) {
        return dispatch(LoadStreamsAction({
            'filters[lesson_id][0]': 'eq=' + lesson.id,
            'selectors[0]': 'user',
            'selectors[1]': 'stream_entities_responses',
            'limit': 0,
        }));
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const show_student_new = ['true', 'false'].includes(Cookie.getCookie(SHOW_STUDENTS_NAMES_COOKIE_NAME + params.code)) ? (Cookie.getCookie(SHOW_STUDENTS_NAMES_COOKIE_NAME + params.code) === 'true') : true
        if (show_student_new !== show_student) {
            dispatch(SetTeacherFacilitationShowStudentsAction(show_student_new));
        }
    }, []);

    useEffect(() => {
        const sort_by_new = ['name', 'progress'].indexOf(Cookie.getCookie(SORT_STUDENTS_BY_COOKIE_NAME + params.code)) >= 0 ? Cookie.getCookie(SORT_STUDENTS_BY_COOKIE_NAME + params.code) : 'name';
        if (sort_by_new !== sort_by) {
            dispatch(SetTeacherFacilitationSortByAction(sort_by_new));
        }
    }, []);

    useEffect(() => {
        let lesson_channel;
        if (!isEmpty(lesson) && !lesson_channel) {
            lesson_channel = pusher.subscribe('lesson_' + lesson.id);
            lesson_channel.bind('lesson.updated', fetchLessonThrottle);
            lesson_channel.bind('stream.updated', () => fetchLessonStreamsThrottle(lesson));
            lesson_channel.bind('responses.created', () => fetchLessonStreamsThrottle(lesson));
            lesson_channel.bind('lesson.poll.updated', () => fetchLessonStreamsThrottle(lesson));
            lesson_channel.bind('lesson.index.updated', () => {
                fetchLessonThrottle();
                fetchLessonStreamsThrottle(lesson);
            });
            lesson_channel.bind('lesson.mode.changed', fetchLessonThrottle);

            return () => {
                lesson_channel.unbind();
                lesson_channel.unsubscribe();
            }
        }
    }, [lesson.id]);

    useEffect(() => {
        if (lesson.index >= 0) {
            dispatch(SetTeacherFacilitationSelectedCardIndexAction(lesson.index));
        }
    }, [lesson.index]);

    function renderTabs() {
        return [
            {
                key: 'overview',
                name: 'Overview',
                content: <Overview/>,
                onClickCallback: () => analytic.log('facilitationGotoOverview', {
                    name: lesson.challenge_version.name,
                    code: lesson.challenge_version.challenge_code.code,
                }, lesson.code)
            },
            {
                key: 'presentation',
                name: lesson.mode === 'teacher' && lesson.user_id === me.id ? 'Presentation' : 'By Question',
                content: <ByQuestion/>,
                onClickCallback: () => analytic.log(lesson.mode === 'teacher' && lesson.user_id === me.id ? 'facilitationGotoPresentation' : 'facilitationGotoByQuestion', {
                    name: lesson.challenge_version.name,
                    code: lesson.challenge_version.challenge_code.code,
                }, lesson.code)
            },
            {
                key: 'by_student',
                name: 'By Student',
                content: <ByStudent/>,
                onClickCallback: () => analytic.log('facilitationGotoByStudent', {
                    name: lesson.challenge_version.name,
                    code: lesson.challenge_version.challenge_code.code,
                }, lesson.code)
            }
        ];
    }

    if (lesson && !isEmpty(lesson) && me) {
        return (
            <div id="teacher-facilitation">
                <div>
                    <Nav/>

                    <Tabs
                        items={renderTabs()}
                        container_class='teacher-facilitation-menu'
                        menu_wrapper_class='teacher-facilitation-menu-tabs'
                        menu_container_class='container'
                        use_query_string={true}
                        extra_menu_content={<Actions/>}
                    />
                </div>
            </div>
        );
    }

    return null;
}
