import React, {useEffect} from 'react';
import useAuth from "../../hooks/useAuth";
import {useDispatch} from "react-redux";

import './student_story.scss';

import {
    LoadStudentStoryDataAction,
    LoadedStudentStoryDataAction,
    SetStudentStoryPageIndexAction
} from "../../actions/student_story_actions";
import Prev from "./Prev";
import Pages from "./Pages";
import Next from "./Next";

export default function () {
    useAuth(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoadStudentStoryDataAction());

        return () => {
            dispatch(LoadedStudentStoryDataAction(null));
            dispatch(SetStudentStoryPageIndexAction(0));
        }
    }, []);

    return (
        <div id='student-story'>
            <div className='student-story-container'>
                <Prev/>
                <Pages/>
                <Next/>
            </div>
        </div>
    );
}