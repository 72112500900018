import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {find} from "lodash";
import Highcharts from 'highcharts';

import {getCategoryNames, computeCategoryData} from './utils'

export default function () {
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const practices = useSelector(state => state.pam_practices);
    const skill_structure_data = useSelector(state => state.pam_challenge_skill_structure);
    const student_skill_data = useSelector(state => state.pam_student_skill_data);
    const mode = useSelector(state => state.pam_mode);

    const selected_practice = find(practices, {id: selected_practice_id});

    function draw() {
        const graph = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Performance Claims'
            },
            xAxis: {
                categories: getCategoryNames(student_skill_data)
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Student %'
                }
            },
            tooltip: {
                pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    maxPointWidth: 100
                }
            },
            series: computeCategoryData(
                student_skill_data,
                skill_structure_data,
                mode,
                selected_practice.code
            ),
            legend: {
                enabled: true,
                title: {
                    text: 'Achievement levels'
                }
            },
            credits: {
                enabled: false
            }
        }

        Highcharts.chart('pam-challenge-category-performance', graph)
    }

    useEffect(() => {
        draw();
    }, []);

    useEffect(() => {
        draw();
    }, [student_skill_data, skill_structure_data, mode]);

    return (
        <div>
            <div id="pam-challenge-category-performance"/>
        </div>
    );
}
