import {get, map, isNumber, isPlainObject, isArray, isString} from 'lodash'

import {replaceSpecialChars} from 'utils/content'

export default class Answer {
    render(cells, answer) {
        const answer_content = get(answer, 'data.answer');
        if (answer_content !== undefined) {
            switch (true) {
                case get(answer_content, 'text') !== undefined:
                    const text = get(answer_content, 'text');
                    cells.push(replaceSpecialChars(text));
                    break;
                case isPlainObject(answer_content):
                    map(answer_content, (answer, key) => {
                        cells.push(key + ': ' + replaceSpecialChars(answer));
                    });
                    break;
                case isArray(answer_content):
                    map(answer_content, (answer) => {
                        cells.push(replaceSpecialChars(answer));
                    });
                    break;
                case isString(answer_content):
                case isNumber(answer_content):
                default:
                    cells.push(replaceSpecialChars(answer_content));
            }
        }
    }
}
