import {toastActions} from "mindsets-js-sdk";

import HeaderCompiler from "./HeaderCompiler";
import SummaryCompiler from "./SummaryCompiler";
import DataCompiler from "./DataCompiler";
import analytic from 'utils/Analytic';
import {downloadFile} from 'utils/index';
import {store} from "../../index";

export default class DownloadCSV {
    constructor() {
        this.dispatch = store.dispatch;
        this.headerCompiler = new HeaderCompiler();
        this.summaryCompiler = new SummaryCompiler();
        this.dataCompiler = new DataCompiler();
    }

    execute(title, lesson, streams, challenge_stream_entities) {
        try {
            let rows = [];
            this.headerCompiler.compile(rows, lesson, streams);
            this.summaryCompiler.compile(rows, challenge_stream_entities, streams, lesson);
            this.dataCompiler.compile(rows, streams, challenge_stream_entities, lesson);
            const string = rows.map(row => {
                return row.map(cell => '"' + cell + '"').join(",");
            }).join("\n");
            downloadFile(this.dispatch, title, string);
        } catch (e) {
            this.dispatch(toastActions.ErrorMessageAction('We are sorry, there was a problem with generating a report for you. We will fix it ASAP.'));
            console.error(e);
            analytic.log('downloadReportError', lesson.code, e.toString(), e.hasOwnProperty('stack') ? e.stack : '');
        }
    }
}
