import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, find} from 'lodash';
import {DateRangeSelector} from "mindsets-js-sdk";

import PAMMenu from 'common/PAMMenu'
import SchoolFilter from 'common/spam/SchoolFilter'
import ChallengeFilter from 'common/spam/ChallengeFilter'
import PracticeFilter from 'common/spam/PracticeFilter'
import StudentsAndSkills from './StudentsAndSkills'
import useAuth from "../../hooks/useAuth";
import Forbidden from "../Forbidden";
import {userCan} from "utils/membership";
import {isEducator} from "utils/role";
import {SetPAMFromAction, SetPAMTillAction} from "../../actions/pam_actions";

export const FILTER_COOKIE_NAME = 'mindsets_sam_engagement_filter'

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const challenges = useSelector(state => state.me_school_challenges);
    const practices = useSelector(state => state.pam_practices);

    const selected_challenge = find(challenges, {id: selected_challenge_id});
    const selected_practice = find(practices, {id: selected_practice_id});

    function renderStudentsAndSkills() {
        if (
            !selected_school_id
            || !selected_challenge_id
            || isEmpty(selected_challenge)
            || !selected_practice_id
            || isEmpty(selected_practice)
        ) {
            return (
                <div className="section">
                    {`Select a school, a Challenge and a Practice to view performance data.`}
                </div>
            )
        }

        return (
            <StudentsAndSkills
                selected_school_id={selected_school_id}
                from={from}
                till={till}
                selected_challenge_id={selected_challenge_id}
                selected_challenge={selected_challenge}
                selected_practice_id={selected_practice_id}
                selected_practice={selected_practice}
            />
        )
    }

    function renderContent() {
        if (me && isEducator(me) && me.permissions.includes('can_see_pam') && userCan(me.subscription_status, 'see_pam')) {
            return (
                <div id="pam-challenge">
                    <div className="container">
                        <div className="section">
                            <PAMMenu/>
                        </div>

                        <div>
                            <div className="flex-row flex-box-10-10 flex-wrap">
                                <div>
                                    <SchoolFilter/>
                                </div>

                                <div>
                                    <DateRangeSelector
                                        from={from}
                                        till={till}
                                        onUpdate={({from, till}) => {
                                            dispatch(SetPAMFromAction(from));
                                            dispatch(SetPAMTillAction(till));
                                        }}
                                    />
                                </div>

                                <div>
                                    <ChallengeFilter/>
                                </div>

                                <div>
                                    <PracticeFilter/>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            {renderStudentsAndSkills()}
                        </div>
                    </div>
                </div>
            );
        } else if (me && (!isEducator(me) || !me.permissions.includes('can_see_pam') || !userCan(me.subscription_status, 'see_pam'))) {
            return (
                <Forbidden/>
            );
        }

        return null;
    }

    return (
        <>
            {renderContent()}
        </>
    );
}
