import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {first, get, set} from "lodash";
import MindsetsJsSDK, {
    rolesConstants,
    toastActions,
    statesConstants,
    actions,
    SetUserAction,
    UpdateMeAction,
    CheckSchoolExistsAction,
    LoadMeAction,
    CreateSchoolAction
} from "mindsets-js-sdk";

import analytic from "utils/Analytic";
import {facebookTrackEvent} from "utils/facebookHelper";

const Footer = function (
    {
        mode,
        setMode,
        submitting,
        setSubmitting,
        setIsOpened,
        validation_error_messages,
        setValidationErrorMessages
    }
) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const user = useSelector(state => state.user);
    const school = useSelector(state => state.school);
    const curricula = useSelector(state => state.curricula);

    let user_new = JSON.parse(JSON.stringify(user));
    let validation_error_messages_new = JSON.parse(JSON.stringify(validation_error_messages));

    function onSchoolChoose(school) {
        user_new.school_id = school.id;
        user_new.school = school;
        if (!user_new.state && school.state) {
            user_new.state = school.state;
            const curriculum = first(curricula.filter(curriculum => {
                return curriculum.country_code === user_new.country && curriculum.state_code === school.state;
            }).sort((a, b) => {
                if (a.is_default && !b.is_default) {
                    return -1;
                } else if (!a.is_default && b.is_default) {
                    return 1;
                }

                return 0;
            }));
            if (curriculum) {
                user_new.curriculum_id = curriculum.id;
            } else {
                user_new.curriculum_id = null;
            }
        }
        dispatch(SetUserAction(user_new));
        setMode('user');
        setValidationErrorMessages({});
    }

    function validateSchoolData() {
        if (!school.name || '' === school.name) {
            validation_error_messages_new['schoolname'] = 'Please enter the name of the school';
        }
        if (!school.location || '' === school.location) {
            validation_error_messages_new['schoollocation'] = 'Please enter the location of the school';
        }
        if (!school.city || '' === school.city) {
            validation_error_messages_new['schoolcity'] = 'Please enter the city of the school';
        }
        if (!school.country || '' === school.country) {
            validation_error_messages_new['schoolcountry'] = 'Please select the country of the school';
        }
        if (!school.zip || '' === school.zip) {
            validation_error_messages_new['schoolzip'] = 'Please enter the zip of the school';
        }
        if (school.country && '' !== school.country && statesConstants.STATES.hasOwnProperty(school.country)) {
            if (!school.state || '' === school.state) {
                validation_error_messages_new['schoolstate'] = 'Please select the state of the school';
            }
        }
        setValidationErrorMessages(validation_error_messages_new);

        return Object.keys(validation_error_messages_new).length <= 0;
    }

    function onUserCancel() {
        if (!!me) {
            const has_visited = me.general_data && me.general_data.dashboard_visited === 1;
            const has_country = (rolesConstants.ROLE_TEACHER === me.persona && !!me.country) || rolesConstants.ROLE_STUDENT === me.persona;
            const has_school = (rolesConstants.ROLE_TEACHER === me.persona && !!me.school_id) || rolesConstants.ROLE_STUDENT === me.persona;
            const has_grades = rolesConstants.ROLE_TEACHER === me.persona && me.grades && me.grades.length > 0 || rolesConstants.ROLE_STUDENT === me.persona;
            if (has_visited && has_country && has_school && has_grades) {
                setIsOpened(false);
                setValidationErrorMessages({});

                return;
            }
            if (!has_country) {
                validation_error_messages_new['country'] = 'Please select country';
                setValidationErrorMessages(validation_error_messages_new);
            }
            if (!has_school) {
                validation_error_messages_new['school'] = 'Please select your school';
                setValidationErrorMessages(validation_error_messages_new);
            }
            if (!has_grades) {
                validation_error_messages_new['grade'] = 'Please select grades you teach';
                setValidationErrorMessages(validation_error_messages_new);
            }
        }
        dispatch(toastActions.ErrorMessageAction('Please complete your profile.'));
    }

    function onSave() {
        if (!user_new.fname) {
            validation_error_messages_new['fname'] = 'Please enter your first name';
        }
        if (!user_new.lname) {
            validation_error_messages_new['lname'] = 'Please enter your last name';
        }
        if (user_new.persona === rolesConstants.ROLE_TEACHER && !user_new.country) {
            validation_error_messages_new['country'] = 'Please select country';
        }
        if (user_new.persona === rolesConstants.ROLE_TEACHER && !user_new.school_id) {
            validation_error_messages_new['school'] = 'Please select your school';
        }
        if (user_new.persona === rolesConstants.ROLE_TEACHER && (!user_new.grades || user_new.grades.length <= 0)) {
            validation_error_messages_new['grade'] = 'Please select grades you teach';
        }

        if (user_new.password_reset) {
            if (!user_new.new_password) {
                validation_error_messages_new['new_password'] = 'Please set your password';
            } else if (user_new.new_password.length < 6) {
                validation_error_messages_new['new_password'] = 'Choose a password with at least 6 characters';
            }
            if (user_new.new_password != user_new.confirm_password) {
                validation_error_messages_new['confirm_password'] = 'Passwords do not match';
            }
        }

        setValidationErrorMessages(validation_error_messages_new);
        if (Object.keys(validation_error_messages_new).length > 0) {
            return;
        }

        if (!submitting) {
            setSubmitting(true);

            Promise.resolve()
            .then(() => {
                if (user_new.password_reset) {
                    return MindsetsJsSDK().API.put(`password/${user_new.id}`, {new_password: user_new.new_password})
                }
            })
            .then(() => {
                delete user_new.roles;
                if (!user_new.general_data || !user_new.general_data.dashboard_visited || !user_new.general_data || user_new.general_data.dashboard_visited !== user_new.general_data.dashboard_visited) {
                    set(user_new, 'general_data.dashboard_visited', 1);
                }
                if (user_new.password_reset) {
                    user_new.password_reset = 0;
                }
                return dispatch(UpdateMeAction(user_new))
            })
            .then(user => {
                analytic.log('updateUser', user);
                analytic.log('profileComplete', user);

                // ga event tracking
                if (user_new.persona === rolesConstants.ROLE_TEACHER) {
                    ga('send', 'event', {
                        'eventCategory': 'login & sign up',
                        'eventAction': 'teacher profile complete',
                        'eventLabel': 'campaign1'
                    });
                }

                if (!get(user, 'general_data.dashboard_visited', 0)) {
                    // Facebook track event for new registration
                    facebookTrackEvent('CompleteRegistration', {email: user.email});
                }
            })
            .then(() => setSubmitting(false))
            .then(() => setIsOpened(false))
            .then(() => dispatch(LoadMeAction()))
            .catch(error => {
                console.error(error);
                dispatch(actions.LoadingAction(false));
                dispatch(toastActions.ErrorMessageAction('We could not successfully save your data. Please try again.'));
                setSubmitting(false);
            });
        }
    }

    function onSchoolFormSubmit(check = true) {
        if (!submitting) {
            setSubmitting(true);
            if (check) {
                dispatch(CheckSchoolExistsAction(school))
                    .then(successful => {
                        if (successful) {
                            dispatch(CreateSchoolAction(school)).then(school => {
                                setSubmitting(false);
                                onSchoolChoose(school);
                            });
                        } else {
                            setMode('schools');
                            setSubmitting(false);
                        }
                    })
                    .catch(error => {
                        setSubmitting(false);
                        dispatch(toastActions.ErrorMessageAction(error))
                    });
            } else {
                dispatch(CreateSchoolAction(school)).then(school => {
                    setSubmitting(false);
                    onSchoolChoose(school);
                });
            }
        }
    }

    if ('confirmation' === mode) {
        return (
            <div className="modal-footer">
                <div className="flex-row flex-h-end h100">
                    <button
                        className="btn btn-dark"
                        onClick={event => {
                            event.preventDefault();
                            onSchoolFormSubmit(true);
                        }}
                        disabled={submitting}
                    >CONFIRM
                    </button>
                </div>
            </div>
        );
    } else if ('school' === mode) {
        return (
            <div className="modal-footer">
                <div className="flex-row flex-h-end h100">
                    <button
                        className="btn btn-dark"
                        onClick={event => {
                            event.preventDefault();
                            if (validateSchoolData()) {
                                setMode('confirmation');
                            }
                        }}
                    >ADD SCHOOL
                    </button>
                </div>
            </div>
        );
    } else if ('schools' === mode) {
        return (
            <div className="modal-footer">
                <div className="flex-row flex-h-end h100">
                    <button
                        className="btn btn-dark"
                        onClick={event => {
                            event.preventDefault();
                            onSchoolFormSubmit(false);
                        }}
                        disabled={submitting}
                    >Create new school
                    </button>
                </div>
            </div>
        );
    } else {
        const renderCancel = () => {
            const dashboard_visited = get(me, 'general_data.dashboard_visited');
            if (!dashboard_visited || me.password_reset) {
                return null;
            }

            return (
                <button onClick={event => {
                    event.preventDefault();
                    onUserCancel();
                }} className="btn btn-flat">Cancel
                </button>
            );
        }

        return (
            <div className="modal-footer">
                <div className="flex-row flex-v-center h100 flex-h-end flex-gap-10">
                    {renderCancel()}
                    <button onClick={event => {
                        event.preventDefault();
                        onSave();
                    }} className="btn btn-dark" disabled={submitting}>SAVE
                    </button>
                </div>
            </div>
        );
    }
};

Footer.propTypes = {
    mode: PropTypes.string.isRequired,
    setMode: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    setIsOpened: PropTypes.func.isRequired,
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired
}

export default Footer;
