import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import SAMMenu from 'common/SAMMenu';
import EngagementStageLegend from 'common/EngagementStageLegend';
import CompletionByChallenge from "./CompletionByChallenge";
import CompletionBySchool from "./CompletionBySchool";
import OverallEngagement from "./OverallEngagement";
import Filters from "./Filters";
import Overview from '../SAMDashboard/Overview'
import useAuth from "../../hooks/useAuth";
import Forbidden from "../Forbidden";
import {userCan} from "utils/membership";
import {isEducator} from "utils/role";
import {SetPAMSelectedChallengeIdAction} from "../../actions/pam_actions";

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const selected_school_id = useSelector(state => state.pam_selected_school_id);

    function renderContent() {
        if (me && isEducator(me) && me.permissions.includes('can_see_sam') && userCan(me.subscription_status, 'see_sam')) {
            return (
                <div id="sam-engagement">
                    <div className="container">
                        <div className="section">
                            <SAMMenu/>
                        </div>

                        <div className="section">
                            <Filters/>
                        </div>

                        <div className="section">
                            <div className="flex-row flex-between flex-wrap flex-box-10-10">
                                <Overview simple={true}/>

                                <EngagementStageLegend/>
                            </div>
                        </div>

                        <div className="section">
                            <div className='row'>
                                <div className='col s6'>
                                    <CompletionBySchool/>
                                </div>
                                <div className='col s6'>
                                    <OverallEngagement/>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <CompletionByChallenge/>
                        </div>
                    </div>
                </div>
            );
        } else if (me && (!isEducator(me) || !me.permissions.includes('can_see_sam') || !userCan(me.subscription_status, 'see_sam'))) {
            return (
                <Forbidden/>
            );
        }

        return null;
    }

    useEffect(() => {
        dispatch(SetPAMSelectedChallengeIdAction(null));
    }, [selected_school_id]);

    return (
        <>
            {renderContent()}
        </>
    );
}
