import React from 'react';
import {useSelector} from "react-redux";
import {map, groupBy, find} from 'lodash';

export default function () {
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const challenges = useSelector(state => state.me_school_challenges);
    const skill_structure_data = useSelector(state => state.pam_challenge_skill_structure);

    const selected_challenge = find(challenges, {id: selected_challenge_id});

    function renderStructure() {
        return map(
            groupBy(skill_structure_data, 'practice_skill_category'),
            (skills, category_name) => {
                const render_skills = map(
                    groupBy(skills, (skill) => `${skill.practice_skill_code}: ${skill.practice_skill_name}`),
                    (questions, skill_name) => {
                        const render_questions = map(questions, (question) => {
                            return (
                                <div key={question.number}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {`Q${question.number}`}
                                    &nbsp;&nbsp;
                                    {`(${question.max_points} points)`}
                                </div>
                            )
                        })

                        return (
                            <div key={skill_name}>
                                <div>
                                    &nbsp;&nbsp;&nbsp;{`-`}&nbsp;
                                    {skill_name}
                                </div>
                                <div>
                                    {render_questions}
                                </div>
                            </div>
                        )
                    }
                );

                return (
                    <div key={category_name}>
                        <div className="section">
                            <div className="qsw-category">
                                <strong>
                                    {category_name}
                                </strong>
                            </div>
                            <div>
                                {render_skills}
                            </div>
                        </div>
                    </div>
                );
            }
        );
    }

    return (
        <div>
            <div className="section">
                <h4 className="header-new">
                    {selected_challenge.name}
                </h4>
                <div>
                    {`Questions and skills weightage`}
                </div>
            </div>

            <div>
                {renderStructure()}
            </div>
        </div>
    );
}
