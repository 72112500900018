import React from 'react';
import MindsetsJsSDK from "mindsets-js-sdk";

import BaseCard from './BaseCard'
import {processStringContent} from 'utils/content'


export default class WinnerCard extends BaseCard {
    constructor(props) {
        super(props)
        this.state = {
            winner: undefined
        }
    }

    componentDidMount() {
        this.fetchWinner()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.is_active && this.props.is_active) {
            this.fetchWinner()
        }
    }

    getCardType() {
        return 'winner'
    }

    useChallengeColor() {
        return true
    }

    getMinHeight() {
        return '400px'
    }

    fetchWinner() {
        if (this.props.lesson.id) {
            MindsetsJsSDK().Api.poll.winner(
                this.props.lesson.id, this.props.stream_entity.id
            ).then((response) => {
                this.setState({winner: response.winner})
            }).catch((error) => {
                console.error(error)
            })
        }
    }

    renderWinner() {
        if (this.state.winner) {
            return (
                <div className="section">
                    <div className="flex-column flex-v-center">
                        <div className="challenge-winner-image-container"
                             style={{backgroundImage: `url(${this.state.winner.option})`}}>
                            <img className="challenge-winner-image" src={this.state.winner.option}/>
                            <div className="challenge-winner-description flex-row flex-vh-center center-align">
                                {processStringContent(this.state.winner.description)}
                            </div>
                        </div>

                        <div className="center-align">
                            {processStringContent(this.state.winner.title)}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderDummy() {
        if (!this.state.winner) {
            return (
                <div className="section">
                    <div className="challenge-winner-dummy">
                        <div className="center-align">
                            <i className="fas fa-trophy"></i>
                        </div>
                        <div className="center-align">
                            {`The image of the winning option displays here.`}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderContentOne() {
        const {content1} = this.props.stream_entity.data
        if (content1) {
            return (
                <div className="section">
                    <div className="flex-row flex-vh-center center-align">
                        {processStringContent(content1)}
                    </div>
                </div>
            )
        }
    }

    renderContentTwo() {
        const {content2} = this.props.stream_entity.data
        if (content2) {
            return (
                <div className="section">
                    <div className="flex-row flex-vh-center center-align">
                        {processStringContent(content2)}
                    </div>
                </div>
            )
        }
    }

    renderOtherContent() {
        return (
            <div className="flex-column flex-vh-center">
                <h2 className="header-new center-align">
                    {processStringContent(this.props.stream_entity.data.title)}
                </h2>

                {this.renderWinner()}

                {this.renderDummy()}

                {this.renderContentOne()}

                {this.renderContentTwo()}
            </div>
        )
    }
}
