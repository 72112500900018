import React from 'react';
import {Link} from "react-router-dom";


export default function () {
    return (
        <div className="setup-class-bottom">
            <div className="flex-row flex-wrap flex-box-2-5">
                <div>
                    {`Have a question?`}
                </div>

                <div>
                    <Link to="/teacher/contact-us" target="_blank">
                        {`Contact Support.`}
                    </Link>
                </div>
            </div>
        </div>
    );
}
