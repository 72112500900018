import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {filter, sortBy, reverse, take} from 'lodash';
import {SetLibraryGradeAction, SetLibraryThemeAction, SetLibraryTopicAction} from "mindsets-js-sdk";

import ActiveChallenge from './ActiveChallenge'

function NoChallenges({challenge_versions}) {
    const dispatch = useDispatch();
    const measure = useSelector(state => state.library_measure);

    function reset() {
        dispatch(SetLibraryGradeAction(''));
        dispatch(SetLibraryTopicAction(''));
        dispatch(SetLibraryThemeAction(''));
    }

    function renderLastest() {
        const latest_challenge_versions = take(
            reverse(
                sortBy(
                    filter(
                        challenge_versions,
                        (cv) => !cv.measure || cv.measure === measure
                    ),
                    'created_at'
                )
            ),
            4
        )
        const render_latest = latest_challenge_versions.map((challenge_version) => {
            return (
                <div className="col" key={challenge_version.id}>
                    <ActiveChallenge
                        challenge_version={challenge_version}
                    />
                </div>
            )
        })

        return (
            <div className="row">
                {render_latest}
            </div>
        )
    }

    return (
        <div className='coming-soon-area'>
            <div className="center">
                <img
                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/403.svg`}
                    className='no_results_filter_image'
                    alt='No results'
                />
                <h3 className="header-new header center-align">
                    {`No results found`}
                </h3>
                <div className="center-align">
                    <span>{`Try `}</span>
                    <a href="#" onClick={event => {
                        event.preventDefault();
                        reset();
                    }}>
                        {`reset your filters`}
                    </a>
                    <span> {`or check out our latest Challenges below.`} </span>
                </div>
            </div>

            <div className="section">
                <div className="section">
                    <div className="divider"/>
                </div>
            </div>

            <div>
                {renderLastest()}
            </div>
        </div>
    );
}

NoChallenges.propTypes = {
    challenge_versions: PropTypes.array.isRequired
};

export default NoChallenges;
