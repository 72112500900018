import React, {Component} from 'react'
import PropTypes from 'prop-types'


class Spacer extends Component {
    render() {
        return (
            <div className="challenge-component-spacer">
                <div></div>
            </div>
        )
    }
}

Spacer.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Spacer
