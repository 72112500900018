import React from 'react';
import {useSelector} from "react-redux";

import {isEducator} from "utils/role";

export default function () {
    const me = useSelector(state => state.me);
    if (me && isEducator(me)) {
        return (
            <div className="flex-row user-info-field">
                <div className="user-info-field-label">Curriculum</div>
                <div>{me.curriculum ? me.curriculum.name : 'Other'}</div>
            </div>
        );
    }

    return null;
};
