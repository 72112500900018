import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    authUtils,
    accessConstants,
    SchoolDropdownItem,
    LoadDistrictAction,
    LoadChallengeCodesAction, LoadDistrictChallengeLocksAction
} from "mindsets-js-sdk";
import moment from "moment";

import useAuth from "../../hooks/useAuth";
import NoMatch from "../nomatch";
import ChallengeLocks from "./ChallengeLocks";

import './district_dashboard.scss';

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(state => state.me);
    const district = useSelector(state => state.district);
    const loading = useSelector(state => state.loading);

    function renderSubscription() {
        if (district && Array.isArray(district.subscriptions) && district.subscriptions.length > 0) {
            const subscription = district.subscriptions.find(subscription => moment().isBetween(moment(subscription.starts_at * 1000), moment(subscription.ends_at * 1000)));
            if (subscription) {
                return (
                    <div>Has valid subscription. Ends
                        on {moment(subscription.ends_at * 1000).format('DD MMM YYYY')}</div>
                );
            } else {
                return (
                    <div>Subscription expired</div>
                );
            }
        }

        return (
            <div>Never subscribed</div>
        );
    }

    function renderSchools() {
        if (Array.isArray(district.schools) && district.schools.length > 0) {
            return (
                <div className='school-list'>{district.schools.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }

                    return 0;
                }).map(school => {
                    return (
                        <div
                            key={school.id}
                            className='school-item'
                            onClick={() => {
                                if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_SCHOOL_DASHBOARD])) {
                                    navigate(`/school/${school.id}`)
                                }
                            }}
                        ><SchoolDropdownItem item={school}/></div>
                    );
                })}</div>
            );
        }

        return (
            <div>No schools assigned yet</div>
        );
    }

    useEffect(() => {
        if (me && me.school && me.school.district_id && (!district || !Object.keys(district).length) && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_DISTRICT_DASHBOARD])) {
            dispatch(LoadDistrictAction(me.school.district_id, {
                'selectors[0]': 'subscriptions',
                'selectors[1]': 'schools'
            }))
                .then(district => dispatch(LoadDistrictChallengeLocksAction(district.id, {
                    'selectors[0]': 'latest_challenge_version_name',
                    limit: 0
                })))
                .then(() => {
                    dispatch(LoadChallengeCodesAction({'selectors[0]': 'latest_challenge_version_name', limit: 0}));
                });
        }
    }, [me]);

    if (loading.active || !district) {
        return null;
    } else if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_DISTRICT_DASHBOARD]) && district) {
        return (
            <div id="district-dashboard">
                <div className='section'>
                    <div className='district-logo flex-row flex-vh-center'
                         style={{height: "100px", border: '1px dashed grey', borderRadius: "25px"}}>
                        <span>District LOGO</span>
                    </div>
                </div>
                <div className='section'>
                    <h2>{district.name}</h2>
                    {renderSubscription()}
                </div>
                <div className='section'>
                    {renderSchools()}
                </div>
                <div className='section'>
                    <ChallengeLocks/>
                </div>
                <div className='section'>&nbsp;</div>
            </div>
        );
    } else {
        return <NoMatch title='You do not have access to this page'/>;
    }
}
