import React from 'react';
import {Link} from "react-router-dom";

import useAuth from "../../hooks/useAuth";


export default function () {
    useAuth(['teacher']);

    function renderContent() {
        return (
            <div className="teacher-pd-entry-content">
                <div className="section">
                    <h3 className="header-new">
                        {`Professional Development`}
                    </h3>
                </div>

                <div className="section">
                    <h4 className="header-new">
                        {`Certified Educator Course - Level 1`}
                    </h4>
                    <br/>
                    <h4 className="header-new">
                        {`Teaching with Mindsets - Getting Started`}
                    </h4>
                </div>

                <div className="section">
                    <div className="flex-row">
                        <Link className="btn btn-new btn-on-dark btn-large" to="/teacher/pd/beginner">
                            <span>{`Start Course`}</span>
                            <i className="fas fa-chevron-right"/>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="teacher-pd">
            <div className="teacher-pd-entry">
                <div className="teacher-pd-bg">
                </div>

                <div className="container">
                    <div className="section">
                        <div className="teacher-pd-entry-container">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
