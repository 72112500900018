const analyticTeacherDashboard = (Analytic) => {
    Analytic.prototype.useChallengeFilter = function (click_on, topic, grade, theme, measure, subject) {
        this.track('Use Challenge Filter', {
            click_on, topic, grade, theme, measure, subject
        })
    }

    Analytic.prototype.downloadCurriculumGuide = function (standard_topic) {
        this.track('Download Curriculum Guide', {
            standard_topic
        })
    }

    Analytic.prototype.teacherComingSoon = function () {
        this.track('Teacher Coming Soon')
    }
}

export default analyticTeacherDashboard