import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, filter, isEmpty, includes} from 'lodash'

import {MATH_INPUT_BUTTON_SETS, MATH_INPUT_BUTTON_SETS_DEFAULT} from 'constants/math'
import MathInputButton from './MathInputButton'


class MathInputHelper extends Component {
    renderButtonSet(button_set) {
        const buttons = button_set.map((btn) => {
            return (
                <div key={btn.key}>
                    <MathInputButton
                        label={btn.label}
                        action={btn.action}
                        onClick={() => this.props.onInsert(btn.action)}
                    />
                </div>
            )
        })

        return (
            <div className="math-input-helper-button-set flex-row flex-between">
                {buttons}
            </div>
        )
    }

    renderSets() {
        let button_sets_config
        if (isEmpty(this.props.button_sets)) {
            button_sets_config = MATH_INPUT_BUTTON_SETS_DEFAULT
        } else {
            button_sets_config = this.props.button_sets
        }
        const button_sets = filter(MATH_INPUT_BUTTON_SETS, (bs, k) => {
            return includes(button_sets_config, k)
        })
        const sets = button_sets.map((button_set, name) => {
            return (
                <div key={name}>
                    {this.renderButtonSet(button_set)}
                </div>
            )
        })

        return (
            <div>
                {sets}
            </div>
        )
    }

    render() {
        return (
            <div className="math-input-helper-container">
                <div className="math-input-helper">
                    {this.renderSets()}
                </div>
            </div>
        )
    }
}

MathInputHelper.propTypes = {
    button_sets: PropTypes.array.isRequired,
    onInsert: PropTypes.func.isRequired
}

export default MathInputHelper
