import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import classNames from 'classnames'

import {processStringContent} from 'utils/content'


class Step extends Component {
    getContainerClassName() {
        const is_bonus = get(this.props.component_data, 'config.is_bonus')
        return classNames('component-step-container', {
            'component-step-container-bonus': is_bonus
        })
    }

    getContainerStyle() {
        const width = get(this.props.component_data, 'config.width')
        return {
            width
        }
    }

    renderTick() {
        const show_tick = get(this.props.component_data, 'config.show_tick')
        if (show_tick) {
            return (
                <div className="component-step-tick">
                    <i className="fas fa-check-circle"></i>
                </div>
            )
        }
        return null
    }

    renderNext() {
        const show_next = get(this.props.component_data, 'config.show_next')
        if (show_next) {
            return (
                <div className="component-step-next">
                    {`NEXT`}
                </div>
            )
        }
        return null
    }

    renderImage() {
        const url = get(this.props.component_data, 'data.image_url')
        if (url) {
            return (
                <div className="component-step-image flex-row">
                    <img src={url} />
                </div>
            )
        } else {
            return (
                <div className="component-step-image-placeholder">
                </div>
            )
        }
    }

    renderContent() {
        const title = get(this.props.component_data, 'data.title')
        const description = get(this.props.component_data, 'data.description')
        return (
            <div className="component-step-content flex-auto flex-column flex-h-center">
                <div className="component-step-content-title">
                    {title}
                </div>

                <div className="component-step-content-description">
                    {processStringContent(description)}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-step">
                <div className={this.getContainerClassName()} style={this.getContainerStyle()}>
                    <div className="flex-row">
                        {this.renderImage()}
                        {this.renderContent()}
                    </div>

                    {this.renderTick()}
                    {this.renderNext()}
                </div>
            </div>
        )
    }
}

Step.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Step
