import React from 'react';
import PropTypes from 'prop-types';

function Email({email, removeUser}) {
    return (
        <div className='email-list-item-wrapper'>
            <div className='email-list-item'>
                <span className='email-list-item-email'>{email}</span>
                <span className='email-list-item-email-close' onClick={removeUser}>
                        <i className="far fa-times-circle"/>
                    </span>
            </div>
        </div>
    );
}

Email.propTypes = {
    email: PropTypes.string.isRequired,
    removeUser: PropTypes.func.isRequired
};

export default Email;
