import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import {isEducator} from 'utils/role';

function ToBuyButton({button_class_name = 'btn-challenge'}) {
    const me = useSelector(state => state.me);

    if (isEducator(me) && !me.subscription_status.subscribed) {
        return (
            <div>
                <a className={"btn " + button_class_name} href={process.env.PUBLIC_PRICING_URL} target='_blank'>
                    <i className="fas fa-shopping-cart"/>
                    <span>{`Buy a full membership`}</span>
                </a>
            </div>
        );
    } else {
        return null;
    }
}

ToBuyButton.propTypes = {
    button_class_name: PropTypes.string
}

export default ToBuyButton;
