import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {MultipleSelector, SetUserAction} from "mindsets-js-sdk";

import {isEducator} from "utils/role";

const GradeField = function ({validation_error_messages, setValidationErrorMessages}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    let user_new = JSON.parse(JSON.stringify(user));
    let validation_error_messages_new = JSON.parse(JSON.stringify(validation_error_messages));

    if (isEducator(user)) {
        return (
            <div className="col s12 m6">
                <label htmlFor="grade" className="flex-row">
                    <div>Teaching grade(s) *</div>
                    {
                        validation_error_messages.hasOwnProperty('grade') ?
                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                    }
                </label>
                <MultipleSelector
                    trigger={(
                        <div className="inputfield w100 grade-selector flex-row flex-v-center">
                            {user.grades && user.grades.length ? 'Grade ' + user.grades.join(', ') : 'Select grades you teach'}
                        </div>
                    )}
                    item_name='grade'
                    items={[
                        {
                            id: '2',
                            label: 'Grade 2'
                        },
                        {
                            id: '3',
                            label: 'Grade 3'
                        },
                        {
                            id: '4',
                            label: 'Grade 4'
                        },
                        {
                            id: '5',
                            label: 'Grade 5'
                        },
                        {
                            id: '6',
                            label: 'Grade 6'
                        },
                        {
                            id: '7',
                            label: 'Grade 7'
                        },
                        {
                            id: '8',
                            label: 'Grade 8'
                        },
                        {
                            id: '9',
                            label: 'Grade 9'
                        },
                        {
                            id: 'High School',
                            label: 'High School'
                        }
                    ]}
                    selected={user_new.grades ? user_new.grades : []}
                    callback={selected => {
                        user_new.grades = selected.sort((a, b) => {
                            if (a < b) {
                                return -1;
                            } else if (a > b) {
                                return 1;
                            }

                            return 0;
                        });
                        dispatch(SetUserAction(user_new));
                        delete validation_error_messages_new.grade;
                        setValidationErrorMessages(validation_error_messages_new);
                    }}
                />
            </div>
        );
    }

    return null;
}

GradeField.propTypes = {
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired
}

export default GradeField;
