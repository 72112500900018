import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import MindsetsJsSDK from "mindsets-js-sdk";

import GeneralError from "../pages/GeneralError";

export default function (required, personas) {
    if (Array.isArray(required) && personas === undefined) {
        personas = required;
        required = true;
    }

    const me = useSelector(state => state.me);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if ((required && !MindsetsJsSDK().Auth.isAuthenticated()) && location.pathname !== '/login') {
            navigate('/login?redirect_url=' + encodeURIComponent(location.pathname))
        }
    }, []);

    useEffect(() => {
        if (me && Array.isArray(personas) && personas.indexOf(me.persona) < 0) {
            return <GeneralError
                title='You do not have access to this page'
                content={`You are signed in as a ${me.persona} but this page is available for ${personas.join('s or ')}s only.`}/>;
        }
    }, [me]);
}
