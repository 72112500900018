import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import MindsetsJsSDK, {
    LoadingAction, ErrorMessageAction,
    SetTeacherFacilitationMobileShowStreamsAction,
    SetTeacherFacilitationQuestionFullscreenAction,
    SetTeacherFacilitationSelectedCardIndexAction
} from "mindsets-js-sdk";

import CardNavigator from 'common/challenge/CardNavigator';
import analytic from "utils/Analytic";


export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const question_fullscreen = useSelector(state => state.teacher_facilitation_question_fullscreen);
    const mobile_show_streams = useSelector(state => state.teacher_facilitation_mobile_show_streams);

    function onSelectCard(selected_card_index_new) {
        if (selected_card_index_new >= 0 && selected_card_index_new < challenge_stream_entities.length) {
            if (selected_card_index_new !== selected_card_index) {
                if (lesson.mode === 'teacher' && lesson.user_id === me.id && !isEmpty(lesson) && !isEmpty(challenge_stream_entities)) {
                    Promise.resolve()
                        .then(() => dispatch(SetTeacherFacilitationSelectedCardIndexAction(selected_card_index_new)))
                        .then(() => dispatch(LoadingAction(true)))
                        .then(() => MindsetsJsSDK().Api.lesson.setStreamsIndex(lesson.id, selected_card_index_new))
                        .then(() => analytic.log('teacherNavigatedToCard', {
                            selected_card_index: selected_card_index_new,
                            lesson_id: lesson.id,
                            challenge_code: lesson.challenge_version.challenge_code.code,
                            challenge_name: lesson.challenge_version.name,
                        }))
                        .then(() => dispatch(LoadingAction(false)))
                        .catch(error => {
                            console.error(error);
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction('Could not change the page. Please reload the page and try again.'));
                        });
                } else if ((lesson.mode === 'student' || lesson.mode === 'teacher' && lesson.user_id !== me.id) && !isEmpty(lesson) && !isEmpty(challenge_stream_entities)) {
                    dispatch(SetTeacherFacilitationSelectedCardIndexAction(selected_card_index_new));
                }
            }
        }
    }

    function toggleQuestionFullscreen(e) {
        e.preventDefault();
        dispatch(SetTeacherFacilitationQuestionFullscreenAction(!question_fullscreen));
    }

    function toggleMobileShowStreams(e) {
        e.preventDefault();
        dispatch(SetTeacherFacilitationMobileShowStreamsAction(!mobile_show_streams));
    }

    function renderFullScreenToggle() {
        const extra_class_name = question_fullscreen ? ' byquestion-toolbar-toggle-on' : '';
        const fa_class = question_fullscreen ? ' fa-search-minus ' : ' fa-search-plus ';
        return (
            <div
                className={"byquestion-toolbar-toggle hide-on-med-and-down flex-row flex-vh-center " + extra_class_name}>
                <i className={"fas " + fa_class} onClick={toggleQuestionFullscreen}/>
            </div>
        );
    }

    function renderMobileModeToggle() {
        const extra_class_name = mobile_show_streams ? ' byquestion-toolbar-toggle-on' : '';
        return (
            <div className={"byquestion-toolbar-toggle hide-on-med-and-up flex-row flex-vh-center " + extra_class_name}>
                <i className="fas fa-comment-alt" onClick={toggleMobileShowStreams}/>
            </div>
        );
    }

    return (
        <div className="byquestion-toolbar flex-row">
            <div className="byquestion-toolbar-toggle"/>

            <div className="flex-auto flex-row flex-vh-center">
                <CardNavigator
                    challenge_version={lesson.challenge_version}
                    stream_entities={challenge_stream_entities}
                    selected_card_index={selected_card_index}
                    onSelect={onSelectCard}
                />
            </div>

            {renderFullScreenToggle()}

            {renderMobileModeToggle()}
        </div>
    );
}
