import React from 'react'

import BaseCard from './BaseCard'
import {processStringContent} from 'utils/content'


export default class WelcomeCard extends BaseCard {
    getCardType() {
        return 'welcome'
    }

    useChallengeColor() {
        return true
    }

    getMinHeight() {
        return '400px'
    }

    renderWelcome() {
        return (
            <div className="section">
                <h2 className="header-new">
                    {processStringContent(this.props.stream_entity.data.title)}
                </h2>
            </div>
        )
    }

    renderSubTitle() {
        if (this.props.stream_entity.data.subtitle) {
            return (
                <div className="section">
                    <div className="center-align">
                        {processStringContent(this.props.stream_entity.data.subtitle)}
                    </div>
                </div>
            )
        }
    }

    renderIframe() {
        if (this.props.stream_entity.data.iframe) {
            return (
                <div className="section">
                    <div className="challenge-welcome-iframe">
                        <iframe src={this.props.stream_entity.data.iframe}/>
                    </div>
                </div>
            )
        }
    }

    renderContentOne() {
        if (this.props.stream_entity.data.content1) {
            return (
                <div className="section">
                    <div className="center-align">
                        {processStringContent(this.props.stream_entity.data.content1)}
                    </div>
                </div>
            )
        }
    }

    renderContentTwo() {
        if (this.props.stream_entity.data.content2) {
            return (
                <div className="section">
                    <div className="center-align">
                        {processStringContent(this.props.stream_entity.data.content2)}
                    </div>
                </div>
            )
        }
    }

    renderOtherContent() {
        return (
            <div className="flex-column flex-vh-center">
                {this.renderWelcome()}

                {this.renderSubTitle()}

                {this.renderContentTwo()}

                {this.renderIframe()}

                {this.renderContentOne()}
            </div>
        )
    }
}
