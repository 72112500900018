import React, { useState, useEffect } from 'react';
import { get } from 'lodash';


export default (props) => {

    const imgSrc = get(props.img, 'src', null);

    const getContainerStyle = () => {
        return {
            backgroundImage: imgSrc ? `url(${imgSrc})` : null,
            backgroundPositionX: props.positionX,
            backgroundPositionY: props.positionY,
            ...props.containerStyle
        }
    }

    return (
        <div className="wow-moment-photo" style={getContainerStyle()}>
        </div>
    )
}
