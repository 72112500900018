import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import classNames from 'classnames';

import CardTitle from './CardTitle';
import {getCardName} from 'utils/challenge/streamEntity';
import {userCan} from "utils/membership";


function CardMenu(
    {
        challenge_version,
        stream_entities,
        selected_index,
        is_preview = false,
        onClick = (i) => (e) => undefined
    }
) {
    const me = useSelector(state => state.me);

    function renderCardFull(se, card_index) {
        const card_number = getCardName(challenge_version, card_index, '');
        const {complete_index, final_index} = challenge_version;
        if (card_index === final_index || card_index === complete_index + 1) {
            return (
                <div>
                    <span>
                        {card_number}
                    </span>
                </div>
            );
        } else {
            return (
                <div>
                    <span>
                        {`${card_number}. `}
                    </span>
                    <CardTitle
                        stream_entity={se}
                    />
                </div>
            );
        }
    }

    function renderRow(se, i) {
        const class_name = classNames('card-menu-row', {
            'card-menu-row-active': i === selected_index
        });

        return (
            <div
                className={class_name}
                key={se.id}
                onClick={onClick(i)}
            >
                {renderCardFull(se, i)}
            </div>
        );
    }

    function renderRows() {
        if (is_preview) {
            const can_preview_full = !!me && (userCan(me.subscription_status, 'preview_full_challenge', {challenge_version}) && challenge_version.is_locked !== true || me.persona === 'student');
            if (!can_preview_full) {
                stream_entities = stream_entities.filter((se, i) => i <= 3);
            }
        }
        return stream_entities.map((se, i) => {
            return renderRow(se, i)
        });
    }

    return (
        <div className="card-menu">
            {renderRows()}
        </div>
    );
}

CardMenu.propTypes = {
    challenge_version: PropTypes.shape({
        complete_index: PropTypes.number.isRequired,
        final_index: PropTypes.number.isRequired,
        is_locked: PropTypes.bool
    }).isRequired,
    stream_entities: PropTypes.array.isRequired,
    selected_index: PropTypes.number,
    is_preview: PropTypes.bool,
    onClick: PropTypes.func
}

export default CardMenu
