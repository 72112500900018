import React from 'react';
import PropTypes from 'prop-types';
import {upperFirst} from 'lodash';

function LoginFormContainer({persona, mode, action_text, content_top, content_bottom, clearPersona, switchMode}) {
    function renderTitle() {
        const title_text = upperFirst(persona)
            + ' '
            + action_text.toLowerCase()
            + ':'

        return (
            <h4 className="header-new center-align">
                <div className="login-form-container-title">
                    {title_text}
                </div>
            </h4>
        )
    }

    function renderPersonaSwitch() {
        return (
            <div className="login-form-container-role-switch">
                <a className="link-new" href="#" onClick={event => {
                    event.preventDefault();
                    clearPersona();
                }}>
                    {`Not a ${persona}?`}
                </a>
            </div>
        )
    }

    function renderModeSwitch() {
        return (
            <div className="login-form-mode-switch">
                <span>
                    {mode === 'signup' ? `Already have an account?` : `Don't have an account?`}{` `}
                </span>
                <a className="" href="#" onClick={event => {
                    event.preventDefault();
                    switchMode();
                }}>
                    {mode === 'signup' ? `SIGN IN HERE` : `SIGN UP`}
                </a>
            </div>
        )
    }

    const extra_class = ` login-form-container-${persona} login-form-container-${mode}`;

    return (
        <div className={"login-form-container z-depth-1 flex-column flex-vh-center" + extra_class}>
            <div className="login-form-container-icon">
                <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_${persona}.svg`}
                     alt={`${persona} icon`}/>
            </div>

            {renderPersonaSwitch()}

            <div className="login-form-container-top">
                <div className="section">
                    {renderTitle()}
                </div>

                {content_top}
            </div>

            <div className="login-form-container-triangle-outter">
                <div className="login-form-container-triangle">
                </div>
            </div>

            <div className="login-form-container-bottom">
                {content_bottom}
            </div>

            <div className="section">
                {renderModeSwitch()}
            </div>
        </div>
    );
}

LoginFormContainer.propTypes = {
    persona: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    action_text: PropTypes.string.isRequired,
    content_top: PropTypes.node,
    content_bottom: PropTypes.node,
    clearPersona: PropTypes.func.isRequired,
    switchMode: PropTypes.func.isRequired
}

export default LoginFormContainer
