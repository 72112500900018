export const getEngagementName = (stream_completion) => {
    switch (stream_completion) {
        case 'stuck':
            return 'started'
        case 'underway':
            return 'underway'
        case 'nearly':
            return 'nearly completed'
        case 'completed':
            return 'completed'
        case 'completed_bonus':
            return 'bonus completed'
        default:
            return 'unkown'
    }
}