import React from 'react';
import {Outlet} from "react-router-dom";
import {Loader, CustomToast} from "mindsets-js-sdk";

export default function () {
    return (
        <>
            <CustomToast/>
            <main>
                <Outlet/>
            </main>
            <Loader/>
        </>
    );
}
