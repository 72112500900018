import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {LoadChallengeVersionAction, LoadingAction, LoadRubricPDFAction} from "mindsets-js-sdk";

import PDFViewer from '../common/PDFViewer';
import GeneralError from "../pages/GeneralError";
import useAuth from "../hooks/useAuth";

export default function () {
    useAuth(true);

    const params = useParams();
    const dispatch = useDispatch();
    const challenge_version = useSelector(state => state.challenge_version);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Rubric not found.');
    const [data, setData] = useState(null);

    function renderError() {
        if (failed === true) {
            return <GeneralError content={message}/>;
        }

        return null;
    }

    function renderContent() {
        if (data) {
            return <PDFViewer url={data} fileName={challenge_version.name + ' rubric.pdf'}/>;
        }

        return null;
    }

    useEffect(() => {
        dispatch(LoadChallengeVersionAction(params.challenge_version_id))
            .then(() => dispatch(LoadRubricPDFAction(params.challenge_version_id, params.practice_identifier, {buffer: true})))
            .then(buffer => setData(buffer))
            .catch(error => {
                setMessage(error);
                setFailed(true);
            })
            .then(() => dispatch(LoadingAction(false)));
    }, []);

    if (!challenge_version) {
        return null;
    }

    return (
        <>
            {renderError()}
            {renderContent()}
        </>
    );
}
