import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'


class ReadOnly extends Component {
    getContent() {
        if (this.props.existing_answer) {
            return get(this.props.existing_answer, 'data.answer')
        } else {
            return (
                <span className="textarea-readonly-placeholder">
                    {this.props.placeholder}
                </span>
            )
        }
    }

    render() {
        if (!this.props.is_readonly) {
            return null
        }

        return (
            <div className="component-textarea-readonly">
                {this.getContent()}
            </div>
        )
    }
}

ReadOnly.propTypes = {
    is_readonly: PropTypes.bool.isRequired,
    existing_answer: PropTypes.object,
    placeholder: PropTypes.string.isRequired
}

export default ReadOnly
