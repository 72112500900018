import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {get} from 'lodash';
import MindsetsJsSDK from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import WowMomentTemplate from './WowMomentTemplate';
import WowMomentCollection from './WowMomentCollection';


export default function () {

    const {wowMomentCode} = useParams();
    const [wowMoment, setWowMoment] = useState(null);
    const [lesson, setLesson] = useState(null);

    const lessonId = get(wowMoment, 'lesson_id');

    useEffect(() => {
        if (wowMomentCode) {
            analytic.log(
                'wowMomentsPublicView',
                wowMomentCode
            );

            return MindsetsJsSDK().Api.wowMoment.get(
                0, {code: wowMomentCode}
            ).then((response) => {
                const wowMoment = response.wowMoment;
                setWowMoment(wowMoment);
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [wowMomentCode])

    useEffect(() => {
        if (lessonId) {
            return MindsetsJsSDK().Api.wowMoment.lesson(
                lessonId
            ).then((response) => {
                setLesson(response.lesson);
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [lessonId])

    return (
        <div id='wow-moment'>
            <div className="wow-moment-container">
                <WowMomentTemplate
                    wowMoment={wowMoment}
                    lesson={lesson}
                />

                <WowMomentCollection
                    wowMoment={wowMoment}
                    lesson={lesson}
                />
            </div>
        </div>
    );
}