import React from 'react';
import PropTypes from 'prop-types';

import {seOldToNew} from 'utils/challenge/dataModel';
import {getCardClass} from './utils';


function Card(
    {
        challenge_version,
        stream_entity,
        lesson = {},
        stream_id,
        is_preview = false,
        is_latest = false,
        is_active,
        refComponent
    }
) {
    function renderContent() {
        const se_new = seOldToNew(challenge_version, stream_entity);
        const CardClass = getCardClass(se_new);

        return (
            <CardClass
                challenge_version={challenge_version}
                stream_entity={se_new}
                lesson={lesson}
                stream_id={stream_id}
                is_preview={is_preview}
                is_latest={is_latest}
                is_active={is_active}
                refComponent={refComponent}
            />
        );
    }

    return (
        <div className="challenge-card" key={stream_entity.id}>
            {renderContent()}
        </div>
    );
}

Card.propTypes = {
    challenge_version: PropTypes.object.isRequired,
    stream_entity: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
    lesson: PropTypes.object,
    stream_id: PropTypes.number,
    is_preview: PropTypes.bool,
    is_latest: PropTypes.bool,
    is_active: PropTypes.bool,
    refComponent: PropTypes.func
}

export default Card
