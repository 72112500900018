import React from 'react';
import PropTypes from 'prop-types';
import {get, map, capitalize, toInteger, find} from 'lodash';

import {MEASURES} from 'constants/index';
import CurriculumSelector from 'common/CurriculumSelector';


function ChallengesFilter(
    {
        curriculums,
        topics,
        grades,
        themes,
        selected_curriculum,
        selected_topic,
        selected_grade,
        selected_theme,
        selected_measure,
        setStateValue
    }
) {
    function renderSubject() {
        const onChange = (event) => {
            setStateValue('selected_subject', event.target.value);
        };
        return (
            <div className="">
                <select
                    value={selected_subject}
                    onChange={onChange}
                    className=""
                >
                    <option key='math' value='math'>
                        {`Math Challenges`}
                    </option>
                    <option key='science' value='science'>
                        {`Science Challenges`}
                    </option>
                </select>
            </div>
        );
    }

    function renderCurriculum() {
        const onChange = (c_id) => {
            setStateValue('selected_curriculum', toInteger(c_id));
        }
        return (
            <CurriculumSelector
                onCurriculumIdChange={onChange}
                validation_error_messages={{}}
                curriculums={curriculums}
                curriculum_id={selected_curriculum}
            />
        )
    }

    function renderMeasure() {
        const onChange = (event) => {
            setStateValue('selected_measure', event.target.value);
        }
        const options = map(Object.keys(MEASURES), (key) => {
            return (
                <option key={key} value={key}>{MEASURES[key]}</option>
            )
        })
        return (
            <div className="">
                <select
                    value={selected_measure}
                    onChange={onChange}
                    className=""
                >
                    {options}
                </select>
            </div>
        );
    }

    function renderTopic() {
        const onChange = (event) => {
            setStateValue('selected_topic', event.target.value);
        }
        const options = map(topics, (topic, key) => {
            return (
                <option key={key} value={topic}>{topic}</option>
            )
        })
        return (
            <div className="">
                <select
                    value={selected_topic}
                    onChange={onChange}
                    className=""
                    disabled={topics.length <= 0}
                >
                    <option key='empty' value=''>
                        {`Topic (All)`}
                    </option>
                    {options}
                </select>
            </div>
        );
    }

    function renderGrade() {
        const onChange = (event) => {
            setStateValue('selected_grade', event.target.value);
        }
        const options = map(grades, (grade, key) => {
            return (
                <option key={key} value={grade}>{grade}</option>
            )
        })
        const grade_label = capitalize(
            get(
                find(curriculums, {id: selected_curriculum}),
                'grade_term',
                'grade'
            )
        )
        return (
            <div className="">
                <select
                    value={selected_grade}
                    onChange={onChange}
                    className=""
                    disabled={grades.length <= 0}
                >
                    <option key='empty' value=''>
                        {`${grade_label} (All)`}
                    </option>
                    {options}
                </select>
            </div>
        );
    }

    function renderTheme() {
        const onChange = (event) => {
            setStateValue('selected_theme', event.target.value);
        }
        const options = map(themes, (theme, key) => {
            return (
                <option key={key} value={theme}>{theme}</option>
            )
        })
        return (
            <div className="">
                <select
                    value={selected_theme}
                    onChange={onChange}
                    className=""
                    disabled={themes.length <= 0}
                >
                    <option key='empty' value=''>
                        {`Theme (All)`}
                    </option>
                    {options}
                </select>
            </div>
        );
    }

    return (
        <div className="teams-c-l-challenges-filter">
            <div className="flex-row flex-v-center flex-box-5-5 flex-wrap">
                {renderSubject()}
                {renderCurriculum()}
                {renderGrade()}
                {renderTheme()}
            </div>
        </div>
    );
}

ChallengesFilter.propTypes = {
    curriculums: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    grades: PropTypes.array.isRequired,
    themes: PropTypes.array.isRequired,
    selected_curriculum: PropTypes.number,
    selected_topic: PropTypes.string.isRequired,
    selected_grade: PropTypes.string.isRequired,
    selected_theme: PropTypes.string.isRequired,
    selected_measure: PropTypes.string.isRequired,
    setStateValue: PropTypes.func.isRequired,
};

export default ChallengesFilter;
