import {forEach} from 'lodash'


export const convertViaDOM = (str, func) => {
    const div = document.createElement('div')
    div.innerHTML = str
    forEachChildNodeDeep(div, func)
    // .innerHTMl will encode & to &amp; etc.
    // So need to use textarea trick
    // return div.innerHTML

    const textarea = document.createElement('textarea')
    textarea.innerHTML = div.innerHTML
    return textarea.value
}

const forEachChildNodeDeep = (elem, func) => {
    if (elem.nodeType == 3) {
        func(elem)
        return
    } else if (elem.className.startsWith('katex')) {
        // Skip Katex for further processing
        return
    }

    forEach(elem.childNodes, (child) => {
        forEachChildNodeDeep(child, func)
    })
}