import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ErrorMessageAction} from "mindsets-js-sdk";

import {LoadSamOverviewAction} from "../../../actions/sam_actions";

export default function ({simple = false}) {
    const dispatch = useDispatch();
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const [no_of_schools, setNumberOfSchools] = useState('-');
    const [no_of_teachers, setNumberOfTeachers] = useState('-');
    const [no_of_classes, setNumberOfClasses] = useState('-');
    const [no_of_student_streams, setNumberOfStudentStreams] = useState('-');
    const [no_of_coteaching_streams, setNumberOfCoTeachingStreams] = useState('-');
    const [no_of_challenges, setNumberOfChallenges] = useState('-');

    function loadOverviewData() {
        dispatch(LoadSamOverviewAction({
            selected_school_id: selected_school_id ? selected_school_id : '',
            selected_from: from,
            selected_till: till
        })).then((response) => {
            setNumberOfSchools(response.no_of_schools);
            setNumberOfTeachers(response.no_of_teachers);
            setNumberOfClasses(response.no_of_classes);
            setNumberOfStudentStreams(response.no_of_student_streams);
            setNumberOfCoTeachingStreams(response.no_of_coteaching_streams);
            setNumberOfChallenges(response.no_of_challenges);
        }).catch((error) => {
            console.error('error', error);
            dispatch(ErrorMessageAction(error));
        })
    }

    function renderOne(label, value) {
        return (
            <div className="sam-overview-data">
                <div>
                    {label}
                </div>
                <div>
                    {value}
                </div>
            </div>
        );
    }

    function renderData() {
        if (simple) {
            return (
                <div className="flex-row flex-box-10-10 flex-between flex-wrap">
                    {renderOne('Students', no_of_student_streams)}
                    {renderOne('Teachers', no_of_teachers)}
                    {renderOne('Classes', no_of_classes)}
                </div>
            );
        } else {
            return (
                <div className="flex-row flex-box-10-10 flex-between flex-wrap">
                    {renderOne('Schools', no_of_schools)}
                    {renderOne('Teachers', no_of_teachers)}
                    {renderOne('Challenges', no_of_challenges)}
                    {renderOne('Students', no_of_student_streams)}
                    {renderOne('Co-Teaching', no_of_coteaching_streams)}
                    {renderOne('Classes', no_of_classes)}
                </div>
            );
        }
    }

    useEffect(() => {
        loadOverviewData()
    }, []);

    useEffect(() => {
        loadOverviewData()
    }, [selected_school_id, from, till]);

    return (
        <div className="sam-overview">
            {renderData()}
        </div>
    );
}
