import {replaceSpecialChars} from 'utils/content'

export default class Spreadsheet {
    render(cells, response) {
        response.data.answer.map(row => {
            Object.keys(row).map(cell_key => {
                cells.push(replaceSpecialChars(row[cell_key]));
            });
        });
    }
}
