import React from 'react';

import ShareToClever from "./ShareTo/ShareToClever";


export default function () {
    return (
        <div className="class-share-content-teams">
            <div className="flex-row-m flex-wrap flex-box-15-15">
                <div className="flex-1">
                    <h5 className="header-new">
                        {`Assign students from Clever`}
                    </h5>

                    <div className="content-classroom-description">
                        {`This will sync the roster you have in Clever with Mindsets.`}
                    </div>

                    <div className="flex-row">
                        <ShareToClever/>
                    </div>
                </div>
            </div>
        </div>
    );
}
