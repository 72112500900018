import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {throttle} from 'lodash'
import MindsetsJsSDK from "mindsets-js-sdk";

import PollBlockOptions from './PollBlockOptions'
import PollInlineOptions from './PollInlineOptions'
import analytic from 'utils/Analytic'


class PollOptions extends Component {
    constructor(props) {
        super(props)

        this.submitVoteThrottle = throttle(this.submitVote.bind(this), 1500, {
            leading: true,
            trailing: false
        })
    }

    submitVote(poll_option) {
        if (!this.props.is_preview && this.props.lesson_id && this.props.stream_id) {
            // Do not submit vote again if it is a same vote
            if (poll_option.id === this.props.my_vote) {
                return
            }

            return MindsetsJsSDK().Api.poll.vote(
                this.props.lesson_id,
                this.props.stream_id,
                this.props.stream_entity_id,
                this.props.poll.id,
                {poll_option_id: poll_option.id}
            ).then(() => {
                this.props.setMyVote(poll_option.id)

                analytic.log('studentSubmittedVote', {
                    lesson_id: this.props.lesson_id,
                    stream_id: this.props.stream_id,
                    stream_entity_id: this.props.stream_entity_id,
                    poll_id: this.props.poll.id
                })
            }).catch((error) => {
                console.error(error)
            })
        }
    }

    getPollOptionsClass() {
        switch(this.props.component_data.type) {
            case 'poll.block':
                return PollBlockOptions
            case 'poll.inline':
            default:
                return PollInlineOptions
        }
    }

    render() {
        const PollOptionsClass = this.getPollOptionsClass()
        return (
            <div className="poll-options">
                <PollOptionsClass
                    poll={this.props.poll}
                    lesson_id={this.props.lesson_id}
                    is_preview={this.props.is_preview}
                    my_vote={this.props.my_vote}
                    submitVote={this.submitVoteThrottle}
                    lesson_votes={this.props.lesson_votes}
                    show_graph={!!this.props.my_vote || (this.props.is_preview && !!this.props.lesson_id)}
                />
            </div>
        )
    }
}

PollOptions.propTypes = {
    component_data: PropTypes.object.isRequired,
    poll: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    stream_entity_id: PropTypes.number.isRequired,
    is_preview: PropTypes.bool,
    my_vote: PropTypes.number,
    setMyVote: PropTypes.func.isRequired,
    lesson_votes: PropTypes.array.isRequired
}

export default PollOptions
