import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Modal from 'react-responsive-modal';
import {
    filterConstants,
    LoadUsersAction,
    LoadSchoolsAction
} from "mindsets-js-sdk";

import {userCan} from 'utils/membership';
import FeatureLock from 'common/membership/FeatureLock';
import AssignForm from "./AssignForm";
import CoachAssignForm from "./CoachAssignForm";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const [open, setOpen] = useState(false);

    function fetchTeachers() {
        dispatch(LoadUsersAction({
            limit: 0,
            'selectors[0]': 'school'
        }));
    }

    function fetchSchools() {
        dispatch(LoadSchoolsAction({
            limit: 1000,
            'filters[schools][0]': filterConstants.EXPRESSION_EQUAL + '=' + me.id,
            'selectors[0]': 'teachers'
        }));
    }

    function renderAssignForm() {
        if (me.permissions.includes('can_have_many_schools')) {
            return (
                <CoachAssignForm close={() => setOpen(false)}/>
            );
        } else {
            return (
                <AssignForm close={() => setOpen(false)}/>
            );
        }
    }

    function renderModal() {
        return (
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                showCloseIcon={true}
                classNames={{
                    modal: 'assign-teachers-modal',
                    closeButton: 'close-button'
                }}
                center
            >
                <h4 className="header-new">Select teachers from the list to add</h4>
                {renderAssignForm()}
            </Modal>
        )
    }

    function canAssign() {
        return userCan(me.subscription_status, 'assign_teachers')
    }

    useEffect(() => {
        if (me.permissions.includes('can_have_many_schools')) {
            fetchSchools();
        } else {
            fetchTeachers();
        }
    }, []);

    if (lesson.user_id !== me.id) {
        return null;
    }

    return (
        <div>
            <FeatureLock
                enable={canAssign()}
                title='Co-teaching available only on School or District Plans.'
            >
                <button className="btn btn-on-white" onClick={event => {
                    event.preventDefault();

                    if (!canAssign()) {
                        window.open(process.env.PUBLIC_PRICING_URL, '_blank');
                        return
                    }

                    setOpen(true);
                }}>
                    <i className="fas fa-plus"/>
                    <span>Add Teachers</span>
                </button>
                {renderModal()}
            </FeatureLock>
        </div>
    );
}
