import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';


function Student({stream}) {
    const dispatch = useDispatch();
    const show_student = useSelector(state => state.teacher_facilitation_show_student);

    function renderName() {
        if (!show_student) {
            return null;
        }

        return (
            <div className="byquestion-stream-name">
                {stream.user.full_name}
            </div>
        );
    }

    function renderFlag() {
        if (stream.red_flag) {
            return (
                <div className="byquestion-stream-flag flex-row flex-vh-center">
                    <i className="fas fa-flag"/>
                </div>
            );
        }
    }

    function renderHand() {
        const onClick = () => {
            dispatch(LoadingAction(true, 'Lowering hand'));

            MindsetsJsSDK().Api.stream.update(
                stream.id, {raise_hand_at: false}
            ).then(() => {
                dispatch(LoadingAction(true, 'Hand lowered'));

                analytic.log(
                    'facilitationLowerHand',
                    stream.lesson.challenge_version.name,
                    stream.lesson.code,
                    stream.id
                )
            }).catch((error) => {
                console.error(error)
            }).then(() => {
                setTimeout(() => {
                    dispatch(LoadingAction(false));
                }, 1000)
            });
        }

        if (stream.raise_hand_at) {
            return (
                <div className="byquestion-stream-hand">
                    <i
                        className="fas fa-hand-sparkles"
                        onClick={onClick}
                        title="lower hand"
                    />
                </div>
            );
        }
    }

    return (
        <div className="flex-row flex-v-center flex-wrap">
            {renderName()}

            {renderFlag()}

            {renderHand()}
        </div>
    );
}

Student.propTypes = {
    stream: PropTypes.object.isRequired
}

export default Student;
