import React, { useState } from 'react';
import { get } from 'lodash';

import WowMomentChallengeCard from './WowMomentChallengeCard';
import WowMomentPhoto from './WowMomentPhoto';
import WowMomentWriting from './WowMomentWriting';


export default (props) => {

    const wowMoment = props.wowMoment;
    const challengeVersion = get(props.lesson, 'challenge_version');
    const challengeName = get(props.lesson, 'challenge_version.name', '');

    const renderTitle = () => {
        return (
            <div className="wm-template-title flex-auto">
                <h1 className="header-new">
                    {`WOW Moments! with Mindsets`}
                </h1>
                <h5 className="header-new">
                    {`${challengeName} Challenge`}
                </h5>
                <h5 className="header-new">
                    {``}
                </h5>
            </div>
        )
    }

    const renderSchoolLogo = () => {
        return (
            <div className="wm-template-photo wm-template-photo-logo">
                <WowMomentPhoto
                    title="District/School Logo"
                    attrName="img_logo"
                    img={get(wowMoment, 'content.img_logo')}
                    positionX="right"
                    positionY="top"
                />
            </div>
        )
    }

    const renderKids = () => {
        const kidsImgSrc = process.env.PUBLIC_S3_ASSETS_URL + `/application/icon_login_student.svg`;
        return (
            <div className="flex-row flex-h-center">
                <div className="wm-template-kids">
                    <img src={kidsImgSrc} />
                </div>
            </div>
        )
    }

    const renderStateFlag = () => {
        const countryCode = get(props.lesson, 'user.school.country');
        const stateCode = get(props.lesson, 'user.school.state');
        let flagImgSrc = '';
        if (countryCode && stateCode) {
            flagImgSrc = process.env.PUBLIC_S3_ASSETS_URL + `/application/flags/${countryCode}/${stateCode}.png`;
        } else if (countryCode) {
            flagImgSrc = process.env.PUBLIC_S3_ASSETS_URL + `/application/flags/countries/${countryCode}.png`;
        } else {
            return null;
        }
        return (
            <div className="flex-row flex-h-center">
                <div className="wm-template-flag">
                    <img src={flagImgSrc} />
                </div>
            </div>
        )
    }

    const renderPhoto2 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-2">
                <WowMomentPhoto
                    title="Class Photo"
                    attrName="img_2"
                    img={get(wowMoment, 'content.img_2')}
                    positionX="center"
                    positionY="bottom"
                />
            </div>
        )
    }

    const renderPhoto3 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-3">
                <WowMomentPhoto
                    title="Class Photo"
                    attrName="img_3"
                    img={get(wowMoment, 'content.img_3')}
                    positionX="center"
                    positionY="top"
                />
            </div>
        )
    }

    const renderPhoto4 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-4">
                <WowMomentPhoto
                    title="Class Photo"
                    attrName="img_4"
                    img={get(wowMoment, 'content.img_4')}
                    positionX="center"
                    positionY="bottom"
                />
            </div>
        )
    }

    const renderPhoto5 = () => {
        return (
            <div className="wm-template-photo wm-template-photo-5">
                <WowMomentPhoto
                    title="Class Photo"
                    attrName="img_5"
                    img={get(wowMoment, 'content.img_5')}
                    positionX="right"
                    positionY="top"
                />
            </div>
        )
    }
    
    const renderStory = () => {
        return (
            <div className="wm-template-writing wm-template-writing-1">
                <WowMomentWriting
                    attrName="writing_1"
                    writing={get(wowMoment, 'content.writing_1')}
                />
            </div>
        )
    }

    return (
        <div className="wow-moment-template">
            <div className="flex-row">
                <div className="wm-template-mr-20 flex-column">
                    <div>
                        <WowMomentChallengeCard
                            challengeVersion={challengeVersion}
                        />
                    </div>
                    {renderPhoto2()}
                </div>
                <div className="flex-auto flex-column">
                    <div className="wm-template-mb-20 flex-row">
                        {renderTitle()}
                        {renderSchoolLogo()}
                    </div>
                    <div className="flex-auto flex-row">
                        <div className="wm-template-mr-20 flex-column flex-between">
                            {renderPhoto3()}
                            {renderStateFlag()}
                        </div>
                        <div className="wm-template-mr-20 flex-column flex-between">
                            {renderStory()}
                            {renderPhoto4()}
                        </div>
                        <div className="flex-column flex-between">
                            {renderPhoto5()}
                            {renderKids()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
