import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {get, isEmpty} from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import analytic from 'utils/Analytic';


function AnswerKeyButton({challenge_version, lesson, is_preview, is_bonus}) {
    const buttonRef = useRef(null);

    function getIsChallengePreview() {
        return isEmpty(lesson) && is_preview
    }

    function getButtonClassName() {
        return classNames('btn btn-large', {
            'btn-white': get(lesson, 'is_answer_keys_shown'),
            'btn-maybe': isEmpty(lesson) && is_preview
        });
    }

    function getTooltipProps() {
        if (getIsChallengePreview()) {
            return {
                'data-for': 'answer-key-button-tooltip',
                'data-tip': 'You can choose to have the Answer Key on or off'
            }
        } else {
            return {}
        }
    }

    function getAnswerKeyUrl() {
        if (is_bonus) {
            return get(challenge_version, 'bonus_answer_key_url')
        } else {
            return get(challenge_version, 'answer_key_url')
        }
    }

    function getButtonLabel() {
        if (is_bonus) {
            return `Download Answer Key - Bonus`
        } else {
            return `Download Answer Key`
        }
    }

    function onClick() {
        const event_name = is_bonus ? 'downloadBonusAnswerKey' : 'downloadAnswerKey'
        analytic.log(event_name, {
            id: challenge_version.id,
            name: challenge_version.name,
            code: challenge_version.challenge_code.code
        }, get(lesson, 'id'))
    }

    function renderTooltip() {
        if (getIsChallengePreview()) {
            return (
                <ReactTooltip
                    id="answer-key-button-tooltip"
                    effect="solid"
                    multiline={true}
                    place='top'
                    delayHide={99999}
                />
            );
        }

        return null
    }

    useEffect(() => {
        if (buttonRef.current) {
            ReactTooltip.show(buttonRef.current);
        }
    }, []);

    if (!challenge_version.answer_key_url) {
        return null;
    }

    const is_answer_keys_shown = get(lesson, 'is_answer_keys_shown');
    if (!isEmpty(lesson) && !is_answer_keys_shown) {
        return null;
    }

    return (
        <div className="answer-key-button">
            <a
                className={getButtonClassName()}
                href={getAnswerKeyUrl()}
                target="_blank"
                onClick={onClick}
                {...getTooltipProps()}
                ref={buttonRef}
            >
                <i className="fas fa-download"></i>
                <span className='no-wrap'>
                        {getButtonLabel()}
                    </span>
            </a>

            {renderTooltip()}
        </div>
    );
}

AnswerKeyButton.propTypes = {
    challenge_version: PropTypes.shape({
        answer_key_url: PropTypes.string.isRequired,
        bonus_answer_key_url: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired,
    }).isRequired,
    lesson: PropTypes.object,
    is_preview: PropTypes.bool,
    is_bonus: PropTypes.bool.isRequired
}

export default AnswerKeyButton
