import React from 'react';
import {indexOf, filter} from 'lodash';

import ChoiceSingle from './ChoiceSingle';


export default class ChoiceMultiple extends ChoiceSingle {
    defaultAnswer() {
        return []
    }

    isSelected(a) {
        return indexOf(this.state.answer, a) >= 0
    }

    isOptionCorrect(o) {
        return indexOf(this.correctAnswer(), o) >= 0
    }

    onChange(selected) {
        return e => {
            if (e) {
                e.preventDefault()
            }

            if (this.props.is_preview) {
                return
            }

            let new_answer
            if (this.isSelected(selected)) {
                new_answer = filter(this.state.answer, (a) => a !== selected)
            } else {
                new_answer = [...this.state.answer, selected]
                new_answer.sort()
            }
            this.setState({
                answer: new_answer,
                auto_grading: false
            })
        }
    }
}
