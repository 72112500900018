import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction} from "mindsets-js-sdk";

import School from './School';

export default function () {
    const dispatch = useDispatch();
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const [schools, setSchools] = useState([]);

    function loadTeacherViewData() {
        MindsetsJsSDK().API.get('sam/teacher_view', {
            selected_school_id: selected_school_id ? selected_school_id : '',
            selected_from: from,
            selected_till: till,
            selected_challenge_id: selected_challenge_id ? selected_challenge_id : ''
        }).then((response) => {
            setSchools(response.schools);
        }).catch((error) => {
            console.error('error', error);
            dispatch(ErrorMessageAction(error));
        })
    }

    function renderSchools() {
        return schools.map((school) => {
            return (
                <School
                    key={school.id}
                    school={school}
                />
            );
        });
    }

    useEffect(() => {
        loadTeacherViewData();
    }, [selected_school_id, from, till, selected_challenge_id]);

    return (
        <div className="sam-t-container-scroll">
            <div className="sam-t-container">
                {renderSchools()}
            </div>
        </div>
    );
}
