import React from 'react';
import {useSelector} from "react-redux";
import {get} from 'lodash';

import Graph from './Graph';

export default function ({printable}) {

    return (
        <div className="flex-3">
            <Graph
                printable={printable}
            />
        </div>
    );
}
