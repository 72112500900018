import {LOADED_CO_TEACHING_LESSONS} from "../actions/lesson_actions";

const co_teaching_lessons = (payload = [], action) => {
    switch (action.type) {
        case LOADED_CO_TEACHING_LESSONS:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    co_teaching_lessons
}
