import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {isEmpty} from 'lodash';
import {
    LoadLessonAction,
    LoadingAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import Top from './Top';
import ClassDetails from './ClassDetails';
import ClassOptions from './ClassOptions';
import ClassShare from './ClassShare';
import ClassBottom from './ClassBottom';
import useAuth from "../../hooks/useAuth";


export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const params = useParams();
    const lesson = useSelector(state => state.lesson);
    let lesson_channel;

    function fetchLesson() {
        return dispatch(LoadLessonAction(params.code, {'selectors[0]': 'challenge_code'}))
            .catch(error => {
                console.error(error);
                dispatch(ErrorMessageAction(error));
            })
            .then(() => dispatch(LoadingAction(false)));
    }

    function renderClassDetails() {
        if (isEmpty(lesson)) {
            return null;
        }

        return (
            <ClassDetails/>
        );
    }

    function renderClassOptions() {
        if (isEmpty(lesson)) {
            return null;
        }

        return (
            <ClassOptions/>
        );
    }

    function renderClassShare() {
        if (isEmpty(lesson)) {
            return null;
        }

        return (
            <ClassShare/>
        );
    }

    useEffect(() => {
        fetchLesson();
    }, []);

    useEffect(() => {
        if (lesson && !lesson_channel) {
            lesson_channel = pusher.subscribe('lesson_' + lesson.id);
            lesson_channel.bind('lesson.updated', fetchLesson);

            return () => {
                lesson_channel.unbind();
                lesson_channel.unsubscribe();
            }
        }
    }, [lesson.id]);

    return (
        <div id="teacher-class-setup">
            <div>
                <Top/>

                <div className="container">
                    <div className="section">
                        <div className="flex-row flex-wrap flex-box-10-10 flex-between">
                            <div>
                                {renderClassDetails()}
                            </div>

                            <div className="">
                                {renderClassOptions()}
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        {renderClassShare()}

                        <ClassBottom/>
                    </div>
                </div>
            </div>
        </div>
    );
}
