import React, {useState, useEffect} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import AWS from "aws-sdk";
import {assign} from 'lodash';
import MindsetsJsSDK, {ErrorMessageAction} from "mindsets-js-sdk";

import useAuth from "../../hooks/useAuth";
import WowMomentTemplate from './WowMomentTemplate';
import WowMomentCollection from './WowMomentCollection';
import WowMomentSubmit from './WowMomentSubmit';
import WowMomentShare from './WowMomentShare';


export default function () {
    useAuth(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const {wowMomentId} = useParams();
    const [lessonId, setLessonId] = useState(searchParams.get('li'));
    const [lesson, setLesson] = useState(null);
    const [wowMomentCode, setWowMomentCode] = useState(null);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (wowMomentId) {
            return MindsetsJsSDK().Api.wowMoment.get(
                wowMomentId
            ).then((response) => {
                const wowMoment = response.wowMoment;
                if (!lessonId) {
                    setLessonId(wowMoment.lesson_id);
                }
                setWowMomentCode(wowMoment.code);
                setContent(wowMoment.content);
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [wowMomentId])

    useEffect(() => {
        if (lessonId) {
            return MindsetsJsSDK().Api.wowMoment.lesson(
                lessonId
            ).then((response) => {
                setLesson(response.lesson);
                if (response.wowMomentId && !wowMomentId) {
                    navigate(`/teacher/wow-moments/save/${response.wowMomentId}`, {replace: true});
                } else if (!response.wowMomentId && !wowMomentId) {
                    setWowMomentCode(`wm-${response.lesson.code}`);
                }
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [lessonId])

    useEffect(() => {
        MindsetsJsSDK().API.get('wow_moments/guest/aws_credentials').then(response => {
            AWS.config.update({
                accessKeyId: response.access_key_id,
                secretAccessKey: response.secret_access_key
            })
        }).catch(e => {
            console.error(e);
            dispatch(ErrorMessageAction('Media Storage is unavailable. Please try reloading the page.', true));
        });
    }, [])

    const updateContent = (moreContent) => {
        const newContent = assign({}, content, moreContent);
        setContent(newContent);
    }

    const renderSubmit = () => {
        if (lesson) {
            return (
                <div className="section">
                    <WowMomentSubmit
                        wowMomentId={wowMomentId}
                        wowMomentCode={wowMomentCode}
                        challengeCodeId={lesson.challenge_version.challenge_code_id}
                        lessonId={lessonId}
                        content={content}
                    />
                </div>
            )
        }
    }

    const renderShare = () => {
        return (
            <div className="section">
                <WowMomentShare
                    wowMomentId={wowMomentId}
                    wowMomentCode={wowMomentCode}
                />
            </div>
        )
    }

    return (
        <>
            <div id='wow-moment-save'>
                <div className="container">
                    {renderShare()}

                    <div className="divider"/>

                    <WowMomentTemplate
                        lesson={lesson}
                        wowMomentCode={wowMomentCode}
                        content={content}
                        updateContent={updateContent}
                    />

                    <WowMomentCollection
                        lesson={lesson}
                        wowMomentCode={wowMomentCode}
                        content={content}
                        updateContent={updateContent}
                    />

                    {renderSubmit()}

                    {renderShare()}
                </div>
            </div>

            <div className="section"/>
        </>
    );
}