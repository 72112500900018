import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {countriesConstants} from "mindsets-js-sdk";

const ConfirmationScreen = function ({setMode, setValidationErrorMessages}) {
    const school = useSelector(state => state.school);

    let location = [];
    if (school.location) {
        location.push(school.location);
    }
    if (school.city) {
        location.push(school.city);
    }
    if (school.state) {
        location.push(school.state);
    }
    if (school.zip) {
        location.push(school.zip);
    }
    if (school.country && countriesConstants.COUNTRIES.hasOwnProperty(school.country)) {
        location.push(school.country);
    }

    return (
        <div className="modal-content">
            <div className="container">
                <div className="center school-confirmation-screen">
                    <div className="row no-mb">
                        <div className="col s1">
                            <h4>
                                <a href="" className="back-link" onClick={event => {
                                    event.preventDefault();
                                    setMode('school');
                                    setValidationErrorMessages({});
                                }
                                }>{"<"}</a>
                            </h4>
                        </div>
                    </div>
                    <img className="school-confirmation-image"
                         src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/ic-mindsets_school.png`}
                         alt={school.name}/>
                    <div className="name">{school.name}</div>
                    {school.district && school.district.name ?
                        <div className="address">{school.district.name}</div> : null}
                    <div className="address">{location.join(', ')}</div>
                </div>
            </div>
        </div>
    );
}

ConfirmationScreen.propTypes = {
    setValidationErrorMessages: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

export default ConfirmationScreen;
