import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction} from "mindsets-js-sdk";


function Content6({teacher_pd_code}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function onClick() {
        if (teacher_pd_code) {
            MindsetsJsSDK().API
                .post(`teacher/pd/join/${teacher_pd_code}`)
                .then(response => navigate('/hello/' + response.stream_code))
                .catch(error => dispatch(ErrorMessageAction(error)));
        } else {
            console.error('Teacher PD code is empty.')
        }
    }

    return (
        <div>
            <div className="center-align">
                <img className="teacher-pd-badge"
                     src={`${process.env.PUBLIC_S3_ASSETS_URL}/badges/tpd1.png`} alt='Badge'/>
            </div>

            <div className="flex-row flex-h-center">
                <button className="btn btn-challenge" onClick={onClick}>
                    {`Start Quiz`}
                </button>
            </div>
        </div>
    );
}

Content6.propTypes = {
    teacher_pd_code: PropTypes.string
}

export default Content6
