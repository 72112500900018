import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DateRangeSelector} from 'mindsets-js-sdk';

import SAMMenu from 'common/SAMMenu';
import SchoolFilter from 'common/spam/SchoolFilter';
import Overview from './Overview';
import TopSchools from './TopSchools';
import TopChallenges from './TopChallenges';
import useAuth from "../../hooks/useAuth";
import Forbidden from "../Forbidden";
import {isEducator} from "utils/role";
import {userCan} from "utils/membership";
import {SetPAMFromAction, SetPAMTillAction} from "../../actions/pam_actions";

export const FILTER_COOKIE_NAME = 'mindsets_spam_filter';

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);

    function renderMain() {
        return (
            <div>
                <div className="section">
                    <Overview/>
                </div>

                <div className="section">
                    <div className="divider"/>
                </div>

                <div className="section">
                    <TopSchools/>
                </div>

                <div className="section">
                    <div className="divider"/>
                </div>

                <div className="section">
                    <TopChallenges/>
                </div>
            </div>
        );
    }

    function renderContent() {
        if (me && isEducator(me) && me.permissions.includes('can_see_sam') && userCan(me.subscription_status, 'see_sam')) {
            return (
                <div id="sam-dashboard">
                    <div className="container">
                        <div className="section">
                            <SAMMenu/>
                        </div>

                        <div className="section">
                            <div className="flex-row flex-box-10-10">
                                <div>
                                    <SchoolFilter/>
                                </div>

                                <div>
                                    <DateRangeSelector
                                        from={from}
                                        till={till}
                                        onUpdate={data => {
                                            dispatch(SetPAMFromAction(data.from));
                                            dispatch(SetPAMTillAction(data.till));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <div className="divider"/>
                        </div>

                        {renderMain()}
                    </div>
                </div>
            );
        } else if (me && (!isEducator(me) || !me.permissions.includes('can_see_sam') || !userCan(me.subscription_status, 'see_sam'))) {
            return (
                <Forbidden/>
            );
        }

        return null;
    }

    return (
        <>
            {renderContent()}
        </>
    );
}
