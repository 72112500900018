const analyticStudentStream = (Analytic) => {
    Analytic.prototype.progressToolBarBack = function () {
        this.track('Clicked Back on Progress Tool Bar', {})
    }

    Analytic.prototype.progressToolBarNext = function (data = {}) {
        this.track('Clicked Next on Progress Tool Bar', data)
    }

    Analytic.prototype.progressToolBarBlock = function () {
        this.track('Clicked Block on Progress Tool Bar', {})
    }

    Analytic.prototype.progressToolBarAvatar = function () {
        this.track('Clicked Avatar on Progress Tool Bar', {})
    }

    Analytic.prototype.studentRaiseHand = function (challenge_name, class_code) {
        this.track('Student Raised Hand', {
            challenge_name,
            class_code
        })
    }

    Analytic.prototype.studentLowerHand = function (challenge_name, class_code) {
        this.track('Student Lowered Hand', {
            challenge_name,
            class_code
        })
    }

    Analytic.prototype.studentSubmittedVote = function (data = {}) {
        this.track('Student Submitted Vote', data)
    }
}

export default analyticStudentStream
