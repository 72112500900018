import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, isEmpty, isEqual, trim} from 'lodash'


class BaseAnswer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: this.initAnswer(),
            auto_grading: false
        }
    }

    defaultAnswer() {
        return null
    }

    initAnswer() {
        return get(this.props.existing_answer, 'data.answer', this.defaultAnswer())
    }

    correctAnswer() {
        return get(this.props.component_data, 'config.answer')
    }

    isValid() {
        return !isEmpty(trim(this.state.answer))
    }

    isEdited() {
        return !isEqual(this.initAnswer(), this.state.answer)
    }

    isAnswerCorrect() {
        if (get(this.props.component_data, 'config.auto_grading')) {
            return !isEmpty(this.state.answer) && isEqual(this.state.answer, this.correctAnswer())
        } else {
            return true
        }
    }

    isCorrect() {
        if (get(this.props.component_data, 'config.auto_grading')) {
            this.setState({auto_grading: true})
            return this.isAnswerCorrect()
        } else {
            return true
        }
    }

    getAnswer() {
        return this.state.answer
    }

    clearAnswer() {
        this.setState({answer: this.defaultAnswer()})
    }

    render() {
        return null
    }
}

BaseAnswer.propTypes = {
    challenge_version: PropTypes.object.isRequired,
    component_data: PropTypes.object.isRequired,
    existing_answer: PropTypes.shape({
        data: PropTypes.shape({
            answer: PropTypes.any
        }).isRequired
    }),
    is_preview: PropTypes.bool.isRequired,
    question_id: PropTypes.number.isRequired,
    stream_entity_id: PropTypes.number.isRequired,
    stream_id: PropTypes.number,
    lesson_id: PropTypes.number,
    is_latest: PropTypes.bool.isRequired,
    support_data: PropTypes.object
}

export default BaseAnswer
