import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {sortBy, reverse, take} from 'lodash';
import Highcharts from 'highcharts';
import MindsetsJsSDK, {ErrorMessageAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const [challenge_codes, setChallengeCodes] = useState([]);

    function loadChallengesData() {
        return MindsetsJsSDK().Api.sam.top_challenges.show({
            selected_school_id: selected_school_id ? selected_school_id : '',
            selected_from: from,
            selected_till: till
        }).then((response) => {
            setChallengeCodes(response.challenge_codes);
        }).catch((error) => {
            console.error('error', error);
            dispatch(ErrorMessageAction(error));
        })
    }

    function drawByClasses() {
        const sorted = reverse(
            sortBy(challenge_codes, 'no_of_classes')
        )
        const top_challenges = take(sorted, 5)

        const challenge_names = top_challenges.map((c) => c.challenge_name)
        const challenge_colors = top_challenges.map((c) => c.challenge_color)
        const challenge_data = top_challenges.map((c) => c.no_of_classes)

        const graph = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Top Challenges by Classes'
            },
            tooltip: {
                enabled: false,
                crosshairs: true
            },
            plotOptions: {
                column: {
                    maxPointWidth: 100
                },
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            xAxis: {
                title: {
                    text: 'Mindsets Challenges'
                },
                categories: challenge_names
            },
            yAxis: {
                title: {
                    text: 'Number of Classes'
                },
                allowDecimals: false
            },
            series: [{
                name: 'Number of Classes',
                data: challenge_data,
                colorByPoint: true,
                colors: challenge_colors
            }],
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        }

        Highcharts.chart('sam-top-challenges-by-classes', graph);
    }

    function drawByStudents() {
        const sorted = reverse(
            sortBy(challenge_codes, 'no_of_student_streams')
        )
        const top_challenges = take(sorted, 5)

        const challenge_names = top_challenges.map((c) => c.challenge_name)
        const challenge_colors = top_challenges.map((c) => c.challenge_color)
        const challenge_data = top_challenges.map((c) => c.no_of_student_streams)

        const graph = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Top Challenges by Students'
            },
            tooltip: {
                enabled: false,
                crosshairs: true
            },
            plotOptions: {
                column: {
                    maxPointWidth: 100
                },
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            xAxis: {
                title: {
                    text: 'Mindsets Challenges'
                },
                categories: challenge_names
            },
            yAxis: {
                title: {
                    text: 'Number of Students'
                },
                allowDecimals: false
            },
            series: [{
                name: 'Number of Students',
                data: challenge_data,
                colorByPoint: true,
                colors: challenge_colors
            }],
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        }

        Highcharts.chart('sam-top-challenges-by-students', graph);
    }

    function renderByClasses() {
        return (
            <div>
                <div id="sam-top-challenges-by-classes"/>
            </div>
        );
    }

    function renderByStudents() {
        return (
            <div>
                <div id="sam-top-challenges-by-students"/>
            </div>
        );
    }

    useEffect(() => {
        loadChallengesData();
    }, []);

    useEffect(() => {
        loadChallengesData();
    }, [selected_school_id, from, till]);
    useEffect(() => {
        drawByClasses();
        drawByStudents();
    }, [challenge_codes]);

    return (
        <div>
            <div className="flex-row-l flex-box-20-20">
                <div className="flex-1">
                    {renderByClasses()}
                </div>

                <div className="flex-1">
                    {renderByStudents()}
                </div>
            </div>
        </div>
    );
}
