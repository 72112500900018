import {get} from "lodash";
import {processStringContent, replaceSpecialChars} from 'utils/content'

export default function InlinePollResponse(cells, poll, response) {
    const response_content = get(response, 'data.answer')
    const poll_option = poll.poll_options.find(poll_option => poll_option.id === response_content);
    if (poll_option) {
        const cell_text = processStringContent(
            replaceSpecialChars(poll_option.title, {convert_katex: false}),
            {
                keep_html: false,
                convert_html: false,
                convert_math: false
            }
        );
        cells.push(cell_text);
    }
}
