const analyticTeacherLesson = (Analytic) => {
    Analytic.prototype.lessonToggleAnswerKeys = function (class_code, show) {
        this.track('Toggle Lesson Answer Keys', {
            class_code,
            show
        })
    }

    Analytic.prototype.lessonToggleHiddenForStudents = function (class_code, is_hidden) {
        this.track('Toggle Lesson Hidden for Students', {
            class_code: class_code,
            is_hidden: !!is_hidden
        })
    }

    Analytic.prototype.toggleTeachingMode = function (class_code, mode) {
        this.track('Toggle Teaching Mode', {
            class_code,
            mode
        })
    }

    Analytic.prototype.clickTeachingModeIndicator = function (class_code) {
        this.track('Click Teaching Mode Indicator', {class_code});
    }
}

export default analyticTeacherLesson
