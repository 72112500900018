import React from 'react';
import PropTypes from 'prop-types';

function Toolbar({scale, onZoomIn, onZoomOut, onDownload}) {
    return (
        <div className="pdf-toolbar">
            <div className="container flex-row flex-h-between flex-v-center">
                <div className='flex-row flex-v-center'>
                    <button className="ZoomIn" onClick={onZoomOut}>-</button>
                    <button className="ZoomOut" onClick={onZoomIn}>+</button>
                    <div className="ZoomPercent">{(scale * 100).toFixed(1)}%</div>
                </div>
                <button className="download" onClick={onDownload}>
                    <i className="fas fa-download fa-2x"/>
                </button>
            </div>
        </div>
    );
}

Toolbar.propTypes = {
    scale: PropTypes.number.isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired
}

export default Toolbar;
