import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from 'lodash';
import MindsetsJsSDK, {filterConstants, LoadLessonsAction} from "mindsets-js-sdk";

import ALesson from './ALesson';
import analytic from 'utils/Analytic';

export default function () {
    const dispatch = useDispatch();
    const lessons = useSelector(state => state.lessons);
    const [survey_url, setUrl] = useState(null)

    function renderOpenLessons() {
        return lessons.map(lesson => {
            return (
                <ALesson
                    key={lesson.id}
                    lesson={lesson}
                />
            );
        });
    }

    function renderSurvey() {
        if (survey_url) {
            const onClick = () => {
                analytic.log('startOpenChallengeSurvey')
            }
            return (
                <div className="flex-row flex-h-end flex-v-center flex-box-5-10 flex-wrap">
                    <div>
                        {`Complete a 5 min survey!`}
                    </div>
                    <a
                        className="btn btn-on-white"
                        href={survey_url}
                        target="_blank"
                        onClick={onClick}>
                        {`Prize Draw`}
                    </a>
                </div>
            );
        }

        return null;
    }

    useEffect(() => {
        dispatch(LoadLessonsAction({
            'filters[is_open][0]': filterConstants.EXPRESSION_TRUE,
            'selectors[0]': 'challengeVersion_challengeCode'
        }));
        MindsetsJsSDK().Api.setting.show('STUDENT_SURVEY_URL').then(response => setUrl(response.setting.value)).catch(error => console.error(error));
    }, []);

    if (isEmpty(lessons)) {
        return null;
    }

    return (
        <div className="student-dashboard-open-lessons">
            <h3 className="header-new">
                {`Open Challenges`}
            </h3>

            <div className="section">
                <div className="flex-row-m flex-between flex-wrap flex-box-10-10">
                    {renderOpenLessons()}
                </div>
            </div>

            {renderSurvey()}

            <hr className="separator bigger"/>
        </div>
    );
}