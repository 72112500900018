import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import {convertLatexToKatex} from 'utils/math'
import {processStringContent} from 'utils/content'


class ReadOnly extends Component {
    getContent() {
        if (this.props.existing_answer) {
            const latex = get(this.props.existing_answer, 'data.answer')
            const katex = convertLatexToKatex(latex)
            return processStringContent(katex, {convert_spelling: false})
        } else if (this.props.default_answer) {
            const katex = convertLatexToKatex(this.props.default_answer)
            return processStringContent(katex, {convert_spelling: false})
        } else {
            return null
        }
    }

    render() {
        if (this.props.is_readonly) {
            return (
                <div className="component-expression-readonly flex-row flex-v-center">
                    {this.getContent()}
                </div>
            )
        }
    }
}

ReadOnly.propTypes = {
    is_readonly: PropTypes.bool.isRequired,
    existing_answer: PropTypes.object,
    default_answer: PropTypes.string
}

export default ReadOnly
