import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux";
import {Cookie, SetTeacherFacilitationSortByAction} from "mindsets-js-sdk";

import {SORT_STUDENTS_BY_COOKIE_NAME} from "../../index";
import analytic from "utils/Analytic";


function SortAction({callBack}) {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);

    function onSortBy(sort_by) {
        Cookie.setCookie(SORT_STUDENTS_BY_COOKIE_NAME + lesson.code, sort_by);
        dispatch(SetTeacherFacilitationSortByAction(sort_by));
        analytic.log('facilitationSortStudents', {
            name: lesson.challenge_version.name,
            code: lesson.challenge_version.challenge_code.code,
        }, lesson.code, sort_by);
        if (typeof callBack === 'function') {
            callBack();
        }
    }

    return (
        <div className="facilitation-menu-actions-sort">
            <hr/>
            <ul>
                <li>
                    <a className='px-4 py-2 d-block' href="#" onClick={e => {
                        e.preventDefault();
                        onSortBy('name');
                    }}>
                        <i className={"fas fa-check mr-2 " + (sort_by !== 'name' ? 'invisible' : '')}/>
                        {`Sort by name`}
                    </a>
                </li>
                <li>
                    <a className='px-4 py-2 d-block' href="#" onClick={e => {
                        e.preventDefault();
                        onSortBy('progress');
                    }}>
                        <i className={"fas fa-check mr-2 " + (sort_by !== 'progress' ? 'invisible' : '')}/>
                        {`Sort by progress`}
                    </a>
                </li>
            </ul>
            <hr/>
        </div>
    );
}

SortAction.propTypes = {
    callBack: PropTypes.func
}

export default SortAction
