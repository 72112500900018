import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useAuth from "../../hooks/useAuth";
import HomeMenu from "common/HomeMenu";
import Challenges from "./Challenges";

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);

    return (
        <div id="teacher-dashboard">
            <div className="container">
                <div className="section">
                    <HomeMenu/>
                </div>
                <div className="section">
                    <Challenges/>
                </div>
            </div>
        </div>
    );
}