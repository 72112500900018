import {get, find, isString, isPlainObject, sum, filter, head} from 'lodash'

import {processStringContent, replaceSpecialChars} from 'utils/content'
import {isBonus} from 'utils/challenge/streamEntity'
import Answer from './Answer'
import Expression from './Expression'
import Graphing from './Graphing'
import Poll from './Poll'
import Spreadsheet from './Spreadsheet'
import AnswerChoice from "./AnswerChoice";
import {getQuestionID} from 'utils/challenge/challengeComponent'
import InlinePollResponse from "./InlinePollResponse";


export default class ByQuestionCompiler {
    compile(rows, streams, challenge_cards, lesson) {
        const is_new = this.isNewDataModel(lesson);
        if (is_new) {
            rows.push(['Question', 'Student', 'Answer(s)']);
        } else {
            rows.push(['Question', 'Question type', 'Student', 'Answer(s)']);
        }

        challenge_cards.filter(challenge_card => {
            if (is_new) {
                const rows = get(challenge_card, 'data.card_content.rows', []);
                return sum(
                    rows.map((row) => {
                        const components = get(row, 'components', []);
                        return filter(components, (c) => {
                            return c.type === 'question' || c.type === 'poll.block';
                        }).length;
                    })
                ) > 0;
            } else {
                return challenge_card.questions.filter(question => this.questionRequiresAnswer(question)).length + challenge_card.polls.length + challenge_card.spreadsheets.filter(spreadsheet => this.spreadsheetRequiresAnswer(spreadsheet)).length > 0;
            }
        }).map(challenge_card => {
            if (is_new) {
                challenge_card.data.card_content.rows
                    .map(row => {
                        row.components
                            .filter(component => {
                                return ['poll.inline', 'poll.block', 'spreadsheet'].indexOf(component.type) >= 0 || component.type.startsWith('answer.');
                            })
                            .map(component => {
                                streams.map(stream => {
                                    const stream_card = stream.stream_entities.find(stream_card => stream_card.id === challenge_card.id);
                                    if (stream_card) {
                                        this.compileNew(rows, stream.user, challenge_card, stream_card, component)
                                    }
                                })
                            });
                    });
            } else {
                streams.map(stream => {
                    const stream_card = stream.stream_entities.find(stream_card => stream_card.id === challenge_card.id);
                    if (stream_card) {
                        this.compileOld(rows, stream.user, challenge_card, stream_card);
                    }
                })
            }
        });

        return rows;
    }

    compileOld(rows, user, challenge_card, stream_card) {
        if (stream_card.answers.length) {
            stream_card.answers.map((answer, index) => {
                let cells = [];
                cells.push(this.getOldQuestion(challenge_card, index));
                cells.push(this.getQuestionType(challenge_card, index));
                cells.push(user.fname + ' ' + head(user.lname));
                const answerClass = new Answer;
                answerClass.render(cells, answer);
                rows.push(cells);
            });
        }
        if (stream_card.votes.length) {
            stream_card.votes.map((vote, index) => {
                let cells = [];
                cells.push(this.getOldQuestion(challenge_card, index));
                cells.push(this.getPollType(challenge_card, index));
                cells.push(user.fname + ' ' + head(user.lname));
                const poll = new Poll();
                poll.render(cells, vote);
                rows.push(cells);
            });
        }
        if (stream_card.user_spreadsheets.length) {
            stream_card.user_spreadsheets.map((user_spreadsheet, index) => {
                let cells = [];
                cells.push(this.getOldQuestion(challenge_card, index));
                cells.push('Spreadsheet');
                cells.push(user.fname + ' ' + head(user.lname));
                const spreadsheet = new Spreadsheet();
                spreadsheet.render(cells, user_spreadsheet);
                rows.push(cells);
            });
        }
    }

    compileNew(rows, user, challenge_card, stream_card, component) {
        const polls = challenge_card.polls;
        const user_votes = stream_card.votes;
        const responses = stream_card.responses;

        let cells = [];
        cells.push(this.getQuestion(challenge_card, component));
        cells.push(user.fname + ' ' + head(user.lname));

        const question_id = getQuestionID(component, challenge_card.questions)
        const poll = find(polls, (p) => p.question_id == question_id)
        const response = find(responses, {question_id})

        switch (true) {
            case component.type.startsWith('answer.'):
                if (!response) {
                    break
                }
                switch (true) {
                    case component.type === 'answer.graphing':
                        new Graphing().render(cells, response);
                        break;
                    case component.type.startsWith('answer.expression'):
                        new Expression().render(cells, response);
                        break;
                    case component.type.startsWith('answer.choice_'):
                        new AnswerChoice().render(cells, component.data.options, response);
                        break;
                    default:
                        new Answer().render(cells, response);
                }
                break;
            case component.type === 'poll.inline':
                if (!poll) {
                    break
                }
                if (response) {
                    InlinePollResponse(cells, poll, response);
                }
                break;
            case component.type === 'poll.block':
                // In case of Poll block, question_id is undefined
                if (!poll) {
                    break
                }
                const vote = find(user_votes, (v) => v.poll_id === poll.id);
                if (vote) {
                    new Poll().render(cells, vote);
                }
                break;
            case component.type === 'spreadsheet':
                if (response) {
                    new Spreadsheet().render(cells, response);
                }
                break;
        }

        if (cells.length > 2) {
            rows.push(cells);
        }
    }

    getOldQuestion(challenge_card, index) {
        let title = '';
        if (challenge_card.data.card_no) {
            title += (isBonus(challenge_card) ? 'B' : 'Q') + challenge_card.data.card_no + ': ';
        }
        if (get(challenge_card, ['questions', index, 'title'])) {
            title += get(challenge_card, ['questions', index, 'title']);
        } else if (challenge_card.data.title) {
            title += challenge_card.data.title;
        } else if (get(challenge_card, ['polls', index, 'description'])) {
            title += get(challenge_card, ['polls', index, 'description'])
        }

        return processStringContent(
            replaceSpecialChars(title),
            {
                keep_html: false,
                convert_html: false,
                convert_math: false
            }
        ).substr(0, 500);
    }

    getQuestionType(challenge_card, index) {
        switch (get(challenge_card, ['questions', index, 'type'])) {
            case 'single':
            case 'multiple':
                return 'Multiple choice';
            case 'slider':
            case 'range':
            case 'numberline':
                return 'Slider';
            default:
                return 'Open answer';
        }
    }

    getPollType(challenge_card, index) {
        switch (get(challenge_card, ['polls', index, 'type'])) {
            case 'inline':
                return 'Multiple choice';
            default:
                return 'Poll';
        }
    }

    getQuestion(challenge_card, component) {
        const q_number = component.data && component.data.question_number ? component.data.question_number : null;
        if (q_number) {
            let question_component;
            find(challenge_card.data.card_content.rows, (row) => {
                return question_component = find(row.components, (component) => {
                    return get(component, ['data', 'question_number']) === q_number && 'question' === get(component, ['type']);
                });
            });
            if (question_component) {
                const question_text = processStringContent(
                    replaceSpecialChars(get(question_component, ['data', 'question']), {convert_katex: false}),
                    {
                        keep_html: false,
                        convert_html: false,
                        convert_math: false
                    }
                ).substr(0, 500);
                return 'Q' + q_number + ': ' + question_text;
            } else {
                return '';
            }
        } else if (component.data && component.type.startsWith('poll.')) {
            if (component.data.title) {
                return component.data.title;
            } else {
                return 'Poll';
            }
        } else {
            return '';
        }
    }

    isNewDataModel(lesson) {
        return lesson.challenge_version.data_version > 0;
    }

    spreadsheetRequiresAnswer(spreadsheet) {
        let isRequired = false;
        spreadsheet.data.map(row => {
            Object.keys(row).map(cell_key => {
                const cell = row[cell_key];
                if ((isString(cell) && cell.length === 0) || (isPlainObject(cell) && cell.hasOwnProperty('editable') && true === cell.editable)) {
                    isRequired = true;
                }
            });
        });

        return isRequired;
    }

    questionRequiresAnswer(question) {
        return question.type !== 'nullable';
    }
}
