import React from 'react';
import {useSelector} from "react-redux";
import {find, get, uniqBy} from 'lodash';

import QuestionSkillWeightage from './QuestionSkillWeightage';
import ModeSelector from './ModeSelector';

export default function () {
    const selected_challenge_id = useSelector(state => state.pam_selected_challenge_id);
    const challenges = useSelector(state => state.me_school_challenges);
    const student_skill_data = useSelector(state => state.pam_student_skill_data);

    const selected_challenge = find(challenges, {id: selected_challenge_id});

    function getStyle() {
        const url = `${process.env.PUBLIC_S3_ASSETS_URL}/challenge-images/${selected_challenge.code}/hero_s_1.jpeg`
        return {
            backgroundImage: `url(${url})`
        }
    }

    function renderContent() {
        const challenge_name = get(selected_challenge, 'name')
        const number_of_students = uniqBy(student_skill_data, 'user_id').length
        return (
            <div className="pam-challenge-sa-top-title-content">
                <h4 className="header-new center-align">
                    {`${challenge_name} Challenge`}
                </h4>
                <div className="center-align">
                    {`Number of students: ${number_of_students}`}
                </div>
                <div>
                    <QuestionSkillWeightage/>
                </div>
                <div>
                    <ModeSelector/>
                </div>
            </div>
        )
    }

    return (
        <div className="pam-challenge-sa-top-title flex-row flex-vh-center" style={getStyle()}>
            {renderContent()}
        </div>
    );
}
