import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {isWindowsApp} from 'utils/windows';
import StudentIcon from './StudentIcon';
import StudentInfo from './StudentInfo';
import ProgressBar from 'common/challenge/ProgressBar';
import Actions from './Actions';
import Overlay from './Overlay';
import analytic from 'utils/Analytic';

function Stream({stream}) {
    const lesson = useSelector(state => state.lesson);

    function renderStudentIcon() {
        return (
            <div className="overview-student-avatar flex-row flex-vh-center">
                <StudentIcon
                    stream={stream}
                />
            </div>
        );
    }

    function renderStudentStreamLink() {
        // Disable teacher student stream view for now
        return null;

        const onClick = () => {
            analytic.log('facilitationOpenStudentStream', stream.code, stream.user)
        }

        return (
            <Link
                className="overview-student-link"
                to={`/hello/${stream.code}`}
                target={isWindowsApp() ? '_self' : '_blank'}
                onClick={onClick}
            >
                &nbsp;
            </Link>
        )
    }

    function renderStudent() {
        return (
            <div className="overview-student flex-row">
                {renderStudentIcon()}

                <div className="flex-auto flex-column">
                    <StudentInfo
                        stream={stream}
                    />

                    <div className="overview-student-progress">
                        <ProgressBar
                            challenge_version={lesson.challenge_version}
                            current_card_index={stream.index}
                            current_stream_index={stream.index}
                            show_avatar={false}
                            current_taller={false}
                            show_tooltip={false}
                        />
                    </div>
                </div>

                <div className="flex-row">
                    <Actions
                        stream={stream}
                    />
                </div>

                <Overlay
                    stream={stream}
                />

                {renderStudentStreamLink()}
            </div>
        );
    }

    return (
        <div className="overview-stream">
            {renderStudent()}
        </div>
    );
}

Stream.propTypes = {
    stream: PropTypes.shape({
        id: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired
    }).isRequired
}

export default Stream
