import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {map} from 'lodash';
import {
    SetLibraryGradeAction,
    SetLibraryMeasureAction,
    SetLibrarySubjectAction,
    SetLibraryThemeAction,
    SetLibraryTopicAction,
    Cookie
} from "mindsets-js-sdk";

import analytic from 'utils/Analytic'
import {capitalizeFirstLetter} from 'utils/content'
import {MEASURES, MEASURE_COOKIE_NAME} from 'constants'
import Flag from "./Flag";
import GuideLink from "./GuideLink";
import {extractTopics, extractGrades, extractThemes} from "common/challengeNew/utils";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const challenge_versions = useSelector(state => state.challenge_versions);
    const subject = useSelector(state => state.library_subject);
    const grade = useSelector(state => state.library_grade);
    const theme = useSelector(state => state.library_theme);
    const topic = useSelector(state => state.library_topic);
    const measure = useSelector(state => state.library_measure);

    const topics = extractTopics(challenge_versions);
    const grades = extractGrades(challenge_versions);
    const themes = extractThemes(challenge_versions);

    function onSubjectChange(event) {
        dispatch(SetLibrarySubjectAction(event.target.value));
        dispatch(SetLibraryGradeAction(''));
        dispatch(SetLibraryThemeAction(''));
        dispatch(SetLibraryTopicAction(''));
        analytic.log('useChallengeFilter', 'subject', topic, grade, theme, measure, event.target.value);
    }

    function onTopicChange(event) {
        dispatch(SetLibraryTopicAction(event.target.value));
        analytic.log('useChallengeFilter', 'topic', event.target.value, grade, theme, measure, subject);
    }

    function onGradeChange(event) {
        dispatch(SetLibraryGradeAction(event.target.value));
        analytic.log('useChallengeFilter', 'grade', topic, event.target.value, theme, measure, subject);
    }

    function onThemeChange(event) {
        dispatch(SetLibraryThemeAction(event.target.value));
        analytic.log('useChallengeFilter', 'theme', topic, grade, event.target.value, measure, subject);
    }

    function onMeasureChange(event) {
        dispatch(SetLibraryMeasureAction(event.target.value));
        Cookie.setCookie(MEASURE_COOKIE_NAME, event.target.value, 7 * 24 * 60 * 60);
        analytic.log('useChallengeFilter', 'measure', topic, grade, theme, event.target.value, subject);
    }

    function renderSubject() {
        return (
            <div className='flex-grow-1'>
                <select
                    value={subject}
                    onChange={onSubjectChange}
                    id="subject"
                >
                    <option value='math'>Math</option>
                    <option value='science'>Science</option>
                </select>
            </div>
        );
    }

    function renderTopic() {
        return (
            <div className="">
                <select
                    value={topic}
                    onChange={onTopicChange}
                    id="topic"
                    disabled={topics.length <= 0}
                >
                    <option key='empty' value=''>Topic (All)</option>
                    {map(topics, (topic, key) => {
                        return <option key={key} value={topic}>{topic}</option>
                    })}
                </select>
            </div>
        );
    }

    function renderGrade() {
        return (
            <div className="">
                <select
                    value={grade}
                    onChange={onGradeChange}
                    id="grade"
                    disabled={grades.length <= 0}
                >
                    <option key='empty'
                            value=''>{capitalizeFirstLetter(me && me.curriculum ? me.curriculum.grade_term : 'grade')} (All)
                    </option>
                    {map(grades, (grade, key) => {
                        return <option key={key} value={grade}>{grade}</option>
                    })}
                </select>
            </div>
        );
    }

    function renderTheme() {
        return (
            <div className='flex-grow-1'>
                <select
                    value={theme}
                    onChange={onThemeChange}
                    id="theme"
                    disabled={themes.length <= 0}
                >
                    <option key='empty' value=''>Theme (All)</option>
                    {map(themes, (theme, key) => {
                        return <option key={key} value={theme}>{theme}</option>;
                    })}
                </select>
            </div>
        );
    }

    function renderMeasure() {
        return (
            <div className='flex-grow-1'>
                <select
                    value={measure}
                    onChange={onMeasureChange}
                    id="measure"
                >
                    {map(Object.keys(MEASURES), (key) => {
                        return <option key={key} value={key}>{MEASURES[key]}</option>
                    })}
                </select>
            </div>
        );
    }

    return (
        <div className='flex-row flex-v-center flex-box-5-5 flex-wrap'>
            {renderTopic()}

            {renderGrade()}
        </div>
    );
}
