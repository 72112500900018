import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {first} from "lodash";
import {countriesConstants, SetUserAction} from "mindsets-js-sdk";

const SchoolsList = function ({setMode, setValidationErrorMessages}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const schools = useSelector(state => state.schools);
    const curricula = useSelector(state => state.curricula);

    let user_new = JSON.parse(JSON.stringify(user));

    return (
        <div className="modal-content">
            <div className="container">
                <div className="row no-mb">
                    <div className="col s1">
                        <h4><a href="" className="back-link" onClick={
                            (event) => {
                                event.preventDefault();
                                setMode('confirmation');
                                setValidationErrorMessages({});
                            }
                        }>{"<"}</a></h4>
                    </div>
                    <div className="col s10">
                        <h4>We've found a {schools.length > 1 ? 'few schools' : 'school'} that
                            {schools.length > 1 ? ' match' : ' matches'}.</h4>
                        <p>{schools.length > 1 ? 'Here are the most relevant. ' : ''}Maybe there's the
                            one you are looking for? Just pick it.</p>
                    </div>
                </div>
                <div className="schools-list">
                    {schools.map(school => {
                        let location = [];
                        if (school.location) {
                            location.push(school.location);
                        }
                        if (school.city) {
                            location.push(school.city);
                        }
                        if (school.state) {
                            location.push(school.state);
                        }
                        if (school.zip) {
                            location.push(school.zip);
                        }
                        if (school.country && countriesConstants.COUNTRIES.hasOwnProperty(school.country)) {
                            location.push(school.country);
                        }

                        return (
                            <div
                                key={school.id}
                                className="autocomplete-dropdown-menu-item pointer"
                                onClick={() => {
                                    user_new.school_id = school.id;
                                    user_new.school = school;
                                    if (!user_new.state && school.state) {
                                        me_nuser_newew.state = school.state;
                                        const curriculum = first(curricula.filter(curriculum => {
                                            return curriculum.country_code === user_new.country && curriculum.state_code === school.state;
                                        }).sort((a, b) => {
                                            if (a.is_default && !b.is_default) {
                                                return -1;
                                            } else if (!a.is_default && b.is_default) {
                                                return 1;
                                            }

                                            return 0;
                                        }));
                                        if (curriculum) {
                                            user_new.curriculum_id = curriculum.id;
                                        } else {
                                            user_new.curriculum_id = null;
                                        }
                                    }
                                    dispatch(SetUserAction(user_new));
                                    setMode('user');
                                    setValidationErrorMessages({});
                                }}>
                                <div className="flex-row flex-v-center">
                                    <img className="school-image mr-3"
                                         src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/ic-mindsets_school.png`}
                                         alt={school.name}/>
                                    <div>
                                        <div className="autocomplete-dropdown-menu-item-title">{school.name}</div>
                                        {school.district && school.district.name ?
                                            <div className="autocomplete-dropdown-menu-item-second">{school.district.name}</div> : null}
                                        <div className="autocomplete-dropdown-menu-item-second">{location.join(', ')}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

SchoolsList.propTypes = {
    setValidationErrorMessages: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

export default SchoolsList;
