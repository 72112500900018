import React from 'react';
import PropTypes from 'prop-types';

import CardSelector from 'common/challenge/CardSelector';


function CardNavigator(
    {
        challenge_version,
        stream_entities,
        selected_card_index,
        onSelect,
        popup_setting = {right: '-160px'}
    }
) {
    function renderPrevious() {
        const onClick = () => {
            onSelect(selected_card_index - 1)
        }
        const extra_class = selected_card_index <= 0 ? ' visibility-hidden' : ''
        return (
            <button className={"btn btn-grey hide-on-small-only " + extra_class} onClick={onClick}>
                <i className="fas fa-angle-left"></i>
            </button>
        )
    }

    function renderCurrent() {
        return (
            <CardSelector
                challenge_version={challenge_version}
                stream_entities={stream_entities}
                selected_card_index={selected_card_index}
                onSelect={onSelect}
                popup_setting={popup_setting}
            />
        )
    }

    function renderNext() {
        const {final_index} = challenge_version;
        const onClick = () => {
            onSelect(selected_card_index + 1);
        }
        const extra_class = selected_card_index >= final_index ? ' visibility-hidden' : '';

        return (
            <button className={"btn btn-grey hide-on-small-only " + extra_class} onClick={onClick}>
                <i className="fas fa-angle-right"></i>
            </button>
        );
    }

    return (
        <div className="challenge-card-navigator">
            <div className="flex-row flex-box-5-10">
                <div>
                    {renderPrevious()}
                </div>

                <div>
                    {renderCurrent()}
                </div>

                <div>
                    {renderNext()}
                </div>
            </div>
        </div>
    );
}

CardNavigator.propTypes = {
    challenge_version: PropTypes.shape({
        final_index: PropTypes.number.isRequired
    }).isRequired,
    stream_entities: PropTypes.array.isRequired,
    selected_card_index: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    popup_setting: PropTypes.object
}

export default CardNavigator
