import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

export const LOADED_CO_TEACHING_LESSONS = '[lessons] Loaded co-teaching lessons';

export const LoadCoTeachingLessonsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Classes')))
        .then(() => MindsetsJsSDK().API.get('lessons/co-teaching', options))
        .then(response => {
            dispatch(LoadedCoTeachingLessonsAction(response.items));
            dispatch(LoadingAction(false));

            return response.items;
        });
};
export const LoadedCoTeachingLessonsAction = payload => ({
    type: LOADED_CO_TEACHING_LESSONS,
    payload
});
