import {isEmpty, get} from 'lodash'

export const userCan = (subscription_status, feature, data = {}) => {
    if (isEmpty(subscription_status)) {
        throw 'No user subscription data'
    }

    const {
        subscribed, on_school_subscription, on_district_subscription, only_individual_subscription
    } = subscription_status

    const challenge_is_free = get(data, 'challenge_version.challenge_code.is_free', false)

    switch (feature) {
        case 'preview_full_challenge':
        case 'create_mindsets_code':
        case 'download_challenge_guide':
            return challenge_is_free || subscribed
        case 'open_reader':
            return true
        case 'download_class_report':
        case 'makeLessonHidden':
            return subscribed
        case 'assign_teachers':
        case 'see_sam':
        case 'see_pam':
            return on_school_subscription || on_district_subscription
        case 'download_project_extension':
            return subscribed && !only_individual_subscription;
        default:
            throw 'Feature does not exist'
    }
}
