import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {getQuestionID} from 'utils/challenge/challengeComponent'
import ContentComponent from './ContentComponent'
import AnswerComponent from './AnswerComponent'
import SpecialComponent from './SpecialComponent'
import CardComponentContainer from './CardComponentContainer'


class CardComponent extends Component {
    getContentComponent() {
        return (
            <ContentComponent
                component_data={this.props.component_data}
                lesson_id={this.props.lesson_id}
                stream_id={this.props.stream_id}
                stream_entity_id={this.props.stream_entity.id}
                is_preview={this.props.is_preview}
                is_latest={this.props.is_latest}
            />
        )
    }

    getAnswerComponent() {
        const question_id = getQuestionID(
            this.props.component_data,
            this.props.stream_entity.questions
        )
        const existing_answer = this.props.getExistingAnswer(question_id)
        const support_data = this.props.getSupportData(this.props.component_data.type, question_id)

        return (
            <AnswerComponent
                component_data={this.props.component_data}
                existing_answer={existing_answer}
                challenge_version={this.props.challenge_version}
                lesson_id={this.props.lesson_id}
                stream_id={this.props.stream_id}
                stream_entity_id={this.props.stream_entity.id}
                is_preview={this.props.is_preview}
                is_latest={this.props.is_latest}
                refComponent={this.props.refComponent}
                question_id={question_id}
                support_data={support_data}
            />
        )
    }

    getSpecialComponent() {
        const question_id = getQuestionID(
            this.props.component_data,
            this.props.stream_entity.questions
        )
        const support_data = this.props.getSupportData(this.props.component_data.type, question_id)

        return (
            <SpecialComponent
                component_data={this.props.component_data}
                support_data={support_data}
                challenge_version={this.props.challenge_version}
                lesson_id={this.props.lesson_id}
                stream_id={this.props.stream_id}
                stream_entity_id={this.props.stream_entity.id}
                is_preview={this.props.is_preview}
                is_latest={this.props.is_latest}
                refComponent={this.props.refComponent}
            />
        )
    }

    getComponent() {
        const type = this.props.component_data.type.toLowerCase()
        switch (true) {
            case type === 'poll.inline':
            case type === 'spreadsheet':
            case type.startsWith('answer.'):
                return this.getAnswerComponent()
            case type === 'poll.block':
                return this.getSpecialComponent()
            default:
                return this.getContentComponent()
        }
    }

    renderComponent() {
        return (
            <CardComponentContainer component_data={this.props.component_data}>
                {this.getComponent()}
            </CardComponentContainer>
        )
    }

    render() {
        return (
            <div className="challenge-card-component">
                {this.renderComponent()}
            </div>
        )
    }
}

CardComponent.propTypes = {
    component_data: PropTypes.object.isRequired,
    challenge_version: PropTypes.object.isRequired,
    stream_entity: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    is_preview: PropTypes.bool.isRequired,
    is_latest: PropTypes.bool.isRequired,
    getSupportData: PropTypes.func.isRequired,
    getExistingAnswer: PropTypes.func.isRequired,
    refComponent: PropTypes.func
}

export default CardComponent
