import React from 'react';
import {Link} from "react-router-dom";


export default function () {
    return (
        <div className="challenge-preview-layer flex-column flex-vh-center">
            <div className="challenge-preview-layer-text flex-column flex-v-center center-align">
                <div>
                    {`You've finished previewing.`}
                </div>
            </div>

            <div className="section">
            </div>

            <div className="flex-row flex-h-center">
                <Link
                    className="btn btn-large btn-challenge"
                    to={`${window.location.origin}/explore/${challenge_version.challenge_code.code}`}
                    target="_blank"
                >
                    {`Explore More`}
                </Link>
            </div>
        </div>
    );
}
