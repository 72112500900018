import katex from 'katex'


export const convertMath = (str) => {
    if (!str || typeof str !== 'string') return str

    let result = ''

    try {
        result = convertKatexToString(str)
    } catch (error) {
        console.error(error)
        result = '!Error when converting math symbol'
    }

    return result
}

export const convertKatexToString = (str) => {
    if (!str || typeof str !== 'string') return str

    // Replace old <mathjax> tags, better remove this later to increase performance
    str = str.replace(/(<mathjax>)(.*?)(<\/mathjax>)/ig,'\\[$2\\]');

    return str.replace(
        /(\\[\(\[])(.*?)(\\[\)\]])/ig,
        (match, p1, p2, p3, offset, string) => {
            return katex.renderToString(p2, {
                displayMode: true,
                macros: {
                    "\\[": "",
                    "\\]": "",
                    "\\(": "",
                    "\\)": "",
                },
                strict: false
            })
        }
    )
}

export const convertLatexToKatex = (str) => {
    if (!str) {
        return str
    }

    // Replace \pi{} with \pi
    str = str.replace(/(\\pi\{\})/ig, '\\pi ')

    return `\\( ${str} \\)`
}