import React from "react";
import PropTypes from "prop-types";

import UserForm from "./UserForm";
import CreateSchoolForm from "./CreateSchoolForm";
import ConfirmationScreen from "./ConfirmationScreen";
import SchoolsList from "./SchoolsList";

const Content = function ({validation_error_messages, setValidationErrorMessages, mode, setMode}) {
    if ('confirmation' === mode) {
        return <ConfirmationScreen setMode={setMode} setValidationErrorMessages={setValidationErrorMessages}/>
    } else if ('school' === mode) {
        return <CreateSchoolForm
            setMode={setMode}
            validation_error_messages={validation_error_messages}
            setValidationErrorMessages={setValidationErrorMessages}
        />;
    } else if ('schools' === mode) {
        return <SchoolsList setMode={setMode} setValidationErrorMessages={setValidationErrorMessages}/>
    } else {
        return <UserForm
            setMode={setMode}
            validation_error_messages={validation_error_messages}
            setValidationErrorMessages={setValidationErrorMessages}
        />;
    }
}

Content.propTypes = {
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    setMode: PropTypes.func.isRequired
}

export default Content;
