import React from 'react'

import SAMMenuNormal from './SAMMenuNormal'
import MobileNavMenu from "common/MobileNavMenu";


export default class SAMMenuMobile extends SAMMenuNormal {
    renderSideMenu() {
        return (
            <>
                <div className="section">
                    <h4 className="header-new center-align">
                        {`Engagement Dashboard`}
                    </h4>
                    <div className="divider">
                    </div>
                </div>

                <div className="flex-column flex-box-10-10">
                    {this.renderMenuItem('overview')}
                    {this.renderMenuItem('teacher')}
                    {this.renderMenuItem('school')}
                </div>
            </>
        )
    }

    render() {
        return (
            <div className='sam-menu-mobile flex-row flex-v-center'>
                <div className="sam-menu-hamburger-name hide-on-med-and-up">
                    {this.getPageName(this.page_name)}
                </div>
                <MobileNavMenu class_name='hide-on-med-and-up'>
                    {this.renderSideMenu()}
                </MobileNavMenu>
            </div>
        );
    }
}
