import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {groupBy, map, filter, sortBy, get, find} from 'lodash';
import MindsetsJsSDK, {
    LoadChallengeVersionsAction,
    ErrorMessageAction,
    LoadingAction,
    Cookie,
    SetLibraryMeasureAction
} from "mindsets-js-sdk";

import OneProjectExtension from './OneProjectExtension';
import ChallengesFilter from './ChallengesFilter';
import {MEASURE_COOKIE_NAME} from 'constants/index';
import {sortCategories, getCategoryText} from "common/challengeNew/utils";

export default function Challenges() {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const challenge_versions = useSelector(state => state.challenge_versions);
    const [projectExtensions, setProjectExtensions] = useState([]);
    const subject = useSelector(state => state.library_subject);
    const grade = useSelector(state => state.library_grade);
    const theme = useSelector(state => state.library_theme);
    const topic = useSelector(state => state.library_topic);
    const measure = useSelector(state => state.library_measure);
    const [loading, setLoading] = useState(true);

    function getChallengesFromServer() {
        setLoading(true);
        dispatch(LoadChallengeVersionsAction({limit: 0}))
            .catch(error => {
                console.error(error);
                dispatch(ErrorMessageAction(error));
            })
            .then(() => {
                setLoading(false);
                dispatch(LoadingAction(false));
            });
    }

    function setMeasure() {
        let measure_new = 'metric'
        if (!me || !me.country || 'US' === me.country) {
            measure_new = 'imperial';
        }
        const cookie_measure = Cookie.getCookie(MEASURE_COOKIE_NAME);
        if (cookie_measure) {
            measure_new = cookie_measure;
        }
        if (measure_new !== measure) {
            dispatch(SetLibraryMeasureAction(measure_new));
        }
    }

    function filterProjects(projects) {
        return filter(projects, (p) => {
            const challenge_version = p.challenge_version;
            if (!challenge_version.challenge_code.has_pe) {
                return false;
            }
            if ('active' !== challenge_version.challenge_code.status) {
                return false;
            }
            if (challenge_version.challenge_code.subject !== subject) {
                return false;
            }
            if (topic && '' !== topic) {
                if (challenge_version.challenge_code.standards
                    .filter(standard => (me.curriculum_id ? standard.curriculum_id === me.curriculum_id : standard.curriculum_id === 1) && standard.topic === topic)
                    .length <= 0) {
                    return false;
                }
            }
            if (grade && '' !== grade) {
                if (challenge_version.challenge_code.standards
                    .filter(standard => (me.curriculum_id ? standard.curriculum_id === me.curriculum_id : standard.curriculum_id === 1) && standard.grade === grade)
                    .length <= 0) {
                    return false;
                }
            }
            if (theme && '' !== theme) {
                if (challenge_version.challenge_code.themes.indexOf(theme) < 0) {
                    return false;
                }
            }
            if (measure && '' !== measure) {
                if (challenge_version.challenge_code.measure && challenge_version.challenge_code.measure.indexOf(measure) < 0) {
                    return false;
                }
            }

            return true;
        });
    }

    function renderLoader() {
        if (loading) {
            return (
                <div className="">
                    <div className="progress">
                        <div className="indeterminate"/>
                    </div>
                </div>
            );
        }
    }

    function renderProjectExtensions () {
        if (!me) {
            return null;
        }
        if (projectExtensions.length <= 0) {
            return null;
        }

        const grade_translated_challenges = map(challenge_versions, (c) => {
            const challenge_version = JSON.parse(JSON.stringify(c));
            const grade = me.curriculum && challenge_version.challenge_code.grades[me.curriculum.code] ? challenge_version.challenge_code.grades[me.curriculum.code] : challenge_version.challenge_code.grades.default
            challenge_version.grade_translated = getCategoryText(grade);

            return challenge_version;
        })
        const projects_with_challenge = map(projectExtensions, (p) => {
            const challenge_version = find(grade_translated_challenges, (c) => c.challenge_code.code === p.challenge_code);
            return {
                ...p,
                challenge_version
            };
        });
        const filtered_projects = filterProjects(projects_with_challenge);
        if (filtered_projects.length <= 0 && false === loading) {
            return null;
        }
        const ordered_projects = sortBy(filtered_projects, (p) => p.name);
        const categorised_projects = groupBy(ordered_projects, (p) => {
            return get(p.challenge_version, 'grade_translated', 'undefined');
        });

        return map(sortCategories(categorised_projects), (category, grade) => {
            return (
                <div className="section" key={grade}>
                    <h4 className="header-new">
                        {grade}
                    </h4>
                    <div className="row">
                        {
                            category.map((project, k) => {
                                return (
                                    <div className="col" key={k}>
                                        <OneProjectExtension
                                            projectExtension={project}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="divider"/>
                </div>
            );
        });
    }

    useEffect(() => {
        setMeasure();
        if (challenge_versions.length <= 0) {
            getChallengesFromServer();
        }
        if (projectExtensions.length <= 0) {
            MindsetsJsSDK().API.get(`project_extensions`, {}).then((response) => {
                setProjectExtensions(response.projectExtensions);
            });
        }
    }, []);
    useEffect(() => {
        if (me) {
            setMeasure();
            const userChannel = pusher.subscribe('user_' + me.id);
            userChannel.bind('user.updated', getChallengesFromServer);

            return () => {
                userChannel.unbind();
                userChannel.unsubscribe();
            }
        }
    }, [me]);

    return (
        <div>
            {renderLoader()}

            <div className="section">
                <ChallengesFilter/>
            </div>

            {renderProjectExtensions()}
        </div>
    );
}
