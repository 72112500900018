import {map, groupBy} from 'lodash'


export const addWeightageDataRequests = (sheetId, raw_data) => {
    const weightage_category = groupBy(raw_data.skill_structure_data, 'practice_skill_category')
    const rows = []
    map(weightage_category, (category, category_name) => {
        rows.push({
            values: [
                {
                    userEnteredValue: {
                        stringValue: category_name
                    },
                    userEnteredFormat: {
                        textFormat: {
                            bold: true
                        }
                    }
                }
            ]
        })

        const weightage_skill = groupBy(category, (skill) => `${skill.practice_skill_code}: ${skill.practice_skill_name}`)
        map(weightage_skill, (skill, skill_name) => {
            const questions = map(skill, (question) => {
                return {
                    userEnteredValue: {
                        stringValue: `Q${question.number} (${question.max_points} points)`
                    }
                }
            })

            rows.push({
                values: [
                    {
                        userEnteredValue: {
                            stringValue: skill_name
                        }
                    },
                    ...questions
                ]
            })
        })

        rows.push({
            values: [
                {
                    userEnteredValue: {
                        stringValue: ''
                    }
                }
            ]
        })
    })

    return [
        {
            appendCells: {
                sheetId,
                fields: '*',
                rows
            }
        }
    ]
}
