import React from 'react';
import {useSelector} from "react-redux";
import {rolesConstants} from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);
    if (me && me.school && me.persona === rolesConstants.ROLE_TEACHER) {
        return (
            <div className="flex-row user-info-field">
                <div className="user-info-field-label">Zip Code</div>
                <div>{me.school.zip}</div>
            </div>
        );
    }

    return null;
}
