import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {nth} from 'lodash';

import PreviewFrame from './PreviewFrame';
import PreviewFooter from './PreviewFooter';
import PreviewChallengeInfo from './PreviewChallengeInfo';
import Card from 'common/challengeNew/Card';


function Preview({goto}) {
    const challenge_version = useSelector(state => state.challenge_version);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const current_index = useSelector(state => state.challenge_preview_current_index);

    function renderPreviewContent() {
        const current_se = nth(stream_entities, current_index);
        if (challenge_version && current_se) {
            return (
                <div className="challenge-preview-adjustment">
                    <Card
                        challenge_version={challenge_version}
                        stream_entity={current_se}
                        is_preview={true}
                    />
                </div>
            );
        }
    }

    return (
        <div>
            <PreviewFrame goto={goto}>
                {renderPreviewContent()}
            </PreviewFrame>

            <PreviewFooter/>

            <PreviewChallengeInfo challenge_version={challenge_version}/>
        </div>
    );
}

Preview.propTypes = {
    goto: PropTypes.func.isRequired
}

export default Preview
