import {actions, toastActions} from "mindsets-js-sdk";

import HeaderCompiler from "./HeaderCompiler";
import SummaryCompiler from "./SummaryCompiler";
import DataCompiler from "./DataCompiler";
import ByQuestionCompiler from "./ByQuestionCompiler";
import {grantGoogleScopes, handleGoogleError, googleAuthenticate} from 'utils/google';
import {store} from "../../index";

export default class ExportToGoogleDrive {
    constructor() {
        this.headerCompiler = new HeaderCompiler();
        this.summaryCompiler = new SummaryCompiler();
        this.dataCompiler = new DataCompiler();
        this.byQuestionCompiler = new ByQuestionCompiler();
        this.dispatch = store.dispatch;
    }

    execute(title, lesson, streams, challenge_stream_entities) {
        this.title = title;
        this.lesson = lesson;
        this.streams = streams;
        this.challenge_stream_entities = challenge_stream_entities;
        const scopes = ['https://www.googleapis.com/auth/spreadsheets'];

        gapi.load('client', function () {
            gapi.client.init({}).then(function () {
                gapi.client.load('sheets', 'v4');
            });
        });
        googleAuthenticate(scopes, this.dispatch, tokenResponse => {
            if (google.accounts.oauth2.hasGrantedAllScopes(tokenResponse, ...scopes)) {
                this.createSheetRequest();
            } else {
                grantGoogleScopes(scopes, () => this.createSheetRequest(), this.dispatch);
            }
        });
    }

    createSheetRequest() {
        return gapi.client.sheets.spreadsheets.create({
            properties: {
                title: this.title
            }
        }).then((response) => {
            return this.manageSheetsRequest(response.result);
        }, (error) => {
            return handleGoogleError(this.dispatch, error);
        }).catch(error => {
            console.error(error);
        });
    }

    manageSheetsRequest(spreadsheet) {
        return gapi.client.sheets.spreadsheets.batchUpdate({
            spreadsheetId: spreadsheet.spreadsheetId,
            requests: [
                {
                    addSheet: {
                        properties: {
                            title: "Summary"
                        }
                    }
                },
                {
                    addSheet: {
                        properties: {
                            title: "By Student"
                        }
                    }
                },
                {
                    addSheet: {
                        properties: {
                            title: "By Question"
                        }
                    }
                },
                {
                    deleteSheet: {
                        sheetId: 0
                    }
                }
            ]
        }).then((response) => {
            return this.updateSheetRequest(response.result, spreadsheet.spreadsheetUrl);
        }, error => {
            return handleGoogleError(this.dispatch, error);
        });
    }

    updateSheetRequest(spreadsheet, spreadsheetUrl) {
        let summaryRows = [];
        this.headerCompiler.compile(summaryRows, this.lesson, this.streams);
        this.summaryCompiler.compile(summaryRows, this.challenge_stream_entities, this.streams, this.lesson);

        let detailsRows = [];
        this.headerCompiler.compile(detailsRows, this.lesson, this.streams);
        this.dataCompiler.compile(detailsRows, this.streams, this.challenge_stream_entities, this.lesson);

        let byQuestionRows = [];
        this.headerCompiler.compile(byQuestionRows, this.lesson, this.streams);
        this.byQuestionCompiler.compile(byQuestionRows, this.streams, this.challenge_stream_entities, this.lesson);

        let data = [
            {
                range: 'Summary!A1',
                values: summaryRows
            },
            {
                range: 'By Student!A1',
                values: detailsRows
            },
            {
                range: 'By Question!A1',
                values: byQuestionRows
            }
        ];

        gapi.client.sheets.spreadsheets.values.batchUpdate({
            spreadsheetId: spreadsheet.spreadsheetId,
            resource: {
                data: data,
                valueInputOption: 'RAW'
            }
        }).then(() => {
            this.dispatch(actions.LoadingAction(false));
            this.dispatch(toastActions.SuccessMessageAction('The Report has been exported to your Drive. <a href="' + spreadsheetUrl + '" target="_blank">' + spreadsheetUrl + '</a>', true));
        }, error => {
            return handleGoogleError(this.dispatch, error);
        });
    }
}
