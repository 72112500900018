import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Poll from './Poll'


class SpecialComponent extends Component {
    getComponentClass() {
        const type = this.props.component_data.type.toLowerCase()
        switch (type) {
            case 'poll.block':
                return Poll
            default:
                throw 'Unknown component type'
        }
    }

    renderComponentContent() {
        const ComponentClass = this.getComponentClass()
        return (
            <ComponentClass
                component_data={this.props.component_data}
                support_data={this.props.support_data}
                challenge_version={this.props.challenge_version}
                lesson_id={this.props.lesson_id}
                stream_id={this.props.stream_id}
                stream_entity_id={this.props.stream_entity_id}
                is_preview={this.props.is_preview}
                is_latest={this.props.is_latest}
                ref={(c) => this.props.refComponent(c)}
            />
        )
    }

    render() {
        return (
            <div className="challenge-special-component">
                {this.renderComponentContent()}
            </div>
        )
    }
}

SpecialComponent.propTypes = {
    component_data: PropTypes.object.isRequired,
    support_data: PropTypes.object,
    challenge_version: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    stream_entity_id: PropTypes.number,
    is_preview: PropTypes.bool.isRequired,
    is_latest: PropTypes.bool,
    refComponent: PropTypes.func.isRequired
}

export default SpecialComponent
