import React from 'react';
import PropTypes from 'prop-types';
import {rolesConstants} from 'mindsets-js-sdk';


function PersonaSelection({onPersonaSelect}) {
    return (
        <div className="container">
            <div className="flex-row-m flex-h-center">
                <div className="flex-1"/>

                <div className="login-role-selection flex-3">
                    <div className="">
                        <h4 className="header-new center-align">
                            {`Let's get started! What's your role?`}
                        </h4>
                    </div>

                    <div className="flex-row flex-box-5-10">
                        <div className="login-role-selection-container z-depth-1 flex-1"
                             onClick={() => onPersonaSelect(rolesConstants.ROLE_TEACHER)}>
                            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_teacher.svg`}
                                 alt='teacher icon'/>
                            <h2 className="header-new center-align">
                                {`Teacher`}
                            </h2>
                        </div>

                        <div className="login-role-selection-container z-depth-1 flex-1"
                             onClick={() => onPersonaSelect(rolesConstants.ROLE_STUDENT)}>
                            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_student.svg`}
                                 alt='student icon'/>
                            <h2 className="header-new center-align">
                                {`Student`}
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="flex-1"/>
            </div>
        </div>
    )
}

PersonaSelection.propTypes = {
    onPersonaSelect: PropTypes.func.isRequired
};

export default PersonaSelection;
