import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {LoadingAction, SuccessMessageAction} from "mindsets-js-sdk";

import ShareTo from "./ShareTo";

export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);

    function handleError(error) {
        if ('Unauthorized' === error) {
            let uri = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(window.location.origin + '/auth/clever/callback')}&client_id=${process.env.PUBLIC_CLEVER_CLIENT_ID}&state=${encodeURIComponent(window.location.href + '?open=clever')}`
            if (process.env.PUBLIC_CLEVER_TEST_MODE) {
                uri += '&district_id=5b2ad81a709e300001e2cd7a'
            }
            window.location.href = uri;
        }
    }

    function fetchClasses() {
        return new Promise((resolve, reject) => {
            dispatch(LoadingAction(true, 'Retrieving your Classes'));
            MindsetsJsSDK().Api.lesson
                .getCleverClasses()
                .then(response => resolve(response.items))
                .catch(error => {
                    handleError(error);
                    reject(error);
                });
        });
    }

    function fetchStudents(class_id) {
        return new Promise((resolve, reject) => {
            dispatch(LoadingAction(true, 'Loading Class Students'));
            MindsetsJsSDK().Api.lesson
                .getCleverClassStudents(class_id)
                .then(response => resolve(response.items))
                .catch(error => {
                    handleError(error);
                    reject(error);
                });
        });
    }

    function sendAssignment(selected_class) {
        dispatch(SuccessMessageAction(`Mindsets Code (${lesson.code.toUpperCase()}) has been successfully assigned to selected class ${selected_class.name}`, true));
        dispatch(LoadingAction(false));

        return Promise.resolve();
    }

    function isNotReady() {
        return false;
    }

    return (
        <ShareTo
            fetchClasses={fetchClasses}
            fetchStudents={fetchStudents}
            event_prefix={'clever'}
            provider={'clever'}
            isNotReady={isNotReady()}
            sendAssignment={sendAssignment}
        />
    );
}
