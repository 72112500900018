const analyticTeacherLessons = (Analytic) => {
    Analytic.prototype.startAClassCode = function (challenge, class_code) {
        this.track('Start a Class Code', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.resumeToClassCode = function (challenge, class_code) {
        this.track('Resume to Class Code', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code
        })
    }

    Analytic.prototype.shareButtonClicked = function (lesson_id) {
        this.track('Share Button Clicked', {lesson_id});
    }

    Analytic.prototype.shareModalClosed = function (lesson_id) {
        this.track('Share Modal Closed', {lesson_id});
    }

    Analytic.prototype.classroomAssignmentSent = function (lesson_id, provider, provider_id) {
        this.track('Classroom assignment sent', {lesson_id, provider, provider_id});
    }
}

export default analyticTeacherLessons
