import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MindsetsJsSDK, {Toggle} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';

import './toggle_answer_keys_action.scss';

function ToggleAnswerKeysAction(
    {
        lesson,
        answer_key_url,
        bonus_answer_key_url,
        asToggle = false,
        showLinks = false
    }
) {
    const [is_on, setIsOn] = useState(lesson.is_answer_keys_shown);

    function toggleAnswerKeys() {
        if (is_on === lesson.is_answer_keys_shown) {
            setIsOn(!is_on);

            MindsetsJsSDK().API.patch(`lessons/${lesson.id}/answer-keys`)
                .then(() => {
                    analytic.log(
                        'lessonToggleAnswerKeys',
                        lesson.code,
                        !lesson.is_answer_keys_shown
                    );
                })
                .catch((error) => {
                    setIsOn(lesson.is_answer_keys_shown);
                    console.error(error);
                });
        }
    }

    function renderAsButton() {
        const action = is_on ? 'Hide' : 'Show';
        const buttonClass = is_on ? 'fa-eye-slash' : 'fa-eye';

        return (
            <button className="btn btn-on-white" onClick={toggleAnswerKeys}>
                <i className={"fas " + buttonClass}/>
                <span>{action + ` Answer Keys`}</span>
            </button>
        );
    }

    function renderDescription() {
        if (!is_on) {
            return (
                <div className='answer-keys-toggle-sub-text'>
                    <a
                        href='https://fast.wistia.com/embed/channel/sc32gs6ywb?wchannelid=sc32gs6ywb&wmediaid=wj8qyakg4k'
                        target='_blank'
                    >
                        {`Learn more`}
                    </a>
                </div>
            );
        }

        const getDescription = () => {
            if (is_on) {
                return 'Yes. Provide students with Answer Keys at the end of the Challenge and Bonus.'
            } else {
                return "No. Don't show students the Answer Keys."
            }
        }

        return (
            <div className='answer-keys-toggle-sub-text'>
                <span>
                    {getDescription()}
                    {` `}
                </span>
                <a
                    href='https://fast.wistia.com/embed/channel/sc32gs6ywb?wchannelid=sc32gs6ywb&wmediaid=wj8qyakg4k'
                    target='_blank'
                >
                    {`Learn more.`}
                </a>
            </div>
        )
    }

    function renderAsToggle() {
        return (
            <div className="answer-keys-toggle">
                <div className='flex-row flex-v-center'>
                    <i className="action-icon fas fa-key mr-2"/>
                    <div className="flex-row flex-h-start flex-v-center">
                        <div className='answer-keys-toggle-text'>Show Answer Keys</div>
                    </div>
                    <div className="flex-auto"></div>
                    <Toggle onChange={toggleAnswerKeys} is_on={is_on}/>
                    <div className="answer-keys-toggle-value">
                        {is_on ? 'Yes' : 'No'}
                    </div>
                </div>

                {renderDescription()}

                {renderLinks()}
            </div>
        );
    }

    function renderLinks() {
        if (showLinks && is_on) {
            return (
                <div className='akt-links'>
                    <div className='akt-links-title'>
                        {`View:`}
                    </div>
                    <div className='akt-links-links'>
                        <a className="underline" href={answer_key_url} target='_blank'>
                            {`Answer Key`}
                        </a>
                        <span className='mx-2'>|</span>
                        <a className="underline" href={bonus_answer_key_url} target='_blank'>
                            {`Answer Key - Bonus`}
                        </a>
                    </div>
                </div>
            );
        }

        return null;
    }

    useEffect(() => {
        setIsOn(lesson.is_answer_keys_shown);
    }, [lesson.is_answer_keys_shown]);

    if (answer_key_url && bonus_answer_key_url) {
        if (asToggle) {
            return renderAsToggle();
        } else {
            return renderAsButton();
        }
    }

    return null;
}

ToggleAnswerKeysAction.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        is_answer_keys_shown: PropTypes.bool.isRequired
    }).isRequired,
    answer_key_url: PropTypes.string,
    bonus_answer_key_url: PropTypes.string,
    asToggle: PropTypes.bool,
    showLinks: PropTypes.bool
}

export default ToggleAnswerKeysAction;
