import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {ErrorMessageAction} from "mindsets-js-sdk";


function ButtonCopyText(
    {
        text_to_copy,
        button_class_name = 'btn btn-challenge',
        whenClick = () => {
        }
    }
) {
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);

    function onClick() {
        whenClick();

        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textarea = document.createElement("textarea");
            textarea.textContent = text_to_copy;
            textarea.style.position = "fixed";
            textarea.style.display = "hidden";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                const copied = document.execCommand("copy");
                if (copied) {
                    setCopied(!!copied)
                } else {
                    throw "failed";
                }

                return copied;
            } catch (ex) {
                dispatch(ErrorMessageAction("Copy to clipboard failed"));
                console.error(ex);

                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        } else {
            dispatch(ErrorMessageAction("Copy to clipboard failed"));
            console.error('queryCommandSupported is unsupported');
        }
    }

    function getButtonLabel() {
        return copied ? 'copied' : 'copy';
    }

    return (
        <button className={button_class_name} onClick={onClick}>
            <i className="far fa-copy"/>
            <span>{getButtonLabel()}</span>
        </button>
    );
}

ButtonCopyText.propTypes = {
    text_to_copy: PropTypes.string.isRequired,
    button_class_name: PropTypes.string,
    whenClick: PropTypes.func
}

export default ButtonCopyText
