import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";

import MultipleSelectFilter from "./MultipleSelectFilter";
import SingleSelectFilter from "./SingleSelectFilter";
import DateFilter from "./DateFilter";
import {
    LoadInsightsDashboardFilterDependenciesAction,
    SetDashboardFiltersAction
} from "../../../actions/performance_actions";
import {default_filters} from "../../../reducers/performance_reducers";

export const FILTERS_MAP = {
    impact_study: {
        filter_label: 'Impact Study',
        type: 'single',
        default_label: 'All Studies',
    },
    subject: {
        filter_label: 'Subject',
        type: 'single',
        default_label: 'All Subjects'
    },
    class_grade: {
        filter_label: 'Grade Level',
        type: 'multiple',
        default_label: 'All Grades'
    },
    school_id: {
        filter_label: 'School',
        type: 'multiple',
        default_label: 'All Schools',
        useSearch: true
    },
    challenge_code: {
        filter_label: 'Mindsets Challenge',
        type: 'multiple',
        default_label: 'All Challenges'
    },
    teacher_id: {
        filter_label: 'Teacher Name',
        type: 'multiple',
        default_label: 'All Teachers',
        useSearch: true
    },
    achievement_level: {
        filter_label: 'Achievement Level',
        type: 'multiple',
        default_label: 'All Levels'
    },
    practice_skill_id: {
        filter_label: 'Skill',
        type: 'multiple',
        default_label: 'All Skills',
    },
    student_id: {
        filter_label: 'Student Name',
        type: 'multiple',
        default_label: 'All Students',
        useSearch: true
    },
    class_date: {
        filter_label: 'Class Date',
        type: 'date_range',
        default_label: 'Any Date'
    }
};

export const DASHBOARD_TYPE_FILTERS = {
    'TYPE 1': [
        'impact_study',
        'subject',
        'class_grade',
        'school_id',
        'challenge_code',
        'teacher_id',
        'practice_skill_id',
        'student_id',
        'achievement_level',
        // 'class_date'
    ],
    'TYPE 2': [
        'impact_study',
        'subject',
        'class_grade',
        'school_id',
        'challenge_code',
        'teacher_id',
        'practice_skill_id'
    ],
    'TYPE 3': [
        'class_grade',
        'school_id'
    ],
}

export default function ({id}) {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const dashboards = useSelector(state => state.dashboards);
    const dashboard_filters = useSelector(state => state.dashboard_filters);
    let dashboard = dashboards.find(dashboard => dashboard.id.toString() === params.dashboard_id.toString()) || {};
    if (!Object.keys(dashboard).length) {
        dashboard = useSelector(state => state.insights_dashboard);
    }

    useEffect(() => {
        const options = {};
        if (dashboard.id === 'mindsets_demo') {
            options.state = 'MINDSETS';
        } else if (me.is_dashboard_super_admin && searchParams.has('state')) {
            options.state = searchParams.get('state');
        }
        DASHBOARD_TYPE_FILTERS[dashboard.type].map((filter_key, index) => {
            if (filter_key !== 'class_date') {
                options['filters[' + index + ']'] = filter_key;
            }
        });
        dispatch(LoadInsightsDashboardFilterDependenciesAction(params.dashboard_id.includes('demo') ? 0 : params.dashboard_id, options))
    }, []);

    if (!Object.keys(dashboard).length || dashboard.id.toString() !== params.dashboard_id.toString()) {
        return null;
    }

    return (
        <div id='insights-filters'>
            <div className="flex-row flex-gap-10 flex-v-end flex-wrap">
                {DASHBOARD_TYPE_FILTERS[dashboard.type].map(filter_key => {
                    if (FILTERS_MAP[filter_key].type === 'multiple') {
                        return (
                            <div key={filter_key}>
                                <label>{FILTERS_MAP[filter_key].filter_label}</label>
                                <MultipleSelectFilter filter_key={filter_key} id={id}/>
                            </div>
                        );
                    } else if (FILTERS_MAP[filter_key].type === 'date_range') {
                        return (
                            <div key={filter_key}>
                                <label>{FILTERS_MAP[filter_key].filter_label}</label>
                                <DateFilter filter_key={filter_key} id={id}/>
                            </div>
                        );
                    } else if (FILTERS_MAP[filter_key].type === 'single') {
                        return (
                            <div key={filter_key}>
                                <label>{FILTERS_MAP[filter_key].filter_label}</label>
                                <SingleSelectFilter filter_key={filter_key} id={id}/>
                            </div>
                        );
                    } else {
                        throw 'Unsupported filter';
                    }
                })}
                <a href={'#!'} onClick={event => {
                    event.preventDefault();
                    const dashboard_filters_x = JSON.parse(JSON.stringify(dashboard_filters));
                    dashboard_filters_x[id] = JSON.parse(JSON.stringify(default_filters));
                    dispatch(SetDashboardFiltersAction(dashboard_filters_x));
                }}>
                    Reset all filters
                </a>
            </div>
        </div>
    );
}
