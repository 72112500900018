import {keys, get, mapKeys, mapValues, upperFirst} from 'lodash'
import {store} from '../index';


export const convertSpelling = (str) => {
    if (needToConvertSpelling()) {
        return str
            .replace(regexp_us_to_uk, (m) => get(US_TO_UK_SPELLING, m, m))
            .replace(regexp_us_to_uk_upper, (m) => get(US_TO_UK_SPELLING_UPPER, m, m))
    } else {
        return str
    }
}

const needToConvertSpelling = () => {
    if (!process.env.FEATURE_SWITCH_SPELLING) {
        return false
    }

    const country_user = store.getState().me ? store.getState().me.country : null;
    const country_stream = store.getState().stream ? store.getState().stream.country : null;
    return (
        country_user && !US_SPELLING_COUNTRIES.includes(country_user)
        ||
        country_stream && !US_SPELLING_COUNTRIES.includes(country_stream)
    )
}

const US_SPELLING_COUNTRIES = [
    'US', 'BR', 'MX', 'VE', 'LR', 'CN', 'JP', 'KR', 'PH', 'IL', 'SA', 'KW', 'JO', 'IQ'
]

const US_TO_UK_SPELLING = {
    "accessorize": "accessorise",
    "accessorized": "accessorised",
    "accessorizes": "accessorises",
    "accessorizing": "accessorising",
    "acclimatization": "acclimatisation",
    "acclimatize": "acclimatise",
    "acclimatized": "acclimatised",
    "acclimatizes": "acclimatises",
    "acclimatizing": "acclimatising",
    "airplane": "aeroplane",
    "airplanes": "aeroplanes",
    "esthetic": "aesthetic",
    "esthetically": "aesthetically",
    "esthetics": "aesthetics",
    "aluminum": "aluminium",
    "amortizable": "amortisable",
    "amortization": "amortisation",
    "amortizations": "amortisations",
    "amortize": "amortise",
    "amortized": "amortised",
    "amortizes": "amortises",
    "amortizing": "amortising",
    "amphitheater": "amphitheatre",
    "amphitheaters": "amphitheatres",
    "analog": "analogue",
    "analogs": "analogues",
    "analyze": "analyse",
    "analyzed": "analysed",
    "analyzes": "analyses",
    "analyzing": "analysing",
    "annualized": "annualised",
    "apologize": "apologise",
    "apologized": "apologised",
    "apologizes": "apologises",
    "apologizing": "apologising",
    "appetizer": "appetiser",
    "appetizers": "appetisers",
    "appetizing": "appetising",
    "appetizingly": "appetisingly",
    "arbor": "arbour",
    "arbors": "arbours",
    "archeological": "archaeological",
    "archeologically": "archaeologically",
    "archeologist": "archaeologist",
    "archeologists": "archaeologists",
    "archeology": "archaeology",
    "artifact": "artefact",
    "artifacts": "artefacts",
    "authorize": "authorise",
    "authorized": "authorised",
    "authorizes": "authorises",
    "authorizing": "authorising",
    "behavior": "behaviour",
    "behavioral": "behavioural",
    "behaviors": "behaviours",
    "biased": "biassed",
    "biasing": "biassing",
    "binging": "bingeing",
    "canceled": "cancelled",
    "canceling": "cancelling",
    "capitalize": "capitalise",
    "capitalized": "capitalised",
    "capitalizes": "capitalises",
    "capitalizing": "capitalising",
    "caramelize": "caramelise",
    "caramelized": "caramelised",
    "caramelizes": "caramelises",
    "caramelizing": "caramelising",
    "carbonize": "carbonise",
    "carbonized": "carbonised",
    "carbonizes": "carbonises",
    "carbonizing": "carbonising",
    "caroled": "carolled",
    "caroling": "carolling",
    "catalog": "catalogue",
    "cataloged": "catalogued",
    "catalogs": "catalogues",
    "cataloging": "cataloguing",
    "catalyze": "catalyse",
    "catalyzed": "catalysed",
    "catalyzes": "catalyses",
    "catalyzing": "catalysing",
    "categorize": "categorise",
    "categorized": "categorised",
    "categorizes": "categorises",
    "categorizing": "categorising",
    "centiliter": "centilitre",
    "centiliters": "centilitres",
    "centimeter": "centimetre",
    "centimeters": "centimetres",
    "centralize": "centralise",
    "centralized": "centralised",
    "centralizes": "centralises",
    "centralizing": "centralising",
    "center": "centre",
    "centered": "centred",
    "centerfold": "centrefold",
    "centerfolds": "centrefolds",
    "centerpiece": "centrepiece",
    "centerpieces": "centrepieces",
    "centers": "centres",
    "channeled": "channelled",
    "channeling": "channelling",
    "characterize": "characterise",
    "characterized": "characterised",
    "characterizes": "characterises",
    "characterizing": "characterising",
    "checkbook": "chequebook",
    "checkbooks": "chequebooks",
    "chili": "chilli",
    "color": "colour",
    "colorant": "colourant",
    "colorants": "colourants",
    "colored": "coloured",
    "coloreds": "coloureds",
    "colorful": "colourful",
    "colorfully": "colourfully",
    "coloring": "colouring",
    "colorize": "colourize",
    "colorized": "colourized",
    "colorizes": "colourizes",
    "colorizing": "colourizing",
    "colorless": "colourless",
    "colors": "colours",
    "commercialize": "commercialise",
    "commercialized": "commercialised",
    "commercializes": "commercialises",
    "commercializing": "commercialising",
    "compartmentalize": "compartmentalise",
    "compartmentalized": "compartmentalised",
    "compartmentalizes": "compartmentalises",
    "compartmentalizing": "compartmentalising",
    "computerize": "computerise",
    "computerized": "computerised",
    "computerizes": "computerises",
    "computerizing": "computerising",
    "conceptualize": "conceptualise",
    "conceptualized": "conceptualised",
    "conceptualizes": "conceptualises",
    "conceptualizing": "conceptualising",
    "contextualize": "contextualise",
    "contextualized": "contextualised",
    "contextualizes": "contextualises",
    "contextualizing": "contextualising",
    "cozier": "cosier",
    "coziest": "cosiest",
    "cozily": "cosily",
    "coziness": "cosiness",
    "cozy": "cosy",
    "councilor": "councillor",
    "councilors": "councillors",
    "counseled": "counselled",
    "counseling": "counselling",
    "counselor": "counsellor",
    "counselors": "counsellors",
    "criticize": "criticise",
    "criticized": "criticised",
    "criticizes": "criticises",
    "criticizing": "criticising",
    "crystallization": "crystallisation",
    "crystallize": "crystallise",
    "crystallized": "crystallised",
    "crystallizes": "crystallises",
    "crystallizing": "crystallising",
    "customize": "customise",
    "customized": "customised",
    "customizes": "customises",
    "customizing": "customising",
    "defense": "defence",
    "defenseless": "defenceless",
    "defenses": "defences",
    "destabilization": "destabilisation",
    "destabilize": "destabilise",
    "destabilized": "destabilised",
    "destabilizes": "destabilises",
    "destabilizing": "destabilising",
    "dialed": "dialled",
    "dialing": "dialling",
    "dialog": "dialogue",
    "dialogs": "dialogues",
    "digitize": "digitise",
    "digitized": "digitised",
    "digitizes": "digitises",
    "digitizing": "digitising",
    "disk": "disc",
    "discolor": "discolour",
    "discolored": "discoloured",
    "discoloring": "discolouring",
    "discolors": "discolours",
    "disks": "discs",
    "disorganization": "disorganisation",
    "disorganized": "disorganised",
    "distill": "distil",
    "distills": "distils",
    "emphasize": "emphasise",
    "emphasized": "emphasised",
    "emphasizes": "emphasises",
    "emphasizing": "emphasising",
    "energize": "energise",
    "energized": "energised",
    "energizes": "energises",
    "energizing": "energising",
    "enroll": "enrol",
    "enrolls": "enrols",
    "equalization": "equalisation",
    "equalize": "equalise",
    "equalized": "equalised",
    "equalizer": "equaliser",
    "equalizers": "equalisers",
    "equalizes": "equalises",
    "equalizing": "equalising",
    "externalization": "externalisation",
    "externalizations": "externalisations",
    "externalize": "externalise",
    "externalized": "externalised",
    "externalizes": "externalises",
    "externalizing": "externalising",
    "factorize": "factorise",
    "factorized": "factorised",
    "factorizes": "factorises",
    "factorizing": "factorising",
    "familiarization": "familiarisation",
    "familiarize": "familiarise",
    "familiarized": "familiarised",
    "familiarizes": "familiarises",
    "familiarizing": "familiarising",
    "favor": "favour",
    "favorable": "favourable",
    "favorably": "favourably",
    "favored": "favoured",
    "favoring": "favouring",
    "favorite": "favourite",
    "favorites": "favourites",
    "favors": "favours",
    "fertilization": "fertilisation",
    "fertilize": "fertilise",
    "fertilized": "fertilised",
    "fertilizer": "fertiliser",
    "fertilizers": "fertilisers",
    "fertilizes": "fertilises",
    "fertilizing": "fertilising",
    "fiber": "fibre",
    "fibers": "fibres",
    "finalization": "finalisation",
    "finalize": "finalise",
    "finalized": "finalised",
    "finalizes": "finalises",
    "finalizing": "finalising",
    "flavor": "flavour",
    "flavored": "flavoured",
    "flavoring": "flavouring",
    "flavorings": "flavourings",
    "flavorless": "flavourless",
    "flavors": "flavours",
    "formalization": "formalisation",
    "formalize": "formalise",
    "formalized": "formalised",
    "formalizes": "formalises",
    "formalizing": "formalising",
    "fossilization": "fossilisation",
    "fossilize": "fossilise",
    "fossilized": "fossilised",
    "fossilizes": "fossilises",
    "fossilizing": "fossilising",
    "fulfill": "fulfil",
    "fulfillment": "fulfilment",
    "fulfills": "fulfils",
    "funneled": "funnelled",
    "funneling": "funnelling",
    "galvanize": "galvanise",
    "galvanized": "galvanised",
    "galvanizes": "galvanises",
    "generalization": "generalisation",
    "generalizations": "generalisations",
    "generalize": "generalise",
    "generalized": "generalised",
    "generalizes": "generalises",
    "generalizing": "generalising",
    "glamor": "glamour",
    "globalization": "globalisation",
    "globalize": "globalise",
    "globalized": "globalised",
    "globalizes": "globalises",
    "globalizing": "globalising",
    "gluing": "glueing",
    "gray": "grey",
    "grayed": "greyed",
    "graying": "greying",
    "grayish": "greyish",
    "grayness": "greyness",
    "grays": "greys",
    "honor": "honour",
    "honorable": "honourable",
    "honorably": "honourably",
    "honored": "honoured",
    "honoring": "honouring",
    "honors": "honours",
    "hypothesize": "hypothesise",
    "hypothesized": "hypothesised",
    "hypothesizes": "hypothesises",
    "hypothesizing": "hypothesising",
    "idealization": "idealisation",
    "idealize": "idealise",
    "idealized": "idealised",
    "idealizes": "idealises",
    "idealizing": "idealising",
    "immobilization": "immobilisation",
    "immobilize": "immobilise",
    "immobilized": "immobilised",
    "immobilizer": "immobiliser",
    "immobilizers": "immobilisers",
    "immobilizes": "immobilises",
    "immobilizing": "immobilising",
    "immortalize": "immortalise",
    "immortalized": "immortalised",
    "immortalizes": "immortalises",
    "immortalizing": "immortalising",
    "immunization": "immunisation",
    "immunize": "immunise",
    "immunized": "immunised",
    "immunizes": "immunises",
    "immunizing": "immunising",
    "individualize": "individualise",
    "individualized": "individualised",
    "individualizes": "individualises",
    "individualizing": "individualising",
    "industrialize": "industrialise",
    "industrialized": "industrialised",
    "industrializes": "industrialises",
    "industrializing": "industrialising",
    "initialize": "initialise",
    "initialized": "initialised",
    "initializes": "initialises",
    "initializing": "initialising",
    "initialed": "initialled",
    "initialing": "initialling",
    "install": "instal",
    "installment": "instalment",
    "installments": "instalments",
    "installs": "instals",
    "internalization": "internalisation",
    "internalize": "internalise",
    "internalized": "internalised",
    "internalizes": "internalises",
    "internalizing": "internalising",
    "internationalization": "internationalisation",
    "internationalize": "internationalise",
    "internationalized": "internationalised",
    "internationalizes": "internationalises",
    "internationalizing": "internationalising",
    "ionization": "ionisation",
    "ionize": "ionise",
    "ionized": "ionised",
    "ionizer": "ioniser",
    "ionizers": "ionisers",
    "ionizes": "ionises",
    "ionizing": "ionising",
    "italicize": "italicise",
    "italicized": "italicised",
    "italicizes": "italicises",
    "italicizing": "italicising",
    "itemize": "itemise",
    "itemized": "itemised",
    "itemizes": "itemises",
    "itemizing": "itemising",
    "jeweled": "jewelled",
    "jeweler": "jeweller",
    "jewelers": "jewellers",
    "jewelry": "jewellery",
    "judgment": "judgement",
    "kilometer": "kilometre",
    "kilometers": "kilometres",
    "labeled": "labelled",
    "labeling": "labelling",
    "labor": "labour",
    "labored": "laboured",
    "laborer": "labourer",
    "laborers": "labourers",
    "laboring": "labouring",
    "labors": "labours",
    "leveled": "levelled",
    "leveler": "leveller",
    "levelers": "levellers",
    "leveling": "levelling",
    "license": "licence",
    "licensed": "licenced",
    "licenses": "licences",
    "licensing": "licencing",
    "likable": "likeable",
    "liquidize": "liquidise",
    "liquidized": "liquidised",
    "liter": "litre",
    "liters": "litres",
    "localize": "localise",
    "localized": "localised",
    "localizes": "localises",
    "localizing": "localising",
    "magnetize": "magnetise",
    "magnetized": "magnetised",
    "magnetizes": "magnetises",
    "magnetizing": "magnetising",
    "maneuverability": "manoeuvrability",
    "maneuverable": "manoeuvrable",
    "maneuver": "manoeuvre",
    "maneuvered": "manoeuvred",
    "maneuvers": "manoeuvres",
    "maneuvering": "manoeuvring",
    "maneuverings": "manoeuvrings",
    "marvelous": "marvellous",
    "marvelously": "marvellously",
    "materialization": "materialisation",
    "materialize": "materialise",
    "materialized": "materialised",
    "materializes": "materialises",
    "materializing": "materialising",
    "maximization": "maximisation",
    "maximize": "maximise",
    "maximized": "maximised",
    "maximizes": "maximises",
    "maximizing": "maximising",
    "mechanization": "mechanisation",
    "mechanize": "mechanise",
    "mechanized": "mechanised",
    "mechanizes": "mechanises",
    "mechanizing": "mechanising",
    "memorialize": "memorialise",
    "memorialized": "memorialised",
    "memorializes": "memorialises",
    "memorializing": "memorialising",
    "memorize": "memorise",
    "memorized": "memorised",
    "memorizes": "memorises",
    "memorizing": "memorising",
    "metabolize": "metabolise",
    "metabolized": "metabolised",
    "metabolizes": "metabolises",
    "metabolizing": "metabolising",
    "meter": "metre",
    "meters": "metres",
    "micrometer": "micrometre",
    "micrometers": "micrometres",
    "milliliter": "millilitre",
    "milliliters": "millilitres",
    "millimeter": "millimetre",
    "millimeters": "millimetres",
    "miniaturization": "miniaturisation",
    "miniaturize": "miniaturise",
    "miniaturized": "miniaturised",
    "miniaturizes": "miniaturises",
    "miniaturizing": "miniaturising",
    "minibusses": "minibuses",
    "minimize": "minimise",
    "minimized": "minimised",
    "minimizes": "minimises",
    "minimizing": "minimising",
    "miter": "mitre",
    "miters": "mitres",
    "mobilization": "mobilisation",
    "mobilize": "mobilise",
    "mobilized": "mobilised",
    "mobilizes": "mobilises",
    "mobilizing": "mobilising",
    "modeled": "modelled",
    "modeler": "modeller",
    "modelers": "modellers",
    "modeling": "modelling",
    "modernize": "modernise",
    "modernized": "modernised",
    "modernizes": "modernises",
    "motorized": "motorised",
    "mold": "mould",
    "molded": "moulded",
    "molds": "moulds",
    "moldy": "mouldy",
    "multicolored": "multicoloured",
    "naturalization": "naturalisation",
    "naturalize": "naturalise",
    "naturalized": "naturalised",
    "naturalizes": "naturalises",
    "naturalizing": "naturalising",
    "neighbor": "neighbour",
    "neighborhood": "neighbourhood",
    "neighborhoods": "neighbourhoods",
    "neighboring": "neighbouring",
    "neighbors": "neighbours",
    "neutralization": "neutralisation",
    "neutralize": "neutralise",
    "neutralized": "neutralised",
    "neutralizes": "neutralises",
    "neutralizing": "neutralising",
    "normalization": "normalisation",
    "normalize": "normalise",
    "normalized": "normalised",
    "normalizes": "normalises",
    "normalizing": "normalising",
    "odor": "odour",
    "odorless": "odourless",
    "odors": "odours",
    "omelet": "omelette",
    "omelets": "omelettes",
    "optimize": "optimise",
    "optimized": "optimised",
    "optimizes": "optimises",
    "optimizing": "optimising",
    "organization": "organisation",
    "organizational": "organisational",
    "organizations": "organisations",
    "organize": "organise",
    "organized": "organised",
    "organizer": "organiser",
    "organizers": "organisers",
    "organizes": "organises",
    "organizing": "organising",
    "overemphasize": "overemphasise",
    "overemphasized": "overemphasised",
    "overemphasizes": "overemphasises",
    "overemphasizing": "overemphasising",
    "oxidization": "oxidisation",
    "oxidize": "oxidise",
    "oxidized": "oxidised",
    "oxidizes": "oxidises",
    "oxidizing": "oxidising",
    "paneled": "panelled",
    "paneling": "panelling",
    "parceled": "parcelled",
    "parceling": "parcelling",
    "parlor": "parlour",
    "parlors": "parlours",
    "pasteurization": "pasteurisation",
    "pasteurize": "pasteurise",
    "pasteurized": "pasteurised",
    "pasteurizes": "pasteurises",
    "pasteurizing": "pasteurising",
    "pedaled": "pedalled",
    "pedaling": "pedalling",
    "personalize": "personalise",
    "personalized": "personalised",
    "personalizes": "personalises",
    "personalizing": "personalising",
    "plow": "plough",
    "plowed": "ploughed",
    "plowing": "ploughing",
    "plows": "ploughs",
    "polarization": "polarisation",
    "polarize": "polarise",
    "polarized": "polarised",
    "polarizes": "polarises",
    "polarizing": "polarising",
    "popularization": "popularisation",
    "popularize": "popularise",
    "popularized": "popularised",
    "popularizes": "popularises",
    "popularizing": "popularising",
    "practice": "practise",
    "practiced": "practised",
    "practices": "practises",
    "practicing": "practising",
    "pressurization": "pressurisation",
    "pressurize": "pressurise",
    "pressurized": "pressurised",
    "pressurizes": "pressurises",
    "pressurizing": "pressurising",
    "prioritization": "prioritisation",
    "prioritize": "prioritise",
    "prioritized": "prioritised",
    "prioritizes": "prioritises",
    "prioritizing": "prioritising",
    "program": "programme",
    "programs": "programmes",
    "publicize": "publicise",
    "publicized": "publicised",
    "publicizes": "publicises",
    "publicizing": "publicising",
    "pulverization": "pulverisation",
    "pulverize": "pulverise",
    "pulverized": "pulverised",
    "pulverizes": "pulverises",
    "pulverizing": "pulverising",
    "pajama": "pyjama",
    "pajamas": "pyjamas",
    "randomize": "randomise",
    "randomized": "randomised",
    "randomizes": "randomises",
    "randomizing": "randomising",
    "rationalization": "rationalisation",
    "rationalizations": "rationalisations",
    "rationalize": "rationalise",
    "rationalized": "rationalised",
    "rationalizes": "rationalises",
    "rationalizing": "rationalising",
    "realizable": "realisable",
    "realization": "realisation",
    "realizations": "realisations",
    "realize": "realise",
    "realized": "realised",
    "realizes": "realises",
    "realizing": "realising",
    "recognizable": "recognisable",
    "recognizably": "recognisably",
    "recognizance": "recognisance",
    "recognize": "recognise",
    "recognized": "recognised",
    "recognizes": "recognises",
    "recognizing": "recognising",
    "refueled": "refuelled",
    "refueling": "refuelling",
    "remodeled": "remodelled",
    "remodeling": "remodelling",
    "reorganization": "reorganisation",
    "reorganizations": "reorganisations",
    "reorganize": "reorganise",
    "reorganized": "reorganised",
    "reorganizes": "reorganises",
    "reorganizing": "reorganising",
    "revolutionize": "revolutionise",
    "revolutionized": "revolutionised",
    "revolutionizes": "revolutionises",
    "revolutionizing": "revolutionising",
    "rigor": "rigour",
    "rigors": "rigours",
    "rivaled": "rivalled",
    "rivaling": "rivalling",
    "rumor": "rumour",
    "rumored": "rumoured",
    "rumors": "rumours",
    "sanitize": "sanitise",
    "sanitized": "sanitised",
    "sanitizes": "sanitises",
    "sanitizing": "sanitising",
    "shoveled": "shovelled",
    "shoveling": "shovelling",
    "signaled": "signalled",
    "signaling": "signalling",
    "snorkeled": "snorkelled",
    "snorkeling": "snorkelling",
    "snowplow": "snowplough",
    "snowplows": "snowploughs",
    "socialize": "socialise",
    "socialized": "socialised",
    "socializes": "socialises",
    "socializing": "socialising",
    "specialization": "specialisation",
    "specializations": "specialisations",
    "specialize": "specialise",
    "specialized": "specialised",
    "specializes": "specialises",
    "specializing": "specialising",
    "spiraled": "spiralled",
    "spiraling": "spiralling",
    "stabilization": "stabilisation",
    "stabilize": "stabilise",
    "stabilized": "stabilised",
    "stabilizer": "stabiliser",
    "stabilizers": "stabilisers",
    "stabilizes": "stabilises",
    "stabilizing": "stabilising",
    "standardization": "standardisation",
    "standardize": "standardise",
    "standardized": "standardised",
    "standardizes": "standardises",
    "standardizing": "standardising",
    "stenciled": "stencilled",
    "stenciling": "stencilling",
    "sterilize": "sterilise",
    "sterilized": "sterilised",
    "sterilizer": "steriliser",
    "sterilizers": "sterilisers",
    "sterilizes": "sterilises",
    "sterilizing": "sterilising",
    "stigmatization": "stigmatisation",
    "sulfate": "sulphate",
    "sulfates": "sulphates",
    "sulfide": "sulphide",
    "sulfides": "sulphides",
    "sulfur": "sulphur",
    "sulfurous": "sulphurous",
    "summarize": "summarise",
    "summarized": "summarised",
    "summarizes": "summarises",
    "summarizing": "summarising",
    "swiveled": "swivelled",
    "swiveling": "swivelling",
    "symbolize": "symbolise",
    "symbolized": "symbolised",
    "symbolizes": "symbolises",
    "symbolizing": "symbolising",
    "synchronization": "synchronisation",
    "synchronize": "synchronise",
    "synchronized": "synchronised",
    "synchronizes": "synchronises",
    "synchronizing": "synchronising",
    "synthesize": "synthesise",
    "synthesized": "synthesised",
    "synthesizer": "synthesiser",
    "synthesizers": "synthesisers",
    "synthesizes": "synthesises",
    "synthesizing": "synthesising",
    "theater": "theatre",
    "theaters": "theatres",
    "traveled": "travelled",
    "traveler": "traveller",
    "travelers": "travellers",
    "traveling": "travelling",
    "trialed": "trialled",
    "trialing": "trialling",
    "tunneled": "tunnelled",
    "tunneling": "tunnelling",
    "unequaled": "unequalled",
    "unfavorable": "unfavourable",
    "unfavorably": "unfavourably",
    "unorganized": "unorganised",
    "unrecognizable": "unrecognisable",
    "unrecognized": "unrecognised",
    "unrivaled": "unrivalled",
    "urbanization": "urbanisation",
    "urbanize": "urbanise",
    "urbanized": "urbanised",
    "utilization": "utilisation",
    "utilize": "utilise",
    "utilized": "utilised",
    "utilizes": "utilises",
    "utilizing": "utilising",
    "vaporization": "vaporisation",
    "vaporize": "vaporise",
    "vaporized": "vaporised",
    "vaporizes": "vaporises",
    "vaporizing": "vaporising",
    "vapor": "vapour",
    "vapors": "vapours",
    "visualization": "visualisation",
    "visualizations": "visualisations",
    "visualize": "visualise",
    "visualized": "visualised",
    "visualizes": "visualises",
    "visualizing": "visualising",
    "vulcanized": "vulcanised",
    "woolen": "woollen",
    "wooly": "woolly",
    "yogurt": "yoghourt",
    "yogurts": "yoghourts",
}

const regexp_us_to_uk = new RegExp(keys(US_TO_UK_SPELLING).join('|'), 'g')

const US_TO_UK_SPELLING_UPPER = mapKeys(
    mapValues(US_TO_UK_SPELLING, (v, k) => upperFirst(v)),
    (v, k) => upperFirst(k)
)

const regexp_us_to_uk_upper = new RegExp(keys(US_TO_UK_SPELLING_UPPER).join('|'), 'g')
