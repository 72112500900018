import React from 'react';
import PropTypes from "prop-types";

import "./mobile_nav_menu.scss";

const MobileNavMenu = function ({class_name, children}) {
    const mobileMenuContent = React.createRef();
    const onClick = function () {
        const content = mobileMenuContent.current;
        if (!content.classList.contains('open')) {
            const overlay = document.createElement('div');
            overlay.id = 'mobile-nav-overlay';
            overlay.addEventListener('click', () => {
                content.classList.remove('open');
                content.style.transform = 'translateX(100%)';
                const element = document.getElementById("mobile-nav-overlay");
                element.parentNode.removeChild(element);
            });
            content.addEventListener('click', () => {
                content.classList.remove('open');
                content.style.transform = 'translateX(100%)';
                const element = document.getElementById("mobile-nav-overlay");
                if (element && element.parentNode) {
                    element.parentNode.removeChild(element);
                }
            });
            document.body.appendChild(overlay);
            content.classList.add('open');
            content.style.transform = 'translateX(0px)';
        }
    }

    return (
        <div className={'mobile-nav-menu ' + class_name}>
            <a href={"#"} onClick={event => {
                event.preventDefault();
                onClick();
                event.stopPropagation();
            }} className='mobile-nav-menu-trigger'>
                <i className="fas fa-bars"/>
            </a>
            <div className="mobile-nav-menu-content" ref={mobileMenuContent}>
                {children}
            </div>
        </div>
    );
}

MobileNavMenu.propTypes = {
    class_name: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
}

export default MobileNavMenu;
