import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SetStudentStoryPageIndexAction} from "../../actions/student_story_actions";

export default function () {
    const dispatch = useDispatch();
    const index = useSelector(state => state.student_story_page_index);

    if (index > 0) {
        return (
            <div className='student-story-progress-bar'>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(i => {
                    return (
                        <div
                            key={i}
                            className={'student-story-progress-bar-item' + (i === index ? ' active' : '')}
                            onClick={() => {
                                dispatch(SetStudentStoryPageIndexAction(i));
                            }}
                        />
                    );
                })}
            </div>
        );
    }

    return null;
}