import React from 'react'
import { get } from 'lodash'


export default (props) => {

    const text = get(props.writing, 'text');

    return (
        <div className="wow-moment-writing">
            {text}
        </div>
    )
}
