import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import AnnotationsFactory from "highcharts/modules/annotations";
AnnotationsFactory(Highcharts);
import {merge} from 'lodash'

import {GRAPH_DEFAULT} from 'utils/graph'


class Graph extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'challenge-component-graph-' + Date.now() + '-' + Math.floor(Math.random() * 100)
    }

    componentDidMount() {
        this.draw()
    }

    componentDidUpdate() {
        this.draw()
    }

    draw() {
        const graph = merge({}, GRAPH_DEFAULT, this.props.component_data.data)
        this.chart = Highcharts.chart(this.uniq_id, graph)
    }

    render() {
        return (
            <div className="challenge-component-graph">
                <div id={this.uniq_id}>
                </div>
            </div>
        )
    }
}

Graph.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Graph
