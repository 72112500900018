import {get} from 'lodash';

import {replaceSpecialChars} from 'utils/content';

export default class Expression {
    render(cells, answer_obj) {
        const answer_content = get(answer_obj, 'data.answer');
        if (Array.isArray(answer_content)) {
            answer_content.map(answer => {
                cells.push(replaceSpecialChars(answer))
            })
        } else {
            cells.push(replaceSpecialChars(answer_content));
        }
    }
}
