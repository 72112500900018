export const graphingContentConfig = (uniq_id) => {
    const width = document.getElementById(`component-${uniq_id}`).offsetWidth
    const height = Math.round(width / 1.5)
    return {
        appName: "classic",
        id: "graphing_instance",
        width: width,
        height: height,
        enableRightClick: false,
        enableLabelDrags: false,
        enableShiftDragZoom: true,
        showResetIcon: true,
        showZoomButtons: true,
        borderColor: null,
        rounding: "2"
    }
}

export const graphingAnswerConfig = (uniq_id) => {
    const width = document.getElementById(`component-${uniq_id}`).offsetWidth
    const height = Math.round(width / 1.5)
    return {
        appName: "graphing",
        id: "graphing_instance",
        width: width,
        height: height,
        enableRightClick: false,
        enableLabelDrags: false,
        showResetIcon: true,
        showZoomButtons: true,
        borderColor: null,
        rounding: "2",
        showToolBar: true,
        showAlgebraInput: true,
        customToolBar: '73 0 1 2 | 40 41 42 39 | 62 25 17 26 27 61 14 58 | 6',
        preventFocus: true
    }
}

export const getLatex = (graphing_instance) => {
    if (!graphing_instance) {
        throw 'graphing_instance undefined'
    }

    const latex_array = []
    const total_number = graphing_instance.getObjectNumber()
    for (var i = 0; i < total_number; i++) {
        latex_array.push(
            graphing_instance.getLaTeXString(
                graphing_instance.getObjectName(i)
            )
        )
    }
    return latex_array
}