import React from 'react';
import {createRoot} from "react-dom/client";
import {configureStore} from '@reduxjs/toolkit';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Provider} from "react-redux";
import Pusher from "pusher-js";
import MindsetsJsSDK, {ErrorMessageAction} from "mindsets-js-sdk";
import {CloseMessagesAction} from "mindsets-js-sdk/src/react/components/CustomToast/actions";

import "../css/style.scss";
import UserEdit from "./layouts/UserEdit";
import analytic from "utils/Analytic";
import Announcements from "common/announcements";
import reducers from "./reducers";
import routes from "./routes";
import crashReporterMiddleware from "./crashReporterMiddleware";

export const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .prepend(
            crashReporterMiddleware,
            // loggerMiddleware
        )
});

(function () {
    pusher = new Pusher(process.env.PUBLIC_PUSHER_APP_KEY, {
        cluster: process.env.PUBLIC_PUSHER_APP_CLUSTER,
        forceTLS: true,
        channelAuthorization: {
            endpoint: `${process.env.PUBLIC_API_URL}/pusher/auth`,
            headers: {
                "X-Source": process.env.PUBLIC_APP_SOURCE,
                "Access-Control-Allow-Origin": "*",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: 'Bearer ' + MindsetsJsSDK().Auth.getAccessToken()
            }
        }
    });
    pusher.connection.bind('unavailable', function () {
        console.error('The pusher is not connected');
        store.dispatch(ErrorMessageAction(' It looks like your device is not connected to the internet. Please check your internet or wifi connection.', true));
    });
    pusher.connection.bind('error', function (error) {
        console.error(error);
    });
    pusher.connection.bind('failed', function () {
        console.error('Pusher is not supported by the browser.');
        store.dispatch(ErrorMessageAction('Your current browser is not supported by Mindsets. Please use Mindsets with Chrome, Firefox or Safari.', true));
    });
    pusher.connection.bind('disconnected', function () {
        console.error('The Pusher connection was previously connected and has now intentionally been closed.');
        store.dispatch(ErrorMessageAction('Your device is no longer connected to the internet. Please refresh this page.', true));
    });
    pusher.connection.bind('state_change', function (states) {
        if ('connected' === states.current) {
            store.dispatch(CloseMessagesAction());
        }
    });
    if (MindsetsJsSDK().Auth.isAuthenticated() && MindsetsJsSDK().Auth.getAccessToken()) {
        pusher.subscribe('presence-online');
    }
})();

analytic.init();

(function () {
    window.ga = window.ga || function () {
        (ga.q = ga.q || []).push(arguments)
    };
    ga.l = +new Date;
    if (process.env.PUBLIC_APP_ENV === 'production') {
        ga('create', 'UA-108798479-1', 'auto');
        ga('send', 'pageview');
    } else {
        window['ga-disable-UA-108798479-1'] = true;
    }
})();

// (function () {
//     if (process.env.PUBLIC_APP_ENV === 'production') {
//         window._mfq = window._mfq || [];
//         (function () {
//             var mf = document.createElement("script");
//             mf.type = "text/javascript";
//             mf.async = true;
//             mf.src = "//cdn.mouseflow.com/projects/a2dce886-f6db-41f5-bc46-bcc56c7b238e.js";
//             document.getElementsByTagName("head")[0].appendChild(mf);
//         })();
//     }
// })();

// (function () {
//     !function (f, b, e, v, n, t, s) {
//         if (f.fbq) return;
//         n = f.fbq = function () {
//             n.callMethod ?
//                 n.callMethod.apply(n, arguments) : n.queue.push(arguments)
//         };
//         if (!f._fbq) f._fbq = n;
//         n.push = n;
//         n.loaded = !0;
//         n.version = '2.0';
//         n.queue = [];
//         t = b.createElement(e);
//         t.async = !0;
//         t.src = v;
//         s = b.getElementsByTagName(e)[0];
//         s.parentNode.insertBefore(t, s)
//     }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
//     if (process.env.PUBLIC_APP_ENV === 'production') {
//         fbq('init', '372832063128253');
//         fbq('track', 'PageView');
//     }
// })();

const router = createBrowserRouter(routes);
const root = createRoot(document.getElementById("root"));
const announcementRoot = createRoot(document.getElementById("mindsets-announcement"));
const userEditRoot = createRoot(document.getElementById("user-edit"));

announcementRoot.render((
    <Provider store={store}>
        <Announcements/>
    </Provider>
));

userEditRoot.render((
    <Provider store={store}>
        <UserEdit/>
    </Provider>
));

root.render((
    // <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>
    // </React.StrictMode>
));
module.hot ? module.hot.accept() : null;
