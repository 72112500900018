import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";

import analytic from 'utils/Analytic';


export default () => {
    const lesson = useSelector(state => state.lesson);

    const onClick = () => {
        analytic.log(
            'facilitationNavClassSetup',
            lesson.code
        );
    }

    return (
        <div>
            <hr className="teacher-facilitation-nav-popup-divider"/>
            <div className="teacher-facilitation-nav-setup">
                <Link to={`/teacher/class/${lesson.code}/setup`} onClick={onClick}>
                    <i className="fas fa-pen"></i>
                    <span>{`Class Setup & Share Options`}</span>
                </Link>
            </div>
        </div>
    );
}
