import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {find} from "lodash";
import Highcharts from 'highcharts';

import {getSkillNames, computeSkillData} from './utils';
import {getPracticeAttr} from '../utils';

export default function () {
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const practices = useSelector(state => state.pam_practices);
    const skill_structure_data = useSelector(state => state.pam_challenge_skill_structure);
    const student_skill_data = useSelector(state => state.pam_student_skill_data);
    const mode = useSelector(state => state.pam_mode);

    const selected_practice = find(practices, {id: selected_practice_id});

    function draw() {
        const graph = {
            chart: {
                type: 'bar',
                marginLeft: 160
            },
            title: {
                text: getPracticeAttr(selected_practice.code, 'practices_title')
            },
            xAxis: {
                categories: getSkillNames(student_skill_data),
                labels: {
                    style: {}
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Student %'
                }
            },
            tooltip: {
                pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                bar: {
                    stacking: 'percent',
                    maxPointWidth: 100
                },
                series: {
                    stacking: 'normal'
                }
            },
            series: computeSkillData(
                student_skill_data,
                skill_structure_data,
                mode,
                selected_practice.code
            ),
            legend: {
                enabled: true,
                title: {
                    text: 'Achievement levels'
                }
            },
            credits: {
                enabled: false
            }
        }

        Highcharts.chart('pam-challenge-skill-performance', graph)
    }

    useEffect(() => {
        draw();
    }, []);

    useEffect(() => {
        draw();
    }, [student_skill_data, skill_structure_data, mode]);

    return (
        <div>
            <div id="pam-challenge-skill-performance"/>
        </div>
    );
}
