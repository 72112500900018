import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page15'>
            <div className='main-title'>Your Mindsets Certificate</div>
            <div className='certificate'>
                <div className='certificate-col'>
                    <div className='title'>Top Literacy Skills</div>
                    {data.top_literacy_skills.map(skill => {
                        return (
                            <img
                                className='hex'
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                alt={skill}/>
                        );
                    })}
                </div>
                <div className='certificate-col middle'>
                    <div className='congrats'>
                        <img
                            className='gear-badge'
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/badge_teamwork.png`}
                            alt='Team work'/>
                        <div className='congratulations'>
                            <div className='yay'>Congratulations!</div>
                            <div className='fname'>{data.fname}
                                <hr/>
                            </div>
                        </div>
                        <img
                            className='gear-badge'
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/badge_creativity.png`}
                            alt='Creativity'/>
                    </div>
                    <div className='smps'>
                        <div className='most-up'>
                            <div className='title'>Most Leveled Up</div>
                            <img
                                className='mp'
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${data.best_skill.slug}.png`}
                                alt={data.best_skill.code}/>
                        </div>
                        <div className='top-math'>
                            <div className='title'>Top Math Practices</div>
                            <div className='flex-row flex-wrap flex-gap-20'>
                                {data.top_strengths.map(skill => {
                                    return (
                                        <img
                                            className='mp'
                                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                            alt={skill}/>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <img
                        className='mindsets-logo'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                        alt='Mindsets Logo'/>
                </div>
                <div className='certificate-col'>
                    <div className='title'>Top Math Skills</div>
                    {data.top_math_skills.map(skill => {
                        return (
                            <img
                                className='hex'
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                alt={skill}/>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}