import React from 'react';
import {useSelector} from "react-redux";
import {map, uniq} from 'lodash';

import {processStringContent, capitalizeFirstLetter} from 'utils/content';


export default function () {
    const me = useSelector(state => state.me);
    const challenge_version = useSelector(state => state.challenge_version);

    function renderObjective() {
        return (
            <div>
                <h5 className="header-new">
                    {`Challenge Objectives`}
                </h5>
                {processStringContent(challenge_version.objective)}
            </div>
        )
    }

    function renderSteps() {
        const content = challenge_version.steps.map((step, i) => {
            return (
                <div key={step.title}>
                    {processStringContent(`${i + 1}. ${step.description}`)}
                </div>
            )
        })

        return (
            <div>
                <h5 className="header-new">
                    {`3-step Overview`}
                </h5>
                <div>
                    {content}
                </div>
            </div>
        )
    }

    function renderCodes() {
        let codes = [];
        challenge_version.challenge_code.standards
            .filter(standard => me.curriculum_id ? standard.curriculum_id === me.curriculum_id : standard.curriculum_id === 1)
            .map(standard => {
                if (me.curriculum && !me.curriculum.is_code_shown) {
                    if (!codes.includes(standard.grade)) {
                        codes.push(standard.grade);
                    }
                } else {
                    if (!codes.includes(standard.code)) {
                        codes.push(standard.code);
                    }
                }
            });

        return (
            <div>
                <h5 className="header-new">
                    {me.curriculum && !me.curriculum.is_code_shown ? capitalizeFirstLetter(me.curriculum.grade_term) : `Standards`}
                </h5>
                <div>
                    {codes.join(', ')}
                </div>
            </div>
        );
    }

    function renderTopic() {
        const topics = uniq(map(challenge_version.challenge_code.standards.filter(standard => me.curriculum_id ? standard.curriculum_id === me.curriculum_id : standard.curriculum_id === 1), 'topic')).join(', ')

        return (
            <div>
                <h5 className="header-new">
                    {`Skill/Topic`}
                </h5>
                <div>
                    {topics}
                </div>
            </div>
        )
    }

    if (!me) {
        return null;
    }

    return (
        <div className="challenge-preview-info flex-row-m">
            <div className="flex-7">
                <div className="challenge-preview-info-left">
                    {renderObjective()}
                    {renderSteps()}
                </div>
            </div>

            <div className="flex-3">
                <div className="challenge-preview-info-right">
                    {renderCodes()}
                    {renderTopic()}
                </div>
            </div>
        </div>
    )
}
