import moment from 'moment'

export default class HeaderCompiler {
    compile(rows, lesson, streams) {
        rows.push([lesson.challenge_version.name + ' Challenge brought to you by Mindsets www.mindsets.com']);
        rows.push(['Mindsets Code: ' + lesson.code.toUpperCase()]);
        rows.push(['Class name: ' + lesson.name, lesson.challenge_version.answer_key_url ? 'Answer keys: ' + (lesson.is_answer_keys_shown ? 'ON': 'OFF') : '']);
        rows.push(['Created on: ' + moment(lesson.created_at * 1000).format('MMM DD, YYYY')]);
        rows.push(['Challenge version: ' + lesson.challenge_version.version]);
        rows.push(['']);
        rows.push([streams.length + ' student(s) in the class']);
        rows.push(['']);

        return rows;
    }
}
