import React, {useEffect} from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

export default function () {
    function configTeams() {
        microsoftTeams.settings.setValidityState(true)
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            microsoftTeams.settings.setSettings({
                websiteUrl: window.location.origin,
                contentUrl: window.location.origin + `/teams/challenge_library`,
                entityId: "mindsetsTab",
                suggestedDisplayName: "Mindsets"
            });
            saveEvent.notifySuccess()
        })
    }

    useEffect(() => {
        microsoftTeams.initialize();
        configTeams();
    }, []);

    return (
        <div id="teams-config">
            <div>
                <div className="flex-row flex-h-center">
                    <img
                        className="mindsets-logo-guest"
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                        alt="Mindsets Learning Inc."
                    />
                </div>

                <div className="flex-row flex-h-center">
                    <div className="teams-config-description">
                        {`Mindsets Challenges involve a holistic approach to real-world `}
                        {`situations creating opportunities for all your students to `}
                        {`learn authentically through topics they care about, including `}
                        {`sports, art, the environment, data science and more!`}
                    </div>
                </div>
            </div>
        </div>
    );
}