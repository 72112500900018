import {get, find} from 'lodash'


export const getQuestionID = (component_data, questions) => {
    // Find question id
    // It will be undefined, for poll block
    const question_number = get(component_data, 'data.question_number')
    const question = find(questions, (q) => q.number == question_number)
    const question_id = get(question, 'id')

    return question_id
}
