import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {SetUserAction, WarningMessageAction} from "mindsets-js-sdk";

import {AVATARS} from "constants";
import CountryField from "./CountryField";
import StateField from "./StateField";
import SchoolField from "./SchoolField";
import CurriculumField from "./CurriculumField";
import GradeField from "./GradeField";
import Passwords from "./Passwords";

const UserForm = function ({validation_error_messages, setValidationErrorMessages, setMode}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    let user_new = JSON.parse(JSON.stringify(user));
    let validation_error_messages_new = JSON.parse(JSON.stringify(validation_error_messages));

    return (
        <div className="modal-content">
            <div className="container">
                <h2 className="header-new">Update Profile</h2>

                <div className="error-messages">
                    {Object.keys(validation_error_messages).length > 0 ? 'Please complete all required fields to proceed with Mindsets.' : ''}
                </div>

                <div className="profile-info section">
                    <Passwords
                        user_new={user_new}
                        validation_error_messages={validation_error_messages}
                        setValidationErrorMessages={setValidationErrorMessages}
                    />

                    <div className="row">
                        <div className="col s12 m6">
                            <div className="inputfield">
                                <label htmlFor="fname" className="flex-row">
                                    <div>First name *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('fname') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <input
                                    type="text"
                                    className={validation_error_messages.hasOwnProperty('fname') ? 'invalid' : ''}
                                    value={user_new.fname || ''}
                                    onChange={event => {
                                        let matches = event.target.value.match(/[^\w\s.\-&"':()\[\],{}’`\/?]+/um);
                                        matches = [...new Set(matches)];
                                        if (matches && matches.length) {
                                            validation_error_messages_new.fname = 'These characters are not allowed: ' + matches.join('');
                                            dispatch(WarningMessageAction('These characters are not allowed: ' + matches.join('')));
                                        } else if (event.target.value.length >= 190) {
                                            validation_error_messages_new.fname = 'First name is too long';
                                            dispatch(WarningMessageAction('First name is too long'));
                                        } else {
                                            user_new.fname = event.target.value;
                                            dispatch(SetUserAction(user_new));
                                            delete validation_error_messages_new.fname;
                                        }
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    autoComplete="off"
                                    maxLength={190}
                                    id="fname" required/>
                            </div>
                        </div>

                        <div className="col s12 m6">
                            <div className="inputfield">
                                <label htmlFor="lname" className="flex-row">
                                    <div>Last name *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('lname') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <input
                                    type="text"
                                    className={validation_error_messages.hasOwnProperty('lname') ? 'invalid' : ''}
                                    value={user_new.lname || ''}
                                    onChange={event => {
                                        let matches = event.target.value.match(/[^\w\s.\-&"':()\[\],{}’`\/?]+/um);
                                        matches = [...new Set(matches)];
                                        if (matches && matches.length) {
                                            validation_error_messages_new.lname = 'These characters are not allowed: ' + matches.join('');
                                            dispatch(WarningMessageAction('These characters are not allowed: ' + matches.join('')));
                                        } else if (event.target.value.length >= 190) {
                                            validation_error_messages_new.lname = 'Last name is too long';
                                            dispatch(WarningMessageAction('Last name is too long'));
                                        } else {
                                            user_new.lname = event.target.value;
                                            dispatch(SetUserAction(user_new));
                                            delete validation_error_messages_new.lname;
                                        }
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    id="lname" required
                                    maxLength={190}
                                    autoComplete="off"/>
                            </div>
                        </div>

                        <CountryField
                            validation_error_messages={validation_error_messages}
                            setValidationErrorMessages={setValidationErrorMessages}
                        />
                        <StateField/>
                        <SchoolField
                            validation_error_messages={validation_error_messages}
                            setValidationErrorMessages={setValidationErrorMessages}
                            setMode={setMode}/>
                        <CurriculumField/>
                        <GradeField
                            validation_error_messages={validation_error_messages}
                            setValidationErrorMessages={setValidationErrorMessages}
                        />
                    </div>
                </div>

                <div className="avatars">
                    {AVATARS.map((avatar, index) => {
                        let classes = "avatar ";
                        if (avatar === user_new.avatar) {
                            classes += " selected";
                        }
                        let c = "cashtivity-icon " + avatar;

                        return (
                            <div className={classes} key={index} onClick={() => {
                                user_new.avatar = avatar;
                                dispatch(SetUserAction(user_new));
                            }}>
                                <div className={c}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

UserForm.propTypes = {
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

export default UserForm;
