import React from 'react';
import {useSelector} from "react-redux";
import {getChallengeHeroImage} from "utils/cloud";

export default function () {
    const challenge_version = useSelector(state => state.challenge_version);

    return (
        <div className="challenge-preview-content-container">
            <div className="challenge-preview-adjustment">
                <div className="challenge-card">
                    <div className='challenge-card-container use-challenge-color'>
                        <div
                            className="card-container-bg"
                            style={{backgroundColor: challenge_version.challenge_code.color}}></div>
                        <div
                            className="card-container-hero"
                            style={{backgroundImage: `url(${getChallengeHeroImage(challenge_version.challenge_code.code)})`}}>
                            <div
                                className="card-container-slope"
                                style={{backgroundColor: challenge_version.challenge_code.color}}>
                            </div>
                        </div>
                        <div className="container">
                            <div className="challenge-card-min-height flex-row flex-v-center">
                                <div className="flex-1">
                                    <div className='challenge-intro-card'>
                                        <div className="challenge-card-other-content">
                                            <div className="flex-column">
                                                <div className="section">
                                                    <div className="challenge-intro-name">
                                                        {challenge_version.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
