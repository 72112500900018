import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

export const SET_PAM_SELECTED_SCHOOL_ID = '[pam] Set Selected School ID';
export const SET_PAM_FROM = '[pam] Set From';
export const SET_PAM_TILL = '[pam] Set Till';
export const SET_PAM_SELECTED_CHALLENGE_ID = '[pam] Set Selected Challenge ID';
export const SET_PAM_SELECTED_PRACTICE_ID = '[pam] Set Selected Practice ID';
export const SET_PAM_MODE = '[pam] Set Mode';
export const SET_PAM_ZOOM = '[pam] Set Zoom';
export const SET_PAM_SHOW_SCORE = '[pam] Set Show Score';
export const LOADED_PAM_PRACTICES = '[pam] Loaded Practices';
export const LOADED_PAM_CHALLENGE_SKILL_STRUCTURE = '[pam] Loaded Challenge Skill Structure';
export const LOADED_PAM_STUDENT_SKILL_DATA = '[pam] Loaded Student Skill Data';

export const SetPAMSelectedSchoolIdAction = payload => ({
    type: SET_PAM_SELECTED_SCHOOL_ID,
    payload
});
export const SetPAMFromAction = payload => ({
    type: SET_PAM_FROM,
    payload
});
export const SetPAMTillAction = payload => ({
    type: SET_PAM_TILL,
    payload
});
export const SetPAMSelectedChallengeIdAction = payload => ({
    type: SET_PAM_SELECTED_CHALLENGE_ID,
    payload
});
export const SetPAMSelectedPracticeIdAction = payload => ({
    type: SET_PAM_SELECTED_PRACTICE_ID,
    payload
});
export const SetPAMModeAction = payload => ({
    type: SET_PAM_MODE,
    payload
});
export const SetPAMZoomAction = payload => ({
    type: SET_PAM_ZOOM,
    payload
});
export const SetPAMShowScoreAction = payload => ({
    type: SET_PAM_SHOW_SCORE,
    payload
});

export const LoadPamPracticesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Practices')))
        .then(() => MindsetsJsSDK().API.get('pam/practices', options))
        .then(response => {
            dispatch(LoadedPamPracticesAction(response.data));
            dispatch(LoadingAction(false));

            return response.data;
        });
};
export const LoadedPamPracticesAction = payload => ({
    type: LOADED_PAM_PRACTICES,
    payload
});

export const LoadPamChallengeSkillStructureAction = options => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Data')))
        .then(() => MindsetsJsSDK().API.get('pam/challenge_skill_structure', options))
        .then(response => {
            dispatch(LoadedPamChallengeSkillStructureAction(response.questions));
            dispatch(LoadingAction(false));

            return response.questions;
        });
};
export const LoadedPamChallengeSkillStructureAction = payload => ({
    type: LOADED_PAM_CHALLENGE_SKILL_STRUCTURE,
    payload
});

export const LoadPamStudentSkillDataAction = options => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Data')))
        .then(() => MindsetsJsSDK().API.get('pam/student_skill_data', options))
        .then(response => {
            dispatch(LoadedPamStudentSkillDataAction(response.data));
            dispatch(LoadingAction(false));

            return response.data;
        });
};
export const LoadedPamStudentSkillDataAction = payload => ({
    type: LOADED_PAM_STUDENT_SKILL_DATA,
    payload
});
