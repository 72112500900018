import React, {useEffect} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import MindsetsJsSDK, {LoadMeAction} from "mindsets-js-sdk";

import analytic from "utils/Analytic";
import {AddLastEventAction, AddLocationAction} from "./actions/history_actions";

export default () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const me = useSelector(state => state.me);

    function catchClicks(event) {
        dispatch(AddLastEventAction({
            TAG: event.target.tagName,
            ID: event.target.id || 'N/A',
            CLASS: event.target.className ? event.target.className.toString() : 'N/A',
            LOC: location.pathname,
            HREF: event.target.href || 'N/A',
            TIME: event.timeStamp,
            TYPE: event.type,
            POINTER: event.pointerType,
            TEXT: event.target.innerText ? event.target.innerText.substring(0, 20) : ''
        }));
    }

    useEffect(() => {
        if (MindsetsJsSDK().Auth.isAuthenticated()) {
            dispatch(LoadMeAction());
        }
    }, []);

    useEffect(() => {
        window.addEventListener('click', catchClicks);

        return () => window.removeEventListener('click', catchClicks);
    }, []);

    useEffect(() => {
        let user_channel;
        if (me) {
            analytic.init(me.id);
            user_channel = pusher.subscribe('user_' + me.id);
            user_channel.bind('signed_out', function () {
                MindsetsJsSDK().Auth.logout();
                window.location.href = "/";
            });
            user_channel.bind('user.updated', () => dispatch(LoadMeAction()));
        }

        return () => {
            if (user_channel) {
                user_channel.unbind_all();
                user_channel.unsubscribe();
            }
        }
    }, [me]);

    useEffect(() => {
        dispatch(AddLocationAction(location));
    }, [location]);

    return (
        <Outlet/>
    );
};
