import React from 'react';

import './bouncing.scss';

export default function ({children}) {
    return (
        <div className="common-ui-bouncing">
            <div className="bouncing-box">
                {children}
            </div>
        </div>
    );
}
