import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {throttle, get, isEqual, cloneDeep, find} from 'lodash'
import MindsetsJsSDK from "mindsets-js-sdk"

import PollUI from './PollUI'


class Poll extends Component {
    constructor(props) {
        super(props)
        this.state = {
            my_vote: get(this.props.support_data, 'vote.poll_option_id'),
            lesson_votes: []
        }

        this.fetchLessonVotesThrottle = throttle(this.fetchLessonVotes.bind(this), 5000, {
            leading: false,
            trailing: true
        })
    }

    componentDidMount() {
        if (this.props.lesson_id) {
            this.fetchLessonVotes()
            this.subscribe()
        }
    }

    componentWillUnmount() {
        if (this.lesson_votes_channel) {
            const poll = this.props.support_data.poll
            this.lesson_votes_channel.unbind()
            pusher.unsubscribe(`lesson_${this.props.lesson_id}_poll_${poll.id}`)
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.support_data.vote, prevProps.support_data.vote)) {
            if (this.props.is_preview) {
                const my_vote = get(this.props.support_data, 'vote.poll_option_id')
                this.setState({my_vote})
            }
        }
    }

    subscribe() {
        if (!this.lesson_votes_channel && this.props.lesson_id) {
            const poll = this.props.support_data.poll
            this.lesson_votes_channel = pusher.subscribe(`lesson_${this.props.lesson_id}_poll_${poll.id}`)
            this.lesson_votes_channel.bind('lesson.poll.updated', () => {
                this.fetchLessonVotesThrottle()
            })
        }
    }

    fetchLessonVotes() {
        const poll = this.props.support_data.poll
        if (this.props.lesson_id && poll) {
            return MindsetsJsSDK().Api.poll.votes(
                this.props.lesson_id, poll.id
            ).then((response) => {
                this.setState({lesson_votes: response.votes})
            }).catch((error) => {
                console.error(error)
            })
        }
    }

    isValid() {
        return !!this.state.my_vote
    }

    getLessonVotes() {
        // The purpose of this function is to reflect user's vote in the
        // lesson votes without needing to wait for the event triggered.
        // The event will still be triggered, but with more votes from the
        // other streams of the class code

        if (!this.state.my_vote || !this.props.stream_id) {
            return this.state.lesson_votes
        }

        const lesson_votes = cloneDeep(this.state.lesson_votes)
        const my_vote_old = find(
            lesson_votes,
            (v) => v.stream_id == this.props.stream_id
        )

        if (!my_vote_old) {
            lesson_votes.push({
                poll_option_id: this.state.my_vote,
                stream_id: this.props.stream_id
            })
        } else if (my_vote_old.poll_option_id != this.state.my_vote) {
            my_vote_old.poll_option_id = this.state.my_vote
        }

        return lesson_votes
    }

    render() {
        return (
            <PollUI
                component_data={this.props.component_data}
                poll={this.props.support_data.poll}
                lesson_id={this.props.lesson_id}
                stream_id={this.props.stream_id}
                stream_entity_id={this.props.stream_entity_id}
                is_preview={this.props.is_preview}
                my_vote={this.state.my_vote}
                setMyVote={(my_vote) => this.setState({my_vote})}
                lesson_votes={this.getLessonVotes()}
            />
        )
    }
}

Poll.propTypes = {
    component_data: PropTypes.object.isRequired,
    support_data: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    stream_entity_id: PropTypes.number.isRequired,
    is_preview: PropTypes.bool.isRequired
}

export default Poll
