import React from "react";
import PropTypes from "prop-types";

function Announcement({announcement, index, onClose}) {
    function renderClose() {
        if (announcement.dismissable) {
            return (
                <div className="mindsets-announcement-close">
                    <i className="fas fa-times" onClick={() => onClose(announcement)}/>
                </div>
            );
        }

        return null;
    }

    return (
        <div className={"mindsets-announcement mindsets-announcement-" + index}>
            <div className="container">
                <div className="center-align" dangerouslySetInnerHTML={{__html: announcement.body}}/>
            </div>

            {renderClose()}
        </div>
    );
}

Announcement.propTypes = {
    announcement: PropTypes.shape({
        id: PropTypes.number.isRequired,
        body: PropTypes.string.isRequired,
        dismissable: PropTypes.bool.isRequired
    }).isRequired,
    index: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Announcement
