import React from 'react'

import BaseCard from './BaseCard'
import {processStringContent} from 'utils/content'
import {getChallengeBadgeUrl} from 'utils/cloud'


export default class UnlockCard extends BaseCard {
    getCardType() {
        return 'unlock'
    }

    getMinHeight() {
        return '400px'
    }

    renderBadge() {
        const src_url = getChallengeBadgeUrl(this.props.challenge_version.challenge_code.code)
        return (
            <div className="unlock-card-badge center-align">
                <img src={src_url}/>
            </div>
        )
    }

    renderContent() {
        const {content1} = this.props.stream_entity.data
        if (content1) {
            return (
                <div className="section">
                    <div className="unlock-card-content">
                        <div className="flex-row flex-vh-center center-align">
                            {processStringContent(content1)}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderOtherContent() {
        return (
            <div className="">
                <div className="section"></div>

                <div className="section">
                    <h2 className="header-new center-align">
                        {`Congratulations!`}
                    </h2>
                </div>

                {this.renderBadge()}

                <h5 className="header-new center-align">
                    {`Now, enter your bonus questions...`}
                </h5>

                {this.renderContent()}
            </div>
        )
    }
}
