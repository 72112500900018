import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page5'>
            <div className='themes'>Here are your Top Mindsets Challenges</div>
            <div className='flex-row flex-wrap badges'>
                {data.top_challenges.map(challenge => {
                    return (
                        <img
                            key={challenge}
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/challenge_${challenge}.png`}
                            alt={challenge}
                        />
                    );
                })}
            </div>
        </div>
    );
}