import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import {getCardName} from 'utils/challenge/streamEntity';


function Overlay({stream}) {
    const lesson = useSelector(state => state.lesson);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);

    function renderName() {
        if (show_student) {
            return (
                <div className="overview-student-overlay-name">
                    {stream.user.full_name}
                </div>
            );
        }
    }

    function renderFlag() {
        if (stream.red_flag) {
            return (
                <div className="overview-student-overlay-flag flex-row flex-vh-center">
                    <i className="fas fa-flag"/>
                    <span>{`falling behind`}</span>
                </div>
            );
        }
    }

    function renderProgress() {
        return (
            <div className="right-align">
                {`on `}
                {getCardName(lesson.challenge_version, stream.index)}
            </div>
        );
    }

    return (
        <div className="overview-student-overlay flex-row">
            <div className="flex-auto flex-column">
                {renderName()}

                {renderFlag()}
            </div>

            {renderProgress()}
        </div>
    );
}

Overlay.propTypes = {
    stream: PropTypes.shape({
        index: PropTypes.number.isRequired,
        user: PropTypes.shape({
            full_name: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
}

export default Overlay
