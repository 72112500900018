import React from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {get} from 'lodash';

export default function () {
    const navigate = useNavigate();
    const stream = useSelector(state => state.stream);

    function needToShowPause() {
        if (get(stream, 'status') === 'paused') {
            return true;
        }

        return !!get(stream, 'lesson.is_hidden');
    }

    function renderIcon() {
        if (get(stream, 'status') === 'paused') {
            return (
                <i className="far fa-pause-circle fa-4x"/>
            );
        }

        if (get(stream, 'lesson.is_hidden')) {
            return (
                <i className="fas fa-ban fa-4x"/>
            );
        }

        return null;
    }

    function renderPauseContent() {
        if (get(stream, 'status') !== 'paused') {
            return null;
        }

        return (
            <div className="center-align">
                <div className="section">
                    {`Your teacher has temporarily…`}
                </div>
                <div className="section">
                    <h3 className="header-new">
                        {`PAUSED`}
                    </h3>
                </div>
                <div className="section">
                    {`this session until further notice.`}
                </div>
            </div>
        );
    }

    function renderHiddenContent() {
        if (get(stream, 'status') === 'paused') {
            return null;
        }
        if (!get(stream, 'lesson.is_hidden')) {
            return null;
        }

        const onClick = () => {
            navigate("/");
        }

        return (
            <div className="center-align">
                <div className="section">
                    {`Your teacher has`}
                </div>
                <div className="section">
                    <h3 className="header-new">
                        {`STOPPED`}
                    </h3>
                </div>
                <div className="section">
                    {`this session.`}
                </div>

                <div className="section">
                    <div className="flex-row flex-h-center">
                        <button className="btn btn-challenge" onClick={onClick}>
                            <i className="fas fa-home"/>
                            <span>{`Home`}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    if (!needToShowPause()) {
        return null;
    }

    return (
        <div className="student-stream-paused flex-column flex-vh-center">
            {renderIcon()}
            {renderPauseContent()}
            {renderHiddenContent()}
        </div>
    );
}
