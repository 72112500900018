import {
    addTitleRequests, addMergeCellsRequests, addEmptyRowsRequests, red_title
} from './utils'


export const addOverviewContent = (sheetId) => {
    let requests_array = []
    requests_array = requests_array.concat(addTitleRequests(sheetId, 'Contents'))
    requests_array = requests_array.concat(addMergeCellsRequests(sheetId, 2, 3, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(sheetId, 1))
    requests_array = requests_array.concat(addContentArray(sheetId))

    return requests_array
}

const addContentArray = (sheetId) => {
    const content_array = [
        `Overall Performance - The Math Practice graphs illustrate the percentage of students that lie in each of the four achievement levels - Exceeded, Met, Nearly Met, and Not Met.`,
        `Overall CAASPP Alignment - The CAASPP Claim graphs illustrate the percentage of students that lie in each of the four achievement levels - Exceeded, Met, Nearly Met, and Not Met.`,
        `Student Achievement - The table displays the strength's and gaps of individual students for every Math Practice Skill in the Challenge. The achievement mode takes into account the correctness of number of questions whether or not attempted by students.`,
        `Student Potential - The table displays the strength's and gaps of students for every Math Practice Skill in the Challenge. The potential mode only takes into account the correctness of number of questions attempted by students.`,
        `Mapping - The table summarises the maximum possible points allocated for the CAASPP Claim Areas and Math Practice Skills in the Challenge.`,
        `Chart Data - Please do not edit any data. This table is for internal purposes only.`
    ]

    let requests_array = []
    requests_array = requests_array.concat(addTextRequests(sheetId, content_array[0]))
    requests_array = requests_array.concat(addEmptyRowsRequests(sheetId, 1))
    requests_array = requests_array.concat(addTextRequests(sheetId, content_array[1]))
    requests_array = requests_array.concat(addEmptyRowsRequests(sheetId, 1))
    requests_array = requests_array.concat(addTextRequests(sheetId, content_array[2]))
    requests_array = requests_array.concat(addEmptyRowsRequests(sheetId, 1))
    requests_array = requests_array.concat(addTextRequests(sheetId, content_array[3]))
    requests_array = requests_array.concat(addEmptyRowsRequests(sheetId, 1))
    requests_array = requests_array.concat(addTextRequests(sheetId, content_array[4]))
    requests_array = requests_array.concat(addEmptyRowsRequests(sheetId, 1))
    requests_array = requests_array.concat(addTextRequests(sheetId, content_array[5], red_title))

    return requests_array
}

const addTextRequests = (sheetId, text, text_format) => {
    return [
        {
            appendCells: {
                sheetId,
                fields: '*',
                rows: [
                    {
                        values: [
                            {
                                userEnteredValue: {
                                    stringValue: text
                                },
                                userEnteredFormat: {
                                    wrapStrategy: 'OVERFLOW_CELL',
                                    textFormat: {
                                        ...text_format
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
}
