import {findKey, keys} from 'lodash'

import {PRACTICES_DATA} from 'constants/practices'


export const getPracticeAttr = (practice_code, attr_name) => {
    const practice_data = PRACTICES_DATA[practice_code]
    if (!practice_data) {
        throw 'Practice data not found'
    }

    return practice_data[attr_name]
}

export const getIntervalCode = (practice_code, points_percent) => {
    const practice_data = PRACTICES_DATA[practice_code]
    if (!practice_data) {
        throw 'Practice data not found'
    }

    if (points_percent === undefined) {
        return '-'
    }

    const practice_intervals = practice_data.intervals
    const interval_code = findKey(practice_intervals, (practice_interval) => {
        return practice_interval.condition(points_percent)
    })

    return interval_code ?? '-'
}

export const getIntervalName = (practice_code, interval_code) => {
    if (interval_code === '-') {
        return ''
    } else {
        return PRACTICES_DATA[practice_code].intervals[interval_code].name
    }
}

const hexColorToRGBA = (hex) => {
    return {
        red: ((`0x${hex[1]}${hex[2]}`)&255)/255,
        green: ((`0x${hex[3]}${hex[4]}`)&255)/255,
        blue: ((`0x${hex[5]}${hex[6]}`)&255)/255,
        alpha: 1
    }
}

export const getIntervalColor = (practice_code, interval_code, rgba = false) => {
    let color
    if (interval_code === '-') {
        color = '#FFFFFF'
    } else {
        color = PRACTICES_DATA[practice_code].intervals[interval_code].color
    }

    if (rgba) {
        return hexColorToRGBA(color)
    } else {
        return color
    }
}

export const getPracticeIntervalCodes = (practice_code) => {
    const practice_data = PRACTICES_DATA[practice_code]
    if (!practice_data) {
        throw 'Practice data not found'
    }

    const practice_intervals = practice_data.intervals
    return keys(practice_intervals)
}
