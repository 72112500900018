import React from 'react'
import {get, isEmpty, trim} from 'lodash'

import {convertLatexToKatex, convertKatexToString} from 'utils/math'
import BaseAnswer from '../BaseAnswer'
import ReadOnly from './ReadOnly'
import MathInput from './MathInput'


export default class Expression extends BaseAnswer {
    defaultAnswer() {
        return get(this.props.component_data, 'data.prefill', '')
    }

    isValid() {
        try {
            const test_str = convertLatexToKatex(this.state.answer)
            convertKatexToString(test_str)
        } catch (error) {
            throw('Invalid symbol or syntax in expression.')
        }

        return !isEmpty(trim(this.state.answer, '\\ '))
    }

    renderReadOnly() {
        if (this.props.is_preview) {
            return (
                <ReadOnly
                    is_readonly={this.props.is_preview}
                    existing_answer={this.props.existing_answer}
                    default_answer={this.defaultAnswer()}
                />
            )
        }
    }

    renderMathInput() {
        if (this.props.is_preview) {
            return null
        }

        const onChange = (answer) => {
            this.setState({answer})
        }

        return (
            <MathInput
                value={this.state.answer}
                onChange={onChange}
                button_sets={get(this.props.component_data, 'data.button_sets', [])}
            />
        )
    }

    render() {
        return (
            <div className="challenge-component-expression">
                <div className="challenge-component-expression-width">
                    {this.renderReadOnly()}

                    {this.renderMathInput()}
                </div>
            </div>
        )
    }
}
