import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import MindsetsJsSDK, {
    CustomDropdown,
    SuccessMessageAction,
    ErrorMessageAction,
    LoadingAction,
    DeleteStreamAction
} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';


function Actions({stream}) {
    const dispatch = useDispatch();

    function changeStreamStatus(status) {
        dispatch(LoadingAction(true));

        MindsetsJsSDK().Api.stream.status.update(
            stream.id, {status}
        ).then(() => {
            dispatch(SuccessMessageAction(`${stream.user.full_name} has been ${status}`));
        }).catch(() => {
            dispatch(ErrorMessageAction('An unexpected error occurred'));
        }).then(() => {
            dispatch(LoadingAction(false));
        });

        switch (status) {
            case 'paused':
                analytic.log('facilitationPauseStudentStream', stream.code, stream.user);
                break;
            case 'started':
                analytic.log('facilitationResumeStudentStream', stream.code, stream.user);
                break;
        }
    }

    function deleteStudent(e) {
        e.preventDefault();

        const user_name = stream.user.fname + (stream.user.fname.endsWith('s') ? "'" : "'s");
        const confirm_message = `Are you sure you want to remove ${stream.user.full_name} from this class?\nAll ${user_name} responses and progress will be deleted and no longer accessible.`;
        if (!confirm(confirm_message)) {
            return;
        }

        dispatch(DeleteStreamAction(stream.id)).then(() => {
            dispatch(SuccessMessageAction(`${stream.user.full_name} has been removed from this class`));
        }).catch(() => {
            dispatch(ErrorMessageAction('An unexpected error occurred'));
        });

        analytic.log('facilitationDeleteStudentStream', stream.code, stream.user);
    }

    function renderPauseOrPlay() {
        if (stream.status === 'paused') {
            const onClick = () => changeStreamStatus('started')
            return (
                <a href="#" onClick={onClick} className='overview-student-actions-action'>
                    <i className="fas fa-play"/>
                    <span>{`RESUME STUDENT`}</span>
                </a>
            );
        } else {
            const onClick = () => changeStreamStatus('paused')
            return (
                <a href="#" onClick={onClick} className='overview-student-actions-action'>
                    <i className="fas fa-pause"/>
                    <span>{`PAUSE STUDENT`}</span>
                </a>
            );
        }
    }

    function renderDelete() {
        return (
            <a href="#" onClick={deleteStudent} className='overview-student-actions-action'>
                <i className="fas fa-trash-alt"/>
                <span>{`DELETE STUDENT`}</span>
            </a>
        );
    }

    function renderTrigger() {
        return (
            <div className="overview-student-actions-trigger flex-row flex-vh-center">
                <i className="fas fa-ellipsis-v"/>
            </div>
        );
    }

    return (
        <div className="overview-student-actions flex-row flex-v-center">
            <CustomDropdown
                trigger={renderTrigger()}
                no_wrap={true}
                items={{
                    a: renderPauseOrPlay(),
                    divider: <hr/>,
                    b: renderDelete()
                }}
                use_arrow={false}
            />
        </div>
    );
}

Actions.propTypes = {
    stream: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        user: PropTypes.shape({
            fname: PropTypes.string,
            full_name: PropTypes.string
        }).isRequired
    }).isRequired
}

export default Actions
