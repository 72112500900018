import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {processStringContent} from 'utils/content'


class PollBlockOption extends Component {
    renderDescription() {
        if (!this.props.poll_option.description) {
            return null
        }

        return (
            <div className="poll-option-image-description flex-row flex-vh-center center-align">
                {processStringContent(this.props.poll_option.description)}
            </div>
        )
    }

    render() {
        const class_name_poll_option = classNames('poll-option', {
            "poll-option-selected": this.props.is_selected
        })

        const class_name_icon = classNames('poll-option-radio far', {
            "fa-dot-circle": this.props.is_selected,
            "fa-circle": !this.props.is_selected
        })

        return (
            <div className={class_name_poll_option}>
                <div className="poll-option-image-container">
                    <img className="poll-option-image" src={this.props.poll_option.option} />
                    {this.renderDescription()}
                </div>

                <div className="poll-option-content-container">
                    <div className="flex-row flex-vh-center">
                        <i className={class_name_icon}></i>

                        <div className="poll-option-title center-align">
                            {processStringContent(this.props.poll_option.title)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PollBlockOption.propTypes = {
    poll_option: PropTypes.object.isRequired,
    is_selected: PropTypes.bool.isRequired
}

export default PollBlockOption
