import React from 'react';
import {Link, Outlet} from "react-router-dom";
import {CustomToast, Loader} from "mindsets-js-sdk";

import Footer from './Footer';

export default function () {
    return (
        <>
            <CustomToast/>
            <div className="container">
                <div className="section center">
                    <Link to="/">
                        <img className="mindsets-logo-guest"
                             src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                             alt="Mindsets Learning Inc."/>
                    </Link>
                </div>
            </div>
            <main>
                <Outlet/>
            </main>
            <footer className="page-footer">
                <Footer/>
            </footer>
            <Loader/>
        </>
    );
}
