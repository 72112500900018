import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {set, get, isEmpty} from 'lodash';
import classNames from 'classnames';

import Card from 'common/challengeNew/Card';
import ProgressToolBar from './ProgressToolBar';
import TeacherDrivenProgressToolBar from "./TeacherDrivenProgressToolBar";


function MainContent({ref_components}) {
    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const stream_entities_responses = useSelector(state => state.student_stream_stream_entities_responses);
    const stream_entities = stream_entities_responses.map((se, i) => {
        const challenge_se = get(challenge_stream_entities, i)
        return {
            ...challenge_se,
            ...se
        }
    });

    function isLatest() {
        return current_index === stream.index;
    }

    function refComponent(card_index) {
        return (row) => (col) => (rc) => set(ref_components, [card_index, row, col], rc);
    }

    function renderCards() {
        if (isEmpty(stream.challenge_version) || isEmpty(stream_entities)) {
            return null;
        }

        return stream_entities.map((se, card_index) => {
            const class_name = classNames('stream-main-content-card', {
                'stream-main-content-card-active': card_index === current_index,
                'stream-main-content-card-inactive': card_index !== current_index
            });
            return (
                <div key={se.id} className={class_name}>
                    <Card
                        challenge_version={stream.challenge_version}
                        stream_entity={se}
                        lesson_id={stream.lesson_id}
                        lesson={stream.lesson}
                        stream_id={stream.id}
                        is_preview={false}
                        is_latest={isLatest()}
                        is_active={card_index === current_index}
                        refComponent={refComponent(card_index)}
                    />
                </div>
            );
        })
    }

    function renderProgressToolBar() {
        const current_stream_entity = get(stream_entities, current_index);
        if (stream && current_stream_entity) {
            if (stream.lesson.mode === 'teacher') {
                return (
                    <TeacherDrivenProgressToolBar
                        getRefComponents={() => get(ref_components, current_index)}
                    />
                );
            } else {
                return (
                    <ProgressToolBar
                        getRefComponents={() => get(ref_components, current_index)}
                    />
                );
            }
        }
    }

    return (
        <div className="student-stream-main-content">
            <div className="stream-main-content-cards">
                {renderCards()}
            </div>

            {renderProgressToolBar()}
        </div>
    );
}

MainContent.propTypes = {
    ref_components: PropTypes.array.isRequired
};

export default MainContent;
