import React from 'react';

export default function Clever() {
    function onClick(e) {
        e.preventDefault();
        const redirectUrl = encodeURIComponent(window.location.origin + '/auth/clever/callback');
        let uri = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUrl}&client_id=${process.env.PUBLIC_CLEVER_CLIENT_ID}`;
        if (process.env.PUBLIC_CLEVER_TEST_MODE) {
            uri += '&district_id=5b2ad81a709e300001e2cd7a';
        }

        return window.location.href = uri;
    }

    return (
        <button className="sso-button-inner flex-row flex-v-center" id='clever-sso-button' onClick={onClick}>
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_clever.svg`} alt='with clever'/>
            <div className="flex-auto center-align sso-button-label">
                {`Sign in with Clever`}
            </div>
        </button>
    );
}
