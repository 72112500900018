import React from 'react';
import { Link } from "react-router-dom";

import ButtonCopyText from 'common/ButtonCopyText';


export default function (props) {

    if (!props.wowMomentId || !props.wowMomentCode) {
        return (
            <div className="wow-moment-share">
                <h5 className="header-new">
                    {`Share your WOW Moments!`}
                </h5>
                <div>
                    {`1. Upload photos of class activity and student works.`}
                </div>
                <div>
                    {`2. Save by clicking the SAVE WOW MOMENTS! button.`}
                </div>
                <div>
                    {`3. Share the provided link (URL) with students, colleagues and parents.`}
                </div>
            </div>
        )
    }

    const url = `${window.location.origin}/wow-moments/${props.wowMomentCode}`;

    return (
        <div className="wow-moment-share">
            <h5 className="header-new">
                {`Share your WOW Moments!`}
            </h5>
            <div>
                {`1. Upload photos of class activity and student works.`}
            </div>
            <div>
                {`2. Save by clicking the SAVE WOW MOMENTS! button.`}
            </div>
            <div>
                {`3. Share this link (URL) with students, colleagues and parents:`}
            </div>
            <div className="flex-row flex-box-5-5 flex-v-center">
                <div>
                    {url}
                </div>
                <div>
                    <ButtonCopyText
                        text_to_copy={url}
                    />
                </div>
                <div>
                    <Link className="underline" to={`/wow-moments/${props.wowMomentCode}`} target="_blank">
                        {`Preview in new tab`}
                    </Link>
                </div>
            </div>
        </div>
    );
}