import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page4'>
            <div className='flex-row flex-v-start flex-wrap content-row'>
                <div className='content'>
                    <div className='title'>You've completed <span className='bold'>{data.number_challenges_completed}</span> Mindsets Challenges!</div>
                    <div className='subtitle'>Awesome job!</div>
                </div>
                <img
                    className='amazing-badge'
                    src={process.env.PUBLIC_S3_ASSETS_URL + '/student_story/badges/badge_amazing.png'}
                    alt='Amazing Badge'
                />
            </div>
        </div>
    );
}