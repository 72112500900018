import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoadLessonsAction} from "mindsets-js-sdk";

import useAuth from "../../hooks/useAuth";
import HomeMenu from "common/HomeMenu";
import Steps from "common/TeacherSteps";
import Challenges from "./Challenges";
import {LoadCoTeachingLessonsAction} from "../../actions/lesson_actions";

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const lessons = useSelector(state => state.lessons);
    const co_teaching_lessons = useSelector(state => state.co_teaching_lessons);
    const loading = useSelector(state => state.loading);

    function getLessons() {
        dispatch(LoadLessonsAction({
            limit: 0,
            order_by: 'created_at',
            order_direction: 'desc',
            'filters[my_only][0]': 'eq=true',
            'selectors[0]': 'challengeVersion_challengeCode',
            'selectors[1]': 'students_count'
        }));
    }

    function getCoClasses() {
        dispatch(LoadCoTeachingLessonsAction({
            limit: 0,
            order_by: 'created_at',
            order_direction: 'desc',
            'selectors[0]': 'challengeVersion_challengeCode',
            'selectors[1]': 'students_count'
        }));
    }

    function renderSteps() {
        if (Array.isArray(lessons) && lessons.length === 0 && !loading) {
            return (
                <Steps/>
            );
        }
    }

    useEffect(() => {
        if (!lessons.length) {
            getLessons();
        }
        if (!co_teaching_lessons.length) {
            getCoClasses();
        }
    }, []);

    return (
        <div id="teacher-dashboard">
            {renderSteps()}
            <div className="container">
                <div className="section">
                    <HomeMenu/>
                </div>
                <div className="section">
                    <Challenges/>
                </div>
            </div>
        </div>
    );
}