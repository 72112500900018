import React from 'react';

export default function () {
    return (
        <div className="profile-footer">
            <p>Want to stay in the know outside of the inbox? Get all the latest content, resources, and updates on
                social media.</p>
            <div className="flex-row mt-2 flex-box-5-5">
                <a href='http://www.facebook.com/100005006474019'>
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icons_fb.svg`}
                         alt='Mindsets on Facebook'/>
                </a>
                <a href='http://instagram.com/gomindsets'>
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icons_ig.svg`}
                         alt='Mindsets on Instagram'/>
                </a>
                <a href='https://twitter.com/gomindsets'>
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icons_twitter.svg`}
                         alt='Mindsets on Twitter'/>
                </a>
            </div>
        </div>
    );
}
