import React from 'react'

import ContentCode from './ContentCode'
import ContentLink from './ContentLink'
import ContentClassroom from './ContentClassroom'
import ContentTeams from './ContentTeams'
import ContentClever from './ContentClever'


let selections = [
    {
        key: 'code',
        icon: (
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_setup_share_code.png`} alt='Share code'/>
        ),
        title: `Share the Code`,
        ContentComponent: ContentCode
    },
    {
        key: 'link',
        icon: (
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_setup_share_link.png`} alt='Share link'/>
        ),
        title: `Share Link`,
        ContentComponent: ContentLink
    },
    {
        key: 'classroom',
        icon: (
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_setup_google_classroom.png`} alt='Share Google Classroom'/>
        ),
        title: `Google Classroom`,
        ContentComponent: ContentClassroom
    },
    {
        key: 'teams',
        icon: (
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_setup_microsoft_teams.png`} alt='Share MS Teams'/>
        ),
        title: `Microsoft Teams`,
        ContentComponent: ContentTeams
    },
    {
        key: 'clever',
        icon: (
            <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_setup_clever.png`} alt='Clever'/>
        ),
        title: `Clever`,
        ContentComponent: ContentClever
    }
];

export const CLASS_SHARE_SELECTIONS = selections;
