import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import Item from './Item'


class Items extends Component {
    renderTop() {
        return (
            <div className="cc-selector-items-top">
                <div>
                    <strong>
                        {get(this.props.component_data, 'data.title_1')}
                    </strong>
                </div>
                <div className="cc-selector-items-top-description">
                    {get(this.props.component_data, 'data.description_1')}
                </div>
            </div>
        )
    }

    renderItems() {
        const items = get(this.props.component_data, 'data.items', [])
        const render_items = items.map((item) => {
            return (
                <div key={item.id}>
                    <Item
                        component_data={this.props.component_data}
                        item={item}
                        onClickItem={this.props.onClickItem}
                    />
                </div>
            )
        })

        return (
            <div className="flex-row flex-wrap flex-box-10-10">
                {render_items}
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-selector-items">
                {this.renderTop()}
                {this.renderItems()}
            </div>
        )
    }
}

Items.propTypes = {
    component_data: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired
}

export default Items