import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {sortBy, reverse, take} from 'lodash';
import Highcharts from 'highcharts';
import MindsetsJsSDK, {ErrorMessageAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const selected_school_id = useSelector(state => state.pam_selected_school_id);
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);
    const [schools, setSchools] = useState([]);

    function loadSchoolsData() {
        MindsetsJsSDK().API.get('sam/top_schools', {
            selected_school_id: selected_school_id ? selected_school_id : '',
            selected_from: from,
            selected_till: till
        }).then((response) => {
            setSchools(response.schools);
        }).catch((error) => {
            console.error('error', error);
            dispatch(ErrorMessageAction(error));
        })
    }

    function drawByClasses() {
        const sorted = reverse(
            sortBy(schools, 'no_of_classes')
        )
        const top_schools = take(sorted, 5)

        const school_names = top_schools.map((c) => c.school_name)
        const school_colors = ['#C61E60', '#E95D0C', '#4A148C', '#01579B', '#219653']
        const school_data = top_schools.map((c) => c.no_of_classes)

        const getTitle = () => {
            if (!selected_school_id && schools.length > 1) {
                return 'Top Schools by Classes'
            } else {
                return 'Your School by Classes'
            }
        }

        const graph = {
            chart: {
                type: 'bar'
            },
            title: {
                text: getTitle()
            },
            tooltip: {
                enabled: false,
                crosshairs: true
            },
            plotOptions: {
                bar: {
                    maxPointWidth: 100
                },
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            xAxis: {
                title: {
                    text: 'Schools'
                },
                labels: {
                    style: {
                        width: '100px'
                    }
                },
                categories: school_names
            },
            yAxis: {
                title: {
                    text: 'Number of Classes'
                },
                allowDecimals: false
            },
            series: [{
                name: 'Number of Classes',
                data: school_data,
                colorByPoint: true,
                colors: school_colors
            }],
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        }

        Highcharts.chart('sam-top-schools-by-classes', graph)
    }

    function drawByStudents() {
        const sorted = reverse(
            sortBy(schools, 'no_of_student_streams')
        )
        const top_schools = take(sorted, 5)

        const school_names = top_schools.map((c) => c.school_name)
        const school_colors = ['#C61E60', '#E95D0C', '#4A148C', '#01579B', '#219653']
        const school_data = top_schools.map((c) => c.no_of_student_streams)

        const getTitle = () => {
            if (!selected_school_id && schools.length > 1) {
                return 'Top Schools by Students'
            } else {
                return 'Your School by Students'
            }
        }

        const graph = {
            chart: {
                type: 'bar'
            },
            title: {
                text: getTitle()
            },
            tooltip: {
                enabled: false,
                crosshairs: true
            },
            plotOptions: {
                bar: {
                    maxPointWidth: 100
                },
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            xAxis: {
                title: {
                    text: 'Schools'
                },
                labels: {
                    style: {
                        width: '100px'
                    }
                },
                categories: school_names
            },
            yAxis: {
                title: {
                    text: 'Number of Students'
                },
                allowDecimals: false
            },
            series: [{
                name: 'Number of Students',
                data: school_data,
                colorByPoint: true,
                colors: school_colors
            }],
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        }

        Highcharts.chart('sam-top-schools-by-students', graph)
    }

    function renderByClasses() {
        return (
            <div>
                <div id="sam-top-schools-by-classes"/>
            </div>
        );
    }

    function renderByStudents() {
        return (
            <div>
                <div id="sam-top-schools-by-students"/>
            </div>
        );
    }

    useEffect(() => {
        loadSchoolsData();
    }, []);

    useEffect(() => {
        loadSchoolsData();
    }, [selected_school_id, from, till]);

    useEffect(() => {
        drawByClasses();
        drawByStudents();
    }, [schools]);

    return (
        <div>
            <div className="flex-row-l flex-box-20-20">
                <div className="flex-1">
                    {renderByClasses()}
                </div>

                <div className="flex-1">
                    {renderByStudents()}
                </div>
            </div>
        </div>
    );
}
