import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {LoadingAction, authUtils} from 'mindsets-js-sdk';

import {PasswordResetAction, PasswordUpdateAction} from "../../actions/login_actions";

export default function () {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [new_password, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [valid, setValid] = useState(null);

    function submit() {
        dispatch(LoadingAction(true));
        dispatch(PasswordUpdateAction({new_password}))
            .then(() => window.location.href = '/')
            .catch(setError)
            .then(() => dispatch(LoadingAction(false)));
    }

    function renderNewPasswordInput() {
        return (
            <input
                className="password-new-password input-new browser-default"
                type="password"
                name="new_password"
                placeholder="Password, minimum 6 characters"
                maxLength="30"
                value={new_password}
                onChange={event => setNewPassword(event.target.value)}
            />
        )
    }

    function renderError() {
        if (error) {
            return (
                <div className="password-error">
                    {error}
                </div>
            )
        }

        return null;
    }

    function renderSubmitButton() {
        return (
            <button
                className="password-submit btn btn-challenge"
                disabled={!authUtils.isValidPassword(new_password)}
                onClick={submit}
            >
                Submit
            </button>
        );
    }

    function renderBody() {
        return (
            <>
                <h4 className="header-new">{`Change Your Password`}</h4>,
                <div className="flex-column flex-vh-center">
                    <div className="section">
                        <div className="password-description center-align">
                            {`Enter your new password below`}
                        </div>
                    </div>

                    {renderNewPasswordInput()}

                    {renderError()}

                    {renderSubmitButton()}
                </div>
            </>
        );
    }

    function renderInvalid() {
        return (
            <div
                className="password-error">{error ? error : 'The security code is invalid or expired.'}</div>
        );
    }

    function renderContent() {
        if (valid === true) {
            return renderBody();
        } else if (valid === false) {
            return renderInvalid();
        }

        return null;
    }

    useEffect(() => {
        if (searchParams.has('t')) {
            dispatch(LoadingAction(true, 'Verifying...'));
            dispatch(PasswordResetAction({t: searchParams.get('t')}))
                .then(() => setValid(true))
                .catch(error => {
                    setError(error);
                    setValid(false);
                })
                .then(() => dispatch(LoadingAction(false)));
        }
    }, []);

    return (
        <div id='password-reset'>
            <div className="container">
                <div className="password-container z-depth-1 flex-column flex-vh-center">
                    <div className="password-icon">
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_password.svg`}
                             alt='Password'/>
                    </div>

                    {renderContent()}
                </div>
            </div>
        </div>
    );
}
