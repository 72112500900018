import React, {useEffect} from 'react';
import PropTypes from 'prop-types';


function ShareToTeams({challenge_version, lesson}) {
    function getAssignmentTitle() {
        return `Start the Mindsets ${challenge_version.name} Challenge`
    }

    function getAssignmentInstruction() {
        const code = lesson.code.toUpperCase()
        return `${challenge_version.description}`
        // + ` Sign in to Mindsets and enter Mindsets Code: ${code} to start.`
    }

    useEffect(() => {
        shareToMicrosoftTeams.renderButtons();
    }, []);

    return (
        <div
            className="teams-share-button"
            data-href={`${window.location.origin}/join/${lesson.code}`}
            data-icon-px-size="40"
            data-preview="true"
            data-msg-text={getAssignmentInstruction()}
            data-assign-title={getAssignmentTitle()}
            data-assign-instr={getAssignmentInstruction()}
        >
        </div>
    )
}

ShareToTeams.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired,
    lesson: PropTypes.object.isRequired
}

export default ShareToTeams
