import React, {Component} from 'react'
import PropTypes from 'prop-types'

import PollHeader from './PollHeader'
import PollOptions from './PollOptions'
import PollGraph from './PollGraph'


class PollUI extends Component {
    renderHeader() {
        if (this.props.component_data.type === 'poll.block') {
            return (
                <PollHeader
                    component_data={this.props.component_data}
                />
            )
        }
    }

    renderOptions() {
        return (
            <PollOptions
                component_data={this.props.component_data}
                poll={this.props.poll}
                lesson_id={this.props.lesson_id}
                stream_id={this.props.stream_id}
                stream_entity_id={this.props.stream_entity_id}
                is_preview={this.props.is_preview}
                my_vote={this.props.my_vote}
                setMyVote={this.props.setMyVote}
                lesson_votes={this.props.lesson_votes}
            />
        )
    }

    renderGraph() {
        if (this.props.component_data.type === 'poll.inline') {
            return null
        }

        if (!this.props.lesson_id) {
            return null
        }

        if (this.props.is_preview || this.props.my_vote) {
            return (
                <PollGraph
                    component_data={this.props.component_data}
                    poll={this.props.poll}
                    lesson_id={this.props.lesson_id}
                    lesson_votes={this.props.lesson_votes}
                />
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-poll">
                <div className='poll-new'>
                    {this.renderHeader()}

                    <div className="section">
                        {this.renderOptions()}
                    </div>

                    {this.renderGraph()}
                </div>
            </div>
        )
    }
}

PollUI.propTypes = {
    component_data: PropTypes.object.isRequired,
    poll: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    stream_entity_id: PropTypes.number.isRequired,
    is_preview: PropTypes.bool.isRequired,
    my_vote: PropTypes.number,
    setMyVote: PropTypes.func.isRequired,
    lesson_votes: PropTypes.array.isRequired
}

export default PollUI
