import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import PollGraphUI from './PollGraphUI'


class PollGraph extends Component {
    render() {
        return (
            <PollGraphUI
                poll={this.props.poll}
                lesson_votes={this.props.lesson_votes}
                custom_graph={get(this.props.component_data, 'config.graph')}
                show_empty={this.props.show_empty}
            />
        )
    }
}

PollGraph.propTypes = {
    component_data: PropTypes.object.isRequired,
    poll: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    show_empty: PropTypes.bool,
    lesson_votes: PropTypes.array.isRequired
}

export default PollGraph
