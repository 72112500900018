import React, {useEffect} from 'react';
import {useSelector} from "react-redux";

import ShareToClassroom from './ShareTo/ShareToClassroom';

export default function () {
    const lesson = useSelector(state => state.lesson);

    function renderShareToGoogleClassroomIcon() {
        gapi.sharetoclassroom.render("content-classroom-icon", {
            title: `Start the Mindsets ${lesson.challenge_version.name} Challenge`,
            body: `${lesson.challenge_version.description}\n\n`
                + `Sign in to Mindsets and enter Mindsets Code: ${lesson.code.toUpperCase()} to start.`,
            url: `${window.location.origin}/join/${lesson.code}`,
            size: 32
        })
    }

    function renderShareToGoogleClassroomButton() {
        return (
            <div className="content-classroom-icon-container">
                <div id="content-classroom-icon"/>
            </div>
        );
    }

    useEffect(() => {
        setTimeout(() => {
            renderShareToGoogleClassroomIcon();
        }, 2000);
    }, []);

    return (
        <div className="class-share-content-classroom">
            <div className="flex-row-m flex-wrap flex-box-15-15">
                <div className="flex-1">
                    <h5 className="header-new">
                        {`Share to Google Classroom`}
                    </h5>

                    <div className="content-classroom-description">
                        {`You'll be redirected to Google Classroom for sharing.`}
                    </div>

                    <div className="flex-row flex-v-center">
                        {renderShareToGoogleClassroomButton()}
                        <div className="content-classroom-icon-helper">
                            <i className="fas fa-hand-point-left"/>
                            <span>
                                {`Click here to share`}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="content-classroom-divider"/>

                <div className="flex-1">
                    <h5 className="header-new">
                        {`Assign students from Google Classroom`}
                    </h5>

                    <div className="content-classroom-description">
                        {`This will sync the roster you have in Google Classroom with Mindsets.`}
                    </div>

                    <div className="flex-row">
                        <ShareToClassroom/>
                    </div>
                </div>
            </div>
        </div>
    );
}
