import React from 'react';
import moment from 'moment';
import {useSelector} from "react-redux";

import {DATE_FORMAT} from 'constants/index.js';

export default function () {
    const district_subscription = useSelector(state => state.me_district_subscription);

    return (
        <div className='profile-membership-card'>
            <div className="">
                <div className="flex-row">
                    <div className={'plan-logo district'}>
                        <i className="fas fa-address-card"></i>
                    </div>
                </div>
                <div className='mt-2'>Membership</div>
                <h4 className="header-new mt-0">District Plan</h4>
            </div>

            <div className="section flex-column card-rows">
                <div className="flex-row">
                    <div className='card-label'>District:</div>
                    <div>{district_subscription.name}</div>
                </div>
                <div className="flex-row">
                    <div className='card-label'>Valid until:</div>
                    <div>{moment(district_subscription.ends_at).format(DATE_FORMAT)}</div>
                </div>
            </div>
        </div>
    );
};
