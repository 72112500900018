import React from 'react';
import {Link, Outlet} from "react-router-dom";
import MindsetsJsSDK, {CustomToast, Loader} from "mindsets-js-sdk";

import Footer from './Footer';
import NavBar from "./NavBar";

export default function () {
    function renderNavBar() {
        if (MindsetsJsSDK().Auth.isAuthenticated()) {
            return <NavBar/>;
        }

        return null;
    }

    function renderGuestContent() {
        if (!MindsetsJsSDK().Auth.isAuthenticated()) {
            return (
                <div className="container">
                    <div className="section center">
                        <Link to="/">
                            <img className="mindsets-logo-guest"
                                 src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                                 alt="Mindsets Learning Inc."/>
                        </Link>
                    </div>
                </div>
            );
        }

        return null;
    }

    return (
        <>
            <CustomToast/>
            <header>
                {renderNavBar()}
                {renderGuestContent()}
            </header>
            <main>
                <Outlet/>
            </main>
            <footer className="page-footer">
                <Footer/>
            </footer>
            <Loader/>
        </>
    );
}
