import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';

import FeatureLockContent from './FeatureLockContent';

import './feature_lock.scss';


function FeatureLock(
    {
        enable = false,
        title = 'This feature is locked',
        message,
        feature_type,
        onClickCallback,
        show_price_link = true,
        children
    }
) {
    const [modal_open, setModalOpen] = useState(false);

    function renderLayer() {
        if (enable) {
            return null;
        }

        const onClick = (e) => {
            e.stopPropagation();

            if (typeof onClickCallback === 'function') {
                onClickCallback();
            }
            setModalOpen(true);
        }

        return (
            <div className="feature-lock-layer flex-row flex-vh-center" onClick={onClick}>
                <i className="fas fa-lock"/>
            </div>
        );
    }

    function renderTitle() {
        return (
            <h4 className="header-new">
                {title}
            </h4>
        );
    }

    function renderModalContent() {
        return (
            <div className="center-align">
                <div className="feature-lock-icon">
                    <i className="fas fa-lock"/>
                </div>

                {renderTitle()}

                <FeatureLockContent
                    message={message}
                    feature_type={feature_type}
                    show_price_link={show_price_link}
                />
            </div>
        );
    }

    function renderModal() {
        if (enable) {
            return null;
        }

        return (
            <Modal
                open={modal_open}
                onClose={() => setModalOpen(false)}
                showCloseIcon={true}
                classNames={{modal: 'feature-lock-modal'}}
            >
                {renderModalContent()}
            </Modal>
        );
    }

    const class_name = classNames('feature-lock', {
        'feature-enable': enable,
        'feature-disable': !enable
    })

    return (
        <div className={class_name}>
            {children}

            {renderLayer()}

            {renderModal()}
        </div>
    );
}

FeatureLock.propTypes = {
    enable: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    feature_type: PropTypes.string,
    onClickCallback: PropTypes.func,
    show_price_link: PropTypes.bool
}

export default FeatureLock
