import React from "react";
import classNames from "classnames";
import {forEach, get, isEmpty, merge, set} from "lodash";

import BaseAnswer from "../BaseAnswer";
import SpreadsheetHeader from "./SpreadsheetHeader";
import SpreadsheetRow from "./SpreadsheetRow";

export default class Spreadsheet extends BaseAnswer {
    constructor(props) {
        super(props);
        this.ref_rows = []
    }

    isValid() {
        let is_valid = true;
        forEach(this.ref_rows, (row) => {
            forEach(row, (field) => {
                is_valid = is_valid && field.isValid()
            })
        });

        return is_valid;
    }

    isCorrect() {
        return true;
    }

    isEdited() {
        let is_edited = false
        forEach(this.ref_rows, (row) => {
            forEach(row, (field) => {
                is_edited = is_edited || field.isEdited()
            })
        })
        return is_edited
    }

    getAnswer() {
        const values = []
        forEach(this.ref_rows, (row, row_key) => {
            set(values, row_key, {})
            forEach(row, (field, col_key) => {
                set(values, [row_key, col_key], field.getValue())
            })
        })
        return values
    }

    refEditableField() {
        return (row) => (col) => (cell) => set(this.ref_rows, [row, col], cell)
    }

    renderTableHeader() {
        const header_data = get(this.props.component_data, 'data.headers')
        if (!isEmpty(header_data)) {
            return (
                <SpreadsheetHeader
                    data={header_data}
                />
            )
        }
    }

    renderTableRows() {
        const spreadsheet_body_data = merge(
            [],
            get(this.props.component_data, 'data.data'),
            get(this.props.existing_answer, 'data.answer', [])
        )
        const getPlaceholder = (row_key) => (col_key) => () => get(this.props.component_data, ['data', 'data', row_key, col_key])
        return spreadsheet_body_data.map((row, row_key) => {
            set(this.ref_rows, row_key, {});
            return (
                <SpreadsheetRow
                    key={row_key}
                    data={row}
                    is_preview={this.props.is_preview}
                    getPlaceholder={getPlaceholder(row_key)}
                    refEditableField={this.refEditableField()(row_key)}
                />
            )
        })
    }

    render() {
        const class_name = classNames(get(this.props.component_data, 'data.class_name'))

        return (
            <div className="challenge-component-spreadsheet">
                <div className="horizontal-scroll-container">
                    <table className={class_name}>
                        {this.renderTableHeader()}

                        <tbody>
                        {this.renderTableRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
