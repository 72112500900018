import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {get} from 'lodash';
import classNames from 'classnames';
import {Cookie, SetTeacherFacilitationShowStudentsAction} from "mindsets-js-sdk";

import {SHOW_STUDENTS_NAMES_COOKIE_NAME} from "../../index";
import analytic from "utils/Analytic";


export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);
    const selected_challenge_stream_entity = get(challenge_stream_entities, selected_card_index);

    function toggleStudent(show_student) {
        Cookie.setCookie(SHOW_STUDENTS_NAMES_COOKIE_NAME + lesson.code, show_student === true ? 'true' : 'false');
        dispatch(SetTeacherFacilitationShowStudentsAction(show_student));
        analytic.log('facilitationToggleStudentNames', {
            name: lesson.challenge_version.name,
            code: lesson.challenge_version.challenge_code.code,
        }, lesson.code, show_student);
    }

    function renderHeadStudent() {
        const fa_class = classNames('byquestion-streams-names-toggle', {
            'fas': true,
            'fa-eye': show_student,
            'fa-eye-slash': !show_student
        });
        const onClick = () => {
            toggleStudent(!show_student);
        }

        return (
            <th>
                <div className="flex-row flex-v-center flex-box-2-5">
                    <div>{`Students`}</div>
                    <i
                        className={fa_class}
                        title={'Click to show/hide student names'}
                        onClick={onClick}
                    />
                </div>
            </th>
        );
    }

    function renderHeadAnswer() {
        const answer_required = get(selected_challenge_stream_entity, 'data.answer_required')
        if (answer_required) {
            return (
                <th>
                    {`Answers`}
                </th>
            );
        } else {
            return (
                <th>
                    <div className="byquestion-streams-no-answer-required right-align">
                        {`No Answer Required`}
                    </div>
                </th>
            );
        }
    }

    return (
        <thead>
        <tr>
            {renderHeadStudent()}

            {renderHeadAnswer()}
        </tr>
        </thead>
    );
}
