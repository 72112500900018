import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
import {cloneDeep} from "lodash";

import Viewer from './Viewer.js';
import Toolbar from './Toolbar.js';

function PDFViewer({url, fileName}) {
    const [scale, setScale] = useState(0);
    const viewerRef = useRef();

    function zoomIn() {
        return setScale(Math.round(scale * 1.1 * 100) / 100);
    }

    function zoomOut() {
        return setScale(Math.round(scale / 1.1 * 100) / 100);
    }

    function onDownload() {
        let a = document.createElement('a');
        const blob = new Blob([url], {'type': 'application/pdf'});
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    }

    useEffect(() => {
        let loadingTask = pdfjsLib.getDocument(cloneDeep(url));
        loadingTask.promise.then((doc) => {
            viewerRef.current.setState({
                doc,
            });
        }, reason => console.error(reason));
    }, []);

    return (
        <div className="pdf-container">
            <Toolbar
                scale={scale}
                onZoomIn={zoomIn}
                onZoomOut={zoomOut}
                onDownload={onDownload}
            />
            <div className="pdf-body" id="pdf-body">
                <Viewer
                    ref={(ref) => viewerRef.current = ref}
                    scale={scale}
                    onScaleChanged={setScale}/>
            </div>
        </div>
    );
}

PDFViewer.propTypes = {
    url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            data: PropTypes.string
        })
    ]),
    fileName: PropTypes.string.isRequired
};

export default PDFViewer;
