import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {filter} from 'lodash';
import MindsetsJsSDK, {LoadingAction, rolesConstants, ErrorMessageAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import TeacherReportAction from 'common/TeacherReportAction';


function ClassReport({lesson_code, challenge_name, lesson_id, challenge_version_id, challenge_data_version}) {
    const dispatch = useDispatch();
    const [challenge_stream_entities, setChallengeStreamEntities] = useState([]);
    const [streams, setStreams] = useState([]);
    const [lesson, setLesson] = useState({});

    function fetchLesson() {
        return MindsetsJsSDK().API.get(`lessons/${lesson_id}`, {
            'selectors[0]': 'challenge_code_standards'
        }).then((response) => {
            setLesson(response.lesson);

            return response.lesson;
        }).catch((error) => {
            console.error(error)
        });
    }

    function fetchChallengeStreamEntities() {
        return MindsetsJsSDK().API.get(`challenge_versions/${challenge_version_id}/entities`).then((response) => {
            setChallengeStreamEntities(response.items);

            return response.items;
        }).catch((error) => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        });
    }

    function fetchLessonStreams() {
        return MindsetsJsSDK().API.get('streams', {
            'filters[lesson_id][0]': 'eq=' + lesson_id,
            'selectors[0]': 'user',
            'selectors[1]': 'stream_entities_responses',
            'limit': 0,
        }).then((response) => {
            const student_streams = filter(response.items, (s) => s.persona === rolesConstants.ROLE_STUDENT);
            setStreams(student_streams);
        }).catch(error => console.error(error));
    }

    function onClick() {
        dispatch(LoadingAction(true, 'Preparing class report...'));
        fetchLesson().then(() => {
        }).then(() => {
            return fetchChallengeStreamEntities()
        }).then(() => {
            return fetchLessonStreams()
        }).then(() => {
            dispatch(LoadingAction(false))
        });

        analytic.log(
            'samTeacherClassReportDownloaded',
            lesson_code,
            challenge_name
        );
    }

    if (challenge_data_version > 0) {
        return (
            <TeacherReportAction
                lesson={lesson}
                challenge_stream_entities={challenge_stream_entities}
                streams={streams}
            >
                <i className="fas fa-download" onClick={onClick}/>
            </TeacherReportAction>
        )
    }

    return 'N/A';
}

ClassReport.propTypes = {
    lesson_id: PropTypes.number.isRequired,
    lesson_code: PropTypes.string.isRequired,
    challenge_version_id: PropTypes.number.isRequired,
    challenge_name: PropTypes.string.isRequired,
    challenge_data_version: PropTypes.number.isRequired
}

export default ClassReport;
