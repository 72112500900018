import React from 'react';
import PropTypes from 'prop-types';

function Teacher({joined, teacher, addUser}) {
    function renderStatus() {
        if (joined) {
            return 'Joined';
        } else {
            return '';
        }
    }

    function renderAvatar() {
        if (teacher.avatar) {
            return (
                <div className={'cashtivity-icon ' + teacher.avatar}/>
            );
        }

        return (
            <i className={"fas fa-user-circle mr-2"}/>
        );
    }

    return (
        <div
            className={'flex-row flex-v-center my-2 teacher-item ' + (joined ? 'joined' : '')}
            onClick={() => joined ? null : addUser(teacher.id)}
        >
            {renderAvatar()}
            <div className='flex-grow-1'>
                <div>{teacher.fname} {teacher.lname}</div>
                <div className="flex-row flex-between">
                    <div>{teacher.email}</div>
                    <div>{renderStatus()}</div>
                </div>
            </div>
        </div>
    );
}

Teacher.propTypes = {
    teacher: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        fname: PropTypes.string.isRequired,
        lname: PropTypes.string,
        avatar: PropTypes.string
    }).isRequired,
    joined: PropTypes.bool.isRequired,
    addUser: PropTypes.func.isRequired
};

export default Teacher;
