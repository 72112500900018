import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {debounce, get} from 'lodash';
import {ErrorMessageAction} from "mindsets-js-sdk";

import {hasQuestions, storeAnswers} from '../ProgressToolBar/utils';
import analytic from 'utils/Analytic';


function ButtonRight({getRefComponents}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stream = useSelector(state => state.stream) || {};
    const current_index = useSelector(state => state.student_stream_current_index);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const stream_entities_responses = useSelector(state => state.student_stream_stream_entities_responses);
    const [enable, setEnable] = useState(hasQuestions(getRefComponents()));
    const stream_entities = stream_entities_responses.map((se, i) => {
        const challenge_se = get(challenge_stream_entities, i)
        return {
            ...challenge_se,
            ...se
        }
    });
    const stream_entity = get(stream_entities, current_index);

    const onClick = debounce(() => {
        if (!enable) {
            return
        }

        return Promise.resolve()
            .then(() => setEnable(false))
            .then(() => analytic.log('progressToolBarNext', {
                stream_id: stream.id,
                current_index,
                lesson_id: stream.lesson_id,
                stream_entity_id: stream_entity.id,
            }))
            .then(() => storeAnswers(getRefComponents(), {
                is_click: true
            }))
            .catch(error => {
                // This is to stop teacher progressing the student stream.
                // TODO Remove this after we have the teacher student stream page.
                if (error === 'Stream not found.') {
                    dispatch(ErrorMessageAction('This is the latest progress of this student.'));
                    return;
                }
                dispatch(ErrorMessageAction(error));
            })
            .then(() => setEnable(true));
    }, 200, {
        leading: false,
        trailing: true
    });

    function renderHomeButton() {
        const onClick = () => {
            navigate('/');
        }

        return (
            <div className="progress-tool-bar-nav progress-tool-bar-nav-right flex-row flex-vh-center"
                 onClick={onClick}>
                <i className="fas fa-home"/>
            </div>
        );
    }

    function renderNextButton() {
        return (
            <div
                className={"progress-tool-bar-nav progress-tool-bar-nav-right flex-row flex-vh-center" + (!enable ? ' disabled' : '')}
                onClick={onClick}
            >
                <span>SUBMIT</span>
            </div>
        );
    }

    useEffect(() => {
        if (!enable && hasQuestions(getRefComponents())) {
            setEnable(true);
        }
    }, []);

    useEffect(() => {
        if (!enable && hasQuestions(getRefComponents())) {
            setEnable(true);
        } else if (enable && !hasQuestions(getRefComponents())) {
            setEnable(false);
        }
    }, [enable, current_index]);

    if (!stream.id) {
        return null;
    }

    if (current_index === stream.challenge_version.final_index) {
        return renderHomeButton();
    } else {
        return renderNextButton();
    }
}

ButtonRight.propTypes = {
    getRefComponents: PropTypes.func.isRequired
}

export default ButtonRight
