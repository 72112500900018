import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {get} from 'lodash';
import {ErrorMessageAction, LoadingAction, Cookie, LoadMySchoolsAction} from "mindsets-js-sdk";

import './sam_school_filter.scss';
import {SetPAMSelectedSchoolIdAction} from "../../../actions/pam_actions";
import {FILTER_COOKIE_NAME} from "../../../pages/SAMDashboard";

export default function () {
    const dispatch = useDispatch();
    const schools = useSelector(state => state.schools);
    const selected_school_id = useSelector(state => state.pam_selected_school_id);

    function onSelect(e) {
        e.preventDefault();
        if (!e.target.value) {
            dispatch(SetPAMSelectedSchoolIdAction(null));
            Cookie.eraseCookie(FILTER_COOKIE_NAME + '_school_id');
        } else {
            dispatch(SetPAMSelectedSchoolIdAction(parseInt(e.target.value)));
            Cookie.setCookie(FILTER_COOKIE_NAME + '_school_id', parseInt(e.target.value), 14 * 24 * 60 * 60);
        }
    }

    function renderOptions() {
        return schools.map((school) => {
            return (
                <option key={school.id} value={school.id}>
                    {`${school.name}`}
                </option>
            )
        })
    }

    function renderFilter() {
        if (schools.length < 2) {
            return null;
        }

        return (
            <select
                value={selected_school_id ?? ''}
                disabled={schools.length <= 0}
                onChange={onSelect}
            >
                <option value="">
                    {`All Schools`}
                </option>
                {renderOptions()}
            </select>
        )
    }

    function renderSchoolName() {
        if (schools.length !== 1) {
            return null;
        }

        const school = get(schools, 0);

        return (
            <div className="sam-school-filter-name flex-row flex-v-center">
                {school.name}
            </div>
        );
    }

    useEffect(() => {
        dispatch(LoadMySchoolsAction())
            .then(schools => {
                if (schools.length === 1) {
                    const school_id = get(schools, [0, 'id']);
                    dispatch(SetPAMSelectedSchoolIdAction(school_id));
                    Cookie.setCookie(FILTER_COOKIE_NAME + '_school_id', school_id, 14 * 24 * 60 * 60);
                } else if (selected_school_id && schools.map(school => school.id).indexOf(selected_school_id) < 0) {
                    dispatch(SetPAMSelectedSchoolIdAction(null));
                    Cookie.eraseCookie(FILTER_COOKIE_NAME + '_school_id');
                }
            })
            .catch(error => {
                console.error(error);
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
            });
    }, []);

    return (
        <div className="sam-school-filter">
            {renderSchoolName()}
            {renderFilter()}
        </div>
    );
}
