import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Checkbox,
    LoadedMePreferencesAction,
    LoadMePreferencesAction,
    UpdateMePreferencesAction
} from "mindsets-js-sdk";

import {EMAIL_CATEGORIES} from 'constants/index'
import analytic from 'utils/Analytic'
import {isEducator} from "utils/role";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const state_preferences = useSelector(state => state.me_preferences);
    let preferences = JSON.parse(JSON.stringify(state_preferences));

    useEffect(() => {
        dispatch(LoadMePreferencesAction());
    }, []);

    if (me && isEducator(me) && preferences.length) {
        return (
            <div>
                <h4 className="header-new">Email Preferences</h4>

                <form onSubmit={event => {
                    event.preventDefault();
                    dispatch(UpdateMePreferencesAction({preferences}))
                        .then(() => {
                            let data = {};
                            preferences.map(preference => {
                                data[preference.category] = preference.disabled ? 'disabled' : 'enabled'
                            });
                            analytic.log('updateEmailPreferences', data);
                        })
                        .then(() => dispatch(LoadMePreferencesAction()));
                }}>
                    {preferences.map((preference, key) => {
                        return (
                            <div className='my-3' key={key}>
                                <Checkbox
                                    toggle={() => {
                                        preferences[key].disabled = !preference.disabled;
                                        dispatch(LoadedMePreferencesAction(preferences));
                                    }}
                                    checked={false === preference.disabled}
                                    label={EMAIL_CATEGORIES[preference.category]}
                                />
                            </div>
                        );
                    })}
                    <button className="btn btn-dark mt-4" type="submit" name="action">SAVE PREFERENCES</button>
                </form>
            </div>
        );
    }

    return null;
}
