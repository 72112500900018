import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {get, min} from 'lodash';
import Highcharts from 'highcharts';
import {getStateBasedTitle} from "../utils";

export default function ({printable}) {

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);

    const graph_id = 'pam-domain-graph';
    const domain_data = dashboard_data.domain_data;
    const skill_data = dashboard_data.skill_data;

    useEffect(() => {
        if (domain_data) {
            draw();
        }
    }, [domain_data, skill_data]);

    if (domain_data === undefined) {
        return null;
    }

    function getPointWidth() {
        const skill_n = get(skill_data, 'categories', []).length;
        const skill_width = Math.round(200 / skill_n);
        const domain_n = get(domain_data, 'categories', []).length;
        const domain_width = Math.round(200 / domain_n);
        return min([skill_width, domain_width, 60]);
    }

    function getGraphHeight() {
        const n = get(domain_data, 'categories', []).length;
        return n * (getPointWidth() + 20) + 150;
    }

    function draw() {
        const graph = {
            chart: {
                type: 'bar',
                marginLeft: 230,
                height: getGraphHeight()
            },
            title: {
                text: getStateBasedTitle(dashboard).domain,
                style: {
                    fontWeight: 'bolder',
                    fontSize: '18px'
                }
            },
            xAxis: {
                categories: domain_data.categories,
                labels: {
                    style: {}
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Student Achievement'
                },
                labels: {
                    format: '{text}%'
                }
            },
            tooltip: {
                pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                bar: {
                    stacking: 'percent',
                    maxPointWidth: 60,
                    pointWidth: getPointWidth()
                },
                series: {
                    stacking: 'normal'
                }
            },
            series: domain_data.series,
            legend: {
                enabled: true,
                reversed: true
            },
            credits: {
                enabled: false
            }
        }

        if (printable) {
            graph.tooltip.enabled = false;
        }

        Highcharts.chart(graph_id, graph)
    }

    return (
        <div>
            <div id={graph_id} className="pam-component"/>
        </div>
    );
}
