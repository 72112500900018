import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class PollHeader extends Component {
    renderIcon() {
        return (
            <div className="poll-header-icon flex-row flex-h-center">
                <i className="fas fa-poll-h fa-3x"></i>
            </div>
        )
    }

    renderTitle() {
        const {title} = this.props.component_data.data
        if (title) {
            return (
                <div className="poll-header-title flex-row flex-h-center">
                    <h4 className="header-new center-align">
                        {processStringContent(title)}
                    </h4>
                </div>
            )
        }
    }

    renderDescription() {
        const {description} = this.props.component_data.data
        if (description) {
            return (
                <div className="section">
                    <div className="poll-header-description flex-row flex-h-center center-align">
                        {processStringContent(description)}
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="poll-header">
                {this.renderIcon()}
                {this.renderTitle()}
                {this.renderDescription()}
            </div>
        )
    }
}

PollHeader.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default PollHeader
