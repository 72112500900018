import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import MindsetsJsSDK from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);

    if (!MindsetsJsSDK().Auth.isAuthenticated()) {
        return <Navigate to='/login'/>;
    }
    if (me) {
        if (me.persona === 'student') {
            return <Navigate to='/student/home'/>;
        } else {
            return <Navigate to='/teacher/home'/>;
        }
    } else {
        return null;
    }
}
