import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as microsoftTeams from "@microsoft/teams-js";
import {filter, isEmpty, find, sortBy, groupBy, map} from 'lodash';
import {filterConstants, LoadChallengeVersionsAction, LoadCurriculaAction} from "mindsets-js-sdk";

import {extractTopics, extractGrades, extractThemes, getCategoryText, sortCategories} from "common/challengeNew/utils";
import ChallengesFilter from './ChallengesFilter';
import GuestChallengeCard from './GuestChallengeCard';
import analytic from 'utils/Analytic';

export default function () {
    const dispatch = useDispatch();
    const challenge_versions = useSelector(state => state.challenge_versions);
    const curriculums = useSelector(state => state.curricula);
    const [selected_curriculum, setSelectedCurriculum] = useState(1);
    const [selected_subject, setSelectedSubject] = useState('math');
    const [selected_topic, setSelectedTopic] = useState('');
    const [selected_grade, setSelectedGrade] = useState('');
    const [selected_theme, setSelectedTheme] = useState('');
    const [selected_measure, setSelectedMeasure] = useState('');

    const topics = extractTopics(challenge_versions);
    const grades = extractGrades(challenge_versions);
    const themes = extractThemes(challenge_versions);

    function getCurriculums() {
        dispatch(LoadCurriculaAction({
            'filters[is_enabled][0]': filterConstants.EXPRESSION_TRUE,
            limit: 0
        }));
    }

    function getChallengesFromServer() {
        dispatch(LoadChallengeVersionsAction({limit: 0}));
    }

    function filterChallenges() {
        return filter(challenge_versions, (challenge_version) => {
            if ('active' !== challenge_version.challenge_code.status) {
                return false;
            }
            if (challenge_version.challenge_code.subject !== selected_subject) {
                return false;
            }
            if (selected_topic && '' !== selected_topic) {
                if (challenge_version.challenge_code.standards
                    .filter(standard => (standard.curriculum_id === selected_curriculum && standard.topic === selected_topic))
                    .length <= 0) {
                    return false;
                }
            }
            if (selected_grade && '' !== selected_grade) {
                if (challenge_version.challenge_code.standards
                    .filter(standard => (standard.curriculum_id === selected_curriculum && standard.grade === selected_grade))
                    .length <= 0) {
                    return false;
                }
            }
            if (selected_theme && '' !== selected_theme) {
                if (challenge_version.challenge_code.themes.indexOf(selected_theme) < 0) {
                    return false;
                }
            }
            if (selected_measure && '' !== selected_measure) {
                if (challenge_version.challenge_code.measure && challenge_version.challenge_code.measure !== selected_measure) {
                    return false;
                }
            }

            return true;
        });
    }

    function getSelectedCurriculum() {
        const selected_curriculum_id = selected_curriculum ? selected_curriculum : 1
        return find(curriculums, {id: selected_curriculum_id})
    }

    function onPreview(challenge_version) {
        let taskInfo = {
            title: `Challenge Preview: ${challenge_version.name}`,
            height: 600,
            width: 1100,
            url: `${window.location.origin}/teams/challenge_preview/${challenge_version.challenge_code.code}`,
            card: null,
            fallbackUrl: null,
            completionBotId: null
        }
        microsoftTeams.tasks.startTask(taskInfo)

        analytic.log('teamsPreviewChallenge', {
            name: challenge_version.name,
            code: challenge_version.challenge_code.code
        })
    }

    function renderChallenges() {
        const curriculum = getSelectedCurriculum()
        const filtered_challenges = filterChallenges()
        const grade_translated_challenges = map(filtered_challenges, (c) => {
            const grade = c.challenge_code.grades[curriculum.code]
            c.grade_translated = getCategoryText(grade)
            return c
        })
        const ordered_challenges = sortBy(grade_translated_challenges, (c) => c.name)
        const categorised_challenges = sortCategories(groupBy(ordered_challenges, (c) => c.grade_translated))
        const render_categories = map(categorised_challenges, (category, grade) => {
            const render_challenges = category.map((challenge_version) => {
                return (
                    <div key={challenge_version.challenge_code.code}>
                        <GuestChallengeCard
                            challenge_version={challenge_version}
                            curriculum={curriculum}
                            onPreview={() => onPreview(challenge_version)}
                        />
                    </div>
                );
            });
            return (
                <div className="section" key={grade}>
                    <h4 className="header-new">
                        {grade}
                    </h4>
                    <div className="flex-row flex-wrap flex-box-10-10">
                        {render_challenges}
                    </div>
                    <div className="section">
                    </div>
                    <div className="divider"/>
                </div>
            )
        });

        return (
            <div>
                {render_categories}
            </div>
        );
    }

    function renderFilters() {
        return (
            <ChallengesFilter
                curriculums={curriculums}
                topics={topics}
                grades={grades}
                themes={themes}
                selected_curriculum={selected_curriculum}
                selected_topic={selected_topic}
                selected_grade={selected_grade}
                selected_theme={selected_theme}
                selected_measure={selected_measure}
                setStateValue={(k, v) => {
                    switch (k) {
                        case 'selected_curriculum':
                            setSelectedCurriculum(v);
                            break;
                        case 'selected_subject':
                            setSelectedSubject(v);
                            break;
                        case 'selected_topic':
                            setSelectedTopic(v);
                            break;
                        case 'selected_grade':
                            setSelectedGrade(v);
                            break;
                        case 'selected_theme':
                            setSelectedTheme(v);
                            break;
                        case 'selected_measure':
                            setSelectedMeasure(v);
                            break;

                    }
                }}
            />
        );
    }

    useEffect(() => {
        microsoftTeams.initialize()
    }, []);

    useEffect(() => {
        getCurriculums();
        getChallengesFromServer();
    }, []);

    if (isEmpty(challenge_versions) || isEmpty(curriculums)) {
        return null
    }

    return (
        <div id="teams-challenge-library">
            <div>
                <div className="section">
                    {renderFilters()}
                </div>

                <div className="section">
                    {renderChallenges()}
                </div>
            </div>
        </div>
    );
}
