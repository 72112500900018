import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class Text extends Component {
    getHTML() {
        return this.props.component_data.data.html
    }

    getContent() {
        return processStringContent(this.getHTML())
    }

    render() {
        return (
            <div className="challenge-component-text max-width-850">
                <div className="horizontal-scroll-container">
                    {this.getContent()}
                </div>
            </div>
        )
    }
}

Text.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Text
