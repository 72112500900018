import {store} from "../../index";


export function extractTopics(challenge_versions) {
    let topics = [];
    const curriculum_id = store.getState().me && store.getState().me.curriculum_id ? store.getState().me.curriculum_id : 1;
    const subject = store.getState().library_subject;

    challenge_versions.filter(challenge_version => challenge_version.challenge_code.subject === subject).map(challenge_version => {
        challenge_version.challenge_code.standards
            .filter(standard => standard.curriculum_id === curriculum_id)
            .map(standard => {
                if (!topics.includes(standard.topic)) {
                    topics.push(standard.topic);
                }
            });
    });

    return topics.sort();
}

export function extractGrades(challenge_versions) {
    let grades = [];
    const curriculum_id = store.getState().me && store.getState().me.curriculum_id ? store.getState().me.curriculum_id : 1;
    const subject = store.getState().library_subject;

    challenge_versions.filter(challenge_version => challenge_version.challenge_code.subject === subject).map(challenge_version => {
        challenge_version.challenge_code.standards
            .filter(standard => standard.curriculum_id === curriculum_id)
            .map(standard => {
                const grade = standard.grade.padStart(10, ' ');
                if (!grades.includes(grade)) {
                    grades.push(grade);
                }
            });
    });

    return grades
        .sort()
        .map(grade => grade.trim());
}

export function extractThemes(challenge_versions) {
    let themes = [];
    const subject = store.getState().library_subject;

    challenge_versions.filter(challenge_version => challenge_version.challenge_code.subject === subject).map(challenge_version => {
        challenge_version.challenge_code.themes
            .map(theme => {
                if (themes.indexOf(theme) < 0) {
                    themes.push(theme);
                }
            });
    });

    return themes.sort();
}

export function getCategoryText(grade) {
    switch (grade.toString()) {
        case '2':
        case '3':
        case '4':
        case '5':
            return 'Beginner'
        case '6':
        case '7':
            return 'Intermediate'
        case '8':
        case '9':
        case '10':
        case '11':
        case '9 - 12':
            return 'Advanced'
        default:
            return grade
    }
}

export function sortCategories(categories) {
    let ordered_categories = {};
    if (categories.hasOwnProperty('Beginner')) {
        ordered_categories['Beginner'] = categories['Beginner'];
    }
    if (categories.hasOwnProperty('Intermediate')) {
        ordered_categories['Intermediate'] = categories['Intermediate'];
    }
    if (categories.hasOwnProperty('Middle School')) {
        ordered_categories['Middle School'] = categories['Middle School'];
    }
    if (categories.hasOwnProperty('Advanced')) {
        ordered_categories['Advanced'] = categories['Advanced'];
    }
    if (categories.hasOwnProperty('High School')) {
        ordered_categories['High School'] = categories['High School'];
    }

    return ordered_categories;
}
