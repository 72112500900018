import React from 'react'

import PAMMenuNormal from './PAMMenuNormal'
import MobileNavMenu from "common/MobileNavMenu";


export default class PAMMenuMobile extends PAMMenuNormal {
    renderSideMenu() {
        return (
            <>
                <div className="section">
                    <h4 className="header-new center-align">
                        {`Performance Dashboard`}
                    </h4>
                    <div className="divider">
                    </div>
                </div>

                <div className="flex-column flex-box-10-10">
                    {this.renderMenuItem('challenge')}
                </div>
            </>
        )
    }

    render() {
        return (
            <div className="pam-menu-mobile flex-row flex-v-center">
                <div className="pam-menu-hamburger-name hide-on-med-and-up">
                    {this.getPageName(this.page_name)}
                </div>
                <MobileNavMenu class_name='hide-on-med-and-up'>
                    {this.renderSideMenu()}
                </MobileNavMenu>
            </div>
        )
    }
}
