import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page8'>
            <div className='contents'>
                <div className='title'>Your Top Mindsets Strengths</div>
                <div className='practices'>
                    {data.top_strengths.map(skill => {
                        return (
                            <img
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                alt={skill}/>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}