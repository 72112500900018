import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }

    return (
        <div className="student-dashboard-welcome">
            <div className="flex-row flex-vh-center flex-wrap flex-box-2-5">
                <div>
                    <i className="far fa-heart small"/>
                </div>

                <div>
                    <h4 className="header-new center-align">
                        {`Welcome to your library ${me.fname + (me.lname ? (' ' + me.lname) : '')}!`}
                    </h4>
                </div>
            </div>
        </div>
    );
}
