import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page16'>
            <div className='main-title'>... and that's a wrap</div>
            <div className='certificate'>
                <div className='certificate-row'>
                    <div className='students-learn'>
                        <div className='students-learn-title'>With Mindsets Challenges, students learn to:</div>
                        <ul>
                            <li>Collaborate</li>
                            <li>Create ideas</li>
                            <li>Make predictions</li>
                            <li>Analyze & reason with data then pitch their concepts.</li>
                        </ul>
                    </div>
                    <div className='students-apply'>
                        Students apply <strong>Mindsets Skills</strong> which include the8 essential math practices,
                        literacy & STEAM skills in a real world context, they demonstrate the entrepreneurial spirit &
                        prepare for future success.
                    </div>
                </div>
                <div className='certificate-row second'>
                    <img
                        className='challenge-badge'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/challenge_catchy_clothing.png`}
                        alt='Catchy clothing'
                    />
                    <img
                        className='challenge-badge'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/challenge_delivery_dash.png`}
                        alt='Delivery Dash'
                    />
                    <img
                        className='challenge-badge'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/challenge_best_road_trip.png`}
                        alt='Best road trip'
                    />
                </div>
                <div className='certificate-row third'>
                    <img
                        className='gear-badge'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/badge_teamwork.png`}
                        alt='Team work'/>
                    <img
                        className='mindsets-logo'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                        alt='Mindsets Logo'/>
                    <img
                        className='gear-badge'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/badges/badge_creativity.png`}
                        alt='Creativity'/>
                </div>
            </div>
        </div>
    );
}
