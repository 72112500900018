import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Cookie, LoadAnnouncementsAction, LoadedAnnouncementsAction} from "mindsets-js-sdk";

import Announcement from './components/Announcement';

import './announcements.scss';

export default function () {
    const dispatch = useDispatch();
    const announcements = useSelector((state) => state.announcements);

    function onClose(announcement) {
        const announcements_new = JSON.parse(JSON.stringify(announcements));
        const count = Cookie.getCookie('mindsets_announcement_count_' + announcement.id) ? parseInt(Cookie.getCookie('mindsets_announcement_count_' + announcement.id)) : 0;
        if (announcement.dismissable_after && announcement.dismissable_after - 1 > count) {
            Cookie.setCookie(
                'mindsets_announcement_count_' + announcement.id,
                count + 1,
                604800
            );
            announcements_new.splice(announcements_new.indexOf(announcement), 1);
        } else {
            Cookie.setCookie('mindsets_announcement_' + announcement.id, 'closed', 604800);
        }
        dispatch(LoadedAnnouncementsAction(announcements_new));
    }

    useEffect(() => {
        dispatch(LoadAnnouncementsAction());
    }, []);

    return (
        <div>
            {announcements
                .filter(announcement => Cookie.getCookie('mindsets_announcement_' + announcement.id) !== 'closed')
                .filter(announcement => {
                    if (!announcement.target) {
                        return true;
                    }

                    const group_keys = Object.keys(announcement.target);
                    if (group_keys.length > 0) {
                        let element_found = false
                        group_keys.map(group_key => {
                            if (Array.isArray(announcement.target[group_key])) {
                                announcement.target[group_key].map(item_key => {
                                    if (!element_found) {
                                        element_found = !!document.getElementById(item_key);
                                    }
                                });
                            }
                        });

                        return element_found;
                    }

                    return true;
                })
                .map((announcement, index) => {
                    return (
                        <Announcement
                            key={announcement.id}
                            announcement={announcement}
                            index={index + 1}
                            onClose={onClose}
                        />
                    );
                })}
        </div>
    );
}
