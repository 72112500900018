import React from 'react';
import {useSelector} from "react-redux";


export default function () {
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }

    return (
        <div className="flex-row user-info-field">
            <div className="user-info-field-label">Name</div>
            <div>{me.fname + ' ' + me.lname}</div>
        </div>
    );
};
