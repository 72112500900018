import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const me = useSelector(state => state.me);
    if (!me || me.remote_provider === 'cashtivity-quick-join') {
        return null;
    }

    return (
        <div className="flex-row user-info-field">
            <div className="user-info-field-label">Email</div>
            <div>
                {
                    me.remote_provider !== 'cashtivity-quick-join' &&
                    me.email
                }
            </div>
        </div>
    );
}
