import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import moment from 'moment';
import MindsetsJsSDK, {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import {getContentStreamEntity, plainTextToImmersiveReader, objectsDiff} from 'utils/immersiveReader';

// import * as ImmersiveReader from '@microsoft/immersive-reader-sdk'

function ImmersiveReaderSwitch(
    {
        challenge_version,
        stream_entity,
        stream,
        from,
        content,
        tiny = false
    }
) {
    const dispatch = useDispatch();
    const [token, setToken] = useState(undefined);
    const [icon, setIcon] = useState(2);
    const [properties, setProperties] = useState({
        adjectiveHighlightingColor: "MiddleGreen",
        adjectiveHighlightingEnabled: false,
        adverbHighlightingColor: "MiddleYellow",
        adverbHighlightingEnabled: false,
        fontFamily: "Calibri",
        formattingEnabled: true,
        nounHighlightingColor: "MiddleMagenta",
        nounHighlightingEnabled: false,
        pictureDictionaryEnabled: true,
        posLabelsEnabled: false,
        syllabificationEnabled: false,
        textSize: 42,
        textSpacing: 40,
        theme: "Light",
        themeSetByUser: false,
        verbHighlightingColor: "MiddleRed",
        verbHighlightingEnabled: false,
        readAloudSpeed: 1,
        voice: "Female",
        shouldTranslateWords: false,
        translationLanguage: null
    });

    function getMSCSToken() {
        return new Promise((resolve, reject) => {
            if (token && moment(token.expires_on * 1000).isAfter(moment())) {
                resolve(token);
            } else {
                reject('MSCS token missing.');
            }
        }).catch(() => {
            dispatch(LoadingAction(true));
            return MindsetsJsSDK().API.get('cstoken').then((response) => {
                dispatch(LoadingAction(false));
                setToken(response.token);

                return response.token;
            })
        }).catch(error => {
            dispatch(LoadingAction(false));
            dispatch(ErrorMessageAction(error, true));

            throw error;
        })
    }

    function getContent() {
        if (content) {
            return plainTextToImmersiveReader(challenge_version, content)
        } else if (stream_entity) {
            return getContentStreamEntity(challenge_version, stream_entity)
        } else {
            return undefined
        }
    }

    function onPreferencesChangedCallback(string) {
        let object = JSON.parse(string);
        if (object.translationState.translationLanguage) {
            object.translationState.translationLanguage = JSON.parse(decodeURIComponent(object.translationState.translationLanguage)).text;
        }
        let data = Object.assign({}, object.displayOptionsState, object.readAloudState, object.translationState);
        const result = objectsDiff(properties, data);
        const key = Object.keys(result)[0];
        if (key && result[key] && result[key] !== '') {
            let properties_new = JSON.parse(JSON.stringify(properties));
            analytic.log('immersiveReaderChanged', {
                challenge_name: challenge_version.name,
                stream_id: !!stream ? stream.id : null,
                class_code: !!stream ? stream.lesson.code : null,
                from,
                key,
                before: properties_new[key],
                after: result[key]
            });
            properties_new[key] = result[key];
            setProperties(properties_new);
        }
    }

    function onExitCallback() {
        analytic.log('immersiveReaderClosed', {
            name: challenge_version.name,
            code: challenge_version.challenge_code.code
        }, stream_entity, from);
    }

    function openImmersiveReader(token) {
        return ImmersiveReader.launchAsync(
            token.access_token,
            'mindsets-immersive-reader',
            getContent(),
            {
                onPreferencesChanged: onPreferencesChangedCallback,
                onExit: onExitCallback
            }
        ).catch(() => dispatch(ErrorMessageAction('Open Reader service in not available at the moment. Please reload the page and try again or try it later.', true)))
    }

    function onClick() {
        getMSCSToken().then(openImmersiveReader);
        analytic.log('immersiveReaderOpened', {
            name: challenge_version.name,
            code: challenge_version.challenge_code.code
        }, stream_entity, from);
    }

    function renderButton() {
        if (tiny) {
            return (
                <div className="immersive-reader-tiny">
                    <button className="btn btn-flat btn-circle" onClick={onClick}>
                        <img
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_immersive_reader_1.svg`}
                            alt={`Immersive Reader`}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div
                    className="immersive-reader-normal"
                    onMouseOver={() => setIcon(1)}
                    onMouseOut={() => setIcon(2)}>
                    <button className="btn btn-on-dark" onClick={onClick}>
                        <img
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_immersive_reader_${icon}.svg`}
                            alt={`Immersive Reader`}/>
                        <div className="hide-on-small-only">
                            {`Open Reader`}
                        </div>
                    </button>
                </div>
            );
        }
    }

    return (
        <div className="immersive-reader">
            {renderButton()}
        </div>
    );
}

ImmersiveReaderSwitch.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    stream_entity: PropTypes.object,
    stream: PropTypes.shape({
        id: PropTypes.number.isRequired,
        lesson: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired
    }),
    from: PropTypes.string,
    content: PropTypes.string,
    tiny: PropTypes.bool
}

export default ImmersiveReaderSwitch
