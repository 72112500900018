import React from 'react';
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";

import {COUNTRIES} from 'constants/countries'
import {States} from 'constants/States'

export default function () {
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }

    if (me.country && COUNTRIES.hasOwnProperty(me.country)) {
        const country = COUNTRIES[me.country];
        if (me.state && States.hasOwnProperty(me.country) && States[me.country].hasOwnProperty(me.state)) {
            const state = States[me.country][me.state];
            return (
                <div>
                    <img
                        className='flag-image'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/flags/${me.country}/${me.state}.png`}
                        alt={state}
                        data-tip={state}
                    />
                    <ReactTooltip/>
                </div>
            );
        } else {
            return (
                <div>
                    <img
                        className='flag-image'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/flags/countries/${me.country}.png`}
                        alt={country}
                        data-tip={country}
                    />
                    <ReactTooltip/>
                </div>
            );
        }
    }

    return null;
}
