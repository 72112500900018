import {get} from 'lodash';

export const setTDWidth = (w) => {
    return {
        width: `${w}px`,
        minWidth: `${w}px`,
        maxWidth: `${w}px`
    };
}

export const getStateBasedTitle = (dashboard) => {
    const district_state = get(dashboard, 'project.district.state', '');
    const state = get(dashboard, 'project.school.state', district_state);
    switch (state.toLowerCase()) {
        case 'ca':
            return {
                skill: 'Student Achievement - Math Practices/ELA Targets',
                category: 'Student Achievement - Math/ELA CAASPP Claims',
                s_table: 'Individual Student Achievement - Math Practices/ELA Targets',
                domain: 'Student Achievement - Math Domains',
                d_table: 'Individual Student Achievement - Math Domains'
            };
        case 'fl':
            return {
                skill: 'Student Achievement - B.E.S.T Mathematical Thinking and Reasoning Standards',
                s_table: 'Individual Student Achievement - B.E.S.T Mathematical Thinking and Reasoning Standards',
                domain: 'Student Achievement - Math Strands',
                d_table: 'Individual Student Achievement - Math Strands'
            };
        case 'ok':
            return {
                skill: 'Student Achievement - Mathematical Actions and Processes',
                s_table: 'Individual Student Achievement - Mathematical Actions and Processes',
                domain: 'Student Achievement - Math Domains',
                d_table: 'Individual Student Achievement - Math Domains'
            };
        case 'tx':
            return {
                skill: 'Student Achievement - TEKS Mathematical Process Standards',
                s_table: 'Individual Student Achievement - TEKS Mathematical Process Standards',
                domain: 'Student Achievement - Math Domains',
                d_table: 'Individual Student Achievement - Math Domains'
            };
        default:
            return {
                skill: 'Student Achievement',
                category: 'Student Achievement',
                s_table: 'Individual Student Achievement',
                domain: 'Student Achievement - Math Domains',
                d_table: 'Individual Student Achievement - Math Domains'
            };
    }
}