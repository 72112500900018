import React from 'react';

import ToBuyButton from 'common/membership/ToBuyButton';

export default function () {
    return (
        <div className='profile-membership-card'>
            <div className="">
                <div className="flex-row">
                    <div className={'plan-logo free'}>
                        <i className="fas fa-address-card"></i>
                    </div>
                </div>
                <div className='mt-2'>Membership</div>
                <h4 className="header-new mt-0">Basic</h4>
            </div>

            <div className="section flex-row">
                <ToBuyButton/>
            </div>
        </div>
    );
}
