import React from 'react';
import {useSelector} from "react-redux";

import CreateClassCode from 'common/challenge/CreateClassCode';

export default function () {
    const challenge_version = useSelector(state => state.challenge_version);

    return (
        <div className="challenge-preview-layer flex-column flex-vh-center">
            <div className="challenge-preview-layer-text flex-column flex-v-center center-align">
                <div>
                    {`You've finished previewing, now create your Mindsets Code!`}
                </div>
            </div>

            <div className="section">
            </div>

            <div className="flex-row flex-h-center">
                <CreateClassCode
                    challenge_version={challenge_version}
                    button_class="btn btn-large btn-challenge"
                />
            </div>
        </div>
    );
}
