import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {map, sortBy} from 'lodash';
import {SetTeacherFacilitationSelectedStreamIdAction} from "mindsets-js-sdk";

import AvatarName from 'common/user/AvatarName';
import analytic from 'utils/Analytic';
import {getStudentStreams} from "../../utils";


export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const streams_orig = useSelector(state => state.streams);
    const show_student = useSelector(state => state.teacher_facilitation_show_student);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);
    const selected_stream_id = useSelector(state => state.teacher_facilitation_selected_stream_id);
    const streams = sortBy(getStudentStreams(streams_orig, me.id), (s) => {
        switch (sort_by) {
            case 'progress':
                return -(s.index);
            case 'name':
            default:
                return s.user.full_name;
        }
    });

    function onSelectStream(selected_stream_id_new) {
        if (selected_stream_id_new !== selected_stream_id) {
            dispatch(SetTeacherFacilitationSelectedStreamIdAction(selected_stream_id_new));
        }
    }

    function renderStreams() {
        return streams.map((s) => {
            const onClick = () => {
                onSelectStream(s.id);
                analytic.log('facilitationByStudentSelectStudent', s.user);
            }

            return (
                <div className="flex-1" key={s.id}>
                    <div className="bystudent-streams-stream" onClick={onClick}>
                        <AvatarName
                            user={s.user}
                            private_mode={!show_student}
                        />
                    </div>
                </div>
            );
        });
    }

    function renderFilling() {
        const n = Math.ceil(streams.length / 12) * 12
        const array_to_fill = Array(n - streams.length)
        return map(array_to_fill, (v, k) => {
            return (
                <div className="flex-1 bystudent-streams-filling" key={`filling-${k}`}></div>
            );
        });
    }

    return (
        <div className="bystudent-streams">
            <h4 className="header-new center-align">
                {`Select a student to view`}
            </h4>

            <div className="bystudent-streams-list">
                <div className="flex-row flex-wrap flex-box-10-10">
                    {renderStreams()}

                    {renderFilling()}
                </div>
            </div>
        </div>
    );
}
