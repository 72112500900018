import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import {isElementSeen} from 'utils/ui'
import Bouncing from 'common/ui/Bouncing'


class ScrollReminder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show_scroll_reminder: false
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show_graph && this.props.show_graph) {
            setTimeout(() => {
                this.setState({
                    show_scroll_reminder: true
                })
            }, 500)

            window.addEventListener('scroll', this.onScroll())
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll())
    }

    getPollGraph() {
        const graphs = document.getElementsByClassName("poll-graph")
        return get(graphs, 0)
    }

    onClick() {
        return () => {
            const poll_graph = this.getPollGraph()
            if (poll_graph) {
                this.setState({
                    show_scroll_reminder: false
                })

                window.removeEventListener('scroll', this.onScroll())

                window.scrollTo({
                    top: poll_graph.offsetTop - 60,
                    behavior: 'smooth'
                })
            }
        }
    }

    onScroll() {
        return () => {
            if (this.state.show_scroll_reminder) {
                if (isElementSeen(this.getPollGraph())) {
                    this.setState({
                        show_scroll_reminder: false
                    })

                    window.removeEventListener('scroll', this.onScroll())
                }
            }
        }
    }

    render() {
        if (!this.state.show_scroll_reminder) {
            return null
        }

        return (
            <div className="poll-scroll-reminder" onClick={this.onClick()}>
                <Bouncing>
                    <i className="fas fa-arrow-down"></i>
                </Bouncing>
                <div>
                    {`Scroll down to see results`}
                </div>
            </div>
        )
    }
}

ScrollReminder.propTypes = {
    show_graph: PropTypes.bool.isRequired
}

export default ScrollReminder
