import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Draggable} from "react-beautiful-dnd"
import {get} from 'lodash'

import {processStringContent} from 'utils/content'


class DnDDraggableItem extends Component {
    getDraggableStyle(default_style) {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0",
            minHeight: "50px",
            minWidth: "50px",
            borderRadius: "4px",
            ...get(this.props.item, 'draggable_style'),
            ...default_style,
            ...this.props.extra_style
        }
    }

    getContent() {
        const contentImage = get(this.props.item, 'content.image');
        const contentHtml = get(this.props.item, 'content.html');
        if (contentImage) {
            return (
                <img src={contentImage} />
            )
        } else if (contentHtml) {
            return processStringContent(contentHtml);
        } else {
            return null;
        }
    }

    renderSelected() {
        const showRemoveIcon = () => {
            if (!this.props.is_preview) {
                return (
                    <i
                        className="dnd-answer-one-remove far fa-times-circle"
                        onClick={this.props.onRemove}
                    >
                    </i>
                )
            }
        }

        return (
            <div
                className="dnd-option-selected"
                style={this.getDraggableStyle({})}
            >
                {showRemoveIcon()}

                {this.getContent()}
            </div>
        )
    }

    render() {
        if (this.props.is_selected) {
            return this.renderSelected();
        }

        return (
            <Draggable
                draggableId={this.props.item.dnd_id}
                index={this.props.option_index}
                type={'123'}
                isDragDisabled={this.props.is_preview || this.props.is_selected}
            >
                {(provided, snapshot) => (
                    <div
                        className="dnd-option"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getDraggableStyle(provided.draggableProps.style)}
                    >
                        {this.getContent()}
                    </div>
                )}
            </Draggable>
        )
    }
}

DnDDraggableItem.defaultProps = {
    onRemove: () => {},
    is_selected: false
}

DnDDraggableItem.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    option_index: PropTypes.number.isRequired,
    extra_style: PropTypes.object,
    onRemove: PropTypes.func,
    is_selected: PropTypes.bool
}

export default DnDDraggableItem
