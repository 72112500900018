import React from 'react';
import {useSelector} from "react-redux";
import {find, get, merge, sortBy} from "lodash";

import Card from 'common/challengeNew/Card';
import {getStudentStreams} from "../../utils";


export default function () {
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_orig = useSelector(state => state.streams);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const selected_stream_id = useSelector(state => state.teacher_facilitation_selected_stream_id);
    const selected_card_index = useSelector(state => state.teacher_facilitation_selected_card_index);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);
    const streams = sortBy(getStudentStreams(streams_orig, me.id), (s) => {
        switch (sort_by) {
            case 'progress':
                return -(s.index);
            case 'name':
            default:
                return s.user.full_name;
        }
    });
    const current_stream_entity = getSelectedSE();

    function getSelectedStream() {
        return find(streams, {id: selected_stream_id});
    }

    function getSelectedSE() {
        const se = get(stream_entities, selected_card_index)
        const stream_se = get(getSelectedStream(), ['stream_entities', selected_card_index])
        return merge({}, se, stream_se)
    }

    function renderPreview() {
        if (current_stream_entity) {
            return (
                <div className="challenge-preview-adjustment">
                    <Card
                        challenge_version={lesson.challenge_version}
                        stream_entity={current_stream_entity}
                        lesson={lesson}
                        is_preview={true}
                    />
                </div>
            );
        }
    }

    return (
        <div className="bystudent-stream-container">
            {renderPreview()}
        </div>
    );
}
