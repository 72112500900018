import React from 'react';
import {useSelector} from "react-redux";
import {find} from "lodash";

import Graph from './Graph';
import {getPracticeAttr} from '../utils';

export default function () {
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const practices = useSelector(state => state.pam_practices);

    const selected_practice = find(practices, {id: selected_practice_id});
    const practices_title = getPracticeAttr(selected_practice.code, 'practices_title');

    return (
        <div>
            <h5 className="header-new center-align">
                {`What are students' achievement levels in ${practices_title}?`}
            </h5>

            <Graph/>
        </div>
    );
}
