import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {get, nth} from 'lodash';

import Header from 'common/challenge/Header';
import ImmersiveReaderSwitch from 'common/challenge/ImmersiveReaderSwitch';
import CardSelector from 'common/challenge/CardSelector';
import analytic from 'utils/Analytic';

function Top({goto}) {
    const challenge_version = useSelector(state => state.challenge_version);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const current_index = useSelector(state => state.challenge_preview_current_index);

    function onSelectPage(to_index) {
        if (to_index !== current_index) {
            goto(to_index);
            analytic.log('previewGoto', {
                name: challenge_version.name,
                code: challenge_version.challenge_code.code
            }, current_index, to_index);
        }
    }

    function renderImmersiveReaderSwitch() {
        if (challenge_version) {
            const current_se = nth(stream_entities, current_index);
            return (
                <ImmersiveReaderSwitch
                    key="immersive-reader"
                    challenge_version={challenge_version}
                    stream_entity={current_se}
                    from={`Teacher Preview`}
                />
            );
        }
    }

    function renderCardSelector() {
        if (stream_entities.length) {
            return (
                <CardSelector
                    key="card-selector"
                    challenge_version={challenge_version}
                    stream_entities={stream_entities}
                    selected_card_index={current_index}
                    onSelect={onSelectPage}
                    is_preview={true}
                />
            );
        }
    }

    function renderHeaderMore() {
        return [
            renderImmersiveReaderSwitch(),
            renderCardSelector()
        ];
    }

    return (
        <Header
            title={get(challenge_version, 'name')}
            challenge_version={challenge_version}
            show_back_button={true}
            show_create_button={true}
            more_to_render={renderHeaderMore()}
        />
    );
}

Top.propTypes = {
    goto: PropTypes.func.isRequired
}

export default Top
