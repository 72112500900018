import {find, filter, sortBy, take, mean} from 'lodash'
import {ROLE_STUDENT, ROLE_TEACHER} from 'constants/index'


export const getOwnerStream = (streams, teacher_id) => {
    return find(streams, (s) => s.user && s.user.id == teacher_id)
}

export const getStudentStreams = (streams, teacher_id) => {
    let student_streams = filter(streams, (s) => s.user && s.user.id != teacher_id && (ROLE_STUDENT === s.persona || 'pd' === s.persona))

    // Get red flag info
    student_streams = getFlagInfo(student_streams)

    return student_streams
}

export const getTeacherStreams = streams => {
    return filter(streams, stream => ROLE_TEACHER === stream.persona);
}

const getFlagInfo = (streams) => {
    const progresses = streams.map((s) => s.index)
    const progresses_sorted = sortBy(progresses)
    const progresses_sorted_half = take(progresses_sorted, progresses_sorted.length / 2)
    const red_flag_threshold = progresses_sorted_half.length ? mean(progresses_sorted_half) : 0

    return streams.map((s) => {
        return {
            ...s,
            red_flag: s.index < red_flag_threshold
        }
    })
}
