import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import {graphingAnswerConfig} from 'utils/ggb';

function Graphing({response}) {
    const uniq_id = 'answer-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100);
    const ggbAppRef = useRef();

    function ggbInit() {
        const ggb_config = {
            ...graphingAnswerConfig(uniq_id)
        }

        const base64 = get(response, 'data.answer.base64');
        if (base64) {
            ggb_config.ggbBase64 = base64;
        }

        ggbAppRef.current = new GGBApplet(ggb_config, true);
        ggbAppRef.current.inject(uniq_id);
    }

    useEffect(() => {
        ggbInit();
    }, []);

    return (
        <div id={`component-${uniq_id}`}>
            <div id={uniq_id}></div>
        </div>
    );
}

Graphing.propTypes = {
    response: PropTypes.object
}

export default Graphing
