import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {LoadCurriculumAction, LoadingAction, LoadCurriculumMapAction} from "mindsets-js-sdk";

import PDFViewer from '../common/PDFViewer';
import GeneralError from "../pages/GeneralError";

export default function () {
    const params = useParams();
    const dispatch = useDispatch();
    const curriculum = useSelector(state => state.curriculum);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Curriculum not found.');
    const [data, setData] = useState(null);

    function renderError() {
        if (failed === true) {
            return <GeneralError content={message}/>;
        }

        return null;
    }

    function renderContent() {
        if (data) {
            return <PDFViewer url={data} fileName={curriculum.name + ' curriculum map.pdf'}/>;
        }

        return null;
    }

    useEffect(() => {
        dispatch(LoadCurriculumAction(params.curriculum_code))
            .then(() => dispatch(LoadCurriculumMapAction(params.curriculum_code)))
            .then(buffer => setData(buffer))
            .catch(error => {
                setMessage(error);
                setFailed(true);
            })
            .then(() => dispatch(LoadingAction(false)));
    }, []);

    return (
        <>
            {renderError()}
            {renderContent()}
        </>
    );
}
