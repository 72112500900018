import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import moment from "moment";
import Cookie from "mindsets-js-sdk/src/Cookie";

import ChallengeTile from './ChallengeTile';
import LessonRow from './LessonRow';
import LessonFilter, {FILTER_COOKIE_NAME} from './LessonFilter';
import ViewModeSwitcher from "./ViewModeSwitcher";

function LessonList({lessons, list_name = 'my_classes'}) {
    const [order, setOrder] = useState(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_order') ? Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_order') : 'created_at:desc');
    const [show_hidden, setShowHidden] = useState(true);
    const [from, setFrom] = useState(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_from') && !isNaN(parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_from'))) ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_from')) : null);
    const [till, setTill] = useState(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_till') && !isNaN(parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_till'))) ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_till')) : null);
    const [limit, setLimit] = useState(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_limit') ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + list_name + '_limit')) : null);
    const [mode, setMode] = useState(Cookie.getCookie('mindsets_' + list_name + '_view_mode') ? Cookie.getCookie('mindsets_' + list_name + '_view_mode') : 'grid');
    const [hide_done, setHideDone] = useState(false);

    function onOrderChange(order) {
        setOrder(order);
        Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_order', order);
    }

    function updateFilterState(key, value) {
        Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_' + key, value);
        switch (key) {
            case 'limit':
                setLimit(value);
                break;
            case 'order':
                setOrder(value);
                setLimit(null);
                Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_limit', null);
                break;
            case 'show_hidden':
                setShowHidden(value);
                setLimit(null);
                Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_limit', null);
                break;
            case 'from':
                setFrom(value);
                setLimit(null);
                Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_limit', null);
                break;
            case 'till':
                setTill(value);
                setLimit(null);
                Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_limit', null);
                break;
            case 'mode':
                setMode(value);
                setLimit(null);
                Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_limit', null);
                break;
            case 'hide_done':
                setHideDone(value);
                setLimit(null);
                Cookie.setCookie(FILTER_COOKIE_NAME + list_name + '_limit', null);
                break;
            default:
                throw 'Unknown filter';
        }
    }

    function onViewModeChange(mode) {
        setMode(mode);
        Cookie.setCookie('mindsets_' + list_name + '_view_mode', mode);
    }

    function filterLessons(lessons) {
        if (from) {
            lessons = lessons.filter(lesson => lesson.created_at > from);
        }
        if (till) {
            lessons = lessons.filter(lesson => lesson.created_at < till);
        }
        if (hide_done) {
            lessons = lessons.filter(lesson => !lesson.is_done);
        }
        if (limit) {
            lessons = lessons.slice(0, limit);
        }
        // lessons = lessons.filter(lesson => {
        //     if (false === this.state.show_hidden) {
        //         return !lesson.is_hidden || lesson.is_hidden === false;
        //     }
        //
        //     return true;
        // })

        return lessons;
    }

    function orderLessons(lessons) {
        return lessons.sort((a, b) => {
            const orders = order.split(':');
            if (orders.length === 2) {
                if (orders[1] === 'asc') {
                    if (orders[0] === 'challenge') {
                        return a.challenge_version.name > b.challenge_version.name ? 1 : -1;
                    } else {
                        return a[orders[0]] > b[orders[0]] ? 1 : -1;
                    }
                } else {
                    if (orders[0] === 'challenge') {
                        return a.challenge_version.name < b.challenge_version.name ? 1 : -1;
                    } else {
                        return a[orders[0]] < b[orders[0]] ? 1 : -1;
                    }
                }
            }
        });
    }

    function groupLessons(lessonsSource) {
        let groups = [
            {key: 'today', name: 'Today', items: []},
            {key: 'this_week', name: 'Earlier this week', items: []},
            {key: 'last_week', name: 'Last week', items: []},
            {key: 'this_month', name: 'Earlier this month', items: []},
            {key: 'last_month', name: 'Last month', items: []},
            {key: 'this_year', name: 'Earlier this year', items: []},
        ];
        lessonsSource.map(lesson => {
            const created_at = moment(lesson.created_at * 1000);
            if (created_at.isBetween(moment().startOf('day'), moment().endOf('day'))) {
                groups.find(group => group.key === 'today').items.push(lesson);
            } else if (created_at.isBetween(moment().startOf('week'), moment().startOf('day'))) {
                groups.find(group => group.key === 'this_week').items.push(lesson);
            } else if (created_at.isBetween(moment().subtract(1, 'w').startOf('week'), moment().endOf('week'))) {
                groups.find(group => group.key === 'last_week').items.push(lesson);
            } else if (created_at.isBetween(moment().startOf('month'), moment().subtract(2, 'w').endOf('week'))) {
                groups.find(group => group.key === 'this_month').items.push(lesson);
            } else if (created_at.isBetween(moment().subtract(1, 'm').startOf('month'), moment().subtract(2, 'w').endOf('week'))) {
                groups.find(group => group.key === 'last_month').items.push(lesson);
            } else if (created_at.isBetween(moment().startOf('year'), moment().subtract(2, 'm').endOf('month'))) {
                groups.find(group => group.key === 'this_year').items.push(lesson);
            } else {
                const year = created_at.year();
                if (!groups.find(group => group.key === year)) {
                    groups.push({key: year, name: year, items: []});
                }
                groups.find(group => group.key === year).items.push(lesson);
            }
        });
        const orders = order.split(':');
        if (orders.length === 2 && orders[0] === 'created_at' && orders[1] === 'asc') {
            groups.reverse();
        }

        return groups;
    }

    function renderLessons(lessons) {
        if (mode === 'list') {
            return (
                <div className='flex flex-column'>
                    {lessons.map(lesson => {
                        return (
                            <Link
                                to={`/teacher/class/${lesson.code}`}
                                key={lesson.code}
                                className='lesson-link'
                            >
                                <LessonRow
                                    key={lesson.id}
                                    lesson={lesson}
                                    challenge_version={lesson.challenge_version}
                                    show_challenge_name={true}
                                />
                            </Link>
                        );

                    })}
                </div>
            );
        } else {
            return (
                <div className='flex-row-m flex-wrap flex-box-15-15'>
                    {lessons.map(lesson => {
                        return (
                            <Link
                                to={`/teacher/class/${lesson.code}`}
                                key={lesson.code}
                                className='class-link'
                            >
                                <ChallengeTile
                                    challenge_version={lesson.challenge_version}
                                    description={lesson.name.length > 30 ? (lesson.name.substring(0, 30) + '...') : lesson.name}
                                    is_done={lesson.is_done}
                                />
                            </Link>
                        );
                    })}
                </div>
            );
        }
    }

    function renderLessonGroups() {
        if (lessons.length) {
            let lessonsFiltered = filterLessons(lessons);
            const groups = groupLessons(lessonsFiltered).filter(group => group.items.length > 0);
            if (groups.length) {
                return (
                    <div>
                        {groups.map(group => {
                            return (
                                <div key={group.key}>
                                    <div>&nbsp;</div>
                                    <div>&nbsp;</div>
                                    <h5>{group.name}</h5>
                                    {renderLessons(orderLessons(group.items))}
                                </div>
                            );
                        })}
                    </div>
                );

            }
        }

        return renderEmptyState();
    }

    function renderEmptyState() {
        return (
            <div className="coteaching-empty-state">
                <div className="center-align">
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/teacher_dashboard_coteaching_empty.png`}
                        alt='No Classes found yet'/>
                </div>
                <div className="coteaching-empty-state-hint center-align">
                    {`Classes that you will create will appear here`}
                </div>
            </div>
        );
    }

    return (
        <div className="teacher-dashboard-coteaching">
            <div className="section">
                <div className='flex-row flex-wrap flex-between flex-v-center flex-gap-10'>
                    <LessonFilter
                        order={order}
                        show_hidden={show_hidden}
                        limit={limit}
                        onOrderChange={onOrderChange}
                        updateState={updateFilterState}
                        hide_done={hide_done}
                        list_name={list_name}
                        from={from}
                        till={till}
                    />
                    <ViewModeSwitcher
                        mode={mode}
                        onViewModeChange={onViewModeChange}
                    />
                </div>
                {renderLessonGroups()}
            </div>
        </div>
    );
}

LessonList.propTypes = {
    lessons: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        is_hidden: PropTypes.bool,
        is_done: PropTypes.bool,
        created_at: PropTypes.number.isRequired,
        challenge_version: PropTypes.shape({
            name: PropTypes.string.isRequired,
            challenge_code: PropTypes.shape({
                color: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    })).isRequired,
    list_name: PropTypes.string
}

export default LessonList;
