import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {map} from 'lodash'

import {processStringContent} from 'utils/content'


class SpreadsheetHeader extends Component {
    renderCell(row) {
        return map(row, (cell, i) => {
            const style = (typeof cell === 'object') ? cell.style : undefined
            const text = (typeof cell === 'object') ? cell.title : cell
            return (
                <th key={i} style={style}>
                    {processStringContent(text)}
                </th>
            )
        })
    }

    renderRows() {
        return this.props.data.map((row, i) => {
            return (
                <tr key={i}>
                    {this.renderCell(row)}
                </tr>
            )
        })
    }

    render() {
        return (
            <thead>
                {this.renderRows()}
            </thead>
        )
    }
}

SpreadsheetHeader.propTypes = {
    data: PropTypes.array.isRequired
}

export default SpreadsheetHeader
