import React from 'react';
import {useSelector} from "react-redux";
import {filter, sortBy} from 'lodash';

import Head from './Head';
import Stream from './Stream';
import {getStudentStreams} from "../../utils";


export default function () {
    const me = useSelector(state => state.me);
    const streams_orig = useSelector(state => state.streams);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);
    const streams = sortBy(getStudentStreams(streams_orig, me.id), (s) => {
        switch (sort_by) {
            case 'progress':
                return -(s.index);
            case 'name':
            default:
                return s.user.full_name;
        }
    });

    function renderBody() {
        const raise_hand_streams = sortBy(
            filter(streams, (s) => !!s.raise_hand_at),
            'raise_hand_at'
        );
        const render_raise_hand_streams = raise_hand_streams.map((stream) => {
            return (
                <Stream
                    key={stream.code}
                    stream={stream}
                />
            );
        });

        const student_streams = filter(streams, (s) => !s.raise_hand_at);
        const render_student_streams = student_streams.map((stream) => {
            return (
                <Stream
                    key={stream.code}
                    stream={stream}
                />
            );
        });

        return (
            <tbody>
            {render_raise_hand_streams}
            {render_student_streams}
            </tbody>
        );
    }

    return (
        <div className="byquestion-streams">
            <table className="bordered">
                <Head/>

                {renderBody()}
            </table>
        </div>
    );
}
