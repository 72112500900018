const getPlaceholderString = (getPlaceholder) => {
    const placeholder = getPlaceholder()
    if (typeof placeholder === 'object') {
        return placeholder.placeholder ? placeholder.placeholder : placeholder.title
    } else if (typeof placeholder === 'string') {
        return placeholder
    }
}

export const cellToObject = (cell_data, getPlaceholder) => {
    if (typeof cell_data === 'object') {
        return {
            title: '',
            editable: true,
            placeholder: getPlaceholderString(getPlaceholder),
            ...cell_data
        }
    } else if (typeof cell_data === 'string') {
        return {
            title: cell_data,
            editable: true,
            placeholder: getPlaceholderString(getPlaceholder)
        }
    } else {
        throw "Invalid cell data type"
    }
}