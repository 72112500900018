import React from 'react';
import PropTypes from 'prop-types';

import './text_bubble.scss';

function TextBubble({bubble_background = 'blue', bubble_direction = 'top', children}) {
    return (
        <div className="text-bubble-container">
            <div className={"text-bubble " + ` text-bubble-${bubble_background}`}>
                <div className="text-bubble-content">
                    {children}
                </div>

                <div className={"text-bubble-arrow " + `text-bubble-arrow-${bubble_direction}`}>
                </div>
            </div>
        </div>
    )
}

TextBubble.propType = {
    bubble_background: PropTypes.string.isRequired,
    bubble_direction: PropTypes.string.isRequired
}

export default TextBubble
