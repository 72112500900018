import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Text from './Text'
import Question from './Question'
import Iframe from './Iframe'
import Image from './Image'
import Table from './Table'
import NumberLine from './NumberLine'
import Comparison from './Comparison'
import InfoTabs from './InfoTabs'
import DataTabs from './DataTabs'
import Link from './Link'
import Graph from './Graph'
import Speech from './Speech'
import Graphing from './Graphing'
import Spacer from './Spacer'
import Divider from './Divider'
import ClickToShow from './ClickToShow'
import Step from './Step'
import Accordion from './Accordion';


class ContentComponent extends Component {
    getComponentClass() {
        const type = this.props.component_data.type.toLowerCase()
        switch (type) {
            case 'text':
                return Text
            case 'question':
                return Question
            case 'iframe':
                return Iframe
            case 'image':
                return Image
            case 'table':
                return Table
            case 'number_line':
                return NumberLine
            case 'comparison':
                return Comparison
            case 'info_tabs':
                return InfoTabs
            case 'data_tabs':
                return DataTabs
            case 'link':
                return Link
            case 'graph':
                return Graph
            case 'speech':
                return Speech
            case 'graphing':
                return Graphing
            case 'spacer':
                return Spacer
            case 'divider':
                return Divider
            case 'click_to_show':
                return ClickToShow
            case 'step':
                return Step
            case 'accordion':
                return Accordion;
            default:
                throw 'Unknown component type'
        }
    }

    renderComponentContent() {
        const ComponentClass = this.getComponentClass()
        return (
            <ComponentClass
                component_data={this.props.component_data}
            />
        )
    }

    render() {
        return (
            <div className="challenge-content-component">
                {this.renderComponentContent()}
            </div>
        )
    }
}

ContentComponent.propTypes = {
    component_data: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    stream_entity_id: PropTypes.number.isRequired,
    is_preview: PropTypes.bool.isRequired,
    is_latest: PropTypes.bool.isRequired
}

export default ContentComponent
