import React from 'react';

export default function () {
    return (
        <div className="footer-copyright">
            <p className="center-align">
                &copy; Mindsets Learning {new Date().getFullYear()}
                &nbsp;|&nbsp;
                www.mindsets.com
            </p>
        </div>
    );
}
