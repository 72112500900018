import {processStringContent, replaceSpecialChars} from 'utils/content'

export default class Poll {
    render(cells, vote) {
        const cell_text = processStringContent(
            replaceSpecialChars(vote.poll_option.title, {convert_katex: false}),
            {
                keep_html: false,
                convert_html: false,
                convert_math: false
            }
        );
        cells.push(cell_text);
    }
}
