import React from 'react';
import {useSelector} from "react-redux";

import {isWindowsApp} from 'utils/windows';
import ShareToTeams from './ShareTo/ShareToTeams';
import ShareToSDS from './ShareTo/ShareToSDS';

export default function () {
    const lesson = useSelector(state => state.lesson);

    function renderSDS() {
        if (process.env.FEATURE_SWITCH_SDS && (isWindowsApp() || process.env.FEATURE_SWITCH_SDS_FORCE)) {
            return (
                <div>
                    <h5 className="header-new">
                        {`Assign students from Microsoft`}
                    </h5>

                    <div className="content-teams-description">
                        {`This will sync the roster you have in Microsoft with Mindsets.`}
                    </div>

                    <div className="flex-row">
                        <ShareToSDS/>
                    </div>
                </div>
            );
        }
    }

    function renderShareToMicrosoftTeamsButton() {
        return (
            <div className="content-teams-icon-container">
                <ShareToTeams
                    challenge_version={lesson.challenge_version}
                    lesson={lesson}
                />
            </div>
        );
    }

    return (
        <div className="class-share-content-teams">
            <div className="flex-row-m flex-wrap flex-box-15-15">
                <div className="flex-1">
                    <h5 className="header-new">
                        {`Share to Microsoft Teams`}
                    </h5>

                    <div className="content-teams-description">
                        {`You'll be redirected to Microsoft Teams for sharing.`}
                    </div>

                    <div className="flex-row flex-v-center">
                        {renderShareToMicrosoftTeamsButton()}
                        <div className="content-teams-icon-helper">
                            <i className="fas fa-hand-point-left"/>
                            <span>
                                {`Click here to share`}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="content-teams-divider"/>

                <div className="flex-1">
                    {renderSDS()}
                </div>
            </div>
        </div>
    );
}
