import React from 'react';
import {useNavigate} from "react-router-dom";

import Template from './Template';

export default function ({dashboard_id, printable}) {
    const navigate = useNavigate();

    return (
        <div id='insights-dashboard' className="insights-dashboard-printable">
            <div className="insights-dashboard-printable-tool">
                <div className="flex-row flex-v-center flex-between">
                    <a className="link-new" href="#" onClick={() => {navigate(-1); return false;}}>
                        <i className="fas fa-chevron-left"></i>
                        <span>{`Back to Insights Dashboard`}</span>
                    </a>

                    <button className="btn btn-on-white" onClick={() => {window.print(); return false;}}>
                        <i className="fas fa-print"></i>
                        <span>{`Print/PDF`}</span>
                    </button>
                </div>
            </div>

            <Template
                dashboard_id={dashboard_id}
                printable={printable}
            />
        </div>
    );
}
