import {map, get, reverse} from 'lodash'

import {getIntervalColor, getPracticeIntervalCodes} from '../utils'
import {getCategoryNames, computeCategoryData} from '../CategoryGraph/utils'


const red_background = {
    backgroundColorStyle: {
        rgbColor: {
            red: 1,
            green : 0.8,
            blue: 0.8,
            alpha: 1
        }
    }
}


export const addCategoryGraphDataRequests = (sheetId, raw_data, mode) => {
    const category_names = getCategoryNames(raw_data.student_skill_data)
    const interval_category_data = computeCategoryData(
        raw_data.student_skill_data,
        raw_data.skill_structure_data,
        mode,
        raw_data.practice_code
    )

    const headers = interval_category_data.map((interval) => {
        return {
            userEnteredValue: {
                stringValue: interval.name
            },
            userEnteredFormat: {
                ...red_background
            }
        }
    })

    const rows = map(category_names, (category_name, i) => {
        const interval_data = map(interval_category_data, (interval) => {
            const v = get(interval.data, i)
            return {
                userEnteredValue: {
                    numberValue: v
                },
                userEnteredFormat: {
                    ...red_background
                }
            }
        })
        return {
            values: [
                {
                    userEnteredValue: {
                        stringValue: category_name
                    },
                    userEnteredFormat: {
                        ...red_background
                    }
                },
                ...interval_data
            ]
        }
    })

    return [
        {
            appendCells: {
                sheetId: sheetId,
                fields: '*',
                rows: [
                    {
                        values: [
                            {
                                userEnteredValue: {
                                    stringValue: ''
                                },
                                userEnteredFormat: {
                                    ...red_background
                                }
                            },
                            ...headers
                        ]
                    }
                ]
            }
        },
        {
            appendCells: {
                sheetId: sheetId,
                fields: '*',
                rows
            }
        }
    ]
}

export const addCategoryGraphChartRequests = (sheetId, dataSheetId, raw_data, mode, rowIndex, row_from, offsetXPixels) => {
    const category_names = getCategoryNames(raw_data.student_skill_data)
    const row_to = row_from + category_names.length + 1
    const mode_text = (mode == 0) ? 'Achievement' : 'Potential'
    const interval_codes = getPracticeIntervalCodes(raw_data.practice_code)
    const series_data = interval_codes.map((interval_code, i) => {
        return {
            "series": {
                "sourceRange": {
                    "sources": [
                        {
                            "sheetId": dataSheetId,
                            "startRowIndex": row_from,
                            "endRowIndex": row_to,
                            "startColumnIndex": i + 1,
                            "endColumnIndex": i + 2
                        }
                    ]
                }
            },
            "targetAxis": "LEFT_AXIS",
            "color": getIntervalColor(raw_data.practice_code, interval_code, true)
        }
    })

    return [
        {
            "addChart": {
                "chart": {
                    "spec": {
                        "title": `Performance Claims (${mode_text})`,
                        "basicChart": {
                            "chartType": "COLUMN",
                            "stackedType": "PERCENT_STACKED",
                            "legendPosition": "BOTTOM_LEGEND",
                            "axis": [
                                {
                                    "position": "BOTTOM_AXIS",
                                    "title": ""
                                },
                                {
                                    "position": "LEFT_AXIS",
                                    "title": "Student %"
                                }
                            ],
                            "domains": [
                                {
                                    "domain": {
                                        "sourceRange": {
                                            "sources": [
                                                {
                                                    "sheetId": dataSheetId,
                                                    "startRowIndex": row_from,
                                                    "endRowIndex": row_to,
                                                    "startColumnIndex": 0,
                                                    "endColumnIndex": 1
                                                }
                                            ]
                                        }
                                    }
                                }
                            ],
                            "series": reverse(series_data),
                            "headerCount": 1
                        }
                    },
                    "position": {
                        "overlayPosition": {
                            anchorCell: {
                                sheetId,
                                rowIndex,
                                columnIndex: 0
                            },
                            widthPixels: 550,
                            offsetXPixels
                        }
                    }
                }
            }
        }
    ]
}
