import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction, LoadingAction} from "mindsets-js-sdk";

import Teacher from "./Teacher";
import {getTeacherStreams} from "../../TeacherFacilitation/utils";
import analytic from "utils/Analytic";
import Email from "./Email";

export default function ({close}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const schools = useSelector(state => state.schools);
    const streams_x = useSelector(state => state.streams);
    const [ids, setIds] = useState([]);
    const [sending, setSending] = useState(false);
    const [input, setInput] = useState('');

    const teacher_streams = getTeacherStreams(streams_x).filter(stream => stream.user_id !== me.id);
    const teacher_stream_emails = teacher_streams.map(stream => stream.user.email);

    function filterSchools() {
        return schools.filter(school => {
            return school.hasOwnProperty('teachers') && school.teachers.length > 0 && filterTeachers(school.teachers).length > 0;
        });
    }

    function filterTeachers(teachers) {
        return teachers.filter(teacher => {
            return teacher.id !== me.id && (input ? (teacher.email.toLowerCase().includes(input.toLowerCase()) || teacher.fname.toLowerCase().includes(input.toLowerCase()) || teacher.lname.toLowerCase().includes(input.toLowerCase())) : true);
        });
    }

    function getEmail(id) {
        return schools.filter(school => {
            return school.hasOwnProperty('teachers') && school.teachers.length > 0 && school.teachers.length > 0;
        }).find(school => school.teachers.find(teacher => teacher.id === id)).teachers.find(teacher => teacher.id === id).email;
    }

    function renderSearchResults() {
        const schools = filterSchools();
        if (schools.length > 0) {
            return schools.map(school => (
                <div key={school.id}>
                    <div><strong>{school.name.toUpperCase()}</strong></div>
                    {filterTeachers(school.teachers).map(teacher => {
                        return (
                            <Teacher
                                key={teacher.id}
                                teacher={teacher}
                                joined={teacher_stream_emails.includes(teacher.email) || ids.includes(teacher.id)}
                                addUser={addUser}
                            />
                        );
                    })}
                </div>
            ));
        }

        return (
            <Teacher
                teacher={{
                    id: 0,
                    email: '',
                    fname: 'No results',
                    lname: ''
                }}
                joined={false}
                addUser={() => setInput('')}
            />
        );
    }

    function addUser(id) {
        let ids_x = JSON.parse(JSON.stringify(ids));
        if (!ids_x.includes(id)) {
            ids_x.push(id);
            setIds(ids_x);
            setInput('');
        }
    }

    function removeUser(id) {
        let ids_x = JSON.parse(JSON.stringify(ids));
        if (ids_x.includes(id)) {
            ids_x.splice(ids_x.indexOf(id), 1);
            setIds(ids_x);
        }
    }

    function assignTeachers() {
        setSending(true);
        dispatch(LoadingAction(true, 'Assigning Teachers'));
        MindsetsJsSDK().Api.lesson
            .assignUsers(lesson.id, {ids})
            .then(() => {
                analytic.log('facilitationMembersTeachersAssigned', ids);
                setSending(false);
                setIds([]);
                dispatch(LoadingAction(false));
                close();
            })
            .catch((error) => {
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
                console.error(error);
                setSending(false);
            });
    }

    useEffect(() => {
        return () => {
            setIds([]);
            setSending(false);
        }
    }, []);

    return (
        <form className='assign-teachers-form' onSubmit={(event) => event.preventDefault()}>
            <div className='flex-row flex-wrap email-list flex-box-2-5 mb-2'>
                {ids.map(id => (
                    <Email
                        key={id}
                        email={getEmail(id)}
                        removeUser={() => removeUser(id)}
                    />
                ))}
                <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder='Type a name or email'
                    value={input}
                    onChange={event => setInput(event.target.value)}
                />
            </div>
            <div className='results'>SEARCH RESULTS</div>
            <div className='teacher-list mb-2'>
                {renderSearchResults()}
            </div>
            <div className='flex-row flex-between flex-v-center'>
                <div className='action-hint'>Teachers from all your schools will be shown here.</div>
                <button className="btn btn-on-white no-wrap" onClick={assignTeachers}
                        disabled={ids.length <= 0 || sending}>
                    <span>Add Teachers</span>
                </button>
            </div>
        </form>
    );
}
