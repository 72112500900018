export default function (Analytic) {
    Analytic.prototype.projectView = function ({challenge_code, project_name, language}) {
        this.track('View Project Extension', {
            challenge_code,
            project_name,
            language
        });
    }

    Analytic.prototype.projectViewChallenge = function ({challenge_code, project_name}) {
        this.track('View Challenge from Project', {
            challenge_code,
            project_name
        });
    }
}
