import React, {useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {isEmpty} from 'lodash';
import {ErrorMessageAction, LoadingAction, JoinLessonAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';

export default function () {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [code, setCode] = useState(searchParams.has('code') ? searchParams.get('code') : '');

    function handleSubmit() {
        if (code === '') {
            dispatch(ErrorMessageAction('Please enter a Mindsets Code'));
            return;
        }
        if (!/^[a-zA-Z]{3,5}[0-9]{1,3}$/.test(code)) {
            dispatch(ErrorMessageAction('This Mindsets Code is invalid'));
            return;
        }

        dispatch(JoinLessonAction(code)).then(response => {
            navigate('/hello/' + response.stream_code);
        }).catch(error => {
            analytic.log('joinByInvalidCodeError', code, error);
        }).then(() => {
            dispatch(LoadingAction(false));
        });
    }

    function renderCodeField() {
        return (
            <div className="student-join-code">
                <input
                    type="text"
                    name="code"
                    className="browser-default input-new"
                    placeholder="Enter Mindsets Code"
                    value={code}
                    onChange={event => setCode(event.target.value.trim())}
                />
            </div>
        );
    }

    function renderJoinButton() {
        return (
            <div>
                <button type="submit" className="btn btn-challenge btn-large" disabled={isEmpty(code)}>
                    {`Join`}
                </button>
            </div>
        );
    }

    return (
        <div className="student-dashboard-join">
            <form method="post" onSubmit={event => {
                event.preventDefault();
                handleSubmit();
            }}>
                <div className="flex-row flex-vh-center flex-wrap flex-box-5-10">
                    <div>
                        {renderCodeField()}
                    </div>

                    <div>
                        {renderJoinButton()}
                    </div>
                </div>
            </form>
        </div>
    );
}
