import {forEach, isEmpty, debounce, get} from 'lodash'
import MindsetsJsSDK, {ErrorMessageAction, LoadingAction, SuccessMessageAction, InfoMessageAction} from "mindsets-js-sdk";

import {store} from '../../../../index';


export const cardIsValid = (ref_components) => {
    return new Promise((resolve, reject) => {
        forEach(ref_components, (row) => {
            forEach(row, (rc) => {
                if (rc) {
                    if (rc.props.component_data.type.startsWith('answer.') && !rc.isValid()) {
                        reject('Please provide answer(s).')
                    }
                    if (rc.props.component_data.type.startsWith('poll.') && !rc.isValid()) {
                        reject('Please provide vote(s).')
                    }
                    if (rc.props.component_data.type == 'spreadsheet' && !rc.isValid()) {
                        reject('Please fill in all cells.')
                    }
                }
            })
        })
        resolve(true)
    })
}

export const cardIsCorrect = (ref_components) => {
    return new Promise((resolve, reject) => {
        forEach(ref_components, (row) => {
            forEach(row, (rc) => {
                if (rc) {
                    if (rc.props.component_data.type.startsWith('answer.') && !rc.isCorrect()) {
                        reject('Please check your answer(s).')
                    }
                    if (rc.props.component_data.type === 'spreadsheet' && !rc.isCorrect()) {
                        reject('Please check your answer(s).')
                    }
                }
            })
        })
        resolve(true)
    })
}

export const needToSubmitAnswers = (ref_components) => {
    return new Promise((resolve, reject) => {
        forEach(ref_components, (row) => {
            forEach(row, (rc) => {
                if (rc) {
                    if (rc.props.component_data.type.startsWith('answer.') && rc.isEdited() && (typeof rc.isEmpty !== 'function' || !rc.isEmpty())) {
                        resolve(true)
                    }
                    if (rc.props.component_data.type === 'spreadsheet' && rc.isEdited()) {
                        resolve(true)
                    }
                }
            })
        })
        resolve(false)
    })
}

export const getCardAnswers = (ref_components) => {
    const answers = []
    forEach(ref_components, (row) => {
        forEach(row, (rc) => {
            if (rc && (rc.props.component_data.type.startsWith('answer.') || rc.props.component_data.type === 'spreadsheet')) {
                answers.push(
                    {
                        question_id: rc.props.question_id,
                        response: rc.getAnswer()
                    }
                )
            }
        })
    })
    return answers
}

// To prevent multi api requests of creating answers to backend
const debounceApiCreateAnswers = debounce(data => MindsetsJsSDK().API.post('responses', data), 1000, {
    leading: true,
    trailing: false
});

export const submitCardAnswers = (ref_components, stream_id, lesson_id, se_id, has_old_answers, answers_required = true) => {
    let p = Promise.resolve();

    const answers = getCardAnswers(ref_components);
    if (!isEmpty(answers)) {
        let data = {responses: answers, stream_id, lesson_id, stream_entity_id: se_id};
        if (!answers_required) {
            data.required = false
        }
        p = p.then(() => {
            return debounceApiCreateAnswers(data);
        });
    }

    return p;
}

export const hasAnswers = stream_entity => {
    return stream_entity.hasOwnProperty('responses') && stream_entity.responses.length > 0;
}

export const hasQuestions = (ref_components) => {
    let result = false;
    forEach(ref_components, (row) => {
        forEach(row, (rc) => {
            if (rc) {
                if (rc.props.component_data.type.startsWith('answer.') || rc.props.component_data.type.startsWith('poll.') || rc.props.component_data.type === 'spreadsheet') {
                    result = true;
                }
            }
        })
    });

    return result;
}

export const storeAnswers = (ref_components, {
    must_be_valid = true,
    is_click = false,
    callback
}) => {
    const current_index = store.getState().student_stream_current_index;
    const challenge_stream_entities = store.getState().challenge_version_stream_entities;
    const stream_entities_responses = store.getState().student_stream_stream_entities_responses;
    const stream = store.getState().stream;
    const stream_entities = stream_entities_responses.map((se, i) => {
        const challenge_se = get(challenge_stream_entities, i)
        return {
            ...challenge_se,
            ...se
        }
    });
    const stream_entity = get(stream_entities, current_index);
    let show_already_saved_message = false;
    let show_saved_message = false;
    const promise = must_be_valid ? cardIsValid(ref_components) : Promise.resolve();

    return promise
        .then(() => needToSubmitAnswers(ref_components))
        .then(need_to_submit_answers => {
            if (need_to_submit_answers) {
                return cardIsCorrect(ref_components)
            } else {
                show_already_saved_message = true;

                return false
            }
        })
        .then((need_to_submit_answers) => {
            if (need_to_submit_answers) {
                store.dispatch(LoadingAction(true));
                return submitCardAnswers(
                    ref_components,
                    stream.id,
                    stream.lesson_id,
                    stream_entity.id,
                    hasAnswers(stream_entity),
                    must_be_valid
                ).then(() => {
                    show_saved_message = true;
                });
            }
        })
        .then(() => {
            if (typeof callback === 'function') {
                return callback();
            }
        })
        .catch((error) => {
            console.error(error);
            // This is to stop teacher progressing the student stream.
            // TODO Remove this after we have the teacher student stream page.
            if (error === 'Stream not found.') {
                store.dispatch(ErrorMessageAction('This is the latest progress of this student.'));
                return
            }

            store.dispatch(ErrorMessageAction(error));
        })
        .then(() => {
            if (show_saved_message) {
                store.dispatch(SuccessMessageAction('Your answer(s) have been submitted!'));
            } else if (is_click && show_already_saved_message) {
                store.dispatch(InfoMessageAction('Thanks. Your work has already been saved.'));
            }
            store.dispatch(LoadingAction(false));
        });
}