import React from 'react';

import SAMMenuNormal from './SAMMenuNormal';
import SAMMenuMobile from './SAMMenuMobile';

import './sam_menu.scss';


export default function () {
    return (
        <div className="sam-menu">
            <div className="flex-row flex-wrap">
                <div className="sam-menu-header">
                    <h2 className="header-new">
                        {`Engagement Dashboard`}
                    </h2>
                    <h4 className="header-new">
                        {`Engagement Dashboard`}
                    </h4>
                </div>

                <div className="flex-auto">
                    <SAMMenuNormal/>
                </div>

                <SAMMenuMobile/>
            </div>
        </div>
    );
}
