import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {LoadingAction} from "mindsets-js-sdk";

import GeneralError from "../pages/GeneralError";
import {LogInProviderAction} from "../actions/login_actions";

export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Authentication code not provided.');

    useEffect(() => {
        if (searchParams.has('code')) {
            dispatch(LogInProviderAction('classlink', {
                code: searchParams.get('code')
            }))
                .then(() => navigate('/'))
                .catch(error => {
                    setMessage(error);
                    setFailed(true);
                })
                .then(() => dispatch(LoadingAction(false)));
        } else {
            setFailed(true);
        }
    }, []);

    if (failed === true) {
        return <GeneralError title='Authentication failed.' content={message}/>;
    }

    return (
        <div>Loading...</div>
    );
};
