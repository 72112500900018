import React from 'react';
import MindsetsJsSDK from "mindsets-js-sdk";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {get, truncate} from 'lodash';
import moment from 'moment';

import {processStringContent, capitalizeFirstLetter} from 'utils/content';
import analytic from 'utils/Analytic';
import {getChallengeHeroThumb} from 'utils/cloud';

function ActiveChallenge({challenge_version, noPreview = false}) {
    const navigate = useNavigate();
    let me = useSelector(state => state.me);

    if (!me) {
        me = {
            curriculum_id: 1,
            curriculum: {
                is_code_shown: true,
                grade_term: 'grade'
            }
        }
    }

    const thumb_url = getChallengeHeroThumb(challenge_version.challenge_code.code);

    function toStart(e) {
        e.preventDefault();

        if (noPreview) {
            return;
        }
        const pd_class_code_key = `PD_CLASS_CODE_${challenge_version.challenge_code.code}`.toUpperCase();
        MindsetsJsSDK().Api.setting.show(pd_class_code_key).then((response) => {
            const pd_class_code = response.setting.value;
            if (pd_class_code) {
                MindsetsJsSDK().Api.lesson
                    .joinPD(pd_class_code)
                    .then(response => navigate('/pd/s/' + response.stream_code))
                    .catch(error => console.error(error));
            } else {
                console.error('PD class code is empty.')
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    function renderChallengeName() {
        const name_array = challenge_version.name.replace(': ', ':: ').split(': ');
        return (
            <div className="challenge-title">
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        );
    }

    function renderNewLabel() {
        const is_new = moment.unix(challenge_version.challenge_code.created_at).isAfter(moment().add(-90, 'day'));
        if (is_new) {
            return (
                <div className="challenge-new-label z-depth-2" style={{color: challenge_version.challenge_code.color}}>
                    {`* NEW *`}
                </div>
            );
        }
    }

    function renderHover() {
        if (noPreview) {
            return null;
        }

        return (
            <div className="challenge-hover">
                <button className="btn btn-large btn-on-dark">
                    {`Start`}
                </button>
            </div>
        );
    }

    return (
        <div className="active-challenge" onClick={toStart}>
            <div className="challenge-triangle"
                 style={{borderBottomColor: challenge_version.challenge_code.color}}>&nbsp;</div>
            {renderNewLabel()}
            <div className="challenge-container"
                 style={{backgroundImage: `url(${thumb_url})`}}>
                <div className="challenge-content-container"
                     style={{backgroundColor: challenge_version.challenge_code.color}}>
                    {renderChallengeName()}

                    <div className="challenge-description">
                        {
                            truncate(
                                processStringContent(challenge_version.description, {
                                    convert_html: false,
                                    convert_math: false
                                }),
                                {length: 140, omission: '...'}
                            )
                        }
                    </div>
                </div>
            </div>

            {renderHover()}
        </div>
    );
}

export default ActiveChallenge;
