import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class Image extends Component {
    renderImage() {
        return (
            <div>
                <img src={this.props.component_data.data.src} />
            </div>
        )
    }

    renderCaption() {
        if (this.props.component_data.data.caption) {
            return (
                <div>
                    {processStringContent(this.props.component_data.data.caption)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-image">
                <div className="flex-row flex-h-start">
                    <div className="flex-column flex-v-center flex-box-2-5 center-align">
                        {this.renderImage()}

                        {this.renderCaption()}
                    </div>
                </div>
            </div>
        )
    }
}

Image.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Image
