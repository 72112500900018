const analyticSocialSharing = (Analytic) => {
    Analytic.prototype.socialSharingCopyLink = function (url_to_share) {
        this.track('Social Sharing Copy Link', {url_to_share})
    }

    Analytic.prototype.socialSharingSendEmail = function (url_to_share) {
        this.track('Social Sharing Send Email', {url_to_share})
    }

    Analytic.prototype.socialSharingFacebook = function (url_to_share) {
        this.track('Social Sharing Facebook', {url_to_share})
    }

    Analytic.prototype.socialSharingTwitter = function (url_to_share) {
        this.track('Social Sharing Twitter', {url_to_share})
    }
}

export default analyticSocialSharing