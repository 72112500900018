import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RequestPasswordChangeAction} from "mindsets-js-sdk";

import Curriculum from './Curriculum';
import School from './School';
import Email from './Email';
import Zip from './Zip';
import Avatar from './Avatar';
import Name from './Name';
import Country from './Country';
import State from './State';

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }

    return (
        <div className="">
            <h4 className="header-new">Profile Details</h4>

            <div className="user-info">
                <div className="flex-column">
                    <Avatar/>
                    <Name/>
                    <Email/>
                    <School/>
                    <Zip/>
                    <Country/>
                    <State/>
                    <Curriculum/>
                </div>

                <div className="flex-row flex-wrap flex-box-5-10 mt-1">
                    <button className="btn btn-dark" onClick={event => {
                        event.preventDefault();
                        const edit_event = new CustomEvent("open-user-edit");
                        document.dispatchEvent(edit_event);
                    }}>
                        {`Edit Profile`}
                    </button>

                    <button className="btn btn-dark" onClick={event => {
                        event.preventDefault();
                        dispatch(RequestPasswordChangeAction({email: me.email}));
                    }}>
                        {`Reset Password`}
                    </button>
                </div>
            </div>
        </div>
    )
}
