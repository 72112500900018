import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DateRangeSelector} from "mindsets-js-sdk";

import SchoolFilter from 'common/spam/SchoolFilter'
import ChallengeFilter from 'common/spam/ChallengeFilter'
import {SetPAMFromAction, SetPAMTillAction} from "../../actions/pam_actions";

export default function () {
    const dispatch = useDispatch();
    const from = useSelector(state => state.pam_from);
    const till = useSelector(state => state.pam_till);

    return (
        <div className='flex-row flex-box-0-10'>
            <div>
                <SchoolFilter/>
            </div>
            <div>
                <ChallengeFilter/>
            </div>
            <div>
                <DateRangeSelector
                    from={from}
                    till={till}
                    onUpdate={data => {
                        dispatch(SetPAMFromAction(data.from));
                        dispatch(SetPAMTillAction(data.till));
                    }}
                />
            </div>
        </div>
    );
}
