import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    LoadChallengeVersionAction,
    LoadChallengeVersionStreamEntitiesAction,
    SetChallengePreviewCurrentIndexAction,
    SetChallengePreviewShowReminderAction
} from "mindsets-js-sdk";

import Top from './Top'
import Preview from './Preview'
import {userCan} from 'utils/membership'
import NoMatch from "../nomatch";
import useAuth from "../../hooks/useAuth";

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const challenge_version = useSelector(state => state.challenge_version);
    const show_reminder = useSelector(state => state.challenge_preview_show_reminder);
    const current_index = useSelector(state => state.challenge_preview_current_index);
    const [loading, setLoading] = useState(true);

    function goto(to_index) {
        const can_preview_full = !!me && userCan(me.subscription_status, 'preview_full_challenge', {challenge_version})
        if (!can_preview_full && to_index > 3) {
            return
        }

        if (show_reminder) {
            if (to_index === current_index + 1) {
                dispatch(SetChallengePreviewShowReminderAction(false));
            } else {
                dispatch(SetChallengePreviewShowReminderAction(false));
                dispatch(SetChallengePreviewCurrentIndexAction(to_index));
            }
        } else if (
            (to_index === 3 || to_index === 8 || to_index === 15)
            && to_index > current_index
        ) {
            dispatch(SetChallengePreviewShowReminderAction(true));
            dispatch(SetChallengePreviewCurrentIndexAction(to_index));
        } else {
            dispatch(SetChallengePreviewCurrentIndexAction(to_index));
        }
    }

    useEffect(() => {
        dispatch(LoadChallengeVersionAction(params.challenge_code, {'selectors[0]': 'is_locked'}))
            .then(challenge_version => dispatch(LoadChallengeVersionStreamEntitiesAction(challenge_version.id)))
            .catch(error => console.error(error))
            .then(() => setLoading(false));

        return () => {
            dispatch(SetChallengePreviewCurrentIndexAction(0));
            dispatch(SetChallengePreviewShowReminderAction(false));
        }
    }, []);

    if (loading) {
        return null;
    }
    if (!loading && !challenge_version) {
        return (
            <NoMatch title='Challenge not found'/>
        );
    }

    return (
        <div id="challenge-preview">
            <div id="challenge-preview-page">
                <Top goto={goto}/>

                <div className="container">
                    <Preview goto={goto}/>
                </div>
            </div>
        </div>
    );
}
