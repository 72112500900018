import React from 'react';
import PropTypes from 'prop-types';

import {COUNTRIES} from 'constants/countries';

function CurriculumSelector({curriculum_id, onCurriculumIdChange, validation_error_messages, curriculums}) {
    function extractCurriculumGroups() {
        let groups = {};
        curriculums.map(curriculum => {
            if (groups.hasOwnProperty(curriculum.country_code) && !groups[curriculum.country_code].includes(curriculum.code)) {
                groups[curriculum.country_code].push(curriculum);
            } else if (!groups.hasOwnProperty(curriculum.country_code)) {
                groups[curriculum.country_code] = [curriculum];
            }
        });

        return groups;
    }

    function renderOptionsByuGroups() {
        const groups = extractCurriculumGroups();
        const groupOptions = [];
        groupOptions.push(<option key={'empty'} value='' disabled>Select curriculum</option>);
        groupOptions.push(<option key='separator' disabled>──────────</option>);

        Object.keys(groups).map(country_code => {
            let curriculumOptions = [];
            groups[country_code]
                .filter(curriculum => curriculum.country_code === country_code)
                .map(curriculum => curriculumOptions.push(
                    <option
                        key={curriculum.id}
                        value={curriculum.id}
                    >{curriculum.name}</option>
                ));
            groupOptions.push(
                <optgroup
                    key={country_code}
                    label={COUNTRIES[country_code]}
                >{curriculumOptions}</optgroup>
            );
        });

        groupOptions.push(<option key={'none'} value=''>Other</option>);

        return groupOptions;
    }

    return (
        <div className="inputfield">
            <label htmlFor="curriculum" className="flex-row">
                <div>Curriculum</div>
                {
                    validation_error_messages.hasOwnProperty('curriculum') ?
                        <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                }
            </label>
            <select
                value={curriculum_id ? curriculum_id : ''}
                onChange={event => onCurriculumIdChange(parseInt(event.target.value))}
                id="curriculum"
                className={"standard-switcher browser-default "}>
                {renderOptionsByuGroups()}
            </select>
        </div>
    );
}

CurriculumSelector.propTypes = {
    curriculum_id: PropTypes.number,
    onCurriculumIdChange: PropTypes.func.isRequired,
    validation_error_messages: PropTypes.shape({
        curriculum: PropTypes.string
    }).isRequired,
    curriculums: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        country_code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        is_enabled: PropTypes.bool.isRequired,
        is_code_shown: PropTypes.bool.isRequired,
        grade_term: PropTypes.string.isRequired
    })).isRequired
};

export default CurriculumSelector;
