import React from 'react'
import {DragDropContext} from "react-beautiful-dnd"
import {get, find, cloneDeep, remove, orderBy} from 'lodash'

import BaseAnswer from '../BaseAnswer';
import AnswerOptions from './AnswerOptions';
import DnDDroppableItem from './DnDDroppableItem';


export default class DragAndDropPuzzle extends BaseAnswer {
    defaultAnswer() {
        return {
            text: '',
            answer_selections: []
        }
    }

    isValid() {
        return this.state.answer.answer_selections.length > 0;
    }

    getAnswerText(answer_selections) {
        const answer_options = get(this.props.component_data, 'data.answer_options');
        const answer_layout = get(this.props.component_data, 'data.answer_layout');
        const answerTextArray = answer_selections.map(([layoutId, optionId]) => {
            const optionItem = find(answer_options, {dnd_id: optionId});
            const layoutItem = find(answer_layout, {dnd_id: layoutId});
            return get(layoutItem, 'content.text') + get(optionItem, 'content.text');
        });

        return answerTextArray.join('\n');
    }

    onDragEnd() {
        return (result) => {
            const draggable_id = get(result, 'draggableId')
            const dest_droppable_id = get(result, 'destination.droppableId')
            const source_droppable_id = get(result, 'source.droppableId')

            if (!draggable_id || !dest_droppable_id) {
                return null
            }

            if (dest_droppable_id === source_droppable_id) {
                return null
            }

            const answer_clone = cloneDeep(this.state.answer)

            // Moved answer back to options area
            if (dest_droppable_id === 'option-droppable') {
                return false;
            }

            // Moved answer from options area to answer area
            remove(answer_clone.answer_selections, (pair) => pair[0] === dest_droppable_id);
            answer_clone.answer_selections.push([dest_droppable_id, draggable_id]);
            answer_clone.answer_selections = orderBy(answer_clone.answer_selections, ([layoutId, optionId]) => layoutId);
            answer_clone.text = this.getAnswerText(answer_clone.answer_selections);
            this.setState({
                answer: answer_clone
            });
        }
    }

    onRemove = (droppable_dnd_id) => (draggable_dnd_id) => () => {
        const answer_clone = cloneDeep(this.state.answer)
        remove(answer_clone.answer_selections, (pair) => pair[0] === droppable_dnd_id && pair[1] === draggable_dnd_id);
        answer_clone.text = this.getAnswerText(answer_clone.answer_selections);
        this.setState({
            answer: answer_clone
        })
    }

    getDnDContainerStyle() {
        const bgImg = get(this.props.component_data, 'data.background_img');
        const width = get(this.props.component_data, 'config.width');
        const height = get(this.props.component_data, 'config.height');

        return {
            backgroundImage: `url(${bgImg})`,
            backgroundRepeat: 'no-repeat',
            width,
            height
        }
    }

    findOptionData(answerLayoutId) {
        const pair = find(this.state.answer.answer_selections, (pair) => pair[0] === answerLayoutId);
        const option_dnd_id = get(pair, 1);
        if (!option_dnd_id) {
            return undefined;
        }
        const answer_options = get(this.props.component_data, 'data.answer_options');
        return find(answer_options, {dnd_id: option_dnd_id});
    }

    renderLayout() {
        const answerLayout = get(this.props.component_data, 'data.answer_layout');
        const renderLayoutItems = answerLayout.map((item, key) => {
            switch(item.type) {
                case 'dnd':
                    return (
                        <DnDDroppableItem
                            key={key}
                            component_data={this.props.component_data}
                            is_preview={this.props.is_preview}
                            item={item}
                            selected={this.findOptionData(item.dnd_id)}
                            onRemove={this.onRemove(item.dnd_id)}
                        />
                    );
                case 'static':
                default:
                    return null;
            }
        });

        return (
            <div className="cc-dndpuzzle-layout">
                {renderLayoutItems}
            </div>
        );
    }

    render() {
        return (
            <div className="challenge-component-dndpuzzle">
                <DragDropContext onDragEnd={this.onDragEnd()}>
                    <AnswerOptions
                        component_data={this.props.component_data}
                        is_preview={this.props.is_preview}
                    />

                    <div className="cc-dndpuzzle-scroll">
                        <div
                            className="cc-dndpuzzle-bg"
                            style={this.getDnDContainerStyle()}
                        >
                            {this.renderLayout()}
                        </div>
                    </div>
                </DragDropContext>
            </div>
        )
    }
}
