import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Modal from 'react-responsive-modal';
import {rolesConstants, filterConstants, SetUserAction, LoadCurriculaAction} from "mindsets-js-sdk";

import Footer from "./Footer";
import Content from "./Content";

import './user_edit.scss';

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const user = useSelector(state => state.user);
    const [isOpened, setIsOpened] = useState(false);
    const [mode, setMode] = useState('user');
    const [submitting, setSubmitting] = useState(false);
    const [validation_error_messages, setValidationErrorMessages] = useState({});

    useEffect(() => {
        document.addEventListener("open-user-edit", () => setIsOpened(true));
    }, []);
    useEffect(() => {
        dispatch(LoadCurriculaAction({
            'filters[is_enabled][0]': filterConstants.EXPRESSION_TRUE,
            limit: 0
        }));
    }, []);
    useEffect(() => {
        if (!!me) {
            const has_visited = me.general_data && me.general_data.dashboard_visited === 1;
            const password_reset = !!me.password_reset;
            const has_country = (rolesConstants.ROLE_TEACHER === me.persona && !!me.country) || rolesConstants.ROLE_STUDENT === me.persona;
            const has_school = (rolesConstants.ROLE_TEACHER === me.persona && !!me.school_id) || rolesConstants.ROLE_STUDENT === me.persona;
            const has_grades = me.grades && me.grades.length > 0 || rolesConstants.ROLE_STUDENT === me.persona;
            if (!has_visited || password_reset || !has_country || !has_school || !has_grades) {
                setIsOpened(true);
            }
        }
    }, [me]);
    useEffect(() => {
        if (isOpened && !!me && !user) {
            dispatch(SetUserAction(me));
        } else {
            dispatch(SetUserAction(null));
        }
    }, [isOpened]);

    if (!user) {
        return null;
    }

    return (
        <Modal
            open={isOpened}
            onClose={() => setIsOpened(false)}
            showCloseIcon={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            classNames={{
                modal: 'user-edit-modal',
                closeButton: 'assignment-close-button'
            }}
            center
        >
            <Content
                validation_error_messages={validation_error_messages}
                setValidationErrorMessages={setValidationErrorMessages}
                mode={mode}
                setMode={setMode}
            />
            <Footer
                mode={mode}
                setMode={setMode}
                submitting={submitting}
                setSubmitting={setSubmitting}
                setIsOpened={setIsOpened}
                validation_error_messages={validation_error_messages}
                setValidationErrorMessages={setValidationErrorMessages}
            />
        </Modal>
    );
};
