import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MindsetsJsSDK, {Toggle} from "mindsets-js-sdk";
import ReactTooltip from "react-tooltip";

import analytic from 'utils/Analytic';

import './toggle_lesson_mode_action.scss';

function ToggleLessonModeAction(
    {
        lesson,
        type = 'ticks',
        with_heading = false
    }
) {
    const [mode, setMode] = useState(lesson.mode);

    function changeMode(new_mode) {
        if (new_mode !== mode) {
            setMode(new_mode);
            MindsetsJsSDK().API.patch(`lessons/${lesson.id}/mode`, {mode: new_mode})
                .then(() => {
                    analytic.log(
                        'toggleTeachingMode',
                        lesson.code,
                        lesson.mode
                    );
                })
                .catch((error) => {
                    setMode(mode);
                    console.error(error);
                });
        }
    }

    function renderAsToggle() {
        return (
            <div className="lesson-mode-toggle toggle">
                <div className='flex-row flex-v-center'>
                    <i className="action-icon fas fa-chalkboard-teacher mr-2"/>
                    <div className="flex-row flex-h-start flex-v-center">
                        <div className='answer-keys-toggle-text'>Enable Teacher Paced mode</div>
                    </div>
                    <div className="flex-auto"/>
                    <Toggle onChange={() => changeMode(mode === 'teacher' ? 'student' : 'teacher')}
                            is_on={mode === 'teacher'}/>
                    <div className="answer-keys-toggle-value">
                        {mode === 'teacher' ? 'Yes' : 'No'}
                    </div>
                </div>
            </div>
        );
    }

    function renderAsButtons() {
        return (
            <div className="lesson-mode-toggle buttons">
                <div className='flex-row flex-box-5-5 flex-wrap'>
                    <button className='btn btn-dark no-wrap' disabled={mode === 'student'}
                            onClick={() => changeMode('student')}>Student Paced mode
                    </button>
                    <button className='btn btn-dark no-wrap' disabled={mode === 'teacher'}
                            onClick={() => changeMode('teacher')}>Teacher Paced mode
                    </button>
                </div>
            </div>
        );
    }

    function renderAsRadio() {
        return (
            <div className="lesson-mode-toggle radio">
                <form action="#">
                    <p>
                        <input name="teaching-mode" className='with-gap' type="radio" id="spm"
                               onChange={() => changeMode('student')} checked={mode === 'student'}/>
                        <label htmlFor="spm" className='radio-label'>Student Paced mode</label>
                    </p>
                    <p>
                        <input name="teaching-mode" className='with-gap' type="radio" id="tdm"
                               onChange={() => changeMode('teacher')} checked={mode === 'teacher'}/>
                        <label htmlFor="tdm" className='radio-label'>Teacher Paced mode</label>
                    </p>
                </form>
            </div>
        );
    }

    function renderAsTicks() {
        return (
            <div className="lesson-mode-toggle ticks flex-box-5-5">
                {with_heading ? <h5 className='header-new'>Teaching mode</h5> : null}
                <div>
                    <div className='flex-row pointer flex-box-0-5 flex-v-center' onClick={() => changeMode('student')}>
                        <i className={"fas fa-check " + (mode === 'student' ? 'selected' : '')}/>
                        <p className='label'>Student Paced</p>
                    </div>
                </div>
                <div>
                    <div className='flex-row pointer flex-box-0-5 flex-v-center' onClick={() => changeMode('teacher')}>
                        <i className={"fas fa-check " + (mode === 'teacher' ? 'selected' : '')}/>
                        <p className='label'>Teacher Paced</p>
                    </div>
                </div>
            </div>
        );
    }

    function renderAsButtonset() {
        return (
            <div className='buttonset'>
                <button
                    className={'btn btn-flat ' + (mode === 'student' ? 'active' : '')}
                    onClick={() => changeMode('student')}
                    data-tip='Students control which page they are on'
                >
                    <img
                        className='mr-1'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/lesson_mode/student_` + (mode === 'student' ? 'teal_inverted' : 'black') + '.svg'}
                        alt='Student Paced mode'
                    />
                    <p>Student Paced</p>
                </button>
                <button
                    className={'btn btn-flat ' + (mode === 'teacher' ? 'active' : '')}
                    onClick={() => changeMode('teacher')}
                    data-tip='You control which page all students are on'
                >
                    <img
                        className='mr-1'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/lesson_mode/teacher_` + (mode === 'teacher' ? 'purple_inverted' : 'black') + '.svg'}
                        alt='Teacher Paced mode'
                    />
                    <p>Teacher Paced</p>
                </button>
                <ReactTooltip/>
            </div>
        );
    }

    function renderAsTiles() {
        return (
            <div className='lesson-mode-toggle mode-tiles flex-row flex-box-0-5'>
                <div className={'mode-tile-border ' + (mode === 'student' ? 'active' : '')}>
                    <a
                        href={'!#'}
                        className={'mode-tile ' + (mode === 'student' ? 'active' : '')}
                        onClick={event => {
                            event.preventDefault();
                            changeMode('student');
                        }}
                        data-tip='Students control which page they are on'
                    >
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_student.svg`}
                             alt='Student Paced mode'/>
                        <p className="mode-tile-name">Student Paced</p>
                    </a>
                </div>
                <div className={'mode-tile-border ' + (mode === 'teacher' ? 'active' : '')}>
                    <a
                        href={'!#'}
                        className={'mode-tile ' + (mode === 'teacher' ? 'active' : '')}
                        onClick={event => {
                            event.preventDefault();
                            changeMode('teacher');
                        }}
                        data-tip='You control which page all students are on'
                    >
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_teacher.svg`}
                             alt='Teacher Paced mode'/>
                        <p className="mode-tile-name">Teacher Paced</p>
                    </a>
                </div>
                <ReactTooltip/>
            </div>
        );
    }

    useEffect(() => {
        setMode(lesson.mode);
    }, [lesson.mode]);

    switch (type) {
        case "buttons":
            return renderAsButtons();
        case "radio":
            return renderAsRadio();
        case "ticks":
            return renderAsTicks();
        case "buttonset":
            return renderAsButtonset();
        case "tiles":
            return renderAsTiles();
        case "toggle":
            return renderAsToggle();
        default:
            return renderAsToggle();
    }
}

ToggleLessonModeAction.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        mode: PropTypes.string.isRequired
    }).isRequired,
    type: PropTypes.oneOf(['toggle', 'buttons', 'radio', 'ticks', 'buttonset', 'tiles']),
    with_heading: PropTypes.bool
}

export default ToggleLessonModeAction;
