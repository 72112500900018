const analyticHelperGeneral = (Analytic) => {
    Analytic.prototype.pusherConnectionFailed = function () {
        this.track('Pusher Connection Failed', {})
    }

    Analytic.prototype.apiRequestBlocked = function (url) {
        this.track('Api Request Blocked', {url})
    }
}

export default analyticHelperGeneral