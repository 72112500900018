import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {addStyles, EditableMathField} from 'react-mathquill'
import {isFunction, findIndex, get} from 'lodash'
import $ from 'jquery';

import MathInputHelper from './MathInputHelper'

// inserts the required css to the <head> block.
// You can skip this, if you want to do that by yourself.
addStyles()

class MathInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            focused: false
        }
        this.handleMouseDownBinded = this.handleMouseDown.bind(this)
    }

    componentDidMount() {
        window.addEventListener("mousedown", this.handleMouseDownBinded)
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.handleMouseDownBinded)
    }

    handleMouseDown(event) {
        const focused = ReactDOM.findDOMNode(this).contains(event.target)
        if (this.state.focused != focused) {
            this.setState({focused})
        }
    }

    handleKeyDown() {
        return (event) => {
            if (event.keyCode == 13 || event.which == 13) {
                event.preventDefault()
                // move to the next textarea field when user presses enter
                var next_index
                const ts = $('textarea')
                const t_index = findIndex(ts, (t) => this.mathQuillEl.el().contains(t))
                const t_next = get(ts, t_index + 1)
                if (t_next) {
                    this.setState({focused: false}, () => {
                        $(t_next).focus()
                    })
                }
            } else if (event.keyCode == 9 || event.which == 9) {
                this.setState({focused: false})
            }
        }
    }

    onInsert() {
        return (value) => {
            if (isFunction(value)) {
                value(this.mathQuillEl)
            } else if (value[0] === "\\") {
                this.mathQuillEl.cmd(value).focus()
            } else {
                this.mathQuillEl.write(value).focus()
            }
            this.mathQuillEl.focus()
        }
    }

    renderMathInputField() {
        return (
            <EditableMathField
                config={{
                    autoCommands: "pi theta phi sqrt nthroot",
                    charsThatBreakOutOfSupSub: "+-*/=<>≠≤≥",
                    supSubsRequireOperand: true,
                    maxDepth: 3,
                }}
                latex={this.props.value}
                mathquillDidMount={el => {
                    this.mathQuillEl = el
                }}
                onChange={mathField => {
                    this.props.onChange(mathField.latex())
                }}
                onFocus={() => {
                    this.setState({focused: true})
                    setTimeout(() => {
                        window.scrollTo({
                            top: this.refs.dom.offsetTop - 150,
                            behavior: 'smooth'
                        })
                    }, 150)
                }}
                onKeyDown={this.handleKeyDown()}
            />
        )
    }

    renderMathInputHelper() {
        if (!this.state.focused) {
            return null
        }

        return (
            <MathInputHelper
                button_sets={this.props.button_sets}
                onInsert={this.onInsert()}
            />
        )
    }

    render() {
        return (
            <div className="expression-math-input" ref="dom">
                {this.renderMathInputField()}

                {this.renderMathInputHelper()}
            </div>
        )
    }
}

MathInput.propTyeps = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    button_sets: PropTypes.array.isRequired
}

export default MathInput
