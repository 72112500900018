import React from 'react'
import {useSelector} from "react-redux";
import {filter, sortBy} from "lodash";

import Stream from './Stream'
import {getStudentStreams} from "../utils";


export default function () {
    const me = useSelector(state => state.me);
    const streams_orig = useSelector(state => state.streams);
    const sort_by = useSelector(state => state.teacher_facilitation_sort_by);
    const student_streams = getStudentStreams(streams_orig, me.id);
    const streams = filter(sortBy(student_streams, (s) => {
        switch (sort_by) {
            case 'progress':
                return -(s.index);
            case 'name':
            default:
                return s.user.full_name;
        }
    }), {raise_hand_at: null});

    function renderHeader() {
        if (streams.length === student_streams.length) {
            return null;
        }

        return (
            <div>
                <h6 className="header-new">
                    {`Students`}
                </h6>
            </div>
        );
    }

    function renderStreams() {
        if (streams.length <= 0) {
            return null;
        }

        const render_streams = streams.map((stream) => {
            return (
                <div className="col s12 m6" key={stream.id}>
                    <Stream
                        stream={stream}
                    />
                </div>
            );
        })

        return (
            <div className="row">
                {render_streams}
            </div>
        );
    }

    if (streams.length <= 0) {
        return null;
    }

    return (
        <div className="section">
            {renderHeader()}

            {renderStreams()}
        </div>
    );
}
