import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {LoadingAction, SuccessMessageAction, DatePicker} from "mindsets-js-sdk";

import {
    googleAuthenticateNew,
    handleGoogleError,
    isVerifiedTeacher
} from 'utils/google';
import ShareTo from "./ShareTo";

export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const [title, setTitle] = useState('');
    const [instructions, setInstructions] = useState(lesson.challenge_version.description + '\n\n Sign in to Mindsets and enter Mindsets Code: ' + lesson.code.toUpperCase() + ' to start.');
    const [due, setDue] = useState(null);

    function initGoogleClient() {
        return Promise.resolve()
            .then(() => dispatch(LoadingAction(true, 'Signing to Google')))
            .then(() => gapi.load('client', function () {
                gapi.client.init({}).then(function () {
                    gapi.client.load('classroom');
                });
            }))
            .then(() => googleAuthenticateNew([
                'https://www.googleapis.com/auth/classroom.rosters.readonly',
                'https://www.googleapis.com/auth/classroom.profile.emails',
                'https://www.googleapis.com/auth/classroom.courses.readonly',
                'https://www.googleapis.com/auth/classroom.coursework.students'
            ]))
            .then(() => dispatch(LoadingAction(true, 'Verifying your account')))
            .then(() => isVerifiedTeacher('me'))
            .then(verified => {
                if (!verified) {
                    throw 'Sorry, but only a verified Google Teacher can perform this action.';
                }

                return gapi.client;
            });
    }

    function fetchClasses() {
        return initGoogleClient()
            .then(() => dispatch(LoadingAction(true, 'Retrieving your Google Classes')))
            .then(() => gapi.client.classroom.courses.list({teacherId: 'me'}))
            .then(response => response.result.courses.map(course => {
                return {
                    id: course.id,
                    name: course.name
                };
            }));
    }

    function fetchStudents(courseId) {
        dispatch(LoadingAction(true, 'Loading Course Students'));
        return gapi.client.classroom.courses.students
            .list({courseId})
            .then(response => {
                if (response.result.hasOwnProperty('students') && response.result.students.length > 0) {
                    return response.result.students.filter(student => {
                        return student.userId && student.profile && student.profile.emailAddress && student.profile.name && student.profile.name.givenName && student.profile.name.familyName;
                    }).map(student => {
                        return {
                            id: student.userId,
                            first_name: student.profile.name.givenName,
                            last_name: student.profile.name.familyName,
                            email: student.profile.emailAddress
                        }
                    });
                } else {
                    throw 'The selected class has no students joined. Please use another one.';
                }
            });
    }

    function sendAssignment(selected_class) {
        let dueDate = null;
        let dueTime = null;
        if (due) {
            const dueX = moment(due, 'ddd MMM DD YYYY HH:mm:ss');
            dueDate = {
                year: dueX.format('YYYY'),
                month: dueX.format('MM'),
                day: dueX.format('DD')
            };
            dueTime = {
                hours: '23',
                minutes: '59',
                seconds: '59',
                nanos: '00'
            };
        }
        dispatch(LoadingAction(true, 'Sending Assignment'));

        return gapi.client.classroom.courses.courseWork
            .create({
                courseId: selected_class.id,
                title: title,
                description: instructions,
                dueDate,
                dueTime,
                workType: 'ASSIGNMENT',
                state: 'PUBLISHED',
                materials: [
                    {
                        link: {
                            url: `${window.location.origin}/join/${lesson.code}`
                        }
                    }
                ]
            })
            .then(() => {
                dispatch(SuccessMessageAction(`Mindsets Code (${lesson.code.toUpperCase()}) has been successfully assigned to selected class ${selected_class.name}`, true));
                dispatch(LoadingAction(false));
                setDue(null);
            })
            .catch(error => {
                dispatch(LoadingAction(false));
                handleGoogleError(dispatch, error);
            });
    }

    function isNotReady() {
        return !(title && instructions);
    }

    function renderExtraContent() {
        return (
            <>
                <div className='assignment-row'>
                    <div className='inputfield'>
                        <label htmlFor='title'>Title</label>
                        <input
                            className='assignment-input-title validate'
                            type="text"
                            name="title"
                            id="title"
                            value={title}
                            onChange={event => setTitle(event.target.value)}
                        />
                    </div>
                </div>
                <div className='assignment-row'>
                    <div className='inputfield'>
                        <label htmlFor='instructions'>Instructions</label>
                        <textarea
                            className='assignment-input-instructions'
                            name="instructions"
                            id="instructions"
                            value={instructions}
                            onChange={event => setInstructions(event.target.value)}
                        />
                    </div>
                </div>
                <div className='assignment-row'>
                    <div className='courses-label'>Due</div>
                    <DatePicker
                        onChange={setDue}
                        date={due}
                    />
                </div>
            </>
        );
    }

    return (
        <ShareTo
            fetchClasses={fetchClasses}
            fetchStudents={fetchStudents}
            event_prefix={'googleClassroom'}
            provider={'google'}
            isNotReady={isNotReady()}
            renderExtraContent={renderExtraContent}
            sendAssignment={sendAssignment}
        />
    );
}
