import React from 'react';
import PropTypes from 'prop-types';
import {Draggable} from "react-beautiful-dnd";
import {get} from 'lodash';

import {processStringContent} from 'utils/content';


function AnswerOption(
    {
        // is_answer = ture if this is for answer, is_answer = false for option
        is_answer,
        is_preview,
        answer_group,
        option,
        option_index,
        extra_style,
        onRemove = () => {
        }
    }
) {
    function getDraggableStyle(default_style) {
        const color_light = get(answer_group, 'color_light')
        const color_dark = get(answer_group, 'color_dark')
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
            minHeight: "50px",
            minWidth: "50px",
            background: color_light,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color_dark,
            borderRadius: "4px",
            ...get(answer_group, 'draggable_style'),
            ...default_style,
            ...extra_style
        }
    }

    function renderOption() {
        return (
            <Draggable
                draggableId={option.dnd_id}
                index={option_index}
                type={answer_group.dnd_type}
                isDragDisabled={is_preview}
            >
                {(provided, snapshot) => (
                    <div
                        className="dnd-option"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDraggableStyle(provided.draggableProps.style)}
                    >
                        {processStringContent(option.html)}

                        <div className="dnd-option-layer"/>
                    </div>
                )}
            </Draggable>
        );
    }

    function renderAnswer() {
        const showRemoveIcon = () => {
            if (!is_preview) {
                return (
                    <i
                        className="dnd-answer-one-remove far fa-times-circle"
                        onClick={onRemove}
                    >
                    </i>
                );
            }
        }

        return (
            <div
                className="dnd-answer-one"
                style={getDraggableStyle({})}
            >
                {showRemoveIcon()}

                {processStringContent(option.html)}
            </div>
        );
    }

    if (is_answer) {
        return renderAnswer();
    } else {
        return renderOption();
    }
}

AnswerOption.propTypes = {
    // is_answer = ture if this is for answer, is_answer = false for option
    is_answer: PropTypes.bool.isRequired,
    is_preview: PropTypes.bool.isRequired,
    answer_group: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    option_index: PropTypes.number.isRequired,
    extra_style: PropTypes.object,
    onRemove: PropTypes.func
}

export default AnswerOption
