import React from 'react';


export default function () {
    return (
        <div>
            <h3 className="header-new">
                {`Module 2: What are sequencing and timing options for Mindsets Challenges?`}
            </h3>

            <br/>

            <div>
                <strong>
                    {`Mindsets Challenges align with a variety of standards & curriculum. This makes it easy for you to bring Mindsets' real-world, inquiry-based lessons into your classroom.`}
                </strong>
            </div>

            <br/>

            <h4 className="header-new">
                {`Curriculum Map`}
            </h4>
            <div>
                {`To help you plan and pace your students, we have created Curriculum Planning Maps for you. `}
                {`The Curriculum Maps can be downloaded by grade or by topic for Common Core, Texas Essential Knowledge and Skills (TEKS), Florida, Next Generation Science Standards (NGSS), and International curriculum including India, Victoria (VIC), and International Baccalaureate (IB) standards.`}
            </div>
            <br/>
            <div>
                {`These Maps are  accessible when you are signed in to your Mindsets Library.`}
            </div>
            <div>
                <br/>
                <img
                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/curricular.png`}
                    alt="A graphic with each of the curricula (CCSS, NGSS, TEKS, B.E.S.T., VIC, CBSE and IB)  in a circle with a state/country flag."
                    title="A graphic with each of the curricula (CCSS, NGSS, TEKS, B.E.S.T., VIC, CBSE and IB)  in a circle with a state/country flag."
                />
            </div>

            <br/>

            <h4 className="header-new">
                {`Suggested Sequence`}
            </h4>
            <div>
                {`Here's an example of a suggested sequence for Common Core 7th grade level math.`}
            </div>
            <div>
                <br/>
                <img
                    src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/sequence.png`}
                    alt="An image of the suggested sequence for Common Core 7th grade math with one Challenge and its description allocated for each month from September to May."
                    title="An image of the suggested sequence for Common Core 7th grade math with one Challenge and its description allocated for each month from September to May."
                />
            </div>

            <br/>

            <h4 className="header-new">
                {`Mindsets Challenges can be shared or assigned at various points during instruction`}
            </h4>
            <ul>
                <li>{`To spark initial motivation, use a Mindsets Challenge at the beginning of an instructional unit.`}</li>
                <li>{`For formative assessment, to build up to a project, and sustain motivation, use a Mindsets Challenge in the middle of an instructional unit.`}</li>
                <li>{`To stretch students' ability to transfer skills to new situations, use a Mindsets Challenge at the end of an instructional unit or as review.`}</li>
            </ul>

            <h5 className="header-new">
                {`Using a Mindsets Challenge at the beginning of an instructional unit`}
            </h5>
            <div>
                {`Using a Mindsets Challenge at the beginning of an instructional unit is a great way to preemptively answer the question `}
                <i>{`"Where will I ever use this?"`}</i>
                &nbsp;
                {` Students may get stuck - that is ok! Productive struggle can help build motivation. `}
                {`Use student responses to pre-assess abilities and help guide future lessons. This can allow you to tailor instruction to students' ability level with each skill.`}
            </div>

            <br/>

            <h5 className="header-new">
                {`Using a Mindsets Challenge in the middle of an instructional unit`}
            </h5>
            <div>
                {`The middle of an instructional unit is also a good time to help students see applications of what they are learning. `}
                {`Using a Mindsets Challenge in the middle of an instructional unit helps make the practice and standards meaningful. `}
                {`Also, you can use the Mindsets Challenge itself as a formative assessment and to build up to the end of instructional unit projects. `}
                {`Pair students of varying ability, and use the hints provided in the Teacher Guide to help students who struggle.`}
            </div>

            <br/>

            <h5 className="header-new">
                {`Using a Mindsets Challenge at the end of an instructional unit`}
            </h5>
            <div>
                {`Although each student is different, the end of an instructional unit maximizes the chance for students to successfully transfer academic skills to a real-world context. `}
                {`This is also where you can encourage richer discussion between students using the facilitator tips, and use the challenges to lead into projects with the suggested project extensions.`}
            </div>

            <br/>

            <h5 className="header-new">
                {`Spaced repetition is a powerful, evidence-based approach to keeping skills in long-term memory`}
            </h5>
            <ul>
                <li>{`Use Mindsets Challenges to turn review into an enriching and instructionally relevant treat.`}</li>
                <li>{`The broad range of stories for Mindsets Challenges helps students improve their transfer skills to new situations.`}</li>
            </ul>

            <div className="section">
                <div className="divider"></div>
            </div>

            <h4 className="header-new">
                {`Reflection`}
            </h4>
            <div>
                <strong>
                    {`Take a moment to reflect on when and how you will integrate a Mindsets Challenge based on your goal.`}
                </strong>
            </div>
            <br/>
            <div>
                <strong>
                    {`Will you assign a Challenge to introduce a new topic, in the middle of an instructional unit, or at the end of an instructional unit?`}
                </strong>
            </div>
        </div>
    );
}
