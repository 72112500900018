import {isEmpty, get} from 'lodash'


export const seOldToNew = (challenge_version, stream_entity) => {
    if (challenge_version.data_version > 0) {
        return stream_entity
    }

    const type = seTypeOldToNew(challenge_version, stream_entity)
    const is_bonus = seIsBonusOldToNew(challenge_version, stream_entity)
    const card_content = cardContentOldToNew(challenge_version, type, stream_entity)
    const data = {
        ...stream_entity.data,
        type: undefined,
        is_bonus,
        card_content
    }

    return {
        ...stream_entity,
        type,
        data
    }
}

const seTypeOldToNew = (challenge_version, stream_entity) => {
    if (challenge_version.data_version > 0) {
        return stream_entity.type
    }

    const {type, data={}, polls} = stream_entity
    switch (true) {
        case type == 'intro':
            return 'intro'
        case type == 'modal' && data.type == 'welcome':
            return 'welcome'
        case type == 'modal' && data.type == 'winner':
            return 'winner'
        case type == 'rating':
            return 'complete'
        case type == 'modal' && data.type == 'bonus':
            return 'unlock'
        case data.type == 'final':
            return 'final'
        case polls && polls[0] && polls[0].type != 'inline':
            return 'poll'
        case type == 'system':
            return 'question'
    }

    throw 'Invalid card type'
}

const seIsBonusOldToNew = (challenge_version, stream_entity) => {
    if (challenge_version.data_version > 0) {
        return get(stream_entity, 'data.is_bonus')
    }

    if (get(stream_entity, 'type') === 'system' && get(stream_entity, 'data.type') === 'bonus') {
        return true
    } else {
        return undefined
    }
}

const cardContentOldToNew = (challenge_version, type, stream_entity) => {
    if (challenge_version.data_version > 0) {
        return get(stream_entity, 'data.card_content')
    }

    if (type != 'question' && type != 'poll') {
        return undefined
    }

    if (isEmpty(stream_entity.data)) {
        return undefined
    }

    const {data, questions, spreadsheets, polls} = stream_entity;
    const poll = get(stream_entity, ['polls', 0]);
    const card_content = {
        rows: []
    };
    const pushToRows = (components) => card_content.rows.push({components});
    let components, component_data;

    if (!isEmpty(data.content)) {
        component_data = {
            type: 'text',
            data: {
                html: data.content
            }
        };
        pushToRows([component_data]);
    }

    if (!isEmpty(data.graph) && isEmpty(poll)) {
        component_data = {
            type: 'graph',
            data: data.graph
        };
        pushToRows([component_data]);
    }

    if (!isEmpty(data.comparisons)) {
        components = data.comparisons.map((data) => {
            return {
                type: 'comparison',
                data
            }
        });
        const direction = get(components, [0, 'data', 'bubble_direction']);
        if (!direction || direction === 'top' || direction === 'bottom') {
            pushToRows(components);
        } else {
            components.map((component) => {
                pushToRows([component])
            });
        }
    }

    if (!isEmpty(data.bubbles)) {
        component_data = {
            type: 'speech',
            data: {
                left: {
                    text: get(data.bubbles, [0, 'content'])
                },
                image: {
                    src: get(data.bubbles, [1, 'content'])
                },
                right: {
                    text: get(data.bubbles, [2, 'content'])
                }
            }
        };
        pushToRows([component_data]);
    }

    if (Array.isArray(questions) && questions.length > 0) {
        questions.map(question => {
            component_data = {
                type: 'question',
                container_style: {
                    maxWidth: "600px",
                    margin: "0 auto"
                },
                data: {
                    question: question.title
                }
            };
            pushToRows([component_data]);
        });
    }

    if (!isEmpty(data.info_tab)) {
        component_data = {
            type: 'info_tabs',
            data: {
                items: data.info_tab
            }
        };
        pushToRows([component_data]);
    }

    if (!isEmpty(data.iframe)) {
        component_data = {
            type: 'iframe',
            data: {
                src: data.iframe
            }
        };
        pushToRows([component_data]);
    }

    if (!isEmpty(data.external_links)) {
        components = data.external_links.map((data) => {
            return {
                type: 'link',
                data
            }
        });
        pushToRows(components);
    }

    if (!isEmpty(data.numberLine) && (data.numberLine.submitAnswer === false)) {
        component_data = {
            type: 'number_line',
            data: data.numberLine
        };
        pushToRows([component_data]);
    }

    if (Array.isArray(spreadsheets) && spreadsheets.length > 0) {
        spreadsheets.map(spreadsheet => {
            component_data = {
                type: 'spreadsheet',
                data: {
                    name: spreadsheet.name,
                    headers: spreadsheet.headers,
                    data: spreadsheet.data
                }
            };
            pushToRows([component_data]);
        });
    }

    if (Array.isArray(polls) && polls.length > 0) {
        polls.map(poll => {
            component_data = {
                type: poll.type === 'inline' ? 'poll.inline' : 'poll.block',
                data: {
                    title: poll.title,
                    description: poll.description,
                    poll_options: poll.poll_options
                }
            };
            pushToRows([component_data]);
        });
    }

    if (Array.isArray(questions) && questions.length > 0) {
        component_data = convertAnswer(stream_entity);
        component_data.map(data => {
            if (data) {
                pushToRows([data]);
            }
        });
    }

    return card_content
};

const convertAnswer = (stream_entity) => {
    const {data, questions} = stream_entity;
    if (data && questions.length > 0) {
        return questions.map(question => {
            switch (question.type) {
                case 'text':
                    return {
                        type: 'answer.textarea'
                    };
                case 'numberline':
                    return {
                        type: 'answer.number_line',
                        data: data.numberLine
                    };
                case 'single':
                    return {
                        type: 'answer.choice_single',
                        data: {options: question.options}
                    };
                case 'multiple':
                    return {
                        type: 'answer.choice_multiple',
                        data: {options: question.options}
                    };
                case 'slider':
                    return {
                        type: 'answer.slider',
                        data: question.options
                    };
                case 'range':
                    return {
                        type: 'answer.range',
                        data: question.options
                    };
                case 'nullable':
                default:
                    return undefined
            }
        });
    }

    return undefined
};
