import React from 'react';
import PropTypes from 'prop-types';

import {processStringContent} from 'utils/content';


function CardTitle({stream_entity}) {
    function getDefaultTitle() {
        if (stream_entity.data) {
            return stream_entity.data.title
        }
    }

    function getCardTitle() {
        const {type, data = {}, polls} = stream_entity;

        // New data model
        switch (type) {
            case 'intro':
                return 'Introduction'
            case 'welcome':
                return 'Welcome'
            case 'poll':
                return 'Class vote and poll'
            case 'winner':
                return 'Poll Winner'
            case 'complete':
                return 'Challenge Review'
            case 'unlock':
                return 'Bonus Unlocked'
            case 'final':
                return 'Finished!'
            case 'question':
            case 'instruction':
                return getDefaultTitle()
        }

        // Old data model
        switch (true) {
            case type == 'intro':
                return 'Introduction'
            case type == 'modal' && data.type == 'welcome':
                return 'Welcome'
            case polls && polls[0] && polls[0].type != 'inline':
                return 'Class vote and poll'
            case type == 'modal' && data.type == 'winner':
                return 'Poll Winner'
            case type == 'rating':
                return 'Challenge Review'
            case type == 'modal' && data.type == 'bonus':
                return 'Bonus Unlocked'
            case data.type == 'final':
                return 'Finished!'
            case type == 'system':
                return getDefaultTitle()
        }

        return getDefaultTitle();
    }

    function getProcessedCardTitle() {
        return processStringContent(getCardTitle(), {
            convert_html: false,
            convert_math: false
        });
    }

    return (
        <span>
            {getProcessedCardTitle()}
        </span>
    );
}

CardTitle.propTypes = {
    stream_entity: PropTypes.object.isRequired
}

export default CardTitle
