import React from 'react';
import {useSelector} from "react-redux";
import {statesConstants} from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);
    if (me && me.country && me.state && statesConstants.STATES.hasOwnProperty(me.country) && statesConstants.STATES[me.country].hasOwnProperty(me.state)) {
        return (
            <div className="flex-row user-info-field">
                <div className="user-info-field-label">State</div>
                <div>{statesConstants.STATES[me.country][me.state]}</div>
            </div>
        );
    }

    return null;
}
