import {PublicClientApplication} from "@azure/msal-browser";

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: process.env.PUBLIC_OFFICE_OAUTH_ID
    }
});
await msalInstance.initialize();

export function microsoftAuthenticate() {
    return new Promise((resolve, reject) => {
        //TODO Implement if SDS is used again
        // const MSUserAgentApplication = new UserAgentApplication({
        //     auth: {
        //         clientId: process.env.PUBLIC_OFFICE_OAUTH_ID,
        //         redirectUri: options.redirectUri
        //     },
        //     cache: {
        //         cacheLocation: "localStorage",
        //         storeAuthStateInCookie: true
        //     }
        // });
        // const authProvider = new MicrosoftGraph.ImplicitMSALAuthenticationProvider(
        //     MSUserAgentApplication,
        //     new MicrosoftGraph.MSALAuthenticationProviderOptions(options.scopes)
        // );
        // const client = MicrosoftGraph.Client.initWithMiddleware({authProvider});
        //
        // if (!MSUserAgentApplication.getAccount()) {
        //     MSUserAgentApplication
        //         .loginPopup({scopes: options.scopes})
        //         .then(() => client.api('/education/me').get())
        //         .then(() => resolve(client))
        //         .catch(response => {
        //             let error = response;
        //             if (error.hasOwnProperty('error')) {
        //                 error = error.error;
        //             }
        //             if ('ResourceNotFound' === error.code) {
        //                 reject('Please sign in with a valid teacher account.');
        //             } else {
        //                 reject('Sorry. There was a problem with your request. Please reload the page and try again or come back later.');
        //             }
        //         });
        // } else {
        //     resolve(client);
        // }
    });
}
