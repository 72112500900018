import moment from 'moment'

const analyticUserGeneral = (Analytic) => {
    Analytic.prototype.updateUser = function (user) {
        this.identify(user.id)

        const user_data = {
            fname: user.fname,
            lname: user.persona === 'student' ? 'xxx' : user.lname,
            email: user.persona === 'student' ? this.obfuscateEmail(user.email) : user.email,
            avatar: user.avatar,
            country: user.country,
            persona: user.persona,
            standard: user.curriculum ? user.curriculum.code : null,
            remote_provider: user.remote_provider,
            created_at: user.created_at ? moment.unix(user.created_at).format() : undefined,
            school: !!user.school && !!user.school.name ? user.school.name : undefined,
            district: !!user.school && !!user.school.district && !!user.school.district.name ? user.school.district.name : undefined
        }

        user_data.$first_name = user_data.fname
        user_data.$last_name = user_data.lname
        user_data.$email = user_data.email
        user_data.$created = user_data.created_at

        this.setUserData(user_data)
        this.setUserDataOnce("No. of Previews", 0)
        this.setUserDataOnce("No. of Class Codes Created", 0)
    }

    Analytic.prototype.signUpError = function () {
        this.track('Sign Up Error')
    }

    Analytic.prototype.loginError = function () {
        this.track('Login Error')
    }

    Analytic.prototype.joinOpenChallengeLesson = function (code) {
        this.track('Join Open Challenge Lesson', {
            code
        })
    }

    Analytic.prototype.joinByCodeError = function () {
        this.track('Join By Code Error')
    }

    Analytic.prototype.joinByInvalidCodeError = function (code, message) {
        this.track('Join By Invalid Code Error', {
            code: code,
            message: message
        })
    }

    Analytic.prototype.startOpenChallengeSurvey = function () {
        this.track('Start Open Challenge Survey')
    }

    Analytic.prototype.logout = function () {
        this.track('User logout')
        this.reset()
    }

    Analytic.prototype.forgotPassword = function () {
        this.track('Forgot Password')
    }

    Analytic.prototype.profileComplete = function (user) {
        this.track(`${user.persona} profile complete`, {
            fname: user.fname,
            lname: user.persona === 'student' ? 'xxx' : user.lname,
            email: user.persona === 'student' ? this.obfuscateEmail(user.email) : user.email,
            avatar: user.avatar,
            country: user.country,
            persona: user.persona,
            standard: user.curriculum ? user.curriculum.code : null,
            remote_provider: user.remote_provider,
            created_at: user.created_at ? moment.unix(user.created_at).format() : undefined,
            school: !!user.school && !!user.school.name ? user.school.name : undefined,
            district: !!user.school && !!user.school.district && !!user.school.district.name ? user.school.district.name : undefined
        })
    }

    Analytic.prototype.navigateViaUserMenu = function (navigate_to) {
        this.track('Navigate via User Menu', {
            navigate_to
        })
    }

    Analytic.prototype.updateEmailPreferences = function (data) {
        this.track('Update Email Preferences', data)
    }
}

export default analyticUserGeneral
