import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page14'>
            <div className='title'>
                <div className='one'>Take a Sneak Peak</div>
                <div className='two'>into more Mindsets Challenges!</div>
            </div>
            <div className='challenges'>
                {data.suggested_challenges.map(challenge => {
                    return (
                        <div
                            key={challenge.code}
                            className='challenge'
                            style={{backgroundColor: challenge.color}}
                        >
                            <div className='name'>{challenge.name} Challenge</div>
                            <div className='description'>{challenge.description}</div>
                            <div className='topic'>{challenge.topic}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}