import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

import useAuth from "../hooks/useAuth";
import GeneralError from "./GeneralError";
import PDFViewer from "../common/PDFViewer";

//TODO Move to/Update on SDK
export const LoadPDCertificateAction = (stream_id) => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'One moment... we\'re building your Certificate!')))
        .then(() => MindsetsJsSDK().API.get(`teacher/pd/certificate`, {buffer: true, stream_id}))
        .then(response => {
            dispatch(LoadingAction(false));

            return response;
        });
};

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const {stream_id} = useParams();
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Your PD certificate not found.');
    const [data, setData] = useState(null);

    function renderError() {
        if (failed === true) {
            return <GeneralError content={message}/>;
        }

        return null;
    }

    function renderContent() {
        if (data) {
            return <PDFViewer url={data} fileName={'Mindsets PD certificate.pdf'}/>;
        }

        return null;
    }

    useEffect(() => {
        dispatch(LoadPDCertificateAction(stream_id))
            .then(setData)
            .catch(error => {
                setMessage(error);
                setFailed(true);
            })
            .then(() => dispatch(LoadingAction(false)));
    }, []);

    return (
        <>
            {renderError()}
            {renderContent()}
        </>
    );
}
