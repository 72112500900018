import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import CreateClassCode from 'common/challenge/CreateClassCode';


function Header(
    {
        title = '',
        challenge_version = {},
        show_back_button = false,
        show_create_button = false,
        beforeCreate,
        afterCreate,
        show_preview_button = false,
        more_to_render
    }
) {
    const navigate = useNavigate();
    const me = useSelector(state => state.me);

    function renderBackButton() {
        if (show_back_button) {
            const onClick = (e) => {
                e.preventDefault();
                navigate(-1);
            }

            return (
                <i className="fas fa-chevron-left" onClick={onClick}/>
            );
        }
    }

    function renderGoToPreview() {
        if (show_preview_button) {
            return (
                <Link className="btn btn-flat" to={`/explore/${challenge_version.challenge_code.code}`}>
                    Preview
                </Link>
            );
        }
    }

    function renderCreateButton() {
        if (show_create_button) {
            return (
                <CreateClassCode
                    challenge_version={challenge_version}
                    beforeCreate={beforeCreate}
                    afterCreate={afterCreate}
                />
            );
        }
    }

    function renderMore() {
        if (more_to_render) {
            return more_to_render
        }
    }

    if (!me) {
        return null;
    }

    return (
        <div className={`challenge-header challenge-header-${me.persona} z-depth-1`}>
            <div className="challenge-header-container container flex-column flex-h-center">
                <div className="flex-row flex-wrap flex-v-center flex-h-end flex-box-5-10">
                    <div className="challenge-header-name flex-row flex-v-center">
                        {renderBackButton()}

                        {title}
                    </div>

                    <div className="flex-auto"/>

                    {renderGoToPreview()}

                    {renderCreateButton()}

                    {renderMore()}
                </div>
            </div>
        </div>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        })
    }),
    show_back_button: PropTypes.bool,
    show_create_button: PropTypes.bool,
    beforeCreate: PropTypes.func,
    afterCreate: PropTypes.func,
    show_preview_button: PropTypes.bool,
    more_to_render: PropTypes.node
}

export default Header
