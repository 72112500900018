import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {CustomDropdown, SuccessMessageAction, ErrorMessageAction} from "mindsets-js-sdk";

import {getTeacherStreams} from "../../TeacherFacilitation/utils";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_x = useSelector(state => state.streams);

    const teacher_streams = getTeacherStreams(streams_x).filter(stream => stream.user_id !== me.id);

    function deleteStream(stream) {
        const confirm_message = `Are you sure you want to remove ${stream.user.fname} from this class?`
        if (!confirm(confirm_message)) {
            return
        }

        MindsetsJsSDK().Api.stream
            .destroy(stream.id)
            .then(() => dispatch(SuccessMessageAction(`${stream.user.fname} has been removed from this class`)))
            .catch(() => dispatch(ErrorMessageAction('An unexpected error occurred')))
    }

    function makeOwner(user_id) {
        const confirm_message = `Are you sure you want to transfer ownership to the selected user?`
        if (!confirm(confirm_message)) {
            return;
        }

        MindsetsJsSDK().Api.lesson
            .transfer(lesson.id, user_id)
            .then(() => dispatch(SuccessMessageAction('Selected user is now the owner of the Mindsets Code')))
            .catch(() => dispatch(ErrorMessageAction('An unexpected error occurred')))
    }

    function renderTeacherName(user) {
        let postfix = '';
        if (user.id === lesson.user_id) {
            postfix = '(owner)';
        }

        return (
            <div>{user.fname} {user.lname}{postfix}</div>
        );
    }

    function renderOptions(stream) {
        if (stream.user.id === me.id || stream.user.id === lesson.user_id || me.id !== lesson.user_id) {
            return null;
        }

        return (
            <CustomDropdown
                trigger={<i className="fas fa-ellipsis-v"/>}
                no_wrap={true}
                item_class='members-list-member-item'
                items={{
                    transfer: (
                        <a href={"#"} onClick={() => makeOwner(stream.user.id)}>
                            <i className="fas fa-id-badge"/>
                            <span>MAKE CLASS OWNER</span>
                        </a>
                    ),
                    remove: (
                        <a href={"#"} onClick={() => deleteStream(stream)}>
                            <i className="fas fa-trash-alt"/>
                            <span>REMOVE</span>
                        </a>
                    )
                }}
                use_arrow={false}
            />
        );
    }

    function renderAvatar(user) {
        if (user.avatar) {
            return (
                <div className={'cashtivity-icon ' + user.avatar}/>
            );
        }

        return (
            <i className="fas fa-user-circle mr-2 user-icon"/>
        );
    }

    if (me && teacher_streams.length) {
        return (
            <div className='row members-list'>
                {teacher_streams.map((stream, key) => {
                    return (
                        <div className='col l6 m6 s12' key={key}>
                            <div className='flex-row flex-between flex-v-center hovers py-2 pr-2'>
                                <div className='flex-row flex-v-center'>
                                    {renderAvatar(stream.user)}
                                    {renderTeacherName(stream.user)}
                                </div>
                                {renderOptions(stream)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return (
            <div>
                <div className='row members-list members-list-empty'>
                    {['A', 'B', 'C', 'D', 'E', 'F'].map(index => {
                        return (
                            <div className='col l6 m6 s12' key={index}>
                                <div className='flex-row flex-between flex-v-center hovers py-2 pr-2'>
                                    <div className='flex-row flex-v-center teacher-name'>
                                        <i className="fas fa-user-circle mr-2 user-icon"/>
                                        Teacher {index}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="section center">
                    <h5 className='hint-title'>Teachers you've added as co-teachers will appear here</h5>
                    <p className='hint-content'>Your co-teachers can access this class from the co-teaching tab on
                        their homepage.</p>
                </div>
            </div>
        );
    }
}
