import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page13'>
            <div className='level-up'>Your Mindsets Skills Snapshot</div>
            <div className='second-row'>
                <div className='second-row-col'>
                    <div className='title'>Top Skills</div>
                    {data.top_literacy_skills.map(skill => {
                        return (
                            <img
                                className='hex'
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                alt={skill}/>
                        );
                    })}
                </div>
                <div className='second-row-col-m'>
                    <div className='second-row-col-row'>
                        <div className='title'>Top Math Practices</div>
                        <div className='flex-row flex-wrap flex-gap-20'>
                            {data.top_strengths.map(skill => {
                                return (
                                    <img
                                        className='mp'
                                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                        alt={skill}/>
                                );
                            })}
                        </div>
                    </div>
                    <div className='flex-row flex-h-center'>
                        <div className='second-row-col-row'>
                            <div className='title'>Most Leveled Up</div>
                            <img
                                className='mp'
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${data.best_skill.slug}.png`}
                                alt={data.best_skill.code}/>
                        </div>
                    </div>
                </div>
                <div className='second-row-col'>
                    <div className='title'>Top Math Skills</div>
                    {data.top_math_skills.map(skill => {
                        return (
                            <img
                                className='hex'
                                src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                                alt={skill}/>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}