import React from 'react';

import Graph from './Graph';

export default function () {
    return (
        <div>
            <h5 className="header-new center-align">
                {`What are students' achievement levels in Performance Claim areas?`}
            </h5>

            <Graph/>
        </div>
    );
}
