import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {throttle} from 'lodash';
import {
    LoadingAction,
    rolesConstants,
    ErrorMessageAction,
    LoadLessonAction,
    LoadStreamsAction
} from "mindsets-js-sdk";

import Teachers from './Teachers';
import Assign from './Assign';
import {getOwnerStream} from "../TeacherFacilitation/utils";
import Nav from '../TeacherFacilitation/Nav';
import useAuth from "../../hooks/useAuth";

function fetchLessonStreamsThrottle(callback) {
    return throttle(callback, 2000, {
        leading: false,
        trailing: true
    });
}

function fetchLessonThrottle(callback) {
    return throttle(callback, 2000, {
        leading: false,
        trailing: true
    });
}

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_x = useSelector(state => state.streams);
    let lesson_channel = null;

    function fetchData() {
        Promise.resolve()
            .then(() => dispatch(LoadingAction(true)))
            .then(fetchLesson)
            .then(fetchLessonStreams)
            .then(() => dispatch(LoadingAction(false)))
            .catch(error => {
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
            });
    }

    function fetchLesson() {
        return dispatch(LoadLessonAction(params.code, {'selectors[0]': 'challenge_code'}));
    }

    function fetchLessonStreams(lesson) {
        return dispatch(LoadStreamsAction({
            'filters[lesson_id][0]': 'eq=' + lesson.id,
            'filters[persona][0]': 'eq=teacher',
            'selectors[0]': 'user'
        }));
    }

    useEffect(() => {
        if (me) {
            fetchData();
        }
    }, []);
    useEffect(() => {
        if (me) {
            fetchData();
        }
    }, [me]);
    useEffect(() => {
        if (lesson && !lesson_channel) {
            lesson_channel = pusher.subscribe('lesson_' + lesson.id);
            lesson_channel.bind('lesson.updated', () => {
                fetchLessonThrottle(fetchLesson)();
                fetchLessonStreamsThrottle(() => fetchLessonStreams(lesson))();
            });
        }
    }, [lesson.id]);
    useEffect(() => {
        return () => {
            if (lesson_channel) {
                lesson_channel.unsubscribe('lesson_' + lesson.id);
                lesson_channel = null;
            }
        }
    }, []);
    if (!me) {
        return null;
    }

    const streams = streams_x.filter(stream => stream.user_id !== me.id && rolesConstants.ROLE_STUDENT === stream.persona)
    const owner_stream = getOwnerStream(streams_x, me.id);

    if (!lesson || !owner_stream) {
        return null;
    }

    return (
        <div id='lesson-members-page'>
            <div>
                <Nav
                    lesson={lesson}
                    student_joined={streams.length}
                    owner_stream={owner_stream}
                />
                <div className="container">
                    <div className="section">
                        <div className='flex-row flex-between'>
                            <div className='flex-row page-title '>
                                <Link to={`/teacher/class/${params.code}`} className='mr-3'>
                                    <i className='fas fa-chevron-left'/>
                                </Link>
                                <div>Teachers</div>
                            </div>
                            <Assign/>
                        </div>
                    </div>
                    <div className="section">
                        <Teachers/>
                    </div>
                </div>
            </div>
        </div>
    );
}
