import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

export const LOADED_DASHBOARDS = '[pam] Loaded Dashboards';
export const LOADED_DASHBOARD = '[pam] Loaded Dashboard';
export const SET_DASHBOARD_FILTERS = '[pam] Set Dashboard Filters';
export const LOADED_DASHBOARD_IMPACT_STUDY_FILTER = '[pam] Loaded Dashboard impact study filter';
export const LOADED_DASHBOARD_PRACTICE_FILTER = '[pam] Loaded Dashboard practice filter';
export const LOADED_DASHBOARD_SUBJECT_FILTER = '[pam] Loaded Dashboard subject filter';
export const LOADED_DASHBOARD_GRADE_FILTER = '[pam] Loaded Dashboard grade filter';
export const LOADED_DASHBOARD_SCHOOL_FILTER = '[pam] Loaded Dashboard school filter';
export const LOADED_DASHBOARD_CHALLENGE_FILTER = '[pam] Loaded Dashboard challenge filter';
export const LOADED_DASHBOARD_TEACHER_FILTER = '[pam] Loaded Dashboard teacher filter';
export const LOADED_DASHBOARD_PRACTICE_SKILL_FILTER = '[pam] Loaded Dashboard practice skill filter';
export const LOADED_DASHBOARD_STUDENT_FILTER = '[pam] Loaded Dashboard student filter';
export const LOADED_DASHBOARD_ACHIEVEMENT_LEVEL_FILTER = '[pam] Loaded Dashboard achievement level filter';
export const LOADED_DASHBOARD_FILTER_DEPENDENCIES = '[pam] Loaded Dashboard filter dependencies';
export const SET_DASHBOARD_DATA = '[pam] Set dashboard data';
export const CLEAN_DASHBOARD_DATA = '[pam] Clean dashboard data';

export const LoadInsightsDashboardsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Dashboards')))
        .then(() => MindsetsJsSDK().API.get('dashboards', options))
        .then(response => {
            dispatch(LoadedInsightsDashboardsAction(response.items));
            dispatch(LoadingAction(false));

            return response.items;
        });
};
export const LoadedInsightsDashboardsAction = payload => ({
    type: LOADED_DASHBOARDS,
    payload
});
export const LoadInsightsDashboardAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading Dashboards')))
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardAction(response.dashboard));
            dispatch(LoadingAction(false));

            return response.dashboard;
        });
};
export const LoadedInsightsDashboardAction = payload => ({
    type: LOADED_DASHBOARD,
    payload
});

export const LoadInsightsDashboardImpactStudyFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/impact_study_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardImpactStudyFilterAction(response.impact_studies));

            return response.impact_studies;
        });
};
export const LoadedInsightsDashboardImpactStudyFilterAction = payload => ({
    type: LOADED_DASHBOARD_IMPACT_STUDY_FILTER,
    payload
});
export const LoadInsightsDashboardPracticeFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/practice_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardPracticeFilterAction(response.practices));

            return response.practices;
        });
};
export const LoadedInsightsDashboardPracticeFilterAction = payload => ({
    type: LOADED_DASHBOARD_PRACTICE_FILTER,
    payload
});
export const LoadInsightsDashboardSubjectFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/subject_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardSubjectFilterAction(response.subjects));

            return response.subjects;
        });
};
export const LoadedInsightsDashboardSubjectFilterAction = payload => ({
    type: LOADED_DASHBOARD_SUBJECT_FILTER,
    payload
});
export const LoadInsightsDashboardGradeFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/grade_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardGradeFilterAction(response.grades));

            return response.grades;
        });
};
export const LoadedInsightsDashboardGradeFilterAction = payload => ({
    type: LOADED_DASHBOARD_GRADE_FILTER,
    payload
});
export const LoadInsightsDashboardSchoolFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/school_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardSchoolFilterAction(response.schools));

            return response.schools;
        });
};
export const LoadedInsightsDashboardSchoolFilterAction = payload => ({
    type: LOADED_DASHBOARD_SCHOOL_FILTER,
    payload
});
export const LoadInsightsDashboardChallengeFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/challenge_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardChallengeFilterAction(response.challenges));

            return response.challenges;
        });
};
export const LoadedInsightsDashboardChallengeFilterAction = payload => ({
    type: LOADED_DASHBOARD_CHALLENGE_FILTER,
    payload
});
export const LoadInsightsDashboardTeacherFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/teacher_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardTeacherFilterAction(response.teachers));

            return response.teachers;
        });
};
export const LoadedInsightsDashboardTeacherFilterAction = payload => ({
    type: LOADED_DASHBOARD_TEACHER_FILTER,
    payload
});
export const LoadInsightsDashboardPracticeSkillFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/practice_skill_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardPracticeSkillFilterAction(response.practice_skills));

            return response.practice_skills;
        });
};
export const LoadedInsightsDashboardPracticeSkillFilterAction = payload => ({
    type: LOADED_DASHBOARD_PRACTICE_SKILL_FILTER,
    payload
});
export const LoadInsightsDashboardStudentFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/student_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardStudentFilterAction(response.students));

            return response.students;
        });
};
export const LoadedInsightsDashboardStudentFilterAction = payload => ({
    type: LOADED_DASHBOARD_STUDENT_FILTER,
    payload
});
export const LoadInsightsDashboardAchievementLevelFilterAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/achievement_level_filter`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardAchievementLevelFilterAction(response.achievement_levels));

            return response.achievement_levels;
        });
};
export const LoadedInsightsDashboardAchievementLevelFilterAction = payload => ({
    type: LOADED_DASHBOARD_ACHIEVEMENT_LEVEL_FILTER,
    payload
});
export const LoadInsightsDashboardFilterDependenciesAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`dashboards/${id}/filter_dependencies`, options))
        .then(response => {
            dispatch(LoadedInsightsDashboardFilterDependenciesAction(response.dependencies));

            return response.dependencies;
        });
};
export const LoadedInsightsDashboardFilterDependenciesAction = payload => ({
    type: LOADED_DASHBOARD_FILTER_DEPENDENCIES,
    payload
});

export const SetDashboardFiltersAction = payload => ({
    type: SET_DASHBOARD_FILTERS,
    payload
});

export const SetDashboardDataAction = payload => ({
    type: SET_DASHBOARD_DATA,
    payload
});

export const CleanDashboardDataAction = payload => ({
    type: CLEAN_DASHBOARD_DATA,
    payload
});
