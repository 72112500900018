import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {sortBy, toArray, merge, get, set, sum, max} from 'lodash'
import HighCharts from 'highcharts'

import {POLL_GRAPH_DEFAULT, processGraphLabels} from 'utils/graph'


class PollGraphUI extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'poll-graph-' + Date.now() + '-' + Math.floor(Math.random() * 100)
    }

    componentDidMount() {
        this.drawGraph()
    }

    componentDidUpdate() {
        this.updateGraph()
    }

    getVotes() {
        const votes = {}
        this.props.poll.poll_options.map((o, i) => {
            votes[o.id] = 0
        })
        this.props.lesson_votes.map((v, i) => {
            votes[v.poll_option_id] ++
        })
        return toArray(sortBy(votes, (v, k) => k))
    }

    drawGraph() {
        const lesson_votes = this.getVotes()
        if (!this.props.show_empty && sum(lesson_votes) <= 0) {
            return
        }

        // Calculate graph height by the number of options
        // to make graph labels align better in small screen
        const height = max([400, this.props.poll.poll_options.length * 55])
        const calculated_graph = {
            chart: {
                height
            }
        }
        const graph = merge({}, POLL_GRAPH_DEFAULT, calculated_graph, this.props.custom_graph)

        if (!get(graph, 'xAxis.categories')) {
            const categories = this.props.poll.poll_options.map((o, i) => o.title)
            set(graph, 'xAxis.categories', categories)
        }
        processGraphLabels(graph)

        set(graph, 'series[0].data', lesson_votes)
        // set(graph, 'xAxis.labels', {useHTML: true})
        // set(graph, 'chart.backgroundColor', 'transparent')
        // set(graph, 'chart.plotBackgroundColor', '#FFFFFF')
        set(graph, 'tooltip', {
            formatter: function () {
                return `<b>${this.y}</b>`
            }
        })
        // set(graph, 'chart.animation', false)
        // set(graph, 'plotOptions.series.animation', false)
        this.chart = HighCharts.chart(this.uniq_id, graph)
    }

    updateGraph() {
        if (!this.chart) {
            this.drawGraph()
        } else {
            this.chart.series[0].update({
                data: this.getVotes()
            }, true)
        }
    }

    render() {
        return (
            <div className="poll-graph">
                <div id={this.uniq_id}>
                </div>
            </div>
        )
    }
}

PollGraphUI.defaultProps = {
    show_empty: false
}

PollGraphUI.propTypes = {
    poll: PropTypes.object.isRequired,
    lesson_votes: PropTypes.array.isRequired,
    custom_graph: PropTypes.object,
    show_empty: PropTypes.bool
}

export default PollGraphUI
