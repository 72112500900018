import React from 'react'
import {isEmpty, get, cloneDeep, find, remove, orderBy, sumBy} from 'lodash'

import BaseAnswer from '../BaseAnswer'
import Items from './Items'
import Selections from './Selections'


export default class Selector extends BaseAnswer {
    defaultAnswer() {
        return {
            text: '',
            answer_selections: [],
            total_value: 0
        }
    }

    isValid() {
        const value_maximum = get(this.props.component_data, 'data.value_maximum')
        if (value_maximum !== undefined) {
            if (this.state.answer.total_value > value_maximum) {
                throw(get(this.props.component_data, 'data.error_value_too_big'))
                return false
            }
        }
        const value_minimum = get(this.props.component_data, 'data.value_minimum')
        if (value_minimum !== undefined) {
            if (this.state.answer.total_value < value_minimum) {
                throw(get(this.props.component_data, 'data.error_value_too_small'))
                return false
            }
        }

        const total_amount = sumBy(this.state.answer.answer_selections, 'amount')
        const amount_maximum = get(this.props.component_data, 'data.amount_maximum')
        if (amount_maximum !== undefined) {
            if (total_amount > amount_maximum) {
                throw(get(this.props.component_data, 'data.error_amount_too_big'))
                return false
            }
        }
        const amount_minimum = get(this.props.component_data, 'data.amount_minimum')
        if (amount_minimum !== undefined) {
            if (total_amount < amount_minimum) {
                throw(get(this.props.component_data, 'data.error_amount_too_small'))
                return false
            }
        }

        return !isEmpty(this.state.answer.answer_selections)
    }

    getAnswerText(answer_selections) {
        if (isEmpty(answer_selections)) {
            return ''
        }

        const unit_prefix = get(this.props.component_data, 'data.unit_prefix', '')
        const unit_suffix = get(this.props.component_data, 'data.unit_suffix', '')
        const items = get(this.props.component_data, 'data.items', [])
        const selection_details = answer_selections.map((selection_data) => {
            const item = find(items, {id: selection_data.id})
            const selection_value_text = `${unit_prefix}${item.value}${unit_suffix}`
            const selection_subtotal = item.value * selection_data.amount
            const selection_subtotal_text = `${unit_prefix}${selection_subtotal}${unit_suffix}`
            const selection_text = `${item.title}: ${selection_value_text} x ${selection_data.amount} = ${selection_subtotal_text}`
            return selection_text
        })
        const total = this.getTotalValue(answer_selections)
        const total_text = `Total = ${unit_prefix}${total}${unit_suffix}`
        const answer_text = selection_details.join('\n') + '\n' + total_text

        return answer_text
    }

    getTotalValue(answer_selections) {
        let total = 0
        const items = get(this.props.component_data, 'data.items', [])
        answer_selections.map((selection_data) => {
            const item = find(items, {id: selection_data.id})
            total += item.value * selection_data.amount
        })
        return total
    }

    onClickItem() {
        return (item_id, step = 1) => {
            if (this.props.is_preview) {
                return false
            }

            // validation
            if (step > 0) {
                const value_maximum = get(this.props.component_data, 'data.value_maximum')
                if (value_maximum !== undefined) {
                    if (this.state.answer.total_value >= value_maximum) {
                        return false
                    }
                }
                const total_amount = sumBy(this.state.answer.answer_selections, 'amount')
                const amount_maximum = get(this.props.component_data, 'data.amount_maximum')
                if (amount_maximum !== undefined) {
                    if (total_amount >= amount_maximum) {
                        return false
                    }
                }
            }

            const answer_clone = cloneDeep(this.state.answer)
            const selected_item = find(answer_clone.answer_selections, {'id': item_id})
            if (selected_item) {
                selected_item.amount = selected_item.amount + step
            } else {
                answer_clone.answer_selections.push({
                    'id': item_id,
                    'amount': step
                })
            }
            remove(answer_clone.answer_selections, (item) => {
                return item.amount <= 0
            })
            answer_clone.answer_selections = orderBy(answer_clone.answer_selections, (item) => item.id)
            answer_clone.text = this.getAnswerText(answer_clone.answer_selections)
            answer_clone.total_value = this.getTotalValue(answer_clone.answer_selections)
            this.setState({
                answer: answer_clone
            })
        }
    }

    renderItems() {
        return (
            <Items
                component_data={this.props.component_data}
                onClickItem={this.onClickItem()}
            />
        )
    }

    renderSelections() {
        return (
            <Selections
                component_data={this.props.component_data}
                answer={this.state.answer}
                onClickItem={this.onClickItem()}
            />
        )
    }

    render() {
        return (
            <div className="challenge-component-selector">
                {this.renderItems()}
                {this.renderSelections()}
            </div>
        )
    }
}
