// Date time
export const DATE_FORMAT = 'MMM DD, YYYY'

export const CHALLENGE_IMAGE_PATH = 'challenge-images';
export const CHALLENGE_IMAGE_MAIN_SOURCE = 'hero_s_1.jpeg';
export const CHALLENGE_IMAGE_MAIN_THUMB = 'hero_t_1.jpeg';

export const MEASURES = {
    'metric': 'Metric measures',
    'imperial': 'U.S. measures'
};
export const MEASURE_COOKIE_NAME = 'mindsets_measure';

export const ROLE_TEACHER = 'teacher';
export const ROLE_STUDENT = 'student';

export const EMAIL_CATEGORY_PRODUCT = 'product';
export const EMAIL_CATEGORY_DIGEST = 'digest';
export const EMAIL_CATEGORY_RESOURCES = 'resources';
export const EMAIL_CATEGORY_CONTENT = 'content';
export const EMAIL_CATEGORY_FEEDBACK = 'feedback';
export const EMAIL_CATEGORIES = {
    [EMAIL_CATEGORY_PRODUCT]: 'I want to receive product updates that improve my experience with Mindsets.',
    [EMAIL_CATEGORY_DIGEST]: 'I want to receive the Mindsets Digest, featuring newly released Challenges in the Mindsets Library.',
    [EMAIL_CATEGORY_RESOURCES]: 'I want to receive actionable tips, best practices and resources created for teachers, including Live Classes.',
    [EMAIL_CATEGORY_CONTENT]: 'I want to receive information on Open Challenges and new types of content and learning programs from Mindsets.',
    [EMAIL_CATEGORY_FEEDBACK]: 'I want to be asked for feedback and participate in surveys and interviews to help improve Mindsets.'
};

export const AVATARS = [
    "cashtivity-icon-avatar-default",
    "cashtivity-icon-avatar-guy-01",
    "cashtivity-icon-avatar-girl-01",
    "cashtivity-icon-avatar-guy-02",
    "cashtivity-icon-avatar-girl-02",
    "cashtivity-icon-avatar-guy-03",
    "cashtivity-icon-avatar-girl-03",
    "cashtivity-icon-avatar-guy-04",
    "cashtivity-icon-avatar-girl-04",
    "cashtivity-icon-avatar-guy-05",
    "cashtivity-icon-avatar-girl-05",
    "cashtivity-icon-avatar-guy-06",
    "cashtivity-icon-avatar-girl-06",
    "cashtivity-icon-avatar-guy-07",
    "cashtivity-icon-avatar-girl-07",
    "cashtivity-icon-avatar-guy-08",
    "cashtivity-icon-avatar-girl-08",
    "cashtivity-icon-avatar-guy-09",
    "cashtivity-icon-avatar-girl-09",
    "cashtivity-icon-avatar-guy-10",
    "cashtivity-icon-avatar-girl-10",
    "cashtivity-icon-avatar-guy-11",
    "cashtivity-icon-avatar-girl-11",
    "cashtivity-icon-avatar-guy-12",
    "cashtivity-icon-avatar-girl-12",
    "cashtivity-icon-avatar-guy-13",
    "cashtivity-icon-avatar-girl-13",
    "cashtivity-icon-avatar-guy-14",
    "cashtivity-icon-avatar-girl-14",
    "cashtivity-icon-avatar-guy-15",
    "cashtivity-icon-avatar-girl-15",
    "cashtivity-icon-avatar-guy-16",
    "cashtivity-icon-avatar-girl-16",
    "cashtivity-icon-avatar-guy-17",
    "cashtivity-icon-avatar-girl-17",
    "cashtivity-icon-avatar-guy-18",
    "cashtivity-icon-avatar-girl-18",
    "cashtivity-icon-avatar-guy-19",
    "cashtivity-icon-avatar-girl-19",
    "cashtivity-icon-avatar-guy-20",
    "cashtivity-icon-avatar-girl-20",
    "cashtivity-icon-avatar-guy-21",
    "cashtivity-icon-avatar-girl-21",
    "cashtivity-icon-avatar-guy-22",
    "cashtivity-icon-avatar-girl-22",
    "cashtivity-icon-avatar-guy-23",
    "cashtivity-icon-avatar-girl-23",
    "cashtivity-icon-avatar-guy-24",
    "cashtivity-icon-avatar-girl-24",
    "cashtivity-icon-avatar-guy-25",
    "cashtivity-icon-avatar-girl-25",
    "cashtivity-icon-avatar-guy-26",
    "cashtivity-icon-avatar-girl-26",
    "cashtivity-icon-avatar-guy-27",
    "cashtivity-icon-avatar-girl-27",
    "cashtivity-icon-avatar-guy-28",
    "cashtivity-icon-avatar-girl-28",
    "cashtivity-icon-avatar-guy-29",
    "cashtivity-icon-avatar-girl-29",
    "cashtivity-icon-avatar-guy-30",
    "cashtivity-icon-avatar-girl-30",
    "cashtivity-icon-avatar-guy-31",
    "cashtivity-icon-avatar-girl-31",
    "cashtivity-icon-avatar-guy-32",
    "cashtivity-icon-avatar-girl-32",
    "cashtivity-icon-avatar-guy-33",
    "cashtivity-icon-avatar-girl-33",
    "cashtivity-icon-avatar-guy-34",
    "cashtivity-icon-avatar-girl-34",
    "cashtivity-icon-avatar-guy-35",
    "cashtivity-icon-avatar-girl-35",
    "cashtivity-icon-avatar-guy-36",
    "cashtivity-icon-avatar-girl-36",
    "cashtivity-icon-avatar-guy-37",
    "cashtivity-icon-avatar-girl-37",
    "cashtivity-icon-avatar-guy-38",
    "cashtivity-icon-avatar-girl-38",
    "cashtivity-icon-avatar-guy-39",
    "cashtivity-icon-avatar-girl-39",
    "cashtivity-icon-avatar-guy-40",
    "cashtivity-icon-avatar-girl-40",
    "cashtivity-icon-avatar-guy-41",
    "cashtivity-icon-avatar-girl-41",
    "cashtivity-icon-avatar-guy-42",
    "cashtivity-icon-avatar-girl-42",
    "cashtivity-icon-avatar-guy-43",
    "cashtivity-icon-avatar-girl-43",
    "cashtivity-icon-avatar-guy-44",
    "cashtivity-icon-avatar-girl-44",
    "cashtivity-icon-avatar-guy-45",
    "cashtivity-icon-avatar-girl-45",
    "cashtivity-icon-avatar-guy-46",
    "cashtivity-icon-avatar-girl-46",
    "cashtivity-icon-avatar-guy-47",
    "cashtivity-icon-avatar-girl-47",
    "cashtivity-icon-avatar-guy-48",
    "cashtivity-icon-avatar-girl-48",
    "cashtivity-icon-avatar-guy-49",
    "cashtivity-icon-avatar-girl-49",
    "cashtivity-icon-avatar-guy-50",
    "cashtivity-icon-avatar-girl-50"
];
