import React, {Component} from 'react'
import {Link} from "react-router-dom";
import classNames from 'classnames'


export default class SAMMenuNormal extends Component {
    constructor(props) {
        super(props)
        const base_url = window.location.href.split('?')[0]
        this.page_name = base_url.split('/').pop()
    }

    getClassNames(page_ref) {
        return classNames('sam-menu-item', {
            'sam-menu-item-active': page_ref === this.page_name
        })
    }

    getPageName(page_ref) {
        switch (page_ref) {
            case 'overview':
                return 'Overview'
            case 'teacher':
                return 'By Teacher'
            case 'school':
                return 'By School'
        }
    }

    renderMenuItem(page_ref) {
        let href = `/engagement/${page_ref}`

        return (
            <div className={this.getClassNames(page_ref)}>
                <Link to={href}>
                    {this.getPageName(page_ref)}
                </Link>
            </div>
        )
    }

    render() {
        return (
            <div className="sam-menu-normal">
                <div className="flex-row flex-box-10-20 flex-wrap">
                    {this.renderMenuItem('overview')}
                    {this.renderMenuItem('teacher')}
                    {this.renderMenuItem('school')}
                </div>

                <div className="divider">
                </div>
            </div>
        )
    }
}
