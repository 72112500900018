import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }

    return (
        <div className="flex-row flex-v-center user-info-field">
            <div className="user-info-field-label">Avatar</div>
            <div className={'cashtivity-icon ' + me.avatar}/>
        </div>
    );
};
