import React from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import Header from 'common/challenge/Header';
import analytic from 'utils/Analytic';

export default function () {
    const navigate = useNavigate();
    const lesson = useSelector(state => state.lesson);

    function renderReadyButton() {
        const onClick = () => {
            analytic.log(
                'classSetupReady',
                lesson.code
            );

            navigate(`/teacher/class/${lesson.code}`);
        }

        return (
            <div>
                <button className="btn btn-challenge" onClick={onClick}>
                    <span>{`I'm Ready`}</span>
                    <i className="fas fa-chevron-right"/>
                </button>
            </div>
        );
    }

    if (!lesson) {
        return null;
    }

    return (
        <Header
            title={`Class Setup`}
            challenge_version={lesson.challenge_version}
            show_back_button={true}
            show_create_button={false}
            more_to_render={renderReadyButton()}
        />
    );
}
