import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {statesConstants, SetUserAction} from "mindsets-js-sdk";
import {first} from "lodash";

import {isEducator} from "utils/role";

export default function () {
    const dispatch = useDispatch();
    const curricula = useSelector(state => state.curricula);
    const user = useSelector(state => state.user);
    let user_new = JSON.parse(JSON.stringify(user));

    if (isEducator(user_new) && user_new.country && statesConstants.STATES.hasOwnProperty(user_new.country)) {
        let stateOptions = [];
        stateOptions.push(<option key={'empty'} value='' disabled>Select state</option>);

        Object.keys(statesConstants.STATES[user_new.country]).map(state_key => {
            stateOptions.push(<option key={state_key}
                                      value={state_key}>{statesConstants.STATES[user_new.country][state_key]}</option>);
        });

        return (
            <div className="col s12 m6">
                <div className="inputfield">
                    <label htmlFor="state">
                        <div>State</div>
                    </label>
                    <select
                        value={user_new.state ? user_new.state : ''}
                        onChange={event => {
                            const state = event.target.value;
                            user_new.state = state;
                            if (state) {
                                const curriculum = first(curricula.filter(curriculum => {
                                    return curriculum.country_code === user_new.country && curriculum.state_code === state;
                                }).sort((a, b) => {
                                    if (a.is_default && !b.is_default) {
                                        return -1;
                                    } else if (!a.is_default && b.is_default) {
                                        return 1;
                                    }

                                    return 0;
                                }));
                                if (curriculum) {
                                    user_new.curriculum_id = curriculum.id;
                                } else {
                                    user_new.curriculum_id = null;
                                }
                            } else {
                                user_new.curriculum_id = null;
                            }
                            dispatch(SetUserAction(user_new));
                        }}
                        id="state"
                        className={"country-switcher browser-default"}>
                        {stateOptions}
                    </select>
                </div>
            </div>
        );
    }

    return null;
}
