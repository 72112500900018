import React from 'react';
import PropTypes from 'prop-types';
import {Droppable} from "react-beautiful-dnd";
import {get, find, filter} from 'lodash';

import AnswerOption from './AnswerOption';


function AnswerGroup({is_preview, answer_group, answer_layout}) {
    function getDroppableStyle() {
        const direction = get(answer_group, 'direction')
        const color_light = get(answer_group, 'color_light')
        const color_dark = get(answer_group, 'color_dark')
        return {
            display: "flex",
            flexDirection: (direction === 'vertical' ? "column" : "row"),
            flexWrap: "wrap",
            alignItems: "center",
            // justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "10px",
            background: "#FFFFFF",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: color_light,
            borderRadius: "4px",
            ...get(answer_group, 'droppable_style')
        }
    }

    function renderOptions() {
        const not_selected_options = filter(answer_group.options, ((option) => {
            return !find(answer_layout, (al) => {
                return get(al, 'content.dnd_id') === option.dnd_id
            })
        }))
        return not_selected_options.map((option, i) => {
            return (
                <AnswerOption
                    key={option.dnd_id}
                    is_answer={false}
                    is_preview={is_preview}
                    answer_group={answer_group}
                    option={option}
                    option_index={i}
                />
            )
        })
    }

    return (
        <div>
            <Droppable
                droppableId={answer_group.dnd_type}
                type={answer_group.dnd_type}
                direction={answer_group.direction}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getDroppableStyle()}
                    >
                        {renderOptions()}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}

AnswerGroup.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    answer_group: PropTypes.object.isRequired,
    answer_layout: PropTypes.array.isRequired
}

export default AnswerGroup
