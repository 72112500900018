const analyticTeacherClassSetup = (Analytic) => {
    Analytic.prototype.classSetupReady = function (class_code) {
        this.track('Class Setup Ready', {
            class_code
        })
    }

    Analytic.prototype.classSetupClassNameEdit = function (class_code) {
        this.track('Class Setup Class Name Edit', {
            class_code
        })
    }

    Analytic.prototype.classSetupCopyCode = function (class_code) {
        this.track('Class Setup Copy Code', {
            class_code
        })
    }

    Analytic.prototype.classSetupCopyLink = function (class_code) {
        this.track('Class Setup Copy Link', {
            class_code
        })
    }

    Analytic.prototype.googleClassroomAssignStudentsClicked = function (class_code) {
        this.track('Google Classroom Assign Students Clicked', {
            class_code
        })
    }

    Analytic.prototype.microsoftTeamsAssignStudentsClicked = function (class_code) {
        this.track('Microsoft Teams Assign Students Clicked', {
            class_code
        })
    }

    Analytic.prototype.cleverAssignStudentsClicked = function (class_code) {
        this.track('Clever Assign Students Clicked', {
            class_code
        })
    }
}

export default analyticTeacherClassSetup
