import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

function InlineEdit(
    {
        text,
        paramName,
        change,
        placeholder,
        className,
        activeClassName,
        minLength = 1,
        maxLength = 256,
        style,
        editingElement = 'input',
        staticElement = 'span',
        tabIndex = 0,
        isDisabled = false,
        stopPropagation = false
    }
) {
    const [editing, setEditing] = useState(false);
    const [text_state, setText] = useState(text);
    const inputRef = useRef();

    function selectInputText(element) {
        element.setSelectionRange(0, element.value.length);
    }

    function startEditing(e) {
        if (stopPropagation) {
            e.stopPropagation()
        }
        setEditing(true);
        setText(text);
    }

    function finishEditing() {
        if (isInputValid(text_state) && text !== text_state) {
            commitEditing();
        } else if (text === text_state || !isInputValid(text_state)) {
            cancelEditing();
        }
    }

    function cancelEditing() {
        setEditing(false);
        setText(text);
    }

    function commitEditing() {
        setEditing(false);
        setText(text_state);
        let newProp = {};
        newProp[paramName] = text_state;
        change(newProp);
    }

    function clickWhenEditing(e) {
        if (stopPropagation) {
            e.stopPropagation();
        }
    }

    function isInputValid(text) {
        return text.length >= minLength && text.length <= maxLength;
    }

    function keyDown(event) {
        if (event.keyCode === 13) {
            finishEditing();
        } else if (event.keyCode === 27) {
            cancelEditing();
        }
    }

    function textChanged(event) {
        setText(event.target.value.trim());
    }

    function renderField() {
        if (isDisabled) {
            const Element = staticElement;
            return <Element
                className={className}
                style={style}>
                {text_state || placeholder}
            </Element>;
        } else if (!editing) {
            const Element = staticElement;
            return <Element
                className={className}
                onClick={startEditing}
                tabIndex={tabIndex}
                style={style}>
                {text_state || placeholder}
            </Element>;
        } else {
            const Element = editingElement;
            return <Element
                onClick={clickWhenEditing}
                onKeyDown={keyDown}
                onBlur={finishEditing}
                className={activeClassName}
                placeholder={placeholder}
                defaultValue={text_state}
                onChange={textChanged}
                style={style}
                ref={inputRef}/>;
        }
    }

    useEffect(() => {
        setText(text);
        finishEditing();
    }, [text]);

    useEffect(() => {
        if (editing) {
            let inputElem = inputRef.current;
            inputElem.focus();
            selectInputText(inputElem);
        }
    }, [editing]);

    return (
        <>
            {renderField()}
            <i className="fas fa-pen" onClick={startEditing}/>
        </>
    );
}

InlineEdit.propTypes = {
    element: PropTypes.element,
    text: PropTypes.string.isRequired,
    paramName: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    style: PropTypes.object,
    editingElement: PropTypes.string,
    staticElement: PropTypes.string,
    tabIndex: PropTypes.number,
    isDisabled: PropTypes.bool
}

export default InlineEdit;
