import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";

import {DATE_FORMAT} from 'constants/index';
import analytic from 'utils/Analytic';
import {userCan} from 'utils/membership';
import FeatureLock from 'common/membership/FeatureLock';


function CreateClassCode(
    {
        challenge_version = {},
        beforeCreate = () => {
        },
        afterCreate = () => {
        },
        button_class = 'btn btn-on-dark'
    }
) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [lesson_created, setLessonCreated] = useState(undefined);

    function canCreate() {
        return !!me && userCan(me.subscription_status, 'create_mindsets_code', {challenge_version}) && !challenge_version.is_locked
    }

    function createClassCode(e) {
        if (e) {
            e.preventDefault();
        }

        if (!canCreate()) {
            window.open(process.env.PUBLIC_PRICING_URL, '_blank');
            return
        }

        beforeCreate();
        dispatch(LoadingAction(true, 'Creating Mindsets Code'));
        MindsetsJsSDK().Api.lesson.create({
            challenge_version_id: challenge_version.id,
            name: moment().format(DATE_FORMAT) + ' Class'
        }).then((response) => {
            setLessonCreated(response.lesson);
            afterCreate();
            dispatch(LoadingAction(false));
            analytic.log('createClassCode', {
                name: challenge_version.name,
                code: challenge_version.challenge_code.code
            }, response.lesson);
            navigate(`/teacher/class/${response.lesson.code}/setup`);
        }).catch((error) => {
            afterCreate();
            dispatch(LoadingAction(false));
            console.error(error);
        })
    }

    return (
        <FeatureLock
            enable={canCreate()}
            message={challenge_version.is_locked ? 'Please use the Challenges selected by your district administrator. This Challenge is not selected at this time' : undefined}
            show_price_link={!challenge_version.is_locked}
            title={challenge_version.is_locked ? 'This Mindsets Challenge is locked' : undefined}
            feature_type={`create_class_code`}
            onClickCallback={() => {
                if (challenge_version.is_locked) {
                    analytic.log('openChallengeLock', {
                        challenge_code: challenge_version.challenge_code.code,
                        challenge_name: challenge_version.name,
                        challenge_version: challenge_version.version,
                        element: "Create Class Code button"
                    });
                }
            }}
        >
            <button className={button_class} onClick={createClassCode}>
                <i className="fas fa-plus"/>
                <span>
                    {`Create`}
                </span>
            </button>
        </FeatureLock>
    );
}

CreateClassCode.propTypes = {
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            id: PropTypes.number.isRequired,
            code: PropTypes.string.isRequired
        }).isRequired,
        is_locked: PropTypes.bool.isRequired
    }).isRequired,
    beforeCreate: PropTypes.func,
    afterCreate: PropTypes.func,
    button_class: PropTypes.string
};

export default CreateClassCode;
