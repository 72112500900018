import React from 'react'
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'
import {get, forEach, isEqual, isObject, values} from 'lodash'

import BaseAnswer from './BaseAnswer'


export default class SliderSingle extends BaseAnswer {
    componentDidMount() {
        this.createSlider()
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.existing_answer, prevProps.existing_answer)) {
            if (this.props.is_preview) {
                const answer = get(this.props.existing_answer, 'data.answer')
                this.refs.slider.noUiSlider.set(
                    isObject(answer) ? values(answer) : answer
                )
            }
        }
    }

    defaultAnswer() {
        return this.props.component_data.data.value
    }

    getStartValue() {
        return [this.state.answer]
    }

    onUpdate() {
        return (values, handle) => {
            const answer = this.numberFormat(false).from(get(values, 0))
            this.setState({answer})
        }
    }

    createSlider() {
        noUiSlider.create(this.refs.slider, {
            start: this.getStartValue(),
            step: Number(this.props.component_data.data.step),
            connect: true,
            range: {
                'min': Number(this.props.component_data.data.min),
                'max': Number(this.props.component_data.data.max)
            },
            format: this.numberFormat(false),
            tooltips: true,
            margin: this.props.component_data.data.margin ? Number(this.props.component_data.data.margin) : Number(this.props.component_data.data.step)
        })

        this.refs.slider.noUiSlider.on('update', this.onUpdate())

        if (this.props.is_preview) {
            const origins = this.refs.slider.getElementsByClassName('noUi-origin')
            forEach(origins, (o) => {
                o.setAttribute('disabled', true)
            })
        }
    }

    numberFormat(keep_int) {
        const getDecimals = () => {
            if (keep_int) {
                return 0
            }

            const decimal_string = this.props.component_data.data.step.toString().split('.')[1]
            if (decimal_string === undefined) {
                return 0
            } else {
                return decimal_string.length
            }
        }

        const config = {
            decimals: getDecimals(),
            thousand: ',',
            prefix: this.props.component_data.data.unit_prefix,
            suffix: this.props.component_data.data.unit_suffix
        }

        // Legacy problem. unit should not be used anymore
        if (this.props.component_data.data.unit) {
            if (this.props.component_data.data.unit === '$') {
                config.prefix = this.props.component_data.data.unit
            } else {
                config.suffix = this.props.component_data.data.unit
            }
        }

        return wNumb(config)
    }

    render() {
        return (
            <div className="challenge-component-slider">
                <div className="section">
                    <div className="section">
                        <div ref="slider">
                        </div>

                        <div className="flex-row flex-v-center">
                            <div>
                                {
                                    this.numberFormat(
                                        Number.isInteger(Number(this.props.component_data.data.min))
                                    ).to(
                                        Number(this.props.component_data.data.min)
                                    )
                                }
                            </div>
                            <div className="flex-auto"></div>
                            <div>
                                {
                                    this.numberFormat(
                                        Number.isInteger(Number(this.props.component_data.data.max))
                                    ).to(
                                        Number(this.props.component_data.data.max)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
