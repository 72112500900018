import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import {
    LoadChallengeVersionAction,
    LoadChallengeVersionStreamEntitiesAction,
    SetChallengePreviewCurrentIndexAction,
    SetChallengePreviewShowReminderAction
} from "mindsets-js-sdk";

import Preview from './Preview';

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const challenge_version = useSelector(state => state.challenge_version);
    const current_index = useSelector(state => state.challenge_preview_current_index);
    const show_reminder = useSelector(state => state.challenge_preview_show_reminder);

    function goto(to_index) {
        // const can_preview_full = userCan('preview_full_challenge', {challenge_version: this.state.challenge_version})
        const can_preview_full = true
        if (!can_preview_full && to_index > 3) {
            return
        }

        if (show_reminder) {
            if (to_index === current_index + 1) {
                dispatch(SetChallengePreviewCurrentIndexAction(false));
            } else {
                dispatch(SetChallengePreviewCurrentIndexAction(to_index));
                dispatch(SetChallengePreviewShowReminderAction(false));
            }
        } else if (
            (to_index === 3 || to_index === 8 || to_index === 15)
            && to_index > current_index
        ) {
            dispatch(SetChallengePreviewCurrentIndexAction(to_index));
            dispatch(SetChallengePreviewShowReminderAction(true));
        } else {
            dispatch(SetChallengePreviewCurrentIndexAction(to_index));
        }
    }

    function renderPreview() {
        if (challenge_version) {
            return (
                <Preview goto={goto}/>
            )
        }
    }

    useEffect(() => {
        microsoftTeams.initialize();
    }, []);

    useEffect(() => {
        dispatch(LoadChallengeVersionAction(params.challenge_code))
            .then(challenge_version => dispatch(LoadChallengeVersionStreamEntitiesAction(challenge_version.id)))
            .catch(error => {
                navigate('/404');
                throw error;
            });
    }, []);

    return (
        <div id="teams-challenge-preview">
            <div id="challenge-preview-page">
                <div className="container">
                    {renderPreview()}
                </div>
            </div>
        </div>
    );
}
