import {get, sum, head} from 'lodash'

import {getEngagementName} from 'utils/stream/progress'


export default class SummaryCompiler {
    compile(rows, stream_entities, streams, lesson) {
        rows.push([
            'Total Pages: ' + stream_entities.length
        ]);
        rows.push([
            'Total Questions: ' + lesson.challenge_version.number_questions
        ]);
        rows.push(['']);
        rows.push([
            'Student name',
            'Student progress (Pages in this Challenge: ' + stream_entities.length + ')',
            'Questions completed (Questions in this Challenge: ' + lesson.challenge_version.number_questions + ')',
            'Student Engagement'
        ]);
        streams
            .map(stream => {
                let cards = stream.index;
                if (stream.index === stream_entities.length - 1) {
                    cards = stream_entities.length;
                } else if (stream.index === 0) {
                    cards = 1;
                }
                rows.push([
                    stream.user.fname + ' ' + head(stream.user.lname),
                    cards,
                    this.getQuestionsCompleted(stream.stream_entities),
                    getEngagementName(stream.completion)
                ]);
            });
        rows.push(['']);

        return rows;
    }

    getQuestionsCompleted(ses) {
        return sum(
            ses.map((se, key) => {
                return get(se, 'responses', []).length
            })
        )
    }
}
