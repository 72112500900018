import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {LoadingAction, authUtils} from "mindsets-js-sdk";

import {PasswordForgotAction} from "../../actions/login_actions";

export default function () {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(null);

    function submit() {
        dispatch(LoadingAction(true));
        dispatch(PasswordForgotAction({email}))
            .then(() => setSent(true))
            .catch((error) => setError(error))
            .then(() => dispatch(LoadingAction(false)));
    }

    function renderEmailInput() {
        return (
            <input
                className="password-email input-new browser-default"
                type="email"
                name="email"
                placeholder="Email"
                maxLength="50"
                value={email}
                onChange={event => {
                    event.preventDefault();
                    setEmail(event.target.value);
                }}
            />
        );
    }

    function renderError() {
        if (error) {
            return (
                <div className="password-error">
                    {error}
                </div>
            )
        }
    }

    function renderSubmitButton() {
        return (
            <button
                className="password-submit btn btn-challenge"
                disabled={!authUtils.isValidEmail(email)}
                onClick={submit}
            >
                Submit
            </button>
        );
    }

    function renderBody() {
        if (!sent) {
            return (
                <div className="flex-column flex-vh-center">
                    <div className="section">
                        <div className="password-description center-align">
                            {`Submit your email address and we’ll send you a link to change your password.`}
                        </div>
                    </div>

                    {renderEmailInput()}

                    {renderError()}

                    {renderSubmitButton()}
                </div>
            );
        } else {
            return (
                <div className="section">
                    <div className="password-description center-align">
                        {`The reset password link has been sent to you. Check your email for instructions.`}
                    </div>
                </div>
            );
        }
    }

    return (
        <div id='password-forgot'>
            <div className="container">
                <div className="password-container z-depth-1 flex-column flex-vh-center">
                    <div className="password-icon">
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_password.svg`}
                             alt='Password'/>
                    </div>

                    <h4 className="header-new">{`Forgot Your Password`}</h4>

                    {renderBody()}
                </div>
            </div>
        </div>
    );
}
