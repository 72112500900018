import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import classNames from 'classnames';
import {throttle} from 'lodash';
import MindsetsJsSDK from "mindsets-js-sdk";

import analytic from 'utils/Analytic';


export default function () {
    const stream = useSelector(state => state.stream);

    function onClick() {
        const raise_hand_at = !stream.raise_hand_at

        MindsetsJsSDK().Api.stream.update(
            stream.id, {raise_hand_at}
        ).then(() => {
            analytic.log(
                raise_hand_at ? 'studentRaiseHand' : 'studentLowerHand',
                stream.challenge_version.name,
                stream.lesson.code
            )
        }).catch(error => console.error(error));
    }

    function renderIcon() {
        const class_names = classNames({
            'far fa-hand-paper': !stream.raise_hand_at,
            'fas fa-hand-sparkles': stream.raise_hand_at
        })

        const title = stream.raise_hand_at ? 'Click this to lower hand' : 'Click this to raise hand'

        return (
            <i className={class_names} title={title}/>
        );
    }

    const onClickThrottle = useCallback(throttle(onClick, 2000, {leading: true, trailing: false}), []);

    if (!stream.lesson.is_raise_hand_enable) {
        return null;
    }

    return (
        <div
            className="student-stream-header-hand"
            onClick={onClickThrottle}
        >
            {renderIcon()}
        </div>
    );
}
