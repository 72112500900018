import React from 'react';
import PropTypes from "prop-types";

import Pause from './Pause';
import Header from './Header';
import MainContent from './MainContent';
import LeftToolBar from './LeftToolBar';

function StudentStreamUI({ref_components}) {
    return (
        <div>
            <Pause/>

            <Header/>

            <div className="flex-row">
                <LeftToolBar
                />

                <div className="flex-auto">
                    <MainContent
                        ref_components={ref_components}
                    />
                </div>
            </div>
        </div>
    );
}

StudentStreamUI.propTypes = {
    ref_components: PropTypes.array.isRequired
}

export default StudentStreamUI
