import {addOverviewContent} from './utils_overview'
import {
    addStudentTableTitleRequests,
    addStudentTableHeaderRequests,
    addStudentTableRequests
} from './utils_student_table'
import {
    addCategoryGraphDataRequests,
    addCategoryGraphChartRequests
} from './utils_category_graph'
import {
    addSkillGraphDataRequests,
    addSkillGraphChartRequests
} from './utils_skill_graph'
import {
    addWeightageDataRequests
} from './utils_weightage'
import {getSkillNames} from '../SkillGraph/utils'
import {getCategoryNames} from '../CategoryGraph/utils'
import {getPracticeAttr} from '../utils'


export const red_title = {
    foregroundColorStyle: {
        rgbColor: {
            red: 1,
            green : 0,
            blue: 0,
            alpha: 1
        }
    }
}

export const batchUpdateRequests = (spreadsheet, raw_data) => {
    const skill_length = getSkillNames(raw_data.student_skill_data).length
    const category_length = getCategoryNames(raw_data.student_skill_data).length
    let data_row_index = 0

    let requests_array = []

    requests_array = requests_array.concat(addSheetsRequests(raw_data))
    requests_array = requests_array.concat(addTitleRequests(1000, 'Overview of Student Performance Report at Mindsets'))
    requests_array = requests_array.concat(addMergeCellsRequests(1000, 0, 1, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(1000, 1))
    requests_array = requests_array.concat(addOverviewContent(1000))
    requests_array = requests_array.concat(addEmptyRowsRequests(1000, 2))
    requests_array = requests_array.concat(addTCRequests(1000))

    requests_array = requests_array.concat(addTitleRequests(9001, 'Chart Data - Internal Purposes Only', red_title))
    requests_array = requests_array.concat(addMergeCellsRequests(9001, 0, 1, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(9001, 1))
    requests_array = requests_array.concat(addSkillGraphDataRequests(9001, raw_data, 0))
    requests_array = requests_array.concat(addSkillGraphDataRequests(9001, raw_data, 1))
    requests_array = requests_array.concat(addCategoryGraphDataRequests(9001, raw_data, 0))
    requests_array = requests_array.concat(addCategoryGraphDataRequests(9001, raw_data, 1))
    requests_array = requests_array.concat(addEmptyRowsRequests(9001, 2))
    requests_array = requests_array.concat(addTCRequests(9001))
    data_row_index += 2

    requests_array = requests_array.concat(addTitleRequests(1001, 'Overall Performance'))
    requests_array = requests_array.concat(addMergeCellsRequests(1001, 0, 1, 0, 5))
    requests_array = requests_array.concat(addSkillGraphChartRequests(1001, 9001, raw_data, 0, 2, data_row_index, 0))
    data_row_index += 1 + skill_length
    requests_array = requests_array.concat(addSkillGraphChartRequests(1001, 9001, raw_data, 1, 2, data_row_index, 600))
    data_row_index += 1 + skill_length
    requests_array = requests_array.concat(addEmptyRowsRequests(1001, 20))
    requests_array = requests_array.concat(addTCRequests(1001))
    requests_array = requests_array.concat(addMergeCellsRequests(1001, 21, 22, 0, 5))

    if (getPracticeAttr(raw_data.practice_code, 'has_category')) {
        requests_array = requests_array.concat(addTitleRequests(1002, 'Overall CAASPP Alignment'))
        requests_array = requests_array.concat(addMergeCellsRequests(1002, 0, 1, 0, 5))
        requests_array = requests_array.concat(addCategoryGraphChartRequests(1002, 9001, raw_data, 0, 2, data_row_index, 0))
        data_row_index += 1 + category_length
        requests_array = requests_array.concat(addCategoryGraphChartRequests(1002, 9001, raw_data, 1, 2, data_row_index, 600))
        data_row_index += 1 + category_length
        requests_array = requests_array.concat(addEmptyRowsRequests(1002, 20))
        requests_array = requests_array.concat(addTCRequests(1002))
        requests_array = requests_array.concat(addMergeCellsRequests(1002, 21, 22, 0, 5))
    } else {
        requests_array = requests_array.concat({
            deleteSheet: {
                sheetId: 1002
            }
        })
    }

    requests_array = requests_array.concat(addTitleRequests(1003, 'Student Achievement'))
    requests_array = requests_array.concat(addMergeCellsRequests(1003, 0, 1, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(1003, 1))
    requests_array = requests_array.concat(addStudentTableTitleRequests(1003))
    requests_array = requests_array.concat(addMergeCellsRequests(1003, 2, 3, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(1003, 1))
    requests_array = requests_array.concat(addStudentTableHeaderRequests(1003, raw_data))
    requests_array = requests_array.concat(addStudentTableRequests(1003, raw_data, 0))
    requests_array = requests_array.concat(addEmptyRowsRequests(1003, 2))
    requests_array = requests_array.concat(addTCRequests(1003))

    requests_array = requests_array.concat(addTitleRequests(1004, 'Student Potential'))
    requests_array = requests_array.concat(addMergeCellsRequests(1004, 0, 1, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(1004, 1))
    requests_array = requests_array.concat(addStudentTableTitleRequests(1004))
    requests_array = requests_array.concat(addMergeCellsRequests(1004, 2, 3, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(1004, 1))
    requests_array = requests_array.concat(addStudentTableHeaderRequests(1004, raw_data))
    requests_array = requests_array.concat(addStudentTableRequests(1004, raw_data, 1))
    requests_array = requests_array.concat(addEmptyRowsRequests(1004, 2))
    requests_array = requests_array.concat(addTCRequests(1004))

    requests_array = requests_array.concat(addTitleRequests(2001, 'Mindsets Challenges Mapping'))
    requests_array = requests_array.concat(addMergeCellsRequests(2001, 0, 1, 0, 5))
    requests_array = requests_array.concat(addEmptyRowsRequests(2001, 1))
    requests_array = requests_array.concat(addWeightageDataRequests(2001, raw_data))
    requests_array = requests_array.concat(addEmptyRowsRequests(2001, 1))
    requests_array = requests_array.concat(addTCRequests(2001))

    // console.log('requests_array', requests_array)

    return gapi.client.sheets.spreadsheets.batchUpdate({
        spreadsheetId: spreadsheet.spreadsheetId,
        requests: requests_array
    })
}

const addSheetsRequests = (raw_data) => {
    return [
        {
            addSheet: {
                properties: {
                    title: "Overview",
                    sheetId: 1000
                }
            }
        },
        {
            addSheet: {
                properties: {
                    title: "Overall Performance",
                    sheetId: 1001
                }
            }
        },
        {
            addSheet: {
                properties: {
                    title: "Overall Alignment",
                    sheetId: 1002
                }
            }
        },
        {
            addSheet: {
                properties: {
                    title: "Student Achievement",
                    sheetId: 1003
                }
            }
        },
        {
            addSheet: {
                properties: {
                    title: "Student Potential",
                    sheetId: 1004
                }
            }
        },
        {
            addSheet: {
                properties: {
                    title: "Mapping",
                    sheetId: 2001
                }
            }
        },
        {
            addSheet: {
                properties: {
                    title: "Chart Data",
                    sheetId: 9001
                }
            }
        },
        {
            deleteSheet: {
                sheetId: 0
            }
        }
    ]
}

export const addEmptyRowsRequests = (sheetId, number_of_rows = 1) => {
    const rows = []
    for (let i = 0; i < number_of_rows; i++) {
        rows.push({
            values: [
                {
                    userEnteredValue: {
                        stringValue: ''
                    }
                }
            ]
        })
    }
    return [
        {
            appendCells: {
                sheetId,
                fields: '*',
                rows
            }
        }
    ]
}

export const addMergeCellsRequests = (sheetId, startRowIndex, endRowIndex, startColumnIndex, endColumnIndex) => {
    return [
        {
            mergeCells: {
                range: {
                    sheetId,
                    startRowIndex,
                    endRowIndex,
                    startColumnIndex,
                    endColumnIndex
                },
                mergeType: 'MERGE_ALL'
            }
        }
    ]
}

export const addTitleRequests = (sheetId, title, text_format = {}) => {
    return [
        {
            appendCells: {
                sheetId,
                fields: '*',
                rows: [
                    {
                        values: [
                            {
                                userEnteredValue: {
                                    stringValue: title
                                },
                                userEnteredFormat: {
                                    textFormat: {
                                        bold: true,
                                        fontSize: 12,
                                        ...text_format
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
}

const addTCRequests = (sheetId) => {
    return [
        {
            appendCells: {
                sheetId,
                fields: '*',
                rows: [
                    {
                        values: [
                            {
                                userEnteredValue: {
                                    stringValue: '© Mindsets Learning 2022. All rights reserved.'
                                        + '\n'
                                        + 'For terms of use and privacy, go to www.mindsets.com/terms'
                                        + '\n'
                                        + 'For any questions, contact hello@mindsets.com'
                                },
                                userEnteredFormat: {
                                    textFormat: {
                                        fontSize: 8
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
}