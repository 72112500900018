import React, {useEffect, useState} from 'react';
import {isEmpty, map} from 'lodash';
import classNames from 'classnames';

const PAGES_PER_GROUP = 10;

export default function ({data_table, selectPage}) {

    const [plusPage, setPlusPage] = useState(0);
    const {current, pages} = data_table;

    useEffect(() => {
        if (plusPage != 0 && current != undefined) {
            setPlusPage(0);
        }
    }, [current]);

    if (!data_table || isEmpty(data_table)) {
        return null;
    }

    const renderNumbers = () => {
        const start = Math.floor((current - 1) / PAGES_PER_GROUP) * PAGES_PER_GROUP + 1 + plusPage;
        const end = (start + PAGES_PER_GROUP - 1 < pages) ? start + PAGES_PER_GROUP - 1 : pages;
        const page_items = new Array(end - start + 1).fill(1);

        const onClick = (page_to_select) => () => {
            selectPage(page_to_select);
            // setPlusPage(0);
        }

        return page_items.map((n, key) => {
            const page_number = start + key;
            const class_name = classNames('pam-component-table-page-item', {
                'pam-component-table-page-item-active': page_number == current
            });
            return (
                <div key={key} className={class_name} onClick={onClick(page_number)}>
                    {page_number}
                </div>
            );
        });
    }

    const renderPrev = () => {
        const isActive = () => {
            return plusPage >= PAGES_PER_GROUP;
        }
        const onClick = () => {
            if (isActive()) {
                setPlusPage(plusPage - PAGES_PER_GROUP);
            }
        }
        const class_name = classNames('pam-component-table-page-item', {
            'pam-component-table-page-item-disable': !isActive()
        });
        return (
            <div className={class_name} onClick={onClick}>
                {`<`}
            </div>
        )
    }

    const renderNext = () => {
        const isActive = () => {
            return plusPage + PAGES_PER_GROUP < data_table.pages;
        }
        const onClick = () => {
            if (isActive()) {
                setPlusPage(plusPage + PAGES_PER_GROUP);
            }
        }
        const class_name = classNames('pam-component-table-page-item', {
            'pam-component-table-page-item-disable': !isActive()
        });
        return (
            <div className={class_name} onClick={onClick}>
                {`>`}
            </div>
        )
    }

    return (
        <div className="pam-component-table-page">
            <div className="flex-row flex-h-center flex-gap-10">
                {renderPrev()}
                {renderNumbers()}
                {renderNext()}
            </div>
        </div>
    );
}
