import React from 'react';
import ActiveChallenge from '../Library/Challenges/ActiveChallenge';


export default function (props) {
    if (!props.challengeVersion) {
        return (
            <div className="wm-challenge-card-empty">
                {`Challenge card`}
            </div>
        )
    }

    return (
        <div className="wm-challenge-card">
            <ActiveChallenge
                challenge_version={props.challengeVersion}
                noPreview={true}
            />
        </div>
    );
}