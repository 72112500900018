import React, {useEffect, useRef, useState} from 'react';
import {map} from 'lodash';
import moment from "moment";

import {DATE_FORMAT} from "constants/index";

export default function ({one_student, printable}) {

    const renderSkills = () => {
        const renderPercentage = (p) => {
            if (p === null || p === undefined || p === '') {
                return '-';
            } else {
                return p + '%';
            }
        }

        return map(one_student.skills, (skill, skill_code) => {
            return (
                <td key={skill_code} className="" style={{background: skill.color}}>
                    {renderPercentage(skill.percentage)}
                </td>
            );
        })
    }

    return (
        <tr className="">
            <td className="">
                {one_student['Student Name']}
            </td>

            <td className="">
                {one_student['Challenge']}
            </td>

            <td className="">
                {one_student['Grade']}
            </td>

            <td className="">
                {moment(one_student['Class Date']).format(DATE_FORMAT)}
            </td>

            <td className="">
                {one_student['School']}
            </td>

            {renderSkills()}
        </tr>
    );
}
