import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from 'lodash';

import {isWindowsApp} from 'utils/windows'
import Popup from 'common/Popup'
import analytic from 'utils/Analytic'
import ButtonCopyText from 'common/ButtonCopyText'
import ToggleLessonModeAction from 'common/ToggleLessonModeAction';
import ClassSetupLink from './ClassSetupLink';
import WowMomentLink from './WowMomentLink';
import {getOwnerStream, getStudentStreams} from "../utils";


export default function () {
    const navigate = useNavigate();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);
    const streams_orig = useSelector(state => state.streams);
    const owner_stream = getOwnerStream(streams_orig, me.id);
    const streams = getStudentStreams(streams_orig, me.id);

    function getInstructionText() {
        return `1. Go to ${window.location.origin} \n`
            + `2. Sign up or login \n`
            + `3. Use this code to join: \n`
            + lesson.code.toUpperCase()
    }

    function goBack(e) {
        e.preventDefault();
        navigate('/');
    }

    function whenCopyLink() {
        analytic.log(
            'facilitationCopyCode',
            lesson.code
        );
    }

    function renderBack() {
        return (
            <div className="teacher-facilitation-nav-back flex-row flex-v-center no-wrap" onClick={goBack}>
                <i className="fas fa-chevron-left"/>
                <div>
                    {`Back to home`}
                </div>
            </div>
        );
    }

    function renderTitle() {
        if (!isEmpty(lesson)) {
            return (
                <div>
                    <div>
                        <strong>{lesson.challenge_version.name}</strong>
                    </div>
                    <div>
                        {lesson.name}
                    </div>
                </div>
            );
        }
    }

    function renderTeacherStream() {
        if (!isEmpty(owner_stream)) {
            const onClick = () => {
                analytic.log('facilitationViewAsStudent', {
                    name: lesson.challenge_version.name,
                    code: lesson.challenge_version.challenge_code.code,
                }, lesson.code);
            }

            return (
                <div>
                    <Link
                        className="btn btn-flat"
                        to={`/hello/${owner_stream.code}`}
                        target={isWindowsApp() ? '_self' : '_blank'}
                        onClick={onClick}
                    >
                        <span>{`View as Student`}</span>
                        <i className="fas fa-external-link-alt"/>
                    </Link>
                </div>
            );
        }
    }

    function renderCode() {
        if (!isEmpty(lesson)) {
            return (
                <div className="teacher-facilitation-nav-code flex-auto flex-none-l flex-row flex-v-center flex-h-end">
                    <Popup popup_content={renderPopup()} show={true} right="0px">
                        <button className="btn btn-flat btn-large">
                            <span>{lesson.code}</span>
                            <i className="fas fa-caret-down"/>
                        </button>
                    </Popup>
                </div>
            );
        }
    }

    function renderModeToggle() {
        if (me && lesson.user_id === me.id) {
            return (
                <div className="teacher-facilitation-nav-setup">
                    <ToggleLessonModeAction
                        lesson={lesson}
                        type='ticks'
                        with_heading={true}
                    />
                </div>
            );
        }

        return null;
    }

    function renderPopup() {
        return (
            <div className="teacher-facilitation-nav-popup">
                {renderInstruction()}

                <ClassSetupLink/>

                <WowMomentLink/>

                <hr className="teacher-facilitation-nav-popup-divider"/>

                {renderModeToggle()}

                <div className="teacher-facilitation-nav-popup-title hide-on-large-only flex-row flex-v-center">
                    {renderTitle()}
                </div>

                <hr className="teacher-facilitation-nav-popup-divider"/>

                <div className="section hide-on-large-only flex-row flex-vh-center">
                    {renderTeacherStream()}
                </div>
            </div>
        );
    }

    function renderInstructionSteps() {
        if (lesson.hasOwnProperty('provider') && 'google' === lesson.provider) {
            return (
                <div className="teacher-facilitation-nav-instruction-steps">
                    <div>
                        {`You have assigned your students to this Challenge through Google Classroom.`}
                    </div>
                    <div>&nbsp;</div>
                    <div>
                        {`New students can join the Challenge using this code:`}
                    </div>
                </div>
            );
        }

        return (
            <div className="teacher-facilitation-nav-instruction-steps">
                <div>
                    {`1. Go to `}
                    {window.location.origin}
                </div>
                <div>
                    {`2. Sign up or login`}
                </div>
                <div>
                    {`3. Use this code to join:`}
                </div>
            </div>
        );
    }

    function renderInstruction() {
        if (!isEmpty(lesson)) {
            return (
                <div className="teacher-facilitation-nav-instruction flex-column">
                    <div className="teacher-facilitation-nav-instruction-logo">
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`} alt='Logo'/>
                    </div>

                    <h4 className="header-new">{`Student Instructions`}</h4>

                    <div className="">
                        {`Students joined: `}
                        <strong>{streams.length}</strong>
                    </div>

                    {renderInstructionSteps()}

                    <div className="flex-row flex-v-center flex-box-5-10">
                        <div>
                            <div className="teacher-facilitation-nav-instruction-code">
                                {lesson.code}
                            </div>
                        </div>
                        <div>
                            <ButtonCopyText
                                text_to_copy={getInstructionText()}
                                whenClick={whenCopyLink}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="teacher-facilitation-nav flex-row">
            <div className="container flex-row">
                {renderBack()}

                <div
                    className="teacher-facilitation-nav-title hide-on-med-and-down flex-auto flex-row flex-v-center">
                    {renderTitle()}
                </div>

                <div className="hide-on-med-and-down flex-row flex-v-center no-wrap">
                    {renderTeacherStream()}
                </div>

                {renderCode()}
            </div>
        </div>
    );
}
