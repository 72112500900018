import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {isEmpty, get} from 'lodash'

import CardComponent from 'common/challengeNew/CardComponent'


class CardRow extends Component {
    getContainerStyle() {
        const {container_style} = this.props.row_data
        return container_style
    }

    getConfig() {
        const {config={}} = this.props.row_data
        return config
    }

    getFlex(i) {
        const {flex=true, flex_custom=[]} = this.getConfig()
        if (flex) {
            return get(flex_custom, i, 1)
        } else {
            return 0
        }
    }

    getHAlign() {
        const {h_align} = this.getConfig()
        return h_align ? `flex-h-${h_align}` : ''
    }

    getVAlign() {
        const {v_align} = this.getConfig()
        return v_align ? `flex-v-${v_align}` : ''
    }

    getFlexWrap() {
        const {wrap=true} = this.getConfig()
        return wrap ? `flex-wrap` : ''
    }

    renderComponents() {
        const {components} = this.props.row_data
        if (!components) {
            return
        }

        return components.map((component_data, i) => {
            return (
                <div className={`flex-${this.getFlex(i)}`} key={i}>
                    <CardComponent
                        component_data={component_data}
                        challenge_version={this.props.challenge_version}
                        stream_entity={this.props.stream_entity}
                        lesson_id={this.props.lesson_id}
                        stream_id={this.props.stream_id}
                        is_preview={this.props.is_preview}
                        is_latest={this.props.is_latest}
                        getSupportData={this.props.getSupportData}
                        getExistingAnswer={this.props.getExistingAnswer}
                        refComponent={this.props.refComponent(i)}
                    />
                </div>
            )
        })
    }

    render() {
        const flex_class = `flex-row-m flex-box-0-10 ${this.getHAlign()} ${this.getVAlign()} ${this.getFlexWrap()}`
        return (
            <div className="challenge-card-row" style={this.getContainerStyle()}>
                <div className={flex_class}>
                    {this.renderComponents()}
                </div>
            </div>
        )
    }
}

CardRow.propTypes = {
    row_data: PropTypes.object.isRequired,
    challenge_version: PropTypes.object.isRequired,
    stream_entity: PropTypes.object.isRequired,
    lesson_id: PropTypes.number,
    stream_id: PropTypes.number,
    is_preview: PropTypes.bool,
    is_latest: PropTypes.bool,
    getSupportData: PropTypes.func.isRequired,
    getExistingAnswer: PropTypes.func.isRequired,
    refComponent: PropTypes.func
}

export default CardRow
