import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

import {DATE_FORMAT} from 'constants/index';

import './lesson_row.scss';

function LessonRow(
    {
        challenge_version,
        lesson,
        show_challenge_name = false
    }
) {
    function renderAnswerKeysState() {
        if (challenge_version.answer_key_url && challenge_version.bonus_answer_key_url) {
            return (
                <div className='flex-row flex-h-start flex-v-center'>
                    <i className="action-icon fas fa-key mr-2"/>
                    <div className=''>Answer Keys {lesson.is_answer_keys_shown ? 'ON' : 'OFF'}</div>
                </div>
            );
        }

        return null;
    }

    function renderInfo() {
        const created_at = moment(lesson.created_at * 1000).format(DATE_FORMAT)
        return (
            <div className="flex-row flex-wrap flex-box-0-5">
                <div>{`Created ${created_at}`}</div>
                <div>{`${lesson.students_count} student${lesson.students_count === 1 ? '' : 's'} joined`}</div>
                {renderAnswerKeysState()}
            </div>
        );
    }

    function renderChallengeName() {
        if (show_challenge_name) {
            return (
                <div className='lessonlist-lesson-challengename'>{challenge_version.name}</div>
            );
        }
    }

    function renderBigMark() {
        if (lesson.is_done) {
            return (
                <div className='mark-done-tick flex-row flex-v-center hide-on-small-only'>
                    <i
                        className={'far fa-check-circle fa-4x mark-done-tick-icon'}
                        data-tip='Class completed'
                    />
                    <ReactTooltip/>
                </div>
            );
        }

        return null;
    }

    function renderSmallMark() {
        if (lesson.is_done) {
            return (
                <div>
                    <i
                        className={'far fa-check-circle fa-lg mark-done-tick-icon'}
                        data-tip='Class completed'
                    />
                    <ReactTooltip/>
                </div>
            );
        }

        return null;
    }

    return (
        <div className={"lessonlist-lesson flex-row "}>
            <div className="lessonlist-lesson-classcode hide-on-small-only flex-row flex-v-center">
                {lesson.code}
            </div>

            <div className="lessonlist-lesson-classname flex-auto">
                <div className="lessonlist-lesson-classcode hide-on-med-and-up">
                    {lesson.code} {renderSmallMark()}
                </div>
                {renderChallengeName()}

                <div className="lessonlist-lesson-classname-name flex-row">{lesson.name}</div>

                {renderInfo()}
            </div>

            {renderBigMark()}
        </div>
    );
}

LessonRow.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        answer_key_url: PropTypes.string,
        bonus_answer_key_url: PropTypes.string
    }).isRequired,
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        is_done: PropTypes.bool.isRequired,
        created_at: PropTypes.number.isRequired,
        students_count: PropTypes.number.isRequired,
        is_answer_keys_shown: PropTypes.bool.isRequired
    }).isRequired,
    show_challenge_name: PropTypes.bool
};

export default LessonRow;
