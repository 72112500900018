import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    authUtils,
    accessConstants,
    datesConstants,
    filterConstants,
    LoadSchoolAction,
    LoadLessonsAction
} from "mindsets-js-sdk";
import UserDropdownItem from "mindsets-js-sdk/src/react/components/UserDropdownItem";
import moment from "moment";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import useAuth from "../../hooks/useAuth";
import GeneralError from "../GeneralError";

import './school_dashboard.scss';

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const school = useSelector(state => state.school);
    const lessons = useSelector(state => state.lessons);
    const loading = useSelector(state => state.loading);

    function getSchoolId() {
        if (params.school_id) {
            return params.school_id;
        } else {
            return me.school_id;
        }
    }

    function fetchData() {
        if (me && (me.school_id || params.school_id) && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_SCHOOL_DASHBOARD])) {
            Promise.resolve()
                .then(fetchSchool)
                .then(fetchLessons)
                .catch(error => {
                    console.error(error);
                });
        }
    }

    function fetchSchool() {
        return dispatch(LoadSchoolAction(getSchoolId(), {
            'selectors[0]': 'subscriptions',
            'selectors[1]': 'district',
            'selectors[3]': 'teachers'
        }));
    }

    function fetchLessons() {
        return dispatch(LoadLessonsAction({
            'filters[school_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + getSchoolId(),
            'selectors[0]': 'challenge_version',
            'selectors[1]': 'user',
            limit: 0
        }));
    }

    function renderSubscription() {
        if (school && Array.isArray(school.subscriptions) && school.subscriptions.length > 0) {
            const subscription = school.subscriptions.find(subscription => moment().isBetween(moment(subscription.starts_at * 1000), moment(subscription.ends_at * 1000)));
            if (subscription) {
                return (
                    <div>Has valid subscription. Ends
                        on {moment(subscription.ends_at * 1000).format('DD MMM YYYY')}</div>
                );
            } else {
                return (
                    <div>Subscription expired</div>
                );
            }
        }

        return (
            <div>Never subscribed</div>
        );
    }

    function renderTeachers() {
        if (Array.isArray(school.teachers) && school.teachers.length > 0) {
            return (
                <div className='teacher-list flex-grow-1'>{school.teachers.sort((a, b) => {
                    if (a.fname < b.fname) {
                        return -1;
                    }
                    if (a.fname > b.fname) {
                        return 1;
                    }

                    return 0;
                }).map(teacher => {
                    return (
                        <div key={teacher.id} className='teacher-item'><UserDropdownItem item={teacher}/></div>
                    );
                })}</div>
            );
        }

        return (
            <div>No teachers assigned yet</div>
        );
    }

    function renderLessons() {
        if (lessons.length > 0) {
            return (
                <div className='lesson-list'>{lessons.map(lesson => {
                    return (
                        <div key={lesson.id} className='lesson-item'>
                            <div className='lesson-code'>{lesson.code.toUpperCase()}</div>
                            <div className='lesson-challenge'>{lesson.challenge_version.name}</div>
                            <div>{lesson.name}</div>
                            <div>{lesson.user.fname + ' ' + lesson.user.lname}</div>
                        </div>
                    );
                })}</div>
            );
        }

        return (
            <div>No Classes created yet</div>
        );
    }

    function renderLessonCalendar() {
        if (lessons.length > 0) {
            return (
                <div>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: 'prevYear,prev,next,nextYear today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek'
                        }}
                        events={lessons.map(lesson => {
                            const date = moment(lesson.created_at * 1000);

                            return ({
                                id: lesson.id,
                                title: lesson.code.toUpperCase() + (lesson.challenge_version ? ' - ' + lesson.challenge_version.name : ''),
                                start: date.format(datesConstants.SYSTEM_DATE_TIME_FORMAT),
                                end: date.add(1, 'hour').format(datesConstants.SYSTEM_DATE_TIME_FORMAT),
                            })
                        })}
                    />
                </div>
            );
        }

        return null;
    }

    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        fetchData();
    }, [me])

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_SCHOOL_DASHBOARD])) {
        if (params.school_id && school && school.district_id && me.school.district_id && school.district_id !== me.school.district_id) {
            return <GeneralError title='You do not have access to this page'/>;
        }
        if (school) {
            return (
                <div className='container'>
                    <div id="school-dashboard">
                        <div className='section'>
                            <div className='school-logo flex-row flex-vh-center'
                                 style={{height: "100px", border: '1px dashed grey', borderRadius: "25px"}}>
                                <span>School LOGO</span>
                            </div>
                        </div>
                        <div className='section'>
                            <h2>{school.name}</h2>
                            {school.district && school.district.name ? <div
                                className="">{school.district.name}</div> : null}
                            {renderSubscription()}
                        </div>
                        <div className='section'>
                            <div className='flex-row flex-box-0-10 flex-h-between'>
                                {renderTeachers()}
                                {renderLessons()}
                            </div>
                        </div>
                        <div className='section'>
                            {renderLessonCalendar()}
                        </div>
                    </div>
                </div>
            );
        } else if (!loading || !me.school_id && !params.school_id) {
            return <GeneralError title='You do not have access to this page'/>;
        } else {
            return null;
        }
    } else {
        return <GeneralError title='You do not have access to this page'/>;
    }
}
