import React from 'react';


export default function () {
    const GOOGLE_FORM_URL = `https://docs.google.com/forms/d/e/1FAIpQLScByQpzsoqEJTq5KVU3YWvc1CwpFUIopwNuYpBQExcsK0XU8w/viewform?embedded=true`;

    return (
        <div id="teacher-quote">
            <div className="container">
                <div className="section">
                    <h1 className="header-new">{`Get a Quote`}</h1>
                </div>

                <div className="section">
                    <div className="flex-row flex-v-center flex-box-1-2">
                        <div>
                            {`Can't see the form? `}
                        </div>
                        <a className="link-new" href={GOOGLE_FORM_URL} target="_blank">
                            {`Click here.`}
                        </a>
                    </div>

                    <div className="teacher-quote-iframe">
                        <iframe
                            src={GOOGLE_FORM_URL}
                            frameBorder="0"
                            marginHeight="0"
                            marginWidth="0"
                        >
                            Loading...
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
