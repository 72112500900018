import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const data = useSelector(state => state.student_story_data);

    return (
        <div className='student-story-page page11'>
            <div className='level-up'>Your Top Math Skills</div>
            <div className='second-row'>
                {data.top_math_skills.map(skill => {
                    return (
                        <img
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/student_story/skill_badges/${skill}.png`}
                            alt={skill}/>
                    );
                })}
            </div>
        </div>
    );
}