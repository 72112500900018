import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {LoadingAction, JoinLessonAction} from "mindsets-js-sdk";

import GeneralError from "../pages/GeneralError";
import useAuth from "../hooks/useAuth";

export default function () {
    useAuth(true);

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const me = useSelector(state => state.me);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('Could not join the Class.');

    useEffect(() => {
        if (!params.lesson_code) {
            setFailed(true);
        }
    }, []);
    useEffect(() => {
        if (params.lesson_code && me) {
            if (me.persona !== 'student') {
                return navigate(`/invalid-persona?q=${btoa(JSON.stringify({
                    lesson_code: params.lesson_code,
                    route_name: 'student.join',
                    persona: 'student'
                }))}`);
            }

            dispatch(JoinLessonAction(params.lesson_code))
                .then(response => navigate(`/hello/${response.stream_code}`))
                .catch(error => {
                    setMessage(error);
                    setFailed(true);
                })
                .then(() => dispatch(LoadingAction(false)));
        }
    }, [me]);

    function renderError() {
        if (failed === true) {
            return <GeneralError content={message}/>;
        }

        return null;
    }

    return (
        <>
            {renderError()}
        </>
    );
};
