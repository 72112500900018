import React, {Component} from 'react'
import {Link} from "react-router-dom";
import classNames from 'classnames'


class PAMMenuNormal extends Component {
    constructor(props) {
        super(props)
        const base_url = window.location.href.split('?')[0]
        this.page_name = base_url.split('/').pop()
    }

    getClassNames(page_ref) {
        return classNames('pam-menu-item', {
            'pam-menu-item-active': page_ref === this.page_name
        })
    }

    getPageName(page_ref) {
        switch (page_ref) {
            case 'challenge':
                return 'Challenge'
        }
    }

    renderMenuItem(page_ref) {
        let href = `/performance/${page_ref}`

        return (
            <div className={this.getClassNames(page_ref)}>
                <Link to={href}>
                    {this.getPageName(page_ref)}
                </Link>
            </div>
        )
    }

    render() {
        return (
            <div className="pam-menu-normal">
                <div className="flex-row flex-box-10-20 flex-wrap">
                    {this.renderMenuItem('challenge')}
                </div>

                <div className="divider">
                </div>
            </div>
        )
    }
}

PAMMenuNormal.propTypes = {
}

export default PAMMenuNormal
