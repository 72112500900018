import moment from 'moment'

const analyticChallengeGeneral = (Analytic) => {
    Analytic.prototype.createClassCode = function (challenge, lesson) {
        this.track('Create Class Code', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            class_code: lesson.code
        })
        this.increment("No. of Class Codes Created")
        this.setUserData({
            "Last Class Code": lesson.code,
            "Last Class Code Created": moment().format(),
            "Last Challenge Created": challenge.name
        })
    }

    Analytic.prototype.fastFactsDownloaded = function (challenge) {
        this.track('Fast Facts Downloaded', {
            challenge_code: challenge.code,
            challenge_name: challenge.name
        })
    }

    Analytic.prototype.immersiveReaderOpened = function (challenge, se, from) {
        this.track('Immersive Reader Opened', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            stream_entity_id: se.id,
            from
        })
    }

    Analytic.prototype.immersiveReaderClosed = function (challenge, se, from) {
        this.track('Immersive Reader Closed', {
            challenge_code: challenge.code,
            challenge_name: challenge.name,
            stream_entity_id: se.id,
            from
        })
    }

    Analytic.prototype.immersiveReaderChanged = function (data) {
        this.track('Immersive Reader Changed', data)
    }

    Analytic.prototype.cardSelectorOnSelect = function (card_index) {
        this.track('Select a Card on Card Selector', {
            card_index
        })
    }

    Analytic.prototype.teacherNavigatedToCard = function (data = {}) {
        this.track('Teacher navigated to card', data)
    }

    Analytic.prototype.graphingAnswerOption = function (answer_option) {
        this.track('Graphing Answer Option', {answer_option})
    }

    Analytic.prototype.downloadAnswerKey = function (challenge_version, lesson_id) {
        this.track('Download Answer Key', {
            challenge_id: challenge_version.id,
            challenge_code: challenge_version.code,
            challenge_name: challenge_version.name,
            lesson_id
        })
    }

    Analytic.prototype.downloadBonusAnswerKey = function (challenge_version, lesson_id) {
        this.track('Download Bonus Answer Key', {
            challenge_id: challenge_version.id,
            challenge_code: challenge_version.code,
            challenge_name: challenge_version.name,
            lesson_id
        })
    }
}

export default analyticChallengeGeneral
