import React, { Component } from 'react'
import PropTypes  from 'prop-types'

import TextBubble from 'common/ui/TextBubble'
import {processStringContent} from 'utils/content'


class Comparison extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gif: false
        }
    }

    renderImages() {
        const {
            imageURL, image_name, animated_gif_url, animated_git_name
        } = this.props.component_data.data
        const {gif} = this.state

        const onClick = () => {
            if (animated_gif_url) {
                this.setState({gif: !gif});
            }
            return true;
        }

        let images = []
        if (imageURL) {
            images.push(
                <img
                    key="image"
                    className={animated_git_name ? 'has-gif' : ''}
                    onClick={onClick}
                    src={imageURL}
                    title={image_name}
                    alt={image_name}
                    style={{display: gif ? 'none' : undefined}}
                />
            )
        }
        if (animated_gif_url) {
            images.push(
                <img
                    key="gif"
                    className={animated_git_name ? 'has-gif' : ''}
                    onClick={onClick}
                    src={animated_gif_url}
                    title={animated_git_name}
                    alt={animated_git_name}
                    style={{display: gif ? undefined : 'none'}}
                />
            )
        }

        return images
    }

    renderText() {
        const {
            text, bubble_background, bubble_direction
        } = this.props.component_data.data

        if (text) {
            return (
                <TextBubble
                    bubble_background={bubble_background}
                    bubble_direction={bubble_direction}
                >
                    {processStringContent(text)}
                </TextBubble>
            )
        }
    }

    render() {
        const {bubble_direction} = this.props.component_data.data
        const extra_class = ` bubble-direction-${bubble_direction} flex-box-10-10 `
        return (
            <div className={"challenge-component-comparison " + extra_class}>
                <div className="challenge-component-comparison-image">
                    {this.renderImages()}
                </div>

                <div className="challenge-component-comparison-text">
                    {this.renderText()}
                </div>
            </div>
        )
    }
}

Comparison.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Comparison
