import {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

export const handleGoogleError = (dispatch, response) => {
    let error = response;
    if (error.hasOwnProperty('result')) {
        error = error.result;
    }
    if (error.hasOwnProperty('error')) {
        error = error.error;
        console.error('handleGoogleError: ', error);
        dispatch(LoadingAction(false));
        if ('PERMISSION_DENIED' === error.status) {
            dispatch(ErrorMessageAction('Sorry. You are not allowed to do that.'));
        } else if ('INVALID_ARGUMENT' === error.status && error.hasOwnProperty('details')) {
            const message = error.details.map(detail => {
                return detail.fieldViolations.map(violation => {
                    return violation.description;
                }).join('. ');
            }).join('. ');
            dispatch(ErrorMessageAction(message));
        } else {
            dispatch(ErrorMessageAction('Sorry. There was a problem with request. Please try again later.'));
        }
    }
};

export const googleAuthenticate = (scopes, dispatch, callback) => {
    google.accounts.oauth2.initTokenClient({
        client_id: process.env.PUBLIC_GOOGLE_OAUTH_ID,
        scope: scopes.join(' '),
        callback: tokenResponse => {
            if (typeof callback === 'function') {
                callback(tokenResponse);
            }
        },
        error_callback: error => {
            dispatch(LoadingAction(false));
            dispatch(ErrorMessageAction('Sorry. We could not sign you in.'));
            console.error('googleAuthenticate: ', error);
        }
    }).requestAccessToken();
};

export function googleAuthenticateNew(scopes) {
    return new Promise((resolve, reject) => {
        google.accounts.oauth2.initTokenClient({
            client_id: process.env.PUBLIC_GOOGLE_OAUTH_ID,
            scope: scopes.join(' '),
            callback: resolve,
            error_callback: reject
        }).requestAccessToken();
    });
}

export const grantGoogleScopes = (scopes, callback, dispatch) => {
    let options = new gapi.auth2.SigninOptionsBuilder({
        scope: scopes
    });

    const auth2 = gapi.auth2.getAuthInstance();
    let googleUser = auth2.currentUser.get();
    googleUser
        .grant(options)
        .then(
            () => {
                if (typeof callback === 'function') {
                    callback();
                }
            },
            fail => {
                console.error('grantGoogleScopes Fail:', fail);
                dispatch(LoadingAction(false));
                if (fail.hasOwnProperty('error')) {
                    if ('popup_blocked_by_browser' === fail.error) {
                        dispatch(ErrorMessageAction('The popup window required for authentication is blocked by your browser. Please allow it and refresh the page.', true));
                    }
                }
            }
        )
        .catch(error => {
            dispatch(LoadingAction(false));
            dispatch(ErrorMessageAction('Sorry. There was a problem with request. Please try again later.'));
            console.error('grantGoogleScopes(catch): ', error);
        });
};

export function isVerifiedTeacher(userId) {
    return gapi.client.classroom.userProfiles
        .get({userId})
        .then(response => response.result.verifiedTeacher);
}
