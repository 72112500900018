import React, {useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {debounce} from "lodash";
import MindsetsJsSDK from "mindsets-js-sdk";

import {SetDashboardDataAction} from "../../../../actions/performance_actions";
import Filters from "../../Filters";
import {default_filters, FILTER_1, FILTER_2} from "../../../../reducers/performance_reducers";
import Graph from "./Graph";
import {getStateBasedTitle} from "../TemplateTypeA/utils";

import './template_type_b.scss';

export default function ({printable}) {
    const dispatch = useDispatch();
    const params = useParams();

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);
    const dashboard_filters = useSelector(state => {
        if (Object.keys(state.dashboard_filters).length === 0) {
            return {
                [FILTER_1]: JSON.parse(JSON.stringify(default_filters)),
                [FILTER_2]: JSON.parse(JSON.stringify(default_filters))
            }
        }

        return state.dashboard_filters;
    });
    const string1 = JSON.stringify(dashboard_filters[FILTER_1]);
    const string2 = JSON.stringify(dashboard_filters[FILTER_2]);
    const mounted = useRef(false);
    const dashboard_id = params.dashboard_id.includes('demo') ? 0 : params.dashboard_id;

    function loadData1(filters) {
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/skills`, filters).then((skill_data) => {
            dispatch(SetDashboardDataAction({skill_data}));
        });
    }

    function loadData2(filters) {
        MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/skills`, filters).then((skill_data_2) => {
            dispatch(SetDashboardDataAction({skill_data_2}));
        });
    }

    const debounceLoadData1 = useCallback(debounce(loadData1, 1500), []);

    const debounceLoadData2 = useCallback(debounce(loadData2, 1500), []);

    useEffect(() => {
        loadData1(dashboard_filters[FILTER_1]);
        loadData2(dashboard_filters[FILTER_2]);
    }, []);

    useEffect(() => {
        if (mounted.current) {
            if (!printable) {
                debounceLoadData1(dashboard_filters[FILTER_1]);
            }
        } else {
            mounted.current = true;
        }
    }, [string1]);

    useEffect(() => {
        if (mounted.current) {
            if (!printable) {
                debounceLoadData2(dashboard_filters[FILTER_2]);
            }
        } else {
            mounted.current = true;
        }
    }, [string2]);

    if (printable && !dashboard_data.skill_data) {
        return (
            <div>
                {`Missing data. Go back to the Insights Dashboard and start again.`}
            </div>
        );
    }

    return (
        <div className="flex-row flex-gap-10 flex-wrap mb-5" id='template_type_b'>
            <div className='template-section flex-1 flex-column flex-h-between'>
                <Filters id={FILTER_1} key={FILTER_1}/>
                <Graph
                    printable={printable}
                    id={'g1'}
                    title={getStateBasedTitle(dashboard).skill}
                    skill_data={dashboard_data.skill_data}
                />
            </div>
            <div className='template-section flex-1 flex-column flex-h-between'>
                <Filters id={FILTER_2} key={FILTER_2}/>
                <Graph
                    printable={printable}
                    id={'g2'}
                    title={getStateBasedTitle(dashboard).skill}
                    skill_data={dashboard_data.skill_data_2}
                />
            </div>
        </div>
    );
}