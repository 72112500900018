import React, {useCallback, useEffect} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";
import {countriesConstants, statesConstants, DistrictDropdown, SetSchoolAction} from "mindsets-js-sdk";

import CurriculumSelector from "common/CurriculumSelector";

const CreateSchoolForm = function ({setMode, validation_error_messages, setValidationErrorMessages}) {
    const dispatch = useDispatch();
    const curricula = useSelector(state => state.curricula);
    const school = useSelector(state => state.school);
    const user = useSelector(state => state.user);
    const default_school = {
        name: user.school ? user.school.name : '',
        country: user.country ? user.country : '',
        state: user.state ? user.state : '',
        curriculum_id: user.curriculum_id ? user.curriculum_id : null,
        location: '',
        zip: '',
        district_id: null,
        district: null,
    }
    let school_new = JSON.parse(JSON.stringify(school));
    let validation_error_messages_new = JSON.parse(JSON.stringify(validation_error_messages));

    const debounced = useCallback(debounce(a => a(), 600), []);

    function renderDistrictField() {
        if (!school.country || -1 === countriesConstants.COUNTRIES_WITH_DISTRICTS.indexOf(school.country)) {
            return null;
        }

        return (
            <div className="row">
                <div className="col s10 offset-s1">
                    <div className="inputfield">
                        <DistrictDropdown
                            onSelect={district => {
                                dispatch(SetSchoolAction(Object.assign(school_new, {
                                    district_id: district ? district.id : null,
                                    district
                                })));

                                return district ? district.name : '';
                            }}
                            label={'School District'}
                            country={school.country}
                            state={school.state}
                            id='create-school-district'
                            show_counts={true}
                            debounced={debounced}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function renderStateField() {
        if (!school.country || !statesConstants.STATES.hasOwnProperty(school.country)) {
            return null;
        }

        const states = [];
        states.push(<option key={'empty'} value='' disabled>Select state</option>);

        for (const stateCode in statesConstants.STATES[school.country]) {
            if (statesConstants.STATES[school.country].hasOwnProperty(stateCode)) {
                states.push(
                    <option
                        key={stateCode}
                        value={stateCode}
                    >{statesConstants.STATES[school.country][stateCode]}</option>
                );
            }
        }

        return (
            <div className="row">
                <div className="col s1">&nbsp;</div>
                <div className="col s6">
                    <div className="inputfield">
                        <label htmlFor="schoolstate" className="flex-row flex-box-2-5">
                            <div>State *</div>
                            {
                                validation_error_messages.hasOwnProperty('schoolstate') ?
                                    <i className="fas fa-exclamation-circle text-red"/> : null
                            }
                        </label>
                        <select
                            value={school.state || ''}
                            onChange={event => {
                                dispatch(SetSchoolAction(Object.assign(school_new, {state: event.target.value})));
                                delete validation_error_messages_new.schoolstate;
                                setValidationErrorMessages(validation_error_messages_new);
                            }}
                            id="schoolstate" required
                        >
                            {states}
                        </select>
                    </div>
                </div>
                <div className="col s4">
                    {renderCurriculumField()}
                </div>
            </div>
        );
    }

    function renderCurriculumField() {
        return (
            <CurriculumSelector
                curriculum_id={school.curriculum_id}
                onCurriculumIdChange={curriculum_id => {
                    dispatch(SetSchoolAction(Object.assign(school_new, {curriculum_id})));
                    delete validation_error_messages_new.curriculum;
                    setValidationErrorMessages(validation_error_messages_new);
                }}
                validation_error_messages={validation_error_messages}
                curriculums={curricula}
            />
        );
    }

    useEffect(() => {
        dispatch(SetSchoolAction(default_school));
    }, []);

    return (
        <div className="modal-content">
            <div className="container">
                <div className="row no-mb">
                    <div className="col s1">
                        <h4>
                            <a href="" className="back-link" onClick={
                                (event) => {
                                    event.preventDefault();
                                    setMode('user');
                                    setValidationErrorMessages({});
                                    dispatch(SetSchoolAction(default_school));
                                }
                            }>{"<"}</a>
                        </h4>
                    </div>
                    <div className="col s10">
                        <h4>Add new school</h4>
                    </div>
                </div>

                <div className="row no-mb">
                    <div className="col s10 offset-s1 error-messages">
                        {Object.keys(validation_error_messages).length > 0 ? 'Please complete all required fields to proceed with Mindsets.' : null}
                    </div>
                </div>

                <div className="add-school-form">
                    <div className="row">
                        <div className="col s1">&nbsp;</div>
                        <div className="col s10">
                            <div className="inputfield">
                                <label htmlFor="schoolname" className="flex-row">
                                    <div>School Name *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('schoolname') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <input
                                    type="text"
                                    value={school.name || ''}
                                    onChange={event => {
                                        dispatch(SetSchoolAction(Object.assign(school_new, {name: event.target.value})));
                                        delete validation_error_messages_new.schoolname;
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    autoComplete="off"
                                    id="schoolname" required/>
                            </div>
                        </div>
                    </div>

                    {renderDistrictField()}

                    <div className="row">
                        <div className="col s1">&nbsp;</div>
                        <div className="col s10">
                            <div className="inputfield">
                                <label htmlFor="location" className="flex-row">
                                    <div>Address *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('schoollocation') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <input
                                    type="text"
                                    value={school.location || ''}
                                    onChange={event => {
                                        dispatch(SetSchoolAction(Object.assign(school_new, {location: event.target.value})));
                                        delete validation_error_messages_new.schoollocation;
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    autoComplete="off"
                                    id="location" required/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s1">&nbsp;</div>
                        <div className="col s10">
                            <div className="inputfield">
                                <label htmlFor="location" className="flex-row">
                                    <div>City *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('schoolcity') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <input
                                    type="text"
                                    value={school.city || ''}
                                    onChange={event => {
                                        dispatch(SetSchoolAction(Object.assign(school_new, {city: event.target.value})));
                                        delete validation_error_messages_new.schoolcity;
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    autoComplete="off"
                                    id="city" required/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s1">&nbsp;</div>
                        <div className="col s6">
                            <div className="inputfield">
                                <label htmlFor="location" className="flex-row">
                                    <div>Country *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('schoolcountry') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <select
                                    value={school.country || ''}
                                    onChange={event => {
                                        dispatch(SetSchoolAction(Object.assign(school_new, {country: event.target.value})));
                                        delete validation_error_messages_new.schoolcountry;
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    id="schoolcountry" required
                                >
                                    <option value='' disabled>Select country</option>
                                    {Object.keys(countriesConstants.COUNTRIES).map(code => (
                                        <option
                                            key={code}
                                            value={code}
                                        >{countriesConstants.COUNTRIES[code]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="inputfield">
                                <label htmlFor="zip" className="flex-row">
                                    <div>Zip Code *</div>
                                    {
                                        validation_error_messages.hasOwnProperty('schoolzip') ?
                                            <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                    }
                                </label>
                                <input
                                    type="text"
                                    value={school.zip || ''}
                                    onChange={event => {
                                        dispatch(SetSchoolAction(Object.assign(school_new, {zip: event.target.value})));
                                        delete validation_error_messages_new.schoolzip;
                                        setValidationErrorMessages(validation_error_messages_new);
                                    }}
                                    id="zip" required
                                    autoComplete="off"/>
                            </div>
                        </div>
                    </div>

                    {renderStateField()}

                    {!school.country || !statesConstants.STATES.hasOwnProperty(school.country) ?
                        <div className="row">
                            <div className="col s1">&nbsp;</div>
                            <div className="col s6">
                                {renderCurriculumField()}
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    );
}

CreateSchoolForm.propTypes = {
    setMode: PropTypes.func.isRequired,
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired
}

export default CreateSchoolForm;
