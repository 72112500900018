import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {processStringContent} from 'utils/content';


function NumberLine({value, data, updateAnswer, readonly = false}) {
    const [readablePosition, setReadablePosition] = useState(0);
    const lineBG = useRef();
    const handle = useRef();
    const valueRef = useRef(value);
    const readablePositionRef = useRef(value);
    const increment_count = Math.floor((data.last_num - data.first_num) / data.increment_interval);

    function setInitialNumber() {
        setReadablePosition(value);
        readablePositionRef.current = value;
        updateWidth();
        if (typeof updateAnswer === 'function') {
            updateAnswer(value);
        }
    }

    function setHandlePos(mousePos) {
        let snap_division_width = 0;
        if (lineBG.current) {
            snap_division_width = lineBG.current.offsetWidth / (data.last_num - data.first_num) * data.increment_interval;
        }

        let position = Math.min(Math.max(mousePos, 0), lineBG.current.offsetWidth);

        if (snap_division_width === 0) {
            position = 0;
        } else {
            position = Math.round(position / snap_division_width);
            position = Math.round(position * snap_division_width);
        }

        handle.current.style.left = position + 'px';

        const intervals_moved = snap_division_width > 0 ? Math.round(position / snap_division_width) : 0;
        const readablePosition = Math.round((data.first_num + data.increment_interval * intervals_moved) * 1000) / 1000;

        setReadablePosition(readablePosition);
        readablePositionRef.current = readablePosition;
    }

    function onMouseMove(e) {
        e.preventDefault();
        let line = lineBG.current;
        if (!line || !window.onmousemove) {
            return;
        }
        let xPos = (e.pageX !== undefined && e.pageX !== null) ? e.pageX : e.changedTouches[0].pageX;
        const startMousePos = -line.getBoundingClientRect().x
        let mousePos = xPos + (startMousePos - (handle.current.offsetWidth / 2));
        setHandlePos(mousePos);
    }

    function handleDown(e) {
        e.preventDefault();

        if (window.onmousemove) {
            return;
        }
        window.onmousemove = onMouseMove;
        window.onmouseup = onMouseUp;
        if ('ontouchstart' in window) {
            window.ontouchmove = onMouseMove;
            window.touchend = onMouseUp;
        }
    }

    function onMouseUp(e) {
        e.preventDefault();

        window.onmousemove = null;
        window.onmouseup = null;
        if ('ontouchstart' in window) {
            window.touchmove = null;
            window.touchend = null;
        }

        if (typeof updateAnswer === 'function') {
            updateAnswer(readablePositionRef.current);
        }
    }

    function updateWidth() {
        let position = readablePosition;
        if (!position) {
            position = value;
        }
        const divSze = (lineBG.current.offsetWidth / (data.last_num - data.first_num));

        if (position !== null) {
            setHandlePos(divSze * (position - data.first_num));
        }
    }

    function getEventHandlers() {
        if (readonly) {
            return {}
        }

        return {
            onMouseDown: handleDown,
            onTouchStart: (('ontouchstart' in window) ? handleDown : null),
            onTouchEnd: onMouseUp
        }
    }

    function renderTopLabels() {
        let labels = [];
        if (data.numberPoints && lineBG.current) {
            for (let l = 0; l < data.numberPoints.length; l++) {
                let thisPoint = data.numberPoints[l];
                const renderPointContent = () => {
                    if (thisPoint.formula) {
                        return processStringContent(thisPoint.formula);
                    } else if (thisPoint.text) {
                        return thisPoint.text;
                    } else if (thisPoint.html) {
                        return processStringContent(thisPoint.html);
                    } else if (thisPoint.image) {
                        return (
                            <img src={thisPoint.image.url} title={thisPoint.image.title} alt={thisPoint.image.alt}/>
                        );
                    }
                }

                let leftPos = (lineBG.current.offsetWidth * (thisPoint.numberLinePoint - data.first_num) / (data.last_num - data.first_num)) + "px";
                labels.push(
                    <div style={{left: leftPos}} key={"topL" + l} id={'top-label-' + l}>
                        <div style={thisPoint.style}>
                            {renderPointContent()}
                        </div>
                    </div>
                );
            }
        }

        return labels;
    }

    function renderBottomLabels() {
        let labels = [];

        if (lineBG.current) {
            for (let l = 1; l < increment_count; l++) {
                let leftPos = ((lineBG.current.offsetWidth / (data.last_num - data.first_num)) * (l * data.increment_interval)) + 'px';
                labels.push(
                    <div
                        id={'line-marker -' + l}
                        style={{left: leftPos}}
                        className="numberLineMarker"
                        key={"bottomL" + l}
                    >|</div>
                );
            }
        }

        if (!isNaN(data.first_num)) {
            labels.push(<div id='bottom-first' style={{left: 0}} key={"bottomLFirst"}>
                <div>{data.first_num}</div>
            </div>);
        }

        if (!isNaN(data.last_num)) {
            labels.push(<div id='bottom-last' style={{right: 0}} key={"bottomLLast"}>
                <div>{data.last_num}</div>
            </div>);
        }

        return labels;
    }

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        setTimeout(() => {
            setInitialNumber();
        }, 300);

        return () => {
            window.removeEventListener("resize", updateWidth);
        }
    }, []);

    useEffect(() => {
        if (readonly && value !== valueRef.current) {
            setInitialNumber();
        }
    });

    return (
        <div>
            <div id="numberLineOuter" className="numberLineOuter">
                <div>
                    <div id="topLabels">
                        {renderTopLabels()}
                    </div>
                    <div className="lineBG" id="lineBG" ref={lineBG}>
                        <div className="leftArrow"></div>
                        <div className="rightArrow"></div>
                    </div>
                    <div className="handle" id="handle" ref={handle} {...getEventHandlers()}>
                        <div className="handle-inner">
                            <div className="handle-arrow"></div>
                        </div>
                        <div className="handle-value">
                            {readablePosition}
                        </div>
                    </div>
                    <div id="bottomLabels">
                        {renderBottomLabels()}
                    </div>

                </div>
            </div>
        </div>
    );
}

NumberLine.propType = {
    value: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    updateAnswer: PropTypes.func,
    readonly: PropTypes.bool
};

export default NumberLine;
