import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Modal from 'react-responsive-modal';

import analytic from 'utils/Analytic';
import {getStudentStreams} from "../utils";


export default function () {
    const me = useSelector(state => state.me);
    const streams_orig = useSelector(state => state.streams);
    const streams = getStudentStreams(streams_orig, me.id);
    const [video_modal_open, setVideoModalOpen] = useState(false);

    function renderContent() {
        const videoOnClick = () => {
            setVideoModalOpen(true);
            analytic.log('facilitationWatchTour');
        };

        return (
            <div className="overview-emptystate-container">
                <img className="hide-on-small-only"
                     src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/teacher_facilitation_overview_empty.png`}
                     alt='Empty'/>
                <img className="hide-on-med-and-up"
                     src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/teacher_facilitation_overview_empty_mobile.png`}
                     alt='Empty'/>

                <div className="section flex-column flex-vh-center">
                    <h5 className="header-new center-align">
                        {`Students who start the Challenge will appear here`}
                    </h5>
                    <div className="header-new center-align">
                        {`From here you can:`}
                    </div>
                    <div className="overview-emptystate-steps">
                        <div className="flex-row flex-v-center flex-box-2-5">
                            <i className="fas fa-check"></i>
                            <span>{`Track student progress`}</span>
                        </div>
                        <div className="flex-row flex-v-center flex-box-2-5">
                            <i className="fas fa-check"></i>
                            <span>{`Pause the class or a student`}</span>
                        </div>
                        <div className="flex-row flex-v-center flex-box-2-5">
                            <i className="fas fa-check"></i>
                            <span>{`Navigate to a student or a question`}</span>
                        </div>
                    </div>
                </div>

                <div className="section flex-row flex-h-center">
                    <button className="btn btn-on-white" onClick={videoOnClick}>
                        <i className="fas fa-video"></i>
                        <span>{`Watch a tour`}</span>
                    </button>
                </div>
            </div>
        );
    }

    function renderVideoModal() {
        const video_script = `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/9sv5wtvnts?seo=false&videoFoam=true" title="Wistia video player" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`

        return (
            <Modal
                open={video_modal_open}
                onClose={() => setVideoModalOpen(false)}
                showCloseIcon={true}
                classNames={{modal: 'overview-emptystate-video-modal'}}
                center
            >
                <div
                    className="overview-emptystate-video-container"
                    ref={(div) => {
                        if (div) {
                            div.innerHTML = video_script
                        }
                    }}
                >
                </div>
            </Modal>
        )
    }

    if (streams.length > 0) {
        return null;
    }

    return (
        <div className="section">
            <div className="overview-emptystate">
                {renderContent()}

                {renderVideoModal()}
            </div>
        </div>
    );
}
