import React, {useEffect, useState} from 'react';
import MindsetsJsSDK from "mindsets-js-sdk";

import ActiveChallenge from './ActiveChallenge';

export default function () {

    const [challenges, setChallenges] = useState([]);

    useEffect(() => {
        MindsetsJsSDK().API.get(`professional_development/challenges`).then((response) => {
            console.log('response', response);
            setChallenges(response.items);
        });
    }, []);

    const renderChallenges = () => {
        return challenges.map((challenge_version) => {
            return (
                <ActiveChallenge
                    key={challenge_version.id}
                    challenge_version={challenge_version}
                />
            );
        });
    }

    return (
        <div id="professional-development-library">
            <div id="teacher-dashboard" className="container">
                <div className="section">
                    <h1 className="header-new">Professional Development Courses</h1>
                </div>

                <div className="section">
                    <div className="flex-row flex-wrap flex-gap-20">
                        {renderChallenges()}
                    </div>
                </div>
            </div>
        </div>
    );
}