// pp = points_percent
export const PRACTICES_DATA = {
    'ccmps': {
        practices_title: 'Math Practices',
        has_category: true,
        intervals: {
            'exceeded': {
                name: 'Exceeded',
                condition: (pp) => 73 < pp && pp <= 100,
                color: '#19A8FF'
            },
            'met': {
                name: 'Met',
                condition: (pp) => 60 < pp && pp < 73,
                color: '#74C043'
            },
            'nearly_met': {
                name: 'Nearly Met',
                condition: (pp) => 44 < pp && pp <= 60,
                color: '#D6DF28'
            },
            'not_met': {
                name: 'Not Met',
                condition: (pp) => 0 <= pp && pp <= 44,
                color: '#F79768'
            }
        }
    },
    'ela': {
        practices_title: 'ELA Targets',
        has_category: true,
        intervals: {
            'exceeded': {
                name: 'Exceeded',
                condition: (pp) => 73 < pp && pp <= 100,
                color: '#19A8FF'
            },
            'met': {
                name: 'Met',
                condition: (pp) => 60 < pp && pp < 73,
                color: '#74C043'
            },
            'nearly_met': {
                name: 'Nearly Met',
                condition: (pp) => 44 < pp && pp <= 60,
                color: '#D6DF28'
            },
            'not_met': {
                name: 'Not Met',
                condition: (pp) => 0 <= pp && pp <= 44,
                color: '#F79768'
            }
        }
    },
    'bestmtrs': {
        practices_title: 'Mathematical Thinking and Reasoning (MTR) Standards',
        has_category: false,
        intervals: {
            'level_5': {
                name: 'Level 5',
                condition: (pp) => 72 < pp && pp <= 100,
                color: '#8264A2'
            },
            'level_4': {
                name: 'Level 4',
                condition: (pp) => 59 < pp && pp <= 72,
                color: '#0470C0'
            },
            'level_3': {
                name: 'Level 3',
                condition: (pp) => 48 < pp && pp <= 59,
                color: '#06B050'
            },
            'level_2': {
                name: 'Level 2',
                condition: (pp) => 38 < pp && pp <= 48,
                color: '#E56C0B'
            },
            'level_1': {
                name: 'Level 1',
                condition: (pp) => 0 <= pp && pp <= 38,
                color: '#C00000'
            }
        }
    }
}