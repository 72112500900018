import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Droppable} from "react-beautiful-dnd"
import {get} from 'lodash'

import {processStringContent} from 'utils/content'
import DnDDraggableItem from './DnDDraggableItem';


class DnDDroppableItem extends Component {
    getItemStyle() {
        return {
            ...this.props.item.item_position
        }
    }

    getDroppableStyle() {
        const style = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "72px",
            minHeight: "72px",
            padding: "10px",
            borderRadius: "4px",
            position: "relative",
            ...get(this.props.item, 'droppable_style')
        }

        const hide_box_when_filled = get(this.props.component_data, 'config.hide_box_when_filled');

        if (this.props.selected && hide_box_when_filled) {
            style.border = "none";
            style.background = "none";
        }

        return style;
    }

    renderSelected() {
        if (!this.props.selected) {
            if (this.props.item.placeholder) {
                return (
                    <div>
                        {processStringContent(this.props.item.placeholder)}
                    </div>
                );
            } else {
                return undefined;
            }
        }

        return (
            <DnDDraggableItem
                is_preview={this.props.is_preview}
                item={this.props.selected}
                option_index={0}
                onRemove={this.props.onRemove(this.props.selected.dnd_id)}
                is_selected={true}
            />
        );
    }

    render() {
        return (
            <div className="cc-dndpuzzle-layout-item" style={this.getItemStyle()}>
                <Droppable
                    droppableId={get(this.props.item, 'dnd_id')}
                    type={'123'}
                    direction="horizontal"
                    isDropDisabled={this.props.is_preview}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={this.getDroppableStyle()}
                        >
                            {this.renderSelected()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

DnDDroppableItem.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    selected: PropTypes.object,
    onRemove: PropTypes.func.isRequired
}

export default DnDDroppableItem
